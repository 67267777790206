/* eslint-disable react/display-name */
import plus from '@assets/icons/Button/plus.svg';
import React, { ButtonHTMLAttributes } from 'react';

interface ButtonAddProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  svg?: string;
  bg?: string;
  textColor?: string;
}

export const ButtonAdd = React.forwardRef<HTMLButtonElement, Omit<ButtonAddProps, 'ref'>>(
  ({ text, svg, bg, textColor, ...props }, ref) => (
    <button
      ref={ref}
      {...props}
      className={`flex items-center w-full ${bg || 'bg-backgray'} px-5 py-4 gap-3 hover:opacity-80
      `}
    >
      <img className="min-w-12 min-h-12 max-w-12 max-h-12 rounded-full" src={svg || plus} alt="Plus Icon" />
      <span className={`text-lg text-black-100 ${textColor || ''}`}>{text}</span>
    </button>
  ),
);
