import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { calendarBuild } from '..';

import moment from 'moment';
import DayCard from '../DayCard';

interface MonthCardProps {
  month: string;
  currentYear: number;
  initialDate: string;
  setInitialDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  date1: Date | null;
  date2: Date | null;
}

export default function MonthCard({
  month,
  currentYear,
  initialDate,
  setInitialDate,
  endDate,
  setEndDate,
  date1,
  date2,
}: Readonly<MonthCardProps>) {
  const { t } = useTranslation();
  const [value, setValue] = useState<moment.Moment>(moment().locale('en').month(month).year(currentYear));
  const [calendar, setCalendar] = useState<moment.Moment[][]>([]);

  const weekDays = [t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat'), t('sun')];

  useEffect(() => {
    setValue(value.month(month).year(currentYear));
    setCalendar(calendarBuild(value));
  }, [value, month, currentYear]);

  return (
    <div className="h-full">
      <div className="grid grid-cols-7 gap-x-8 mb-2">
        {weekDays.map((value, index) => {
          if (value === 'Sat' || value === 'Sun') {
            return (
              <div className="text-xs text-primary-100 mb-2 text-center" key={index}>
                {value}
              </div>
            );
          }
          return (
            <div className="text-xs text-primary-200 mb-2 text-center" key={index}>
              {value}
            </div>
          );
        })}
      </div>
      {calendar.map((week, index) => (
        <div className="grid grid-cols-7 gap-x-8 mb-3" key={week.toString()}>
          {week.map((day) => {
            return (
              <DayCard
                key={day.unix()}
                calendarIndex={index}
                day={day}
                initialDate={initialDate}
                setInitialDate={setInitialDate}
                endDate={endDate}
                setEndDate={setEndDate}
                date1={date1}
                date2={date2}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
}
