import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGroups } from '@hooks/useGroups';

import { Button } from '@components/Button';
import GroupCard from '@components/GroupCard';
import Loading from '@components/Loading';
import { PaginationComponent } from '@components/Pagination';

export type PageProps = {
  current: number;
  next: number[];
  previous: number[];
};
export default function ListGroups() {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<PageProps>();
  const navigate = useNavigate();
  const { getGroups, groups, pagination } = useGroups();

  const { t } = useTranslation();

  const getGroupsList = useCallback(async () => {
    await getGroups(`?take=6&page=1`);
    setLoading(false);
  }, [getGroups]);

  useEffect(() => {
    getGroupsList();
  }, [getGroupsList]);

  async function handleSelectPage(selectedPage: number) {
    setLoading(true);
    await getGroups(`?take=6&page=${selectedPage}`);
    setLoading(false);
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="w-full flex flex-col min-h-[calc(100vh-88px)] bg-backgray">
          {groups?.length === 0 ? (
            <div className="flex flex-col items-center h-full mt-40">
              <h2 className="text-[25px] font-semibold text-secondary-400 mb-5">{t('no_groups_here_yet')}</h2>
              <p className="text-lg text-secondary-400 mb-12">{t('create_a_group_and_invite')}</p>
              <span>
                <Button
                  onClick={() => navigate('/groups/create')}
                  data-test="create-group-btn"
                  size="medium"
                  variant="primary"
                  text={t('create_a_new_group')}
                />
              </span>
            </div>
          ) : (
            <div className="flex flex-col p-5">
              <div className="flex flex-wrap justify-center gap-12 relative">
                {groups.map((group) => {
                  return <GroupCard key={group.uuid} group={group} />;
                })}
              </div>
              <span className="flex items-center justify-center my-8">
                <Button
                  onClick={() => navigate('/groups/create')}
                  data-test="create-group-btn"
                  size="medium"
                  variant="primary"
                  text={t('create_a_new_group')}
                />
              </span>
              {groups?.length > 0 && (
                <PaginationComponent
                  setPage={setPage}
                  pagination={pagination}
                  page={page}
                  handleSelectPage={handleSelectPage}
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
