import * as Dialog from '@radix-ui/react-dialog';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUser } from '@hooks/useUser';

import ButtonAction from '@components/ButtonAction';

import calendar from '@assets/icons/Profile/SettingsAndPrivacy/calendar.svg';
import faq from '@assets/icons/Profile/SettingsAndPrivacy/faq.svg';
import flag from '@assets/icons/Profile/SettingsAndPrivacy/flag.svg';
import language from '@assets/icons/Profile/SettingsAndPrivacy/language.svg';
import logoutIcon from '@assets/icons/Profile/SettingsAndPrivacy/logout.svg';
import notification from '@assets/icons/Profile/SettingsAndPrivacy/notification.svg';
import privacy from '@assets/icons/Profile/SettingsAndPrivacy/privacy.svg';
import resetPassword from '@assets/icons/Profile/SettingsAndPrivacy/reset-password.svg';
import shareLink from '@assets/icons/Profile/SettingsAndPrivacy/share-link.svg';
import tag from '@assets/icons/Profile/SettingsAndPrivacy/tag.svg';
import terms from '@assets/icons/Profile/SettingsAndPrivacy/terms.svg';
import { toast } from 'utils/toast';

export default function SettingsAndPrivacyModal() {
  const { t } = useTranslation();
  const closeButton = useRef<HTMLButtonElement>(null);
  const closeButtonTerms = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  const { logout, deleteTermsOfUse, user } = useUser();

  async function handleLogout() {
    await logout();
    navigate('/login');
  }
  function handleChangePage(url: string) {
    navigate(url);
    closeButton.current?.click();
  }

  const handleDeleteTermsOfUse = useCallback(async () => {
    const response = await deleteTermsOfUse();
    closeButtonTerms.current?.click();
    if (response)
      toast({
        label: t('success'),
        message: t('terms_of_use_deleted_successfully'),
        type: 'success',
      });
  }, [deleteTermsOfUse, closeButtonTerms]);

  return (
    <div className="relative z-[10000] max-h-screen overflow-y-scroll">
      <Dialog.Close ref={closeButton} className={'absolute top-2 left-2 hover:opacity-70'}>
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
          <path
            d="M3.35209 0.489335C2.69963 -0.163112 1.6418 -0.163112 0.989335 0.489335C0.336888 1.1418 0.336888 2.19963 0.989335 2.85209L10.6372 12.4999L0.989435 22.1478C0.336989 22.8002 0.336989 23.8581 0.989435 24.5105C1.6419 25.1629 2.69973 25.1629 3.35217 24.5105L12.9999 14.8627L22.6478 24.5105C23.3002 25.1629 24.3581 25.1629 25.0105 24.5105C25.6629 23.8581 25.6629 22.8002 25.0105 22.1478L15.3627 12.4999L25.0107 2.85209C25.6631 2.19963 25.6631 1.1418 25.0107 0.489335C24.3583 -0.163112 23.3004 -0.163112 22.648 0.489335L12.9999 10.1372L3.35209 0.489335Z"
            fill="#737373"
          />
        </svg>
      </Dialog.Close>
      <div className="flex flex-col w-full py-8 px-10">
        <ButtonAction
          onClick={() => handleChangePage('/profile/language')}
          text={t('language')}
          src={language}
          imgLarge={true}
        />
        <ButtonAction
          onClick={() => handleChangePage('/profile/subscription')}
          text={t('subscription')}
          src={tag}
          imgLarge={true}
        />
        <ButtonAction
          onClick={() => handleChangePage('/profile/notifications-settings')}
          text={t('notifications')}
          src={notification}
          imgLarge={true}
        />
        <ButtonAction
          onClick={() => handleChangePage('/profile/calendar')}
          text={t('calendar')}
          src={calendar}
          imgLarge={true}
        />
        <ButtonAction
          // onClick={() => handleChangePage('/faq')}
          text="FAQ"
          src={faq}
          imgLarge={true}
        />
        <ButtonAction
          onClick={() => handleChangePage('/profile/report-problem')}
          text={t('report_a_problem')}
          src={flag}
          imgLarge={true}
        />
        <ButtonAction
          onClick={() => window.open(t('terms_of_use_link'), '_blank')}
          text={t('terms_of_service')}
          src={terms}
          imgLarge={true}
        />
        <ButtonAction
          onClick={() => window.open(t('privacy_policy_link'), '_blank')}
          text={t('privacy_policy')}
          src={privacy}
          imgLarge={true}
        />
        <ButtonAction
          onClick={() => handleChangePage('/invitation-code')}
          text={t('have_share_link')}
          src={shareLink}
          imgLarge={true}
        />
        <ButtonAction
          onClick={() => handleChangePage('/password/reset-internal')}
          text={t('reset_password')}
          src={resetPassword}
          imgLarge={true}
        />
        {user?.is_super_admin === 1 && (
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <ButtonAction text={t('delete_terms_of_use_accepted')} src={terms} imgLarge={true} />
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay
                style={{
                  position: 'fixed',
                  width: '100vw',
                  height: '100vh',
                  inset: 0,
                  background: 'rgba(0, 0, 0, 0.75)',
                  zIndex: '10',
                }}
              />
              <Dialog.Content
                forceMount
                className={
                  'z-40 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white px-12 py-8 rounded-lg font-poppins'
                }
              >
                <div className="flex flex-col gap-5">
                  <Dialog.Close ref={closeButtonTerms} className={'hidden'}></Dialog.Close>
                  <h1 className="text-lg text-black-100 font-semibold">{t('delete_terms_of_use')}</h1>
                  <span className="text-sm text-grey-200 mb-2">{t('confirm_delete_terms_of_use_accepted')}</span>
                  <div className="flex items-center justify-end gap-4">
                    <button
                      onClick={() => closeButtonTerms.current?.click()}
                      className="flex items-center justify-center py-2 px-3 rounded-md bg-primary-200 bg-opacity-20 hover:opacity-70"
                    >
                      {t('cancel')}
                    </button>
                    <button
                      onClick={handleDeleteTermsOfUse}
                      className="flex items-center justify-center py-2 px-3 rounded-md bg-green-300 hover:opacity-70"
                    >
                      {t('confirm')}
                    </button>
                  </div>
                </div>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        )}
        <div className="mt-8">
          <ButtonAction onClick={handleLogout} text={t('logout')} src={logoutIcon} imgLarge={true} />
        </div>
      </div>
    </div>
  );
}
