import { SVGProps } from 'react';

interface GoogleProps extends SVGProps<SVGSVGElement> {}

function GoogleIcon({ ...props }: GoogleProps) {
  return (
    <svg
      {...props}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_798_20)">
        <path
          d="M5.98559 10.6714C6.95704 7.72774 9.72335 5.61364 13 5.61364C14.7613 5.61364 16.3522 6.23864 17.6022 7.26136L21.2386 3.625C19.0227 1.69318 16.1818 0.5 13 0.5C8.07296 0.5 3.83096 3.31073 1.79163 7.42711L5.98559 10.6714Z"
          fill="#EA4335"
        />
        <path
          d="M17.2091 19.263C16.0739 19.9961 14.6313 20.3863 13 20.3863C9.7359 20.3863 6.97823 18.2883 5.99683 15.3623L1.789 18.5572C3.8258 22.6808 8.06769 25.4999 13 25.4999C16.055 25.4999 18.9743 24.4139 21.1606 22.3745L17.2091 19.263Z"
          fill="#34A853"
        />
        <path
          d="M21.1606 22.3743C23.4471 20.2415 24.9318 17.0661 24.9318 12.9998C24.9318 12.2611 24.8182 11.4657 24.6477 10.7271H13V15.5566H19.7045C19.3737 17.1806 18.4857 18.4385 17.2091 19.2629L21.1606 22.3743Z"
          fill="#4A90E2"
        />
        <path
          d="M5.99686 15.3625C5.74826 14.6213 5.61364 13.827 5.61364 13.0001C5.61364 12.1858 5.7442 11.4031 5.98563 10.6715L1.79166 7.42725C0.954778 9.10475 0.5 10.9953 0.5 13.0001C0.5 14.9997 0.963313 16.8857 1.78902 18.5574L5.99686 15.3625Z"
          fill="#FBBC05"
        />
      </g>
      <defs>
        <clipPath id="clip0_798_20">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export { GoogleIcon };
