import { useRef, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { useTranslation } from 'react-i18next';

import { Comment, useComments } from '@hooks/useComments';
import { Insight, useInsights } from '@hooks/useInsights';

import ButtonAction from '@components/ButtonAction';
import { Button } from '@components/Button';

import trash from '@assets/icons/Button/trash.svg';
import pencil from '@assets/icons/Button/pencil.svg';

import { toast } from 'utils/toast';

interface DeleteOrEditCommentModalProps {
  comment: Comment;
  insight: Insight;
  setComments: React.Dispatch<React.SetStateAction<Comment[]>>;
}

export default function DeleteOrEditCommentModal({ comment, insight, setComments }: DeleteOrEditCommentModalProps) {
  const { t } = useTranslation();
  const [newComment, setNewComment] = useState(comment?.body || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showEditComment, setShowEditComment] = useState(false);
  const closeButton = useRef<HTMLButtonElement>(null);

  const { deleteComment, updateComment } = useComments();
  const { updateSpecificInsight } = useInsights();

  async function handleDeleteComment() {
    setIsSubmitting(true);
    const response = await deleteComment(insight?.group?.uuid || '', insight?.uuid || '', comment?.uuid || '');
    if (response.uuid) {
      toast({
        label: t('success'),
        message: t('comment_deleted'),
        type: 'success',
      });
      updateSpecificInsight(insight?.group?.uuid || '', insight?.uuid || '');
      setComments((prev) => prev.filter((comment) => comment.uuid !== response.uuid));
    }
    closeButton.current?.click();
    setIsSubmitting(false);
  }

  async function handleUpdateComment() {
    setIsSubmitting(true);
    const response = await updateComment(
      insight?.group?.uuid || '',
      insight?.uuid || '',
      comment?.uuid || '',
      newComment,
    );
    if (response.uuid) {
      toast({
        label: t('success'),
        message: t('comment_updated'),
        type: 'success',
      });
      setComments((prev) =>
        prev.map((comment) => (comment.uuid === response.uuid ? { ...comment, body: response.body } : comment)),
      );
    }
    closeButton.current?.click();
    setIsSubmitting(false);
  }

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '20',
        }}
      />
      <Dialog.Content
        forceMount
        className={'z-40 w-[calc(100vw-200px)] rounded-lg p-10 right-0 bottom-0 fixed bg-white font-poppins'}
      >
        <Dialog.Close onClick={() => setShowEditComment(false)} ref={closeButton} className={'absolute top-4 right-8'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
            <path
              d="M3.35209 0.489335C2.69963 -0.163112 1.6418 -0.163112 0.989335 0.489335C0.336888 1.1418 0.336888 2.19963 0.989335 2.85209L10.6372 12.4999L0.989435 22.1478C0.336989 22.8002 0.336989 23.8581 0.989435 24.5105C1.6419 25.1629 2.69973 25.1629 3.35217 24.5105L12.9999 14.8627L22.6478 24.5105C23.3002 25.1629 24.3581 25.1629 25.0105 24.5105C25.6629 23.8581 25.6629 22.8002 25.0105 22.1478L15.3627 12.4999L25.0107 2.85209C25.6631 2.19963 25.6631 1.1418 25.0107 0.489335C24.3583 -0.163112 23.3004 -0.163112 22.648 0.489335L12.9999 10.1372L3.35209 0.489335Z"
              fill="#737373"
            />
          </svg>
        </Dialog.Close>

        <div className="flex flex-col items-center w-full">
          {showEditComment ? (
            <>
              <h1 className="text-lg font-semibold mb-5">{t('edit_comment')}</h1>
              <textarea
                value={newComment}
                maxLength={255}
                onChange={(e) => setNewComment(e.target.value)}
                className="w-[500px] border border-grey-300 outline-none p-3 rounded-md text-sm h-[100px] bg-backgray resize-none mb-5"
              />
              <div className="flex items-center gap-10">
                <button
                  onClick={() => {
                    setNewComment(comment?.body || '');
                    setShowEditComment(false);
                  }}
                  className="flex items-center p-2 border border-grey-400 rounded-md shadow-md text-primary-200 hover:opacity-70"
                >
                  {t('cancel')}
                </button>
                <Button onClick={handleUpdateComment} text={t('save')} size="medium" variant="primary" boxShadow />
              </div>
            </>
          ) : (
            <>
              <ButtonAction
                onClick={() => setShowEditComment(true)}
                disabled={isSubmitting}
                src={pencil}
                text={t('edit_comment')}
              />
              <ButtonAction
                disabled={isSubmitting}
                onClick={handleDeleteComment}
                src={trash}
                text={t('delete_comment')}
              />
            </>
          )}
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
