/* eslint-disable no-async-promise-executor */
import { User } from '@hooks/useUser';
import axios from 'axios';

const token = localStorage.getItem('familyWisdomToken');

const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASEPATH,

  headers: {
    Authorization: `Bearer ${token ?? ''}`,
    Accept: 'application/json',
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    return new Promise(async (resolve, reject) => {
      if (err?.response?.config?.url && err.response.config.url.includes('auth')) {
        reject(err);
        return;
      }
      if (err.response?.data?.status === 'login-expired' && err.config && !err.config.retry) {
        try {
          err.config.retry = true;
          const refreshToken = localStorage.getItem('familyWisdomRefreshToken');

          const { data } = await api.get('/auth/refresh-token', {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          });
          err.config.headers['Authorization'] = `Bearer ${data.token}`;
          localStorage.setItem('familyWisdomToken', data.token);
          localStorage.setItem('familyWisdomRefreshToken', data.refresh_token);
          api.defaults.headers['Authorization'] = `Bearer ${data.token}`;
          resolve(api.request(err.config));
          return;
        } catch (tokenError) {
          const user: User = localStorage.getItem('familyWisdomUser')
            ? JSON.parse(localStorage.getItem('familyWisdomUser')!)
            : null;
          if (!user) {
            reject(err);
            return;
          }
          try {
            await Promise.all([
              api.post(`/users/${user.uuid}/tokens`, {
                device: 'ios',
                token: null,
              }),
              await api.post('/auth/logout', {}),
            ]);
            localStorage.removeItem('familyWisdomUser');
            localStorage.removeItem('familyWisdomToken');
            localStorage.removeItem('familyWisdomRefreshToken');
            window.location.href = '/login';
            reject(tokenError);
          } catch (error: any) {
            localStorage.removeItem('familyWisdomUser');
            localStorage.removeItem('familyWisdomToken');
            localStorage.removeItem('familyWisdomRefreshToken');
            window.location.href = '/login';
            reject(tokenError);
          }
        }
      }
      reject(err);
    });
  },
);

export { api };
