import { Pagination } from '@hooks/useGroups';
import { usePagination } from '@hooks/usePagination';
import { PageProps } from '@pages/Groups';
import { useEffect } from 'react';

type PaginationProps = {
  pagination: Pagination;
  page: PageProps | undefined;
  handleSelectPage: (page: number) => void;
  setPage: React.Dispatch<React.SetStateAction<PageProps | undefined>>;
};
export const PaginationComponent = ({ pagination, page, handleSelectPage, setPage }: PaginationProps) => {
  const { setPagination } = usePagination();
  useEffect(() => {
    setPagination(pagination, setPage);
  }, [pagination, setPage, setPagination]);

  return (
    <div className="absolute bottom-0 left-[50%] -translate-x-[50%] flex items-center ml-28 gap-2 py-2">
      {pagination && (
        <>
          {page?.previous && page?.previous?.length > 0 && (
            <button
              onClick={() => handleSelectPage(page?.current - 1)}
              className="text-primary-200 text-2xl hover:opacity-70"
            >
              {'<'}
            </button>
          )}
          {page?.previous.map((page) => {
            return (
              <button onClick={() => handleSelectPage(page)} className="hover:opacity-70" key={page}>
                {page}
              </button>
            );
          })}
          <button
            className={`${page?.previous && page?.previous?.length > 0 ? 'flex' : 'hidden'} ${
              page?.next && page?.next?.length > 0 ? 'flex' : 'hidden'
            } text-primary-200 font-semibold underline hover:opacity-70`}
          >
            {page?.current}
          </button>
          {page?.next.map((page) => {
            return (
              <button onClick={() => handleSelectPage(page)} className="hover:opacity-70" key={page}>
                {page}
              </button>
            );
          })}
          {page?.next && page?.next?.length > 0 && (
            <button
              onClick={() => handleSelectPage(page?.current + 1)}
              className="text-primary-200 text-2xl hover:opacity-70"
            >
              {'>'}
            </button>
          )}
        </>
      )}
    </div>
  );
};
