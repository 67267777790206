
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from '../locale/en.json';
import ptTranslations from '../locale/pt.json';

const userJSON = localStorage.getItem('familyWisdomUser');

if (userJSON) {
  const user = JSON.parse(userJSON);

  if (user && user.language) {
    i18n.use(initReactI18next).init({
      lng: user.language,
      resources: {
        en: enTranslations,
        pt: ptTranslations,
      },
    });
  }
} else {
  i18n.use(initReactI18next).init({
    lng: 'en',
    resources: {
      en: enTranslations,
      pt: ptTranslations,
    },
  });
}