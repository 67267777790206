import { useCallback, useRef } from 'react';
import * as Dialog from '@radix-ui/react-dialog';

import AdsDescriptionBox from '@components/AdsDescriptionBox';
import TextEditorModalAds from '@components/TextEditorModalAds';

import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';

import { X } from '@phosphor-icons/react';
import { useAds } from '@hooks/useAds';
import { useTranslation } from 'react-i18next';

export default function AdsPrimaryInformation() {
  const { t } = useTranslation();
  const { ads, setAds } = useAds();

  const dragAndDropInput = useRef<HTMLInputElement>(null);

  const handleRemoveFile = useCallback(() => {
    setAds({ thumbnail: '', file: undefined, url: '', fileType: '' });
  }, [ads, setAds]);

  async function handleDragAndDropFile(event: any) {
    event.preventDefault();
    let file: File = {} as File;

    if (event?.target?.files) {
      file = event.target.files[0];
    } else if (event?.dataTransfer?.files) {
      file = event.dataTransfer.files[0];
    }

    if (!file) return;
    if (file.type.includes('audio')) return;
    if (file.type.includes('video')) {
      const videoThumbnails = await generateVideoThumbnails(file, 1, 'png');
      setAds({
        thumbnail: videoThumbnails[0],
        file: file,
        url: URL.createObjectURL(file),
        fileType: 'video',
      });
    } else {
      setAds({
        thumbnail: URL.createObjectURL(file),
        file: file,
        url: URL.createObjectURL(file),
        fileType: 'image',
      });
    }
  }

  return (
    <div className="flex flex-col pt-5 pb-3 px-3 bg-white rounded-md relative">
      <input
        id="select-image"
        accept="image/*,video/*,audio/*"
        type="file"
        className="hidden"
        ref={dragAndDropInput}
        onChange={handleDragAndDropFile}
      />
      {!ads.url ? (
        <>
          <p className="text-lg font-medium text-grey-200 px-3">
            {t('select_a_photo_video_or_add_a_description_for_your_commercial')}
          </p>
          <div
            aria-hidden
            onDrop={handleDragAndDropFile}
            onDragOver={(e) => e.preventDefault()}
            onClick={() => dragAndDropInput.current?.click()}
            className="w-full h-[232px] border border-grey-600 flex flex-col gap-2 items-center justify-center cursor-pointer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
              <g opacity="0.6">
                <path
                  d="M19.1663 3.02539V8.53278C19.1663 9.27951 19.1663 9.65288 19.3117 9.9381C19.4395 10.189 19.6435 10.393 19.8944 10.5208C20.1796 10.6661 20.5529 10.6661 21.2997 10.6661H26.8071M12.4997 19.9993L16.4997 23.9993M16.4997 23.9993L20.4997 19.9993M16.4997 23.9993L16.4997 15.9993M19.1663 2.66602H12.233C9.9928 2.66602 8.87269 2.66602 8.01705 3.10199C7.2644 3.48548 6.65248 4.09741 6.26898 4.85005C5.83301 5.7057 5.83301 6.82581 5.83301 9.06602V22.9327C5.83301 25.1729 5.83301 26.293 6.26898 27.1486C6.65248 27.9013 7.2644 28.5132 8.01705 28.8967C8.87269 29.3327 9.9928 29.3327 12.233 29.3327H20.7663C23.0066 29.3327 24.1267 29.3327 24.9823 28.8967C25.735 28.5132 26.3469 27.9013 26.7304 27.1486C27.1663 26.293 27.1663 25.1729 27.1663 22.9327V10.666L19.1663 2.66602Z"
                  stroke="#282A74"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            <p className="text-sm text-grey-200 text-opacity-60">{t('your_files_will_appear_here')}</p>
          </div>
        </>
      ) : (
        <div
          onDrop={handleDragAndDropFile}
          onDragOver={(e) => e.preventDefault()}
          className="flex flex-col gap-8 items-center mt-8"
          aria-hidden
        >
          <span className="w-[150px] h-[150px] relative">
            {ads?.fileType == 'image' && (
              <img
                alt="Preview"
                src={ads.url}
                className="w-full h-full max-h-full max-w-full object-cover pointer-events-none"
              />
            )}
            {ads?.fileType == 'video' && (
              <video controls src={ads.url} className="w-full h-full max-h-full max-w-full object-cover" />
            )}
            <button
              onClick={handleRemoveFile}
              title="Remove file"
              className="absolute top-0 right-0 flex items-center justify-center bg-white rounded-full border border-primary-200 hover:brightness-110"
            >
              <X size={20} />
            </button>
          </span>
        </div>
      )}

      {ads.description && <AdsDescriptionBox />}

      <div className="flex items-center justify-center py-3 border-t border-grey-600 mt-8">
        <p className="w-[200px] text-lg font-medium ml-3">{t('add_description')}</p>
        <div className="flex items-center justify-start ml-1">
          {/* ADD DESCRIPTION */}
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <button
                defaultValue={ads.description}
                className="flex items-center justify-center bg-primary-200 bg-opacity-10 rounded-md px-[10px] py-[5px] hover:opacity-70"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path
                    d="M20.3955 19.7062H12.9453M3.16699 20.1719L8.33486 18.1842C8.6654 18.0571 8.83068 17.9935 8.9853 17.9105C9.12265 17.8368 9.25358 17.7517 9.37674 17.6561C9.51538 17.5485 9.6406 17.4233 9.89102 17.1729L20.3955 6.66844C21.4242 5.63979 21.4242 3.97202 20.3955 2.94336C19.3669 1.91471 17.6991 1.91471 16.6704 2.94336L6.16594 13.4478C5.91552 13.6983 5.79031 13.8235 5.68272 13.9621C5.58715 14.0853 5.50206 14.2162 5.42833 14.3535C5.34533 14.5082 5.28176 14.6734 5.15463 15.004L3.16699 20.1719ZM3.16699 20.1719L5.08366 15.1886C5.22081 14.832 5.28939 14.6537 5.40701 14.572C5.50981 14.5006 5.637 14.4737 5.75992 14.4971C5.90058 14.524 6.03566 14.6591 6.30582 14.9292L8.40964 17.0331C8.6798 17.3032 8.81488 17.4383 8.84174 17.579C8.86522 17.7019 8.83822 17.8291 8.76685 17.9319C8.68518 18.0495 8.50688 18.1181 8.15028 18.2552L3.16699 20.1719Z"
                    stroke="#902687"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </Dialog.Trigger>
            <TextEditorModalAds />
          </Dialog.Root>
        </div>
      </div>
    </div>
  );
}
