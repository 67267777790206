import { useCallback, useEffect, useState } from 'react';

import { useCalendar } from '@hooks/useCalendar';
import { useInsights } from '@hooks/useInsights';

import AdsPost from '@components/AdsPost';
import Calendar from '@components/Calendar';
import EmptyFeed from '@components/EmptyStates/EmptyFeed';
import Loading from '@components/Loading';
import PostFeed from '@components/PostFeed';

import loadingGif from '@assets/icons/loading.gif';

import { handleMessageError } from 'utils/handleMessageError';

export default function Feed() {
  const [loading, setLoading] = useState(false);
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [loadingFeed, setLoadingFeed] = useState(false);

  const { insights, getUserFeed, pagination } = useInsights();
  const { getUserCalendar, userCalendar, getUserCalendarByDay, userCalendarByDay, setUserCalendarByDay } =
    useCalendar();

  const [, setSelectedDay] = useState<string | null>(null);

  const [, setMonthSelected] = useState('');
  const [, setYearSelected] = useState('');

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const getFullDate = new Date();
  const [monthNow, setMonthNow] = useState((getFullDate.getMonth() + 1).toString().padStart(2, '0'));
  const yearNow = getFullDate.getFullYear().toString();

  useEffect(() => {
    const onScroll = async () => {
      if (
        Math.round(window.innerHeight + document.documentElement.scrollTop + 35) >=
          document.documentElement.offsetHeight &&
        !loadingFeed &&
        pagination.current_page < pagination.last_page
      ) {
        setLoadingFeed(true);
        await getUserFeed(false, `?sortBy=updated_at&sortDirection=desc&page=${pagination.current_page + 1}`);
        setLoadingFeed(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [getUserFeed, loadingFeed, pagination]);

  function handleDateSelect(day: string) {
    setSelectedDay(day);
    getCalendarByDay(yearNow, monthNow, day);
  }

  const getCalendar = useCallback(
    async (year: string, month: string) => {
      setLoadingCalendar(true);
      try {
        await getUserCalendar(`year=${year}&month=${month}`);
      } catch (err) {
        const message = handleMessageError(err);
        console.error(message);
      } finally {
        setLoadingCalendar(false);
      }
    },
    [getUserCalendar],
  );

  const getCalendarByDay = useCallback(
    async (year: string, month: string, day: string) => {
      setLoadingCalendar(true);
      try {
        await getUserCalendarByDay(`year=${year}&month=${month}&day=${day}`);
      } catch (err) {
        const message = handleMessageError(err);
        console.error(message);
      } finally {
        setLoadingCalendar(false);
      }
    },
    [getUserCalendarByDay],
  );

  const handleDateChange = (newDate: Date | null) => {
    setSelectedDate(newDate);
  };

  useEffect(() => {
    handleDateChange(selectedDate);
    if (selectedDate) {
      const yearParam = selectedDate.getFullYear().toString();
      const monthParam = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
      getCalendar(yearParam, monthParam);
      getCalendarByDay(yearParam, monthParam, '');
    }
  }, [getCalendar, getCalendarByDay, selectedDate]);

  useEffect(() => {
    setUserCalendarByDay([]);
    setMonthSelected(monthNow);
    setYearSelected(yearNow);

    getCalendar(yearNow, monthNow);
    getCalendarByDay(yearNow, monthNow, '');
  }, [getCalendar, getCalendarByDay, setUserCalendarByDay, yearNow]);

  const handleMonthYearChange = (month: string, year: string) => {
    setMonthSelected(month);
    setYearSelected(year);

    getCalendar(year, month);
    getCalendarByDay(year, month, '');

    setMonthNow(month);
  };

  const getFeedForUser = useCallback(
    async (first: boolean) => {
      setLoading(true);
      await getUserFeed(first, `?sortBy=updated_at&sortDirection=desc&page=1`);
      setLoading(false);
    },
    [getUserFeed],
  );

  useEffect(() => {
    getFeedForUser(true);
  }, [getFeedForUser]);

  return (
    <div className="min-h-[calc(100vh-88px)] bg-backgray py-8 relative overflow-x-scroll h-full">
      <div className="max-w-6xl grid desktop:grid-cols-2 grid-cols-1 justify-center mx-auto">
        {loading ? (
          <Loading />
        ) : (
          <div className="flex flex-col gap-5 justify-center items-center desktop:ml-20">
            {insights.length === 0 ? (
              <EmptyFeed />
            ) : (
              <>
                {insights.map((insightOrAds) => {
                  if (insightOrAds?.type === 'ad') return <AdsPost ads={insightOrAds} key={insightOrAds.uuid} />;
                  else return <PostFeed key={insightOrAds.uuid} insight={insightOrAds} />;
                })}
              </>
            )}
          </div>
        )}

        <div className="hidden desktop:flex flex-col fixed right-60 desktop-lg:right-20 items-center">
          <Calendar
            shouldDisplayTabs
            loadingCalendar={loadingCalendar}
            userCalendarByDay={userCalendarByDay}
            type="eventPicker"
            onMonthYearChange={handleMonthYearChange}
            onDateSelect={handleDateSelect}
            userCalendar={userCalendar}
          />
        </div>
      </div>
      {loadingFeed && (
        <img src={loadingGif} alt="Loading" className="w-12 absolute bottom-0 left-[50%] -translate-x-[50%]" />
      )}
    </div>
  );
}
