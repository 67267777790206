import * as Dialog from '@radix-ui/react-dialog';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useGuest } from '@hooks/useGuest';

import { Button } from '@components/Button';
import { isEmail } from 'utils/auxFunctions';
import { toast } from 'utils/toast';

interface AddVisitorModalProps {
  insightUuid: string;
  groupId: string;
}

export default function AddVisitorModal({ groupId, insightUuid }: Readonly<AddVisitorModalProps>) {
  const { t } = useTranslation();

  const { inviteGuest, getGuestsByPostUuid } = useGuest();

  const closeButton = useRef<HTMLButtonElement>(null);
  const inviteByEmailOrPhoneInput = useRef<HTMLInputElement>(null);

  async function handleInviteUserByEmailOrPhone() {
    if (!groupId || !insightUuid) return;

    if (!inviteByEmailOrPhoneInput.current?.value) {
      toast({
        label: t('error'),
        message: t('fill_all_fields'),
        type: 'error',
      });
      return;
    }
    if (isEmail(inviteByEmailOrPhoneInput?.current?.value || '')) {
      const response = await inviteGuest(groupId, insightUuid, inviteByEmailOrPhoneInput?.current?.value || '');
      if (response) {
        toast({
          label: t('success'),
          message: t('invitation_sent'),
          type: 'success',
        });
      }
      getGuestsByPostUuid(groupId, insightUuid);
      inviteByEmailOrPhoneInput.current.value = '';
    } else {
      toast({
        label: t('error'),
        message: t('invalid_email'),
        type: 'error',
      });
    }
  }

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '10',
        }}
      />
      <Dialog.Content
        forceMount
        className={'z-40 w-[calc(100vw-200px)] rounded-lg p-4 right-0 bottom-0 fixed bg-white font-poppins'}
      >
        <div className="w-full flex flex-col items-center gap-1 p-10 relative">
          <h1 className="text-2xl font-bold">{t('share')}</h1>
          <p className="text-lg">{t('invite_friends')}</p>
          <div className="mt-5 flex flex-col items-center desktop:w-[50%] tablet:w-[80%]">
            <input
              ref={inviteByEmailOrPhoneInput}
              placeholder={t('invite_by_email_or_phone_number')}
              className="block border border-primary-200 font-medium p-4 w-full text-xl text-primary-200 bg-white rounded-lg appearance-none focus:outline-none focus:ring-0 peer a mb-5 placeholder:text-primary-200 placeholder:opacity-60"
            />
            {/* INVITE USER BY EMAIL OR PHONE BUTTON */}
            <span className="ml-auto">
              <Button
                onClick={handleInviteUserByEmailOrPhone}
                size="medium"
                variant="primary"
                text={t('invite')}
                boxShadow
              />
            </span>
          </div>
        </div>

        <Dialog.Close ref={closeButton} className={'absolute top-4 right-8'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
            <path
              d="M3.35209 0.489335C2.69963 -0.163112 1.6418 -0.163112 0.989335 0.489335C0.336888 1.1418 0.336888 2.19963 0.989335 2.85209L10.6372 12.4999L0.989435 22.1478C0.336989 22.8002 0.336989 23.8581 0.989435 24.5105C1.6419 25.1629 2.69973 25.1629 3.35217 24.5105L12.9999 14.8627L22.6478 24.5105C23.3002 25.1629 24.3581 25.1629 25.0105 24.5105C25.6629 23.8581 25.6629 22.8002 25.0105 22.1478L15.3627 12.4999L25.0107 2.85209C25.6631 2.19963 25.6631 1.1418 25.0107 0.489335C24.3583 -0.163112 23.3004 -0.163112 22.648 0.489335L12.9999 10.1372L3.35209 0.489335Z"
              fill="#737373"
            />
          </svg>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
