import { create } from 'zustand';
import { api } from '../services/axios';
import { showErrorMessage } from '../utils/auxFunctions';
import { toast } from '../utils/toast';

interface Pagination {
  current_page: number;
  last_page: number;
  total: number;
  last_page_url: string | null;
}

export interface EventLabel {
  uuid: string;
  name: string;
  color: string;
}

interface EventLabelsProps {
  pagination: Pagination;
  eventLabels: EventLabel[];
  getEventLabelsForGroup: (groupUuid: string, params?: string) => Promise<void>;
  createEventLabel: (groupUuid: string, name: string, color: string) => Promise<EventLabel>;
  updateEventLabel: (groupUuid: string, eventLabelUuid: string, name: string, color: string) => Promise<EventLabel>;
}

export const useEventLabels = create<EventLabelsProps>((set) => ({
  pagination: {
    current_page: 1,
    last_page: 1,
    total: 1,
    last_page_url: null,
  },
  eventLabels: [],

  getEventLabelsForGroup: async (groupUuid: string, params?: string) => {
    try {
      const { data } = await api.get(`/groups/${groupUuid}/event-labels` + (params ? params : ''));
      if (data.content.data) {
        set({ eventLabels: data.content.data.data });
        set({
          pagination: {
            current_page: data.content.data.current_page,
            last_page: data.content.data.last_page,
            total: data.content.data.total,
            last_page_url: data.content.data.last_page_url,
          },
        });
      }
    } catch (error) {
      showErrorMessage(error);
    }
  },

  createEventLabel: async (groupUuid: string, name: string, color: string) => {
    try {
      const { data } = await api.post(`/groups/${groupUuid}/event-labels`, { name, color });
      toast({
        label: 'Success',
        message: data.content.message || 'Event Label created successfully',
        type: 'success',
      });
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  updateEventLabel: async (groupUuid: string, eventLabelUuid: string, name: string, color: string) => {
    try {
      const { data } = await api.put(`/groups/${groupUuid}/event-labels/${eventLabelUuid}`, { name, color });
      toast({
        label: 'Success',
        message: data.content.message || 'Event Label updated successfully',
        type: 'success',
      });
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },
}));
