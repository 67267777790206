import { useEffect, useState } from 'react';

import { Category } from '@hooks/useCategory';
import { useInsights } from '@hooks/useInsights';

import { ImageWithFallback } from '@pages/ShareInsight/Groups';
import { useNavigate, useParams } from 'react-router-dom';
import { getCategoryImage } from 'utils/icons';
import SubcategoriesListComponent from './SubcategoriesListComponent';

interface CategorieListComponentProps {
  category: Category;
}

export default function CategorieListComponent({ category }: Readonly<CategorieListComponentProps>) {
  const { editingInsight, editInsight } = useInsights();
  const [isSelected, setIsSelected] = useState<boolean>(editingInsight?.category.uuid === category.uuid || false);

  const { insightUuid, groupId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    setIsSelected(editingInsight.category.uuid === category.uuid);
  }, [category.uuid, editingInsight.category.uuid]);

  function handleSelectCategory(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.stopPropagation();
    editInsight({ category: { uuid: category.uuid, name: category.name, subCategoryUuid: '', topicUuid: '' } }, true);
    navigate('/insight/edit/' + groupId + '/' + insightUuid + '/finish?previous=editInsightCategories');
  }

  return (
    <div className="flex flex-col w-full">
      <button
        className={`flex items-center justify-between w-full p-3 transition-all border-b border-grey-600 cursor-pointer ${
          isSelected ? 'bg-primary-200 bg-opacity-10' : ''
        }`}
        onClick={() => {
          if (!isSelected) {
            editInsight(
              { category: { uuid: category.uuid, name: category.name, subCategoryUuid: '', topicUuid: '' } },
              true,
            );
          } else {
            editInsight({ category: { uuid: '', name: '', subCategoryUuid: '', topicUuid: '' } }, true);
          }
        }}
      >
        <div className="flex items-center gap-3">
          <svg
            className={`${isSelected ? 'rotate-90' : ''} duration-300`}
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="26"
            viewBox="0 0 25 26"
            fill="none"
          >
            <g clipPath="url(#clip0_3335_16907)">
              <path
                d="M6.2502 21.2375L8.0127 23L18.0127 13L8.0127 3L6.2502 4.7625L14.4752 13L6.2502 21.2375Z"
                fill={`${isSelected ? '#EDB561' : '#333333'}`}
              />
            </g>
            <defs>
              <clipPath id="clip0_3335_16907">
                <rect width="25" height="25" fill="white" transform="translate(0 0.9)" />
              </clipPath>
            </defs>
          </svg>
          <span className="flex items-center justify-center w-[30px] h-[30px] rounded-full bg-grey-600">
            <ImageWithFallback
              className="min-w-[20px] max-w-[20px] min-h-[20px] max-h-[20px]"
              src={getCategoryImage(category?.icon)}
              alt={category?.name}
            />
          </span>
          <p className="break-words max-w-[25vw]">{category?.name}</p>
        </div>
        {isSelected && (
          <button
            data-test="edit-insight-category-submit"
            onClick={handleSelectCategory}
            className="hover:opacity-70 z-[1]"
          >
            <svg
              className="duration-300"
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="26"
              viewBox="0 0 25 26"
              fill="none"
            >
              <g clipPath="url(#clip0_3335_16907)">
                <path
                  d="M6.2502 21.2375L8.0127 23L18.0127 13L8.0127 3L6.2502 4.7625L14.4752 13L6.2502 21.2375Z"
                  fill="#EDB561"
                />
              </g>
              <defs>
                <clipPath id="clip0_3335_16907">
                  <rect width="25" height="25" fill="white" transform="translate(0 0.9)" />
                </clipPath>
              </defs>
            </svg>
          </button>
        )}
      </button>
      <div
        className={`flex flex-col w-full ${
          isSelected ? 'max-h-[500px]' : 'max-h-0 overflow-hidden'
        } transition-all duration-300`}
      >
        {category.children.map((subCategories) => (
          <SubcategoriesListComponent key={subCategories.uuid} category={subCategories} />
        ))}
      </div>
    </div>
  );
}
