import { create } from 'zustand';
import { api } from '../services/axios';
import { showErrorMessage } from '../utils/auxFunctions';
import { User } from './useUser';
import { Group } from './useGroups';

interface Pagination {
  current_page: number;
  last_page: number;
  total: number;
  last_page_url: string | null;
}

export interface SubscriptionPrice {
  uuid: string;
  name: string;
  item_type: string;
  currency: string;
  price: number;
  pricing_model: string;
  period_unit: string;
  period: number;
  chargebee_item_price_id: string;
}

export interface SubscriptionPlan {
  id: number;
  uuid: string;
  name: string;
  description: string;
  chargebee_plan_id: string;
  subscription_prices: SubscriptionPrice[];
}

export interface CreateSubscriptionDTO {
  plan_uuid: string;
  chargebee_item_price_id: string;
  first_name: string;
  last_name: string;
  email: string;
  token_id: string;
}

export interface Subscription {
  uuid: string;
  trial_starts_at: string;
  trial_ends_at: string;
  ends_at: string;
  chargebee_subscription_id: string;
  chargebee_customer_id: string;
  status: string;
  user: User;
  group: Group;
  info: 'unauthorized' | 'subscribed' | 'not-subscribed';
  subscription_plan: SubscriptionPlan;
}

export interface PortalSession {
  id: string;
  token: string;
  access_url: string;
  status: string;
  created_at: number;
  expires_at: number;
  object: string;
  customer_id: string;
}
interface SubscriptionProps {
  pagination: Pagination;
  plans: SubscriptionPlan[];
  plan: SubscriptionPlan;
  subscriptions: Subscription[];
  getSubscriptionPlans: () => Promise<SubscriptionPlan[]>;
  getSubscriptionPlanDetails: (subscriptionPlanUuid: string) => Promise<SubscriptionPlan>;
  createSubscription: (subscriptionObj: CreateSubscriptionDTO, groupId: string) => Promise<any>;
  getSubscriptionsForUser: (params?: string) => Promise<Subscription[]>;
  authenticatePortalSession: (customer_id: string) => Promise<PortalSession>;
}

export const useSubscription = create<SubscriptionProps>((set) => ({
  pagination: {
    current_page: 1,
    last_page: 1,
    total: 1,
    last_page_url: null,
  },
  plans: [],
  plan: {
    id: 0,
    uuid: '',
    name: '',
    subscription_prices: [],
    slug: '',
    description: '',
    chargebee_plan_id: '',
  },
  subscriptions: [],

  getSubscriptionPlans: async () => {
    try {
      const { data } = await api.get('/subscription-plans');
      set({ plans: data.content.data });
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  getSubscriptionPlanDetails: async (subscriptionPlanUuid: string) => {
    try {
      const { data } = await api.get(`/subscription-plans/${subscriptionPlanUuid}`);
      set({ plan: data.content.data });
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  createSubscription: async (subscription: CreateSubscriptionDTO, groupId: string) => {
    try {
      const { data } = await api.post('/groups/' + groupId + '/subscriptions', { ...subscription });
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },
  getSubscriptionsForUser: async (params?: string) => {
    try {
      const { data } = await api.get(`/subscriptions` + (params ? params : ''));
      if (data.content.data) {
        set({ subscriptions: data.content.data.data });
        set({
          pagination: {
            current_page: data.content.data.current_page,
            last_page: data.content.data.last_page,
            total: data.content.data.total,
            last_page_url: data.content.data.last_page_url,
          },
        });
        return data.content.data.data;
      }
      return data.content.data.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  authenticatePortalSession: async (customer_id: string) => {
    try {
      const { data } = await api.post(`/chargebee-login-portal`, { customer_id });
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },
}));
