import { useTranslation } from 'react-i18next';

export default function EmptySubscriptions() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center gap-5">
      <svg xmlns="http://www.w3.org/2000/svg" width="248" height="186" viewBox="0 0 248 186" fill="none">
        <path d="M247.899 162.945H0.101013V163.069H247.899V162.945Z" fill="#EBEBEB" />
        <path d="M223.07 170.922H215.685V171.046H223.07V170.922Z" fill="#EBEBEB" />
        <path d="M164.252 172.267H159.944V172.391H164.252V172.267Z" fill="#EBEBEB" />
        <path d="M213.592 166.32H196.647V166.444H213.592V166.32Z" fill="#EBEBEB" />
        <path d="M54.433 168.877H33.027V169.001H54.433V168.877Z" fill="#EBEBEB" />
        <path d="M66.566 168.877H58.846V169.001H66.566V168.877Z" fill="#EBEBEB" />
        <path d="M126.356 167.214H79.931V167.338H126.356V167.214Z" fill="#EBEBEB" />
        <path
          d="M117.564 140.842H21.864C21.114 140.841 20.3949 140.543 19.8645 140.013C19.3341 139.482 19.0358 138.763 19.035 138.013V3.49206C19.0361 2.74218 19.3345 2.02334 19.8649 1.49318C20.3952 0.963031 21.1142 0.664856 21.864 0.664062H117.564C118.314 0.665121 119.032 0.96341 119.563 1.49353C120.093 2.02366 120.391 2.74235 120.392 3.49206V138.011C120.392 138.761 120.094 139.481 119.564 140.012C119.033 140.542 118.314 140.841 117.564 140.842ZM21.865 0.788063C21.1496 0.790173 20.4641 1.07532 19.9582 1.58121C19.4523 2.0871 19.1671 2.77263 19.165 3.48806V138.011C19.1671 138.727 19.4523 139.412 19.9582 139.918C20.4641 140.424 21.1496 140.709 21.865 140.711H117.565C118.28 140.709 118.966 140.424 119.472 139.918C119.978 139.412 120.263 138.727 120.265 138.011V3.49206C120.263 2.77663 119.978 2.0911 119.472 1.58521C118.966 1.07931 118.28 0.794175 117.565 0.792065L21.865 0.788063Z"
          fill="#EBEBEB"
        />
        <path
          d="M224.76 140.842H129.06C128.31 140.841 127.591 140.543 127.061 140.012C126.531 139.482 126.233 138.763 126.232 138.013V3.49206C126.233 2.74227 126.531 2.02342 127.061 1.49324C127.591 0.963063 128.31 0.664857 129.06 0.664062H224.76C225.51 0.664856 226.229 0.963031 226.759 1.49318C227.29 2.02334 227.588 2.74218 227.589 3.49206V138.011C227.589 138.761 227.291 139.481 226.76 140.012C226.23 140.543 225.51 140.841 224.76 140.842ZM129.06 0.788063C128.345 0.790173 127.659 1.07532 127.153 1.58121C126.647 2.0871 126.362 2.77263 126.36 3.48806V138.011C126.362 138.727 126.647 139.412 127.153 139.918C127.659 140.424 128.345 140.709 129.06 140.711H224.76C225.476 140.71 226.163 140.426 226.67 139.92C227.177 139.414 227.463 138.727 227.465 138.011V3.49206C227.463 2.77576 227.177 2.08948 226.67 1.58345C226.163 1.07741 225.476 0.792855 224.76 0.792065L129.06 0.788063Z"
          fill="#EBEBEB"
        />
        <path d="M106.394 162.944H115.623L115.623 99.5721H106.394V162.944Z" fill="#E6E6E6" />
        <path d="M109.643 162.944H106.394V150.904H113.049L109.643 162.944Z" fill="#F0F0F0" />
        <path d="M203.988 162.944H213.217V99.5721H203.988V162.944Z" fill="#E6E6E6" />
        <path d="M106.394 159.657H206.823V99.572L106.394 99.572L106.394 159.657Z" fill="#F0F0F0" />
        <path d="M203.578 162.944H206.827V150.904H200.168L203.578 162.944Z" fill="#F0F0F0" />
        <path d="M173.713 104.547V154.251H203.484V104.547H173.713Z" fill="#E6E6E6" />
        <path
          d="M185.654 106.295H191.544C194.037 106.32 196.449 105.414 198.308 103.753H178.891C180.75 105.413 183.162 106.32 185.654 106.295Z"
          fill="#F0F0F0"
        />
        <path d="M141.723 104.547V154.251H171.494V104.547H141.723Z" fill="#E6E6E6" />
        <path
          d="M153.363 106.295H159.253C161.745 106.32 164.157 105.413 166.016 103.753H146.599C148.458 105.414 150.87 106.32 153.363 106.295Z"
          fill="#F0F0F0"
        />
        <path d="M109.733 104.547V154.251H139.504V104.547H109.733Z" fill="#E6E6E6" />
        <path
          d="M121.373 106.295H127.263C129.756 106.32 132.168 105.414 134.027 103.753H114.609C116.468 105.414 118.88 106.32 121.373 106.295Z"
          fill="#F0F0F0"
        />
        <path d="M34.7831 162.944H97.7531L97.7531 21.3921H34.7831L34.7831 162.944Z" fill="#F0F0F0" />
        <path d="M34.7831 162.944H96.4241L96.4241 21.3921H34.7831L34.7831 162.944Z" fill="#F5F5F5" />
        <path d="M92.668 159.732L92.668 24.604L38.539 24.604L38.539 159.732H92.668Z" fill="#F0F0F0" />
        <path
          d="M87.349 49.735C87.349 47.7971 86.9673 45.8782 86.2257 44.0878C85.4841 42.2974 84.3971 40.6706 83.0268 39.3003C81.6565 37.9299 80.0297 36.8429 78.2393 36.1013C76.4489 35.3597 74.5299 34.978 72.592 34.978H58.616C54.7022 34.978 50.9487 36.5328 48.1812 39.3003C45.4138 42.0677 43.859 45.8212 43.859 49.735H87.349Z"
          fill="#FAFAFA"
        />
        <path d="M87.349 151.333V119.051H43.859V151.333H87.349Z" fill="#F5F5F5" />
        <path d="M87.349 58.8372H68.166V114.886H87.349V58.8372Z" fill="#F5F5F5" />
        <path d="M63.042 58.8372H43.859V114.886H63.042V58.8372Z" fill="#F5F5F5" />
        <path
          d="M50.816 86.6091H42.959C42.6359 86.6084 42.3263 86.4797 42.0979 86.2512C41.8695 86.0228 41.7408 85.7132 41.74 85.3901C41.741 85.0672 41.8698 84.7577 42.0982 84.5293C42.3266 84.301 42.636 84.1722 42.959 84.1711H50.816C51.139 84.1722 51.4484 84.301 51.6768 84.5293C51.9052 84.7577 52.0339 85.0672 52.035 85.3901C52.0342 85.7132 51.9055 86.0228 51.6771 86.2512C51.4486 86.4797 51.139 86.6084 50.816 86.6091Z"
          fill="#E0E0E0"
        />
        <path d="M39.514 159.732L39.514 24.604H38.54L38.54 159.732H39.514Z" fill="#F0F0F0" />
        <path d="M137.583 84.0361L204.362 84.0361V21.3921L137.583 21.3921V84.0361Z" fill="#E6E6E6" />
        <path d="M135.9 84.0361L202.679 84.0361V21.3921L135.9 21.3921V84.0361Z" fill="#F5F5F5" />
        <path d="M132.001 87.6621L204.362 87.6621V84.0361L132.001 84.0361V87.6621Z" fill="#E6E6E6" />
        <path d="M130.106 87.6621L198.74 87.6621V84.0361L130.106 84.0361V87.6621Z" fill="#F5F5F5" />
        <path d="M199.657 80.7781V24.6511L138.921 24.6511V80.7781H199.657Z" fill="white" />
        <path d="M170.972 80.7781V24.6511H167.605V80.7781H170.972Z" fill="#F5F5F5" />
        <path d="M140.194 80.7781V24.6511H138.921V80.7781H140.194Z" fill="#E6E6E6" />
        <path d="M172.245 80.7781V24.6511H170.972V80.7781H172.245Z" fill="#E6E6E6" />
        <path
          d="M146.56 85.697C145.332 72.972 144.477 52.323 145.141 45.417C145.153 45.2983 145.189 45.1834 145.248 45.08C145.308 44.9765 145.388 44.8869 145.485 44.817C145.613 44.723 145.766 44.667 145.924 44.6554C146.083 44.6437 146.242 44.6769 146.382 44.7511C146.523 44.8253 146.64 44.9376 146.72 45.0752C146.8 45.2127 146.84 45.3699 146.835 45.529C146.718 48.951 146.583 57.504 147.312 72.259C147.675 79.604 148.198 84.634 147.839 85.695L146.56 85.697Z"
          fill="#E6E6E6"
        />
        <path
          d="M141.982 56.3681C138.482 52.4851 131.343 49.7241 124.065 49.7381C121.208 49.7441 132.758 54.2551 137.565 59.2381C141.407 63.3761 144.569 68.0962 146.934 73.2231C146.006 65.6181 145.479 60.2491 141.982 56.3681Z"
          fill="#E6E6E6"
        />
        <path
          d="M148.914 57.55C151.814 53.506 158.521 50.396 165.774 50.044C168.621 49.906 157.788 54.981 153.745 60.183C150.52 64.5309 148.068 69.4022 146.498 74.583C146.286 66.97 146.01 61.595 148.914 57.55Z"
          fill="#E6E6E6"
        />
        <path
          d="M141.826 42.1861C138.865 38.8981 132.817 36.5601 126.654 36.5721C124.235 36.5721 134.015 40.3981 138.082 44.6131C141.335 48.1169 144.013 52.1137 146.016 56.4551C145.234 50.0211 144.785 45.4741 141.826 42.1861Z"
          fill="#E6E6E6"
        />
        <path
          d="M147.695 47.049C150.154 43.624 155.831 40.991 161.972 40.693C164.383 40.576 155.21 44.873 151.786 49.278C149.054 52.9615 146.978 57.0887 145.649 61.478C145.471 55.026 145.236 50.472 147.695 47.049Z"
          fill="#E6E6E6"
        />
        <path
          d="M147.054 99.5721C145.209 99.572 143.433 98.8663 142.091 97.5994C140.749 96.3325 139.943 94.6004 139.837 92.7581L139.199 81.6951H154.909L154.271 92.7581C154.165 94.6004 153.359 96.3325 152.017 97.5994C150.675 98.8663 148.899 99.572 147.054 99.5721Z"
          fill="#E6E6E6"
        />
        <path d="M155.926 84.2101H138.185L137.677 79.6101H156.438L155.926 84.2101Z" fill="#E6E6E6" />
        <path
          d="M124 185.328C177.07 185.328 220.091 182.815 220.091 179.716C220.091 176.617 177.07 174.104 124 174.104C70.9304 174.104 27.909 176.617 27.909 179.716C27.909 182.815 70.9304 185.328 124 185.328Z"
          fill="#F5F5F5"
        />
        <path d="M125.939 174.353L121.801 174.819L120.275 165.435L124.413 164.969L125.939 174.353Z" fill="#FFC3BD" />
        <path d="M144.465 175.257H140.333L139.304 165.688H143.436L144.465 175.257Z" fill="#FFC3BD" />
        <path
          d="M139.993 174.776H145.03C145.112 174.776 145.192 174.804 145.255 174.855C145.319 174.907 145.364 174.978 145.382 175.058L146.198 178.731C146.217 178.821 146.216 178.913 146.194 179.002C146.173 179.091 146.131 179.174 146.073 179.245C146.015 179.315 145.941 179.372 145.858 179.41C145.775 179.449 145.685 179.468 145.593 179.466C143.972 179.438 142.793 179.343 140.749 179.343C139.493 179.343 135.705 179.473 133.971 179.473C132.271 179.473 132.01 177.758 132.72 177.603C135.904 176.903 138.303 175.946 139.32 175.03C139.505 174.865 139.745 174.774 139.993 174.776Z"
          fill="#263238"
        />
        <path
          d="M121.733 174.11L126.217 173.602C126.298 173.593 126.381 173.611 126.45 173.655C126.52 173.698 126.572 173.765 126.599 173.842L127.824 177.399C127.855 177.485 127.866 177.577 127.856 177.668C127.845 177.759 127.815 177.846 127.766 177.923C127.717 178.001 127.651 178.066 127.573 178.113C127.495 178.161 127.407 178.19 127.316 178.199C125.696 178.355 123.348 178.525 121.316 178.756C118.939 179.026 118.543 179.662 115.739 179.597C114.039 179.557 113.613 177.832 114.326 177.697C117.574 177.066 118.202 176.575 120.783 174.549C121.053 174.317 121.382 174.165 121.733 174.11Z"
          fill="#263238"
        />
        <path
          d="M125.383 53.8411C123.995 54.9871 122.629 55.9831 121.216 57.0031C119.803 58.0231 118.36 58.9701 116.875 59.8891C115.39 60.8081 113.866 61.6891 112.268 62.4891C110.634 63.3354 108.934 64.0452 107.183 64.6111C106.72 64.7591 106.269 64.8811 105.749 65.0111C105.241 65.1213 104.726 65.2038 104.209 65.2581C103.278 65.3581 102.402 65.4031 101.529 65.4431C99.789 65.5231 98.082 65.5211 96.377 65.5091C92.967 65.4681 89.586 65.3041 86.19 65.0041L86.139 61.9041C89.474 61.4751 92.824 61.1041 96.139 60.7311C97.796 60.5511 99.449 60.3491 101.064 60.1381C101.864 60.0291 102.664 59.9011 103.402 59.7641C103.719 59.7081 104.033 59.6343 104.342 59.5431C104.633 59.4431 104.99 59.3291 105.32 59.1961C106.737 58.6207 108.113 57.9478 109.437 57.1821C110.808 56.3951 112.18 55.5501 113.528 54.6521C114.876 53.7541 116.218 52.8241 117.548 51.8751C118.878 50.9261 120.207 49.9211 121.455 48.9751L125.383 53.8411Z"
          fill="#FFC3BD"
        />
        <path
          d="M129.422 50.3092C128.828 54.2152 120.604 60.1902 120.604 60.1902L114.704 52.8672C117.289 50.1089 120.029 47.5009 122.912 45.0562C125.567 42.8862 130.068 46.0592 129.422 50.3092Z"
          fill="#282A74"
        />
        <path
          d="M87.277 62.231L84.931 60.561L84.674 65.837C85.0286 65.838 85.378 65.7521 85.6918 65.5868C86.0056 65.4216 86.2741 65.182 86.474 64.889L87.277 62.231Z"
          fill="#FFC3BD"
        />
        <path d="M82.812 59.0251L82.332 64.1961L84.676 65.8371L84.933 60.5611L82.812 59.0251Z" fill="#FFC3BD" />
        <path
          opacity="0.2"
          d="M120.277 165.438L121.063 170.275L125.202 169.809L124.416 164.972L120.277 165.438Z"
          fill="black"
        />
        <path opacity="0.2" d="M143.438 165.689H139.304L139.836 170.621H143.97L143.438 165.689Z" fill="black" />
        <path
          d="M143.497 45.1812C143.805 45.2502 144.096 45.3801 144.352 45.5632C144.609 45.7463 144.827 45.9789 144.993 46.2473C145.158 46.5158 145.268 46.8147 145.317 47.1263C145.365 47.438 145.351 47.7562 145.274 48.0622C144.377 51.7837 143.702 55.5549 143.25 59.3562C142.859 62.5562 142.625 65.6132 142.479 68.3842C142.136 74.8792 142.279 79.8302 142.085 81.5082C138.854 81.2842 126.678 80.4372 120.039 79.9732C117.798 62.4292 120.424 51.2332 121.899 46.6842C122.125 45.9754 122.547 45.3453 123.118 44.8673C123.688 44.3893 124.382 44.083 125.119 43.9842C125.965 43.8732 126.977 43.7662 128.045 43.7032C128.419 43.6792 128.798 43.6662 129.184 43.6582C132.318 43.6953 135.448 43.913 138.557 44.3102C139.147 44.3802 139.743 44.4672 140.323 44.5602C141.482 44.7542 142.584 44.9772 143.497 45.1812Z"
          fill="#EDB561"
        />
        <path
          d="M138.366 33.697C137.566 36.671 136.588 42.155 138.558 44.315C133.448 47.752 130.186 52.591 129.245 51.54C128.752 50.99 128.538 44.625 129.186 43.661C132.605 43.093 132.692 40.592 132.27 38.208L138.366 33.697Z"
          fill="#FFC3BD"
        />
        <path
          d="M138.162 43.082C138.162 43.082 129.835 46.767 128.731 55.007C131.025 52.351 136.092 49.461 136.092 49.461L133.857 48.819C135.278 48.2796 136.75 47.8836 138.25 47.637C138.002 46.499 139.324 44.229 139.324 44.229L138.162 43.082Z"
          fill="#282A74"
        />
        <path
          d="M130.649 42.9492C130.649 42.9492 131.759 47.6552 128.732 55.0072C128.135 52.4412 127.317 49.9317 126.286 47.5072L128.325 48.0622C128.325 48.0622 128.225 47.1132 126.574 45.8512C127.262 44.8519 128.12 43.9811 129.109 43.2782L130.649 42.9492Z"
          fill="#282A74"
        />
        <path
          opacity="0.2"
          d="M135.881 35.5381L132.273 38.2061C132.38 38.7613 132.438 39.3248 132.446 39.8901C133.746 39.7901 135.606 38.5071 135.837 37.1721C135.95 36.6346 135.965 36.0809 135.881 35.5381Z"
          fill="black"
        />
        <path
          d="M127.785 25.0851C125.77 25.1721 124.658 28.6461 126.703 31.5071C128.748 34.3681 130.5 24.9681 127.785 25.0851Z"
          fill="#263238"
        />
        <path
          d="M137.722 28.1422C137.961 32.2362 138.309 34.6192 136.507 36.9522C133.797 40.4612 128.557 39.2032 127.1 35.2632C125.789 31.7172 125.618 25.6182 129.4 23.5232C130.232 23.0568 131.169 22.8109 132.123 22.8089C133.077 22.8068 134.015 23.0487 134.849 23.5116C135.683 23.9745 136.385 24.6429 136.888 25.4534C137.391 26.2638 137.678 27.1894 137.722 28.1422Z"
          fill="#FFC3BD"
        />
        <path
          d="M138.323 31.2881C137.228 30.6464 136.272 29.7933 135.511 28.7784C134.749 27.7636 134.197 26.6074 133.886 25.3771C132.686 25.6611 126.705 28.4131 124.866 25.3771C123.027 22.3411 125.341 20.5601 128.782 22.4291C127.26 19.7431 128.644 18.4291 133.426 18.3131C138.208 18.1971 137.565 20.9691 137.565 20.9691C137.565 20.9691 141.185 19.3931 142.465 22.1691C143.948 25.3591 141.212 30.8061 138.323 31.2881Z"
          fill="#263238"
        />
        <path
          d="M136.747 20.2361C136.791 20.2361 138.558 20.6611 140.594 18.6121C140.229 20.3601 137.067 21.5241 137.067 21.5241L136.747 20.2361Z"
          fill="#263238"
        />
        <path
          d="M139.123 26.225C137.216 25.566 134.907 28.391 135.76 31.802C136.613 35.213 141.692 27.113 139.123 26.225Z"
          fill="#263238"
        />
        <path
          d="M140.202 31.5472C140.027 32.5639 139.457 33.47 138.616 34.0672C137.495 34.8492 136.485 33.9552 136.416 32.6612C136.358 31.4972 136.87 29.6872 138.177 29.4202C138.464 29.3666 138.76 29.3855 139.037 29.4752C139.315 29.565 139.566 29.7227 139.767 29.934C139.968 30.1454 140.113 30.4036 140.189 30.6854C140.265 30.9671 140.27 31.2633 140.202 31.5472Z"
          fill="#FFC3BD"
        />
        <path
          d="M126.253 126.527C127.246 114.441 134.53 80.9841 134.53 80.9841L120.036 79.9731C120.036 79.9731 114.447 113.184 114.019 125.788C113.573 138.893 118.844 168.019 118.844 168.019L125.795 167.228C125.795 167.228 125.256 138.663 126.253 126.527Z"
          fill="#282A74"
        />
        <path
          opacity="0.5"
          d="M126.253 126.527C127.246 114.441 134.53 80.9841 134.53 80.9841L120.036 79.9731C120.036 79.9731 114.447 113.184 114.019 125.788C113.573 138.893 118.844 168.019 118.844 168.019L125.795 167.228C125.795 167.228 125.256 138.663 126.253 126.527Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M129.07 92.3142C126.148 96.0502 126.096 109.171 127.226 118.865C128.303 111.872 130 102.991 131.487 95.5882L129.07 92.3142Z"
          fill="black"
        />
        <path
          d="M127.857 80.5181C127.857 80.5181 129.421 115.893 131.166 127.873C133.076 140.992 137.927 169.665 137.927 169.665H145.495C145.495 169.665 144.25 142.659 143.251 129.77C142.114 115.089 142.083 81.5101 142.083 81.5101L127.857 80.5181Z"
          fill="#282A74"
        />
        <path
          opacity="0.5"
          d="M127.857 80.5181C127.857 80.5181 129.421 115.893 131.166 127.873C133.076 140.992 137.927 169.665 137.927 169.665H145.495C145.495 169.665 144.25 142.659 143.251 129.77C142.114 115.089 142.083 81.5101 142.083 81.5101L127.857 80.5181Z"
          fill="white"
        />
        <path d="M137.017 169.826H145.928V167.164L135.967 166.991L137.017 169.826Z" fill="#282A74" />
        <path d="M118.541 168.802L126.825 167.863L126.525 165.186L117.248 166.095L118.541 168.802Z" fill="#282A74" />
        <path
          d="M131.694 30.0192C131.734 30.3502 131.594 30.6392 131.374 30.6652C131.154 30.6912 130.95 30.4442 130.911 30.1132C130.872 29.7822 131.011 29.4932 131.231 29.4672C131.451 29.4412 131.655 29.6882 131.694 30.0192Z"
          fill="#263238"
        />
        <path
          d="M127.955 30.4682C127.995 30.7992 127.855 31.0882 127.635 31.1142C127.415 31.1402 127.211 30.8932 127.171 30.5622C127.131 30.2312 127.271 29.9422 127.491 29.9162C127.711 29.8902 127.915 30.1412 127.955 30.4682Z"
          fill="#263238"
        />
        <path
          d="M129.163 30.4902C128.89 31.549 128.473 32.5651 127.923 33.5102C128.168 33.6447 128.44 33.7239 128.719 33.7424C128.998 33.7608 129.278 33.7181 129.539 33.6172L129.163 30.4902Z"
          fill="#ED847E"
        />
        <path
          d="M132.711 28.6701C132.676 28.6806 132.639 28.6814 132.604 28.6724C132.569 28.6634 132.537 28.645 132.511 28.6191C132.346 28.4372 132.14 28.2971 131.91 28.2102C131.68 28.1233 131.432 28.0921 131.188 28.1191C131.137 28.1277 131.085 28.1163 131.042 28.0872C130.999 28.0581 130.97 28.0137 130.959 27.9631C130.954 27.9378 130.954 27.9117 130.959 27.8864C130.964 27.861 130.974 27.837 130.989 27.8156C131.003 27.7942 131.022 27.776 131.044 27.762C131.065 27.748 131.09 27.7385 131.115 27.7341C131.423 27.6941 131.737 27.7289 132.029 27.8354C132.321 27.9419 132.584 28.117 132.794 28.3461C132.83 28.3834 132.85 28.433 132.85 28.4846C132.85 28.5362 132.83 28.5859 132.794 28.6231C132.771 28.6455 132.742 28.6616 132.711 28.6701Z"
          fill="#263238"
        />
        <path
          d="M131.274 34.9372C131.746 34.838 132.187 34.6266 132.56 34.3209C132.933 34.0153 133.227 33.6244 133.417 33.1812C133.426 33.1547 133.425 33.1256 133.412 33.1003C133.4 33.0749 133.379 33.0555 133.352 33.0462C133.326 33.0369 133.296 33.0386 133.271 33.0508C133.246 33.063 133.226 33.0847 133.217 33.1112C133.035 33.5189 132.76 33.878 132.413 34.1598C132.067 34.4415 131.659 34.6379 131.223 34.7332C131.21 34.7363 131.197 34.742 131.186 34.75C131.174 34.758 131.165 34.7682 131.157 34.7799C131.15 34.7916 131.145 34.8046 131.143 34.8182C131.141 34.8318 131.141 34.8458 131.144 34.8592C131.147 34.8727 131.153 34.8854 131.161 34.8967C131.169 34.9079 131.179 34.9175 131.191 34.9248C131.202 34.9322 131.215 34.9371 131.229 34.9394C131.243 34.9417 131.257 34.9413 131.27 34.9382L131.274 34.9372Z"
          fill="#263238"
        />
        <path
          d="M126.196 29.3741C126.157 29.3872 126.114 29.3872 126.075 29.3741C126.027 29.3562 125.987 29.3203 125.964 29.2739C125.942 29.2275 125.938 29.1742 125.954 29.1251C126.048 28.8285 126.212 28.5589 126.432 28.3392C126.652 28.1194 126.922 27.9561 127.219 27.8631C127.269 27.8517 127.322 27.8602 127.366 27.8866C127.41 27.913 127.442 27.9555 127.456 28.0051C127.467 28.0551 127.459 28.1077 127.433 28.1518C127.407 28.1959 127.364 28.2282 127.315 28.2421C127.082 28.3199 126.87 28.4521 126.698 28.6277C126.525 28.8033 126.397 29.0173 126.324 29.2521C126.313 29.2809 126.296 29.3069 126.274 29.328C126.252 29.3492 126.225 29.3649 126.196 29.3741Z"
          fill="#263238"
        />
        <path
          d="M138.632 175.498C138.349 175.521 138.067 175.446 137.832 175.287C137.759 175.224 137.702 175.144 137.667 175.054C137.632 174.963 137.62 174.866 137.632 174.77C137.632 174.713 137.647 174.657 137.677 174.608C137.707 174.559 137.749 174.519 137.8 174.493C138.259 174.257 139.59 175.077 139.74 175.171C139.756 175.182 139.769 175.197 139.777 175.214C139.785 175.232 139.788 175.252 139.785 175.271C139.782 175.29 139.773 175.308 139.76 175.322C139.747 175.336 139.73 175.346 139.711 175.351C139.358 175.44 138.996 175.489 138.632 175.498ZM138.018 174.643C137.974 174.641 137.931 174.649 137.891 174.667C137.871 174.678 137.854 174.695 137.843 174.716C137.832 174.736 137.827 174.759 137.829 174.782C137.82 174.848 137.828 174.914 137.851 174.976C137.874 175.038 137.912 175.094 137.962 175.137C138.424 175.348 138.948 175.374 139.429 175.211C138.998 174.932 138.517 174.739 138.013 174.643H138.018Z"
          fill="#282A74"
        />
        <path
          d="M139.685 175.353C139.669 175.353 139.652 175.349 139.638 175.341C139.211 175.109 138.384 174.203 138.471 173.741C138.485 173.669 138.524 173.604 138.581 173.557C138.637 173.51 138.708 173.485 138.782 173.484C138.856 173.475 138.932 173.482 139.004 173.503C139.075 173.525 139.142 173.561 139.199 173.61C139.679 174.003 139.778 175.195 139.781 175.245C139.782 175.263 139.779 175.28 139.771 175.296C139.763 175.312 139.752 175.325 139.737 175.335C139.722 175.346 139.704 175.352 139.685 175.353ZM138.851 173.678H138.806C138.679 173.694 138.668 173.752 138.663 173.778C138.611 174.05 139.138 174.734 139.563 175.057C139.535 174.585 139.365 174.131 139.075 173.757C139.012 173.705 138.933 173.677 138.851 173.678Z"
          fill="#282A74"
        />
        <path
          d="M121.397 174.721L121.381 174.728C120.734 174.935 119.576 175.228 119.081 174.882C119.008 174.832 118.948 174.764 118.907 174.685C118.866 174.607 118.845 174.519 118.845 174.43C118.841 174.377 118.851 174.323 118.874 174.274C118.897 174.226 118.932 174.184 118.976 174.153C119.441 173.826 121.188 174.469 121.386 174.544C121.403 174.551 121.418 174.563 121.428 174.578C121.439 174.594 121.445 174.611 121.446 174.63C121.447 174.648 121.443 174.667 121.435 174.683C121.426 174.699 121.413 174.713 121.397 174.722V174.721ZM119.114 174.297L119.091 174.311C119.073 174.323 119.059 174.34 119.05 174.359C119.041 174.379 119.038 174.401 119.041 174.422C119.04 174.481 119.053 174.539 119.08 174.591C119.106 174.643 119.145 174.688 119.193 174.722C119.801 174.912 120.457 174.876 121.041 174.622C120.436 174.354 119.773 174.242 119.113 174.297H119.114Z"
          fill="#282A74"
        />
        <path
          d="M121.397 174.721C121.373 174.733 121.346 174.735 121.321 174.727C120.801 174.561 119.721 173.784 119.741 173.312C119.746 173.201 119.809 173.056 120.073 172.999C120.168 172.978 120.267 172.976 120.363 172.995C120.459 173.013 120.55 173.05 120.631 173.105C121.055 173.51 121.34 174.039 121.447 174.615C121.45 174.632 121.449 174.649 121.444 174.666C121.439 174.682 121.43 174.697 121.417 174.708C121.411 174.713 121.404 174.718 121.397 174.721ZM120.006 173.231C119.939 173.267 119.937 173.309 119.936 173.325C119.922 173.609 120.668 174.231 121.209 174.477C121.109 174.016 120.87 173.597 120.524 173.277C120.465 173.236 120.398 173.208 120.327 173.195C120.257 173.181 120.184 173.182 120.114 173.198C120.077 173.204 120.04 173.215 120.006 173.231Z"
          fill="#282A74"
        />
        <path
          d="M146.983 51.1412C146.878 52.7552 146.688 54.2582 146.491 55.8102C146.294 57.3622 146.029 58.8832 145.738 60.4182C145.162 63.5534 144.312 66.6319 143.197 69.6182L142.706 70.7752L142.582 71.0642L142.551 71.1362L142.5 71.2462L142.356 71.5312C142.166 71.8775 141.937 72.2015 141.675 72.4972C141.244 72.9823 140.74 73.3973 140.181 73.7272C139.728 73.9966 139.254 74.2271 138.762 74.4162C137.124 75.007 135.411 75.3645 133.674 75.4782C130.519 75.7138 127.348 75.5954 124.22 75.1252L124.333 72.0252L126.519 71.7782C127.252 71.6912 127.981 71.5782 128.706 71.4782C130.153 71.2652 131.585 71.0272 132.95 70.7102C134.213 70.4531 135.44 70.0463 136.606 69.4982C136.92 69.3661 137.196 69.1557 137.406 68.8872C137.419 68.8162 137.261 69.1272 137.447 68.6302L137.774 67.6742C138.575 64.9335 139.188 62.1414 139.608 59.3172C139.843 57.8792 140.044 56.4282 140.238 54.9732C140.432 53.5182 140.592 52.0332 140.745 50.6382L146.983 51.1412Z"
          fill="#FFC3BD"
        />
        <path
          d="M147.343 48.1451C149.366 51.5381 147.643 62.2021 147.643 62.2021L137.043 60.2431C137.043 60.2431 136.469 54.1431 138.487 49.4491C140.678 44.3401 144.892 44.0341 147.343 48.1451Z"
          fill="#282A74"
        />
        <path
          d="M121.365 81.6161L115.74 55.4521C115.47 54.3348 114.844 53.3356 113.957 52.6051C113.069 51.8746 111.968 51.4524 110.82 51.4021H89.267C90.4155 51.4521 91.5166 51.8743 92.4042 52.6048C93.2919 53.3353 93.9179 54.3347 94.188 55.4521L99.813 81.6161C100.083 82.7334 100.709 83.7326 101.596 84.4631C102.484 85.1936 103.585 85.6159 104.733 85.6661H126.285C125.137 85.6159 124.036 85.1936 123.148 84.4631C122.261 83.7326 121.635 82.7334 121.365 81.6161Z"
          fill="#263238"
        />
        <path
          opacity="0.7"
          d="M121.365 81.6161L115.74 55.4521C115.47 54.3348 114.844 53.3356 113.957 52.6051C113.069 51.8746 111.968 51.4524 110.82 51.4021H89.267C90.4155 51.4521 91.5166 51.8743 92.4042 52.6048C93.2919 53.3353 93.9179 54.3347 94.188 55.4521L99.813 81.6161C100.083 82.7334 100.709 83.7326 101.596 84.4631C102.484 85.1936 103.585 85.6159 104.733 85.6661H126.285C125.137 85.6159 124.036 85.1936 123.148 84.4631C122.261 83.7326 121.635 82.7334 121.365 81.6161Z"
          fill="#282A74"
        />
        <path
          d="M89.267 51.4021H110.819C109.716 51.4228 108.658 51.8457 107.844 52.5915C107.03 53.3373 106.517 54.3546 106.401 55.4521L100.81 116.252C100.693 117.35 100.18 118.367 99.3667 119.112C98.5532 119.858 97.4954 120.281 96.392 120.302H82.94C82.4218 120.312 81.9076 120.211 81.4317 120.005C80.9558 119.8 80.5293 119.495 80.1807 119.112C79.832 118.729 79.5694 118.275 79.4102 117.782C79.2511 117.289 79.1992 116.767 79.258 116.252L84.85 55.4521C84.9663 54.3547 85.4793 53.3376 86.2926 52.5919C87.106 51.8461 88.1637 51.423 89.267 51.4021Z"
          fill="#282A74"
        />
        <path
          d="M92.71 116.251C92.6512 116.766 92.7031 117.288 92.8623 117.781C93.0214 118.274 93.2841 118.728 93.6327 119.111C93.9813 119.495 94.4078 119.799 94.8837 120.004C95.3596 120.21 95.8738 120.311 96.392 120.301H74.84C74.3218 120.311 73.8075 120.21 73.3315 120.005C72.8556 119.8 72.429 119.495 72.0803 119.111C71.7316 118.728 71.469 118.274 71.3099 117.781C71.1508 117.288 71.099 116.766 71.158 116.251H92.71Z"
          fill="#263238"
        />
        <path
          opacity="0.7"
          d="M92.71 116.251C92.6512 116.766 92.7031 117.288 92.8623 117.781C93.0214 118.274 93.2841 118.728 93.6327 119.111C93.9813 119.495 94.4078 119.799 94.8837 120.004C95.3596 120.21 95.8738 120.311 96.392 120.301H74.84C74.3218 120.311 73.8075 120.21 73.3315 120.005C72.8556 119.8 72.429 119.495 72.0803 119.111C71.7316 118.728 71.469 118.274 71.3099 117.781C71.1508 117.288 71.099 116.766 71.158 116.251H92.71Z"
          fill="#282A74"
        />
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M102.666 58.214H88.082C87.9694 58.2156 87.8577 58.1931 87.7545 58.1482C87.6512 58.1033 87.5586 58.037 87.483 57.9535C87.4073 57.8701 87.3503 57.7716 87.3156 57.6644C87.281 57.5573 87.2695 57.444 87.282 57.332C87.3079 57.0933 87.4198 56.8721 87.5969 56.7098C87.7739 56.5475 88.0039 56.4552 88.244 56.45H102.826C102.939 56.4485 103.05 56.4709 103.154 56.5158C103.257 56.5607 103.349 56.6271 103.425 56.7105C103.501 56.7939 103.558 56.8925 103.592 56.9996C103.627 57.1068 103.638 57.2201 103.626 57.332C103.6 57.5704 103.488 57.7913 103.312 57.9536C103.135 58.1158 102.906 58.2084 102.666 58.214Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M102.232 62.985H87.648C87.5354 62.9866 87.4238 62.9641 87.3205 62.9192C87.2172 62.8743 87.1247 62.8079 87.049 62.7245C86.9734 62.6411 86.9163 62.5425 86.8817 62.4354C86.847 62.3282 86.8355 62.2149 86.848 62.103C86.874 61.8643 86.9859 61.6431 87.1629 61.4808C87.3399 61.3185 87.5699 61.2262 87.81 61.221H102.394C102.507 61.2195 102.618 61.2419 102.722 61.2868C102.825 61.3317 102.917 61.3981 102.993 61.4815C103.069 61.5649 103.126 61.6635 103.16 61.7706C103.195 61.8778 103.207 61.9911 103.194 62.103C103.168 62.3418 103.056 62.5631 102.879 62.7255C102.702 62.8878 102.472 62.9801 102.232 62.985Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M98.2231 67.756H87.2141C87.1015 67.7576 86.9898 67.7351 86.8866 67.6902C86.7833 67.6453 86.6907 67.5789 86.6151 67.4955C86.5394 67.4121 86.4823 67.3135 86.4477 67.2064C86.413 67.0992 86.4016 66.9859 86.4141 66.874C86.44 66.6353 86.5519 66.4141 86.7289 66.2518C86.906 66.0895 87.136 65.9972 87.3761 65.992H98.3851C98.4977 65.9905 98.6093 66.0129 98.7126 66.0578C98.8159 66.1027 98.9084 66.1691 98.9841 66.2525C99.0598 66.3359 99.1168 66.4345 99.1515 66.5416C99.1861 66.6488 99.1976 66.7621 99.1851 66.874C99.1591 67.1128 99.0472 67.3339 98.8702 67.4962C98.6932 67.6585 98.4632 67.7509 98.2231 67.756Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M90.5141 72.526H86.7811C86.6685 72.5276 86.5568 72.5052 86.4536 72.4602C86.3503 72.4153 86.2577 72.349 86.1821 72.2655C86.1064 72.1821 86.0494 72.0836 86.0147 71.9764C85.9801 71.8693 85.9686 71.756 85.9811 71.644C86.0068 71.4054 86.1185 71.1842 86.2954 71.0219C86.4722 70.8596 86.7021 70.7672 86.9421 70.762H90.6761C90.7887 70.7605 90.9004 70.7829 91.0036 70.8278C91.1069 70.8727 91.1995 70.9391 91.2751 71.0225C91.3508 71.106 91.4078 71.2045 91.4425 71.3117C91.4771 71.4188 91.4886 71.5321 91.4761 71.644C91.4502 71.8828 91.3383 72.104 91.1612 72.2662C90.9842 72.4285 90.7542 72.5209 90.5141 72.526Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M100.93 77.297H86.342C86.2294 77.2986 86.1178 77.2762 86.0145 77.2312C85.9112 77.1863 85.8187 77.12 85.743 77.0365C85.6673 76.9531 85.6103 76.8546 85.5756 76.7474C85.541 76.6403 85.5295 76.527 85.542 76.415C85.5679 76.1763 85.6799 75.9551 85.8569 75.7928C86.0339 75.6305 86.2639 75.5382 86.504 75.533H101.088C101.201 75.5315 101.312 75.5539 101.416 75.5988C101.519 75.6437 101.611 75.7101 101.687 75.7935C101.763 75.8769 101.82 75.9755 101.854 76.0827C101.889 76.1898 101.901 76.3031 101.888 76.415C101.862 76.6531 101.751 76.8737 101.575 77.0359C101.398 77.1982 101.169 77.2909 100.93 77.297Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M94.7531 82.068H85.913C85.8004 82.0696 85.6888 82.0472 85.5855 82.0022C85.4822 81.9573 85.3897 81.891 85.314 81.8076C85.2384 81.7241 85.1813 81.6256 85.1467 81.5184C85.112 81.4113 85.1006 81.298 85.1131 81.186C85.1388 80.9474 85.2505 80.7262 85.4273 80.5639C85.6042 80.4016 85.8341 80.3092 86.0741 80.304H94.9141C95.0267 80.3025 95.1383 80.3249 95.2416 80.3698C95.3449 80.4147 95.4374 80.4811 95.5131 80.5645C95.5887 80.6479 95.6458 80.7465 95.6804 80.8537C95.7151 80.9608 95.7265 81.0741 95.7141 81.186C95.6881 81.4246 95.5764 81.6456 95.3996 81.8079C95.2228 81.9702 94.993 82.0626 94.7531 82.068Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M100.063 86.838H85.4791C85.3665 86.8396 85.2548 86.8172 85.1516 86.7723C85.0483 86.7274 84.9557 86.661 84.8801 86.5776C84.8044 86.4941 84.7474 86.3956 84.7127 86.2884C84.6781 86.1813 84.6666 86.068 84.6791 85.9561C84.705 85.7173 84.8169 85.4961 84.994 85.3338C85.171 85.1715 85.401 85.0792 85.6411 85.074H100.226C100.339 85.0725 100.45 85.0949 100.554 85.1398C100.657 85.1848 100.749 85.2511 100.825 85.3345C100.901 85.4179 100.958 85.5165 100.992 85.6237C101.027 85.7308 101.039 85.8441 101.026 85.9561C101 86.195 100.888 86.4163 100.711 86.5786C100.534 86.7409 100.303 86.8331 100.063 86.838Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M96.054 91.6091H85.045C84.9324 91.6106 84.8207 91.5882 84.7175 91.5433C84.6142 91.4983 84.5217 91.432 84.446 91.3486C84.3703 91.2652 84.3133 91.1666 84.2786 91.0594C84.244 90.9523 84.2325 90.839 84.245 90.727C84.2709 90.4883 84.3829 90.2671 84.5599 90.1048C84.7369 89.9425 84.9669 89.8502 85.207 89.845H96.216C96.3286 89.8435 96.4403 89.8659 96.5436 89.9108C96.6468 89.9558 96.7394 90.0221 96.815 90.1055C96.8907 90.189 96.9477 90.2875 96.9824 90.3947C97.017 90.5018 97.0285 90.6151 97.016 90.727C96.9901 90.9658 96.8782 91.187 96.7012 91.3493C96.5241 91.5116 96.2941 91.6039 96.054 91.6091Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M88.3451 96.3791H84.6091C84.4965 96.3806 84.3848 96.3582 84.2816 96.3133C84.1783 96.2684 84.0857 96.202 84.0101 96.1186C83.9344 96.0352 83.8774 95.9366 83.8427 95.8295C83.8081 95.7223 83.7966 95.609 83.8091 95.4971C83.835 95.2583 83.9469 95.0372 84.124 94.8749C84.301 94.7126 84.531 94.6202 84.7711 94.6151H88.5091C88.6217 94.6135 88.7334 94.636 88.8366 94.6809C88.9399 94.7258 89.0325 94.7921 89.1081 94.8755C89.1838 94.959 89.2408 95.0575 89.2755 95.1647C89.3101 95.2718 89.3216 95.3851 89.3091 95.4971C89.2831 95.7362 89.1709 95.9576 88.9935 96.1199C88.8161 96.2823 88.5856 96.3744 88.3451 96.3791Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M98.7611 101.151H84.1771C84.0645 101.153 83.9528 101.13 83.8496 101.085C83.7463 101.04 83.6537 100.974 83.5781 100.891C83.5024 100.807 83.4454 100.709 83.4107 100.601C83.3761 100.494 83.3646 100.381 83.3771 100.269C83.403 100.03 83.5149 99.8091 83.692 99.6468C83.869 99.4845 84.099 99.3922 84.3391 99.387H98.9231C99.0357 99.3855 99.1473 99.4079 99.2506 99.4528C99.3539 99.4977 99.4464 99.5641 99.5221 99.6475C99.5978 99.7309 99.6548 99.8295 99.6895 99.9367C99.7241 100.044 99.7356 100.157 99.7231 100.269C99.6972 100.508 99.5853 100.729 99.4082 100.891C99.2312 101.054 99.0012 101.146 98.7611 101.151Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M92.584 105.921H83.744C83.6314 105.923 83.5197 105.9 83.4165 105.855C83.3132 105.81 83.2206 105.744 83.145 105.661C83.0693 105.577 83.0123 105.479 82.9776 105.371C82.943 105.264 82.9315 105.151 82.944 105.039C82.9697 104.8 83.0816 104.579 83.2586 104.417C83.4357 104.254 83.6658 104.162 83.906 104.157H92.745C92.8576 104.156 92.9692 104.178 93.0725 104.223C93.1758 104.268 93.2683 104.334 93.344 104.418C93.4197 104.501 93.4767 104.6 93.5114 104.707C93.546 104.814 93.5575 104.927 93.545 105.039C93.5191 105.278 93.4073 105.499 93.2305 105.661C93.0537 105.823 92.8239 105.916 92.584 105.921Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M97.8931 110.692H83.3091C83.1964 110.694 83.0848 110.671 82.9815 110.626C82.8782 110.581 82.7857 110.515 82.71 110.432C82.6344 110.348 82.5773 110.25 82.5427 110.142C82.508 110.035 82.4966 109.922 82.5091 109.81C82.535 109.571 82.6469 109.35 82.8239 109.188C83.0009 109.026 83.231 108.933 83.4711 108.928H98.0531C98.1657 108.927 98.2773 108.949 98.3806 108.994C98.4839 109.039 98.5764 109.105 98.6521 109.189C98.7277 109.272 98.7848 109.371 98.8194 109.478C98.8541 109.585 98.8655 109.698 98.853 109.81C98.8272 110.048 98.7155 110.269 98.5389 110.432C98.3623 110.594 98.1328 110.686 97.8931 110.692Z"
            fill="white"
          />
        </g>
        <path
          d="M125.604 72.3631L122.438 69.6121L120.954 74.6761C120.954 74.6761 124.17 76.4201 125.284 74.8191L125.604 72.3631Z"
          fill="#FFC3BD"
        />
        <path d="M118.989 69.4141L117.959 73.6581L120.951 74.6781L122.435 69.6131L118.989 69.4141Z" fill="#FFC3BD" />
      </svg>

      <div className="flex flex-col items-center">
        <h3 className="font-medium text-black-200">{t('no_subscriptions')}</h3>
        <span className="text-sm text-black-200 w-[251px] text-center">{t('no_subscriptions_yet')}</span>
      </div>
    </div>
  );
}
