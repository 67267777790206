import { ImgHTMLAttributes } from 'react';

import defaultImageViewer from '@assets/defaultImageViewer.png';

interface ImageViewerProps extends ImgHTMLAttributes<HTMLImageElement> {
  url: string;
  className?: string;
  previewImage?: string;
}

export default function ImageViewer({ url, className, previewImage, ...props }: Readonly<ImageViewerProps>) {
  return <img alt="" src={url || previewImage || defaultImageViewer} className={className} {...props} />;
}
