import tag from '@assets/icons/Category/icons/tag.svg';
import moon from '@assets/icons/Category/icons/moon.svg';
import piece from '@assets/icons/Category/icons/piece.svg';
import zap from '@assets/icons/Category/icons/zap.svg';
import bookmark from '@assets/icons/Category/icons/bookmark.svg';
import piggyBank from '@assets/icons/Category/icons/piggy-bank.svg';
import umbrella from '@assets/icons/Category/icons/umbrella.svg';
import sun from '@assets/icons/Category/icons/sun.svg';
import snowflake from '@assets/icons/Category/icons/snowflake.svg';
import alarmClock from '@assets/icons/Category/icons/alarm-clock.svg';
import star from '@assets/icons/Category/icons/star.svg';
import face from '@assets/icons/Category/icons/face.svg';
import like from '@assets/icons/Category/icons/like.svg';
import palette from '@assets/icons/Category/icons/palette.svg';
import hand from '@assets/icons/Category/icons/hand.svg';
import drop from '@assets/icons/Category/icons/drop.svg';
import lightbulb from '@assets/icons/Category/icons/lightbulb.svg';
import trophy from '@assets/icons/Category/icons/trophy.svg';
import graduation from '@assets/icons/Category/icons/graduation.svg';
import briefcase from '@assets/icons/Category/icons/briefcase.svg';
import key from '@assets/icons/Category/icons/key.svg';
import gift from '@assets/icons/Category/icons/gift.svg';
import music from '@assets/icons/Category/icons/music.svg';
import markerPin from '@assets/icons/Category/icons/marker-pin.svg';
import pin from '@assets/icons/Category/icons/pin.svg';
import bookClosed from '@assets/icons/Category/icons/book-closed.svg';
import rocket from '@assets/icons/Category/icons/rocket.svg';
import heart from '@assets/icons/Category/icons/heart.svg';
import globe from '@assets/icons/Category/icons/globe.svg';
import plane from '@assets/icons/Category/icons/plane.svg';

export function getCategoryImage(tag: string) {
  const category = categoriesDefault.find((category) => category.tag === tag);
  if (!category) {
    return '';
  }
  return category.icon;
}

export const colors = [
  {
    name: 'yellow',
    hex: '#EDB561',
  },
  {
    name: 'dark-blue',
    hex: '#282A74',
  },
  {
    name: 'green',
    hex: '#27AE60',
  },
  {
    name: 'pink',
    hex: '#E8228B',
  },
  {
    name: 'purple',
    hex: '#902687',
  },
  {
    name: 'blue',
    hex: '#2F80ED',
  },
];

export const categoriesDefault = [
  {
    tag: 'tag',
    icon: tag,
  },
  {
    tag: 'moon',
    icon: moon,
  },
  {
    tag: 'piece',
    icon: piece,
  },
  {
    tag: 'zap',
    icon: zap,
  },
  {
    tag: 'bookmark',
    icon: bookmark,
  },
  {
    tag: 'piggy-bank',
    icon: piggyBank,
  },
  {
    tag: 'umbrella',
    icon: umbrella,
  },
  {
    tag: 'sun',
    icon: sun,
  },
  {
    tag: 'snowflake',
    icon: snowflake,
  },
  {
    tag: 'alarm-clock',
    icon: alarmClock,
  },
  {
    tag: 'star',
    icon: star,
  },
  {
    tag: 'face',
    icon: face,
  },
  {
    tag: 'like',
    icon: like,
  },
  {
    tag: 'palette',
    icon: palette,
  },
  {
    tag: 'hand',
    icon: hand,
  },
  {
    tag: 'drop',
    icon: drop,
  },
  {
    tag: 'lightbulb',
    icon: lightbulb,
  },
  {
    tag: 'trophy',
    icon: trophy,
  },
  {
    tag: 'graduation',
    icon: graduation,
  },
  {
    tag: 'briefcase',
    icon: briefcase,
  },
  {
    tag: 'key',
    icon: key,
  },
  {
    tag: 'gift',
    icon: gift,
  },
  {
    tag: 'music',
    icon: music,
  },
  {
    tag: 'marker-pin',
    icon: markerPin,
  },
  {
    tag: 'pin',
    icon: pin,
  },
  {
    tag: 'book-closed',
    icon: bookClosed,
  },
  {
    tag: 'rocket',
    icon: rocket,
  },
  {
    tag: 'heart',
    icon: heart,
  },
  {
    tag: 'globe',
    icon: globe,
  },
  {
    tag: 'plane',
    icon: plane,
  },
];
