import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Group, useGroups } from '@hooks/useGroups';
import { useInsights } from '@hooks/useInsights';
import { useUser } from '@hooks/useUser';

import loadingGif from '@assets/icons/loading.gif';
import { PaginationComponent } from '@components/Pagination';
import { useTranslation } from 'react-i18next';

import { DEFAULT_PHOTO } from '../../../utils/defaultPhoto';

export default function ShareInsightGroups() {
  const { t } = useTranslation();
  const [isInsightValid, setIsInsightValid] = useState(false);
  const [search, setSearch] = useState('');
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [page, setPage] = useState<{ current: number; next: number[]; previous: number[] }>();
  const [first, setFirst] = useState<number>(0);

  const { creatingInsight, editInsight } = useInsights();
  const { getGroups, groups, pagination, createGroup } = useGroups();
  const { user } = useUser();

  const searchInput = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const getAllGroups = useCallback(async () => {
    setLoadingGroups(true);
    let groupsList: Group[] = [];
    if (user?.is_super_admin === 1) {
      groupsList = await getGroups('?take=10');
    } else {
      groupsList = await getGroups('?take=10&status=published');
    }
    if (groupsList?.length === 0) {
      setFirst((prev) => prev + 1);
      return;
    }
    setLoadingGroups(false);
  }, [getGroups, user?.is_super_admin]);

  useEffect(() => {
    if (creatingInsight.title && creatingInsight.collections.length > 0) setIsInsightValid(true);
    else return;
    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = '';

      const confirmationMessage = t('are_you_sure_you_want_to_leave_all_unsaved_data_will_be_lost');
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [creatingInsight, t]);

  useEffect(() => {
    getAllGroups();
  }, [getAllGroups]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (user?.is_super_admin === 1) {
        getGroups('?take=10&search=' + search);
      } else {
        getGroups('?take=10&status=published&search=' + search);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [getGroups, search, user?.is_super_admin]);

  async function handleSelectGroup(groupUuid: string, groupName: string) {
    editInsight({ groupUuid, groupName });
    navigate('/insight/create/tags-and-description?previous=insightGroups');
  }

  const createNewGroupForUser = useCallback(async () => {
    const groupName = t('group_of').replace(':name', user?.first_name || '');
    const createdGroup = await createGroup(groupName);
    editInsight({ groupUuid: createdGroup?.uuid, groupName });
    navigate('/insight/create/tags-and-description?previous=insightGroups');
  }, [createGroup, editInsight, navigate, user, t]);

  useEffect(() => {
    if (first === 1) {
      createNewGroupForUser();
    }
  }, [createNewGroupForUser, first]);

  async function handleSelectPage(selectedPage: number) {
    setLoadingGroups(true);
    if (user?.is_super_admin === 1) await getGroups(`?take=10&page=${selectedPage}`);
    else await getGroups(`?take=10&status=published&page=${selectedPage}`);
    setLoadingGroups(false);
  }

  return (
    <>
      {!isInsightValid ? (
        <></>
      ) : (
        <div className="flex justify-center w-full py-20 relative">
          <div className="flex flex-col w-[550px]">
            <h2 className="text-black-100 text-xl font-medium mb-5">
              {t('where_would_you_like_to_share_this_insight')}
            </h2>
            <div className="flex items-center border border-primary-200 bg-grey-600 rounded-md px-5 py-5 w-full mb-5">
              <svg
                className="cursor-pointer"
                onClick={() => searchInput.current?.focus()}
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M18 17.5L13 12.5M14.6667 8.33333C14.6667 11.555 12.055 14.1667 8.83333 14.1667C5.61167 14.1667 3 11.555 3 8.33333C3 5.11167 5.61167 2.5 8.83333 2.5C12.055 2.5 14.6667 5.11167 14.6667 8.33333Z"
                  stroke="#282A74"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                ref={searchInput}
                type="text"
                placeholder={t('search_for_name')}
                className="bg-transparent outline-none border-none w-full h-full ml-2 text-sm"
              />
            </div>
            <div className="flex flex-col gap-5">
              {loadingGroups ? (
                <div className="flex items-center justify-center py-5">
                  <img src={loadingGif} alt="Loading" className="w-12" />
                </div>
              ) : (
                <>
                  {groups?.length === 0 && (
                    <div className="flex items-center justify-center py-5">{t('group_not_found')}</div>
                  )}
                  {groups.map((group) => (
                    <button
                      key={group.uuid}
                      data-test="create-insight-group"
                      onClick={() => handleSelectGroup(group.uuid, group.name)}
                      className="flex items-center justify-between w-full border-b border-grey-600 px-5 py-1 hover:opacity-70"
                    >
                      <p>{group.name}</p>
                      <div className="flex items-center w-[120px] justify-end">
                        {group?.last4Members &&
                          group.last4Members.map((member, index) => {
                            if (index < 4) {
                              return (
                                <ImageWithFallback
                                  key={index}
                                  className="min-w-12 min-h-12 max-w-12 max-h-12 rounded-full ml-[-28px] object-cover"
                                  src={member.image ?? DEFAULT_PHOTO}
                                  alt={member.first_name}
                                />
                              );
                            } else if (group?.users_count && group?.users_count > 3) {
                              return (
                                <span
                                  key={member.first_name}
                                  className="min-w-12 min-h-12 max-w-12 max-h-12 rounded-full relative ml-[-28px]"
                                >
                                  <ImageWithFallback
                                    key={index}
                                    alt={member.first_name}
                                    className=" brightness-70 object-cover"
                                    src={member.image ?? DEFAULT_PHOTO}
                                  />
                                  <p className="text-white absolute top-[12px] right-[12px] text-xs font-light">
                                    {group?.users_count - 4}
                                  </p>
                                </span>
                              );
                            }
                          })}
                      </div>
                    </button>
                  ))}
                </>
              )}
            </div>
          </div>
          {groups?.length > 0 && (
            <PaginationComponent
              pagination={pagination}
              handleSelectPage={handleSelectPage}
              page={page}
              setPage={setPage}
            />
          )}
        </div>
      )}
    </>
  );
}

export const ImageWithFallback = ({ src, alt, className }: any) => {
  const [errored, setErrored] = useState(false);

  // Função para lidar com o erro de carregamento da imagem
  const handleImageError = () => {
    setErrored(true);
  };

  return <img src={errored ? DEFAULT_PHOTO : src} className={className} onError={handleImageError} alt={alt} />;
};
