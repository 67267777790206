import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as z from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';

import { Button } from '@components/Button';

import logo from '@assets/logo.svg';

import { PasswordInfo } from '@components/Auth/PasswordInfo';
import { Input } from '@components/Input';
import { isMobile } from 'react-device-detect';
import { api } from 'services/axios';
import { toast } from 'utils/toast';

const passwordResetSchema = z.object({
  password: z.string(),
  confirmPassword: z.string(),
});

type passwordResetInputs = z.infer<typeof passwordResetSchema>;

export default function ResetPasswordPage() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<passwordResetInputs>({
    resolver: zodResolver(passwordResetSchema),
  });
  const { t } = useTranslation();
  const [values, setValues] = useState<string[]>(['', '', '', '']);
  const [step, setStep] = useState<'begin' | 'twoFactor' | 'reset'>('begin');
  const [disableResetButton, setDisableResetButton] = useState(false);

  const codeInput0 = useRef<HTMLInputElement>(null);
  const codeInput1 = useRef<HTMLInputElement>(null);
  const codeInput2 = useRef<HTMLInputElement>(null);
  const codeInput3 = useRef<HTMLInputElement>(null);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get('token');
  const emailOrPhone = searchParams.get('email_or_phone');
  const code = searchParams.get('code');

  useEffect(() => {
    if (code) {
      if (isMobile) {
        const scheme = import.meta.env.VITE_APP_SCHEME ?? 'com.noiteminente.familywisdom://';
        window.location.replace(`${scheme}menu-check-email/${code}/${emailOrPhone}/${token}`);
      } else {
        const arrayCode = code.split('');
        setValues(arrayCode);
      }
    }
  }, [code, emailOrPhone, token]);

  useEffect(() => {
    if (token && emailOrPhone) {
      setStep('twoFactor');
    }
  }, [emailOrPhone, token]);

  async function handleBeginResetPassword() {
    setDisableResetButton(true);
    try {
      await api.post('/auth/reset-password-internal');
      setDisableResetButton(false);
      toast({
        label: t('success'),
        message: t('code_sent_to_your_email'),
        type: 'success',
      });
    } catch (error: any) {
      setDisableResetButton(false);
      toast({
        label: t('error'),
        message: error.response.data.message,
        type: 'error',
      });
    }
  }

  function handleInputChange(value: string, position: number) {
    let number = value;
    if (value.length === 4) {
      const intValue = parseInt(value, 10);
      if (intValue.toString().length >= 4) {
        const newArray = [...values];
        newArray[0] = intValue.toString()[0];
        newArray[1] = intValue.toString()[1];
        newArray[2] = intValue.toString()[2];
        newArray[3] = intValue.toString()[3];
        setValues(newArray);
      }
      return;
    }
    if (value.length > 1 && values[position] === value[0]) {
      number = value[1];
    } else if (values.length > 1 && values[position] === value[1]) {
      number = value[0];
    }
    if (/^\d*$/.test(number)) {
      const intValue = parseInt(number, 10);
      if (intValue >= 0 && intValue <= 9) {
        const newArray = [...values];
        newArray[position] = intValue.toString();
        setValues(newArray);
      }
    }
  }

  async function handleCofirmTwoFactorCode() {
    if (!emailOrPhone || values.length !== 4) {
      toast({
        label: t('error'),
        message: t('invalid_code_or_email'),
        type: 'error',
      });
      return;
    }

    if (!values[0] || !values[1] || !values[2] || !values[3]) {
      toast({
        label: t('error'),
        message: t('invalid_code'),
        type: 'error',
      });
      return;
    }

    try {
      setDisableResetButton(true);
      const code = values[0] + values[1] + values[2] + values[3];
      await api.post('/auth/confirm-reset-password-code', {
        code,
        email_or_phone: emailOrPhone,
      });
      setDisableResetButton(false);
      toast({
        label: t('success'),
        message: t('code_confirmed'),
        type: 'success',
      });
      setStep('reset');
    } catch (error: any) {
      toast({
        label: 'Error',
        message: error?.response?.data?.message || 'Invalid code',
        type: 'error',
      });
      setDisableResetButton(false);
    }
  }

  async function handleChangePassword(data: passwordResetInputs) {
    const { password, confirmPassword } = data;
    if (!emailOrPhone || !token) return;
    if (password !== confirmPassword) {
      toast({
        label: t('error'),
        message: t('password_dont_match'),
        type: 'error',
      });
      return;
    }
    try {
      await api.post('/auth/reset-password', {
        password: password,
        password_confirmation: confirmPassword,
        email_or_phone: emailOrPhone,
        token: token,
      });
      toast({
        label: t('success'),
        message: t('account_update_success'),
        type: 'success',
      });
      navigate('/');
    } catch (error) {
      toast({
        label: t('error'),
        message: t('failed_to_reset_password'),
        type: 'error',
      });
    }
  }

  const handleSetValues = useCallback((value: number) => {
    setValues((prev) => {
      const newArray = [...prev];
      newArray[value] = '';
      return newArray;
    });
  }, []);
  return (
    <div className="flex justify-center py-14">
      <form
        onSubmit={handleSubmit(handleChangePassword)}
        className="flex flex-col gap-5 items-center w-[400px] bg-white p-8 shadow-md border border-gray-100"
      >
        <h1 className="text-primary-200 text-xl font-bold text-center">{t('reset_password')}</h1>
        <img className="shadow-md rounded-lg" width={48} height={48} src={logo} alt="Logo" />
        {step === 'begin' && (
          <>
            <span className="text-sm text-primary-200 text-center mt-5">{t('start_password_reset')}</span>

            <div className="mt-5">
              <Button
                text={t('begin')}
                disabled={disableResetButton}
                onClick={handleBeginResetPassword}
                size="medium"
                variant="primary"
                type="button"
              />
            </div>
          </>
        )}
        {step === 'twoFactor' && (
          <div className="text-start flex flex-col w-full mt-10">
            <h1 className="text-primary-200 text-lg font-semibold mb-5">{t('type_two_factor_code')}</h1>
            <div className="flex flex-col w-full gap-5">
              <div className="flex gap-2 justify-center items-center">
                <input
                  type="number"
                  value={values[0]}
                  required
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace') {
                      setValues((prev) => {
                        const newArray = [...prev];
                        newArray[0] = '';
                        return newArray;
                      });
                    } else if (e.key === 'Delete') {
                      handleSetValues(0);
                    }
                  }}
                  onChange={(e) => {
                    handleInputChange(e.target.value, 0);
                    codeInput1.current?.focus();
                  }}
                  ref={codeInput0}
                  className="text-center text-primary-200 py-[10px] border-2 rounded-[3px] border-primary-200 max-w-[50px] text-3xl font-semibold placeholder:text-primary-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                />
                <input
                  type="number"
                  value={values[1]}
                  required
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace') {
                      setValues((prev) => {
                        const newArray = [...prev];
                        newArray[1] = '';
                        return newArray;
                      });
                    } else if (e.key === 'Delete') {
                      handleSetValues(1);
                    }
                  }}
                  onChange={(e) => {
                    handleInputChange(e.target.value, 1);
                    codeInput2.current?.focus();
                  }}
                  ref={codeInput1}
                  className="text-center text-primary-200 py-[10px] border-2 rounded-[3px] border-primary-200 max-w-[50px] text-3xl font-semibold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                />
                <input
                  type="number"
                  value={values[2]}
                  required
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace') {
                      setValues((prev) => {
                        const newArray = [...prev];
                        newArray[2] = '';
                        return newArray;
                      });
                    } else if (e.key === 'Delete') {
                      handleSetValues(2);
                    }
                  }}
                  onChange={(e) => {
                    handleInputChange(e.target.value, 2);
                    codeInput3.current?.focus();
                  }}
                  ref={codeInput2}
                  className="text-center text-primary-200 py-[10px] border-2 rounded-[3px] border-primary-200 max-w-[50px] text-3xl font-semibold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                />
                <input
                  type="number"
                  value={values[3]}
                  required
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace') {
                      setValues((prev) => {
                        const newArray = [...prev];
                        newArray[3] = '';
                        return newArray;
                      });
                    } else if (e.key === 'Delete') {
                      handleSetValues(3);
                    }
                  }}
                  onChange={(e) => {
                    handleInputChange(e.target.value, 3);
                    codeInput3.current?.blur();
                  }}
                  ref={codeInput3}
                  className="text-center text-primary-200 py-[10px] border-2 rounded-[3px] border-primary-200 max-w-[50px] text-3xl font-semibold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                />
              </div>
              <div className="flex flex-col justify-center items-center mt-5">
                <Button
                  disabled={disableResetButton}
                  onClick={handleCofirmTwoFactorCode}
                  text={t('continue')}
                  size="large"
                  variant="primary"
                />
              </div>
            </div>
          </div>
        )}

        {step === 'reset' && (
          <div className="text-start flex flex-col w-full mt-10">
            <h1 className="text-primary-200 text-lg font-semibold mb-5">{t('type_new_password')}</h1>
            <div className="flex flex-col w-full gap-5">
              <Input
                {...register('password')}
                type="password"
                identificator="password"
                label={t('new_password')}
                required
              />
              <Input
                {...register('confirmPassword')}
                type="password"
                identificator="confirmPassword"
                label={t('confirm_password')}
                required
              />
              <PasswordInfo />
              <div className="flex items-center justify-center mt-10">
                <Button
                  disabled={isSubmitting}
                  text={t('change_password')}
                  size="medium"
                  variant="primary"
                  type="submit"
                />
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}
