import * as Popover from '@radix-ui/react-popover';
import { endOfMonth, format, isLastDayOfMonth, parse, subDays, subMonths } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import { useDashboard } from '@hooks/useDashboard';
import { useUser } from '@hooks/useUser';

import { Button } from '@components/Button';
import DashboardCard from '@components/DashboardCard';
import DayRangePicker from '@components/DayRangePicker';
import NoPermission from '@components/EmptyStates/NoPermissions';
import Loading from '@components/Loading';

import box from '@assets/icons/Dashboard/box.svg';
import calendar from '@assets/icons/Dashboard/calendar.svg';
import camera from '@assets/icons/Dashboard/camera.svg';
import categories from '@assets/icons/Dashboard/categories.svg';
import groups from '@assets/icons/Dashboard/groups.svg';
import insight from '@assets/icons/Dashboard/insight.svg';

import { X } from '@phosphor-icons/react';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { toast } from 'utils/toast';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: true,
      position: 'bottom' as const,
      align: 'start' as const,
      labels: {
        font: {
          size: 14 as const,
          family: 'Poppins' as const,
        },
      },
    },
  },
  scales: {
    members: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
      ticks: {
        font: {
          size: 8 as const,
          family: 'Poppins' as const,
        },
      },
    },
    x: {
      ticks: {
        font: {
          size: 8 as const,
          family: 'Poppins' as const,
        },
      },
    },
  },
};

export default function DashboardPage() {
  const { t } = useTranslation();
  const [initialDate, setInitialDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [datePicker, setDatePicker] = useState<'today' | '3days' | 'week' | 'month' | null>(null);

  const [date1, setDate1] = useState<Date | null>(null);
  const [date2, setDate2] = useState<Date | null>(null);

  const [hasNoPermission, sethasNoPermission] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [showInitialDate, setShowInitialDate] = useState<string>('');
  const [showEndDate, setShowEndDate] = useState<string>('');

  const { getUserDetails, user } = useUser();
  const { getDasboard, dashboard, downloadDashboard } = useDashboard();

  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    Object.keys(dashboard['groups-subscriptions'].free).map((label) => {
      const dateArray = label.split('-');
      let labelFormatted = '';
      if (dateArray.length === 2) {
        labelFormatted = `${dateArray[1]}/${dateArray[0]}`;
      } else if (dateArray.length === 3) {
        if (user?.language === 'pt') labelFormatted = `${dateArray[2]}/${dateArray[1]}`;
        else labelFormatted = `${dateArray[1]}/${dateArray[2]}`;
      } else {
        labelFormatted = dateArray[0];
      }
      setLabels((prev) => {
        if (!prev.includes(labelFormatted)) {
          return [...prev, labelFormatted];
        }
        return prev;
      });
    });
  }, [dashboard, user?.language]);

  const data = {
    labels,
    datasets: [
      {
        label: 'Free',
        data: Object.values(dashboard['groups-subscriptions'].free).map((value) => {
          return value;
        }),
        borderColor: '#282A74',
        backgroundColor: '#282A74',
        yAxisID: 'members',
      },
      {
        label: 'Premium',
        data: Object.values(dashboard['groups-subscriptions'].premium).map((value) => {
          return value;
        }),
        borderColor: '#EDB561',
        backgroundColor: '#EDB561',
        yAxisID: 'members',
      },
    ],
  };

  const verifyUserPermissions = useCallback(async () => {
    const nowDt = new Date();
    const initialDate = subDays(nowDt, 7);
    const endDate = subDays(nowDt, 1);
    setInitialDate(format(initialDate, 'dd-MM-yyyy'));
    setEndDate(format(endDate, 'dd-MM-yyyy'));
    setDatePicker('week');
    await Promise.all([
      getUserDetails(user?.uuid ?? ''),
      getDasboard(format(initialDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd')),
    ]).then((data) => {
      const { userDetail } = data[0];
      if (userDetail && userDetail.is_super_admin === 1) {
        sethasNoPermission(false);
      }
      setIsLoading(false);
    });
  }, [getDasboard, getUserDetails, user?.uuid]);

  useEffect(() => {
    verifyUserPermissions();
  }, [verifyUserPermissions]);

  useEffect(() => {
    if (initialDate) {
      const date = parse(initialDate, 'dd-MM-yyyy', new Date());
      const formattedDate = user?.language === 'pt' ? format(date, 'dd/MM/yyyy') : format(date, 'MM/dd/yyyy');
      setShowInitialDate(formattedDate);
      setDate1(date);
    } else {
      setDate1(null);
      setShowInitialDate('');
    }
  }, [initialDate, user?.language]);

  useEffect(() => {
    if (endDate) {
      const date = parse(endDate, 'dd-MM-yyyy', new Date());
      const formattedDate = user?.language === 'pt' ? format(date, 'dd/MM/yyyy') : format(date, 'MM/dd/yyyy');
      setShowEndDate(formattedDate);
      const nowDt = new Date();
      setDate2(date);
      if (!date1) return;
      if (date.getFullYear() === nowDt.getFullYear()) {
        if (date.getMonth() === nowDt.getMonth()) {
          if (date.getDate() === nowDt.getDate() && date.getDate() === date1.getDate()) {
            setDatePicker('today');
            return;
          } else if (
            date.getDate() === subDays(nowDt, 1).getDate() &&
            date1.getDate() === subDays(nowDt, 3).getDate()
          ) {
            setDatePicker('3days');
            return;
          } else if (
            date.getDate() === subDays(nowDt, 1).getDate() &&
            date1.getDate() === subDays(nowDt, 7).getDate()
          ) {
            setDatePicker('week');
            return;
          } else {
            setDatePicker(null);
          }
        } else if (
          date.getMonth() === subMonths(nowDt, 1).getMonth() &&
          date1.getDate() === 1 &&
          isLastDayOfMonth(date)
        ) {
          setDatePicker('month');
          return;
        }
      }
    } else {
      setDate2(null);
      setShowEndDate('');
    }
  }, [endDate, date1, user?.language]);

  function handleDatePickerChange(when: 'today' | '3days' | 'week' | 'month') {
    if (datePicker === when) {
      setDatePicker(null);
      setInitialDate('');
      setEndDate('');
    } else {
      setDatePicker(when);
      const nowDt = new Date();
      if (when === 'today') {
        setInitialDate(format(nowDt, 'dd-MM-yyyy'));
        setEndDate(format(nowDt, 'dd-MM-yyyy'));
      } else if (when === '3days') {
        const initialDate = subDays(nowDt, 3);
        const endDate = subDays(nowDt, 1);
        setInitialDate(format(initialDate, 'dd-MM-yyyy'));
        setEndDate(format(endDate, 'dd-MM-yyyy'));
      } else if (when === 'week') {
        const initialDate = subDays(nowDt, 7);
        const endDate = subDays(nowDt, 1);
        setInitialDate(format(initialDate, 'dd-MM-yyyy'));
        setEndDate(format(endDate, 'dd-MM-yyyy'));
      } else {
        const lastMonth = subMonths(nowDt, 1);
        const initialDate = subMonths(nowDt, 1);
        initialDate.setDate(1);
        const lastDayOfLastMonth = endOfMonth(lastMonth); //
        setInitialDate(format(initialDate, 'dd-MM-yyyy'));
        setEndDate(format(lastDayOfLastMonth, 'dd-MM-yyyy'));
      }
    }
  }

  async function handleChangeDashboard() {
    setLabels([]);
    const initialDataArray = initialDate.split('-');
    const endDateArray = endDate.split('-');
    const initialDateFormatted = initialDataArray[2] + '-' + initialDataArray[1] + '-' + initialDataArray[0];
    let endDateFormatted = endDateArray[2] + '-' + endDateArray[1] + '-' + endDateArray[0];
    if (initialDateFormatted.includes('undefined') && endDateFormatted.includes('undefined')) {
      toast({
        label: t('error'),
        message: t('please_select_an_date_range'),
        type: 'error',
      });
      return;
    }
    if (endDateFormatted.includes('undefined')) {
      endDateFormatted = initialDateFormatted;
      setEndDate(initialDate);
    }
    setIsLoading(true);
    await getDasboard(initialDateFormatted, endDateFormatted);
    setIsLoading(false);
  }

  async function handleDownloadDashboard() {
    if (!initialDate || !endDate) {
      toast({
        label: t('error'),
        message: t('please_fill_all_fields'),
        type: 'error',
      });
      return;
    }
    const initialDataArray = initialDate.split('-');
    const endDateArray = endDate.split('-');
    const initialDateFormatted = initialDataArray[2] + '-' + initialDataArray[1] + '-' + initialDataArray[0];
    const endDateFormatted = endDateArray[2] + '-' + endDateArray[1] + '-' + endDateArray[0];
    downloadDashboard(initialDateFormatted, endDateFormatted);
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {hasNoPermission ? (
            <div className="py-20">
              <NoPermission />
            </div>
          ) : (
            <div className="relative">
              <header className="flex items-center justify-end gap-3 w-full bg-white py-2 px-20 pr-[300px] fixed z-[1]">
                <span className="bg-primary-200 bg-opacity-10 flex items-center gap-3 text-primary-200 px-3 py-2">
                  <p>{showInitialDate}</p>
                  <p>-</p>
                  <p>{showEndDate}</p>
                </span>
                <Popover.Root>
                  <Popover.Trigger asChild>
                    <button className="px-3 py-2 bg-primary-200 text-white flex items-center gap-3 rounded-md hover:opacity-90">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path
                          d="M16.5698 2.03125H3.39845C2.0337 2.03125 1.33546 3.68164 2.31935 4.63379L7.46095 9.77539V15.2344C7.46095 15.7422 7.68312 16.1865 8.06398 16.5039L10.0952 18.0273C11.0791 18.6938 12.5391 18.0591 12.5391 16.7895V9.77539L17.6489 4.63379C18.6328 3.68164 17.9346 2.03125 16.5698 2.03125ZM11.0156 9.14062V16.7578L8.98439 15.2344V9.14062L3.39845 3.55469H16.6016L11.0156 9.14062Z"
                          fill="white"
                        />
                      </svg>
                      <p>{t('filter')}</p>
                    </button>
                  </Popover.Trigger>
                  <Popover.Content className="PopoverContent" sideOffset={5}>
                    <div className="relative flex flex-col bg-white p-8 overflow-y-scroll">
                      <h1 className="text-primary-200 font-bold text-3xl mt-3 mb-8">{t('time_frame')}</h1>
                      <span className="flex items-center gap-5 mb-8">
                        <input
                          checked={datePicker === 'today'}
                          onChange={() => handleDatePickerChange('today')}
                          className="w-6 h-6"
                          type="checkbox"
                        />
                        <p className="text-primary-200 text-lg">{t('today')}</p>
                      </span>
                      <span className="flex items-center gap-5 mb-8">
                        <input
                          checked={datePicker === '3days'}
                          onChange={() => handleDatePickerChange('3days')}
                          className="w-6 h-6"
                          type="checkbox"
                        />
                        <p className="text-primary-200 text-lg">{t('last_3_days')}</p>
                      </span>
                      <span className="flex items-center gap-5 mb-8">
                        <input
                          checked={datePicker === 'week'}
                          onChange={() => handleDatePickerChange('week')}
                          className="w-6 h-6"
                          type="checkbox"
                        />
                        <p className="text-primary-200 text-lg">{t('last_week')}</p>
                      </span>
                      <span className="flex items-center gap-5 mb-8">
                        <input
                          checked={datePicker === 'month'}
                          onChange={() => handleDatePickerChange('month')}
                          className="w-6 h-6"
                          type="checkbox"
                        />
                        <p className="text-primary-200 text-lg">{t('last_month')}</p>
                      </span>
                      <div className="flex flex-col items-center">
                        <DayRangePicker
                          initialDate={initialDate}
                          setInitialDate={setInitialDate}
                          endDate={endDate}
                          setEndDate={setEndDate}
                          date1={date1}
                          date2={date2}
                        />
                        <div className="flex items-center gap-10 mt-8">
                          <Button
                            onClick={() => {
                              setInitialDate('');
                              setEndDate('');
                              setDatePicker(null);
                            }}
                            variant="primary"
                            size="medium"
                            text={t('clean')}
                          />
                          <Button onClick={handleChangeDashboard} variant="primary" size="medium" text={t('apply')} />
                        </div>
                      </div>
                      <Popover.Arrow />
                      <Popover.Close className={'absolute top-3 left-3 hover:opacity-70'}>
                        <X size={20} />
                      </Popover.Close>
                    </div>
                  </Popover.Content>
                </Popover.Root>
                <button
                  onClick={handleDownloadDashboard}
                  className="px-3 py-2 bg-primary-200 text-white flex items-center gap-3 rounded-md hover:opacity-90"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M17.5 17.5H2.5M15 9.16667L10 14.1667M10 14.1667L5 9.16667M10 14.1667V2.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p>{t('report')}</p>
                </button>
              </header>
              <main className="w-full flex flex-col justify-center items-center bg-backgray py-28 px-5">
                <div className="max-w-[1000px]">
                  <section className="flex items-center flex-wrap tablet:justify-center desktop:justify-between w-full gap-y-8">
                    {/* Gráfico */}
                    <div className="px-10 py-5 bg-white flex flex-col h-[350px] max-w-[60vw] rounded-md">
                      <span className="flex items-center gap-3 mb-5">
                        <img src={groups} alt="groups" />
                        <h2 className="text-primary-200 font-bold">{t('groups_details')}</h2>
                      </span>
                      <Line style={{ height: '90%' }} options={options} data={data} />
                    </div>

                    {/* Storage */}
                    <div className="p-8 h-[350px] min-w-[350px] bg-white flex flex-col items-center rounded-md">
                      <h3 className="text-primary-200 text-xl font-bold mb-3">{t('storage')}</h3>
                      <h1 className="text-5xl text-primary-200 mb-3">{dashboard?.storage?.total || '0 GB'}</h1>
                      <span className="w-full h-[1px] bg-[#DDE2E4] my-6" />
                      <p className="text-primary-100 mb-3">{t('average')}</p>
                      <div className="flex items-center w-full justify-center gap-10">
                        <div className="flex flex-col items-center">
                          <p className="text-black-200">Free</p>
                          <p className="text-black-200 text-3xl">{dashboard?.storage?.free || '0 GB'}</p>
                        </div>
                        <div className="flex flex-col items-center">
                          <p className="text-black-200">Premium</p>
                          <p className="text-black-200 text-3xl">{dashboard?.storage?.premium || '0 GB'}</p>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div className="w-full flex flex-wrap items-center tablet:justify-center desktop:justify-between tablet:gap-10 mt-10 gap-y-10">
                    <DashboardCard
                      svgIcon={groups}
                      title={t('groups')}
                      total={dashboard?.groups?.total?.toString() || '0'}
                      free={dashboard?.groups?.free?.toString() || '0'}
                      premium={dashboard?.groups?.free?.toString() || '0'}
                    />
                    <DashboardCard
                      svgIcon={categories}
                      title={t('categories')}
                      total={dashboard?.categories.total?.toString() || '0'}
                      free={dashboard?.categories?.free?.toString() || '0'}
                      premium={dashboard?.categories?.premium?.toString() || '0'}
                    />
                    <DashboardCard
                      svgIcon={insight}
                      title={t('insights')}
                      total={dashboard?.insights.total?.toString() || '0'}
                      free={dashboard?.insights?.free?.toString() || '0'}
                      premium={dashboard?.insights?.premium?.toString() || '0'}
                    />
                    <DashboardCard
                      svgIcon={box}
                      title={t('insight_storage')}
                      total={dashboard?.['insights-storage'].total?.toString() || '0 '}
                      free={dashboard?.['insights-storage']?.free?.toString() || '0 '}
                      premium={dashboard?.['insights-storage']?.premium?.toString() || '0 '}
                    />
                    <DashboardCard
                      svgIcon={calendar}
                      title={t('events')}
                      total={dashboard?.events.total?.toString() || '0'}
                      free={dashboard?.events?.free?.toString() || '0'}
                      premium={dashboard?.events?.premium?.toString() || '0'}
                    />
                    <DashboardCard
                      svgIcon={calendar}
                      title={t('events_type')}
                      total={dashboard?.['events-types'].total?.toString() || '0'}
                      free={dashboard?.['events-types']?.free?.toString() || '0'}
                      premium={dashboard?.['events-types']?.premium?.toString() || '0'}
                    />
                    <div className="flex flex-col items-center justify-between p-5 h-[225px] max-h-[225px] w-[480px] rounded-md bg-white">
                      <div className="flex flex-col items-center">
                        <img src={camera} alt="camera" />
                        <h1 className="text-primary-200 font-bold">{t('most_used_viats')}</h1>
                      </div>
                      <div className="flex flex-wrap items-center w-full justify-center gap-x-5 mb-5">
                        <div className="flex flex-col items-center">
                          <p className="text-black-200 text-sm">{t('image')}</p>
                          <p className="text-black-200 text-2xl">
                            {dashboard?.['most-used-viat'].image?.toString() || '0'}
                          </p>
                        </div>
                        <span className="h-[75px] w-[1px] bg-[#DDE2E4]" />
                        <div className="flex flex-col items-center">
                          <p className="text-black-200 text-sm">{t('text')}</p>
                          <p className="text-black-200 text-2xl">
                            {dashboard?.['most-used-viat'].text?.toString() || '0'}
                          </p>
                        </div>
                        <span className="h-[75px] w-[1px] bg-[#DDE2E4]" />
                        <div className="flex flex-col items-center">
                          <p className="text-black-200 text-sm">{t('video')}</p>
                          <p className="text-black-200 text-2xl">
                            {dashboard?.['most-used-viat'].video?.toString() || '0'}
                          </p>
                        </div>
                        <span className="h-[75px] w-[1px] bg-[#DDE2E4]" />
                        <div className="flex flex-col items-center">
                          <p className="text-black-200 text-sm">{t('audio')}</p>
                          <p className="text-black-200 text-2xl">
                            {dashboard?.['most-used-viat'].audio?.toString() || '0'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          )}
        </>
      )}
    </>
  );
}
