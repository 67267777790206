import * as Dialog from '@radix-ui/react-dialog';
import { useRef, useState } from 'react';

import { Category, useCategory } from '@hooks/useCategory';

import { Button } from '@components/Button';

import { useTranslation } from 'react-i18next';
import { toast } from 'utils/toast';

interface CreateOrEditCategoryModalProps {
  groupId: string;
  categoryId: string;
  subCategory?: Category;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CreateOrEditSubjectModal({
  groupId,
  categoryId,
  subCategory,
  isSubmitting,
  setIsSubmitting,
}: CreateOrEditCategoryModalProps) {
  const { t } = useTranslation();
  const [categoryName, setCategoryName] = useState<string>(subCategory?.name || '');

  const closeButton = useRef<HTMLButtonElement>(null);

  const { createCategory, updateCategory, getCategory } = useCategory();

  async function handleCreateOrEditCategory() {
    if (!groupId) {
      toast({
        label: t('error'),
        message: t('group_not_found'),
        type: 'error',
      });
      return;
    }
    setIsSubmitting(true);
    if (!subCategory) {
      const response = await createCategory(groupId, categoryName, '', '', categoryId);
      if (response.uuid) {
        toast({
          label: t('success'),
          message: `${t('category')} ${response.name}, ${t('created_successfully')}`,
          type: 'success',
        });
        setCategoryName('');
      }
    } else {
      const response = await updateCategory(groupId, subCategory.uuid, categoryName, '', '', categoryId);
      if (response.uuid) {
        toast({
          label: t('success'),
          message: `${t('category')} ${response.name}, ${t('updated_successfully')}`,
          type: 'success',
        });
        setCategoryName('');
      }
    }
    setIsSubmitting(false);
    getCategory(groupId, categoryId);
    closeButton.current?.click();
  }

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '10',
        }}
      />
      <Dialog.Content
        forceMount
        className={'z-40 w-[calc(100vw-200px)] rounded-lg p-4 right-0 bottom-0 fixed bg-white font-poppins'}
      >
        <Dialog.Close ref={closeButton} className={'absolute top-4 right-8'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
            <path
              d="M3.35209 0.489335C2.69963 -0.163112 1.6418 -0.163112 0.989335 0.489335C0.336888 1.1418 0.336888 2.19963 0.989335 2.85209L10.6372 12.4999L0.989435 22.1478C0.336989 22.8002 0.336989 23.8581 0.989435 24.5105C1.6419 25.1629 2.69973 25.1629 3.35217 24.5105L12.9999 14.8627L22.6478 24.5105C23.3002 25.1629 24.3581 25.1629 25.0105 24.5105C25.6629 23.8581 25.6629 22.8002 25.0105 22.1478L15.3627 12.4999L25.0107 2.85209C25.6631 2.19963 25.6631 1.1418 25.0107 0.489335C24.3583 -0.163112 23.3004 -0.163112 22.648 0.489335L12.9999 10.1372L3.35209 0.489335Z"
              fill="#737373"
            />
          </svg>
        </Dialog.Close>

        <div className="flex flex-col items-center w-full">
          <div className="flex flex-col gap-3 bg-white  py-5">
            <h2 className="text-xl text-black-100 font-bold mb-8 w-full text-center">
              {subCategory ? t('rename_the_subcategory') : t('create_a_subcategory')}
            </h2>

            <div className="flex flex-col gap-4 desktop:w-[45vw] tablet:w-[60vw]">
              <span className="text-lg text-secondary-400">{t('name')}</span>
              <input
                className="bg-transparent border-b border-grey-600 focus:outline-none text-lg text-black-100"
                type="text"
                maxLength={255}
                placeholder={t('choose_a_name')}
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </div>
          </div>
          <span className="mt-12 mb-5">
            <Button
              disabled={isSubmitting}
              onClick={handleCreateOrEditCategory}
              variant="primary"
              size="medium"
              text={t('save')}
            />
          </span>
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
