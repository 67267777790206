import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGroups } from '@hooks/useGroups';

import { Button } from '@components/Button';

export default function EmptyEvents() {
  const { t } = useTranslation();

  const [showCreateEvent, setShowCreateEvent] = useState(false);

  const { getGroups, groups } = useGroups();

  const navigate = useNavigate();

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  useEffect(() => {
    const existGroupWithoutDefault = groups.find((group) => group.status !== 'fake');
    if (existGroupWithoutDefault) {
      setShowCreateEvent(true);
    }
  }, [groups]);

  return (
    <div className="flex flex-col items-center gap-5">
      <svg xmlns="http://www.w3.org/2000/svg" width="150" height="179" viewBox="0 0 150 179" fill="none">
        <path
          d="M75 167.328C116.421 167.328 150 133.749 150 92.3281C150 50.9068 116.421 17.3281 75 17.3281C33.5786 17.3281 0 50.9068 0 92.3281C0 133.749 33.5786 167.328 75 167.328Z"
          fill="#282A74"
          fillOpacity="0.1"
        />
        <g filter="url(#filter0_d_3648_30640)">
          <path
            d="M118 60.3281H32C29.2386 60.3281 27 62.5667 27 65.3281V170.328C27 173.09 29.2386 175.328 32 175.328H118C120.761 175.328 123 173.09 123 170.328V65.3281C123 62.5667 120.761 60.3281 118 60.3281Z"
            fill="white"
          />
        </g>
        <path
          d="M65 75.3281H39C37.3431 75.3281 36 76.6713 36 78.3281C36 79.985 37.3431 81.3281 39 81.3281H65C66.6569 81.3281 68 79.985 68 78.3281C68 76.6713 66.6569 75.3281 65 75.3281Z"
          fill="#B4DAFF"
        />
        <path
          d="M83 88.3281H39C37.3431 88.3281 36 89.6713 36 91.3281C36 92.985 37.3431 94.3281 39 94.3281H83C84.6569 94.3281 86 92.985 86 91.3281C86 89.6713 84.6569 88.3281 83 88.3281Z"
          fill="#DFEAFB"
        />
        <path
          d="M65 102.328H39C37.3431 102.328 36 103.671 36 105.328C36 106.985 37.3431 108.328 39 108.328H65C66.6569 108.328 68 106.985 68 105.328C68 103.671 66.6569 102.328 65 102.328Z"
          fill="#B4DAFF"
        />
        <path
          d="M83 115.328H39C37.3431 115.328 36 116.671 36 118.328C36 119.985 37.3431 121.328 39 121.328H83C84.6569 121.328 86 119.985 86 118.328C86 116.671 84.6569 115.328 83 115.328Z"
          fill="#DFEAFB"
        />
        <path
          d="M65 129.328H39C37.3431 129.328 36 130.671 36 132.328C36 133.985 37.3431 135.328 39 135.328H65C66.6569 135.328 68 133.985 68 132.328C68 130.671 66.6569 129.328 65 129.328Z"
          fill="#B4DAFF"
        />
        <path
          d="M83 142.328H39C37.3431 142.328 36 143.671 36 145.328C36 146.985 37.3431 148.328 39 148.328H83C84.6569 148.328 86 146.985 86 145.328C86 143.671 84.6569 142.328 83 142.328Z"
          fill="#DFEAFB"
        />
        <g filter="url(#filter1_d_3648_30640)">
          <path
            d="M118 9.32812H32C29.2386 9.32812 27 11.5667 27 14.3281V44.3281C27 47.0896 29.2386 49.3281 32 49.3281H118C120.761 49.3281 123 47.0896 123 44.3281V14.3281C123 11.5667 120.761 9.32812 118 9.32812Z"
            fill="#282A74"
          />
        </g>
        <path
          d="M65 20.3281H39C37.3431 20.3281 36 21.6713 36 23.3281C36 24.985 37.3431 26.3281 39 26.3281H65C66.6569 26.3281 68 24.985 68 23.3281C68 21.6713 66.6569 20.3281 65 20.3281Z"
          fill="#B4DAFF"
        />
        <path
          d="M83 33.3281H39C37.3431 33.3281 36 34.6713 36 36.3281C36 37.985 37.3431 39.3281 39 39.3281H83C84.6569 39.3281 86 37.985 86 36.3281C86 34.6713 84.6569 33.3281 83 33.3281Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d_3648_30640"
            x="21"
            y="51.3281"
            width="108"
            height="127"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-3" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3648_30640" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3648_30640" result="shape" />
          </filter>
          <filter
            id="filter1_d_3648_30640"
            x="21"
            y="0.328125"
            width="108"
            height="52"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-3" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3648_30640" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3648_30640" result="shape" />
          </filter>
        </defs>
      </svg>

      <div className="flex flex-col items-center">
        <h3 className="font-medium text-black-200">{t('no_events')}</h3>
        <span className="text-sm text-black-200">{t('no_events_for_this_day')}</span>
      </div>
      {showCreateEvent && (
        <Button
          onClick={() => navigate('/calendar/create-event')}
          size="medium"
          text={t('create_an_event')}
          variant="primary"
          boxShadow
        />
      )}
    </div>
  );
}
