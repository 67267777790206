/* eslint-disable import/named */
import { useGoogleLogin } from '@react-oauth/google';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { api } from '../../services/axios';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { IResolveParams, LoginSocialApple, LoginSocialFacebook } from 'reactjs-social-login';

import { zodResolver } from '@hookform/resolvers/zod';
import { AppleUser, FacebookUser, GoogleUser, User, useUser } from '@hooks/useUser';

import { Button } from '@components/Button';
import { Input } from '@components/Input';

import { AppleIcon } from '@assets/icons/AppleIcon';
import { GoogleIcon } from '@assets/icons/GoogleIcon';
import logo from '@assets/logo.svg';

import { FacebookIcon } from '@assets/icons/FacebookIcon';
import i18next from 'i18next';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { isEmail, isPhoneNumber } from 'utils/auxFunctions';
import { toast } from '../../utils/toast';

const loginFormSchema = z.object({
  emailOrPhone: z.string(),
  password: z.string(),
});

type loginFormInputs = z.infer<typeof loginFormSchema>;

export default function LoginForm() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<loginFormInputs>({
    resolver: zodResolver(loginFormSchema),
  });

  const navigate = useNavigate();

  const { setUser, setToken, verifyUser, setRefreshToken } = useUser();
  const { t } = useTranslation();



  async function handleLogin(data: loginFormInputs) {
    const { password } = data;
    let emailOrPhone = data.emailOrPhone;
    if (!isEmail(emailOrPhone) && !isPhoneNumber(emailOrPhone) && !isPhoneNumber('+' + emailOrPhone)) {
      toast({
        label: t('error'),
        message: t('invalid_email_or_phone'),
        type: 'error',
      });
      return;
    }

    if (isPhoneNumber(emailOrPhone) && emailOrPhone.startsWith('+')) {
      emailOrPhone = emailOrPhone.replaceAll('+', '');
    }
    try {
      const { data } = await api.post('/auth/login', {
        email_or_phone: emailOrPhone,
        password,
      });
      const token = data.auth.token;
      const user: User = data.user;
      setUser(user);
      setToken(token);
      setRefreshToken(data.auth.refresh_token as string);
      if (verifyUser() === 'feed') {
        navigate('/feed');
      } else if (verifyUser() === 'profile') {
        navigate('/register/finish');
      }
    } catch (error: any) {
      if (
        error &&
        error?.response?.data?.message &&
        error?.response?.data?.message.includes('Two-factor authentication is not activated')
      ) {
        toast({
          label: t('warning'),
          message: error?.response?.data?.message + ' We have sent a new code to your email.',
          type: 'alert',
        });
        await api.post('/auth/resend-two-factor-code', {
          email_or_phone: emailOrPhone,
        });
        navigate(`/two-factor?emailOrPhone=${emailOrPhone}`);
        return;
      }
      toast({
        label: t('error'),
        message: t('invalid_email_password'),
        type: 'error',
      });
    }
  }
  useEffect(() => {
    const redirect = new URLSearchParams(window.location.search).get('redirect');

    if (redirect == 'account-delete') {
      navigate('/account-delete/');
    }
  }, []);

  const handleLoginWithGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const response = await fetch(
        `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${codeResponse.access_token}`,
      );
      const user: GoogleUser = await response.json();
      try {
        const { data } = await api.post('/auth/login-social', {
          email_or_phone: user.email,
          first_name: user.given_name,
          last_name: user.family_name,
          provider: 'google',
          provider_id: user.id,
          image: user.picture,
          language: user.locale,
        });
        if (data?.method === 'two_factor_code') {
          toast({
            label: t('success'),
            message: t('account_created'),
            type: 'success',
          });
          navigate(`/two-factor?emailOrPhone=${user.email}`);
          return;
        }
        api.defaults.headers.common['Authorization'] = `Bearer ${data.auth.token}`;
        setUser(data.user);
        setToken(data.auth.token);
        setRefreshToken(data.auth.refresh_token as string);

        if (verifyUser() === 'feed') {
          navigate('/feed');
        } else if (verifyUser() === 'profile') {
          navigate('/register/finish');
        }
      } catch (error: any) {
        if (error?.response?.data?.message && error?.response?.data?.message === 'Unauthorized') {
          toast({
            label: t('error'),
            message: t('user_not_registered'),
            type: 'error',
          });
          return;
        } else {
          if (
            error?.response?.data?.message &&
            error?.response?.data?.message.includes('Two-factor authentication is not activated')
          ) {
            toast({
              label: t('warning'),
              message: error?.response?.data?.message + ' We have sent a new code to your email.',
              type: 'alert',
            });
            await api.post('/auth/resend-two-factor-code', {
              email_or_phone: user.email,
            });
            navigate(`/two-factor?emailOrPhone=${user.email}`);
            return;
          }
          toast({
            label: t('error'),
            message: t('user_not_registered'),
            type: 'error',
          });
        }
        throw error;
      }
    },
    onError: () =>
      toast({
        label: t('error'),
        message: t('authentication_failed'),
        type: 'error',
      }),
  });

  useEffect(() => {
    const lang = new URL(window.location.href).searchParams.get('lang');

    if (lang?.includes('pt')) {
      i18next.changeLanguage('pt');
    } else {
      i18next.changeLanguage('en');
    }
  }, []);

  async function handleLoginWithApple(obj: AppleUser) {
    const userJWT: { email: string; sub: string } = jwtDecode(obj.authorization.id_token);

    if (!userJWT.email || !userJWT.sub) {
      toast({
        label: t('error'),
        message: t('invalid_email'),
        type: 'error',
      });
      return;
    }
    try {
      let data = null;

      if (obj?.user) {
        const { user } = obj;
        const response = await api.post('/auth/login-social', {
          first_name: user.name.firstName,
          last_name: user.name.lastName,
          email_or_phone: user.email,
          provider: 'apple',
          provider_id: userJWT.sub,
        });
        data = response.data;
      } else {
        const response = await api.post('/auth/login-social', {
          email_or_phone: userJWT.email,
          provider: 'apple',
          provider_id: userJWT.sub,
        });
        data = response.data;
      }
      if (data?.method === 'two_factor_code') {
        toast({
          label: t('success'),
          message: t('account_created'),
          type: 'success',
        });
        navigate(`/two-factor?emailOrPhone=${userJWT.email}`);
        return;
      }
      api.defaults.headers.common['Authorization'] = `Bearer ${data.auth.token}`;
      setUser(data.user);
      setToken(data.auth.token);
      setRefreshToken(data.auth.refresh_token as string);

      if (verifyUser() === 'feed') {
        navigate('/feed');
      } else if (verifyUser() === 'profile') {
        navigate('/register/finish');
      }
    } catch (error: any) {
      if (error?.response?.data?.message && error?.response?.data?.message === 'Unauthorized') {
        toast({
          label: t('error'),
          message: t('user_not_registered'),
          type: 'error',
        });
        return;
      } else {
        if (
          error?.response?.data?.message &&
          error?.response?.data?.message.includes('Two-factor authentication is not activated')
        ) {
          toast({
            label: t('warning'),
            message: t('resend_verification_code'),
            type: 'alert',
          });
          await api.post('/auth/resend-two-factor-code', {
            email_or_phone: userJWT.email,
          });
          navigate(`/two-factor?emailOrPhone=${userJWT.email}`);
          return;
        }
        toast({
          label: t('error'),
          message: t('user_not_registered'),
          type: 'error',
        });
      }
      throw error;
    }
  }

  async function handleLoginWithFacebook(obj: { accessToken: string }) {
    const accessToken = obj.accessToken;
    const data = await fetch(
      `https://graph.facebook.com/me?fields=id,name,email,picture,location&access_token=${accessToken}`,
    );
    const user: FacebookUser = await data.json();
    if (!user.email) {
      toast({
        label: 'Error',
        message: t('auth_email_failed'),
        type: 'error',
      });
      return;
    }
    try {
      const { data } = await api.post('/auth/login-social', {
        first_name: user.first_name,
        last_name: user.last_name,
        email_or_phone: user.email,
        provider: 'facebook',
        provider_id: user.id,
        image: user.picture.data.url,
        language: user.location || '',
      });
      if (data?.method === 'two_factor_code') {
        toast({
          label: 'Success',
          message: t('account_created'),
          type: 'success',
        });
        navigate(`/two-factor?emailOrPhone=${user.email}`);
        return;
      }
      api.defaults.headers.common['Authorization'] = `Bearer ${data.auth.token}`;
      setUser(data.user);
      setToken(data.auth.token);
      setRefreshToken(data.auth.refresh_token as string);
      if (verifyUser() === 'feed') {
        navigate('/feed');
      } else if (verifyUser() === 'profile') {
        navigate('/register/finish');
      }
    } catch (error: any) {
      if (error?.response?.data?.message && error?.response?.data?.message === 'Unauthorized') {
        toast({
          label: 'Error',
          message: t('user_not_registered'),
          type: 'error',
        });
        return;
      } else {
        if (
          error?.response?.data?.message &&
          error?.response?.data?.message.includes('Two-factor authentication is not activated')
        ) {
          toast({
            label: 'Warning',
            message: error?.response?.data?.message + ' We have sent a new code to your email.',
            type: 'alert',
          });
          await api.post('/auth/resend-two-factor-code', {
            email_or_phone: user.email,
          });
          navigate(`/two-factor?emailOrPhone=${user.email}`);
          return;
        }
        toast({
          label: 'Error',
          message: t('user_not_registered'),
          type: 'error',
        });
      }
      throw error;
    }
  }

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <main className="h-[80vh]">
        <form
          onSubmit={handleSubmit(handleLogin)}
          className="flex flex-col items-center w-[420px] bg-white p-8 shadow-md border border-gray-100"
        >
          <img className="my-12 shadow-md rounded-lg" width={48} height={48} src={logo} alt="Logo" />
          <div className="text-center">
            <h1 className="text-primary-200 text-3xl font-semibold text-center">{t('welcome')}</h1>
            <span className="font-bold text-primary-100 text-xs">{t('get_in_with_your_credentials')}</span>
          </div>
          <section className="w-full mt-8  flex flex-col gap-5">
            <div className="flex items-center gap-1">
              {/* <div className="flex flex-col relative">
            <label
              htmlFor="countryCode"
              className="absolute text-sm text-grey-300 font-semibold  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-grey-300  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2"
            >
              Code
            </label>
            <select
              id="countryCode"
              name="countryCode"
              className="bg-transparent border-2 border-grey-300 rounded-lg px-1 py-4 text-sm outline-none"
              onChange={(event) => setSelectedCode(event.target.value)}
            >
              {countryCodes.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name} {'(' + country.code + ')'}
                </option>
              ))}
            </select>
          </div> */}
              <Input
                {...register('emailOrPhone')}
                data-test="login-email"
                identificator="emailOrPhone"
                label={t('email')}
                required
              />
            </div>
            <Input
              {...register('password')}
              data-test="login-password"
              type="password"
              identificator="password"
              label={t('password')}
              required
            />
          </section>
          <section className="flex justify-end w-full mt-1">
            <Link className="underline text-secondary-300 text-xs" to="/password/forgot">
              {t('forgot_password')}
            </Link>
          </section>
          <section className="mt-16">
            <Button
              data-test="login-submit"
              disabled={isSubmitting}
              text={t('login')}
              size="large"
              type="submit"
              variant="primary"
            />
          </section>

          <div className="flex w-full items-center mt-12">
            <hr className="w-full h-[1px] border" />
            <span className="px-5 text-primary-200 w-full text-xs text-center">{t('or_login_with')}</span>
            <hr className="w-full h-[1px] border" />
          </div>

          <section className="flex mt-6 justify-center w-full gap-3">
            <LoginSocialFacebook
              isOnlyGetToken
              appId={import.meta.env.VITE_FACEBOOK_OAUTH_CLIENT_ID}
              onResolve={({ data }: IResolveParams) => {
                handleLoginWithFacebook(data);
              }}
            >
              <button
                disabled={isSubmitting}
                onClick={(e) => e.preventDefault()}
                className="py-4 px-8 bg-white rounded shadow-md hover:scale-110 transition-transform"
              >
                <FacebookIcon height={30} width={25} />
              </button>
            </LoginSocialFacebook>
            <button
              disabled={isSubmitting}
              onClick={(e) => {
                e.preventDefault();
                handleLoginWithGoogle();
              }}
              className="py-4 px-8 bg-white rounded shadow-md hover:scale-110 transition-transform"
            >
              <GoogleIcon height={25} width={25} />
            </button>
            <LoginSocialApple
              client_id={import.meta.env.VITE_APPLE_OAUTH_CLIENT_ID}
              scope={'name email'}
              redirect_uri={window.location.origin + '/feed'}
              onResolve={({ data }: IResolveParams) => {
                handleLoginWithApple(data);
              }}
            >
              <button
                onClick={(e) => e.preventDefault()}
                disabled={isSubmitting}
                className="py-4 px-8 bg-white rounded shadow-md hover:scale-110 transition-transform"
              >
                <AppleIcon height={30} />
              </button>
            </LoginSocialApple>
          </section>

          <section className="flex w-full mt-6">
            <span className="text-xs text-center text-grey-100">{t('dont_have_an_account')}</span>
            <Link to={'/register'} className="ml-1 underline text-secondary-300 text-xs">
              {t('register')}
            </Link>
          </section>
        </form>
      </main>
    </div>
  );
}
