import * as Dialog from '@radix-ui/react-dialog';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { AdsView, useAds } from '@hooks/useAds';
import { Insight } from '@hooks/useInsights';

import ButtonAction from '@components/ButtonAction';
import ImageViewer from '@components/ImageViewer';

import statistics from '@assets/icons/Button/statistics.svg';
import trash from '@assets/icons/Button/trash.svg';
import pencil from '@assets/icons/ProfileModal/pencil.svg';

import { toast } from 'utils/toast';

export interface AdsPostProps {
  isAdminMode?: boolean;
  ads: Insight | AdsView;
}

export default function AdsPost({ ads, isAdminMode }: AdsPostProps) {
  const { t } = useTranslation();
  const { registerReaction, registerClick, registerView, deleteAds, listAds } = useAds();

  const [currentAds, setCurrentAds] = useState<Insight | AdsView>(ads);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const adsRefSection = useRef<HTMLDivElement>(null);
  const closeButton = useRef<HTMLButtonElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const navigate = useNavigate();

  const [triggered, setTriggered] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const callback = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          videoRef.current?.play();
        } else {
          videoRef.current?.pause();
        }

        if (entry.isIntersecting && !isAdminMode && !triggered) {
          registerView(ads?.uuid || '');
          setTriggered(true);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    // Inicia a observação do componente
    if (adsRefSection.current) {
      observer.observe(adsRefSection.current);
    }

    return () => {
      if (adsRefSection.current) {
        observer.unobserve(adsRefSection.current);
      }
    };
  }, [triggered]);

  async function handleReactToPost() {
    setIsSubmitting(true);
    if (!currentAds?.total_likes) return;
    if (currentAds?.user_reaction?.emoji) {
      registerReaction(currentAds?.uuid || '', 'remove');
      setCurrentAds({
        ...currentAds,
        user_reaction: null,
        total_likes: currentAds?.total_likes - 1,
      });
    } else {
      setCurrentAds({
        ...currentAds,
        total_likes: currentAds?.total_likes + 1,
      });
      await registerReaction(currentAds?.uuid || '', 'like');
      setCurrentAds((prev) => {
        return { ...prev, user_reaction: { emoji: 'like', uuid: '1' } };
      });
    }
    setIsSubmitting(false);
  }

  const handleDeleteAds = useCallback(async () => {
    const response = await deleteAds(currentAds?.uuid || '');
    if (response?.uuid) {
      toast({
        label: t('success'),
        message: t('ads_deleted'),
        type: 'success',
      });
      listAds(true, `?sortBy=updated_at&sortDirection=desc&page=1`);
      closeButton.current?.click();
    }
  }, [currentAds]);

  return (
    <section ref={adsRefSection} className="flex flex-col w-[459px] bg-white p-5 gap-5">
      <header className="flex items-center justify-between mb-5">
        <div className="flex items-center gap-5">
          <ImageViewer
            url={currentAds?.profile_photo ?? ''}
            alt="User Profile"
            className="h-12 w-12 rounded-full  object-cover"
          />
          <span className="flex flex-col">
            <h3 className="text-lg font-bold text-black-100">{currentAds?.name ?? ''}</h3>
            <p className="text-xs text-black-100">{t('sponsored')}</p>
          </span>
        </div>

        {isAdminMode && (
          <Dialog.Root>
            <Dialog.Trigger>
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.5 12.5C7.5 13.8807 6.38071 15 5 15C3.61929 15 2.5 13.8807 2.5 12.5C2.5 11.1193 3.61929 10 5 10C6.38071 10 7.5 11.1193 7.5 12.5Z"
                  fill="#333333"
                />
                <path
                  d="M15 12.5C15 13.8807 13.8807 15 12.5 15C11.1193 15 10 13.8807 10 12.5C10 11.1193 11.1193 10 12.5 10C13.8807 10 15 11.1193 15 12.5Z"
                  fill="#333333"
                />
                <path
                  d="M20 15C21.3807 15 22.5 13.8807 22.5 12.5C22.5 11.1193 21.3807 10 20 10C18.6193 10 17.5 11.1193 17.5 12.5C17.5 13.8807 18.6193 15 20 15Z"
                  fill="#333333"
                />
              </svg>
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay
                style={{
                  position: 'fixed',
                  width: '100vw',
                  height: '100vh',
                  inset: 0,
                  background: 'rgba(0, 0, 0, 0.75)',
                  zIndex: '10',
                }}
              />
              <Dialog.Content
                forceMount
                className={'z-40 w-[calc(100vw-200px)] rounded-lg p-4 right-0 bottom-0 fixed bg-white font-poppins'}
              >
                <div className="w-full flex flex-col items-center gap-1 p-10 relative">
                  <ButtonAction
                    src={statistics}
                    text={t('ads_statistics')}
                    onClick={() => navigate(`/ads/statistics/${currentAds?.uuid}`)}
                  />
                  <ButtonAction
                    src={pencil}
                    text={t('edit_ads')}
                    onClick={() => navigate(`/ads/edit/${currentAds?.uuid}`)}
                  />
                  <ButtonAction src={trash} text={t('remove_ads')} onClick={handleDeleteAds} />
                </div>
                <Dialog.Close ref={closeButton} className={'absolute top-4 right-8'}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                    <path
                      d="M3.35209 0.489335C2.69963 -0.163112 1.6418 -0.163112 0.989335 0.489335C0.336888 1.1418 0.336888 2.19963 0.989335 2.85209L10.6372 12.4999L0.989435 22.1478C0.336989 22.8002 0.336989 23.8581 0.989435 24.5105C1.6419 25.1629 2.69973 25.1629 3.35217 24.5105L12.9999 14.8627L22.6478 24.5105C23.3002 25.1629 24.3581 25.1629 25.0105 24.5105C25.6629 23.8581 25.6629 22.8002 25.0105 22.1478L15.3627 12.4999L25.0107 2.85209C25.6631 2.19963 25.6631 1.1418 25.0107 0.489335C24.3583 -0.163112 23.3004 -0.163112 22.648 0.489335L12.9999 10.1372L3.35209 0.489335Z"
                      fill="#737373"
                    />
                  </svg>
                </Dialog.Close>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        )}
      </header>

      <div className="flex flex-col items-start">
        <div className="flex flex-col gap-5">
          <h2 className="text-xl text-black-100 font-medium text-start break-all">{currentAds?.title || ''}</h2>
          <span
            className="text-lg text-black-100 text-start mb-2 break-all"
            dangerouslySetInnerHTML={{ __html: currentAds?.description || '' }}
          ></span>
        </div>

        {currentAds?.external_url ? (
          <a
            onClick={() => {
              if (!isAdminMode && currentAds?.aws_type === 'image') registerClick(currentAds?.uuid || '');
              if (!isAdminMode && currentAds?.aws_type === 'video') {
                videoRef?.current?.requestFullscreen().then(() => videoRef?.current?.play());
              }
            }}
            href={currentAds?.external_url}
            target="_blank"
            className="w-full "
            rel="noreferrer"
          >
            {currentAds?.aws_type === 'image' ? (
              <img
                src={currentAds?.aws_url || ''}
                alt="Ads Image"
                className="min-h-[258px] max-h-[258px] w-full object-cover"
              />
            ) : (
              <video
                controls
                controlsList="nodownload"
                muted
                ref={videoRef}
                loop
                className="h-full max-h-full max-w-full object-contain"
              >
                Your browser does not support the video tag.
                <source src={currentAds?.aws_url || ''} type="video/mp4" />
              </video>
            )}
          </a>
        ) : (
          <>
            {currentAds?.aws_type === 'image' ? (
              <img
                src={currentAds?.aws_url || ''}
                alt="Ads Image"
                className="min-h-[258px] max-h-[258px] w-full object-cover"
              />
            ) : (
              <video controls controlsList="nodownload" className="h-full max-h-full max-w-full object-contain">
                Your browser does not support the video tag.
                <source src={currentAds?.aws_url || ''} type="video/mp4" />
              </video>
            )}
          </>
        )}
      </div>

      {currentAds?.external_url && (
        <a
          onClick={() => {
            if (!isAdminMode) registerClick(currentAds?.uuid || '');
          }}
          href={currentAds?.external_url}
          target="_blank"
          className="flex items-center gap-2 mt-2 ml-3 hover:opacity-80"
          rel="noreferrer"
        >
          <span className="text-secondary-300">Learn More</span>
          <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M-9.72748e-05 13.6781L1.32178 15L8.82178 7.5L1.32178 0L-9.72748e-05 1.32187L6.16865 7.5L-9.72748e-05 13.6781Z"
              fill="#902687"
            />
          </svg>
        </a>
      )}

      <div className="flex justify-end items-center mt-2">
        <div className="flex items-center justify-center gap-2">
          <div className="flex mt-1.5 relative items-center gap-1">
            <div>
              <button
                disabled={isSubmitting}
                onClick={handleReactToPost}
                className={`hover:opacity-70 ${isSubmitting ? '!cursor-not-allowed' : ''}`}
              >
                {!currentAds?.user_reaction?.emoji && (
                  <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Like Button" clipPath="url(#clip0_1725_31086)">
                      <g id="Group">
                        <path
                          id="Vector"
                          d="M12.2926 5.91544L12.9997 6.62255L13.7068 5.91544C14.6286 4.9936 16.0974 4.125 17.6872 4.125C20.2389 4.125 22.4163 6.21885 22.4163 9.375C22.4163 12.6341 20.6756 15.2536 18.4986 17.3063C16.5719 19.1229 14.4022 20.4052 12.9997 21.2331C11.5971 20.4052 9.42749 19.1229 7.50079 17.3063C5.32375 15.2536 3.58301 12.6341 3.58301 9.375C3.58301 6.22347 5.79571 4.125 8.31217 4.125C9.90199 4.125 11.3707 4.9936 12.2926 5.91544Z"
                          stroke="#333333"
                          strokeWidth="2"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_1725_31086">
                        <rect width="25" height="25" fill="white" transform="translate(0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                )}

                {currentAds?.user_reaction?.emoji === 'like' && (
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17 16" fill="none">
                    <path
                      d="M12.7559 0.5C9.7706 0.5 8.67377 3.55069 8.67377 3.55069C8.67377 3.55069 7.59193 0.5 4.58621 0.5C2.32715 0.5 -0.248018 2.29035 0.703022 6.36021C1.65406 10.4301 8.68195 15.5 8.68195 15.5C8.68195 15.5 15.6839 10.4301 16.6364 6.36021C17.5874 2.29035 15.1975 0.5 12.7559 0.5Z"
                      fill="#F44336"
                    />
                    <path
                      d="M4.12581 0.803842C6.46935 0.803842 7.63294 3.32314 8.009 4.34367C8.06213 4.48946 8.26515 4.49355 8.32646 4.35185L8.66982 3.54932C8.18612 2.00422 6.8563 0.5 4.58634 0.5C3.6462 0.5 2.65292 0.810655 1.88037 1.46603C2.56299 1.01776 3.36279 0.803842 4.12581 0.803842Z"
                      fill="#CC3333"
                    />
                    <path
                      d="M12.7558 0.5C12.0351 0.5 11.3783 0.65669 10.866 0.972795C11.2257 0.833818 11.6713 0.803842 12.1127 0.803842C14.3214 0.803842 16.2984 2.47838 15.4059 6.34931C14.6402 9.6725 10.1657 13.7042 8.79906 15.2425C8.71186 15.3406 8.68188 15.4986 8.68188 15.4986C8.68188 15.4986 15.6839 10.4287 16.6363 6.35884C17.5873 2.29035 15.2016 0.5 12.7558 0.5Z"
                      fill="#CC3333"
                    />
                    <path
                      d="M2.27124 2.65972C2.78219 2.02206 3.69507 1.49476 4.46899 2.10245C4.88728 2.43081 4.7047 3.1039 4.33001 3.3873C3.785 3.80151 3.31084 4.05085 2.97839 4.69941C2.77946 5.08909 2.65956 5.51556 2.59824 5.95021C2.57372 6.12189 2.3489 6.16412 2.26443 6.01288C1.68944 4.99372 1.52594 3.59032 2.27124 2.65972Z"
                      fill="#FF8A80"
                    />
                    <path
                      d="M10.4625 4.00042C10.2227 4.00042 10.0537 3.7688 10.141 3.54534C10.3031 3.13386 10.5129 2.736 10.765 2.38175C11.1383 1.85854 11.8482 1.55334 12.2978 1.8708C12.7584 2.19508 12.6984 2.84364 12.3755 3.15702C11.682 3.82875 10.8072 4.00042 10.4625 4.00042Z"
                      fill="#FF8A80"
                    />
                  </svg>
                )}
              </button>
            </div>
            <span className="mb-1">{currentAds?.total_likes}</span>
          </div>
        </div>
      </div>
    </section>
  );
}
