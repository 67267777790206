import * as Dialog from '@radix-ui/react-dialog';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { GroupMembers, useGroups } from '@hooks/useGroups';

import ButtonAction from '@components/ButtonAction';

import switchArrows from '@assets/icons/Button/switch.svg';
//import pencil from '@assets/icons/Button/pencil.svg';
import trash from '@assets/icons/Button/trash.svg';
import { useUser } from '@hooks/useUser';
import { toast } from 'utils/toast';

interface EditUserModalProps {
  userInGroupInfo: GroupMembers;
}

export default function EditUserModal({ userInGroupInfo }: EditUserModalProps) {
  const { t } = useTranslation();
  const closeButton = useRef<HTMLButtonElement>(null);
  const closeButton1 = useRef<HTMLButtonElement>(null);
  const { group, editUserRole, getGroupMembers, removeUserFromGroup } = useGroups();
  const { user } = useUser();

  async function handleEditUser(role: string) {
    const response = await editUserRole(group.uuid, userInGroupInfo.uuid, role);
    if (response?.content?.data) {
      toast({
        label: t('success'),
        message: response?.content?.message || t('user_role_updated_successfully'),
        type: 'success',
      });
      getGroupMembers(group.uuid, '?page=1');
      closeButton1.current?.click();
      closeButton.current?.click();
    }
    closeButton1.current?.click();
    closeButton.current?.click();
  }

  async function handleDeleteUser() {
    const response = await removeUserFromGroup(group.uuid, userInGroupInfo.uuid);
    if (response?.content?.data) {
      toast({
        label: t('success'),
        message: response?.content?.message || t('user_remove_from_group'),
        type: 'success',
      });
      getGroupMembers(group.uuid, '?page=1');
      closeButton1.current?.click();
      closeButton.current?.click();
    } else {
      toast({
        label: t('error'),
        message: response?.content?.message || t('failed_to_remove_user_from_group'),
        type: 'error',
      });
      closeButton1.current?.click();
      closeButton.current?.click();
    }
  }

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '10',
        }}
      />
      <Dialog.Content
        forceMount
        className={'z-40 w-[calc(100vw-200px)] rounded-lg p-4 right-0 bottom-0 fixed bg-white font-poppins'}
      >
        <Dialog.Close ref={closeButton} className={'absolute top-4 right-8'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
            <path
              d="M3.35209 0.489335C2.69963 -0.163112 1.6418 -0.163112 0.989335 0.489335C0.336888 1.1418 0.336888 2.19963 0.989335 2.85209L10.6372 12.4999L0.989435 22.1478C0.336989 22.8002 0.336989 23.8581 0.989435 24.5105C1.6419 25.1629 2.69973 25.1629 3.35217 24.5105L12.9999 14.8627L22.6478 24.5105C23.3002 25.1629 24.3581 25.1629 25.0105 24.5105C25.6629 23.8581 25.6629 22.8002 25.0105 22.1478L15.3627 12.4999L25.0107 2.85209C25.6631 2.19963 25.6631 1.1418 25.0107 0.489335C24.3583 -0.163112 23.3004 -0.163112 22.648 0.489335L12.9999 10.1372L3.35209 0.489335Z"
              fill="#737373"
            />
          </svg>
        </Dialog.Close>
        <div className="w-full flex flex-col gap-1 p-10">
          {userInGroupInfo.role.name !== 'Admin' && (
            <ButtonAction onClick={() => handleEditUser('admin')} src={switchArrows} text={t('switch_to_admin')} />
          )}

          {userInGroupInfo.role.name !== 'Member' && (
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <ButtonAction src={switchArrows} text={t('switch_to_member')} />
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay
                  style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                    inset: 0,
                    background: 'rgba(0, 0, 0, 0.75)',
                    zIndex: '40',
                  }}
                />
                <Dialog.Content
                  forceMount
                  className={
                    'z-50 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white px-12 py-8 rounded-lg font-poppins'
                  }
                >
                  <div className="flex flex-col gap-5">
                    <Dialog.Close ref={closeButton1} className={'hidden'}></Dialog.Close>
                    <h1 className="text-lg text-black-100 font-semibold">{t('switch_to_member')}</h1>
                    <span className="text-sm text-grey-200 mb-2">
                      {t(
                        userInGroupInfo.user.uuid === user?.uuid
                          ? 'are_you_sure_you_want_to_remove_your_admin_role'
                          : 'are_you_sure_you_want_to_switch_user_roles',
                      )
                        .replace(':user', userInGroupInfo.user.first_name!)
                        .replace(':role', t('member'))}
                    </span>
                    <div className="flex items-center justify-end gap-4">
                      <button
                        onClick={() => closeButton1.current?.click()}
                        className="flex items-center justify-center py-2 px-3 rounded-md bg-primary-200 bg-opacity-20 hover:opacity-70"
                      >
                        {t('cancel')}
                      </button>
                      <button
                        onClick={() => handleEditUser('member')}
                        className="flex items-center justify-center py-2 px-3 rounded-md bg-green-300 hover:opacity-70"
                      >
                        {t('confirm')}
                      </button>
                    </div>
                  </div>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          )}
          {/* <ButtonAction src={pencil} text="Edit visitor permissions" /> */}
          {userInGroupInfo?.user?.uuid !== user?.uuid && (
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <ButtonAction src={trash} text={t('remove_from_group')} />
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay
                  style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                    inset: 0,
                    background: 'rgba(0, 0, 0, 0.75)',
                    zIndex: '40',
                  }}
                />
                <Dialog.Content
                  forceMount
                  className={
                    'z-50 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white px-12 py-8 rounded-lg font-poppins'
                  }
                >
                  <div className="flex flex-col gap-5">
                    <Dialog.Close ref={closeButton1} className={'hidden'}></Dialog.Close>
                    <h1 className="text-lg text-black-100 font-semibold">{t('remove_user')}</h1>
                    <span className="text-sm text-grey-200 mb-2">
                      {t('are_you_sure_you_want_to_remove')} <b>{userInGroupInfo.user.first_name}</b>{' '}
                      {t('from_this_group')}
                    </span>
                    <div className="flex items-center justify-end gap-4">
                      <button
                        onClick={() => closeButton1.current?.click()}
                        className="flex items-center justify-center py-2 px-3 rounded-md bg-primary-200 bg-opacity-20 hover:opacity-70"
                      >
                        {t('cancel')}
                      </button>
                      <button
                        onClick={handleDeleteUser}
                        className="flex items-center justify-center py-2 px-3 rounded-md bg-green-300 hover:opacity-70"
                      >
                        {t('confirm')}
                      </button>
                    </div>
                  </div>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          )}
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
