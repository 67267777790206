import { createSearchParams, Link, useNavigate } from 'react-router-dom';

import { Button } from '@components/Button';
import { Input } from '@components/Input';

import logo from '@assets/logo.svg';
import { Spinner } from '@phosphor-icons/react';
import { api } from '@services/axios';
import i18next from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { handleMessageError } from 'utils/handleMessageError';
import { toast } from 'utils/toast';

export default function DataDeletion() {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [agreeWithTermsAndConditions, setAgreeWithTermsAndConditions] = useState(false);
  const [selectedReason, setSelectedReason] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const options = useMemo(
    () => [
      { value: 'no_longer_using_the_platform', name: t('no_longer_using_the_platform') },
      { value: 'privacy_concerns', name: t('privacy_concerns') },
      { value: 'found_another_platform', name: t('found_another_platform') },
      { value: 'unsatisfied_with_features_or_services', name: t('unsatisfied_with_features_or_services') },
      { value: 'concerns_about_content', name: t('concerns_about_content') },
      { value: 'receiving_spam_or_unwanted_communication', name: t('receiving_spam_or_unwanted_communication') },
      { value: 'personal_reasons', name: t('personal_reasons') },
      { value: 'account_security_concerns', name: t('account_security_concerns') },
      { value: 'too_time-consuming_or_distracting', name: t('too_time-consuming_or_distracting') },
      { value: 'technical_issues', name: t('technical_issues') },
    ],
    [t],
  );

  const handleSubmit = useCallback(
    (event: any) => {
      event?.preventDefault();

      if (agreeWithTermsAndConditions) {
        setIsLoading(true);
        api
          .post('/account-delete', {
            name,
            email,
            subject,
            agreeWithTermsAndConditions,
            reason: selectedReason,
          })
          .then(() => {
            toast({
              label: 'Success',
              message: t('data_deletion_success'),
              type: 'success',
            });

            navigate({
              pathname: '/account-delete/confirmation',
              search: createSearchParams({
                email_or_phone: email,
              }).toString(),
            });
          })
          .catch((error: any) => {
            const message = handleMessageError(error);
            console.error(message);
            toast({
              label: t('data_deletion_error'),
              message: message,
              type: 'error',
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [name, email, subject, agreeWithTermsAndConditions, selectedReason, t, navigate],
  );

  useEffect(() => {
    const browserLanguage = navigator.language;
    if (browserLanguage.includes('pt')) {
      i18next.changeLanguage('pt');
    } else {
      i18next.changeLanguage('en');
    }
  }, []);

  return (
    <div className="w-screen h-screen flex items-center justify-center overflow-y-scroll">
      <main className="h-full">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-center w-[420px] bg-white p-8 shadow-md border border-gray-100 gap-8"
        >
          <img className=" shadow-md rounded-lg" width={48} height={48} src={logo} alt="Logo" />
          <div className="text-left flex flex-col justify-start gap-3">
            <h1 className="text-primary-200 text-3xl font-semibold text-center">{t('account_delete')}</h1>
            <span className="font-normal text-grey-100 text-base">{t('account_delete_subtitle')}</span>
          </div>
          {isLoading && <Spinner size={32} weight="bold" className="animate-spin" />}
          <section className="w-full flex flex-col gap-5">
            <div className="flex items-center gap-1">
              <Input
                label={t('name')}
                identificator="name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <Input
              label={t('email')}
              type="email"
              identificator="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="flex flex-col gap-2 relative">
              <label
                htmlFor="countryCode"
                className="absolute text-sm text-grey-300 font-semibold  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-grey-300  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2"
              >
                {t('reason')}
              </label>
              <select
                id="reason"
                name="reason"
                required
                className="bg-transparent border-2 border-grey-300 rounded-lg px-1 py-4 text-sm outline-none"
                onChange={(event) => setSelectedReason(event.target.value)}
              >
                <option hidden value={''}>
                  {t('choose_a_reason')}
                </option>

                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col gap-2 relative">
              <label
                htmlFor="countryCode"
                className="absolute text-sm text-grey-300 font-semibold  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-grey-300  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2"
              >
                {t('request')}
              </label>
              <textarea
                required
                maxLength={255}
                value={subject}
                className="bg-transparent border-2 border-grey-300 rounded-lg p-2 text-sm outline-none resize-none h-32"
                placeholder={t('explain_your_problem')}
                onChange={(event) => setSubject(event.target.value)}
              />
            </div>
          </section>

          <section className="w-full flex flex-col gap-5">
            <span className="font-bold text-primary-100 text-xs">{t('account_delete_warning')}</span>
            <div className="flex items-center gap-2">
              <input
                checked={agreeWithTermsAndConditions}
                onChange={() => setAgreeWithTermsAndConditions(!agreeWithTermsAndConditions)}
                type="checkbox"
              />

              <span className="text-lg">
                {t('i_agree_with')}{' '}
                <a
                  className="underline text-secondary-300 text-lg"
                  href={t('terms_of_use_link')}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('terms_and_conditions')}
                </a>
              </span>
            </div>
          </section>

          <Button
            text={t('request_your_account_deletion')}
            size="large"
            type="submit"
            variant="primary"
            disabled={!agreeWithTermsAndConditions || isLoading}
          />

          <section className="flex w-full">
            <span className="text-xs text-center text-grey-100">{t('account_deletion_back_to_login_page')}</span>
            <Link to={'/login'} className="ml-1 underline text-secondary-300 text-xs">
              {t('Login')}
            </Link>
          </section>
        </form>
      </main>
    </div>
  );
}
