import { create } from 'zustand';
import { api } from '../services/axios';
import { showErrorMessage } from '../utils/auxFunctions';
import { Group } from './useGroups';

export interface Event {
  uuid?: string;
  title: string;
  description: string;
  start_time: string;
  end_time: string;
  location: string;
  recurring: string;
  notifications: string[];
  event_notifications?: string[];
  event_label?: EventLabel;
  group_id: string;
  event_label_id: string;
  group?: Group;
  post_id?: string;
  moon_phases?: string;
  moon_phases_date?: string[];
  type: 'lunar' | 'gregorian';
}

export interface CreateEventDTO {
  title: string;
  description: string;
  start_time?: string;
  end_time?: string;
  location?: string;
  recurring?: string;
  notifications?: string[];
  group_id: string;
  event_label_id?: string;
  type: 'lunar' | 'gregorian';
  moon_phases_date?: string[];
  post_id?: string;
  moon_phases?: string;
}

export interface EventLabel {
  uuid: string;
  name: string;
  color: string;
}

interface EventProps {
  currentEvent: Event | null;
  currentEditEvent: Event | null;
  eventsLabel: EventLabel[];
  setEventsLabel: (eventsLabel: EventLabel[]) => void;
  setCurrentEvent: (currentEvent: Event) => void;
  setCurrentEditEvent: (currentEditEvent: Event) => void;
  createEvent: (event: CreateEventDTO) => Promise<any>;
  createEventLabel: (eventLabel: Omit<EventLabel, 'uuid'>, groupUuid: string) => Promise<void>;
  updateEventLabel: (eventLabel: EventLabel, groupUuid: string) => Promise<void>;
  getAllEventsLabelByGroupUuid: (groupUuid: string) => Promise<{ eventsLabel: EventLabel[] }>;
  getEventByUuid: (uuid: string) => Promise<{ event: Event }>;
  deleteEventLabel: (groupUuid: string, uuid: string) => Promise<void>;
  deleteEvent: (uuid: string) => Promise<any>;
  updateEvent: (eventUuid: string, toUpdate: Partial<CreateEventDTO>) => Promise<any>;
}

export const useEvent = create<EventProps>((set) => ({
  currentEvent: null,
  setCurrentEvent: (currentEvent) => set(() => ({ currentEvent })),
  currentEditEvent: null,
  setCurrentEditEvent: (currentEditEvent) => set(() => ({ currentEditEvent })),
  eventsLabel: [],
  setEventsLabel: (eventsLabel) => set(() => ({ eventsLabel })),

  updateEvent: async (eventUuid, toUpdate) => {
    try {
      const { data } = await api.put(`/events/${eventUuid}`, toUpdate);
      return data.content.data;
    } catch (error: any) {
      console.log(error);
    }
  },

  getAllEventsLabelByGroupUuid: async (groupUuid: string) => {
    try {
      const { data } = await api.get(`/groups/${groupUuid}/event-labels?sortBy=created_at&sortDirection=desc`);
      set({ eventsLabel: data.content.data.data });
      return {
        eventsLabel: data.content.data.data,
      };
    } catch (err) {
      console.log(err);
      return { eventsLabel: [] };
    }
  },

  getEventByUuid: async (uuid: string) => {
    try {
      const { data } = await api.get(`/events/${uuid}`);
      return {
        event: data.content.data,
      };
    } catch (err) {
      console.log(err);
      return { event: null };
    }
  },

  createEvent: async (event: CreateEventDTO) => {
    try {
      const { data } = await api.post('/events', event);
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  createEventLabel: async (eventLabel, groupUuid) => {
    try {
      await api.post(`/groups/${groupUuid}/event-labels`, eventLabel);
    } catch (err) {
      console.log(err);
    }
  },
  updateEventLabel: async (eventLabel, groupUuid) => {
    try {
      await api.put(`/groups/${groupUuid}/event-labels/${eventLabel.uuid}`, eventLabel);
    } catch (err) {
      console.log(err);
    }
  },
  deleteEventLabel: async (groupUuid, uuid) => {
    try {
      await api.delete(`/groups/${groupUuid}/event-labels/${uuid}`);
    } catch (err) {
      console.log(err);
    }
  },

  deleteEvent: async (uuid) => {
    try {
      const { data } = await api.delete(`/events/${uuid}`);
      return data.content.data;
    } catch (err) {
      console.log(err);
    }
  },
}));
