import Loading from '@components/Loading';
import { NotificationSwitch } from '@components/Switch';
import { CalendarConfig, useCalendar } from '@hooks/useCalendar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { handleMessageError } from 'utils/handleMessageError';
import { toast } from 'utils/toast';

export default function CalendarSettings() {
  const { t } = useTranslation();
  const { getCalendarConfig, updateCalendarConfig } = useCalendar();
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState<CalendarConfig>({
    show_holidays: false,
    show_moon_phases: false,
    show_tides: false,
  });

  useEffect(() => {
    async function fetchInitialSettings() {
      try {
        setIsLoading(true);
        const { settings } = await getCalendarConfig();

        const updatedSettings = {
          show_holidays: settings.show_holidays || false,
          show_moon_phases: settings.show_moon_phases || false,
          show_tides: settings.show_tides || false,
        };

        setSettings(updatedSettings);
      } catch (err) {
        const message = handleMessageError(err);
        console.error(message);
      } finally {
        setIsLoading(false);
      }
    }

    fetchInitialSettings();
  }, [getCalendarConfig]);

  async function updateCalendarSettings(updatedSettings: CalendarConfig) {
    try {
      await updateCalendarConfig(updatedSettings);
      toast({
        label: t('success'),
        message: t('calendar_settings_updated_successfully'),
        type: 'success',
      });
    } catch (err) {
      toast({
        label: t('error'),
        message: t('unable_to_update_calendar_settings'),
        type: 'error',
      });
    }
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col w-full items-center px-5 py-12">
          <div className="flex items-center justify-between w-[60vw] px-5 py-5">
            <p className="text-lg text-black-100">{t('lunar_calendar')}</p>
            <NotificationSwitch
              value={Boolean(settings.show_moon_phases)}
              checked={Boolean(settings.show_moon_phases)}
              onChange={() => {
                const updatedSettings = {
                  ...settings,
                  show_moon_phases: !settings.show_moon_phases,
                };
                updateCalendarSettings(updatedSettings);
                setSettings(updatedSettings);
              }}
            />
          </div>
          <div className="flex items-center justify-between w-[60vw] px-5 py-5">
            <p className="text-lg text-black-100">{t('tide_calendar')}</p>
            <NotificationSwitch
              colorSwitchOn="#282A74"
              value={Boolean(settings.show_tides)}
              checked={Boolean(settings.show_tides)}
              onChange={() => {
                const updatedSettings = {
                  ...settings,
                  show_tides: !settings.show_tides,
                };
                updateCalendarSettings(updatedSettings);
                setSettings(updatedSettings);
              }}
            />
          </div>
          <div className="flex items-center justify-between w-[60vw] px-5 py-5">
            <p className="text-lg text-black-100">{t('holidays')}</p>
            <NotificationSwitch
              colorSwitchOn="#902687"
              value={Boolean(settings.show_holidays)}
              checked={Boolean(settings.show_holidays)}
              onChange={() => {
                const updatedSettings = {
                  ...settings,
                  show_holidays: !settings.show_holidays,
                };
                updateCalendarSettings(updatedSettings);
                setSettings(updatedSettings);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
