import * as Dialog from '@radix-ui/react-dialog';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useInsights } from '@hooks/useInsights';
import { Tag, useTags } from '@hooks/useTags';
import { useTranslation } from 'react-i18next';
import { toast } from 'utils/toast';

interface CreateInsightAddTagsModalProps {
  isEditingInsight?: boolean;
}

export default function CreateInsightAddTagsModal({ isEditingInsight }: Readonly<CreateInsightAddTagsModalProps>) {
  const closeButton = useRef<HTMLButtonElement>(null);
  const searchInput = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const [search, setSearch] = useState('');

  const { getAllTagsForGroup, tags, createNewTagForGroup } = useTags();
  const { creatingInsight, editInsight, editingInsight } = useInsights();

  const getAllTags = useCallback(async () => {
    if (!isEditingInsight) {
      if (!creatingInsight?.groupUuid) return;
      if (search) {
        await getAllTagsForGroup(creatingInsight?.groupUuid || '', '?search=' + search);
      } else {
        await getAllTagsForGroup(creatingInsight?.groupUuid || '');
      }
    } else {
      if (!editingInsight?.groupUuid) return;
      if (search) {
        await getAllTagsForGroup(editingInsight?.groupUuid || '', '?search=' + search);
      } else {
        await getAllTagsForGroup(editingInsight?.groupUuid || '');
      }
    }
  }, [creatingInsight?.groupUuid, editingInsight?.groupUuid, getAllTagsForGroup, isEditingInsight, search]);

  useEffect(() => {
    getAllTags();
  }, [getAllTags]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAllTags();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [getAllTags, search]);

  async function handleSelectTag(tag: Tag) {
    if (!isEditingInsight) {
      const tagExist = creatingInsight.tags.find((t) => t.uuid === tag.uuid);
      if (!tagExist) {
        editInsight({ tags: [...creatingInsight.tags, tag] });
        setSearch('');
        closeButton.current?.click();
      } else {
        toast({
          label: t('error'),
          message: t('tag_already_exists'),
          type: 'error',
        });
      }
    } else {
      const tagExist = editingInsight.tags.find((t) => t.uuid === tag.uuid);
      if (!tagExist) {
        editInsight({ tags: [...editingInsight.tags, tag] }, true);
        setSearch('');
        closeButton.current?.click();
      } else {
        toast({
          label: t('error'),
          message: t('tag_already_exists'),
          type: 'error',
        });
      }
    }
  }

  async function handleCreateTag() {
    if (search.length > 50) {
      toast({
        label: t('error'),
        message: t('tag_name_too_long'),
        type: 'error',
      });
      return;
    }
    if (!isEditingInsight) {
      const tagExist = creatingInsight.tags.find((t) => t.name === search);
      if (!tagExist) {
        const tag = await createNewTagForGroup(creatingInsight?.groupUuid || '', search);
        editInsight({ tags: [...creatingInsight.tags, tag] });
        setSearch('');
        closeButton.current?.click();
      }
    } else {
      const tagExist = editingInsight.tags.find((t) => t.name === search);
      if (!tagExist) {
        const tag = await createNewTagForGroup(editingInsight?.groupUuid || '', search);
        editInsight({ tags: [...editingInsight.tags, tag] }, true);
        setSearch('');
        closeButton.current?.click();
      }
    }
  }

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '10',
        }}
      />
      <Dialog.Content
        forceMount
        className={`z-40 ${
          isEditingInsight ? 'w-[calc(100vw-200px)]' : 'w-[100vw]'
        }  rounded-lg p-4 right-0 bottom-0 fixed bg-white font-poppins`}
      >
        <div className="flex justify-center py-5">
          <Dialog.Close ref={closeButton} className={'absolute top-4 right-8'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
              <path
                d="M3.35209 0.489335C2.69963 -0.163112 1.6418 -0.163112 0.989335 0.489335C0.336888 1.1418 0.336888 2.19963 0.989335 2.85209L10.6372 12.4999L0.989435 22.1478C0.336989 22.8002 0.336989 23.8581 0.989435 24.5105C1.6419 25.1629 2.69973 25.1629 3.35217 24.5105L12.9999 14.8627L22.6478 24.5105C23.3002 25.1629 24.3581 25.1629 25.0105 24.5105C25.6629 23.8581 25.6629 22.8002 25.0105 22.1478L15.3627 12.4999L25.0107 2.85209C25.6631 2.19963 25.6631 1.1418 25.0107 0.489335C24.3583 -0.163112 23.3004 -0.163112 22.648 0.489335L12.9999 10.1372L3.35209 0.489335Z"
                fill="#737373"
              />
            </svg>
          </Dialog.Close>

          <div className="flex flex-col items-center w-[60vw]">
            <h1 className="text-xl font-bold mb-5">Tags</h1>
            <div className="flex items-center border border-primary-200 bg-grey-600 rounded-md px-5 py-5 w-full mb-5">
              <svg
                className="cursor-pointer"
                onClick={() => searchInput.current?.focus()}
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M18 17.5L13 12.5M14.6667 8.33333C14.6667 11.555 12.055 14.1667 8.83333 14.1667C5.61167 14.1667 3 11.555 3 8.33333C3 5.11167 5.61167 2.5 8.83333 2.5C12.055 2.5 14.6667 5.11167 14.6667 8.33333Z"
                  stroke="#282A74"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <input
                value={search}
                maxLength={255}
                onChange={(e) => setSearch(e.target.value)}
                ref={searchInput}
                type="text"
                placeholder={t('search_tag_name')}
                className="bg-transparent outline-none border-none w-full h-full ml-2 text-sm"
              />
              {search !== '' && tags.length === 0 && (
                <button
                  onClick={handleCreateTag}
                  className="text-2xl rounded-full bg-primary-200 w-10 h-9 text-white mr-5 hover:opacity-70"
                  title={t('create_tag')}
                >
                  +
                </button>
              )}
            </div>
            <div className="flex flex-col max-h-[400px] overflow-y-scroll w-full">

              {tags &&
                tags.map((tag) => {
                  if (!isEditingInsight) {
                    const tagExist = creatingInsight?.tags?.find((t) => t.uuid === tag.uuid);
                    return (
                      <button
                        onClick={() => handleSelectTag(tag)}
                        key={tag.uuid}
                        disabled={tagExist !== undefined}
                        className={`flex items-center justify-between w-full border-b border-grey-600 text-xl p-5 hover:opacity-70 disabled:opacity-70`}
                      >
                        <p>{tag.name}</p>
                        {tagExist && <span className="text-sm">{t('already_included')}</span>}
                      </button>
                    );
                  } else {
                    const tagExist = editingInsight?.tags?.find((t) => t.uuid === tag.uuid);
                    return (
                      <button
                        onClick={() => handleSelectTag(tag)}
                        key={tag.uuid}
                        disabled={tagExist !== undefined}
                        className={`flex items-center justify-between w-full border-b border-grey-600 text-xl p-5 hover:opacity-70 disabled:opacity-70`}
                      >
                        <p>{tag.name}</p>
                        {tagExist && <span className="text-sm">{t('already_included')}</span>}
                      </button>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
