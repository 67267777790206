import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { toast } from './toast';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_APY_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: import.meta.env.VITE_FIREBASE_VAPIDKEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken);
        return currentToken;
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        //onsole.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      //console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log("payload firebase", payload)
      // console.log("TITLE:", payload.notification.title);
      // console.log("BODY:", payload.notification.body);
      // console.log("URL IMAGE:", payload.data.url_image);
      toast({
        label: payload.notification.title,
        message: payload.notification.body,
        urlImage: payload.data.url_image,
        type: 'notification',
      });
      resolve(payload);
    });
  });
