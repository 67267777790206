import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as z from 'zod';

import loading from '@assets/icons/loading.gif';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUser } from '@hooks/useUser';

import { Button } from '@components/Button';
import EditPhotoModal from '@components/EditPhotoModal';

import userNoPhoto from '@assets/icons/user-no-photo.svg';

import CountryRegionPickModal from '@components/CountryRegionPickModal';
import { useTranslation } from 'react-i18next';
import { getDefaultImages, isBase64Img, retrieveImgS3 } from 'utils/auxFunctions';
import { uploadToS3 } from 'utils/awsS3';
import { toast } from 'utils/toast';

import { Trash } from '@assets/icons/Trash';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { api } from '@services/axios';
import addYears from 'date-fns/addYears';
import dayjs, { Dayjs } from 'dayjs';
import PhoneNumber from 'libphonenumber-js';
import Modal from 'react-responsive-modal';

const editProfileFormSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  birth_date: z.string(),
  countryOrRegion: z.string(),
  email: z.string(),
  phone: z.string(),
  // userName: z.string(),
});

type editProfileInputs = z.infer<typeof editProfileFormSchema>;

export default function EditProfile() {
  const { t } = useTranslation();
  const { register, handleSubmit, setValue } = useForm<editProfileInputs>({
    resolver: zodResolver(editProfileFormSchema),
  });

  const { user, updateUser } = useUser();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const countryRegionUser =
    user?.country_id === '1' ? { label: '🇵🇹 Portugal', value: '1' } : { label: `🇬🇧 ${t('england')}`, value: '2' };
  const [countryRegion, setCountryRegion] = useState<{ label: string; value: string } | null>(countryRegionUser);
  const [birthDate, setBirthDate] = useState<Dayjs | null>(
    dayjs(
      user?.birth_date ||
        new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()).toString(),
    ),
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setValue('first_name', user.first_name ? user.first_name : '');
      setValue('last_name', user.last_name ? user.last_name : '');
      setValue('birth_date', user.birth_date ? user.birth_date : '');
      setValue('countryOrRegion', user.country_id ? user.country_id.toString() : '');
      setValue('phone', user.phone ? user.phone : '');
      setValue('email', user.email ? user.email : '');
      setPreviewImage(user?.image || '');
    }
  }, [setValue, user]);

  useEffect(() => {
    if (!previewImage) {
      setUploadedFile(null);
    }
  }, [previewImage]);

  const handleImageChange = (event: any) => {
    event.preventDefault();
    let file: File = {} as File;
    if (event.target.files) {
      file = event.target.files?.[0];
    } else if (event.dataTransfer.files) {
      file = event.dataTransfer.files?.[0];
    }
    if (!file) return;
    if (!/image.*/.exec(file.type) || file.type.includes('svg+xml')) {
      toast({
        label: t('error'),
        message: t('the_file_is_not_an_image'),
        type: 'error',
      });
      return;
    }

    const fileSize = file.size;
    if (fileSize <= 2000000) {
      setUploadedFile(file);
      // Cria uma URL temporária para visualização
      const reader = new FileReader();
      reader.onload = () => {
        if ((reader.result as string).length > 2796936) {
          toast({
            label: t('error'),
            message: t('the_image_needs_to_be_less_than_2MB'),
            type: 'error',
          });
          return;
        }
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      toast({
        label: t('error'),
        message: t('the_image_needs_to_be_less_than_2MB'),
        type: 'error',
      });
    }
  };

  async function handleEditUser(data: editProfileInputs) {
    if (!user?.uuid) {
      toast({
        label: t('error'),
        message: t('user_not_found'),
        type: 'error',
      });
      return;
    }

    if (data.phone && !PhoneNumber(String('+' + data.phone))?.isValid()) {
      toast({
        label: t('error'),
        message: t('invalid_phone_number'),
        type: 'error',
      });
      return;
    }

    let imgURL = '';

    if (previewImage) {
      if (isBase64Img(previewImage)) {
        if (uploadedFile) {
          const { key, error } = await uploadToS3(uploadedFile, user?.uuid);
          if (error) {
            toast({
              label: t('error'),
              message: t('failed_to_upload_image'),
              type: 'error',
            });
          } else if (key) imgURL = retrieveImgS3(key);
        }
      } else {
        imgURL = previewImage;
      }
    } else {
      imgURL = getDefaultImages('profile-default-1.png');
    }
    const objtToPost = {
      first_name: data.first_name,
      last_name: data.last_name,
      image: imgURL,
      country_id: countryRegion?.value || '2',
      birth_date: birthDate?.format('YYYY-MM-DD'),
      phone: data.phone,
    };
    const response = await updateUser(objtToPost);
    if (response.uuid) {
      toast({
        label: t('success'),
        message: t('user_updated_successfully'),
        type: 'success',
      });
      window.location.reload();
    }
  }

  const handleRemoveAccountCancel = async () => {
    setIsLoading(true);

    try {
      const result = await api.get('/deletion/cancel');

      if (result) {
        toast({
          type: 'success',
          message: t('remove_account_revert'),
          label: t('success'),
        });
        await updateUser(user as any);
        navigate('/feed');
      }
    } catch (error) {
      console.error(error);
      toast({
        type: 'error',
        message: t('remove_account_error'),
        label: t('error'),
      });
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  };
  return (
    <div className="flex flex-col items-center gap-10 mb-5">
      <header className="flex flex-col items-center gap-5">
        <Dialog.Root>
          <Dialog.Trigger asChild>
            <img
              onDrop={handleImageChange}
              onDragOver={(e) => e.preventDefault()}
              className="h-36 w-36 rounded-full object-cover cursor-pointer"
              src={previewImage ?? userNoPhoto}
              alt="edit"
              aria-hidden
            />
          </Dialog.Trigger>
          <EditPhotoModal
            handleImageChange={handleImageChange}
            setPreviewImage={setPreviewImage}
            setUploadedFile={setUploadedFile}
          />
        </Dialog.Root>
        <Dialog.Root>
          <Dialog.Trigger asChild>
            <button className="text-black-100 text-xl text-center cursor-pointer hover:underline">
              {t('edit_picture')}
            </button>
          </Dialog.Trigger>
          <EditPhotoModal
            handleImageChange={handleImageChange}
            setPreviewImage={setPreviewImage}
            setUploadedFile={setUploadedFile}
          />
        </Dialog.Root>
      </header>

      <form
        onSubmit={handleSubmit(handleEditUser)}
        className="flex flex-col items-center w-[500px] bg-backgray p-3 rounded-sm gap-2"
        id="edit-profile-form"
      >
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="name" className="text-lg text-grey-200">
            {t('name')}
          </label>
          <input
            {...register('first_name')}
            maxLength={20}
            required
            className="w-full outline-none border-b border-grey-500 bg-transparent text-lg text-black-100"
            type="text"
            id="name"
          />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="lastName" className="text-lg text-grey-200">
            {t('last_name')}
          </label>
          <input
            required
            {...register('last_name')}
            maxLength={20}
            className="w-full outline-none border-b border-grey-500 bg-transparent text-lg text-black-100"
            type="text"
            id="lastName"
          />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="birthDate" className="text-lg text-grey-200">
            {t('birth_date')}
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    padding: 0,
                    border: 'none',
                    borderBottom: '1px solid #E0E0E0',
                  },
                  '&:hover fieldset': {
                    borderColor: '#E0E0E0',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#E0E0E0',
                  },
                  '& .MuiButtonBase-root': {
                    padding: 1,
                  },
                  '& .MuiInputBase-input': {
                    padding: 1,
                    paddingRight: 20,
                    paddingLeft: 0,
                  },
                  padding: 0,
                },
                width: '317px',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: '500',
                border: 'none',
                padding: 0,
              }}
              value={birthDate}
              onChange={(newValue) => setBirthDate(newValue)}
              maxDate={dayjs(addYears(new Date(), -18))}
              className="relative"
              format={user?.language === 'pt' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
            />
          </LocalizationProvider>
        </div>

        {/* <div className="flex flex-col gap-1 w-full">
          <label htmlFor="userName" className="text-lg text-grey-200">
            {t('user_name')}
          </label>
          <input
            {...register('userName')}
            className="w-full outline-none border-b border-grey-500 bg-transparent text-lg text-black-100"
            type="text"
            id="userName"
          />
        </div> */}
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="countryOrRegion" className="text-lg text-grey-200">
            {t('country_region')}
          </label>
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <div
                className={`flex items-center justify-between w-full outline-none border-b border-grey-500 bg-transparent text-lg text-black-100 placeholder:text-black-100 placeholder:text-opacity-40 cursor-pointer`}
              >
                <span className="">{countryRegion?.label}</span>
              </div>
            </Dialog.Trigger>
            <CountryRegionPickModal setCountryRegion={setCountryRegion} />
          </Dialog.Root>
          {/* <input
            {...register('countryOrRegion')}
            className="w-full outline-none border-b border-grey-500 bg-transparent text-lg text-black-100"
            type="text"
            id="countryOrRegion"
          /> */}
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="phoneNumber" className="text-lg text-grey-200">
            {t('phone_number')} (+DDD)
          </label>
          <div className="flex items-end">
            <span className="border-b border-grey-500 block">+</span>
            <input
              {...register('phone')}
              className="w-full outline-none border-b border-grey-500 bg-transparent text-lg text-black-100"
              type="number"
              maxLength={20}
              id="phoneNumber"
            />
          </div>
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="email" className="text-lg text-grey-200">
            {t('email')}
          </label>
          <input
            {...register('email')}
            className="w-full outline-none border-b border-grey-500 bg-transparent text-lg text-black-100"
            type="text"
            id="email"
            readOnly
          />
        </div>
      </form>

      <Button type="submit" size="medium" variant="primary" text={t('done')} form="edit-profile-form" />

      {!user?.account_deletion_confirmed_at ? (
        <Link to="/profile/account-remove" className="flex gap-2 cursor-pointer">
          <Trash />
          <span>{t('remove_account')}</span>
        </Link>
      ) : (
        <div>
          <button onClick={() => setIsOpen(true)} className="group px-5 py-3 flex-col items-center gap-x-[10px]">
            <p className="text-xs font-mediumPoppins">
              {t('remove_account_grace')}:{' '}
              {new Date(String(user?.account_deletion_grace_at)).toLocaleDateString(user.language)}
            </p>
            <p className="text-xs underline font-normalPoppins mt-2 text-primary-200">
              {t('remove_account_cancel').toLocaleUpperCase()}?
            </p>
          </button>
        </div>
      )}

      <Modal open={isOpen} onClose={() => setIsOpen(false)} center classNames={{ modal: 'w-[500px]' }}>
        <div className="flex flex-col py-8 px-6 mt-4">
          <h2 className="text-2xl font-poppins font-bold text-primary-200">
            {t('mister')} {user?.first_name} {user?.last_name}
          </h2>
          <p className="mt-3 text-base font-poppins font-bold text-primary-200">{t('remove_account_cancel_text')}</p>

          <div className="mt-6 mx-auto">
            {isLoading ? (
              <img src={loading} alt="Loading" className="w-12" />
            ) : (
              <Button
                text={t('confirm')}
                type="button"
                size="large"
                variant="primary"
                onClick={() => handleRemoveAccountCancel()}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
