import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Category } from '@hooks/useCategory';
import { useInsights } from '@hooks/useInsights';

import TopicsListComponent from './TopicsListComponent';

interface SubcategoriesListComponentProps {
  category: Category;
}

export default function SubcategoriesListComponent({ category }: Readonly<SubcategoriesListComponentProps>) {
  const { editingInsight, editInsight } = useInsights();
  const [isSelected, setIsSelected] = useState<boolean>(
    editingInsight?.category.subCategoryUuid === category.uuid || false,
  );

  const { insightUuid, groupId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    setIsSelected(editingInsight.category.subCategoryUuid === category.uuid);
  }, [category.uuid, editingInsight.category.subCategoryUuid]);

  function handleSelectSubCategory(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.stopPropagation();
    editInsight(
      {
        category: {
          uuid: editingInsight.category.uuid,
          name: editingInsight.category.name,
          subCategoryUuid: category.uuid,
          subCategoryName: category.name,
          topicUuid: '',
        },
      },
      true,
    );
    navigate('/insight/edit/' + groupId + '/' + insightUuid + '/finish?previous=editInsightCategories');
  }

  return (
    <div className="flex flex-col w-full">
      <button
        className={`flex items-center justify-between w-full py-3 pl-14 pr-3 transition-all border-b border-grey-600 cursor-pointer ${
          isSelected ? 'bg-primary-200 bg-opacity-10' : ''
        }`}
        onClick={() => {
          if (!isSelected) {
            editInsight(
              {
                category: {
                  uuid: editingInsight.category.uuid,
                  name: editingInsight.category.name,
                  subCategoryUuid: category.uuid,
                  subCategoryName: category.name,
                  topicUuid: '',
                },
              },
              true,
            );
          } else {
            editInsight(
              {
                category: {
                  uuid: editingInsight.category.uuid,
                  name: editingInsight.category.name,
                  subCategoryUuid: '',
                  subCategoryName: '',
                  topicUuid: '',
                },
              },
              true,
            );
          }
        }}
      >
        <div className="flex items-center gap-3">
          <svg
            className={`${isSelected ? 'rotate-90' : ''} duration-300`}
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="26"
            viewBox="0 0 25 26"
            fill="none"
          >
            <g clipPath="url(#clip0_3335_16907)">
              <path
                d="M6.2502 21.2375L8.0127 23L18.0127 13L8.0127 3L6.2502 4.7625L14.4752 13L6.2502 21.2375Z"
                fill={`${isSelected ? '#EDB561' : '#333333'}`}
              />
            </g>
            <defs>
              <clipPath id="clip0_3335_16907">
                <rect width="25" height="25" fill="white" transform="translate(0 0.9)" />
              </clipPath>
            </defs>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M10.8334 5.83333L9.9038 3.9741C9.63625 3.439 9.50247 3.17144 9.30289 2.97597C9.1264 2.80311 8.91368 2.67164 8.68014 2.59109C8.41605 2.5 8.11692 2.5 7.51866 2.5H4.33341C3.39999 2.5 2.93328 2.5 2.57676 2.68166C2.26316 2.84144 2.00819 3.09641 1.8484 3.41002C1.66675 3.76654 1.66675 4.23325 1.66675 5.16667V5.83333M1.66675 5.83333H14.3334C15.7335 5.83333 16.4336 5.83333 16.9684 6.10582C17.4388 6.3455 17.8212 6.72795 18.0609 7.19836C18.3334 7.73314 18.3334 8.4332 18.3334 9.83333V13.5C18.3334 14.9001 18.3334 15.6002 18.0609 16.135C17.8212 16.6054 17.4388 16.9878 16.9684 17.2275C16.4336 17.5 15.7335 17.5 14.3334 17.5H5.66675C4.26662 17.5 3.56655 17.5 3.03177 17.2275C2.56137 16.9878 2.17892 16.6054 1.93923 16.135C1.66675 15.6002 1.66675 14.9001 1.66675 13.5V5.83333Z"
              stroke="#282A74"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p>{category?.name}</p>
        </div>
        {isSelected && (
          <button onClick={handleSelectSubCategory} className="hover:opacity-70 z-[1]">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M12 5V19M5 12H19"
                stroke="#EDB561"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
      </button>
      <div
        className={`flex flex-col w-full ${
          isSelected ? 'max-h-[500px]' : 'max-h-0 overflow-hidden'
        } transition-all duration-300`}
      >
        {category.children.map((subCategories) => (
          <TopicsListComponent key={subCategories.uuid} category={subCategories} />
        ))}
      </div>
    </div>
  );
}
