import { useTranslation } from 'react-i18next';

interface DashBoardCardProps {
  svgIcon: string;
  title: string;
  total: string;
  free: string;
  premium: string;
}

export default function DashboardCard({ svgIcon, title, total, free, premium }: Readonly<DashBoardCardProps>) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center p-3 w-[220px] rounded-md bg-white">
      <img src={svgIcon} alt="Icon" />
      <h1 className="text-primary-200 font-bold mb-3">{title}</h1>
      <h3 className="text-2xl text-black-100">{total}</h3>

      <span className="w-full h-[1px] bg-[#DDE2E4] my-3" />
      <p className="text-primary-100 mb-3">{t('average')}</p>
      <div className="flex items-center w-full justify-center gap-10 max-h-[50px]">
        <div className="flex flex-col items-center">
          <p className="text-black-200 text-sm text-center">Free</p>
          <p className="text-black-200 text-lg text-center">{free}</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-black-200 text-sm text-center">Premium</p>
          <p className="text-black-200 text-lg text-center">{premium}</p>
        </div>
      </div>
    </div>
  );
}
