import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';

import { Button } from '@components/Button';
import { Input } from '@components/Input';

import logo from '@assets/logo.svg';

import { api } from '@services/axios';

import loading from '@assets/icons/loading.gif';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { isEmail, isPhoneNumber } from 'utils/auxFunctions';
import { toast } from 'utils/toast';

const RemoveAccountSchema = z.object({
  emailOrPhone: z.string(),
});

type RemoveAccountInputs = z.infer<typeof RemoveAccountSchema>;

export default function ExternalRemoveAccount() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = useForm<RemoveAccountInputs>({
    resolver: zodResolver(RemoveAccountSchema),
  });

  const [userLanguage, setUserLanguage] = useState<string>('en');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const browserLanguage = navigator.language;

    if (browserLanguage.includes('pt')) {
      setUserLanguage('pt');
    } else {
      setUserLanguage('en');
    }
  }, []);

  async function handleRemoveAccount(data: RemoveAccountInputs) {
    let emailOrPhone = data.emailOrPhone;
    if (!emailOrPhone) return;
    if (!isEmail(emailOrPhone) && !isPhoneNumber(emailOrPhone) && !isPhoneNumber('+' + emailOrPhone)) {
      toast({
        label: 'Error',
        message: userLanguage === 'pt' ? 'Email inválido' : 'Invalid Email',
        type: 'error',
      });
      return;
    }

    if (isPhoneNumber(emailOrPhone) && emailOrPhone.startsWith('+')) {
      emailOrPhone = emailOrPhone.replaceAll('+', '');
    }

    setIsLoading(true);
    console.log(emailOrPhone);

    try {
      await api.post('/auth/request-account-deletion', {
        email_or_phone: emailOrPhone,
      });
      toast({
        label: 'Success',
        message: userLanguage === 'pt' ? 'Código enviado com sucesso' : `Code sent successfully`,
        type: 'success',
      });
      navigate(`/account/two-factor/?token=dfh3ABJb&email_or_phone=${emailOrPhone}`);
    } catch (error: any) {
      toast({
        label: 'Error',
        message: userLanguage === 'pt' ? 'Erro ao solicitar remoção da conta' : `Failed to request account removal`,
        type: 'error',
      });
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  }

  return (
    <div className="w-screen flex items-center justify-center">
      <main className="h-[60vh]">
        <form
          onSubmit={handleSubmit(handleRemoveAccount)}
          className="flex flex-col justify-center items-center w-full max-w-[400px] px-8 py-[70px] bg-white shadow-md border border-gray-100 gap-20 relative"
        >
          <img className="shadow-md rounded-lg" width={48} height={48} src={logo} alt="Logo" />
          <div className="text-start flex flex-col w-full">
            <h1 className="text-primary-200 text-xl font-semibold mb-2">
              {userLanguage === 'pt' ? 'Vamos encontrar sua conta' : "Let's find your account"}
            </h1>
            <span className="text-secondary-400 text-xs mb-6">
              {userLanguage === 'pt'
                ? 'Nós enviaremos um código para o email informado, para remoção da sua conta'
                : "We'll send you a code by email to remove your account."}
            </span>
            <div className="flex flex-col items-center gap-1">
              <Input {...register('emailOrPhone')} identificator="email" label={'Email'} required />
            </div>
          </div>
          <Button
            disabled={isSubmitting}
            text={userLanguage === 'pt' ? 'Continuar' : 'Continue'}
            type="button"
            size="large"
            variant="primary"
            onClick={() => setIsOpen(true)}
          />
          <Link to={'/login'} className="absolute top-4 left-4 hover:opacity-70">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 40" fill="none">
              <path d="M23.525 3.525L20 0L0 20L20 40L23.525 36.475L7.075 20L23.525 3.525Z" fill="#282A74" />
            </svg>
          </Link>
          <Modal open={isOpen} onClose={() => setIsOpen(false)} center classNames={{ modal: 'w-500' }}>
            <div className="flex flex-col py-8 px-6 mt-4">
              <h2 className="text-2xl font-poppins font-bold text-primary-200">
                {userLanguage === 'en' ? 'User' : 'Prezado(a)'} {userLanguage === 'pt' ? 'Utilizador(a)' : 'User'}
              </h2>
              <p className="mt-3 text-base font-poppins font-bold text-primary-200">
                {userLanguage === 'pt'
                  ? 'Todas as suas informações pessoais, inclusive a suas memórias, serão removidas, tem certeza que deseja continuar?'
                  : 'All your personal information, including your memories, will be removed, are you sure you want to continue?'}
              </p>

              <div className="mt-6 mx-auto">
                {isLoading ? (
                  <img src={loading} alt="Loading" className="w-12" />
                ) : (
                  <Button
                    disabled={isSubmitting}
                    text={userLanguage === 'pt' ? 'Confirmar' : 'Confirm'}
                    type="submit"
                    size="large"
                    variant="primary"
                    onClick={() => handleRemoveAccount({ emailOrPhone: getValues('emailOrPhone') })}
                  />
                )}
              </div>
            </div>
          </Modal>
        </form>
      </main>
    </div>
  );
}
