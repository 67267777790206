import * as Dialog from '@radix-ui/react-dialog';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGroups } from '@hooks/useGroups';
import { useInsights } from '@hooks/useInsights';

import { Button } from '@components/Button';
import Loading from '@components/Loading';
import PostFeed from '@components/PostFeed';
import { toast } from 'utils/toast';

export default function InsightDetails() {
  const [isLoading, setIsLoading] = useState(true);
  const [needApprove, setNeedApprove] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { groupId, insightUuid } = useParams();

  const { getInsightDetails, approvalInsight, insight } = useInsights();
  const { getGroupByUuid, group } = useGroups();
  const { t } = useTranslation();

  const closeButtonReject = useRef<HTMLButtonElement>(null);
  const closeButtonApprove = useRef<HTMLButtonElement>(null);

  const getDetails = useCallback(async () => {
    if (!groupId || !insightUuid) return;
    setIsLoading(true);
    await Promise.all([getGroupByUuid(groupId), getInsightDetails(groupId, insightUuid)]);
    setIsLoading(false);
  }, [getGroupByUuid, getInsightDetails, groupId, insightUuid]);

  useEffect(() => {
    getDetails();
  }, [getDetails, getInsightDetails, groupId, insightUuid]);

  useEffect(() => {
    if (insight?.status === 'pending_approval' && group?.role === 'Admin') {
      setNeedApprove(true);
    } else {
      setNeedApprove(false);
    }
  }, [insight, group]);

  async function handleApproveOrRejectInsight(type: 'published' | 'refused') {
    if (!groupId || !insightUuid || insight?.status !== 'pending_approval' || group?.role !== 'Admin') return;
    setIsSubmitting(true);
    const response = await approvalInsight(groupId, insightUuid, type);
    if (response?.uuid) {
      toast({
        label: t('success'),
        message: t('insight_updated'),
        type: 'success',
      });
      if (type === 'published') closeButtonApprove.current?.click();
      else closeButtonReject.current?.click();
      setNeedApprove(false);
    }
    setIsSubmitting(false);
  }

  return (
    <section className="flex flex-col items-center py-10 w-full bg-backgray min-h-[calc(100vh-88px)]">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col gap-10">
          <PostFeed insight={insight} />
          {needApprove && (
            <div className="flex items-center justify-evenly">
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <button
                    disabled={isSubmitting}
                    className="bg-red-500  px-5 py-[10px] shadow-md font-semibold  flex items-center justify-center gap-2 rounded-[4px] hover:opacity-70 text-white disabled:bg-grey-400 disabled:hover:opacity-100"
                  >
                    {t('reject')}
                  </button>
                </Dialog.Trigger>
                <Dialog.Portal>
                  <Dialog.Overlay
                    style={{
                      position: 'fixed',
                      width: '100vw',
                      height: '100vh',
                      inset: 0,
                      background: 'rgba(0, 0, 0, 0.75)',
                      zIndex: '10',
                    }}
                  />
                  <Dialog.Content
                    forceMount
                    className={
                      'z-40 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white px-12 py-8 rounded-lg font-poppins'
                    }
                  >
                    <div className="flex flex-col gap-5">
                      <Dialog.Close ref={closeButtonReject} className={'hidden'}></Dialog.Close>
                      <h1 className="text-lg text-black-100 font-semibold">{t('reprove_insight')}</h1>
                      <span className="text-sm text-grey-200 mb-2">{t('reprove_insight_description')}</span>
                      <div className="flex items-center justify-end gap-4">
                        <button
                          onClick={() => closeButtonReject.current?.click()}
                          className="flex items-center justify-center py-2 px-3 rounded-md bg-primary-200 bg-opacity-20 hover:opacity-70"
                        >
                          {t('cancel')}
                        </button>
                        <button
                          onClick={() => handleApproveOrRejectInsight('refused')}
                          className="flex items-center justify-center py-2 px-3 rounded-md bg-red-500 text-white font-medium hover:opacity-70"
                        >
                          {t('reject')}
                        </button>
                      </div>
                    </div>
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <Button disabled={isSubmitting} size="medium" variant="primary" text={t('approve')} />
                </Dialog.Trigger>
                <Dialog.Portal>
                  <Dialog.Overlay
                    style={{
                      position: 'fixed',
                      width: '100vw',
                      height: '100vh',
                      inset: 0,
                      background: 'rgba(0, 0, 0, 0.75)',
                      zIndex: '10',
                    }}
                  />
                  <Dialog.Content
                    forceMount
                    className={
                      'z-40 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white px-12 py-8 rounded-lg font-poppins'
                    }
                  >
                    <div className="flex flex-col gap-5">
                      <Dialog.Close ref={closeButtonApprove} className={'hidden'}></Dialog.Close>
                      <h1 className="text-lg text-black-100 font-semibold">{t('approve_insight')}</h1>
                      <span className="text-sm text-grey-200 mb-2">{t('approve_insight_description')}</span>
                      <div className="flex items-center justify-end gap-4">
                        <button
                          onClick={() => closeButtonApprove.current?.click()}
                          className="flex items-center justify-center py-2 px-3 rounded-md bg-primary-200 bg-opacity-20 hover:opacity-70"
                        >
                          {t('cancel')}
                        </button>
                        <button
                          onClick={() => handleApproveOrRejectInsight('published')}
                          className="flex items-center justify-center py-2 px-3 rounded-md bg-green-300 hover:opacity-70"
                        >
                          {t('confirm')}
                        </button>
                      </div>
                    </div>
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>
            </div>
          )}
        </div>
      )}
    </section>
  );
}
