import { SVGProps } from 'react';

interface FacebookProps extends SVGProps<SVGSVGElement> {}

function FacebookIcon({ ...props }: FacebookProps) {
  return (
    <svg
      {...props}
      viewBox="0 0 14 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.43094 25.5V14.4572H0.75V9.85602H4.43094V6.17508C4.43094 2.37176 6.83919 0.5 10.2321 0.5C11.8572 0.5 13.2541 0.621471 13.6609 0.674845V4.65026L11.3078 4.65118C9.46278 4.65118 9.03211 5.52816 9.03211 6.81465V9.85602H13.6333L12.713 14.4572H9.03211L9.10573 25.5"
        fill="#3B5998"
      />
    </svg>
  );
}

export { FacebookIcon };
