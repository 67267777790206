import { toast } from './toast';

const { VITE_AWS_BUCKET_NAME, VITE_AWS_BUCKET_REGION } = import.meta.env;

export function isEmail(str: string) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(str);
}

export function isPhoneNumber(str: string) {
  const phoneNumberRegex =
    /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
  return phoneNumberRegex.test(str);
}

export function isBase64Img(str: string) {
  const base64Pattern = /^data:image\/(jpeg|jpg|png|gif|bmp|webp);base64,/;
  return base64Pattern.test(str);
}

export function retrieveImgS3(img: string) {
  return `https://${VITE_AWS_BUCKET_NAME}.s3.${VITE_AWS_BUCKET_REGION}.amazonaws.com/${img}`;
}

export function base64ToFile(dataURI: string) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const imageBlob = new Blob([ab], { type: mimeString });
  const imageFile = new File([imageBlob], 'screenshoot.jpg', {
    type: 'image/jpeg',
  });
  return imageFile;
}

export function showErrorMessage(error: any) {
  if (error?.response?.data?.message) {
    toast({
      label: 'Error',
      message: error.response.data.message,
      type: 'error',
    });
  } else if (error?.response?.data?.errors) {
    const message = Object.values(error.response.data.errors);
    toast({
      label: 'Error',
      message: message.join('\n'),
      type: 'error',
    });
  } else if (error?.response?.data?.content?.message) {
    toast({
      label: 'Error',
      message: error.response.data.content.message,
      type: 'error',
    });
  } else {
    toast({
      label: 'Error',
      message: 'Unknown error',
      type: 'error',
    });
  }
}

export function getDefaultImages(image: string) {
  return `https://${VITE_AWS_BUCKET_NAME}.s3.${VITE_AWS_BUCKET_REGION}.amazonaws.com/public/${image}`;
}

export function isUUID(str: string) {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(str);
}
