import * as Dialog from '@radix-ui/react-dialog';
import { format } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Event, useEvent } from '@hooks/useEvent';
import { useUser } from '@hooks/useUser';

import { Button } from '@components/Button';
import Loading from '@components/Loading';

import bell from '@assets/icons/Event/bell.svg';
import blockQuote from '@assets/icons/Event/block-quote.svg';
import clock from '@assets/icons/Event/clock.svg';
import locationMark from '@assets/icons/Event/location-marker.svg';
import repeat from '@assets/icons/Event/repeat.svg';
import userGroup from '@assets/icons/Event/user-group.svg';

import { Moon } from '@phosphor-icons/react';
import { enUS, pt } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { toast } from 'utils/toast';

export default function ReadEvent() {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [event, setEvent] = useState<Event | null>(null);
  const { eventUuid } = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { getEventByUuid, deleteEvent } = useEvent();
  const { user } = useUser();
  const [searchParams] = useSearchParams();

  const finish = searchParams.get('finish');

  const closeButton = useRef<HTMLButtonElement>(null);

  const getEvent = useCallback(async () => {
    const { event } = await getEventByUuid(eventUuid!);
    setEvent(event);
    setIsLoading(false);
  }, [eventUuid, getEventByUuid]);

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  function getNotificationLabel(notificationType: string) {
    switch (notificationType) {
      case 'one_hour_before':
        return t('one_hour_before');
      case 'thirty_minutes_before':
        return t('thirty_minutes_before');
      case 'one_day_before':
        return t('one_day_before');
      default:
        return '';
    }
  }

  function getRecurringLabel(recurringType: string) {
    switch (recurringType) {
      case 'not_repeat':
        return t('none');
      case 'every_week':
        return t('every_week');
      case 'every_month':
        return t('every_month');
      case 'every_year':
        return t('every_year');
      default:
        return '';
    }
  }

  function getMoonPhaseLabel(moonType: string) {
    switch (moonType) {
      case 'Last quarter':
        return t('last_quarter');
      case 'First quarter':
        return t('first_quarter');
      case 'New moon':
        return t('new_moon');
      case 'Full moon':
        return t('full_moon');
      default:
        return '';
    }
  }

  async function handleDeleteEvent() {
    if (!eventUuid) return;
    setIsSubmitting(true);
    const response = await deleteEvent(eventUuid);
    if (response?.uuid) {
      toast({
        label: t('success'),
        message: t('event_deleted'),
        type: 'success',
      });
      navigate('/calendar');
      setIsSubmitting(false);
    }
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col items-center justify-center w-full bg-backgray min-h-[calc(100vh-88px)]">
          <main className={`flex flex-col items-center w-full max-w-[600px] mx-auto bg-white p-5 rounded-md shadow-md`}>
            <div className=" border-b-2 border-gray-200 p-4 w-full">
              <div className="flex gap-10 items-center w-full">
                <div
                  className={`min-w-12 min-h-12 max-w-12 max-h-12 rounded-full`}
                  style={{ backgroundColor: event?.event_label == null ? '#282A74' : event.event_label.color }}
                ></div>
                <p className="text-lg font-normal text-black-100 break-all">{event?.title}</p>
              </div>
            </div>

            <div className="flex flex-col w-full">
              {event?.type === 'gregorian' ? (
                <div className="flex gap-10 items-center w-full border-b-2 p-4 border-gray-200">
                  <img src={clock} alt="Clock" className="w-12 h-12" />
                  <p className="text-lg font-normal text-black-100">
                    {event?.start_time
                      ? format(new Date(event?.start_time), 'EEEE, dd MMMM . HH:mm', {
                          locale: user?.language === 'pt' ? pt : enUS,
                        })
                      : ''}{' '}
                    - <br />
                    {event?.end_time
                      ? format(new Date(event?.end_time), 'EEEE, dd MMMM . HH:mm', {
                          locale: user?.language === 'pt' ? pt : enUS,
                        })
                      : ''}
                  </p>
                </div>
              ) : (
                <div className="flex gap-10 items-center w-full border-b-2 p-4 border-gray-200">
                  <img src={clock} alt="Clock" className="w-12 h-12" />
                  <div className="text-lg font-normal text-black-100 flex items-center gap-5">
                    {event?.moon_phases_date?.map((date, index) => {
                      const dateArray = date.split('-');
                      if (user?.language === 'pt')
                        return (
                          <span key={index}>
                            {dateArray[2].split(' ')[0] + '/' + dateArray[1] + '/' + dateArray[0]}
                          </span>
                        );
                      else
                        return (
                          <span key={index}>
                            {dateArray[1] + '/' + dateArray[2].split(' ')[0] + '/' + dateArray[0]}
                          </span>
                        );
                    })}
                  </div>
                </div>
              )}
              <div className="flex gap-10  items-center w-full border-b-2 p-4 border-gray-200">
                <img src={blockQuote} alt="Clock" className="w-12 h-12" />
                <p className="text-lg font-normal text-black-100 break-all">{event?.description}</p>
              </div>
            </div>

            <div className="flex flex-col w-full">
              <div className="flex gap-10 items-center w-full border-b-2 border-gray-200 p-4 ">
                <img src={locationMark} alt="Clock" className="w-12 h-12" />
                <p className="text-lg font-normal text-black-100 break-all">{event?.location}</p>
              </div>
              <div className="flex gap-10 items-center w-full border-b-2 border-gray-200 p-4 ">
                <img src={bell} alt="Clock" className="w-12 h-12" />
                {event?.event_notifications && event.event_notifications.length > 0 ? (
                  event.event_notifications.map((notificationType, index) => (
                    <p key={index} className="text-lg font-normal text-black-100">
                      {getNotificationLabel(notificationType)}
                    </p>
                  ))
                ) : (
                  <p className="text-lg font-normal text-black-100">{t('no_notifications_available')}</p>
                )}
              </div>
              {/* MOON */}
              {event?.moon_phases && (
                <div className="flex gap-10 items-center w-full border-b-2 border-gray-200 p-4 ">
                  <Moon size={48} />
                  <p className="text-lg font-normal text-black-100">
                    {getMoonPhaseLabel(event?.moon_phases || '') || '-'}
                  </p>
                </div>
              )}
              {/* RECURRING */}
              <div className="flex gap-10 items-center w-full border-b-2 border-gray-200 p-4 ">
                <img src={repeat} alt="Clock" className="w-12 h-12" />
                <p className="text-lg font-normal text-black-100">{getRecurringLabel(event?.recurring || '') || '-'}</p>
              </div>
            </div>
            <div className="flex flex-col w-full p-4 ">
              <div className="flex gap-10 items-center w-full">
                <img src={userGroup} alt="Clock" className="w-12 h-12" />
                <p className="text-lg font-normal text-black-100">
                  {event?.group == undefined ? 'No group assigned' : event?.group.name}
                </p>
              </div>
            </div>
          </main>

          <div className="flex items-center gap-12 mt-12">
            {finish && (
              <Link
                className="bg-primary-200 text-white px-5 py-[10px] shadow-md font-semibold  flex items-center justify-center gap-2 rounded-[4px] hover:opacity-90 disabled:bg-grey-400 disabled:hover:opacity-100"
                to={'/calendar'}
              >
                {t('save')}
              </Link>
            )}
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <Button disabled={isSubmitting} size="medium" variant="primary" boxShadow text={t('delete')} />
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay
                  style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                    inset: 0,
                    background: 'rgba(0, 0, 0, 0.75)',
                    zIndex: '10',
                  }}
                />
                <Dialog.Content
                  forceMount
                  className={
                    'z-40 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white px-12 py-8 rounded-lg font-poppins'
                  }
                >
                  <div className="flex flex-col gap-5">
                    <Dialog.Close ref={closeButton} className={'hidden'}></Dialog.Close>
                    <h1 className="text-lg text-black-100 font-semibold">{t('delete_event_title')}</h1>
                    <span className="text-sm text-grey-200 mb-2">{t('delete_event_description')}</span>
                    <div className="flex items-center justify-end gap-4">
                      <button
                        onClick={() => closeButton.current?.click()}
                        className="flex items-center justify-center py-2 px-3 rounded-md bg-primary-200 bg-opacity-20 hover:opacity-70"
                      >
                        {t('cancel')}
                      </button>
                      <button
                        onClick={handleDeleteEvent}
                        className="flex items-center justify-center py-2 px-3 rounded-md bg-green-300 hover:opacity-70"
                      >
                        {t('confirm')}
                      </button>
                    </div>
                  </div>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
            <Button
              onClick={() => navigate('/calendar/edit-event/' + (eventUuid || ''))}
              disabled={isSubmitting}
              size="medium"
              variant="primary"
              boxShadow
              text={t('edit')}
            />
          </div>
        </div>
      )}
    </>
  );
}
