import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Dialog from '@radix-ui/react-dialog';

interface ConfirmActionModalProps {
  title: string;
  description: string;
  id: string;
  handleDelete: (id: string) => void;
}

export default function ConfirmActionModal({ title, description, handleDelete, id }: ConfirmActionModalProps) {
  const { t } = useTranslation();
  const closeButton = useRef<HTMLButtonElement>(null);

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '10',
        }}
      />
      <Dialog.Content
        forceMount
        className={
          'z-40 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white px-12 py-8 rounded-lg font-poppins'
        }
      >
        <div className="flex flex-col gap-5">
          <Dialog.Close ref={closeButton} className={'hidden'}></Dialog.Close>
          <h1 className="text-lg text-black-100 font-semibold">{title}</h1>
          <span className="text-sm text-grey-200 mb-2">{description}</span>
          <div className="flex items-center justify-end gap-4">
            <button
              onClick={() => closeButton.current?.click()}
              className="flex items-center justify-center py-2 px-3 rounded-md bg-primary-200 bg-opacity-20 hover:opacity-70"
            >
              {t('cancel')}
            </button>
            <button
              onClick={() => handleDelete(id)}
              className="flex items-center justify-center py-2 px-3 rounded-md bg-green-300 hover:opacity-70"
            >
              {t('confirm')}
            </button>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
