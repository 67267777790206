import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';
import { useUser } from '@hooks/useUser';

import { Button } from '@components/Button';
import { Input } from '@components/Input';

import ellipse10 from '@assets/profiles/Ellipse 10.svg';
import ellipse11 from '@assets/profiles/Ellipse 11.svg';
import ellipse12 from '@assets/profiles/Ellipse 12.svg';
import ellipse9 from '@assets/profiles/Ellipse 9.svg';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import addYears from 'date-fns/addYears';
import dayjs, { Dayjs } from 'dayjs';
import { getDefaultImages, isBase64Img, isEmail, retrieveImgS3 } from 'utils/auxFunctions';
import { uploadToS3 } from 'utils/awsS3';
import { toast } from 'utils/toast';

const profileConfigurationFormSchema = z.object({
  name: z.string(),
  lastName: z.string(),
});

type profileConfigurationInputs = z.infer<typeof profileConfigurationFormSchema>;

const languages = [
  { value: 'en', name: '🇬🇧 English (EN)' },
  { value: 'pt', name: '🇵🇹 Portuguese (PT)' },
];

export default function ProfileConfigurationForm() {
  const { updateUser, user } = useUser();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { register, handleSubmit } = useForm<profileConfigurationInputs>({
    resolver: zodResolver(profileConfigurationFormSchema),
    defaultValues: {
      name: user?.first_name ?? '',
      lastName: user?.last_name ?? '',
    },
  });
  const [previewImage, setPreviewImage] = useState<string>(user?.image ?? ellipse9);
  const [birthDate, setBirthDate] = useState<Dayjs | null>(
    dayjs(
      user?.birth_date ??
        new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()).toString(),
    ),
  );
  const [uploadedFile, setUploadedFile] = useState<File | string>(user?.image ?? 'profile-default-1.png');
  const [selectedLanguage, setSelectedLanguage] = useState<string>(languages[0].value);
  const [email, setEmail] = useState<string>('');
  const [userLanguage, setUserLanguage] = useState<string>('en');

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    if (!user.email?.includes('@privaterelay.appleid.com')) {
      setEmail(user.email ?? '');
    }
    const browserLanguage = navigator.language;

    if (browserLanguage.includes('pt')) {
      setUserLanguage('pt');
    } else {
      setUserLanguage('en');
    }
  }, [navigate, user]);

  useEffect(() => {
    if (!previewImage) {
      setUploadedFile('profile-default-1.png');
    }
  }, [previewImage]);

  const handleImageChange = (event: any) => {
    event.preventDefault();
    let file: File = {} as File;

    if (event.target.files) {
      file = event.target.files?.[0];
    } else if (event.dataTransfer.files) {
      file = event.dataTransfer.files?.[0];
    }

    if (!file) return;

    if (!/image.*/.exec(file.type)) {
      toast({
        label: 'File import failed',
        message: userLanguage === 'pt' ? 'O arquivo não é uma imagem' : 'The file is not an image',
        type: 'error',
      });
      return;
    }
    const fileSize = file.size;
    if (fileSize <= 2000000) {
      setUploadedFile(file);
      // Cria uma URL temporária para visualização
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      toast({
        label: 'File import failed',
        message: userLanguage === 'pt' ? 'A imagem precisa ser menor que 2MB' : 'The image needs to be less than 2MB',
        type: 'error',
      });
    }
  };

  async function handleProfileConfiguration(data: profileConfigurationInputs) {
    setIsSubmitting(true);
    if (!user?.uuid) {
      toast({
        label: 'Error',
        message: userLanguage === 'pt' ? 'Utilizador não encontrado' : 'User not found',
        type: 'error',
      });
      return;
    }
    if (!birthDate || !birthDate?.toDate()) {
      toast({
        label: 'Error',
        message: userLanguage === 'pt' ? 'Data de nascimento é obrigatório' : 'Birth date is required',
        type: 'error',
      });
      return;
    }
    if (user?.provider === 'apple' && !email) {
      toast({
        label: 'Error',
        message: userLanguage === 'pt' ? 'Email é obrigatório' : 'Email is required',
        type: 'error',
      });
      return;
    }
    if (user?.provider === 'apple' && !isEmail(email)) {
      toast({
        label: 'Error',
        message: userLanguage === 'pt' ? 'Email não é valido' : 'Email is not valid',
        type: 'error',
      });
      return;
    }
    const birthDay = birthDate.toDate();
    const today = new Date();
    let age = today.getFullYear() - birthDay.getFullYear();
    const m = today.getMonth() - birthDay.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDay.getDate())) {
      age--;
    }
    if (age < 18) {
      toast({
        label: 'Error',
        message: userLanguage === 'pt' ? 'Voce deve ter pelo menos 18 anos' : 'You must be at least 18 years old',
        type: 'error',
      });
      return;
    }
    let imgURL = '';
    if (isBase64Img(previewImage)) {
      if (uploadedFile instanceof File) {
        const { key, error } = await uploadToS3(uploadedFile, user?.uuid);
        if (error) {
          toast({
            label: 'Error',
            message: userLanguage === 'pt' ? 'Falha ao fazer upload da imagem' : 'Failed to upload image',
            type: 'error',
          });
        } else if (key) imgURL = retrieveImgS3(key);
      }
    } else if (typeof uploadedFile === 'string' && !uploadedFile.includes('http')) {
      imgURL = getDefaultImages(uploadedFile);
    } else {
      imgURL = previewImage;
    }
    const objToPost = {
      first_name: data.name,
      last_name: data.lastName,
      image: imgURL,
      birth_date: birthDate.format('YYYY-MM-DD'),
      language: selectedLanguage,
      country_id: selectedLanguage === 'pt' ? 1 : 2,
    } as any;
    if (user?.provider === 'apple') {
      objToPost.email = email;
    }
    const response = await updateUser(objToPost).finally(() => setIsSubmitting(false));
    if (response.uuid) {
      toast({
        label: 'Success',
        message: userLanguage === 'pt' ? 'Perfil atualizado com sucesso' : 'Profile updated successfully',
        type: 'success',
      });
      navigate('/feed');
    }
  }

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <main className="h-[80vh]">
        <form
          onSubmit={handleSubmit(handleProfileConfiguration)}
          action=""
          className="flex flex-col items-center w-[420px] bg-white p-10 shadow-md border border-gray-100"
        >
          <div className="text-center mb-16 mt-14">
            <h1 className="text-primary-200 text-4xl font-medium text-center">
              {userLanguage === 'pt' ? 'Bem Vindo!' : 'Welcome!'}
            </h1>
            <span className="text-grey-200 text-xl">
              {userLanguage === 'pt' ? 'Vamos criar seu perfil' : 'Let’s create your profile'}
            </span>
          </div>
          <label
            htmlFor="select-image"
            className="flex flex-col items-center gap-5 cursor-pointer"
            onDrop={handleImageChange}
            onDragOver={(e) => e.preventDefault()}
            aria-hidden
          >
            <img className="object-cover w-[100px] h-[100px] rounded-full" src={previewImage} alt="preview" />
            <input
              id="select-image"
              accept="image/*"
              type="file"
              className="hidden"
              onClick={(e) => ((e.target as any).value = null)}
              onChange={handleImageChange}
            />
            <span className="text-grey-100 text-sm underline">
              {userLanguage === 'pt' ? 'Selecionar Imagem' : 'Choose Image'}
            </span>
          </label>

          <span className="text-grey-100 text-sm mt-10">
            {userLanguage === 'pt' ? 'Ou selecione uma das imagens definidas' : 'Or choose one of our defaults'}
          </span>

          <div className="flex items-center gap-[10px] mt-4">
            <img
              className="cursor-pointer"
              onClick={() => {
                setPreviewImage(ellipse9);
                setUploadedFile('profile-default-1.png');
              }}
              src={ellipse9}
              aria-hidden
              alt="profile-default-1.png"
            />
            <img
              className="cursor-pointer"
              onClick={() => {
                setPreviewImage(ellipse10);
                setUploadedFile('profile-default-3.png');
              }}
              src={ellipse10}
              aria-hidden
              alt="profile-default-3.png"
            />
            <img
              className="cursor-pointer"
              onClick={() => {
                setPreviewImage(ellipse11);
                setUploadedFile('profile-default-2.png');
              }}
              src={ellipse11}
              aria-hidden
              alt="profile-default-2.png"
            />
            <img
              className="cursor-pointer"
              onClick={() => {
                setPreviewImage(ellipse12);
                setUploadedFile('profile-default-4.png');
              }}
              src={ellipse12}
              alt="profile-default-4.png"
              aria-hidden
            />
          </div>

          <div className="flex flex-col gap-5 my-11 w-full">
            <Input
              {...register('name')}
              maxLength={20}
              data-test="profile-name"
              label={userLanguage === 'pt' ? 'Nome' : 'Name'}
              identificator="name"
              required
            />
            <Input
              {...register('lastName')}
              maxLength={20}
              data-test="profile-last-name"
              label={userLanguage === 'pt' ? 'Sobrenome' : 'Last Name'}
              identificator="lastName"
              required
            />
            {user?.provider === 'apple' && (
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label="Email"
                identificator="email"
                required
              />
            )}
            <div className="w-full relative">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: '#828282',
                      },
                    },
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: '500',
                    border: '1px solid #828282',
                    borderRadius: '6px',
                    width: '100%',
                  }}
                  value={birthDate}
                  onChange={(newValue) => setBirthDate(newValue)}
                  maxDate={dayjs(addYears(new Date(), -18))}
                  className="absolute"
                />
              </LocalizationProvider>
              <label className="absolute text-sm text-grey-300 font-semibold  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-grey-300  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2">
                {userLanguage === 'pt' ? 'Data de Nascimento' : 'Birth Date'}
              </label>
            </div>
            {/* <Input
          {...register('birthDate')}
          type="date"
          identificator="birthDate"
          label="Birth Date"
          required
        /> */}
            <div className="flex flex-col relative">
              <label
                htmlFor="countryCode"
                className="absolute text-sm text-grey-300 font-semibold  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-grey-300  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2"
              >
                {userLanguage === 'pt' ? 'Idioma' : 'Language'}
              </label>
              <select
                id="countryCode"
                name="countryCode"
                data-test="profile-select-country"
                className="bg-transparent border-2 border-grey-300 rounded-lg px-1 py-4 text-sm outline-none"
                onChange={(event) => setSelectedLanguage(event.target.value)}
              >
                {languages.map((language) => (
                  <option selected={language.value === selectedLanguage} key={language.value} value={language.value}>
                    {language.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <span className="mb-12">
            <Button
              data-test="profile-submit"
              disabled={isSubmitting}
              text="Continue"
              type="submit"
              size="large"
              variant="primary"
            />
          </span>
        </form>
      </main>
    </div>
  );
}
