import { useEffect, useState } from 'react';

import MonthCard from './MonthCard';
import moment from 'moment';
import { useUser } from '@hooks/useUser';

export const monthsEn = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const monthsPt = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export function calendarBuild(value: moment.Moment) {
  const startDay = value.clone().startOf('month').startOf('isoWeek');
  const endDay = value.clone().endOf('month').endOf('isoWeek');
  const day = startDay.clone().subtract(1, 'day');

  const calendar = [];

  while (day.isBefore(endDay, 'day')) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => day.add(1, 'day').clone())
    );
  }

  return calendar;
}

interface CalendarProps {
  initialDate: string;
  setInitialDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  date1: Date | null;
  date2: Date | null;
}

export default function DayRangePicker({
  initialDate,
  setInitialDate,
  endDate,
  setEndDate,
  date1,
  date2,
}: CalendarProps) {
  const { user } = useUser();
  const [currentYear, setCurrentYear] = useState(2023);
  const [currentMonthIndex, setCurrentMonthIndex] = useState<number | null>(null);

  useEffect(() => {
    const today = new Date();
    const monthIndex = today.getMonth();
    setCurrentMonthIndex(monthIndex);
  }, []);

  return (
    <>
      {currentMonthIndex !== null ? (
        <div className="w-[400px] h-[350px] flex flex-col items-center font-poppins bg-white pt-5 px-5 shadow-md rounded-md">
          <div className="flex items-center justify-center w-full gap-8 mb-5">
            <button
              onClick={() => {
                if (currentMonthIndex! > 0) {
                  setCurrentMonthIndex(currentMonthIndex! - 1);
                } else {
                  setCurrentMonthIndex(11);
                  setCurrentYear(currentYear - 1);
                }
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M13.5 7.5L9 12L13.5 16.5"
                  stroke="#282A74"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <span className="text-primary-200 font-semibold w-[150px] text-center">
              {user?.language === 'en' ? monthsEn[currentMonthIndex] : monthsPt[currentMonthIndex]} {currentYear}
            </span>
            <button
              onClick={() => {
                if (currentMonthIndex! < 11) {
                  setCurrentMonthIndex(currentMonthIndex! + 1);
                } else {
                  setCurrentMonthIndex(0);
                  setCurrentYear(currentYear + 1);
                }
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M10.5 7.5L15 12L10.5 16.5"
                  stroke="#282A74"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="h-full px-2 py-2">
            <MonthCard
              month={monthsEn[currentMonthIndex]}
              currentYear={currentYear}
              initialDate={initialDate}
              setInitialDate={setInitialDate}
              endDate={endDate}
              setEndDate={setEndDate}
              date1={date1}
              date2={date2}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}
