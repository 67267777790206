import { create } from 'zustand';
import { api } from '../services/axios';
import { isUUID, showErrorMessage } from '../utils/auxFunctions';
import { deleteFromS3 } from '../utils/awsS3';
import { Category } from './useCategory';

export interface Pagination {
  current_page: number;
  last_page: number;
  total: number;
  last_page_url: string | null;
}

export interface FirstMembers {
  image?: string;
  first_name: string;
}

export interface Group {
  id?: number;
  uuid: string;
  name: string;
  description: string;
  slug?: string;
  image?: string;
  thumbnail800?: string;
  thumbnail1600?: string;
  creator_id?: number;
  users_count?: number;
  favorite: number;
  role: string;
  last6Members?: FirstMembers[];
  last4Members?: FirstMembers[];
  categories_parent?: Category[];
  user_created?: {
    uuid: string;
    first_name: string;
    image?: string;
  };
  status?: string;
  storage_limit: number;
  storage_used: number;
  plan_name?: string;
  code?: string;
  share_link?: string;
  time_expire_link?: string;
}

export interface GroupMembers {
  uuid: string;
  user: {
    id?: number;
    uuid: string;
    image?: string;
    email: string;
    first_name?: string;
  };
  role: {
    id?: number;
    name: 'Member' | 'Admin' | 'Visitor';
  };
}

export interface Guest {
  id: number;
  uuid: string;
  guest_email: string;
  guest_phone: string;
  is_new_account: number;
  expires_at: Date;
  role_id: number;
  group_id: number;
  is_code: number;
  is_approved: number;
  user_id: number;
  is_approved_admin: number;
}

interface UpdateGroupDTO {
  uuid: string;
  name: string;
  image: string;
  status: 'draft' | 'published';
}

interface GroupsProps {
  pagination: Pagination;
  groups: Group[];
  group: Group;
  groupMembers: GroupMembers[];
  guests: Guest[];
  getGroups: (params?: string) => Promise<Group[]>;
  getGroupByUuid: (uuid: string, params?: string) => Promise<Group>;
  updateGroup: (objToUpdate: Partial<UpdateGroupDTO>) => Promise<Group>;
  createGroup: (name: string, image?: string) => Promise<Group>;
  getGroupMembers: (uuid: string, params?: string) => Promise<void>;
  updateFavorite: (groupUuid: string, favorite: boolean) => Promise<void>;
  editUserRole: (groupUuid: string, memberUuid: string, role: string) => Promise<any>;
  removeUserFromGroup: (groupUuid: string, memberUuid: string) => Promise<any>;
  exitGroup: (groupUuid: string) => Promise<any>;
  inviteByEmailOrPhone: (groupUuid: string, email_or_phone: string) => Promise<any>;
  bulkAddMembersToGroup: (groupUuid: string, members: string[]) => Promise<any>;
  checkStorage: (groupUuid: string, byte: number) => Promise<boolean>;
  getGuestsForGroup: (groupUuid: string, params?: string) => Promise<Guest[]>;
  verifyGroupLimit: () => Promise<boolean>;
  approveGuest: (groupUuid: string, guestUuid: string) => Promise<any>;
  rejectGuest: (groupUuid: string, guestUuid: string) => Promise<any>;
  denyGuest: (groupUuid: string, guestUuid: string) => Promise<any>;
  joinGuest: (groupUuid: string, guestUuid: string) => Promise<any>;
  validateShareLink: (code: string) => Promise<'valid' | 'invalid' | 'already-member'>;
  acceptShareLinkInvite: (code: string) => Promise<any>;
}

//const navigate = useNavigate();

export const useGroups = create<GroupsProps>((set, get) => ({
  pagination: {
    current_page: 1,
    last_page: 1,
    total: 1,
    last_page_url: null,
  },
  groups: [],
  group: {} as Group,
  groupMembers: [],
  guests: [],
  getGroups: async (params?: string) => {
    try {
      const { data } = await api.get(`/groups${params ? `${params}` : ''}`);
      if (data.content.data) {
        set({ groups: data.content.data.data });
        set({
          pagination: {
            current_page: data.content.data.current_page,
            last_page: data.content.data.last_page,
            total: data.content.data.total,
            last_page_url: data.content.data.last_page_url,
          },
        });
        return data.content.data.data;
      }
    } catch (error) {
      showErrorMessage(error);
    }
  },

  getGroupByUuid: async (uuid: string, params?: string) => {
    try {
      const { data } = await api.get(`/groups/${uuid}` + (params ? params : ''));
      if (data?.content?.data) {
        set({ group: data.content.data });
        return data.content.data;
      } else {
        if (data?.uuid) {
          set({ group: data });
          return data;
        }
      }
    } catch (error) {
      showErrorMessage(error);
    }
  },

  createGroup: async (name: string, image?: string, status?: string) => {
    try {
      const { data } = await api.post('/groups', { name, image, status: status ? status : 'published' });
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  updateGroup: async (objToUpdate: Partial<UpdateGroupDTO>) => {
    try {
      const group = get().group;
      if (!group) return;
      if (group.image && objToUpdate.image) {
        const imageKey = group.image.split('/').pop();
        if (imageKey && isUUID(imageKey)) deleteFromS3(imageKey);
      }
      const { data } = await api.put(`/groups/${objToUpdate.uuid}`, objToUpdate);
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  getGroupMembers: async (uuid: string, params?: string) => {
    try {
      const { data } = await api.get(`/groups/${uuid}/member-groups` + (params ? params : ''));
      if (data.content.data) {
        set({ groupMembers: data.content.data.data });
        set({
          pagination: {
            current_page: data.content.data.current_page,
            last_page: data.content.data.last_page,
            total: data.content.data.total,
            last_page_url: data.content.data.last_page_url,
          },
        });
        return data.content.data.data;
      }
    } catch (error) {
      showErrorMessage(error);
    }
  },

  updateGroupMember: async (groupUuid: string, memberGroupUuid: string, role_id: number) => {
    try {
      await api.put(`/groups/${groupUuid}/member-groups/${memberGroupUuid}`, {
        role_id,
      });
    } catch (error) {
      showErrorMessage(error);
    }
  },

  updateFavorite: async (groupUuid: string, favorite: boolean) => {
    try {
      await api.put(`/groups/${groupUuid}/favorite`, { favorite });
      const newGroups = get().groups;
      const index = newGroups.findIndex((group) => group.uuid === groupUuid);
      newGroups[index].favorite = favorite ? 1 : 0;
      set({ groups: newGroups });
    } catch (error) {
      showErrorMessage(error);
    }
  },

  editUserRole: async (groupUuid: string, memberUuid: string, role: string) => {
    try {
      const { data } = await api.put(`/groups/${groupUuid}/member-groups/${memberUuid}`, { role });
      return data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  removeUserFromGroup: async (groupUuid: string, memberUuid: string) => {
    try {
      const { data } = await api.delete(`/groups/${groupUuid}/member-groups/${memberUuid}`);
      return data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  exitGroup: async (groupUuid: string) => {
    try {
      const { data } = await api.delete(`/groups/${groupUuid}/exit`);
      return data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  inviteByEmailOrPhone: async (groupUuid: string, email_or_phone: string) => {
    try {
      const { data } = await api.post(`/groups/${groupUuid}/invite-members`, {
        email_or_phone,
      });
      return data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  bulkAddMembersToGroup: async (groupUuid: string, members: string[]) => {
    try {
      const { data } = await api.post(`/groups/${groupUuid}/bulk-members`, {
        members,
      });
      return data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  checkStorage: async (groupUuid: string, byte: number) => {
    try {
      const { data } = await api.post(`/groups/${groupUuid}/check-storage`, {
        byte,
      });
      return data?.content?.data[0] || false;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },

  getGuestsForGroup: async (groupUuid: string, params?: string) => {
    try {
      const { data } = await api.get(`/groups/${groupUuid}/guests` + (params ? '?' + params : ''));
      set({ guests: data.content.data.data });
      return data.content.data.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  verifyGroupLimit: async () => {
    try {
      const { data } = await api.get(`/groups/check-free-group`);
      if (!data?.content?.data?.status) {
        return true;
      }
      return false;
    } catch (error) {
      showErrorMessage(error);
      return false;
    }
  },

  approveGuest: async (groupUuid: string, guestUuid: string) => {
    try {
      const { data } = await api.post(`/groups/${groupUuid}/guests/${guestUuid}/approve`);

      const newGuestList = get().guests.map((guest) => {
        if (guest.uuid === guestUuid) {
          return { ...guest, is_approved: 1 };
        }
        return guest;
      });

      set({ guests: newGuestList });
      return data.content;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  joinGuest: async (groupUuid: string, guestUuid: string) => {
    try {
      const response = await api.post(`/groups/${groupUuid}/guests/${guestUuid}/join`);

      const newGuestList = get().guests.map((guest) => {
        if (guest.uuid === guestUuid) {
          return { ...guest, is_approved: 1 };
        }
        return guest;
      });

      set({ guests: newGuestList });
      return response;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  denyGuest: async (groupUuid: string, guestUuid: string) => {
    try {
      const response = await api.post(`/groups/${groupUuid}/guests/${guestUuid}/reject-by-user`);

      const newGuestList = get().guests;
      set({ guests: newGuestList.filter((guest) => guest.uuid !== guestUuid) });
      return response;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  rejectGuest: async (groupUuid: string, guestUuid: string) => {
    try {
      const { data } = await api.post(`/groups/${groupUuid}/guests/${guestUuid}/reject`);

      const newGuestList = get().guests;
      set({ guests: newGuestList.filter((guest) => guest.uuid !== guestUuid) });
      return data.content;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  validateShareLink: async (code: string) => {
    try {
      const { data } = await api.get(`/check-code-group`, {
        params: {
          code,
        },
      });
      if (data?.content?.data) set({ group: data.content.data });
      if (data?.headerCode === 202) return 'already-member';
      return 'valid';
    } catch (error) {
      return 'invalid';
    }
  },

  acceptShareLinkInvite: async (code: string) => {
    try {
      const { data } = await api.post('validate-share-link', {
        code,
      });
      return data;
    } catch (error) {
      showErrorMessage(error);
    }
  },
}));
