import { useCallback } from 'react';
import { PageProps } from '../pages/Groups';
import { Pagination } from './useGroups';
export const usePagination = () => {
  const setPagination = useCallback(
    (pagination: Pagination, setPage: React.Dispatch<React.SetStateAction<PageProps | undefined>>) => {
      let current = 0;
      const next: number[] = [];
      const previous: number[] = [];
      if (pagination) {
        for (let i: number = 1; i <= pagination.last_page; i++) {
          if (pagination.current_page === i) {
            current = i;
          }
          if (pagination.current_page > i) {
            previous.push(i);
          }
          if (pagination.current_page < i) {
            next.push(i);
          }
        }
      }
      setPage({ current: current, next: next, previous: previous });
    },
    [],
  );

  return { setPagination };
};
