import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';
import { useCalendar } from '@hooks/useCalendar';
import { CreateEventDTO, useEvent } from '@hooks/useEvent';
import { useGroups } from '@hooks/useGroups';
import { useUser } from '@hooks/useUser';

import { Button } from '@components/Button';
import DatePickModal from '@components/DatePickModal';
import NotificationPickModal from '@components/NotificationPickModal';
import RecurrencePickModal from '@components/RecurrencePickModal';
import Select from '@components/Select';

import loading from '@assets/icons/loading.gif';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { WarningCircle } from '@phosphor-icons/react';
import { FaCheck } from 'react-icons/fa';
import { toast } from 'utils/toast';

import dayjs, { type Dayjs } from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/pt';

const createEventFormSchema = z.object({
  title: z.string(),
  description: z.string(),
  location: z.string(),
});

type createEventInputs = z.infer<typeof createEventFormSchema>;

export default function CreateEventPage() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<createEventInputs>({
    resolver: zodResolver(createEventFormSchema),
  });

  const { user } = useUser();

  const [auxNotifications, setAuxNotifications] = useState<{ label: string; value: string; order: number }[]>([]);

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');

  const [selectedStartDate, setSelectedStartDate] = useState<string>(dayjs().format('DD/MM/YYYY'));
  const [selectedStartDateFormatted, setSelectedStartDateFormatted] = useState<string>('');
  const [selectedStartTime, setSelectedStartTime] = useState<string>(dayjs().format('HH:mm'));

  const [selectedEndDate, setSelectedEndDate] = useState<string>(dayjs().format('DD/MM/YYYY'));
  const [selectedEndDateFormatted, setSelectedEndDateFormatted] = useState<string>('');
  const [selectedEndTime, setSelectedEndTime] = useState<string>(dayjs().add(1, 'hour').format('HH:mm'));

  const [notifications, setNotifications] = useState<{ label: string; value: string; order: number }[]>([]);

  const [recurrence, setRecurrence] = useState<{ label: string; value: string } | null>(null);
  const [endDateError, setEndDateError] = useState(false);
  const params = new URLSearchParams(document.location.search);
  const group = params.get('group');
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>(group ?? '');
  const [selectedMoonPhase, setSelectedMoonPhase] = useState<{ label: string; value: string } | null>(null);
  const [currentMoonPhase, setCurrentMoonPhase] = useState<string>('');

  const [calendarType, setCalendarType] = useState<'gregorian' | 'lunar'>('gregorian');

  const [lunarDatePicker, setLunarDatePicker] = useState<Dayjs | null>(dayjs(new Date()));
  const [monthPhase, setMonthPhase] = useState<string>('');
  const [moonPhasesDate, setMoonPhasesDate] = useState<string[]>([]);

  const [isFetchingMoonPhases, setIsFetchingMoonPhases] = useState(false);

  const navigate = useNavigate();

  const { getGroups, groups } = useGroups();
  const { createEvent } = useEvent();
  const { searchLunarRecurrencies, moonPhasesRecurrencies, clearMoonPhasesRecurrencies } = useCalendar();

  useEffect(() => {
    clearMoonPhasesRecurrencies();
    getGroups('?take=100&status=published');
  }, [clearMoonPhasesRecurrencies, getGroups]);

  async function handleCreateNewEvent(data: createEventInputs) {
    const { description, location, title } = data;
    if (!selectedGroup) {
      toast({
        label: t('error'),
        message: t('missing_informations'),
        type: 'error',
      });
      return;
    }
    if (calendarType === 'gregorian') {
      if (!selectedStartDate || !selectedStartTime || !selectedEndDate || !selectedEndTime) {
        toast({
          label: t('error'),
          message: t('missing_informations'),
          type: 'error',
        });
        return;
      }

      if (endDateError) {
        toast({
          label: t('error'),
          message: t('start_date_must_be_less_than_end_date'),
          type: 'error',
        });
        return;
      }
    } else {
      if (moonPhasesDate?.length === 0) {
        toast({
          label: t('error'),
          message: t('please_select_at_least_one_moon_phase_recurrencie'),
          type: 'error',
        });
        return;
      }
      if (!currentMoonPhase) {
        toast({
          label: t('error'),
          message: t('missing_informations'),
          type: 'error',
        });
        return;
      }
    }

    const notificationsToSend: string[] = [];
    notifications.map((notification) => {
      if (notification.value !== 'none') notificationsToSend.push(notification.value);
    });

    const startDateArray = selectedStartDate.split('/');
    const endDateArray = selectedEndDate.split('/');
    const newEventObj: CreateEventDTO = {
      description,
      location,
      title,
      group_id: selectedGroup,
      type: calendarType,
      recurring: selectedMoonPhase ? 'not_repeat' : recurrence?.value || 'not_repeat',
      ...(notificationsToSend.length > 0 && { notifications: notificationsToSend }),
      ...(calendarType === 'gregorian' && {
        start_time: startDateArray[2] + '-' + startDateArray[1] + '-' + startDateArray[0] + ' ' + selectedStartTime,
        end_time: endDateArray[2] + '-' + endDateArray[1] + '-' + endDateArray[0] + ' ' + selectedEndTime,
      }),
      ...(calendarType === 'lunar' && {
        moon_phases_date: moonPhasesDate,
        moon_phases: currentMoonPhase,
        start_time: '',
        end_time: '',
      }),
    };
    const response = await createEvent(newEventObj);
    if (response.uuid) {
      toast({
        label: t('success'),
        message: t('event_created'),
        type: 'success',
      });
      navigate(`${response.uuid}`);
    }
  }

  async function handlesearchLunarRecurrencies(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    if (!lunarDatePicker) {
      toast({
        label: t('error'),
        message: t('please_select_an_date'),
        type: 'error',
      });
      return;
    }
    if (!selectedMoonPhase) {
      toast({
        label: t('error'),
        message: t('please_select_a_moon_phase'),
        type: 'error',
      });
      return;
    }
    setIsFetchingMoonPhases(true);
    const response = await searchLunarRecurrencies({
      year: lunarDatePicker.year().toString(),
      month: (lunarDatePicker.month() + 1).toString(),
      moon: selectedMoonPhase.value,
    });
    if (response) {
      setMoonPhasesDate([]);
      setMonthPhase(lunarDatePicker.month().toString());
      setCurrentMoonPhase(selectedMoonPhase.value);
      response.map((moonPhase) => {
        const date = new Date(moonPhase?.year || 1, Number(lunarDatePicker.month().toString()), moonPhase?.day || 1);
        setMoonPhasesDate((prev) => [
          ...prev,
          dayjs(date).format('YYYY-MM-DD') + ' ' + (moonPhase?.timeEvent || '00:00:00'),
        ]);
      });
    }
    setIsFetchingMoonPhases(false);
  }

  useEffect(() => {
    if (!selectedStartDate) return;
    const startDateArray = selectedStartDate.split('/');
    const endDateArray = selectedEndDate.split('/');
    if (user?.language === 'pt') {
      setSelectedStartDateFormatted(startDateArray[0] + '/' + startDateArray[1] + '/' + startDateArray[2]);
      setSelectedEndDateFormatted(endDateArray[0] + '/' + endDateArray[1] + '/' + endDateArray[2]);
    } else {
      setSelectedStartDateFormatted(startDateArray[1] + '/' + startDateArray[0] + '/' + startDateArray[2]);
      setSelectedEndDateFormatted(endDateArray[1] + '/' + endDateArray[0] + '/' + endDateArray[2]);
    }
    const startTimeArray = selectedStartTime.split(':');
    const endTimeArray = selectedEndTime.split(':');
    const startDateMilliseconds = new Date(
      Number(startDateArray[2]),
      Number(startDateArray[1]),
      Number(startDateArray[0]),
      Number(startTimeArray[0]),
      Number(startTimeArray[1]),
    ).getTime();
    const endDateMilliseconds = new Date(
      Number(endDateArray[2]),
      Number(endDateArray[1]),
      Number(endDateArray[0]),
      Number(endTimeArray[0]),
      Number(endTimeArray[1]),
    ).getTime();
    if (endDateMilliseconds <= startDateMilliseconds) {
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }
  }, [selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, user?.language]);

  useEffect(() => {
    if (calendarType === 'gregorian') {
      if (!selectedStartDate || !selectedStartTime) return;
      const startDateArray = selectedStartDate.split('/');
      const startTimeArray = selectedStartTime.split(':');
      const date = new Date(
        Number(startDateArray[2]),
        Number(startDateArray[1]) - 1,
        Number(startDateArray[0]),
        Number(startTimeArray[0]),
        Number(startTimeArray[1]),
      );
      setSelectedEndDate(dayjs(date).add(1, 'hour').format('DD/MM/YYYY'));
      setSelectedEndTime(dayjs(date).add(1, 'hour').format('HH:mm'));
    }
  }, [calendarType, selectedStartTime, selectedStartDate]);

  return (
    <section className="flex flex-col items-center w-full py-12 gap-8 bg-backgray min-h-[calc(100vh-88px)]">
      <form
        id="create-event"
        onSubmit={handleSubmit(handleCreateNewEvent)}
        className="flex flex-col desktop:w-[588px] tablet:w-[60vw] p-5 rounded-md gap-11 bg-white shadow-md"
      >
        <div className="flex gap-3 w-full">
          <label htmlFor="title" className="text-lg text-grey-200 w-[130px]">
            {t('title')}
          </label>
          <input
            maxLength={50}
            {...register('title')}
            className="w-[317px] px-2 outline-none border-b border-grey-500 bg-transparent text-lg text-black-100 placeholder:text-black-100 placeholder:text-opacity-40"
            type="text"
            id="title"
            placeholder={t('add_title')}
            required
          />
        </div>

        <div className="flex gap-3 w-full">
          <label htmlFor="description" className="text-lg text-grey-200 w-[130px]">
            {t('description')}
          </label>
          <input
            maxLength={50}
            {...register('description')}
            className="w-[317px] px-2 outline-none border-b border-grey-500 bg-transparent text-lg text-black-100 placeholder:text-black-100 placeholder:text-opacity-40"
            type="text"
            id="description"
            placeholder={t('add_description')}
            required
          />
        </div>

        <div className="flex gap-3 w-full">
          <label htmlFor="end-date" className="text-lg text-grey-200 w-[130px]">
            {t('location')}
          </label>
          <input
            maxLength={50}
            {...register('location')}
            className="w-[317px] px-2 outline-none border-b border-grey-500 bg-transparent text-lg text-black-100 placeholder:text-black-100 placeholder:text-opacity-40"
            type="text"
            id="location"
            placeholder={t('optional')}
          />
        </div>

        <div className="flex gap-3 w-full">
          <label htmlFor="end-date" className="text-lg text-grey-200 w-[130px]">
            {t('notification')}
          </label>
          <div className="flex flex-col gap-5">
            <Dialog.Root onOpenChange={() => setAuxNotifications([])}>
              <Dialog.Trigger asChild>
                <div
                  className={`flex items-center justify-between w-[317px] px-2 outline-none border-b border-grey-500 bg-transparent text-lg text-black-100 placeholder:text-black-100 placeholder:text-opacity-40 cursor-pointer`}
                >
                  <span>{notifications[0]?.label ? notifications[0].label : t('none')}</span>
                </div>
              </Dialog.Trigger>
              <NotificationPickModal
                isCreatingEvent
                auxNotifications={auxNotifications}
                setAuxNotifications={setAuxNotifications}
                notifications={notifications}
                setNotifications={setNotifications}
              />
            </Dialog.Root>

            {notifications.length > 1 && (
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <div
                    className={`flex items-center justify-between w-[317px] px-2 outline-none border-b border-grey-500 bg-transparent text-lg text-black-100 placeholder:text-black-100 placeholder:text-opacity-40 cursor-pointer`}
                  >
                    <span>{notifications[1]?.label ? notifications[1].label : t('none')}</span>
                  </div>
                </Dialog.Trigger>
                <NotificationPickModal
                  isCreatingEvent
                  auxNotifications={auxNotifications}
                  setAuxNotifications={setAuxNotifications}
                  notifications={notifications}
                  setNotifications={setNotifications}
                />
              </Dialog.Root>
            )}

            {notifications.length > 2 && (
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <div
                    className={`flex items-center justify-between w-[317px] px-2 outline-none border-b border-grey-500 bg-transparent text-lg text-black-100 placeholder:text-black-100 placeholder:text-opacity-40 cursor-pointer`}
                  >
                    <span>{notifications[2]?.label ? notifications[2].label : t('none')}</span>
                  </div>
                </Dialog.Trigger>
                <NotificationPickModal
                  isCreatingEvent
                  auxNotifications={auxNotifications}
                  setAuxNotifications={setAuxNotifications}
                  notifications={notifications}
                  setNotifications={setNotifications}
                />
              </Dialog.Root>
            )}
          </div>
        </div>

        <div className="flex flex-wrap gap-3 w-full">
          <label htmlFor="end-date" className="text-lg text-grey-200 w-[130px]">
            {t('calendar_type')}
          </label>
          <div className="flex items-center">
            <button
              onClick={(e) => {
                e.preventDefault();
                setCalendarType('gregorian');
              }}
              className={`flex items-center justify-center border border-primary-200 px-[10px] py-[5px] rounded-l-full min-w-[150px]
                ${calendarType === 'gregorian' ? 'bg-primary-200 text-white' : ''}
              `}
            >
              {t('gregorian')}
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                setCalendarType('lunar');
              }}
              className={`flex items-center justify-center border border-primary-200 px-[10px] py-[5px] rounded-r-full min-w-[150px] ${
                calendarType === 'lunar' ? 'bg-primary-200 text-white' : ''
              }`}
            >
              {t('lunar')}
            </button>
          </div>
        </div>

        {calendarType === 'gregorian' ? (
          <>
            {/* START DATE */}
            <div className="flex gap-3 w-full">
              <label htmlFor="start-date" className="text-lg text-grey-200 w-[130px]">
                {t('start')}
              </label>
              <Dialog.Root
                onOpenChange={() => {
                  setSelectedDate('');
                  setSelectedTime('');
                }}
              >
                <Dialog.Trigger asChild>
                  <div
                    className={`flex items-center justify-between w-[317px] px-2 outline-none border-b border-grey-500 bg-transparent text-lg text-black-100 placeholder:text-black-100 placeholder:text-opacity-40 cursor-pointer ${
                      selectedStartDate ? '' : 'text-opacity-40'
                    }`}
                    id="start-date"
                  >
                    <span>{selectedStartDate ? selectedStartDateFormatted : dayjs().format('DD/MM/YYYY')}</span>
                    <span>{selectedStartTime ?? '00:00'}</span>
                  </div>
                </Dialog.Trigger>
                <DatePickModal
                  isCreatingEvent
                  selectedDate={selectedDate}
                  selectedTime={selectedTime}
                  setSelectedDate={setSelectedDate}
                  setSelectedTime={setSelectedTime}
                  setDate={setSelectedStartDate}
                  setTime={setSelectedStartTime}
                  withTime
                />
              </Dialog.Root>
            </div>

            {/* END DATE */}
            {selectedStartDate && selectedStartTime && calendarType === 'gregorian' && (
              <div className="flex gap-3 w-full">
                <label className="text-lg text-grey-200 w-[130px]">{t('end')}</label>
                <Dialog.Root
                  onOpenChange={() => {
                    setSelectedDate('');
                    setSelectedTime('');
                  }}
                >
                  <Dialog.Trigger asChild>
                    <div
                      className={`flex items-center justify-between w-[317px] px-2 outline-none border-b border-grey-500 bg-transparent text-lg text-black-100 placeholder:text-black-100 placeholder:text-opacity-40 cursor-pointer relative ${
                        selectedEndDate ? '' : 'text-opacity-40'
                      }`}
                      id="end-date"
                    >
                      <span className={`${endDateError ? 'text-[#ec2a2a]' : ''}`}>
                        {selectedEndDate ? selectedEndDateFormatted : dayjs().format('DD/MM/YYYY')}
                      </span>
                      <span className={`${endDateError ? 'text-[#ec2a2a]' : ''}`}>{selectedEndTime ?? '00:00'}</span>
                      {selectedEndDate && endDateError && (
                        <span
                          className="absolute right-[-40px] bottom-1"
                          title={t('start_date_must_be_less_than_end_date')}
                        >
                          <WarningCircle color="#ec2a2a" size={32} weight="fill" />
                        </span>
                      )}
                    </div>
                  </Dialog.Trigger>
                  <DatePickModal
                    isCreatingEvent
                    selectedDate={selectedDate}
                    selectedTime={selectedTime}
                    setSelectedDate={setSelectedDate}
                    setSelectedTime={setSelectedTime}
                    setDate={setSelectedEndDate}
                    setTime={setSelectedEndTime}
                    withTime
                  />
                </Dialog.Root>
              </div>
            )}

            {/* RECURRENCE */}
            {calendarType === 'gregorian' && (
              <div className="flex gap-3 w-full">
                <label htmlFor="end-date" className="text-lg text-grey-200 w-[130px]">
                  {t('recurrence')}
                </label>
                <Dialog.Root>
                  <Dialog.Trigger asChild>
                    <div
                      className={`flex items-center justify-between w-[317px] px-2 outline-none border-b border-grey-500 bg-transparent text-lg text-black-100 placeholder:text-black-100 placeholder:text-opacity-40 cursor-pointer`}
                    >
                      <span className="px-1">{recurrence?.label ? recurrence.label : t('does_not_repeat')}</span>
                    </div>
                  </Dialog.Trigger>
                  <RecurrencePickModal isCreatingEvent setRecurrence={setRecurrence} />
                </Dialog.Root>
              </div>
            )}
          </>
        ) : (
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-3 w-full">
              <label htmlFor="end-date" className="text-lg text-grey-200">
                {t('moon_phases')}
              </label>
              <div className="flex items-center justify-center flex-wrap gap-5">
                <div className={`flex flex-col items-center gap-1`}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedMoonPhase({ label: t('new_moon'), value: 'New moon' });
                    }}
                    className={`border border-primary-200 p-1 rounded-md flex items-center justify-center w-[55px] h-[55px]
                      ${selectedMoonPhase?.value === 'New moon' ? 'bg-primary-100' : 'bg-backgray'}
                    `}
                  >
                    <svg width="40" height="40" viewBox="0 0 100 100">
                      <g>
                        <circle cx="50" cy="50" r="49" stroke="none" fill="#111111" />
                        <path
                          d="M 50 1 A 49,49 0 1,0 49,99 A 49,49 0 0,1 50,1"
                          strokeWidth="0"
                          stroke="none"
                          fill="#FFFF88"
                        />
                        <circle cx="50" cy="50" r="49" strokeWidth="0" fill="transparent" />
                      </g>
                    </svg>
                  </button>
                  <span className="text-grey-200 text-xs">{t('new_moon')}</span>
                </div>
                <div className={`flex flex-col items-center gap-1`}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedMoonPhase({ label: t('first_quarter'), value: 'First quarter' });
                    }}
                    className={`border border-primary-200 p-1 rounded-md flex items-center justify-center w-[55px] h-[55px]
                    ${selectedMoonPhase?.value === 'First quarter' ? 'bg-primary-100' : 'bg-backgray'}
                  `}
                  >
                    <svg width="40" height="40" viewBox="0 0 100 100">
                      <g>
                        <circle cx="50" cy="50" r="49" stroke="none" fill="#111111" />
                        <path
                          d="M 50 1 A 49,49 0 0,1 49,99 A 0.98,49 0 1,0 50,1"
                          strokeWidth="0"
                          stroke="none"
                          fill="#FFFF88"
                        />
                        <circle cx="50" cy="50" r="49" strokeWidth="0" fill="transparent" />
                      </g>
                    </svg>
                  </button>
                  <span className="text-grey-200 text-xs">{t('first_quarter')}</span>
                </div>
                <div className={`flex flex-col items-center gap-1`}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedMoonPhase({ label: t('full_moon'), value: 'Full moon' });
                    }}
                    className={`border border-primary-200 p-1 rounded-md flex items-center justify-center w-[55px] h-[55px]
                    ${selectedMoonPhase?.value === 'Full moon' ? 'bg-primary-100' : 'bg-backgray'}
                  `}
                  >
                    <svg width="40" height="40" viewBox="0 0 100 100">
                      <g>
                        <circle cx="50" cy="50" r="49" stroke="none" fill="#111111" />
                        <path
                          d="M 50 1 A 49,49 0 0,1 49,99 A -48.02,49 0 0,1 50,1"
                          strokeWidth="0"
                          stroke="none"
                          fill="#FFFF88"
                        />
                        <circle cx="50" cy="50" r="49" strokeWidth="0" fill="transparent" />
                      </g>
                    </svg>
                  </button>
                  <span className="text-grey-200 text-xs">{t('full_moon')}</span>
                </div>
                <div className={`flex flex-col items-center gap-1`}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedMoonPhase({ label: t('last_quarter'), value: 'Last quarter' });
                    }}
                    className={`border border-primary-200 p-1 rounded-md flex items-center justify-center w-[55px] h-[55px]
                    ${selectedMoonPhase?.value === 'Last quarter' ? 'bg-primary-100' : 'bg-backgray'}
                  `}
                  >
                    <svg width="40" height="40" viewBox="0 0 100 100">
                      <g>
                        <circle cx="50" cy="50" r="49" stroke="none" fill="#111111" />
                        <path
                          d="M 50 1 A 49,49 0 1,0 49,99 A -3.92,49 0 1,0 50,1"
                          strokeWidth="0"
                          stroke="none"
                          fill="#FFFF88"
                        />
                        <circle cx="50" cy="50" r="49" strokeWidth="0" fill="transparent" />
                      </g>
                    </svg>
                  </button>
                  <span className="text-grey-200 text-xs">{t('last_quarter')}</span>
                </div>
              </div>
            </div>

            <LocalizationProvider adapterLocale={user?.language || 'en'} dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: '#BDBDBD',
                    },
                  },
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: '500',
                  border: '1px solid #BDBDBD',
                  borderRadius: '6px',
                  width: '100%',
                }}
                views={['month', 'year']}
                // minDate={dayjs(new Date(new Date().getFullYear(), 0, 1))}
                minDate={dayjs()}
                value={lunarDatePicker}
                onChange={(newValue) => setLunarDatePicker(newValue)}
                className="relative"
              />
            </LocalizationProvider>

            <div className="flex items-center justify-center w-full">
              <Button
                disabled={isFetchingMoonPhases || isSubmitting}
                onClick={handlesearchLunarRecurrencies}
                size="medium"
                variant="primary"
                text={t('search')}
              />
            </div>

            {isFetchingMoonPhases && (
              <div className="flex items-center justify-center my-5">
                <img src={loading} className="w-12 h-12" alt="loading" />
              </div>
            )}

            {!isFetchingMoonPhases && moonPhasesRecurrencies && moonPhasesRecurrencies?.length > 0 && (
              <div className="flex flex-col gap-2">
                {moonPhasesRecurrencies.map((moonPhase) => {
                  const date = new Date(moonPhase?.year || 1, Number(monthPhase), moonPhase?.day || 1);
                  const isChecked = moonPhasesDate.find(
                    (item) => item === dayjs(date).format('YYYY-MM-DD') + ' ' + (moonPhase?.timeEvent || '00:00:00'),
                  );
                  const dateFormatted =
                    user?.language === 'pt' ? dayjs(date).format('DD/MM/YYYY') : dayjs(date).format('MM/DD/YYYY');
                  return (
                    <button
                      key={date.getTime()}
                      onClick={(e) => {
                        e.preventDefault();
                        if (isChecked) {
                          setMoonPhasesDate(
                            moonPhasesDate.filter(
                              (item) =>
                                item !== dayjs(date).format('YYYY-MM-DD') + ' ' + (moonPhase?.timeEvent || '00:00:00'),
                            ),
                          );
                        } else {
                          setMoonPhasesDate([
                            ...moonPhasesDate,
                            dayjs(date).format('YYYY-MM-DD') + ' ' + (moonPhase?.timeEvent || '00:00:00'),
                          ]);
                        }
                      }}
                      className="flex items-center justify-between border border-grey-500 rounded-md p-3 hover:opacity-70"
                    >
                      <span>{dateFormatted}</span>
                      {isChecked && <FaCheck className="text-primary-200 text-lg" />}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </form>

      <div className="desktop:w-[588px] tablet:w-[60vw]">
        <h3 className="text-xl text-black-100 mb-2 font-medium">{t('share_with_a_group')}</h3>
        <Select
          bg="bg-white"
          textColor="text-primary-200"
          value={selectedGroup}
          onChange={(e) => setSelectedGroup(e.target.value)}
          options={
            <>
              <option hidden value={undefined}>
                {t('choose_a_group')}
              </option>
              {groups?.map((group) => (
                <option key={group.uuid} value={group.uuid}>
                  {group.name}
                </option>
              ))}
            </>
          }
        />
      </div>

      <Button
        disabled={isSubmitting || isFetchingMoonPhases}
        form="create-event"
        type="submit"
        text={t('next')}
        size="medium"
        variant="primary"
      />
    </section>
  );
}
