/* eslint-disable react/display-name */
import React, { ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size: 'small' | 'medium' | 'large';
  variant: 'primary' | 'secondary' | 'default';
  text: string;
  boxShadow?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, Omit<ButtonProps, 'ref'>>(
  ({ size, variant, text, boxShadow, ...props }, ref) => (
    <button
      {...props}
      ref={ref}
      className={`${
        variant === 'primary'
          ? 'bg-primary-200 text-white'
          : variant === 'secondary'
            ? 'bg-secondary text-white'
            : variant === 'default'
      } 
        ${size === 'small' ? 'px-[8px] py-[5px] text-xs' : size === 'medium' ? 'px-5 py-[10px]' : 'px-8 py-3 text-xl'}
        ${boxShadow ? 'shadow-md' : ''}
       shadow-md font-semibold  flex items-center justify-center gap-2 rounded-[4px] hover:opacity-90 disabled:bg-grey-400 disabled:hover:opacity-100`}
    >
      {text}
    </button>
  ),
);
