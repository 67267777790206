import * as Dialog from '@radix-ui/react-dialog';
import { useCallback, useEffect, useState } from 'react';
import { matchPath, Navigate, Outlet, useParams } from 'react-router-dom';

import { useUser } from '@hooks/useUser';

import TermsOfUseModal from '@components/TermsOfUseModal';
import { isAndroid, isIOS } from 'react-device-detect';

export default function ProtectedRoutes() {
  const { user, verifyUser, acceptTerms } = useUser();
  const isUserVerify = verifyUser();

  const [termsOfUse, setTermsOfUse] = useState<boolean>(false);

  useEffect(() => {
    if (user?.terms_of_use === false && user?.is_super_admin !== 1) {
      setTermsOfUse(true);
    }
  }, [user]);

  const handleAcceptTermsOfUse = useCallback(async () => {
    const response = await acceptTerms();
    if (response) {
      setTermsOfUse(false);
    }
  }, [acceptTerms]);

  const params = useParams();

  if (isAndroid || isIOS) {
    const scheme = import.meta.env.VITE_APP_SCHEME ?? 'com.noiteminente.familywisdom://';

    if (matchPath('/groups/:groupId', window.location.pathname)) {
      window.location.replace(`${scheme}groups-read/?groupId=${params?.groupId}`);
    }
    if (matchPath('/events/:eventUuid', window.location.pathname)) {
      window.location.replace(`${scheme}event-read/?eventUuid=${params?.eventUuid}`);
    }

    if (matchPath('/groups/:groupId/insight/:insightUuid', window.location.pathname)) {
      window.location.replace(`${scheme}view-insight/?groupUuid=${params?.groupId}&postUuid=${params?.insightUuid}`);
    }
  }

  if (termsOfUse)
    return (
      <Dialog.Root open={termsOfUse}>
        <TermsOfUseModal handleAcceptTermsOfUse={handleAcceptTermsOfUse} />
        <Outlet />
      </Dialog.Root>
    );

  if (isUserVerify === 'login') return <Navigate to="/login" />;
  else if (isUserVerify === 'inviteLink') return <Navigate to={`/invite/${localStorage.getItem('inviteLink')}`} />;
  else if (isUserVerify === 'profile') return <Navigate to="/register/finish" />;

  return <Outlet />;
}
