import { Outlet } from 'react-router-dom';

import { useUser } from '@hooks/useUser';
import NoPermission from '@components/EmptyStates/NoPermissions';

export default function SuperAdminRoutes() {
  const { user } = useUser();

  if (user?.is_super_admin) return <Outlet />;
  return (
    <div className="py-20">
      <NoPermission />
    </div>
  );
}
