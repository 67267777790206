import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Category } from '@hooks/useCategory';
import { useInsights } from '@hooks/useInsights';

interface TopicsListComponentProps {
  category: Category;
}

export default function TopicsListComponent({ category }: Readonly<TopicsListComponentProps>) {
  const { creatingInsight, editInsight } = useInsights();
  const [isSelected, setIsSelected] = useState<boolean>(creatingInsight?.category.topicUuid === category.uuid || false);

  const navigate = useNavigate();

  useEffect(() => {
    setIsSelected(creatingInsight.category.topicUuid === category.uuid);
  }, [category.uuid, creatingInsight.category.topicUuid]);

  function handleSelectTopic(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.stopPropagation();
    editInsight({
      category: {
        uuid: creatingInsight.category.uuid,
        name: creatingInsight.category.name,
        subCategoryUuid: creatingInsight.category.subCategoryUuid,
        subCategoryName: creatingInsight.category.subCategoryName,
        topicUuid: category.uuid,
        topicName: category.name,
      },
    });
    navigate('/insight/create/finish?previous=insightCategories');
  }

  return (
    <button
      className={`flex items-center w-full gap-3 py-3 pl-24 pr-3 transition-all border-b border-grey-600 cursor-pointer ${
        isSelected ? 'bg-primary-200 bg-opacity-10' : ''
      }`}
      onClick={() => {
        if (!isSelected) {
          editInsight({
            category: {
              uuid: creatingInsight.category.uuid,
              name: creatingInsight.category.name,
              subCategoryUuid: creatingInsight.category.subCategoryUuid,
              subCategoryName: creatingInsight.category.subCategoryName,
              topicUuid: category.uuid,
              topicName: category.name,
            },
          });
        } else {
          editInsight({
            category: {
              uuid: creatingInsight.category.uuid,
              name: creatingInsight.category.name,
              subCategoryUuid: creatingInsight.category.subCategoryUuid,
              subCategoryName: creatingInsight.category.subCategoryName,
              topicUuid: '',
              topicName: '',
            },
          });
        }
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
        <g clipPath="url(#clip0_3335_17023)">
          <path
            d="M7.5 14.25C10.9518 14.25 13.75 11.4518 13.75 8C13.75 4.54822 10.9518 1.75 7.5 1.75C4.04822 1.75 1.25 4.54822 1.25 8C1.25 11.4518 4.04822 14.25 7.5 14.25Z"
            stroke="#282A74"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3335_17023">
            <rect width="15" height="15" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </svg>
      <p>{category?.name}</p>
      {isSelected && (
        <button onClick={handleSelectTopic} className="hover:opacity-70 z-[1] ml-auto">
          <svg
            className="duration-300"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="26"
            viewBox="0 0 25 26"
            fill="none"
          >
            <g clipPath="url(#clip0_3335_16907)">
              <path
                d="M6.2502 21.2375L8.0127 23L18.0127 13L8.0127 3L6.2502 4.7625L14.4752 13L6.2502 21.2375Z"
                fill="#EDB561"
              />
            </g>
            <defs>
              <clipPath id="clip0_3335_16907">
                <rect width="25" height="25" fill="white" transform="translate(0 0.9)" />
              </clipPath>
            </defs>
          </svg>
        </button>
      )}
    </button>
  );
}
