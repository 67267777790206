import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';

import { useUser } from '@hooks/useUser';

import PlusActionModal from '@components/PlusActionModal';
import { useNotifications } from 'contexts/NotificationContext';

export default function SideBar() {
  const { unreadNotifications } = useNotifications();
  const [showSideBar, setShowSideBar] = useState(false);

  const { t } = useTranslation();
  const { user } = useUser();

  const location = useLocation();
  const { eventUuid } = useParams();

  useEffect(() => {
    const path = location.pathname;

    if (path.includes('create')) {
      if (path.includes('groups') && !path.includes('insight')) {
        setShowSideBar(false);
      } else if (path.includes('insight')) {
        if (
          !path.includes('groups') &&
          !path.includes('categories') &&
          !path.includes('tags-and-description') &&
          !path.includes('finish')
        )
          setShowSideBar(false);
      } else if (path.includes('calendar')) {
        if (eventUuid) setShowSideBar(true);
        else setShowSideBar(false);
      } else setShowSideBar(true);
    } else {
      setShowSideBar(true);
    }
  }, [eventUuid, location]);

  return (
    <>
      {showSideBar ? (
        <div className="fixed flex flex-col w-[200px] h-full overflow-y-scroll gap-10 bg-white p-7 z-[1]">
          <Link to={'/feed'} className="flex items-center gap-2 hover:opacity-70">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M12.9823 2.76424C12.631 2.49099 12.4553 2.35436 12.2613 2.30184C12.0902 2.2555 11.9098 2.2555 11.7387 2.30184C11.5447 2.35436 11.369 2.49099 11.0177 2.76424L4.23539 8.03937C3.78202 8.39199 3.55534 8.5683 3.39203 8.7891C3.24737 8.98469 3.1396 9.20503 3.07403 9.4393C3 9.70376 3 9.99094 3 10.5653V17.8002C3 18.9203 3 19.4804 3.21799 19.9082C3.40973 20.2845 3.71569 20.5905 4.09202 20.7822C4.51984 21.0002 5.0799 21.0002 6.2 21.0002H8.2C8.48003 21.0002 8.62004 21.0002 8.727 20.9457C8.82108 20.8978 8.89757 20.8213 8.9455 20.7272C9 20.6203 9 20.4803 9 20.2002V13.6002C9 13.0402 9 12.7601 9.10899 12.5462C9.20487 12.3581 9.35785 12.2051 9.54601 12.1092C9.75992 12.0002 10.0399 12.0002 10.6 12.0002H13.4C13.9601 12.0002 14.2401 12.0002 14.454 12.1092C14.6422 12.2051 14.7951 12.3581 14.891 12.5462C15 12.7601 15 13.0402 15 13.6002V20.2002C15 20.4803 15 20.6203 15.0545 20.7272C15.1024 20.8213 15.1789 20.8978 15.273 20.9457C15.38 21.0002 15.52 21.0002 15.8 21.0002H17.8C18.9201 21.0002 19.4802 21.0002 19.908 20.7822C20.2843 20.5905 20.5903 20.2845 20.782 19.9082C21 19.4804 21 18.9203 21 17.8002V10.5653C21 9.99094 21 9.70376 20.926 9.4393C20.8604 9.20503 20.7526 8.98469 20.608 8.7891C20.4447 8.5683 20.218 8.39199 19.7646 8.03937L12.9823 2.76424Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="text-grey-100 text-lg">{t('feed')}</span>
          </Link>
          <Link to={'/calendar'} className="flex items-center gap-2 hover:opacity-70">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M8 7V3M16 7V3M7 11L17 11M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5L5 5C3.89543 5 3 5.89543 3 7L3 19C3 20.1046 3.89543 21 5 21Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="text-grey-100 text-lg">{t('calendar')}</span>
          </Link>
          <Link to={'/groups'} data-test="create-group-link" className="flex items-center gap-2 hover:opacity-70">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M17 20H22V18C22 16.3431 20.6569 15 19 15C18.0444 15 17.1931 15.4468 16.6438 16.1429M17 20H7M17 20V18C17 17.3438 16.8736 16.717 16.6438 16.1429M16.6438 16.1429C15.9065 14.301 14.1052 13 12 13C9.89482 13 8.0935 14.301 7.35625 16.1429M7 20H2L2 18C2 16.3431 3.34315 15 5 15C5.95561 15 6.80686 15.4468 7.35625 16.1429M7 20V18C7 17.3438 7.12642 16.717 7.35625 16.1429M15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7ZM21 10C21 11.1046 20.1046 12 19 12C17.8954 12 17 11.1046 17 10C17 8.89543 17.8954 8 19 8C20.1046 8 21 8.89543 21 10ZM7 10C7 11.1046 6.10457 12 5 12C3.89543 12 3 11.1046 3 10C3 8.89543 3.89543 8 5 8C6.10457 8 7 8.89543 7 10Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="text-grey-100 text-lg">{t('groups')}</span>
          </Link>
          <Link to={'/profile'} className="flex items-center gap-2 hover:opacity-70">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M5.12104 17.8037C7.15267 16.6554 9.4998 16 12 16C14.5002 16 16.8473 16.6554 18.879 17.8037M15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="text-grey-100 text-lg">{t('profile')}</span>
          </Link>
          <Link to={'/notifications'} className="flex items-center gap-2 hover:opacity-70">
            <div className="relative">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_4268_8052)">
                  <path
                    d="M12 22.8535C12.42 22.8438 12.823 22.6859 13.1378 22.4078C13.4525 22.1296 13.6588 21.7491 13.72 21.3335H10.2134C10.2764 21.7604 10.4923 22.1498 10.8209 22.4294C11.1496 22.709 11.5686 22.8597 12 22.8535Z"
                    fill="black"
                  />
                  <path
                    d="M21.6734 18.5531C21.0306 17.98 20.4677 17.323 20.0001 16.5998C19.4896 15.6015 19.1836 14.5113 19.1001 13.3931V10.0998C19.0974 9.69974 19.0617 9.30061 18.9934 8.90644C18.4687 8.80155 17.9644 8.61244 17.5001 8.34644C17.6771 8.91634 17.767 9.50969 17.7668 10.1064V13.3998C17.8485 14.7638 18.2237 16.0941 18.8668 17.2998C19.327 18.0291 19.8732 18.7006 20.4934 19.2998H3.42011C4.04039 18.7006 4.58651 18.0291 5.04678 17.2998C5.68986 16.0941 6.06507 14.7638 6.14678 13.3998V10.0998C6.14327 9.32823 6.29199 8.56357 6.58441 7.84959C6.87683 7.1356 7.30722 6.4863 7.85092 5.93888C8.39463 5.39146 9.04098 4.95666 9.75296 4.65938C10.4649 4.3621 11.2286 4.20818 12.0001 4.20644C13.1293 4.20734 14.2331 4.54127 15.1734 5.16644C15.0698 4.78576 15.0116 4.39414 15.0001 3.99977C15.0001 3.85977 15.0001 3.71977 15.0001 3.57977C14.304 3.23735 13.5562 3.0121 12.7868 2.91311V2.07311C12.7868 1.83706 12.693 1.61069 12.5261 1.44378C12.3592 1.27687 12.1328 1.18311 11.8968 1.18311C11.6607 1.18311 11.4344 1.27687 11.2675 1.44378C11.1005 1.61069 11.0068 1.83706 11.0068 2.07311V2.94644C9.28393 3.18947 7.70716 4.0476 6.56759 5.36239C5.42803 6.67719 4.8026 8.35986 4.80678 10.0998V13.3931C4.72328 14.5113 4.4173 15.6015 3.90678 16.5998C3.44737 17.3213 2.89358 17.9783 2.26011 18.5531C2.189 18.6156 2.13201 18.6925 2.09293 18.7787C2.05384 18.8649 2.03357 18.9585 2.03345 19.0531V19.9598C2.03345 20.1366 2.10369 20.3062 2.22871 20.4312C2.35373 20.5562 2.5233 20.6264 2.70011 20.6264H21.2334C21.4103 20.6264 21.5798 20.5562 21.7049 20.4312C21.8299 20.3062 21.9001 20.1366 21.9001 19.9598V19.0531C21.9 18.9585 21.8797 18.8649 21.8406 18.7787C21.8016 18.6925 21.7446 18.6156 21.6734 18.5531Z"
                    fill="black"
                  />
                  <path
                    d="M20.0001 7.33317C21.841 7.33317 23.3334 5.84079 23.3334 3.99984C23.3334 2.15889 21.841 0.666504 20.0001 0.666504C18.1591 0.666504 16.6667 2.15889 16.6667 3.99984C16.6667 5.84079 18.1591 7.33317 20.0001 7.33317Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4268_8052">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="absolute top-[-2px] right-[-2px] h-[15px] w-[15px] rounded-full bg-[#333333] text-white flex items-center justify-center text-[10px] select-none">
                {unreadNotifications}
              </span>
            </div>
            <span className="text-grey-100 text-lg">{t('notifications')}</span>
          </Link>
          {user?.is_super_admin === 1 && (
            <>
              <Link to={'/dashboard'} className="flex items-center gap-2 hover:opacity-70">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M8 21H16M12 17V21M6.8 17H17.2C18.8802 17 19.7202 17 20.362 16.673C20.9265 16.3854 21.3854 15.9265 21.673 15.362C22 14.7202 22 13.8802 22 12.2V7.8C22 6.11984 22 5.27976 21.673 4.63803C21.3854 4.07354 20.9265 3.6146 20.362 3.32698C19.7202 3 18.8802 3 17.2 3H6.8C5.11984 3 4.27976 3 3.63803 3.32698C3.07354 3.6146 2.6146 4.07354 2.32698 4.63803C2 5.27976 2 6.11984 2 7.8V12.2C2 13.8802 2 14.7202 2.32698 15.362C2.6146 15.9265 3.07354 16.3854 3.63803 16.673C4.27976 17 5.11984 17 6.8 17Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="text-grey-100 text-lg">Dashboard</span>
              </Link>
              <Link to={'/ads'} className="flex items-center gap-2 hover:opacity-70">
                <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.3269 34H28.6728M23.9998 6V8M36.7278 11.2721L35.3136 12.6863M42 23.9998H40M8 23.9998H6M12.6862 12.6863L11.272 11.2721M16.9288 31.0712C13.0236 27.1658 13.0236 20.8342 16.9288 16.929C20.834 13.0237 27.1658 13.0237 31.071 16.929C34.9762 20.8342 34.9762 27.1658 31.071 31.0712L29.9768 32.1654C28.711 33.431 27.9998 35.1478 27.9998 36.938V38C27.9998 40.2092 26.209 42 23.9998 42C21.7908 42 19.9999 40.2092 19.9999 38V36.938C19.9999 35.1478 19.2888 33.431 18.023 32.1654L16.9288 31.0712Z"
                    stroke="black"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="text-grey-100 text-lg">{t('ads')}</span>
              </Link>
            </>
          )}

          <div className="flex w-full justify-center mt-5">
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <button className="mb-16 hover:opacity-80">
                  {/* <img src={plus} alt="Add Icon" /> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 41 40" fill="none">
                    <g clipPath="url(#clip0_4042_32682)">
                      <path
                        d="M27.1657 1.14194V23.9997C36.4978 23.9997 40.4972 28.7066 40.4972 19.9998C40.4972 11.293 34.9323 3.88732 27.1657 1.14194Z"
                        fill="#902687"
                      />
                      <path
                        d="M40.1643 16.3436L7.50146 35.2014C13.7623 40.5548 22.9584 41.6713 30.4986 37.318C38.039 32.9646 41.67 24.4423 40.1643 16.3436Z"
                        fill="#282A74"
                      />
                      <path
                        d="M33.4985 35.2014L0.835717 16.3436C-0.669984 24.4422 2.9611 32.9646 10.5013 37.318C18.0416 41.6714 27.2376 40.5548 33.4985 35.2014Z"
                        fill="#EEE752"
                      />
                      <path
                        d="M13.8342 38.8576V1.14194C6.06775 3.88732 0.502686 11.293 0.502686 19.9997C0.502686 28.7065 6.06775 36.1123 13.8342 38.8576Z"
                        fill="#EDB561"
                      />
                      <path
                        d="M0.835508 23.656L27.1655 8.45435V1.14772C21.8464 -0.730792 15.7698 -0.360246 10.5012 2.68155C2.9609 7.0349 -0.670114 15.5572 0.835508 23.656Z"
                        fill="#282A74"
                      />
                      <path
                        d="M7.50146 4.79811L27.1657 16.1513V1.14304C20.3809 -1.26383 12.8644 0.212492 7.50146 4.79811Z"
                        fill="#E8228B"
                      />
                      <path
                        d="M27.1657 1.14194V1.14303C27.1874 1.15069 27.2091 1.15787 27.2307 1.16561C27.209 1.15787 27.1875 1.14959 27.1657 1.14194Z"
                        fill="#902687"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4042_32682">
                        <rect width="40" height="40" fill="white" transform="translate(0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </Dialog.Trigger>

              <PlusActionModal />
            </Dialog.Root>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
