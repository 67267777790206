import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Dialog from '@radix-ui/react-dialog';
import { Button } from '@components/Button';

interface ConfirmReturnHomeModalProps {
  handleConfirmHome: () => void;
  showReturnFeed: 'groups' | 'insight' | 'calendar' | 'category' | 'insightSteps' | 'ads' | null;
}

export default function ConfirmReturnHomeModal({ handleConfirmHome, showReturnFeed }: ConfirmReturnHomeModalProps) {
  const closeButton = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '10',
        }}
      />
      <Dialog.Content
        forceMount
        className={
          'z-40 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white p-12 rounded-lg font-poppins w-[400px]'
        }
      >
        <Dialog.Close ref={closeButton} className={'absolute top-4 right-8 hover:opacity-70'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 13 13" fill="none">
            <path
              d="M1.68773 0.244075C1.36229 -0.0813583 0.834653 -0.0813583 0.509212 0.244075C0.183778 0.569517 0.183778 1.09715 0.509212 1.42259L5.32148 6.23484L0.509262 11.0471C0.183828 11.3725 0.183828 11.9002 0.509262 12.2256C0.834703 12.551 1.36234 12.551 1.68777 12.2256L6.49998 7.41334L11.3122 12.2256C11.6376 12.551 12.1653 12.551 12.4907 12.2256C12.8161 11.9002 12.8161 11.3725 12.4907 11.0471L7.67848 6.23484L12.4908 1.42259C12.8162 1.09715 12.8162 0.569517 12.4908 0.244075C12.1654 -0.0813583 11.6377 -0.0813583 11.3123 0.244075L6.49998 5.05634L1.68773 0.244075Z"
              fill="#737373"
            />
          </svg>
        </Dialog.Close>
        <div className="flex flex-col items-center gap-5">
          <p className="text-center">{t('are_you_sure_you_want_to_go_to_home_page')}</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="135" height="101" viewBox="0 0 135 101" fill="none">
            <path d="M134.594 88.3487H0.405701V88.4159H134.594V88.3487Z" fill="#EBEBEB" />
            <path d="M121.149 92.6684H117.15V92.7356H121.149V92.6684Z" fill="#EBEBEB" />
            <path d="M89.2973 93.3968H86.9644V93.464H89.2973V93.3968Z" fill="#EBEBEB" />
            <path d="M116.016 90.1764H106.84V90.2435H116.016V90.1764Z" fill="#EBEBEB" />
            <path d="M29.8276 91.561H18.2358V91.6282H29.8276V91.561Z" fill="#EBEBEB" />
            <path d="M36.3982 91.561H32.2176V91.6282H36.3982V91.561Z" fill="#EBEBEB" />
            <path d="M68.7759 90.6605H43.6356V90.7276H68.7759V90.6605Z" fill="#EBEBEB" />
            <path
              d="M64.0148 76.3794H12.1909C11.7847 76.379 11.3953 76.2174 11.1081 75.9302C10.8209 75.643 10.6594 75.2536 10.6589 74.8474V2.00108C10.6595 1.595 10.8211 1.20573 11.1083 0.918639C11.3955 0.631549 11.7848 0.47008 12.1909 0.46965H64.0148C64.4207 0.470223 64.8099 0.631754 65.097 0.918829C65.3841 1.2059 65.5456 1.5951 65.5462 2.00108V74.8464C65.546 75.2526 65.3847 75.6422 65.0976 75.9297C64.8105 76.2171 64.421 76.3788 64.0148 76.3794ZM12.1914 0.5368C11.804 0.537942 11.4328 0.692354 11.1588 0.966306C10.8849 1.24026 10.7305 1.61149 10.7293 1.99892V74.8464C10.7305 75.2338 10.8849 75.605 11.1588 75.879C11.4328 76.1529 11.804 76.3073 12.1914 76.3085H64.0153C64.4027 76.3073 64.774 76.1529 65.0479 75.879C65.3219 75.605 65.4763 75.2338 65.4774 74.8464V2.00108C65.4763 1.61365 65.3219 1.24242 65.0479 0.968471C64.774 0.694519 64.4027 0.540109 64.0153 0.538967L12.1914 0.5368Z"
              fill="#EBEBEB"
            />
            <path
              d="M122.064 76.3794H70.24C69.834 76.3788 69.4447 76.2172 69.1576 75.93C68.8705 75.6429 68.709 75.2535 68.7086 74.8474V2.00108C68.709 1.59505 68.8705 1.20578 69.1576 0.918672C69.4447 0.631566 69.834 0.47008 70.24 0.46965H122.064C122.47 0.47008 122.859 0.631549 123.147 0.918639C123.434 1.20573 123.595 1.595 123.596 2.00108V74.8464C123.596 75.2527 123.434 75.6424 123.147 75.9299C122.86 76.2173 122.47 76.379 122.064 76.3794ZM70.24 0.5368C69.8526 0.537942 69.4814 0.692354 69.2074 0.966306C68.9335 1.24026 68.7791 1.61149 68.7779 1.99892V74.8464C68.7791 75.2338 68.9335 75.605 69.2074 75.879C69.4814 76.1529 69.8526 76.3073 70.24 76.3085H122.064C122.452 76.308 122.824 76.154 123.098 75.8799C123.373 75.6059 123.528 75.2343 123.529 74.8464V2.00108C123.528 1.61319 123.373 1.24155 123.098 0.967519C122.824 0.693489 122.452 0.539395 122.064 0.538967L70.24 0.5368Z"
              fill="#EBEBEB"
            />
            <path d="M57.966 88.3482H62.9637L62.9637 54.0307H57.966L57.966 88.3482Z" fill="#E6E6E6" />
            <path d="M59.7254 88.3482H57.9659V81.8282H61.5698L59.7254 88.3482Z" fill="#F0F0F0" />
            <path d="M110.815 88.3482H115.813V54.0307H110.815V88.3482Z" fill="#E6E6E6" />
            <path d="M57.966 86.5682H112.351V54.0307L57.966 54.0307L57.966 86.5682Z" fill="#F0F0F0" />
            <path d="M110.593 88.3482H112.353V81.8282H108.747L110.593 88.3482Z" fill="#F0F0F0" />
            <path d="M94.4205 56.7248V83.6407H110.542V56.7248H94.4205Z" fill="#E6E6E6" />
            <path
              d="M100.887 57.6714H104.077C105.426 57.6851 106.733 57.1942 107.739 56.2948H97.2247C98.2312 57.194 99.5373 57.685 100.887 57.6714Z"
              fill="#F0F0F0"
            />
            <path d="M77.0974 56.7248V83.6407H93.2191V56.7248H77.0974Z" fill="#E6E6E6" />
            <path
              d="M83.4007 57.6714H86.5903C87.94 57.685 89.2461 57.194 90.2526 56.2948H79.7379C80.7446 57.1942 82.0509 57.6851 83.4007 57.6714Z"
              fill="#F0F0F0"
            />
            <path d="M59.7742 56.7248V83.6407H75.8959V56.7248H59.7742Z" fill="#E6E6E6" />
            <path
              d="M66.0775 57.6714H69.2671C70.6169 57.6851 71.9232 57.1942 72.9299 56.2948H62.4146C63.4213 57.1942 64.7276 57.6851 66.0775 57.6714Z"
              fill="#F0F0F0"
            />
            <path d="M19.187 88.3482H53.2867L53.2867 11.6944H19.187L19.187 88.3482Z" fill="#F0F0F0" />
            <path d="M19.1869 88.3482H52.567L52.567 11.6944H19.1869L19.1869 88.3482Z" fill="#F5F5F5" />
            <path d="M50.5328 86.6088L50.5328 13.4337L21.2207 13.4337L21.2207 86.6088H50.5328Z" fill="#F0F0F0" />
            <path
              d="M47.6527 27.0428C47.6527 25.9933 47.446 24.9542 47.0444 23.9846C46.6428 23.0151 46.0542 22.1341 45.3121 21.3921C44.57 20.65 43.6891 20.0614 42.7195 19.6598C41.75 19.2582 40.7108 19.0515 39.6614 19.0515H32.0931C29.9737 19.0515 27.9411 19.8934 26.4424 21.3921C24.9437 22.8907 24.1018 24.9233 24.1018 27.0428H47.6527Z"
              fill="#FAFAFA"
            />
            <path d="M47.6526 82.0605V64.579H24.1017V82.0605H47.6526Z" fill="#F5F5F5" />
            <path d="M47.6525 31.9717H37.2645V62.3236H47.6525V31.9717Z" fill="#F5F5F5" />
            <path d="M34.4899 31.9717H24.1018V62.3236H34.4899V31.9717Z" fill="#F5F5F5" />
            <path
              d="M27.8691 47.0109H23.6143C23.4394 47.0105 23.2717 46.9408 23.148 46.8171C23.0243 46.6934 22.9547 46.5257 22.9542 46.3508C22.9548 46.1759 23.0245 46.0083 23.1482 45.8847C23.2719 45.761 23.4394 45.6913 23.6143 45.6907H27.8691C28.044 45.6913 28.2116 45.761 28.3352 45.8847C28.4589 46.0083 28.5286 46.1759 28.5292 46.3508C28.5288 46.5257 28.4591 46.6934 28.3354 46.8171C28.2117 46.9408 28.044 47.0105 27.8691 47.0109Z"
              fill="#E0E0E0"
            />
            <path d="M21.7488 86.6088L21.7488 13.4337H21.2213L21.2213 86.6088H21.7488Z" fill="#F0F0F0" />
            <path d="M74.8558 45.6176L111.018 45.6176V11.6943L74.8558 11.6943V45.6176Z" fill="#E6E6E6" />
            <path d="M73.9446 45.6176L110.107 45.6176V11.6943L73.9446 11.6943V45.6176Z" fill="#F5F5F5" />
            <path d="M71.833 47.5811L111.018 47.5811V45.6176L71.833 45.6176V47.5811Z" fill="#E6E6E6" />
            <path d="M70.8067 47.5811L107.974 47.5811V45.6176L70.8067 45.6176V47.5811Z" fill="#F5F5F5" />
            <path d="M108.47 43.8533V13.4592L75.5803 13.4592V43.8533H108.47Z" fill="white" />
            <path d="M92.9369 43.8533V13.4592H91.1136V43.8533H92.9369Z" fill="#F5F5F5" />
            <path d="M76.2697 43.8533V13.4592H75.5804V43.8533H76.2697Z" fill="#E6E6E6" />
            <path d="M93.6262 43.8533V13.4592H92.9369V43.8533H93.6262Z" fill="#E6E6E6" />
            <path
              d="M79.7172 46.517C79.0523 39.6262 78.5892 28.4442 78.9488 24.7045C78.9551 24.6402 78.9749 24.578 79.007 24.522C79.0391 24.466 79.0828 24.4174 79.1351 24.3795C79.2046 24.3287 79.2871 24.2983 79.373 24.292C79.4589 24.2857 79.5449 24.3037 79.6211 24.3439C79.6973 24.3841 79.7607 24.4449 79.804 24.5194C79.8474 24.5938 79.8689 24.679 79.8662 24.7651C79.8028 26.6182 79.7297 31.2499 80.1245 39.2401C80.321 43.2175 80.6043 45.9414 80.4098 46.516L79.7172 46.517Z"
              fill="#E6E6E6"
            />
            <path
              d="M77.238 30.6347C75.3427 28.532 71.4768 27.0368 67.5356 27.0444C65.9884 27.0476 72.243 29.4905 74.8461 32.1889C76.9264 34.4297 78.6388 36.9857 79.9197 39.7621C79.4171 35.6438 79.1318 32.7363 77.238 30.6347Z"
              fill="#E6E6E6"
            />
            <path
              d="M80.9919 31.2748C82.5623 29.0848 86.1943 27.4007 90.122 27.2101C91.6637 27.1354 85.7974 29.8836 83.608 32.7006C81.8615 35.0551 80.5339 37.693 79.6836 40.4985C79.5688 36.3759 79.4193 33.4652 80.9919 31.2748Z"
              fill="#E6E6E6"
            />
            <path
              d="M77.1535 22.9548C75.5501 21.1743 72.275 19.9082 68.9375 19.9147C67.6276 19.9147 72.9237 21.9866 75.1261 24.2691C76.8877 26.1665 78.3378 28.3309 79.4225 30.6818C78.9991 27.1976 78.7559 24.7353 77.1535 22.9548Z"
              fill="#E6E6E6"
            />
            <path
              d="M80.3318 25.5882C81.6634 23.7335 84.7376 22.3077 88.0631 22.1463C89.3687 22.0829 84.4013 24.4099 82.5471 26.7953C81.0677 28.79 79.9435 31.025 79.2238 33.4019C79.1274 29.908 79.0002 27.4419 80.3318 25.5882Z"
              fill="#E6E6E6"
            />
            <path
              d="M79.9844 54.0307C78.9851 54.0307 78.0236 53.6485 77.2969 52.9624C76.5703 52.2764 76.1336 51.3384 76.0762 50.3407L75.7307 44.3499H84.2381L83.8926 50.3407C83.8352 51.3384 83.3985 52.2764 82.6718 52.9624C81.9452 53.6485 80.9837 54.0307 79.9844 54.0307Z"
              fill="#E6E6E6"
            />
            <path d="M84.7889 45.7118H75.1818L74.9067 43.2208H85.0662L84.7889 45.7118Z" fill="#E6E6E6" />
            <path
              d="M67.5001 100.47C96.2385 100.47 119.536 99.109 119.536 97.4306C119.536 95.7522 96.2385 94.3916 67.5001 94.3916C38.7616 94.3916 15.4645 95.7522 15.4645 97.4306C15.4645 99.109 38.7616 100.47 67.5001 100.47Z"
              fill="#F5F5F5"
            />
            <path d="M68.5498 94.5264L66.309 94.7788L65.4826 89.6971L67.7234 89.4448L68.5498 94.5264Z" fill="#FFC3BD" />
            <path d="M78.582 95.016H76.3444L75.7872 89.8341H78.0247L78.582 95.016Z" fill="#FFC3BD" />
            <path
              d="M76.1602 94.7555H78.8879C78.9323 94.7555 78.9753 94.7707 79.01 94.7984C79.0446 94.8262 79.0688 94.8649 79.0785 94.9082L79.5204 96.8972C79.5307 96.9457 79.53 96.9958 79.5183 97.044C79.5066 97.0921 79.4841 97.137 79.4526 97.1753C79.4211 97.2136 79.3814 97.2442 79.3364 97.265C79.2914 97.2858 79.2423 97.2961 79.1927 97.2952C78.3149 97.2801 77.6765 97.2286 76.5696 97.2286C75.8895 97.2286 73.8382 97.299 72.8992 97.299C71.9786 97.299 71.8372 96.3703 72.2217 96.2864C73.9459 95.9073 75.245 95.3891 75.7958 94.893C75.896 94.8036 76.0259 94.7545 76.1602 94.7555Z"
              fill="#263238"
            />
            <path
              d="M66.2721 94.3948L68.7003 94.1197C68.7444 94.1146 68.789 94.1247 68.8266 94.1483C68.8643 94.1719 68.8927 94.2077 68.9072 94.2497L69.5706 96.1759C69.5873 96.2225 69.5931 96.2723 69.5877 96.3215C69.5822 96.3707 69.5656 96.418 69.539 96.4598C69.5125 96.5016 69.4767 96.5368 69.4345 96.5627C69.3923 96.5886 69.3447 96.6044 69.2955 96.6091C68.4182 96.6936 67.1467 96.7857 66.0463 96.9108C64.7591 97.057 64.5447 97.4014 63.0262 97.3662C62.1056 97.3445 61.875 96.4104 62.2611 96.3373C64.0199 95.9956 64.36 95.7297 65.7577 94.6326C65.9037 94.507 66.0819 94.4247 66.2721 94.3948Z"
              fill="#263238"
            />
            <path
              d="M68.2486 29.2663C67.4969 29.8869 66.7572 30.4262 65.992 30.9786C65.2269 31.5309 64.4455 32.0438 63.6413 32.5414C62.8371 33.0391 62.0118 33.5162 61.1465 33.9494C60.2619 34.4077 59.3408 34.792 58.3928 35.0985C58.1421 35.1786 57.8979 35.2447 57.6163 35.3151C57.341 35.3748 57.0626 35.4195 56.7823 35.4489C56.2782 35.503 55.8038 35.5274 55.3311 35.549C54.3888 35.5924 53.4644 35.5913 52.5411 35.5848C50.6945 35.5626 48.8636 35.4738 47.0246 35.3113L46.997 33.6326C48.803 33.4003 50.6171 33.1994 52.4122 32.9974C53.3096 32.8999 54.2047 32.7905 55.0792 32.6763C55.5125 32.6172 55.9457 32.5479 56.3453 32.4737C56.5171 32.4434 56.6871 32.4035 56.8544 32.3541C57.0119 32.2999 57.2053 32.2382 57.384 32.1662C58.1514 31.8546 58.8964 31.4901 59.6134 31.0755C60.3559 30.6493 61.0988 30.1918 61.8288 29.7055C62.5588 29.2192 63.2855 28.7156 64.0057 28.2017C64.726 27.6877 65.4456 27.1435 66.1215 26.6312L68.2486 29.2663Z"
              fill="#FFC3BD"
            />
            <path
              d="M70.436 27.3536C70.1144 29.4688 65.6609 32.7044 65.6609 32.7044L62.4659 28.7388C63.8655 27.2452 65.3495 25.8329 66.9107 24.509C68.3485 23.3339 70.7859 25.0521 70.436 27.3536Z"
              fill="#282A74"
            />
            <path
              d="M47.6133 33.8097L46.3429 32.9053L46.2037 35.7624C46.3958 35.7629 46.585 35.7164 46.7549 35.6269C46.9248 35.5374 47.0703 35.4077 47.1785 35.249L47.6133 33.8097Z"
              fill="#FFC3BD"
            />
            <path
              d="M45.1955 32.0735L44.9356 34.8738L46.2049 35.7624L46.3441 32.9053L45.1955 32.0735Z"
              fill="#FFC3BD"
            />
            <path
              opacity="0.2"
              d="M65.4836 89.6988L65.9093 92.3181L68.1507 92.0658L67.725 89.4464L65.4836 89.6988Z"
              fill="black"
            />
            <path opacity="0.2" d="M78.0258 89.8347H75.7871L76.0752 92.5055H78.3139L78.0258 89.8347Z" fill="black" />
            <path
              d="M78.0578 24.5767C78.2245 24.6141 78.382 24.6844 78.521 24.7835C78.6601 24.8827 78.7779 25.0087 78.8676 25.154C78.9573 25.2994 79.017 25.4612 79.0432 25.63C79.0693 25.7988 79.0615 25.9711 79.0201 26.1368C78.5345 28.1521 78.1686 30.1943 77.924 32.2528C77.7123 33.9857 77.5856 35.6411 77.5065 37.1417C77.3208 40.6589 77.3982 43.34 77.2932 44.2486C75.5435 44.1273 68.9499 43.6687 65.3547 43.4174C64.1412 33.9169 65.5632 27.854 66.362 25.3906C66.4843 25.0067 66.7131 24.6655 67.0218 24.4067C67.3305 24.1478 67.7064 23.982 68.1057 23.9285C68.5638 23.8684 69.1118 23.8104 69.6902 23.7763C69.8927 23.7633 70.0979 23.7563 70.307 23.7519C72.0042 23.772 73.699 23.8899 75.3827 24.105C75.7022 24.1429 76.0249 24.19 76.339 24.2404C76.9666 24.3455 77.5634 24.4662 78.0578 24.5767Z"
              fill="#EDB561"
            />
            <path
              d="M75.2794 18.3578C74.8462 19.9683 74.3166 22.938 75.3834 24.1077C72.6162 25.9689 70.8497 28.5894 70.3402 28.0202C70.0732 27.7224 69.9573 24.2756 70.3082 23.7536C72.1597 23.446 72.2068 22.0916 71.9783 20.8006L75.2794 18.3578Z"
              fill="#FFC3BD"
            />
            <path
              d="M75.1689 23.44C75.1689 23.44 70.6596 25.4355 70.0618 29.8977C71.304 28.4594 74.0479 26.8944 74.0479 26.8944L72.8376 26.5468C73.6073 26.2546 74.4042 26.0402 75.2165 25.9067C75.0822 25.2904 75.7981 24.0612 75.7981 24.0612L75.1689 23.44Z"
              fill="#282A74"
            />
            <path
              d="M71.1002 23.368C71.1002 23.368 71.7013 25.9164 70.0621 29.8977C69.7389 28.5081 69.2958 27.1492 68.7375 25.8363L69.8417 26.1368C69.8417 26.1368 69.7876 25.6229 68.8935 24.9395C69.2661 24.3984 69.7307 23.9268 70.2663 23.5462L71.1002 23.368Z"
              fill="#282A74"
            />
            <path
              opacity="0.2"
              d="M73.9337 19.3548L71.9799 20.7996C72.0377 21.1002 72.0691 21.4054 72.0735 21.7115C72.7775 21.6573 73.7848 20.9626 73.9099 20.2396C73.9713 19.9485 73.9793 19.6487 73.9337 19.3548Z"
              fill="black"
            />
            <path
              d="M69.5494 13.6942C68.4583 13.7413 67.8561 15.6226 68.9635 17.1719C70.0709 18.7212 71.0197 13.6309 69.5494 13.6942Z"
              fill="#263238"
            />
            <path
              d="M74.9305 15.3497C75.0599 17.5667 75.2484 18.8571 74.2726 20.1205C72.805 22.0207 69.9674 21.3395 69.1784 19.2059C68.4685 17.2856 68.3759 13.9828 70.4239 12.8484C70.8745 12.5958 71.3821 12.4626 71.8986 12.4615C72.4151 12.4604 72.9233 12.5914 73.3749 12.8421C73.8265 13.0927 74.2064 13.4547 74.4787 13.8936C74.751 14.3325 74.9065 14.8337 74.9305 15.3497Z"
              fill="#FFC3BD"
            />
            <path
              d="M75.2559 17.0533C74.6631 16.7058 74.1454 16.2438 73.7329 15.6942C73.3204 15.1447 73.0213 14.5186 72.8531 13.8523C72.2033 14.0061 68.9645 15.4964 67.9686 13.8523C66.9727 12.2083 68.2258 11.2438 70.0892 12.2559C69.265 10.8014 70.0145 10.0898 72.604 10.027C75.1936 9.9642 74.8454 11.4653 74.8454 11.4653C74.8454 11.4653 76.8057 10.6119 77.4989 12.1151C78.302 13.8426 76.8203 16.7923 75.2559 17.0533Z"
              fill="#263238"
            />
            <path
              d="M74.4026 11.0684C74.4265 11.0684 75.3833 11.2985 76.4859 10.1889C76.2882 11.1355 74.5759 11.7659 74.5759 11.7659L74.4026 11.0684Z"
              fill="#263238"
            />
            <path
              d="M75.6891 14.3116C74.6565 13.9547 73.4061 15.4845 73.868 17.3316C74.3299 19.1788 77.0803 14.7924 75.6891 14.3116Z"
              fill="#263238"
            />
            <path
              d="M76.2736 17.1935C76.1791 17.7441 75.8702 18.2348 75.4147 18.5582C74.8077 18.9817 74.2608 18.4975 74.2234 17.7968C74.192 17.1665 74.4693 16.1863 75.177 16.0417C75.3323 16.0127 75.4925 16.0229 75.6428 16.0715C75.7932 16.1201 75.929 16.2055 76.038 16.32C76.1469 16.4344 76.2255 16.5743 76.2667 16.7268C76.3079 16.8794 76.3102 17.0398 76.2736 17.1935Z"
              fill="#FFC3BD"
            />
            <path
              d="M68.7199 68.6275C69.2576 62.0826 73.2021 43.9649 73.2021 43.9649L65.3532 43.4174C65.3532 43.4174 62.3267 61.402 62.0949 68.2273C61.8534 75.324 64.7077 91.0964 64.7077 91.0964L68.4719 90.6681C68.4719 90.6681 68.18 75.1994 68.7199 68.6275Z"
              fill="#282A74"
            />
            <path
              opacity="0.5"
              d="M68.7199 68.6275C69.2576 62.0826 73.2021 43.9649 73.2021 43.9649L65.3532 43.4174C65.3532 43.4174 62.3267 61.402 62.0949 68.2273C61.8534 75.324 64.7077 91.0964 64.7077 91.0964L68.4719 90.6681C68.4719 90.6681 68.18 75.1994 68.7199 68.6275Z"
              fill="white"
            />
            <path
              opacity="0.2"
              d="M70.2454 50.1003C68.6631 52.1235 68.6349 59.2288 69.2468 64.4784C69.8301 60.6915 70.749 55.8822 71.5543 51.8733L70.2454 50.1003Z"
              fill="black"
            />
            <path
              d="M69.5884 43.7125C69.5884 43.7125 70.4354 62.8689 71.3803 69.3564C72.4146 76.4606 75.0416 91.9878 75.0416 91.9878H79.1398C79.1398 91.9878 78.4656 77.3634 77.9247 70.3837C77.3089 62.4335 77.2922 44.2497 77.2922 44.2497L69.5884 43.7125Z"
              fill="#282A74"
            />
            <path
              opacity="0.5"
              d="M69.5884 43.7125C69.5884 43.7125 70.4354 62.8689 71.3803 69.3564C72.4146 76.4606 75.0416 91.9878 75.0416 91.9878H79.1398C79.1398 91.9878 78.4656 77.3634 77.9247 70.3837C77.3089 62.4335 77.2922 44.2497 77.2922 44.2497L69.5884 43.7125Z"
              fill="white"
            />
            <path d="M74.5488 92.0749H79.3743V90.6334L73.9802 90.5397L74.5488 92.0749Z" fill="#282A74" />
            <path
              d="M64.5437 91.5204L69.0297 91.0119L68.8672 89.5623L63.8435 90.0545L64.5437 91.5204Z"
              fill="#282A74"
            />
            <path
              d="M71.6662 16.3661C71.6879 16.5453 71.6121 16.7018 71.4929 16.7159C71.3738 16.73 71.2633 16.5962 71.2422 16.417C71.2211 16.2378 71.2963 16.0813 71.4155 16.0672C71.5346 16.0531 71.6451 16.1869 71.6662 16.3661Z"
              fill="#263238"
            />
            <path
              d="M69.6415 16.6092C69.6632 16.7885 69.5874 16.945 69.4682 16.9591C69.3491 16.9731 69.2386 16.8394 69.2169 16.6601C69.1953 16.4809 69.2711 16.3244 69.3902 16.3103C69.5094 16.2962 69.6198 16.4322 69.6415 16.6092Z"
              fill="#263238"
            />
            <path
              d="M70.2956 16.6212C70.1478 17.1945 69.9218 17.7448 69.6241 18.2566C69.757 18.3293 69.9041 18.3722 70.0553 18.3823C70.2064 18.3923 70.358 18.3691 70.4992 18.3145L70.2956 16.6212Z"
              fill="#ED847E"
            />
            <path
              d="M72.2171 15.6356C72.1982 15.6413 72.1782 15.6417 72.1591 15.6368C72.14 15.632 72.1226 15.622 72.1088 15.608C72.0192 15.5095 71.9076 15.4336 71.7831 15.3865C71.6585 15.3395 71.5246 15.3226 71.3923 15.3372C71.3647 15.3419 71.3364 15.3357 71.3133 15.3199C71.2902 15.3042 71.2741 15.2801 71.2683 15.2527C71.2655 15.239 71.2655 15.2249 71.2682 15.2112C71.271 15.1974 71.2764 15.1844 71.2843 15.1728C71.2922 15.1613 71.3023 15.1514 71.3141 15.1438C71.3258 15.1363 71.339 15.1311 71.3528 15.1287C71.5198 15.1071 71.6896 15.1259 71.8478 15.1836C72.006 15.2412 72.1481 15.3361 72.262 15.4601C72.2814 15.4803 72.2922 15.5072 72.2922 15.5351C72.2922 15.5631 72.2814 15.59 72.262 15.6101C72.2494 15.6222 72.2339 15.631 72.2171 15.6356Z"
              fill="#263238"
            />
            <path
              d="M71.4388 19.0293C71.6943 18.9756 71.9332 18.8611 72.1352 18.6956C72.3371 18.5301 72.4963 18.3184 72.5993 18.0784C72.6043 18.064 72.6034 18.0483 72.5968 18.0345C72.5902 18.0208 72.5784 18.0103 72.5641 18.0053C72.5497 18.0003 72.5339 18.0011 72.5202 18.0077C72.5065 18.0143 72.496 18.0261 72.491 18.0405C72.3924 18.2612 72.2433 18.4557 72.0557 18.6083C71.8682 18.7609 71.6474 18.8672 71.4112 18.9188C71.4039 18.9205 71.397 18.9236 71.3909 18.9279C71.3848 18.9323 71.3796 18.9378 71.3757 18.9441C71.3717 18.9504 71.369 18.9575 71.3677 18.9649C71.3665 18.9722 71.3667 18.9798 71.3684 18.9871C71.3701 18.9944 71.3732 19.0012 71.3775 19.0073C71.3818 19.0134 71.3873 19.0186 71.3937 19.0226C71.4 19.0266 71.407 19.0293 71.4144 19.0305C71.4218 19.0317 71.4293 19.0315 71.4366 19.0299L71.4388 19.0293Z"
              fill="#263238"
            />
            <path
              d="M68.689 16.0168C68.6677 16.0239 68.6447 16.0239 68.6235 16.0168C68.5973 16.0071 68.5758 15.9877 68.5636 15.9626C68.5514 15.9374 68.5494 15.9086 68.558 15.882C68.6087 15.7213 68.6975 15.5753 68.8168 15.4564C68.936 15.3374 69.0822 15.249 69.243 15.1986C69.2702 15.1924 69.2987 15.197 69.3226 15.2113C69.3465 15.2256 69.3639 15.2486 69.3713 15.2755C69.3775 15.3026 69.373 15.331 69.3588 15.3549C69.3446 15.3788 69.3217 15.3963 69.295 15.4038C69.1686 15.446 69.054 15.5175 68.9607 15.6126C68.8674 15.7077 68.7981 15.8236 68.7583 15.9507C68.7526 15.9663 68.7434 15.9804 68.7314 15.9919C68.7193 16.0033 68.7049 16.0119 68.689 16.0168Z"
              fill="#263238"
            />
            <path
              d="M75.4232 95.1465C75.2701 95.1586 75.1173 95.1183 74.99 95.0322C74.9504 94.998 74.9198 94.9546 74.9009 94.9058C74.882 94.8569 74.8754 94.8042 74.8817 94.7522C74.8816 94.7213 74.8901 94.6909 74.9061 94.6645C74.9222 94.638 74.9452 94.6165 74.9727 94.6023C75.2213 94.4745 75.942 94.9185 76.0233 94.9694C76.0321 94.9751 76.0392 94.9832 76.0435 94.9928C76.0478 95.0025 76.0493 95.0131 76.0476 95.0235C76.0459 95.0339 76.0411 95.0435 76.034 95.0511C76.0269 95.0588 76.0177 95.0643 76.0076 95.0669C75.8164 95.1151 75.6204 95.1418 75.4232 95.1465ZM75.0907 94.6835C75.0671 94.6824 75.0436 94.6868 75.022 94.6965C75.011 94.7025 75.002 94.7117 74.9961 94.7227C74.9901 94.7337 74.9874 94.7462 74.9884 94.7588C74.9836 94.7943 74.9876 94.8304 75.0002 94.8639C75.0128 94.8975 75.0334 94.9274 75.0604 94.951C75.3104 95.0651 75.5946 95.0794 75.8548 94.9911C75.6216 94.8398 75.3612 94.7353 75.088 94.6835H75.0907Z"
              fill="#282A74"
            />
            <path
              d="M75.9935 95.068C75.9846 95.0679 75.9759 95.0657 75.9681 95.0615C75.7368 94.9358 75.289 94.4452 75.3361 94.195C75.3438 94.156 75.3648 94.1208 75.3955 94.0954C75.4262 94.0701 75.4647 94.0561 75.5045 94.0559C75.5448 94.051 75.5857 94.0545 75.6246 94.0662C75.6635 94.078 75.6995 94.0977 75.7303 94.1241C75.9903 94.3369 76.0439 94.9824 76.0455 95.0095C76.0462 95.019 76.0443 95.0285 76.0401 95.037C76.0359 95.0456 76.0296 95.0529 76.0217 95.0582C76.0134 95.064 76.0036 95.0674 75.9935 95.068ZM75.5419 94.1609H75.5175C75.4487 94.1696 75.4428 94.201 75.4401 94.215C75.4119 94.3623 75.6973 94.7328 75.9274 94.9077C75.9122 94.6518 75.8201 94.4064 75.6632 94.2037C75.629 94.1755 75.5861 94.1604 75.5419 94.1609Z"
              fill="#282A74"
            />
            <path
              d="M66.0903 94.7257L66.0816 94.7295C65.7313 94.8416 65.1042 95.0003 64.8361 94.8129C64.7966 94.7855 64.7642 94.749 64.742 94.7063C64.7197 94.6637 64.7082 94.6162 64.7083 94.5681C64.7062 94.5391 64.7116 94.5101 64.724 94.4838C64.7365 94.4575 64.7555 94.4349 64.7793 94.4181C65.0311 94.2411 65.9771 94.5892 66.0844 94.6299C66.0935 94.6338 66.1014 94.6403 66.1072 94.6484C66.1129 94.6566 66.1163 94.6663 66.1169 94.6762C66.1175 94.6862 66.1154 94.6962 66.1107 94.705C66.106 94.7138 66.0989 94.7212 66.0903 94.7263V94.7257ZM64.854 94.4961L64.8415 94.5037C64.8319 94.5102 64.8242 94.5192 64.8194 94.5299C64.8146 94.5405 64.8129 94.5522 64.8145 94.5638C64.8138 94.5956 64.8209 94.627 64.8353 94.6553C64.8497 94.6837 64.8708 94.708 64.8968 94.7263C65.226 94.829 65.5813 94.8098 65.8975 94.6721C65.5698 94.5267 65.2107 94.4662 64.8535 94.4961H64.854Z"
              fill="#282A74"
            />
            <path
              d="M66.0902 94.7257C66.0775 94.7322 66.0627 94.7334 66.0491 94.729C65.7675 94.6391 65.1826 94.2183 65.1934 93.9627C65.1962 93.9026 65.2303 93.8241 65.3732 93.7932C65.4249 93.7817 65.4783 93.7808 65.5302 93.7907C65.5822 93.8006 65.6316 93.821 65.6754 93.8506C65.905 94.07 66.0596 94.3561 66.1173 94.6683C66.1191 94.6774 66.1186 94.6868 66.1157 94.6956C66.1129 94.7044 66.1078 94.7124 66.101 94.7187C66.0977 94.7214 66.0941 94.7238 66.0902 94.7257ZM65.337 93.9188C65.3007 93.9383 65.2996 93.9611 65.299 93.9697C65.2915 94.1235 65.6954 94.4604 65.9884 94.5936C65.9343 94.3441 65.8048 94.1172 65.6175 93.9438C65.5854 93.9216 65.5492 93.9064 65.511 93.899C65.4727 93.8916 65.4334 93.8923 65.3954 93.901C65.3752 93.9042 65.3555 93.9102 65.337 93.9188Z"
              fill="#282A74"
            />
            <path
              d="M79.9454 27.8042C79.8886 28.6782 79.7857 29.4921 79.679 30.3325C79.5723 31.173 79.4288 31.9967 79.2712 32.8279C78.9594 34.5257 78.499 36.1928 77.8952 37.8099L77.6293 38.4365L77.5622 38.593L77.5454 38.6319L77.5178 38.6915L77.4398 38.8459C77.3367 39.0334 77.213 39.2088 77.071 39.369C76.8376 39.6317 76.5646 39.8564 76.262 40.035C76.0169 40.1809 75.7598 40.3057 75.4936 40.4081C74.6066 40.7281 73.6791 40.9217 72.7383 40.9832C71.03 41.1109 69.3127 41.0467 67.6187 40.7921L67.6799 39.1134L68.8637 38.9796C69.2606 38.9325 69.6554 38.8713 70.048 38.8171C70.8316 38.7018 71.607 38.5729 72.3462 38.4013C73.0299 38.2621 73.6946 38.0417 74.326 37.7449C74.4963 37.6734 74.6454 37.5595 74.7592 37.4141C74.7663 37.3756 74.6807 37.544 74.7815 37.2749L74.9585 36.7572C75.3923 35.2731 75.7241 33.761 75.9517 32.2317C76.079 31.453 76.1878 30.6672 76.2928 29.8793C76.3979 29.0914 76.4846 28.2872 76.5674 27.5318L79.9454 27.8042Z"
              fill="#FFC3BD"
            />
            <path
              d="M80.1403 26.1818C81.2359 28.0192 80.3028 33.794 80.3028 33.794L74.5627 32.7331C74.5627 32.7331 74.2518 29.4298 75.3446 26.8879C76.5311 24.1213 78.8131 23.9556 80.1403 26.1818Z"
              fill="#282A74"
            />
            <path
              d="M66.0727 44.3071L63.0266 30.1387C62.8804 29.5337 62.5415 28.9926 62.0609 28.597C61.5803 28.2014 60.9842 27.9727 60.3623 27.9455H48.6909C49.3128 27.9726 49.9091 28.2012 50.3898 28.5968C50.8704 28.9924 51.2095 29.5336 51.3557 30.1387L54.4018 44.3071C54.548 44.9122 54.8869 45.4533 55.3675 45.8489C55.8481 46.2444 56.4442 46.4731 57.0661 46.5003H68.737C68.1151 46.4731 67.519 46.2444 67.0384 45.8489C66.5578 45.4533 66.2189 44.9122 66.0727 44.3071Z"
              fill="#263238"
            />
            <path
              opacity="0.7"
              d="M66.0727 44.3071L63.0266 30.1387C62.8804 29.5337 62.5415 28.9926 62.0609 28.597C61.5803 28.2014 60.9842 27.9727 60.3623 27.9455H48.6909C49.3128 27.9726 49.9091 28.2012 50.3898 28.5968C50.8704 28.9924 51.2095 29.5336 51.3557 30.1387L54.4018 44.3071C54.548 44.9122 54.8869 45.4533 55.3675 45.8489C55.8481 46.2444 56.4442 46.4731 57.0661 46.5003H68.737C68.1151 46.4731 67.519 46.2444 67.0384 45.8489C66.5578 45.4533 66.2189 44.9122 66.0727 44.3071Z"
              fill="#282A74"
            />
            <path
              d="M48.6908 27.9455H60.3617C59.7641 27.9567 59.1912 28.1858 58.7507 28.5896C58.3101 28.9935 58.0322 29.5444 57.9692 30.1387L54.9416 63.0634C54.8785 63.6576 54.6006 64.2084 54.16 64.6123C53.7195 65.0161 53.1466 65.2452 52.5491 65.2565H45.2645C44.9839 65.2617 44.7055 65.2069 44.4478 65.0958C44.1901 64.9846 43.9591 64.8197 43.7703 64.612C43.5815 64.4044 43.4393 64.1588 43.3531 63.8917C43.2669 63.6246 43.2388 63.3422 43.2706 63.0634L46.2989 30.1387C46.3618 29.5444 46.6396 28.9937 47.0801 28.5898C47.5205 28.186 48.0933 27.9568 48.6908 27.9455Z"
              fill="#282A74"
            />
            <path
              d="M50.5552 63.0628C50.5234 63.3416 50.5515 63.6241 50.6377 63.8912C50.7239 64.1583 50.8661 64.4038 51.0549 64.6115C51.2437 64.8192 51.4747 64.9841 51.7323 65.0952C51.99 65.2064 52.2685 65.2612 52.5491 65.256H40.8782C40.5976 65.2613 40.3191 65.2065 40.0613 65.0954C39.8036 64.9843 39.5726 64.8194 39.3837 64.6117C39.1949 64.404 39.0527 64.1584 38.9666 63.8912C38.8804 63.6241 38.8524 63.3417 38.8843 63.0628H50.5552Z"
              fill="#263238"
            />
            <path
              opacity="0.7"
              d="M50.5552 63.0628C50.5234 63.3416 50.5515 63.6241 50.6377 63.8912C50.7239 64.1583 50.8661 64.4038 51.0549 64.6115C51.2437 64.8192 51.4747 64.9841 51.7323 65.0952C51.99 65.2064 52.2685 65.2612 52.5491 65.256H40.8782C40.5976 65.2613 40.3191 65.2065 40.0613 65.0954C39.8036 64.9843 39.5726 64.8194 39.3837 64.6117C39.1949 64.404 39.0527 64.1584 38.9666 63.8912C38.8804 63.6241 38.8524 63.3417 38.8843 63.0628H50.5552Z"
              fill="#282A74"
            />
            <g opacity="0.5">
              <path
                opacity="0.5"
                d="M55.9466 31.6344H48.049C47.988 31.6352 47.9276 31.6231 47.8717 31.5988C47.8157 31.5744 47.7656 31.5385 47.7246 31.4933C47.6837 31.4481 47.6528 31.3948 47.634 31.3368C47.6153 31.2787 47.609 31.2174 47.6158 31.1568C47.6299 31.0275 47.6905 30.9077 47.7863 30.8198C47.8822 30.7319 48.0067 30.6819 48.1368 30.6791H56.0333C56.0942 30.6783 56.1547 30.6904 56.2106 30.7148C56.2666 30.7391 56.3167 30.775 56.3576 30.8202C56.3986 30.8654 56.4295 30.9187 56.4483 30.9768C56.467 31.0348 56.4732 31.0961 56.4665 31.1568C56.4525 31.2859 56.392 31.4055 56.2964 31.4934C56.2008 31.5812 56.0764 31.6313 55.9466 31.6344Z"
                fill="white"
              />
              <path
                opacity="0.5"
                d="M55.7116 34.218H47.814C47.753 34.2188 47.6925 34.2067 47.6366 34.1824C47.5807 34.158 47.5306 34.1221 47.4896 34.0769C47.4486 34.0318 47.4177 33.9784 47.399 33.9204C47.3802 33.8623 47.374 33.801 47.3808 33.7404C47.3948 33.6111 47.4554 33.4913 47.5513 33.4034C47.6471 33.3155 47.7717 33.2655 47.9017 33.2628H55.7993C55.8603 33.2619 55.9207 33.2741 55.9767 33.2984C56.0326 33.3227 56.0827 33.3586 56.1237 33.4038C56.1647 33.449 56.1955 33.5023 56.2143 33.5604C56.2331 33.6184 56.2393 33.6798 56.2325 33.7404C56.2186 33.8697 56.158 33.9895 56.0621 34.0774C55.9663 34.1654 55.8416 34.2153 55.7116 34.218Z"
                fill="white"
              />
              <path
                opacity="0.5"
                d="M53.5406 36.8016H47.5789C47.518 36.8024 47.4575 36.7903 47.4016 36.766C47.3456 36.7417 47.2955 36.7057 47.2546 36.6605C47.2136 36.6154 47.1827 36.562 47.1639 36.504C47.1452 36.4459 47.139 36.3846 47.1457 36.324C47.1598 36.1947 47.2204 36.0749 47.3162 35.987C47.4121 35.8991 47.5366 35.8491 47.6667 35.8464H53.6283C53.6893 35.8455 53.7497 35.8577 53.8057 35.882C53.8616 35.9063 53.9117 35.9422 53.9527 35.9874C53.9937 36.0326 54.0246 36.086 54.0433 36.144C54.0621 36.202 54.0683 36.2634 54.0615 36.324C54.0475 36.4533 53.9869 36.573 53.891 36.6609C53.7952 36.7488 53.6706 36.7988 53.5406 36.8016Z"
                fill="white"
              />
              <path
                opacity="0.5"
                d="M49.3661 39.3847H47.3446C47.2836 39.3855 47.2232 39.3734 47.1673 39.349C47.1113 39.3247 47.0612 39.2888 47.0202 39.2436C46.9793 39.1984 46.9484 39.1451 46.9296 39.087C46.9108 39.029 46.9046 38.9677 46.9114 38.907C46.9253 38.7778 46.9858 38.658 47.0816 38.5702C47.1774 38.4823 47.3018 38.4322 47.4318 38.4294H49.4539C49.5148 38.4286 49.5753 38.4407 49.6312 38.4651C49.6872 38.4894 49.7373 38.5253 49.7782 38.5705C49.8192 38.6157 49.8501 38.669 49.8689 38.7271C49.8876 38.7851 49.8938 38.8464 49.8871 38.907C49.873 39.0363 49.8124 39.1561 49.7166 39.244C49.6207 39.3319 49.4962 39.3819 49.3661 39.3847Z"
                fill="white"
              />
              <path
                opacity="0.5"
                d="M55.0067 41.9683H47.107C47.046 41.9691 46.9855 41.957 46.9296 41.9327C46.8737 41.9083 46.8235 41.8724 46.7826 41.8272C46.7416 41.7821 46.7107 41.7287 46.6919 41.6707C46.6732 41.6126 46.667 41.5513 46.6737 41.4907C46.6878 41.3614 46.7484 41.2416 46.8442 41.1537C46.9401 41.0658 47.0647 41.0158 47.1947 41.013H55.0923C55.1533 41.0122 55.2137 41.0244 55.2696 41.0487C55.3256 41.073 55.3757 41.1089 55.4167 41.1541C55.4576 41.1993 55.4885 41.2526 55.5073 41.3107C55.5261 41.3687 55.5323 41.4301 55.5255 41.4907C55.5115 41.6196 55.4512 41.7391 55.3558 41.8269C55.2604 41.9147 55.1364 41.965 55.0067 41.9683Z"
                fill="white"
              />
              <path
                opacity="0.5"
                d="M51.6616 44.5519H46.8745C46.8135 44.5527 46.7531 44.5406 46.6972 44.5163C46.6412 44.4919 46.5911 44.456 46.5501 44.4108C46.5092 44.3657 46.4783 44.3123 46.4595 44.2543C46.4408 44.1962 46.4345 44.1349 46.4413 44.0743C46.4552 43.945 46.5157 43.8253 46.6115 43.7374C46.7073 43.6495 46.8318 43.5994 46.9617 43.5966H51.7488C51.8098 43.5958 51.8702 43.608 51.9262 43.6323C51.9821 43.6566 52.0322 43.6925 52.0732 43.7377C52.1141 43.7829 52.145 43.8362 52.1638 43.8943C52.1826 43.9523 52.1888 44.0137 52.182 44.0743C52.168 44.2035 52.1075 44.3232 52.0117 44.411C51.916 44.4989 51.7915 44.549 51.6616 44.5519Z"
                fill="white"
              />
              <path
                opacity="0.5"
                d="M54.537 47.135H46.6394C46.5784 47.1358 46.518 47.1237 46.4621 47.0993C46.4061 47.075 46.356 47.0391 46.315 46.9939C46.2741 46.9487 46.2432 46.8954 46.2244 46.8373C46.2056 46.7793 46.1994 46.718 46.2062 46.6573C46.2202 46.5281 46.2809 46.4083 46.3767 46.3204C46.4726 46.2325 46.5971 46.1825 46.7272 46.1797H54.6253C54.6863 46.1789 54.7467 46.191 54.8027 46.2154C54.8586 46.2397 54.9087 46.2756 54.9497 46.3208C54.9906 46.366 55.0215 46.4193 55.0403 46.4774C55.0591 46.5354 55.0653 46.5967 55.0585 46.6573C55.0444 46.7867 54.9838 46.9066 54.8878 46.9945C54.7918 47.0824 54.6671 47.1323 54.537 47.135Z"
                fill="white"
              />
              <path
                opacity="0.5"
                d="M52.366 49.7186H46.4044C46.3434 49.7194 46.2829 49.7073 46.227 49.6829C46.1711 49.6586 46.121 49.6227 46.08 49.5775C46.039 49.5323 46.0081 49.479 45.9894 49.4209C45.9706 49.3629 45.9644 49.3016 45.9712 49.241C45.9852 49.1117 46.0458 48.9919 46.1417 48.904C46.2375 48.8161 46.3621 48.7661 46.4921 48.7633H52.4537C52.5147 48.7625 52.5752 48.7746 52.6311 48.799C52.687 48.8233 52.7372 48.8592 52.7781 48.9044C52.8191 48.9496 52.85 49.0029 52.8688 49.061C52.8875 49.119 52.8937 49.1803 52.887 49.241C52.8729 49.3702 52.8123 49.49 52.7165 49.5779C52.6206 49.6658 52.496 49.7158 52.366 49.7186Z"
                fill="white"
              />
              <path
                opacity="0.5"
                d="M48.1914 52.3016H46.1682C46.1073 52.3025 46.0468 52.2903 45.9909 52.266C45.9349 52.2417 45.8848 52.2058 45.8439 52.1606C45.8029 52.1154 45.772 52.062 45.7532 52.004C45.7345 51.946 45.7282 51.8846 45.735 51.824C45.7491 51.6947 45.8097 51.575 45.9055 51.4871C46.0014 51.3992 46.1259 51.3492 46.256 51.3464H48.2802C48.3412 51.3456 48.4016 51.3577 48.4575 51.382C48.5135 51.4064 48.5636 51.4423 48.6046 51.4875C48.6455 51.5326 48.6764 51.586 48.6952 51.644C48.714 51.7021 48.7202 51.7634 48.7134 51.824C48.6993 51.9535 48.6386 52.0734 48.5425 52.1613C48.4464 52.2492 48.3216 52.2991 48.1914 52.3016Z"
                fill="white"
              />
              <path
                opacity="0.5"
                d="M53.8321 54.8858H45.9345C45.8736 54.8866 45.8131 54.8745 45.7572 54.8502C45.7012 54.8259 45.6511 54.7899 45.6102 54.7447C45.5692 54.6996 45.5383 54.6462 45.5195 54.5882C45.5008 54.5301 45.4945 54.4688 45.5013 54.4082C45.5153 54.2789 45.576 54.1591 45.6718 54.0712C45.7677 53.9834 45.8922 53.9333 46.0223 53.9306H53.9198C53.9808 53.9297 54.0413 53.9419 54.0972 53.9662C54.1531 53.9905 54.2033 54.0264 54.2442 54.0716C54.2852 54.1168 54.3161 54.1702 54.3349 54.2282C54.3536 54.2862 54.3598 54.3476 54.3531 54.4082C54.339 54.5375 54.2784 54.6572 54.1826 54.7451C54.0867 54.833 53.9621 54.883 53.8321 54.8858Z"
                fill="white"
              />
              <path
                opacity="0.5"
                d="M50.4871 57.4689H45.7C45.639 57.4697 45.5785 57.4576 45.5226 57.4332C45.4667 57.4089 45.4166 57.373 45.3756 57.3278C45.3346 57.2826 45.3037 57.2293 45.285 57.1712C45.2662 57.1132 45.26 57.0519 45.2668 56.9913C45.2807 56.8619 45.3413 56.7421 45.4371 56.6542C45.533 56.5663 45.6576 56.5163 45.7877 56.5136H50.5742C50.6352 56.5128 50.6957 56.5249 50.7516 56.5493C50.8075 56.5736 50.8576 56.6095 50.8986 56.6547C50.9396 56.6999 50.9705 56.7532 50.9892 56.8113C51.008 56.8693 51.0142 56.9306 51.0075 56.9913C50.9934 57.1205 50.9329 57.2402 50.8372 57.328C50.7414 57.4159 50.617 57.466 50.4871 57.4689Z"
                fill="white"
              />
              <path
                opacity="0.5"
                d="M53.362 60.0525H45.4644C45.4034 60.0533 45.3429 60.0412 45.287 60.0169C45.2311 59.9925 45.181 59.9566 45.14 59.9114C45.099 59.8663 45.0681 59.8129 45.0494 59.7549C45.0306 59.6968 45.0244 59.6355 45.0312 59.5749C45.0452 59.4456 45.1058 59.3258 45.2017 59.2379C45.2975 59.15 45.4221 59.1 45.5521 59.0972H53.4486C53.5096 59.0964 53.57 59.1085 53.626 59.1329C53.6819 59.1572 53.732 59.1931 53.773 59.2383C53.814 59.2835 53.8449 59.3368 53.8636 59.3949C53.8824 59.4529 53.8886 59.5142 53.8818 59.5749C53.8678 59.704 53.8074 59.8236 53.7117 59.9115C53.6161 59.9993 53.4918 60.0494 53.362 60.0525Z"
                fill="white"
              />
            </g>
            <path
              d="M68.3684 39.2964L66.6539 37.8067L65.8503 40.5489C65.8503 40.5489 67.5918 41.4934 68.1951 40.6264L68.3684 39.2964Z"
              fill="#FFC3BD"
            />
            <path d="M64.7861 37.6994L64.2283 39.9977L65.8486 40.55L66.6522 37.8072L64.7861 37.6994Z" fill="#FFC3BD" />
          </svg>
          {showReturnFeed && (
            <>
              {showReturnFeed === 'insight' && (
                <p className="text-center">{t('the_insight_will_be_deleted_and_cannot_be_recovered')}</p>
              )}
              {showReturnFeed === 'insightSteps' && (
                <p className="text-center">{t('the_insight_will_be_deleted_and_cannot_be_recovered')}</p>
              )}
              {showReturnFeed === 'groups' && (
                <p className="text-center">{t('the_group_will_be_deleted_and_cannot_be_recovered')}</p>
              )}
              {showReturnFeed === 'calendar' && (
                <p className="text-center">{t('the_calendar_will_be_deleted_and_cannot_be_recovered')}</p>
              )}
              {showReturnFeed === 'category' && (
                <p className="text-center">{t('the_category_will_be_deleted_and_cannot_be_recovered')}</p>
              )}
              {showReturnFeed === 'ads' && (
                <p className="text-center">{t('the_ads_will_be_deleted_and_cannot_be_recovered')}</p>
              )}
            </>
          )}
          <Button onClick={handleConfirmHome} size="medium" variant="primary" text={t('confirm')} />
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
