import * as Dialog from '@radix-ui/react-dialog';
import { useCallback, useRef, useState } from 'react';
import { toast } from 'utils/toast';

import photo from '@assets/icons/Button/photo.svg';
import ButtonAction from '@components/ButtonAction';
import { base64ToFile } from 'utils/auxFunctions';

import galery from '@assets/icons/Button/galery.svg';
import { Collection, useInsights } from '@hooks/useInsights';
import { X } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';

interface ShareInsightPhotoOrVideoModalProps {
  collection: Collection;
  isEdit?: boolean;
}

export default function ShareInsightPhotoOrVideoModal({
  collection,
  isEdit,
}: Readonly<ShareInsightPhotoOrVideoModalProps>) {
  const { t } = useTranslation();
  const { editCollection, creatingInsight, editingInsight } = useInsights();
  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const inputEditImage = useRef<HTMLInputElement>(null);
  const closeButton = useRef<HTMLButtonElement>(null);

  const webcamRef = useRef<any>(null);

  const captureImage = useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot();
    let files: any = [];
    if (!isEdit) files = creatingInsight.collections.find((c) => c.uuid === collection.uuid)?.uploadedFiles || [];
    else files = editingInsight.collections.find((c) => c.uuid === collection.uuid)?.mediasToEdit || [];
    if (imageSrc) {
      const imageFile = base64ToFile(imageSrc);
      if (!isEdit) editCollection({ uploadedFiles: [...files, imageFile] }, collection.uuid);
      else editCollection({ mediasToEdit: [...files, { file: imageFile }] }, collection.uuid, true);
      setIsCameraOpen(false);
      closeButton.current?.click();
    } else {
      toast({
        label: t('error'),
        message: t('failed_to_capture_image'),
        type: 'error',
      });
    }
  }, [isEdit, creatingInsight.collections, editingInsight.collections, collection.uuid, editCollection, t]);

  function handleImageOrVideoChange(event: React.ChangeEvent<HTMLInputElement>) {
    const uploadedFiles = event.currentTarget.files as FileList;
    let files: any = [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i];
      const fileNameParts = file.name.split('.');
      const extension =
        fileNameParts?.length > 1 ? fileNameParts?.[fileNameParts?.length - 1]?.toLowerCase?.() ?? '' : '';
      if (['svg', 'gif'].includes(extension)) {
        toast({
          label: t('error'),
          message: t('only_these_image_extensions_are_allowed'),
          type: 'error',
        });
        throw new Error('Invalid file type');
      }
    }
    if (!isEdit) files = creatingInsight.collections.find((c) => c.uuid === collection.uuid)?.uploadedFiles || [];
    else files = editingInsight.collections.find((c) => c.uuid === collection.uuid)?.mediasToEdit || [];

    if (uploadedFiles) {
      if (!isEdit) editCollection({ uploadedFiles: [...files, ...uploadedFiles] }, collection.uuid);
      else {
        Array.from(uploadedFiles).forEach((file) => {
          files.push({ file: file });
        });
        editCollection({ mediasToEdit: files }, collection.uuid, true);
      }
      closeButton.current?.click();
    }
  }

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '10',
        }}
      />
      <Dialog.Content
        forceMount
        className={`z-40 ${
          isEdit ? 'w-[calc(100vw-200px)]' : 'w-[100vw]'
        } rounded-lg p-4 right-0 bottom-0 fixed bg-white font-poppins`}
      >
        <Dialog.Close onClick={() => setIsCameraOpen(false)} ref={closeButton} className={'absolute top-4 right-8'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
            <path
              d="M3.35209 0.489335C2.69963 -0.163112 1.6418 -0.163112 0.989335 0.489335C0.336888 1.1418 0.336888 2.19963 0.989335 2.85209L10.6372 12.4999L0.989435 22.1478C0.336989 22.8002 0.336989 23.8581 0.989435 24.5105C1.6419 25.1629 2.69973 25.1629 3.35217 24.5105L12.9999 14.8627L22.6478 24.5105C23.3002 25.1629 24.3581 25.1629 25.0105 24.5105C25.6629 23.8581 25.6629 22.8002 25.0105 22.1478L15.3627 12.4999L25.0107 2.85209C25.6631 2.19963 25.6631 1.1418 25.0107 0.489335C24.3583 -0.163112 23.3004 -0.163112 22.648 0.489335L12.9999 10.1372L3.35209 0.489335Z"
              fill="#737373"
            />
          </svg>
        </Dialog.Close>
        {isCameraOpen ? (
          <div className="w-full flex flex-col items-center gap-1">
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '600px',
                maxHeight: '600px',
              }}
              mirrored={true}
            />
            <div className="flex gap-12 w-full items-center justify-center mt-2">
              <button className="hover:opacity-70" onClick={captureImage}>
                <img alt="avatar" className="w-12 h-12" src={photo} />
              </button>
              <button className="opacity-60 hover:opacity-40" onClick={() => setIsCameraOpen(false)}>
                <X size={48} />
              </button>
            </div>
          </div>
        ) : (
          <div className="w-full flex flex-col justify-center gap-1 p-10">
            <input
              id="choose-from-library"
              accept="image/png, image/jpeg, image/avif, image/jpg, video/*"
              type="file"
              className="hidden"
              multiple
              ref={inputEditImage}
              onChange={handleImageOrVideoChange}
            />
            <ButtonAction
              onClick={() => inputEditImage.current?.click()}
              src={galery}
              text={t('choose_from_library')}
            />
            <ButtonAction onClick={() => setIsCameraOpen(true)} src={photo} text={t('camera')} />
          </div>
        )}
      </Dialog.Content>
    </Dialog.Portal>
  );
}
