import dayjs from 'dayjs';
import { useState } from 'react';

import { CalendarUser, MoonPhase } from '@hooks/useCalendar';
import { Event } from '@hooks/useEvent';

import { BiDotsHorizontalRounded } from 'react-icons/bi';

interface DayCardProps {
  calendarIndex: number;
  day: moment.Moment;
  selectedDate?: string;
  setDate?: React.Dispatch<React.SetStateAction<string>>;
  type: 'datePicker' | 'eventPicker';
  userCalendar?: CalendarUser | object;
  onDateSelect: (day: string) => void;
}

type EventNamesByDate = Record<string, { names: string[]; colors: string[] }>;
type MoonPhaseDataByDate = Record<string, MoonPhase | undefined>;

export default function DayCard({
  calendarIndex,
  day,
  selectedDate,
  setDate,
  type,
  userCalendar,
  onDateSelect,
}: Readonly<DayCardProps>) {
  const today = dayjs().format('DD-MM-YYYY');
  const eventNamesByDate: EventNamesByDate = {};
  const [isSelected, setIsSelected] = useState(false);

  for (const date in userCalendar) {
    if (Object.hasOwn(userCalendar, date)) {
      //@ts-expect-error parse string as index
      const eventData: Event[] = userCalendar[date].event;

      if (eventData && eventData.length > 0) {
        eventNamesByDate[date] = {
          names: eventData.map((event) => event.title),
          colors: eventData.map((event) => event.event_label?.color || '#282A74'),
        };
      }
    }
  }

  const eventsForDay = eventNamesByDate[day.format('YYYY-MM-DD')] || { names: [], colors: [] };

  const moonPhaseDataByDate: MoonPhaseDataByDate = {};

  for (const date in userCalendar) {
    if (Object.hasOwn(userCalendar, date)) {
      //@ts-expect-error parse string as index
      const moonPhaseData: MoonPhase[] = userCalendar[date].moonPhase;

      if (moonPhaseData && moonPhaseData.length > 0) {
        // Assumindo que há apenas um moonPhase por data
        moonPhaseDataByDate[date] = moonPhaseData[0];
      }
    }
  }

  const moonPhaseData = moonPhaseDataByDate[day.format('YYYY-MM-DD')];

  function handleSelectDate() {
    if (type === 'datePicker') {
      if (typeof setDate == 'function') {
        setDate(day.format('DD-MM-YYYY'));
      }
    }
    const selectedDay = day.format('DD');
    onDateSelect(selectedDay);
    setIsSelected(!isSelected);
  }

  return (
    <>
      {calendarIndex === 0 && Number(day.format('DD').toString()) > 7 ? (
        <div className="flex flex-col w-[50px] h-[70px] border border-grey-600 border-opacity-50">
          <p className="text-primary-200 text-sm font-medium text-right w-full opacity-40">
            {day.format('DD').toString()}
          </p>
          <div className="flex flex-col w-full"></div>
        </div>
      ) : (
        <>
          {calendarIndex > 3 && Number(day.format('DD').toString()) < 20 ? (
            <div className="flex flex-col w-[50px] h-[70px] border border-grey-600 border-opacity-50">
              <p className="text-primary-200 text-sm font-medium text-right w-full opacity-40">
                {day.format('DD').toString()}
              </p>
              <div className="flex flex-col w-full"></div>
            </div>
          ) : (
            <button
              onClick={handleSelectDate}
              className={`flex flex-col w-[50px] h-[70px] border border-grey-600 border-opacity-50 hover:border-primary-200 ${
                selectedDate === day.format('DD-MM-YYYY') ? 'border-2 border-primary-200' : ''
              }`}
            >
              <div className="flex justify-between w-full px-[2px]">
                {moonPhaseData && (
                  <div className="relative group">
                    <div className="absolute -top-5 left-0 w-32 rounded-sm h-full bg-primary-200 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                      <p className="text-white text-xs capitalize">{moonPhaseData.title}</p>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: moonPhaseData.svg }} className="moon-svg" />
                  </div>
                )}
                <p
                  className={`${
                    selectedDate === day.format('DD-MM-YYYY')
                      ? 'text-primary-100'
                      : today === day.format('DD-MM-YYYY')
                        ? 'text-primary-100'
                        : 'text-primary-200'
                  }  text-sm font-medium text-right w-full`}
                >
                  {day.format('DD').toString()}
                </p>
              </div>
              <div className="flex flex-col w-full gap-[2px] mt-[2px] overflow-hidden">
                {eventsForDay.names.map((eventName, index) => (
                  <div
                    className={`p-[1px] rounded-sm`}
                    key={index}
                    style={{ backgroundColor: eventsForDay.colors[index] }}
                  >
                    <p className="text-[7px] truncate w-10 text-white">{eventName}</p>
                  </div>
                ))}
                {eventsForDay.names.length > 2 && <BiDotsHorizontalRounded />}
              </div>
            </button>
          )}
        </>
      )}
    </>
  );
}
