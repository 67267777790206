import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useGroups } from '@hooks/useGroups';
import { User, useUser } from '@hooks/useUser';

import { Button } from '@components/Button';
import Loading from '@components/Loading';

import userNoPhoto from '@assets/icons/user-no-photo.svg';

import { MagnifyingGlass, X } from '@phosphor-icons/react';
import { FaCheck } from 'react-icons/fa6';
import { IoClose } from 'react-icons/io5';
import { isEmail, isPhoneNumber } from 'utils/auxFunctions';
import { toast } from 'utils/toast';

const orderName = (a: User, b: User) => {
  const nomeA = `${a.first_name} ${a.last_name}`;
  const nomeB = `${b.first_name} ${b.last_name}`;
  return nomeA.localeCompare(nomeB);
};

export default function AddParticipants() {
  const [invitedMembers, setInvitedMembers] = useState<User[]>([]);
  const [contactsOrdered, setContactsOrdered] = useState<{
    [firstLetter: string]: User[];
  }>({});
  const [listOfContacts, setListOfContacts] = useState<User[]>([]);
  const [search, setSearch] = useState('');
  const [usersFounded, setUsersFounded] = useState<User[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);

  const { groupId } = useParams();
  const [searchParams] = useSearchParams();
  const next = searchParams.get('next');

  const navigate = useNavigate();

  const inviteByEmailOrPhoneInput = useRef<HTMLInputElement>(null);

  const { getAllUsersFromAllGroups } = useUser();
  const {
    inviteByEmailOrPhone,
    bulkAddMembersToGroup,
    getGroupByUuid,
    getGuestsForGroup,
    guests,
    approveGuest,
    rejectGuest,
  } = useGroups();

  const { t } = useTranslation();

  useEffect(() => {
    const orderedContacts = listOfContacts.slice().sort(orderName);
    const toReturnContactsOrdered: {
      [firstLetter: string]: User[];
    } = {};
    orderedContacts.forEach((contact) => {
      if (contact.first_name) {
        const firstLetter = contact.first_name[0].toUpperCase();
        const contacts = toReturnContactsOrdered[firstLetter];
        if (!contacts) {
          toReturnContactsOrdered[firstLetter] = [contact];
        } else {
          toReturnContactsOrdered[firstLetter].push(contact);
        }
      }
    });
    setContactsOrdered(toReturnContactsOrdered);
  }, [listOfContacts]);

  const getListOfContacts = useCallback(async () => {
    if (!groupId) return;
    setIsLoading(true);
    await Promise.all([
      getGroupByUuid(groupId),
      getAllUsersFromAllGroups(`?groupUuid=${groupId}`),
      getGuestsForGroup(groupId),
    ]).then((data) => {
      const group = data[0];
      const users = data[1];
      if (group.role !== 'Admin') {
        setIsLoading(false);
        setHasPermission(false);
        return;
      }
      setHasPermission(true);
      setListOfContacts(users);
    });
    setIsLoading(false);
  }, [getAllUsersFromAllGroups, getGroupByUuid, getGuestsForGroup, groupId]);

  const getUsersByFilter = useCallback(
    async (search: string) => {
      if (!groupId) return;
      const users = await getAllUsersFromAllGroups(`?groupUuid=${groupId}&search=${search}`);
      setUsersFounded(users);
    },
    [getAllUsersFromAllGroups, groupId],
  );

  async function handleInviteUserByEmailOrPhone() {
    if (!groupId) return;
    if (!inviteByEmailOrPhoneInput.current?.value) {
      toast({
        label: t('error'),
        message: t('fill_all_fields'),
        type: 'error',
      });
      return;
    }
    const phone = inviteByEmailOrPhoneInput.current?.value.startsWith('+')
      ? inviteByEmailOrPhoneInput.current?.value
      : `+${inviteByEmailOrPhoneInput.current?.value}`;
    if (!isEmail(inviteByEmailOrPhoneInput.current?.value || '') && !isPhoneNumber(phone || '')) {
      toast({
        label: t('error'),
        message: t('invalid_email_or_phone'),
        type: 'error',
      });
      return;
    }
    const response = await inviteByEmailOrPhone(groupId, inviteByEmailOrPhoneInput.current.value);
    if (response?.content?.data) {
      toast({
        label: t('success'),
        message: response?.content?.message || `We send an email/sms to the email/mobile number provided`,
        type: 'success',
      });
      await Promise.all([getAllUsersFromAllGroups(`?groupUuid=${groupId}`), getGuestsForGroup(groupId)]).then(
        (data) => {
          const users = data[0];
          setListOfContacts(users);
        },
      );
      inviteByEmailOrPhoneInput.current.value = '';
    } else {
      toast({
        label: t('error'),
        message: response?.content?.message || 'Failed to invite user',
        type: 'error',
      });
    }
  }

  useEffect(() => {
    getListOfContacts();
  }, [getListOfContacts]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) {
        getUsersByFilter(search);
        setIsSearching(true);
      } else {
        setUsersFounded([]);
        setIsSearching(false);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [getUsersByFilter, search]);

  async function handleInviteUsers() {
    if (!groupId) return;
    if (invitedMembers.length === 0) {
      toast({
        label: t('error'),
        message: t('please_select_at_least_one_user_to_invite'),
        type: 'error',
      });
      return;
    }
    const members = invitedMembers.map((member) => member.uuid);
    const response = await bulkAddMembersToGroup(groupId, members);
    if (response?.content?.status) {
      toast({
        label: t('success'),
        message: response?.content?.message || 'User(s) added successfully',
        type: 'success',
      });
    }
    if (next === 'categories') {
      navigate(`/groups/${groupId}/category/create`);
    } else {
      const users = await getAllUsersFromAllGroups(`?groupUuid=${groupId}`);
      setListOfContacts(users);
      setSearch('');
      setIsSearching(false);
      setInvitedMembers([]);
      setInvitedMembers([]);
    }
  }

  async function handleAproveOrRejectGuest(guestUuid: string, isApprove: boolean) {
    if (!groupId) return;
    if (isApprove) {
      const response = await approveGuest(groupId, guestUuid);
      if (response?.data?.uuid) {
        toast({
          label: t('success'),
          message: t('user_approved'),
          type: 'success',
        });
      }
    } else {
      const response = await rejectGuest(groupId, guestUuid);
      if (response?.data?.uuid) {
        toast({
          label: t('success'),
          message: t('user_rejected'),
          type: 'success',
        });
      }
    }
  }
  console.log(next);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col w-full items-center bg-backgray min-h-[calc(100vh-88px)]">
          <section className="flex flex-col w-[30vw] desktop-lg:w-[40vw] mt-12 items-center">
            {hasPermission ? (
              <>
                <div className="w-full">
                  <div className="flex items-center justify-between mb-12">
                    <h3 className="text-xl font-bold text-primary-200">{t('invite_to_this_group_with_a_link')}</h3>
                    <Button
                      onClick={() => navigate('share', { state: { groupId } })}
                      size="small"
                      variant="primary"
                      text={t('invite')}
                      boxShadow
                    />
                  </div>
                  <input
                    ref={inviteByEmailOrPhoneInput}
                    placeholder={t('invite_by_email_or_phone_number')}
                    className="block border border-primary-200 font-medium px-4 py-6 w-full text-xl text-primary-200 bg-white rounded-lg appearance-none focus:outline-none focus:ring-0 peer a mb-5 placeholder:text-primary-200 placeholder:opacity-60"
                  />
                  {/* INVITE USER BY EMAIL OR PHONE BUTTON */}
                  <span className="flex justify-end">
                    <Button
                      onClick={handleInviteUserByEmailOrPhone}
                      size="small"
                      variant="primary"
                      text={t('invite')}
                      boxShadow
                    />
                  </span>
                </div>

                {/* SEARCH CONTAINER */}
                <div className="w-full relative my-12">
                  <span className="flex justify-between bg-white rounded-lg px-4 py-6 border border-primary-200 items-center w-full">
                    <input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className="bg-transparent focus:outline-none w-full mr-2 text-primary-200 text-xl font-medium placeholder:text-primary-200 placeholder:opacity-60"
                      type="text"
                      placeholder={t('search_for_a_name_or_e-mail')}
                    />
                    <MagnifyingGlass size={20} />
                  </span>
                  {isSearching && (
                    <>
                      {usersFounded.length > 0 ? (
                        <div className="absolute left-0 bg-white w-full p-2 rounded-md z-[1]">
                          {usersFounded.map((contact) => {
                            const existUser = !!invitedMembers.find((item) => item.uuid === contact.uuid);
                            return (
                              <button
                                onClick={() => {
                                  setInvitedMembers((prev) => {
                                    const member = prev.find((item) => {
                                      return item.uuid === contact.uuid;
                                    });
                                    if (!member) {
                                      return [...prev, contact];
                                    }
                                    return prev;
                                  });
                                  setUsersFounded([]);
                                  setSearch('');
                                  setIsSearching(false);
                                }}
                                disabled={existUser}
                                key={contact.uuid}
                                className={`flex items-center gap-5 bg-white w-full p-3 border-b border-grey-600 text-start disabled:opacity-50 hover:opacity-70`}
                              >
                                <img
                                  src={contact.image || userNoPhoto}
                                  alt={contact.first_name}
                                  className="min-w-12 min-h-12 max-w-12 max-h-12 rounded-full"
                                />
                                <div className="flex flex-col">
                                  <p className="text-black-100 text-lg">
                                    {contact.first_name} {contact.last_name}
                                  </p>
                                  <p className="text-black-100 text-xs">{contact.email}</p>
                                </div>
                                {existUser && (
                                  <span className="text-xs text-black-100 font-semibold ml-auto">
                                    {t('already_invited')}
                                  </span>
                                )}
                              </button>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="absolute left-0 bg-white w-full p-2 rounded-md z-[1]">
                          <p className="text-black-100 text-lg text-center font-semibold py-2">
                            {t('no_users_founded')}
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>

                {/* INVITED MEMBERS LIST */}
                {invitedMembers.length > 0 && (
                  <div className="flex flex-wrap items-center w-full gap-4 mb-5">
                    {invitedMembers.map((member) => (
                      <div className="relative" key={member.uuid}>
                        <img
                          alt={member.first_name}
                          src={member.image || userNoPhoto}
                          className="w-[52px] h-[52px] rounded-full"
                        />
                        <button
                          onClick={() => setInvitedMembers((prev) => prev.filter((item) => item.uuid !== member.uuid))}
                          className="absolute top-[-5px] right-[-5px] bg-primary-200 rounded-full p-1 hover:opacity-90"
                        >
                          <X size={15} fill="#fff" weight="bold" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}

                {/* INVITE USER MASSIVE BUTTON */}
                <span className="mb-10 flex items-center gap-10">
                  <Button onClick={handleInviteUsers} size="medium" variant="primary" text={t('save')} />
                  {next && (
                    <Button
                      onClick={() => navigate(`/groups/${groupId}/category/create`)}
                      size="medium"
                      variant="primary"
                      text={t('skip')}
                    />
                  )}
                </span>

                {/* PENDING LIST */}
                {guests && guests?.length > 0 && (
                  <>
                    <p className="text-start w-full text-black-100 font-medium text-xl mb-2">{t('pending')}</p>
                    <div className="flex flex-col items-start w-full bg-backgray p-2 mb-12">
                      {guests.map((guest) => {
                        console.log(guest.is_approved, 'xy');
                        return (
                          <div
                            key={guest.uuid}
                            className={`flex items-center gap-5 bg-white w-full p-3 border-b border-grey-600 relative`}
                          >
                            <img
                              src={userNoPhoto}
                              alt="Guest"
                              className="min-w-12 min-h-12 max-w-12 max-h-12 rounded-full"
                            />
                            <div className="flex flex-col">
                              {guest?.guest_email && <p className="text-black-100 text-xs">{guest.guest_email}</p>}
                              {guest?.guest_phone && <p className="text-black-100 text-xs">{guest.guest_phone}</p>}
                            </div>

                            <span
                              className={`text-xs ${
                                guest.is_approved === 1 ? 'text-success' : 'text-primary-100'
                              }  font-semibold ml-auto`}
                            >
                              {guest.is_approved === 1 ? t('accepted') : t('pending')}
                            </span>
                            <div className="-right-20 absolute flex flex-row gap-2 justify-end items-center w-full">
                              {guest.is_approved_admin === 0 && (
                                <button
                                  onClick={() => handleAproveOrRejectGuest(guest.uuid, true)}
                                  className="bg-white p-1 rounded-md hover:opacity-70"
                                >
                                  <FaCheck size={19} className="text-success" />
                                </button>
                              )}

                              <button
                                onClick={() => handleAproveOrRejectGuest(guest.uuid, false)}
                                className="bg-white p-1 rounded-md hover:opacity-70"
                              >
                                <IoClose size={20} className="text-error" />
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}

                {/* CONTACT LIST */}
                <p className="text-start w-full text-black-100 font-medium text-xl mb-2">
                  {t('contact_on_family_wisdom')}
                </p>
                {Object.entries(contactsOrdered).length === 0 ? (
                  <h1 className="mt-10">{t('no_contacts')}</h1>
                ) : (
                  <div className="flex flex-col items-start w-full bg-backgray p-2 gap-2 mb-14">
                    {contactsOrdered &&
                      Object.entries(contactsOrdered).map(([firstLetter, contacts]) => (
                        <div key={firstLetter} className="w-full">
                          <p className="text-black-100 font-medium text-sm ml-3 mb-1">{firstLetter}</p>
                          <div className="flex flex-col items-start">
                            {contacts.map((contact) => {
                              const existUser = !!invitedMembers.find((item) => item.uuid === contact.uuid);
                              return (
                                <div
                                  key={contact.uuid}
                                  className={`flex items-center gap-5 bg-white w-full p-3 border-b border-grey-600`}
                                >
                                  <img
                                    src={contact.image || userNoPhoto}
                                    alt={contact.first_name}
                                    className="min-w-12 min-h-12 max-w-12 max-h-12 rounded-full"
                                  />
                                  <div className="flex flex-col">
                                    <p className="text-black-100 text-lg">
                                      {contact.first_name} {contact.last_name}
                                    </p>
                                    <p className="text-black-100 text-xs">{contact.email}</p>
                                  </div>

                                  <button
                                    onClick={() =>
                                      setInvitedMembers((prev) => {
                                        const member = prev.find((item) => {
                                          return item.uuid === contact.uuid;
                                        });
                                        if (!member) {
                                          return [...prev, contact];
                                        }
                                        return prev;
                                      })
                                    }
                                    disabled={existUser}
                                    className="text-xs text-primary-200 font-semibold ml-auto hover:opacity-70 disabled:text-grey-400"
                                  >
                                    {t('invite')}
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </>
            ) : (
              <h1 className="font-semibold text-lg">{t('without_permission')}</h1>
            )}
          </section>
        </div>
      )}
    </>
  );
}
