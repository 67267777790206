/* eslint-disable react/display-name */
import React, { ButtonHTMLAttributes } from 'react';

interface ButtonActionProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  src?: string;
  imgLarge?: boolean;
}

const ButtonAction = React.forwardRef<HTMLButtonElement, Omit<ButtonActionProps, 'ref'>>(
  ({ text, src, imgLarge, ...props }, ref) => (
    <button
      ref={ref}
      {...props}
      data-test="insight-actions"
      className="flex items-center w-full gap-5 hover:opacity-70 py-5 border-b border-backgray"
    >
      {src && <img className={`${imgLarge ? 'w-12 h-12' : 'w-[30px] h-[30px]'} `} src={src} alt="Action" />}
      <span className="text-lg text-black-100">{text}</span>
    </button>
  ),
);

export default ButtonAction;
