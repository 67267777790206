import { useCallback, useEffect, useState } from 'react';

import { useAds } from '@hooks/useAds';

import AdsPost from '@components/AdsPost';
import Loading from '@components/Loading';

import loadingGif from '@assets/icons/loading.gif';
import EmptyAds from '@components/EmptyStates/EmptyAds';

export default function ListAdsPage() {
  const [loading, setLoading] = useState(false);
  const [loadingFeed, setLoadingFeed] = useState(false);

  const { pagination, listAds, adsList } = useAds();

  useEffect(() => {
    const onScroll = async () => {
      if (
        window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight &&
        !loadingFeed &&
        pagination.current_page < pagination.last_page
      ) {
        setLoadingFeed(true);
        await listAds(false, `?sortBy=updated_at&sortDirection=desc&page=${pagination.current_page + 1}`);
        setLoadingFeed(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [loadingFeed, pagination]);

  const getFeedForUser = useCallback(
    async (first: boolean) => {
      setLoading(true);
      await listAds(first, `?sortBy=updated_at&sortDirection=desc&page=1`);
      setLoading(false);
    },
    [listAds],
  );

  useEffect(() => {
    getFeedForUser(true);
  }, [getFeedForUser]);

  return (
    <div className="min-h-[calc(100vh-88px)] bg-backgray py-8 relative overflow-x-scroll h-full">
      <div className="mx-auto">
        {loading ? (
          <Loading />
        ) : (
          <div className="flex flex-col gap-5 justify-center items-center">
            {adsList.length === 0 ? (
              <EmptyAds />
            ) : (
              <>
                {adsList.map((ads) => {
                  return <AdsPost isAdminMode ads={ads} key={ads.uuid} />;
                })}
              </>
            )}
          </div>
        )}
      </div>
      {loadingFeed && (
        <img src={loadingGif} alt="Loading" className="w-12 absolute bottom-0 left-[50%] -translate-x-[50%]" />
      )}
    </div>
  );
}
