import { useEffect, useState } from 'react';

import { NotificationSwitch } from '@components/Switch';
import { FormGroup } from '@mui/material';
import { useUser } from '@hooks/useUser';
import { toast } from 'utils/toast';
import { requestForToken } from '../../utils/firebase';
import { NotificationSettings, useNotification } from '@hooks/useNotification';
import { useTranslation } from 'react-i18next';

export default function NotificationsSettings() {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<NotificationSettings>({});
  const [blockAll, setBlockAll] = useState(true);
  const [settings, setSettings] = useState<NotificationSettings>({
    reactions: false,
    comments: false,
    new_posts_groups: false,
    your_events: false,
    group_events: false,
    adm_actions: false,
    email: false,
  });
  const [tokenDevice, setTokenDevice] = useState<any>(null);
  notifications;

  const { addTokenToUser, user } = useUser();
  const { getAllNotificationsSettings, updateNotificationsSettings } = useNotification();

  const userAgent = window.navigator.userAgent;

  useEffect(() => {
    async function fetchInitialSettings() {
      try {
        const { settings } = await getAllNotificationsSettings();
        setNotifications(settings);

        // Atualize o objeto 'settings' com as configurações obtidas
        const updatedSettings = {
          reactions: settings.reactions || false,
          comments: settings.comments || false,
          new_posts_groups: settings.new_posts_groups || false,
          your_events: settings.your_events || false,
          group_events: settings.group_events || false,
          adm_actions: settings.adm_actions || false,
          email: settings.email || false,
        };

        setSettings(updatedSettings);
      } catch (error) {
        console.error('Erro ao obter configurações de notificação:', error);
      }
    }

    fetchInitialSettings();
  }, [getAllNotificationsSettings]);

  async function updateNotificationSettings(updatedSettings: NotificationSettings) {
    try {
      await updateNotificationsSettings(updatedSettings);
      toast({
        label: t('success'),
        message: t('notification_settings_updated_successfully'),
        type: 'success',
      });
    } catch (error) {
      console.error('Erro ao atualizar configurações de notificação:', error);
      toast({
        label: t('error'),
        message: t('unable_to_update_notification_settings'),
        type: 'error',
      });
    }
  }

  useEffect(() => {
    requestForToken()
      .then((currentToken) => {
        setTokenDevice(currentToken);
      })
      .catch((err) => {
        console.error('Erro ao obter o token:', err);
      });
  }, []);

  async function handleSendDeviceToken() {
    if (!user?.uuid) {
      toast({
        label: t('error'),
        message: t('unable_to_update_notification_settings'),
        type: 'error',
      });
      return;
    }

    const deviceTokenToSend = blockAll ? tokenDevice : null;

    const userAgentSubstring = userAgent.slice(0, 10);
    const tokenSubstring = tokenDevice.slice(-5);

    const combinedString = `${userAgentSubstring}-${tokenSubstring}`;

    const objtToPost = {
      device: combinedString,
      token: deviceTokenToSend,
    };
    const response = await addTokenToUser(user?.uuid, objtToPost.device, objtToPost.token);
    if (response) {
      console.log('Token adicionado com sucesso');
    }
  }

  function handleBlockAll(event: React.ChangeEvent<HTMLInputElement>) {
    const isChecked = event.target.checked;
    setBlockAll(isChecked);
    const updatedSettings: NotificationSettings = {
      reactions: !isChecked,
      comments: !isChecked,
      new_posts_groups: !isChecked,
      your_events: !isChecked,
      group_events: !isChecked,
      adm_actions: !isChecked,
      email: !isChecked,
    };
    setSettings(updatedSettings);

    // Atualize as configurações no servidor com base no novo estado
    updateNotificationSettings(updatedSettings);
  }

  useEffect(() => {
    if (
      !settings.comments &&
      !settings.new_posts_groups &&
      !settings.reactions &&
      !settings.your_events &&
      !settings.group_events &&
      !settings.adm_actions &&
      !settings.email
    ) {
      setBlockAll(true);
    } else {
      setBlockAll(false);
    }
  }, [settings]);

  return (
    <FormGroup className="flex flex-col items-center justify-center py-12 w-full">
      <div className="w-[60vw]">
        <span className="flex items-center px-5 py- justify-between h-[80px] w-full">
          <p>{t('block_all')}</p>
          <NotificationSwitch
            value={blockAll}
            checked={blockAll}
            onChange={handleBlockAll}
            onClick={handleSendDeviceToken}
          />
        </span>
      </div>

      <div className="flex flex-col items-center mt-12 w-[60vw]">
        <span className="flex items-center px-5 py- justify-between h-[80px] w-full">
          <p>{t('comments')}</p>
          <NotificationSwitch
            value={settings.comments}
            checked={Boolean(settings.comments)}
            onChange={() => {
              const updated = { ...settings, comments: !settings.comments };
              setSettings(updated);
              updateNotificationSettings(updated);
              setTokenDevice(tokenDevice);
            }}
          />
        </span>
        <span className="flex items-center px-5 py- justify-between h-[80px] w-full">
          <p>{t('new_posts_groups')}</p>
          <NotificationSwitch
            value={settings.new_posts_groups}
            checked={Boolean(settings.new_posts_groups)}
            onChange={() => {
              const updated = { ...settings, new_posts_groups: !settings.new_posts_groups };
              setSettings(updated);
              updateNotificationSettings(updated);
              setTokenDevice(tokenDevice);
            }}
          />
        </span>
        <span className="flex items-center px-5 py- justify-between h-[80px] w-full">
          <p>{t('new_posts_in_the_groups_you_participate')}</p>
          <NotificationSwitch
            value={settings.reactions}
            checked={Boolean(settings.reactions)}
            onChange={() => {
              const updated = { ...settings, reactions: !settings.reactions };
              setSettings(updated);
              updateNotificationSettings(updated);
              setTokenDevice(tokenDevice);
            }}
          />
        </span>
        <span className="flex items-center px-5 py- justify-between h-[80px] w-full">
          <p>{t('your_events')}</p>
          <NotificationSwitch
            value={settings.your_events}
            checked={Boolean(settings.your_events)}
            onChange={() => {
              const updated = { ...settings, your_events: !settings.your_events };
              setSettings(updated);
              updateNotificationSettings(updated);
              setTokenDevice(tokenDevice);
            }}
          />
        </span>
        <span className="flex items-center px-5 py- justify-between h-[80px] w-full">
          <p>{t('group_events')}</p>
          <NotificationSwitch
            value={settings.group_events}
            checked={Boolean(settings.group_events)}
            onChange={() => {
              const updated = { ...settings, group_events: !settings.group_events };
              setSettings(updated);
              updateNotificationSettings(updated);
              setTokenDevice(tokenDevice);
            }}
          />
        </span>
        <span className="flex items-center px-5 py- justify-between h-[80px] w-full">
          <p>{t('requests_for_adm_actions')}</p>
          <NotificationSwitch
            value={settings.adm_actions}
            checked={Boolean(settings.adm_actions)}
            onChange={() => {
              const updated = { ...settings, adm_actions: !settings.adm_actions };
              setSettings(updated);
              updateNotificationSettings(updated);
              setTokenDevice(tokenDevice);
            }}
          />
        </span>
        <span className="flex items-center px-5 py- justify-between h-[80px] w-full">
          <p>{t('email_notifications')}</p>
          <NotificationSwitch
            value={settings.email}
            checked={Boolean(settings.email)}
            onChange={() => {
              const updated = { ...settings, email: !settings.email };
              setSettings(updated);
              updateNotificationSettings(updated);
              setTokenDevice(tokenDevice);
            }}
          />
        </span>
      </div>
    </FormGroup>
  );
}
