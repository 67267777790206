import { create } from 'zustand';
import { api } from '../services/axios';
import { isUUID, showErrorMessage } from '../utils/auxFunctions';
import { deleteFromS3 } from '../utils/awsS3';
import { toast } from '../utils/toast';
export interface User {
  id?: number;
  uuid: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  birth_date?: string;
  image?: string;
  country_id?: string;
  phone?: string;
  language: string;
  refreshToken?: string;
  notifications?: number;
  admin_groups: [{ uuid: string }];
  provider?: string;
  timezone: string;
  customer_id?: string;
  is_super_admin?: number;
  account_deletion_confirmed_at?: string;
  account_deletion_grace_at?: string;
  terms_of_use?: boolean;
}

export interface Token {
  token?: string | null;
  device?: string;
}

export interface GoogleUser {
  email: string;
  family_name: string;
  given_name: string;
  hd: string;
  id: string;
  locale: string;
  name: string;
  picture: string;
  verified_email: boolean;
}

export interface FacebookUser {
  email: string;
  id: string;
  first_name: string;
  last_name: string;
  picture: {
    data: {
      url: string;
    };
  };
  birthday: string;
  location: string;
}

export interface AppleUser {
  authorization: {
    code: string;
    id_token: string;
    state: string;
  };
  user?: {
    name: {
      firstName: string;
      lastName: string;
    };
    email: string;
  };
}

export interface IToken {
  iss: string;
  iat: number;
  exp: number;
  nbf: number;
  jti: string;
  sub: string;
  prv: string;
}

interface UserProps {
  user: User | null;
  token: string | null;
  refresh_token: string | null;

  setUser: (user: User) => void;
  getUser: () => User | null;
  getUserDetails: (uuid: string) => Promise<{ userDetail: User }>;

  setToken: (token: string) => void;
  setRefreshToken: (refreshToken: string) => void;
  verifyUser: () => 'login' | 'feed' | 'profile' | 'inviteLink';

  logout: () => Promise<void>;
  createUser: (
    email: string,
    password: string,
    password_confirmation: string,
  ) => Promise<{ status: boolean; message: string }>;
  updateUser: (obj: {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    password?: string;
    password_confirmation?: string;
    birth_date?: string;
    language?: string;
    country_id?: string;
    image?: string;
  }) => Promise<User>;
  getAllUsersFromAllGroups: (groupUuid?: string) => Promise<User[]>;
  addTokenToUser: (userUuid: string, device: string, token: string | null) => Promise<Token>;
  acceptTerms: () => Promise<any>;
  deleteTermsOfUse: () => Promise<any>;
}

export const useUser = create<UserProps>((set, get) => ({
  user: localStorage.getItem('familyWisdomUser') ? JSON.parse(localStorage.getItem('familyWisdomUser')!) : null,
  token: localStorage.getItem('familyWisdomToken'),
  refresh_token: localStorage.getItem('familyWisdomRefreshToken'),

  setUser: (user: User) => {
    localStorage.setItem('familyWisdomUser', JSON.stringify(user));
    set({ user });
  },

  setRefreshToken: (refreshToken: string) => {
    localStorage.setItem('familyWisdomRefreshToken', refreshToken);
    set({ refresh_token: refreshToken });
  },

  setToken: (token: string) => {
    localStorage.setItem('familyWisdomToken', token);
    set({ token });
    api.defaults.headers['Authorization'] = 'Bearer ' + token;
  },

  getUser: () => {
    const user = get().user;
    return user;
  },

  getUserDetails: async (uuid: string) => {
    try {
      const { data } = await api.get(`/users/${uuid}`);
      const userDetail: User = data.content.data;
      const currentUser = get().user;
      if (
        currentUser?.first_name !== userDetail.first_name ||
        currentUser?.last_name !== userDetail.last_name ||
        currentUser?.image !== userDetail.image ||
        currentUser?.language !== userDetail.language ||
        currentUser?.phone !== userDetail.phone ||
        currentUser?.birth_date !== userDetail.birth_date ||
        currentUser?.country_id !== userDetail.country_id ||
        currentUser?.provider !== userDetail.provider ||
        currentUser?.timezone !== userDetail.timezone ||
        currentUser?.account_deletion_confirmed_at !== userDetail.account_deletion_confirmed_at ||
        currentUser?.account_deletion_grace_at !== userDetail.account_deletion_grace_at ||
        currentUser?.terms_of_use !== userDetail.terms_of_use
      ) {
        localStorage.setItem('familyWisdomUser', JSON.stringify(userDetail));
        set({ user: userDetail });
      }
      return {
        userDetail,
      };
    } catch (error: any) {
      throw new Error('Erro ao obter os detalhes do usuário por ID');
    }
  },

  verifyUser: () => {
    const token = localStorage.getItem('familyWisdomToken');
    const user: User = localStorage.getItem('familyWisdomUser')
      ? JSON.parse(localStorage.getItem('familyWisdomUser')!)
      : null;
    if (token && user && token !== 'undefined') {
      if (!user.first_name || !user.last_name || !user.birth_date) {
        return 'profile';
      }
      if (localStorage.getItem('inviteLink')) {
        return 'inviteLink';
      }
      return 'feed';
    }
    return 'login';
  },

  logout: async () => {
    try {
      await Promise.all([
        api.post(`/users/${get().user?.uuid}/tokens`, {
          device: 'ios',
          token: null,
        }),
        await api.post('/auth/logout', {}),
      ]);
      localStorage.removeItem('familyWisdomUser');
      localStorage.removeItem('familyWisdomToken');
      localStorage.removeItem('familyWisdomRefreshToken');
    } catch (error: any) {
      localStorage.removeItem('familyWisdomRefreshToken');
      localStorage.removeItem('familyWisdomUser');
      localStorage.removeItem('familyWisdomToken');
    }
  },

  createUser: async (email_or_phone: string, password: string, password_confirmation: string) => {
    try {
      const { data } = await api.post(`/auth/register`, {
        email_or_phone,
        password,
        password_confirmation,
      });
      return data;
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast({
          label: 'Error',
          message: error.response.data.message,
          type: 'error',
        });
      } else if (error?.response?.data?.errors) {
        const message = Object.values(error.response.data.errors);
        toast({
          label: 'Error',
          message: message.join('\n'),
          type: 'error',
        });
      } else {
        toast({
          label: 'Error',
          message: 'Email already been taken',
          type: 'error',
        });
      }
    }
  },

  updateUser: async (obj: {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    password?: string;
    password_confirmation?: string;
    birth_date?: string;
    language?: string;
    country_id?: string;
    image?: string;
  }) => {
    try {
      const user = get().user;
      const newUser = {
        ...user,
        ...obj,
      } as User;

      if (!user) return;
      if (user.image && obj.image !== undefined) {
        const imageKey = user.image.split('/').pop();
        if (imageKey && isUUID(imageKey)) deleteFromS3(imageKey);
      }
      const { data } = await api.put(`/users/${user.uuid}`, obj);

      get().setUser({ ...newUser });

      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  addTokenToUser: async (userUuid: string, device: string, token: string | null) => {
    try {
      const { data } = await api.post(`/users/${userUuid}/tokens`, { device, token });
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  getAllUsers: async () => {
    try {
      const { data } = await api.get('/users');
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  getAllUsersFromAllGroups: async (params?: string) => {
    try {
      const { data } = await api.get('/users/member-groups' + (params ?? ''));
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  acceptTerms: async () => {
    try {
      const { data } = await api.post(`/users/${get().user?.uuid}/confirm-terms-of-use`);
      const currentUser = get().user;
      if (currentUser) {
        currentUser.terms_of_use = true;
        get().setUser({ ...currentUser });
      }
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  deleteTermsOfUse: async () => {
    try {
      const { data } = await api.post(`/users/delete-terms-of-use`);
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },
}));
