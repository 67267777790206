import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCategory } from '@hooks/useCategory';

import { Button } from '@components/Button';

import { useTranslation } from 'react-i18next';
import { categoriesDefault, colors } from 'utils/icons';
import { toast } from 'utils/toast';

export default function CreateCategory() {
  const [color, setColor] = useState<string>('#EDB561');
  const [icon, setIcon] = useState<string>('');
  const [tag, setTag] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const nameInput = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const { groupId } = useParams();

  const { createCategory } = useCategory();
  const navigate = useNavigate();

  async function handleCreateCategory() {
    if (!groupId) {
      toast({
        label: t('error'),
        message: t('group_not_found'),
        type: 'error',
      });
      return;
    }
    if (nameInput.current?.value === '' || tag === '') {
      toast({
        label: t('error'),
        message: t('fill_all_fields'),
        type: 'error',
      });
      return;
    }
    setIsSubmitting(true);
    const response = await createCategory(groupId, nameInput.current!.value, tag, color);
    setIsSubmitting(false);
    if (response.uuid) {
      toast({
        label: t('success'),
        message: `${t('category')} ${response.name}, ${t('created_successfully')}`,
        type: 'success',
      });
      navigate(`/groups/${groupId}`);
    }
  }

  return (
    <div className="flex flex-col items-center w-full min-h-[calc(100vh-88px)] bg-backgray py-12">
      <div className="flex flex-col gap-3 bg-white px-10 py-5">
        <h2 className="text-xl font-medium text-black-100 mb-8">
          {t('finally_create_a_category_to_organize_your_posts')}
        </h2>

        <div className="w-full flex justify-center">
          <span
            className={`flex items-center justify-center w-[60px] h-[60px] rounded-full`}
            style={{ backgroundColor: color }}
          >
            {icon && <img src={icon} className="w-[30px] h-[30px] svg-white" alt="icon" />}
          </span>
        </div>

        <div className="flex flex-col w-full gap-4">
          <span className="text-lg text-secondary-400">{t('name')}</span>
          <input
            className="bg-transparent border-b border-grey-600 focus:outline-none text-lg text-black-100"
            type="text"
            maxLength={255}
            placeholder="Choose a name"
            ref={nameInput}
          />
        </div>
        <div className="flex items-center mt-8">
          <span className="text-lg text-black-100 mr-8">{t('choose_a_color')}</span>
          {colors.map((color) => {
            return (
              <button
                key={color.hex}
                style={{ backgroundColor: color.hex }}
                className={`w-[40px] h-[40px] rounded-full mr-3`}
                onClick={() => setColor(color.hex)}
              />
            );
          })}
        </div>

        <div className="flex mt-8">
          <span className="text-lg text-black-100 mr-8">{t('choose_a_icon')}</span>
          <div className="flex flex-wrap items-center justify-center gap-x-3 gap-y-5 max-w-[544px] mt-5">
            {categoriesDefault.map((icon) => {
              return (
                <button
                  key={icon.tag}
                  className="flex items-center justify-center w-[40px] h-[40px] bg-grey-600 rounded-full"
                  onClick={() => {
                    setIcon(icon.icon);
                    setTag(icon.tag);
                  }}
                >
                  <img className="w-[25px] h-[25px]" src={icon.icon} alt="icon" />
                </button>
              );
            })}
          </div>
        </div>
      </div>
      <span className="mt-12 flex items-center gap-12">
        <Button
          disabled={isSubmitting}
          onClick={handleCreateCategory}
          variant="primary"
          size="medium"
          text={t('done')}
        />

        <Button
          disabled={isSubmitting}
          onClick={() => navigate(`/groups/${groupId}`)}
          variant="primary"
          size="medium"
          text={t('skip')}
        />
      </span>
    </div>
  );
}
