import dayjs from 'dayjs';

interface DayCardProps {
  calendarIndex: number;
  day: moment.Moment;
  initialDate: string;
  setInitialDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  date1: Date | null;
  date2: Date | null;
}

export default function DayCard({
  calendarIndex,
  day,
  initialDate,
  setInitialDate,
  endDate,
  setEndDate,
  date1,
  date2,
}: DayCardProps) {
  function handleSelectDate() {
    if (initialDate && !endDate) {
      if (date1 && day.toDate() < date1) {
        setInitialDate(day.format('DD-MM-YYYY'));
        setEndDate(dayjs(date1).format('DD-MM-YYYY'));
        return;
      }
      setEndDate(day.format('DD-MM-YYYY'));
    } else if (initialDate && endDate) {
      setInitialDate(day.format('DD-MM-YYYY'));
      setEndDate('');
    } else {
      setInitialDate(day.format('DD-MM-YYYY'));
    }
  }

  return (
    <>
      {calendarIndex === 0 && Number(day.format('DD').toString()) > 7 ? (
        <div className="flex flex-col items-center justify-center text-[#D5D5D6] text-sm opacity-80 w-[25px] h-[25px]">
          <p>{day.format('DD').toString()}</p>
        </div>
      ) : (
        <>
          {calendarIndex > 3 && Number(day.format('DD').toString()) < 20 ? (
            <div className="flex flex-col items-center justify-center text-[#D5D5D6] text-sm opacity-80 w-[25px] h-[25px]">
              <p>{day.format('DD').toString()}</p>
            </div>
          ) : (
            <div className="relative w-[25px] h-[25px]">
              {dayjs(date1).format('DD-MM-YYYY') === dayjs(date2).format('DD-MM-YYYY') ? (
                <></>
              ) : (
                <>
                  {date1 && date2 && day.toDate() > date1 && day.toDate() < date2 && (
                    <span className="absolute bg-grey-600 top-[-5px] left-[-5px] w-[55px] h-[35px]" />
                  )}
                  {date1 &&
                    date2 &&
                    day.toDate().getDate() == date1.getDate() &&
                    day.toDate().getMonth() == date1.getMonth() &&
                    day.toDate().getFullYear() == date1.getFullYear() && (
                      <span className="absolute bg-grey-600 top-[-5px] left-[-5px] w-[55px] h-[35px] border-l rounded-l-full" />
                    )}
                  {date1 &&
                    date2 &&
                    day.toDate().getDate() == date2.getDate() &&
                    day.toDate().getMonth() == date2.getMonth() &&
                    day.toDate().getFullYear() == date2.getFullYear() && (
                      <span className="absolute bg-grey-600 top-[-5px] left-[-5px] w-[35px] h-[35px] border-r rounded-r-full" />
                    )}
                </>
              )}
              <button
                onClick={handleSelectDate}
                className={`relative flex flex-col items-center justify-center  text-grey-300 text-sm w-full h-full ${
                  initialDate === day.format('DD-MM-YYYY') ? 'rounded-full text-white bg-primary-200' : ''
                }
              ${endDate === day.format('DD-MM-YYYY') ? 'rounded-full text-white bg-primary-200' : ''}
              `}
              >
                <p className="z-20">{day.format('DD').toString()}</p>
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
}
