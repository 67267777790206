import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useInsights } from '@hooks/useInsights';
import { useUser } from '@hooks/useUser';

import EmptyFeed from '@components/EmptyStates/EmptyFeed';
import ImageViewer from '@components/ImageViewer';
import PostFeed from '@components/PostFeed';

import dots from '@assets/icons/Profile/dots.svg';
import grid from '@assets/icons/Profile/grid.svg';
import loadingGif from '@assets/icons/loading.gif';
import defaultUserPhoto from '@assets/profiles/Ellipse 9.svg';

export default function UserProfile() {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<'grid' | 'save'>('grid');
  const [loadingFeed, setLoadingFeed] = useState(false);

  const { user } = useUser();
  const { getUserPosts, getFavoriteInsights, insights, pagination } = useInsights();

  const getFeedForUser = useCallback(async () => {
    setLoading(true);
    if (selected === 'grid') {
      await getUserPosts(true, `?sortBy=updated_at&sortDirection=desc&page=1`);
    } else {
      await getFavoriteInsights(true, `?sortBy=updated_at&sortDirection=desc&page=1`);
    }
    setLoading(false);
  }, [getFavoriteInsights, getUserPosts, selected]);

  useEffect(() => {
    getFeedForUser();
  }, [getFeedForUser, selected]);

  useEffect(() => {
    const onScroll = async () => {
      if (
        Math.round(window.innerHeight + document.documentElement.scrollTop + 35) >=
          document.documentElement.offsetHeight &&
        !loadingFeed &&
        pagination.current_page < pagination.last_page
      ) {
        setLoadingFeed(true);
        if (selected === 'grid') {
          await getUserPosts(false, `?sortBy=updated_at&sortDirection=desc&page=${pagination.current_page + 1}`);
        } else {
          await getFavoriteInsights(false, `?sortBy=updated_at&sortDirection=desc&page=${pagination.current_page + 1}`);
        }
        setLoadingFeed(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [getFavoriteInsights, getUserPosts, loadingFeed, pagination, selected]);

  return (
    <>
      <header className="relative flex flex-col items-center w-full">
        <div className="flex flex-col items-center gap-5">
          <ImageViewer className="h-36 w-36 rounded-full" url={user?.image || ''} previewImage={defaultUserPhoto} />
          <h3 className="text-grey-150 text-[25px] font-bold">{user && user.first_name + ' ' + user.last_name}</h3>
        </div>

        <Link to={'/profile/edit'} className="absolute top-6 right-10 hover:opacity-70">
          <img src={dots} alt={dots} />
        </Link>

        <div className="grid grid-cols-2 w-full mt-14">
          <button
            onClick={() => setSelected('grid')}
            className={`flex items-center justify-center py-3 ${
              selected === 'grid' ? 'border-b-2 border-grey-150' : ''
            }`}
          >
            <img src={grid} alt="" />
          </button>
          <button
            onClick={() => setSelected('save')}
            className={`flex items-center justify-center py-3 ${
              selected === 'save' ? 'border-b-2 border-grey-150' : ''
            }`}
          >
            <svg width="26" height="31" viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Save">
                <g id="Vector" filter="url(#filter0_d_566_26839)">
                  <path
                    d="M20.0833 21.75L12.7917 16.5417L5.5 21.75V5.08333C5.5 4.5308 5.71949 4.00089 6.11019 3.61019C6.50089 3.21949 7.0308 3 7.58333 3H18C18.5525 3 19.0824 3.21949 19.4731 3.61019C19.8638 4.00089 20.0833 4.5308 20.0833 5.08333V21.75Z"
                    stroke="#333333"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    shapeRendering="crispEdges"
                  />
                </g>
              </g>
              <defs>
                <filter
                  id="filter0_d_566_26839"
                  x="0.5"
                  y="2"
                  width="24.583"
                  height="28.75"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_566_26839" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_566_26839" result="shape" />
                </filter>
              </defs>
            </svg>
          </button>
        </div>
      </header>

      {loading ? (
        <div className="flex justify-center items-center w-full h-full">
          <img src={loadingGif} alt="Loading" className="w-12" />
        </div>
      ) : (
        <main className="flex flex-col items-center w-full bg-backgray py-14 min-h-[70vh] gap-9 relative">
          {insights?.length === 0 && (
            <div className="py-10">
              <EmptyFeed />
            </div>
          )}
          {insights?.length > 0 && insights.map((insight) => <PostFeed key={insight.uuid} insight={insight} />)}
          {loadingFeed && (
            <img src={loadingGif} alt="Loading" className="w-12 absolute bottom-0 left-[50%] -translate-x-[50%]" />
          )}
        </main>
      )}
    </>
  );
}
