import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useGroups } from '@hooks/useGroups';
import { useUser } from '@hooks/useUser';

import { Button } from '@components/Button';
import { ButtonAdd } from '@components/ButtonAdd';
import EditPhotoModal from '@components/EditPhotoModal';

import inputImage from '@assets/input-image.png';

import { useTranslation } from 'react-i18next';
import { getDefaultImages, isBase64Img, retrieveImgS3 } from 'utils/auxFunctions';
import { uploadToS3 } from 'utils/awsS3';
import { toast } from 'utils/toast';

export default function EditGroup() {
  const { t } = useTranslation();
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getGroupByUuid, updateGroup, group, exitGroup } = useGroups();
  const { user } = useUser();

  const navigate = useNavigate();

  const { groupId } = useParams();
  const emailInput = useRef<HTMLInputElement>(null);
  const groupNameInput = useRef<HTMLInputElement>(null);
  const closeButton = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (groupId) {
      getGroupByUuid(groupId);
    }
  }, [getGroupByUuid, groupId]);

  useEffect(() => {
    if (user && emailInput) {
      emailInput.current!.value = user.email || '';
    }
  }, [user]);

  useEffect(() => {
    if (group && groupNameInput) {
      groupNameInput.current!.value = group.name || '';
    }
    if (group.image) {
      setPreviewImage(String(group.thumbnail1600 || group.image));
    }
  }, [group]);

  useEffect(() => {
    if (!previewImage) {
      setUploadedFile(null);
    }
  }, [previewImage]);

  const handleImageChange = (event: any) => {
    event.preventDefault();
    let file: File = {} as File;
    if (event.target.files) {
      file = event.target.files?.[0];
    } else if (event.dataTransfer.files) {
      file = event.dataTransfer.files?.[0];
    }
    if (!file) return;
    if (!/image.*/.exec(file.type) || file.type.includes('svg+xml')) {
      toast({
        label: t('error'),
        message: t('the_file_is_not_an_image'),
        type: 'error',
      });
      return;
    }
    const fileSize = file.size;
    if (fileSize <= 2000000) {
      setUploadedFile(file);
      // Cria uma URL temporária para visualização
      const reader = new FileReader();
      reader.onload = () => {
        if ((reader.result as string).length > 2796936) {
          toast({
            label: t('error'),
            message: t('the_image_needs_to_be_less_than_2MB'),
            type: 'error',
          });
          return;
        }
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      toast({
        label: t('error'),
        message: t('the_image_needs_to_be_less_than_2MB'),
        type: 'error',
      });
    }
  };

  async function handleUpdateGroup() {
    if (!groupNameInput.current?.value || !groupId) return;
    let imgURL = '';
    setIsSubmitting(true);
    if (previewImage) {
      if (isBase64Img(previewImage)) {
        if (uploadedFile) {
          const { key, error } = await uploadToS3(uploadedFile, user?.uuid);
          if (error) {
            toast({
              label: t('error'),
              message: t('failed_to_upload_image'),
              type: 'error',
            });
          } else if (key) imgURL = retrieveImgS3(key);
        }
      } else {
        imgURL = previewImage;
      }
    } else {
      imgURL = getDefaultImages('group-default-1.png');
    }
    const response = await updateGroup({ uuid: groupId, name: groupNameInput.current?.value, image: imgURL });
    if (response.uuid) {
      toast({
        label: t('success'),
        message: t('group_updated_successfully'),
        type: 'success',
      });
      navigate(`/groups/${response.uuid}`);
    }
    setIsSubmitting(false);
  }

  async function handleExitGroup() {
    const response = await exitGroup(group.uuid);
    if (response?.content?.data) {
      toast({
        label: t('success'),
        message: response?.content?.message || t('you_have_successfully_left_the_group'),
        type: 'success',
      });
      closeButton.current?.click();
      navigate('/groups');
    } else {
      toast({
        label: t('error'),
        message: response?.content?.message || t('failed_to_leave_the_group'),
        type: 'error',
      });
      closeButton.current?.click();
    }
  }

  return (
    <div className="flex flex-col w-full items-center bg-backgray min-h-[calc(100vh-88px)]">
      <Dialog.Root>
        <Dialog.Trigger asChild>
          <img
            className="object-cover w-full h-[271px] cursor-pointer"
            src={previewImage ?? inputImage}
            onDrop={handleImageChange}
            alt="group"
            onDragOver={(e) => e.preventDefault()}
            aria-hidden
          />
        </Dialog.Trigger>
        <EditPhotoModal
          setUploadedFile={setUploadedFile}
          handleImageChange={handleImageChange}
          setPreviewImage={setPreviewImage}
        />
      </Dialog.Root>
      <div className="w-full flex justify-center">
        {group?.role === 'Admin' && (
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <button className="text-black-100 text-xl text-center mt-3 cursor-pointer hover:underline">
                {t('edit_cover')}
              </button>
            </Dialog.Trigger>
            <EditPhotoModal
              setUploadedFile={setUploadedFile}
              handleImageChange={handleImageChange}
              setPreviewImage={setPreviewImage}
            />
          </Dialog.Root>
        )}
      </div>

      <section className="desktop:w-[45vw] tablet:w-[60vw] flex flex-col mt-12">
        <div className="flex flex-col w-full gap-4 ml-10">
          <span className="text-xs text-grey-150">{t('name')}</span>
          <input
            className="bg-transparent focus:outline-none text-lg text-black-100 border-b border-grey-300 border-opacity-20 mr-10"
            type="text"
            ref={groupNameInput}
            readOnly={group?.role !== 'Admin'}
            maxLength={35}
          />
        </div>

        <div className="flex flex-col w-full gap-4 ml-10 mt-3">
          <span className="text-xs text-grey-150">{t('email')}</span>
          <input
            className="bg-transparent focus:outline-none text-lg text-black-100 border-b border-grey-300 border-opacity-20 mr-10"
            type="text"
            placeholder="Email"
            ref={emailInput}
            readOnly
          />
        </div>

        <div className="flex flex-col w-full bg-white rounded-md p-1 my-12">
          <Link
            to={'participants'}
            className="flex items-center px-5 py-6 gap-3 hover:opacity-70 border-b border-grey-600"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
              <path
                d="M23 19.7961C24.8199 20.7103 26.3802 22.1774 27.5191 24.012C27.7446 24.3753 27.8574 24.557 27.8964 24.8085C27.9756 25.3197 27.626 25.9482 27.15 26.1505C26.9157 26.25 26.6521 26.25 26.125 26.25M20.5 14.4153C22.3522 13.4949 23.625 11.5836 23.625 9.375C23.625 7.16642 22.3522 5.25514 20.5 4.3347M18 9.375C18 12.4816 15.4816 15 12.375 15C9.26844 15 6.75004 12.4816 6.75004 9.375C6.75004 6.2684 9.26844 3.75 12.375 3.75C15.4816 3.75 18 6.2684 18 9.375ZM3.69908 23.6729C5.69196 20.6807 8.83675 18.75 12.375 18.75C15.9133 18.75 19.0581 20.6807 21.051 23.6729C21.4876 24.3284 21.7059 24.6562 21.6807 25.0749C21.6612 25.4009 21.4475 25.8 21.187 25.997C20.8524 26.25 20.3923 26.25 19.4721 26.25H5.27799C4.35775 26.25 3.89764 26.25 3.56309 25.997C3.3026 25.8 3.0889 25.4009 3.06933 25.0749C3.0442 24.6562 3.26249 24.3284 3.69908 23.6729Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-lg text-black-100">{t('participants')}</p>
          </Link>
          <Link to={'themes'} className="flex items-center px-5 py-6 gap-3 hover:opacity-70 border-b border-grey-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
              <path
                d="M26.75 15L11.75 15M26.75 7.5L11.75 7.5M26.75 22.5L11.75 22.5M6.75 15C6.75 15.6904 6.19036 16.25 5.5 16.25C4.80964 16.25 4.25 15.6904 4.25 15C4.25 14.3096 4.80964 13.75 5.5 13.75C6.19036 13.75 6.75 14.3096 6.75 15ZM6.75 7.5C6.75 8.19036 6.19036 8.75 5.5 8.75C4.80964 8.75 4.25 8.19036 4.25 7.5C4.25 6.80964 4.80964 6.25 5.5 6.25C6.19036 6.25 6.75 6.80964 6.75 7.5ZM6.75 22.5C6.75 23.1904 6.19036 23.75 5.5 23.75C4.80964 23.75 4.25 23.1904 4.25 22.5C4.25 21.8096 4.80964 21.25 5.5 21.25C6.19036 21.25 6.75 21.8096 6.75 22.5Z"
                stroke="black"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-lg text-black-100">{t('categories')}</p>
          </Link>
          <Link to={'event-labels'} className="flex items-center px-5 py-6 gap-3 hover:opacity-70">
            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
              <path
                d="M11.75 18.75H19.25M26.75 10H4.25M20.5 2.5V6.25M10.5 2.5V6.25M10.25 27.5H20.75C22.8502 27.5 23.9003 27.5 24.7025 27.0913C25.4081 26.7317 25.9817 26.1581 26.3413 25.4525C26.75 24.6503 26.75 23.6002 26.75 21.5V11C26.75 8.8998 26.75 7.8497 26.3413 7.04754C25.9817 6.34193 25.4081 5.76825 24.7025 5.40873C23.9003 5 22.8502 5 20.75 5H10.25C8.1498 5 7.0997 5 6.29754 5.40873C5.59193 5.76825 5.01825 6.34193 4.65873 7.04754C4.25 7.8497 4.25 8.8998 4.25 11V21.5C4.25 23.6002 4.25 24.6503 4.65873 25.4525C5.01825 26.1581 5.59193 26.7317 6.29754 27.0913C7.0997 27.5 8.1498 27.5 10.25 27.5Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-lg text-black-100">{t('event_labels')}</p>
          </Link>
        </div>

        <div
          className={`flex flex-col w-full bg-white rounded-md ${
            group?.role !== 'Admin' ? 'mb-12' : group?.status !== 'fake' ? 'p-1' : ''
          }`}
        >
          {group?.status !== 'fake' && (
            <span className="">
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <ButtonAdd bg="bg-white" text={t('exit_group')} />
                </Dialog.Trigger>
                <Dialog.Portal>
                  <Dialog.Overlay
                    style={{
                      position: 'fixed',
                      width: '100vw',
                      height: '100vh',
                      inset: 0,
                      background: 'rgba(0, 0, 0, 0.75)',
                      zIndex: '40',
                    }}
                  />
                  <Dialog.Content
                    forceMount
                    className={
                      'z-50 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white px-12 py-8 rounded-lg font-poppins'
                    }
                  >
                    <div className="flex flex-col gap-5">
                      <Dialog.Close ref={closeButton} className={'hidden'}></Dialog.Close>
                      <h1 className="text-lg text-black-100 font-semibold">{t('exit_group')}</h1>
                      <span className="text-sm text-grey-200 mb-2">
                        {t('are_you_sure_you_want_to_leave_this_group')}
                      </span>
                      <div className="flex items-center justify-end gap-4">
                        <button
                          onClick={() => closeButton.current?.click()}
                          className="flex items-center justify-center py-2 px-3 rounded-md bg-primary-200 bg-opacity-20 hover:opacity-70"
                        >
                          {t('cancel')}
                        </button>
                        <button
                          onClick={handleExitGroup}
                          className="flex items-center justify-center py-2 px-3 rounded-md bg-green-300 hover:opacity-70"
                        >
                          {t('confirm')}
                        </button>
                      </div>
                    </div>
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>
            </span>
          )}
        </div>

        {group?.role === 'Admin' && (
          <span className="flex w-full items-center justify-center my-12">
            <Button
              disabled={isSubmitting}
              onClick={handleUpdateGroup}
              size="medium"
              text={t('done')}
              variant="primary"
            />
          </span>
        )}
      </section>
    </div>
  );
}
