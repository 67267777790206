import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGroups } from '@hooks/useGroups';

import { Button } from '@components/Button';
import EditPhotoModal from '@components/EditPhotoModal';

import inputImage from '@assets/input-image.png';
import MaxGroupsLimit from '@components/MaxGroupsLimit';
import { useTranslation } from 'react-i18next';
import { getDefaultImages, isBase64Img, retrieveImgS3 } from 'utils/auxFunctions';
import { uploadToS3 } from 'utils/awsS3';
import { toast } from 'utils/toast';

export default function CreateGroup() {
  const { t } = useTranslation();
  const [previewImage, setPreviewImage] = useState<string | null>(`${getDefaultImages('group-default-1.png')}`);
  const [image, setImage] = useState<File | null>(null);
  const [groupName, setGroupName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMaximumGroupLimit, setShowMaximumGroupLimit] = useState(false);

  const { createGroup, verifyGroupLimit } = useGroups();

  const navigate = useNavigate();

  useEffect(() => {
    if (!previewImage) {
      setImage(null);
    }
  }, [previewImage]);

  const handleImageChange = (event: any) => {
    event.preventDefault();
    let file: File = {} as File;
    if (event.target.files) {
      file = event.target.files?.[0];
    } else if (event.dataTransfer.files) {
      file = event.dataTransfer.files?.[0];
    }
    if (!file) return;
    if (!/image.*/.exec(file.type) || file.type.includes('svg+xml')) {
      toast({
        label: t('error'),
        message: t('the_file_is_not_an_image'),
        type: 'error',
      });
      return;
    }

    const fileSize = file.size;
    if (fileSize <= 2000000) {
      setImage(file);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      toast({
        label: t('error'),
        message: t('the_image_needs_to_be_less_than_2MB'),
        type: 'error',
      });
    }
  };

  async function handleCreateGroup() {
    setIsSubmitting(true);
    try {
      if (!(await verifyGroupLimit())) {
        setShowMaximumGroupLimit(true);
        return;
      }
      if (!groupName) {
        toast({
          label: t('error'),
          message: t('fill_all_fields'),
          type: 'error',
        });
        return;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }

    let imgURL = '';
    if (previewImage) {
      if (isBase64Img(previewImage)) {
        if (image) {
          const { key, error } = await uploadToS3(image);
          if (error) {
            toast({
              label: t('error'),
              message: t('failed_to_upload_image'),
              type: 'error',
            });
          } else if (key) imgURL = retrieveImgS3(key);
        }
      } else {
        imgURL = previewImage;
      }
    } else {
      imgURL = getDefaultImages('group-default-1.png');
    }

    createGroup(groupName, imgURL)
      .then((createdGroup) => {
        if (createdGroup?.uuid) {
          toast({
            label: t('success'),
            message: t('group_created_successfully'),
            type: 'success',
          });
          navigate(`/groups/${createdGroup.uuid}/invite?next=categories`);
          setGroupName('');
          setPreviewImage(null);
        } else {
          toast({
            label: t('error'),
            message: t('failed_to_create_group'),
            type: 'error',
          });
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  return (
    <div className="flex flex-col w-full items-center">
      {/* ERROR MESSAGE */}
      <Dialog.Root open={showMaximumGroupLimit}>
        <MaxGroupsLimit setShowMaximumGroupLimit={setShowMaximumGroupLimit} />
      </Dialog.Root>

      <Dialog.Root>
        <Dialog.Trigger asChild>
          <img
            className="object-cover w-full h-[271px] cursor-pointer"
            src={previewImage ?? inputImage}
            onDrop={handleImageChange}
            onDragOver={(e) => e.preventDefault()}
            alt="group-cover"
            aria-hidden
          />
        </Dialog.Trigger>
        <EditPhotoModal
          fullSize
          handleImageChange={handleImageChange}
          setPreviewImage={setPreviewImage}
          setUploadedFile={setImage}
        />
      </Dialog.Root>
      <div className="w-full flex justify-center">
        <Dialog.Root>
          <Dialog.Trigger asChild>
            <button className="text-black-100 text-xl text-center mt-3 cursor-pointer hover:underline">
              {t('choose_a_cover')}
            </button>
          </Dialog.Trigger>
          <EditPhotoModal
            fullSize
            handleImageChange={handleImageChange}
            setPreviewImage={setPreviewImage}
            setUploadedFile={setImage}
          />
        </Dialog.Root>
      </div>

      <section className="w-[40vw] flex flex-col mt-12">
        <div className="flex flex-col w-full gap-4 ml-10">
          <span className="text-xs text-grey-150">{t('name')}</span>
          <input
            className="bg-transparent border-b border-grey-600 focus:outline-none text-lg text-black-100"
            type="text"
            data-test="create-group-name"
            placeholder={t('group_name')}
            onChange={(e) => {
              setGroupName(e.target.value);
            }}
            maxLength={35}
            value={groupName}
          />
        </div>

        <span className="flex w-full items-center justify-center my-12">
          <Button
            disabled={isSubmitting}
            onClick={handleCreateGroup}
            data-test="create-group-submit"
            size="medium"
            text={t('next')}
            variant="primary"
          />
        </span>
      </section>
    </div>
  );
}
