import * as Dialog from '@radix-ui/react-dialog';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import type { Category } from '@hooks/useCategory';
import { useCategory } from '@hooks/useCategory';
import { useInsights } from '@hooks/useInsights';

import ButtonAction from '@components/ButtonAction';
import CategoryCard from '@components/CategoryCard';
import EmptyFeed from '@components/EmptyStates/EmptyFeed';
import ImageViewer from '@components/ImageViewer';
import Loading from '@components/Loading';
import PostFeed from '@components/PostFeed';

import moveDown from '@assets/icons/Button/move-down.svg';
import moveUp from '@assets/icons/Button/move-up.svg';
import star from '@assets/icons/Button/star.svg';
import photoPreviewLarge from '@assets/icons/Category/photoPreviewLarge.png';
import loadingGif from '@assets/icons/loading.gif';

import { CheckFat } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

export default function Category() {
  const [loading, setLoading] = useState(true);
  const [showMode, setShowMode] = useState<'categories' | 'insights'>('categories');
  const [scrollDir, setScrollDir] = useState<'up' | 'down'>('up');
  const [headerShown, setHeaderShown] = useState(true);
  const [loadingFeed, setLoadingFeed] = useState(false);
  const [listCategories, setListCategories] = useState<Category[]>([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<'favorite' | 'asc' | 'desc' | null>(null);

  const { groupId, categoryId } = useParams();
  const { getCategory, category } = useCategory();
  const { getPostsByGroup, insights, pagination } = useInsights();

  const { t } = useTranslation();

  const closeButton = useRef<HTMLButtonElement>(null);

  const getCategoryInformation = useCallback(async () => {
    if (!groupId || !categoryId) return;
    const category = await getCategory(groupId, categoryId);
    if (category.children && category.children.length > 0) {
      setShowMode('categories');
      setListCategories(category.children);
    } else if (category.posts && category.posts.length > 0) {
      await getPostsByGroup(groupId, true, `?sortBy=updated_at&sortDirection=desc&categoryUuid=${categoryId}&page=1`);
      setShowMode('insights');
    }
    setLoading(false);
  }, [categoryId, getCategory, getPostsByGroup, groupId]);

  useEffect(() => {
    getCategoryInformation();
  }, [getCategoryInformation]);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.scrollY;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.scrollY;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? 'down' : 'up');
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = async () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }

      if (
        window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight &&
        !loadingFeed &&
        groupId &&
        categoryId &&
        showMode === 'insights' &&
        pagination.current_page < pagination.last_page
      ) {
        setLoadingFeed(true);
        await getPostsByGroup(
          groupId,
          false,
          `?sortBy=updated_at&sortDirection=desc&categoryUuid=${categoryId}&page=${pagination.current_page + 1}`,
        );
        setLoadingFeed(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    if (scrollDir === 'up') setHeaderShown(true);
    else setHeaderShown(false);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollDir, pagination, showMode, loadingFeed, groupId, categoryId, getPostsByGroup]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!category.children) return;
      if (search) {
        if (filter === 'asc') {
          setListCategories(
            category.children
              .toSorted((a, b) => a.name.localeCompare(b.name))
              .filter((category) => category?.name?.toLowerCase?.()?.includes?.(search?.toLowerCase?.())),
          );
        } else if (filter === 'desc') {
          setListCategories(
            category.children
              .toSorted((a, b) => b.name.localeCompare(a.name))
              .filter((category) => category?.name?.toLowerCase?.()?.includes?.(search?.toLowerCase?.())),
          );
        } else if (filter === 'favorite') {
          setListCategories(
            category.children
              .filter((category) => category?.category_favorite && category?.category_favorite[0]?.favorite === 1)
              .filter((category) => category?.name?.toLowerCase?.().includes?.(search?.toLowerCase?.())),
          );
        } else {
          setListCategories(
            category.children.filter((category) => category?.name?.toLowerCase?.().includes(search?.toLowerCase?.())),
          );
        }
      } else if (filter === 'asc') {
        setListCategories(category.children.toSorted((a, b) => a.name.localeCompare(b.name)));
      } else if (filter === 'desc') {
        setListCategories(category.children.toSorted((a, b) => b.name.localeCompare(a.name)));
      } else if (filter === 'favorite') {
        setListCategories(
          category.children.filter(
            (category) => category?.category_favorite && category?.category_favorite[0]?.favorite === 1,
          ),
        );
      } else {
        setListCategories(category.children);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [filter, search, category]);

  return (
    <div className="bg-backgray min-h-[calc(100vh-88px)]">
      {showMode === 'categories' && (
        <header
          className={`flex flex-col w-[calc(100vw-200px)] bg-white fixed z-[1] py-2 ${
            headerShown ? 'max-h-[100px]' : 'max-h-0 overflow-hidden'
          } transition-all duration-300`}
        >
          <div className="flex items-center justify-end gap-5 px-12">
            <div className={`flex items-center ${headerShown ? 'scale-100' : 'scale-0 z-10'} relative w-[250px]`}>
              <input
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                placeholder={t('search_for_name')}
                className="bg-backgray outline-none w-full h-full py-1 rounded-md px-3 pr-8"
                type="text"
              />
              <svg
                className={`${
                  headerShown ? 'scale-100' : 'scale-0 z-10'
                }  transition-all duration-300 absolute right-2`}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M17.5 17.5L12.5 12.5M14.1667 8.33333C14.1667 11.555 11.555 14.1667 8.33333 14.1667C5.11167 14.1667 2.5 11.555 2.5 8.33333C2.5 5.11167 5.11167 2.5 8.33333 2.5C11.555 2.5 14.1667 5.11167 14.1667 8.33333Z"
                  stroke="#282A74"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <button className="hover:opacity-70">
                  <svg
                    className={`${headerShown ? 'scale-100' : 'scale-0 z-10'}  transition-all duration-300`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                    fill="none"
                  >
                    <path
                      d="M10.5 21H7M10.5 21C10.5 22.933 12.067 24.5 14 24.5C15.933 24.5 17.5 22.933 17.5 21M10.5 21C10.5 19.067 12.067 17.5 14 17.5C15.933 17.5 17.5 19.067 17.5 21M31.5 31.5C31.5 29.567 29.933 28 28 28C26.067 28 24.5 29.567 24.5 31.5M31.5 31.5C31.5 33.433 29.933 35 28 35C26.067 35 24.5 33.433 24.5 31.5M31.5 31.5H35M24.5 31.5H7M17.5 21H35M31.5 10.5C31.5 8.567 29.933 7 28 7C26.067 7 24.5 8.567 24.5 10.5M31.5 10.5C31.5 12.433 29.933 14 28 14C26.067 14 24.5 12.433 24.5 10.5M31.5 10.5H35M24.5 10.5H7"
                      stroke="#737373"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay
                  style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                    inset: 0,
                    background: 'rgba(0, 0, 0, 0.75)',
                    zIndex: '10',
                  }}
                />
                <Dialog.Content
                  forceMount
                  className={
                    'z-40 w-[calc(100vw-200px)] rounded-lg p-10 px-20 right-0 bottom-0 fixed bg-white font-poppins'
                  }
                >
                  <Dialog.Close ref={closeButton} className={'absolute top-4 right-8'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                      <path
                        d="M3.35209 0.489335C2.69963 -0.163112 1.6418 -0.163112 0.989335 0.489335C0.336888 1.1418 0.336888 2.19963 0.989335 2.85209L10.6372 12.4999L0.989435 22.1478C0.336989 22.8002 0.336989 23.8581 0.989435 24.5105C1.6419 25.1629 2.69973 25.1629 3.35217 24.5105L12.9999 14.8627L22.6478 24.5105C23.3002 25.1629 24.3581 25.1629 25.0105 24.5105C25.6629 23.8581 25.6629 22.8002 25.0105 22.1478L15.3627 12.4999L25.0107 2.85209C25.6631 2.19963 25.6631 1.1418 25.0107 0.489335C24.3583 -0.163112 23.3004 -0.163112 22.648 0.489335L12.9999 10.1372L3.35209 0.489335Z"
                        fill="#737373"
                      />
                    </svg>
                  </Dialog.Close>
                  <div className="w-full flex flex-col gap-2">
                    <div className="flex items-center w-full relative">
                      <ButtonAction
                        onClick={() => {
                          if (filter === 'favorite') setFilter(null);
                          else setFilter('favorite');
                        }}
                        src={star}
                        text={t('filter_favorite')}
                      />
                      {filter === 'favorite' && (
                        <span className="absolute right-10">
                          <CheckFat color="#282A74" size={20} weight="fill" />
                        </span>
                      )}
                    </div>
                    <div className="flex items-center w-full relative">
                      <ButtonAction
                        onClick={() => {
                          if (filter === 'asc') setFilter(null);
                          else setFilter('asc');
                        }}
                        src={moveUp}
                        text={t('filter_asc')}
                      />
                      {filter === 'asc' && (
                        <span className="absolute right-10">
                          <CheckFat color="#282A74" size={20} weight="fill" />
                        </span>
                      )}
                    </div>
                    <div className="flex items-center w-full relative">
                      <ButtonAction
                        onClick={() => {
                          if (filter === 'desc') setFilter(null);
                          else setFilter('desc');
                        }}
                        src={moveDown}
                        text={t('filter_desc')}
                      />
                      {filter === 'desc' && (
                        <span className="absolute right-10">
                          <CheckFat color="#282A74" size={20} weight="fill" />
                        </span>
                      )}
                    </div>
                    <div className="flex items-center ml-5 w-full relative">
                      <ButtonAction
                        onClick={() => {
                          setFilter(null);
                        }}
                        text={t('none')}
                      />
                      {!filter && (
                        <span className="absolute right-14">
                          <CheckFat color="#282A74" size={20} weight="fill" />
                        </span>
                      )}
                    </div>
                  </div>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          </div>
        </header>
      )}
      <div className="flex justify-center pt-20">
        {loading ? (
          <Loading />
        ) : (
          <>
            {showMode === 'categories' ? (
              <section className="flex flex-wrap gap-10 py-9 justify-center">
                {listCategories?.length === 0 && (
                  <div className="py-10">
                    <EmptyFeed />
                  </div>
                )}
                {listCategories?.length > 0 &&
                  listCategories &&
                  listCategories.map((child) => {
                    if (child.uuid !== category.uuid) {
                      return <CategoryCard key={child.uuid} subCategory={child} groupId={groupId} />;
                    } else {
                      return (
                        <Link
                          key={child.uuid}
                          to={'/groups/' + groupId + '/category/' + child.uuid + '/insight'}
                          className="flex flex-col w-[486px]"
                        >
                          <div className="w-full h-[166px]">
                            <ImageViewer
                              className="h-full w-full object-cover"
                              url={child?.image || ''}
                              previewImage={photoPreviewLarge}
                            />
                          </div>

                          <div className="w-full bg-white flex items-center justify-between p-4 pt-2 h-[70px]">
                            <p className="text-2xl text-grey-150">{child?.name}</p>
                          </div>
                        </Link>
                      );
                    }
                  })}
              </section>
            ) : (
              <main className="flex flex-col items-center w-full bg-backgray min-h-[90vh] gap-9 relative">
                {insights?.length === 0 && (
                  <div className="py-10">
                    <EmptyFeed />
                  </div>
                )}

                {insights?.length > 0 && insights.map((insight) => <PostFeed key={insight.uuid} insight={insight} />)}
                {loadingFeed && (
                  <img
                    src={loadingGif}
                    alt="Loading"
                    className="w-12 absolute bottom-0 left-[50%] -translate-x-[50%]"
                  />
                )}
              </main>
            )}
          </>
        )}
      </div>
    </div>
  );
}
