import * as Dialog from '@radix-ui/react-dialog';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGuest } from '@hooks/useGuest';
import { useInsights } from '@hooks/useInsights';
import { useUser } from '@hooks/useUser';

import AddVisitorModal from '@components/AddVisitorModal';
import { ButtonAdd } from '@components/ButtonAdd';
import Loading from '@components/Loading';
import VisitorModal from '@components/VisitorModal';

import arrowRight from '@assets/icons/Button/arrow-right.svg';
import userNoPhoto from '@assets/icons/user-no-photo.svg';

export default function InsightVisitors() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const { groupId, insightUuid } = useParams();

  const { getGuestsByPostUuid, guests } = useGuest();
  const { getInsightDetails, insight } = useInsights();
  const { user } = useUser();

  const getGuests = useCallback(async () => {
    if (!groupId || !insightUuid) return;
    await Promise.all([getGuestsByPostUuid(groupId, insightUuid), getInsightDetails(groupId, insightUuid)]);
    setIsLoading(false);
  }, [getGuestsByPostUuid, getInsightDetails, groupId, insightUuid]);

  useEffect(() => {
    getGuests();
  }, [getGuests]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col items-center bg-backgray min-h-[calc(100vh-88px)] py-12">
          <div className="flex flex-col bg-white desktop:w-[45vw] tablet:w-[60vw]">
            {insight?.user?.uuid === user?.uuid && (
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <ButtonAdd bg="bg-white" text={t('add_visitor')} textColor="text-primary-200" />
                </Dialog.Trigger>
                <AddVisitorModal groupId={groupId || ''} insightUuid={insightUuid || ''} />
              </Dialog.Root>
            )}
            {guests &&
              guests.map((guest) => (
                <div
                  key={guest.uuid}
                  className={`flex items-center justify-between w-full py-4 gap-3 px-[10px] border-t border-grey-600`}
                >
                  <div className="flex items-center gap-8 ml-4">
                    <img
                      className="min-w-12 min-h-12 max-w-12 max-h-12 rounded-full"
                      src={guest.image || userNoPhoto}
                      alt="Guest"
                    />
                    <div className="flex flex-col items-start">
                      <span className="text-lg text-black-100">{guest.first_name}</span>
                      <span className="text-xs text-black-100">{guest.email}</span>
                    </div>
                  </div>

                  <Dialog.Root>
                    <Dialog.Trigger asChild>
                      <button className={`flex items-center gap-1 hover:opacity-70`}>
                        <span className={`text-black-100`}>{t('visitor')}</span>
                        <img className="w-[30px] h-[30px]" src={arrowRight} alt="Arrow right" />
                      </button>
                    </Dialog.Trigger>
                    <VisitorModal guest={guest} />
                  </Dialog.Root>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
}
