import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useState } from 'react';

import { Collection, useInsights } from '@hooks/useInsights';

import UploadedFilesPreview from '@components/CollectionCard/UploadedFilesPreview';

import * as UploadedFilesPreviewEdit from '@components/CollectionEditCard/UploadedFilesPreview';
import CollectionEditModal from '@components/CollectionEditModal';

interface CollectionEditModeCardProps {
  collection: Collection;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  isEditingInsight?: boolean;
}

export default function CollectionEditModeCard({
  collection,
  setEditMode,
  isEditingInsight,
}: CollectionEditModeCardProps) {
  const { editCollection, creatingInsight, editingInsight } = useInsights();
  const [collectionTitle, setCollectionTitle] = useState(collection.title);

  useEffect(() => {
    setCollectionTitle(collection.title);
  }, [collection]);

  return (
    <div className="w-[500px] p-5 flex flex-col gap-8 bg-white rounded-md">
      <div className="flex items-center justify-between">
        {!isEditingInsight && creatingInsight.collections.length > 1 && (
          <input
            maxLength={255}
            value={collectionTitle}
            onChange={(e) => setCollectionTitle(e.target.value)}
            placeholder="Add a collection title"
            onBlur={(e) => editCollection({ title: e.target.value }, collection.uuid)}
            className="text-xl font-medium text-primary-200 outline-grey-500 bg-transparent border-none w-[300px] px-2 py-1"
          />
        )}
        {isEditingInsight && editingInsight.collections.length > 1 && (
          <input
            value={collectionTitle}
            maxLength={255}
            onChange={(e) => setCollectionTitle(e.target.value)}
            placeholder="Add a collection title"
            onBlur={(e) => editCollection({ title: e.target.value }, collection.uuid, true)}
            className="text-xl font-medium text-primary-200 outline-grey-500 bg-transparent border-none w-[300px] px-2 py-1"
          />
        )}
        <Dialog.Root>
          <Dialog.Trigger asChild>
            <button className="flex items-center justify-center px-1 py-[2px] rounded-full border border-primary-200 hover:opacity-70 ml-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                <path
                  d="M7.5 8.625C7.84518 8.625 8.125 8.34518 8.125 8C8.125 7.65482 7.84518 7.375 7.5 7.375C7.15482 7.375 6.875 7.65482 6.875 8C6.875 8.34518 7.15482 8.625 7.5 8.625Z"
                  fill="#282A74"
                />
                <path
                  d="M11.875 8.625C12.2202 8.625 12.5 8.34518 12.5 8C12.5 7.65482 12.2202 7.375 11.875 7.375C11.5298 7.375 11.25 7.65482 11.25 8C11.25 8.34518 11.5298 8.625 11.875 8.625Z"
                  fill="#282A74"
                />
                <path
                  d="M3.125 8.625C3.47018 8.625 3.75 8.34518 3.75 8C3.75 7.65482 3.47018 7.375 3.125 7.375C2.77982 7.375 2.5 7.65482 2.5 8C2.5 8.34518 2.77982 8.625 3.125 8.625Z"
                  fill="#282A74"
                />
                <path
                  d="M7.5 8.625C7.84518 8.625 8.125 8.34518 8.125 8C8.125 7.65482 7.84518 7.375 7.5 7.375C7.15482 7.375 6.875 7.65482 6.875 8C6.875 8.34518 7.15482 8.625 7.5 8.625Z"
                  stroke="#282A74"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.875 8.625C12.2202 8.625 12.5 8.34518 12.5 8C12.5 7.65482 12.2202 7.375 11.875 7.375C11.5298 7.375 11.25 7.65482 11.25 8C11.25 8.34518 11.5298 8.625 11.875 8.625Z"
                  stroke="#282A74"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.125 8.625C3.47018 8.625 3.75 8.34518 3.75 8C3.75 7.65482 3.47018 7.375 3.125 7.375C2.77982 7.375 2.5 7.65482 2.5 8C2.5 8.34518 2.77982 8.625 3.125 8.625Z"
                  stroke="#282A74"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </Dialog.Trigger>
          <CollectionEditModal collection={collection} setEditMode={setEditMode} isEditingMode={isEditingInsight} />
        </Dialog.Root>
      </div>
      <div className="flex flex-wrap gap-[10px] items-center justify-center">
        {!isEditingInsight &&
          collection?.uploadedFiles &&
          collection.uploadedFiles.map((file: File, index: number) => (
            <UploadedFilesPreview key={index} index={index} file={file} collection={collection} isEditMode={true} />
          ))}
        {isEditingInsight &&
          collection?.mediasToEdit &&
          collection.mediasToEdit.map((media, index: number) => (
            <UploadedFilesPreviewEdit.default
              key={index}
              index={index}
              media={media}
              collection={collection}
              isEditMode={true}
            />
          ))}
      </div>
      <p
        className="text-lg text-black-100 break-words"
        dangerouslySetInnerHTML={{ __html: collection.description }}
      ></p>
    </div>
  );
}
