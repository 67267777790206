import * as Dialog from '@radix-ui/react-dialog';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Insight, useInsights } from '@hooks/useInsights';

import AddVisitorModal from '@components/AddVisitorModal';
import CoverPhotoFeed from '@components/CoverPhotoFeed';
import FeedInfoModal from '@components/FeedInfoModal';
import ImageViewer from '@components/ImageViewer';
import ListComments from '@components/ListComments';
import ReactionToolTip from '@components/ReactionToolTip';
import { Tooltip } from '@nextui-org/react';

interface PostFeedProps {
  insight: Insight;
}

export default function PostFeed({ insight }: PostFeedProps) {
  const [isFavorite, setIsFavorite] = useState(insight?.favorite === 1 || false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [loading, setLoading] = useState(false);

  const { addInsightToFavorite, getInsightDetails, addReactionToPost, deleteReactionFromPost } = useInsights();

  async function getInsightDetail() {
    setLoading(true);
    await getInsightDetails(insight?.group?.uuid || '', insight?.uuid || '');
    setLoading(false);
  }

  async function handleAddInsightToFavorite() {
    setIsSubmitting(true);
    await addInsightToFavorite(insight?.group?.uuid || '', insight?.uuid || '', !isFavorite);
    setIsSubmitting(false);
    setIsFavorite(!isFavorite);
  }

  async function handleReactToPost() {
    if (insight?.user_reaction?.emoji && !insight?.user_reaction?.uuid) return;
    setIsSubmitting(true);
    if (insight?.user_reaction?.emoji) {
      deleteReactionFromPost(insight?.uuid || '');
    } else {
      if (insight?.user_reaction?.emoji) {
        addReactionToPost(insight?.uuid || '', 'like', true);
      } else {
        addReactionToPost(insight?.uuid || '', 'like');
      }
    }
    setIsSubmitting(false);
  }

  return (
    <section className="flex flex-col w-[459px] bg-white p-5 gap-5">
      <header className="flex items-center justify-between mb-5">
        <div className="flex items-center gap-5">
          <ImageViewer
            url={insight?.user?.image || ''}
            alt="User Profile"
            className="h-12 w-12 rounded-full object-cover"
          />
          <span className="flex flex-col">
            <h3 className="text-lg font-bold text-black-100">
              {insight?.user?.first_name} {insight?.user?.last_name}
            </h3>
            <p className="text-xs text-black-100">{insight?.elapsed_time || ''}</p>
          </span>
        </div>
        {insight?.user?.role !== 'Visitant' && (
          <div className="flex items-center gap-1">
            {insight?.status !== 'progress' && (
              <button
                disabled={isSubmitting}
                onClick={handleAddInsightToFavorite}
                className="h-[30px] w-[30px] hover:opacity-70"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill={isFavorite ? '#EDB561' : 'none'}
                >
                  <path
                    d="M6.25 9.75C6.25 7.6498 6.25 6.5997 6.65873 5.79754C7.01825 5.09193 7.59193 4.51825 8.29754 4.15873C9.0997 3.75 10.1498 3.75 12.25 3.75H17.75C19.8502 3.75 20.9003 3.75 21.7025 4.15873C22.4081 4.51825 22.9817 5.09193 23.3413 5.79754C23.75 6.5997 23.75 7.6498 23.75 9.75V26.25L15 21.25L6.25 26.25V9.75Z"
                    stroke={isFavorite ? '#EDB561' : '#333333'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
          </div>
        )}
      </header>

      <Dialog.Root
        modal={false}
        onOpenChange={(e) => {
          if (e === true) {
            getInsightDetail();
          }
        }}
      >
        <Dialog.Trigger asChild>
          <button className="flex flex-col items-start" data-test="edit-insight-post">
            <div className="flex flex-col gap-5">
              <h2 className="text-xl text-black-100 font-medium text-start break-all">{insight?.title || ''}</h2>
              <span className="text-lg text-black-100 text-start mb-2 break-all">{insight?.description || ''}</span>
            </div>

            <CoverPhotoFeed insight={insight} />
          </button>
        </Dialog.Trigger>
        <FeedInfoModal
          loading={loading}
          isFavorite={isFavorite}
          setIsFavorite={setIsFavorite}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
        />
      </Dialog.Root>

      <div className="flex items-center gap-2 mt-2 justify-between">
        <div className="flex flex-col">
          <Link
            className="text-lg font-bold underline text-black-100 break-all"
            to={insight?.user?.role !== 'Visitant' ? '/groups/' + insight?.group?.uuid : '#'}
          >
            {insight?.group?.name || ''}
          </Link>
          <div className="flex items-center gap-1">
            <p className="text-xs text-black-100 break-all pr-5">{insight?.category?.name}</p>
          </div>
        </div>

        {insight?.user?.role !== 'Visitant' && (
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <button className="min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] hover:opacity-70">
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                  <path
                    d="M26.75 11.75L26.75 4.25001M26.75 4.25001H19.25M26.75 4.25001L15.5 15.5M13 4.25H10.25C8.1498 4.25 7.0997 4.25 6.29754 4.65873C5.59193 5.01825 5.01825 5.59193 4.65873 6.29754C4.25 7.0997 4.25 8.1498 4.25 10.25V20.75C4.25 22.8502 4.25 23.9003 4.65873 24.7025C5.01825 25.4081 5.59193 25.9817 6.29754 26.3413C7.0997 26.75 8.1498 26.75 10.25 26.75H20.75C22.8502 26.75 23.9003 26.75 24.7025 26.3413C25.4081 25.9817 25.9817 25.4081 26.3413 24.7025C26.75 23.9003 26.75 22.8502 26.75 20.75V18"
                    stroke="#333333"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </Dialog.Trigger>
            <AddVisitorModal groupId={insight?.group?.uuid || ''} insightUuid={insight?.uuid || ''} />
          </Dialog.Root>
        )}
      </div>

      <div className="flex flex-wrap items-center gap-2">
        {insight?.tags?.map((tag) => (
          <Link
            to={`/groups/${insight?.group?.uuid}?tag=${tag?.uuid}`}
            key={tag?.uuid}
            className="px-3 py-1 border border-primary-200 rounded-3xl text-sm text-primary-200 hover:opacity-70 break-all"
          >
            {tag?.name}
          </Link>
        ))}
      </div>

      <div className="flex justify-end items-center mt-2">
        <div className="flex items-center justify-center gap-2">
          {insight?.user?.role !== 'Visitant' && (
            <div className="flex mt-1.5 relative items-center gap-1">
              <Tooltip
                content={
                  <ReactionToolTip insight={insight} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />
                }
              >
                <div>
                  <button
                    disabled={isSubmitting}
                    onClick={handleReactToPost}
                    className={`hover:opacity-70 ${isSubmitting ? '!cursor-not-allowed' : ''}`}
                  >
                    {!insight?.user_reaction?.emoji && (
                      <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Like Button" clipPath="url(#clip0_1725_31086)">
                          <g id="Group">
                            <path
                              id="Vector"
                              d="M12.2926 5.91544L12.9997 6.62255L13.7068 5.91544C14.6286 4.9936 16.0974 4.125 17.6872 4.125C20.2389 4.125 22.4163 6.21885 22.4163 9.375C22.4163 12.6341 20.6756 15.2536 18.4986 17.3063C16.5719 19.1229 14.4022 20.4052 12.9997 21.2331C11.5971 20.4052 9.42749 19.1229 7.50079 17.3063C5.32375 15.2536 3.58301 12.6341 3.58301 9.375C3.58301 6.22347 5.79571 4.125 8.31217 4.125C9.90199 4.125 11.3707 4.9936 12.2926 5.91544Z"
                              stroke="#333333"
                              strokeWidth="2"
                            />
                          </g>
                        </g>
                        <defs>
                          <clipPath id="clip0_1725_31086">
                            <rect width="25" height="25" fill="white" transform="translate(0.5)" />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                    {insight?.user_reaction?.emoji === 'like' && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17 16" fill="none">
                        <path
                          d="M12.7559 0.5C9.7706 0.5 8.67377 3.55069 8.67377 3.55069C8.67377 3.55069 7.59193 0.5 4.58621 0.5C2.32715 0.5 -0.248018 2.29035 0.703022 6.36021C1.65406 10.4301 8.68195 15.5 8.68195 15.5C8.68195 15.5 15.6839 10.4301 16.6364 6.36021C17.5874 2.29035 15.1975 0.5 12.7559 0.5Z"
                          fill="#F44336"
                        />
                        <path
                          d="M4.12581 0.803842C6.46935 0.803842 7.63294 3.32314 8.009 4.34367C8.06213 4.48946 8.26515 4.49355 8.32646 4.35185L8.66982 3.54932C8.18612 2.00422 6.8563 0.5 4.58634 0.5C3.6462 0.5 2.65292 0.810655 1.88037 1.46603C2.56299 1.01776 3.36279 0.803842 4.12581 0.803842Z"
                          fill="#CC3333"
                        />
                        <path
                          d="M12.7558 0.5C12.0351 0.5 11.3783 0.65669 10.866 0.972795C11.2257 0.833818 11.6713 0.803842 12.1127 0.803842C14.3214 0.803842 16.2984 2.47838 15.4059 6.34931C14.6402 9.6725 10.1657 13.7042 8.79906 15.2425C8.71186 15.3406 8.68188 15.4986 8.68188 15.4986C8.68188 15.4986 15.6839 10.4287 16.6363 6.35884C17.5873 2.29035 15.2016 0.5 12.7558 0.5Z"
                          fill="#CC3333"
                        />
                        <path
                          d="M2.27124 2.65972C2.78219 2.02206 3.69507 1.49476 4.46899 2.10245C4.88728 2.43081 4.7047 3.1039 4.33001 3.3873C3.785 3.80151 3.31084 4.05085 2.97839 4.69941C2.77946 5.08909 2.65956 5.51556 2.59824 5.95021C2.57372 6.12189 2.3489 6.16412 2.26443 6.01288C1.68944 4.99372 1.52594 3.59032 2.27124 2.65972Z"
                          fill="#FF8A80"
                        />
                        <path
                          d="M10.4625 4.00042C10.2227 4.00042 10.0537 3.7688 10.141 3.54534C10.3031 3.13386 10.5129 2.736 10.765 2.38175C11.1383 1.85854 11.8482 1.55334 12.2978 1.8708C12.7584 2.19508 12.6984 2.84364 12.3755 3.15702C11.682 3.82875 10.8072 4.00042 10.4625 4.00042Z"
                          fill="#FF8A80"
                        />
                      </svg>
                    )}
                    {insight?.user_reaction?.emoji === 'happy' && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 17 16" fill="none">
                        <path
                          d="M8.63209 15.5C4.8888 15.5 0.850342 13.1521 0.850342 8C0.850342 2.84794 4.8888 0.5 8.63209 0.5C10.7117 0.5 12.6303 1.18426 14.0525 2.43202C15.5954 3.80054 16.4138 5.73256 16.4138 8C16.4138 10.2674 15.5954 12.186 14.0525 13.5546C12.6276 14.8023 10.6983 15.5 8.63209 15.5Z"
                          fill="url(#paint0_radial_3707_44566)"
                        />
                        <path
                          d="M6.05333 5.05347C5.49116 5.05347 4.97998 5.52842 4.97998 6.31733C4.97998 7.10624 5.49116 7.57985 6.05333 7.57985C6.61683 7.57985 7.12667 7.1049 7.12667 6.31733C7.12667 5.52976 6.6222 5.05347 6.05333 5.05347Z"
                          fill="#422B0D"
                        />
                        <path
                          d="M6.00646 5.58106C5.81594 5.48982 5.58651 5.57032 5.49394 5.76084C5.42283 5.90977 5.45503 6.08821 5.57444 6.2036C5.76496 6.29483 5.99438 6.21433 6.08696 6.02381C6.15807 5.87488 6.12587 5.69644 6.00646 5.58106Z"
                          fill="#896024"
                        />
                        <path
                          d="M11.2052 5.05347C10.643 5.05347 10.1318 5.52842 10.1318 6.31733C10.1318 7.10624 10.643 7.57985 11.2052 7.57985C11.7673 7.57985 12.2785 7.1049 12.2785 6.31733C12.2785 5.52976 11.7673 5.05347 11.2052 5.05347Z"
                          fill="#422B0D"
                        />
                        <path
                          d="M11.1517 5.58106C10.9612 5.48982 10.7318 5.57032 10.6392 5.76084C10.5681 5.90977 10.6003 6.08821 10.7197 6.2036C10.9102 6.29483 11.1396 6.21433 11.2322 6.02381C11.3033 5.87488 11.2711 5.69644 11.1517 5.58106Z"
                          fill="#896024"
                        />
                        <path
                          d="M15.1083 3.54175C15.8234 4.69559 16.1964 6.06948 16.1964 7.59765C16.1964 9.86509 15.378 11.7837 13.8351 13.1522C12.4129 14.4 10.4809 15.0977 8.41466 15.0977C5.99158 15.0977 3.45044 14.1115 1.95044 12.0198C3.39409 14.3892 6.10965 15.5002 8.683 15.5002C10.7492 15.5002 12.6812 14.8025 14.1034 13.5547C15.6463 12.1862 16.4647 10.2676 16.4647 8.00016C16.4647 6.29085 15.9992 4.77207 15.1083 3.54175Z"
                          fill="#EB8F00"
                        />
                        <path
                          d="M13.8443 9.16987C13.6565 8.83043 13.2486 8.6815 12.8863 8.81835C11.5098 9.21952 10.0822 9.41943 8.64795 9.41272C7.2137 9.41943 5.78615 9.21952 4.40958 8.81835C4.04867 8.6815 3.6408 8.82909 3.45296 9.16719C3.2705 9.50395 3.40198 9.89438 3.55627 10.2311C4.41763 12.1229 6.31611 13.2553 8.63722 13.262H8.65869C10.9798 13.262 12.8783 12.1229 13.741 10.2311C13.8939 9.8917 14.0268 9.50664 13.8443 9.16987Z"
                          fill="#422B0D"
                        />
                        <path
                          d="M10.7963 12.7285C10.7466 12.6829 10.6957 12.6413 10.6447 12.5944C10.0973 12.1127 9.38887 11.8538 8.65899 11.8685C7.90765 11.8564 7.17643 12.106 6.59012 12.5756C6.53913 12.6172 6.48547 12.6574 6.43582 12.7098C6.38618 12.7621 6.35935 12.7996 6.32715 12.8439C7.06507 13.1324 7.8513 13.28 8.64423 13.2759H8.6657C9.42509 13.2759 10.1778 13.1404 10.8889 12.8734C10.862 12.8225 10.8312 12.7742 10.7963 12.7285Z"
                          fill="#ED7770"
                        />
                        <path
                          d="M12.8867 8.81819C11.5101 9.21935 10.0826 9.41926 8.64831 9.41255C7.21405 9.41926 5.7865 9.21935 4.40994 8.81819C4.04903 8.68134 3.64115 8.82892 3.45332 9.16702C3.42649 9.21801 3.40502 9.27168 3.3916 9.32669C3.43588 9.34949 3.48686 9.3723 3.54724 9.39645C5.16262 10.2055 6.9484 10.616 8.7543 10.5946C10.4864 10.6147 12.1984 10.2377 13.7628 9.4944C13.8259 9.46756 13.8782 9.44207 13.9251 9.41792C13.9144 9.33071 13.8862 9.24484 13.8446 9.16702C13.6568 8.82892 13.2489 8.67999 12.8867 8.81819Z"
                          fill="white"
                        />
                        <path
                          d="M13.8497 9.17122C13.6591 8.83177 13.2499 8.6815 12.885 8.81835C11.5084 9.21952 10.0809 9.41943 8.64795 9.41272C7.2137 9.41943 5.78615 9.21952 4.40958 8.81835C4.04867 8.6815 3.6408 8.82909 3.45296 9.16719C3.2705 9.50395 3.40198 9.89438 3.55627 10.2311C3.6247 10.3828 3.70118 10.5317 3.7857 10.6752C3.7857 10.6752 3.50395 9.63141 3.74813 9.32819C3.83132 9.19939 3.97085 9.11889 4.12381 9.11084C4.18686 9.11084 4.24858 9.12157 4.30896 9.14036C5.70967 9.55225 7.16271 9.76021 8.6238 9.75753H8.67076C10.1319 9.76021 11.5849 9.55225 12.9856 9.14036C13.046 9.12157 13.1077 9.11084 13.1708 9.11084C13.3237 9.11889 13.4646 9.19939 13.5478 9.32819C13.796 9.63141 13.5102 10.6793 13.5102 10.6793C13.5934 10.5357 13.6766 10.3881 13.7463 10.2352C13.8993 9.89841 14.0321 9.50932 13.8497 9.17122Z"
                          fill="#EB8F00"
                        />
                        <defs>
                          <radialGradient
                            id="paint0_radial_3707_44566"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(8.63209 8) scale(7.64218)"
                          >
                            <stop offset="0.5" stopColor="#FDE030" />
                            <stop offset="0.92" stopColor="#F7C02B" />
                            <stop offset="1" stopColor="#F4A223" />
                          </radialGradient>
                        </defs>
                      </svg>
                    )}
                    {insight?.user_reaction?.emoji === 'funny' && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 18 16" fill="none">
                        <path
                          d="M8.7798 15.5C5.03651 15.5 0.998047 13.1521 0.998047 8C0.998047 2.84794 5.03651 0.5 8.7798 0.5C10.8594 0.5 12.778 1.18426 14.2002 2.43202C15.7431 3.80054 16.5616 5.73256 16.5616 8C16.5616 10.2674 15.7431 12.186 14.2002 13.5546C12.778 14.8023 10.846 15.5 8.7798 15.5Z"
                          fill="url(#paint0_radial_3707_44577)"
                        />
                        <path
                          d="M15.2055 3.54175C15.9206 4.69559 16.2936 6.06948 16.2936 7.59765C16.2936 9.86509 15.4752 11.7837 13.9322 13.1522C12.51 14.4 10.578 15.0977 8.51183 15.0977C6.08875 15.0977 3.54761 14.1115 2.04761 12.0198C3.49126 14.3892 6.20682 15.5002 8.78017 15.5002C10.8464 15.5002 12.7784 14.8025 14.2006 13.5547C15.7435 12.1862 16.5619 10.2676 16.5619 8.00016C16.5619 6.29085 16.0964 4.77207 15.2055 3.54175Z"
                          fill="#EB8F00"
                        />
                        <path
                          d="M5.53869 3.74844C5.47966 3.60622 5.31866 3.53377 5.17375 3.58609C4.9564 3.6572 4.7471 3.74978 4.54719 3.86114C4.01588 4.15363 3.56105 4.56821 3.22027 5.06865C3.12635 5.19611 3.1505 5.37456 3.27527 5.47116C3.34102 5.52617 3.42688 5.553 3.51275 5.54227C3.57715 5.53287 3.63619 5.50202 3.6818 5.45506C4.19701 4.95059 4.77393 4.5132 5.39781 4.15363L5.41257 4.14558C5.55479 4.0691 5.61114 3.89334 5.53869 3.74844Z"
                          fill="#422B0D"
                        />
                        <path
                          d="M12.4148 3.61441C12.4739 3.47219 12.6349 3.39974 12.7798 3.45206C12.9958 3.52183 13.2051 3.61172 13.405 3.7204C13.9363 4.01288 14.3911 4.42612 14.7333 4.92791C14.8258 5.05537 14.8017 5.23247 14.6783 5.33042C14.6125 5.38542 14.5266 5.41092 14.4408 5.40152C14.3764 5.39213 14.3173 5.36127 14.2717 5.31432C13.7565 4.80984 13.1796 4.37246 12.5557 4.01288L12.541 4.00483C12.4028 3.92836 12.3464 3.75796 12.4148 3.61441Z"
                          fill="#422B0D"
                        />
                        <path
                          d="M13.9415 9.17012C13.7536 8.83067 13.3458 8.68175 12.9835 8.8186C11.6069 9.21976 10.1794 9.41967 8.74512 9.41296C7.31087 9.41967 5.88332 9.21976 4.50675 8.8186C4.14584 8.68175 3.73797 8.82933 3.55013 9.16743C3.36766 9.5042 3.49915 9.89463 3.65344 10.2314C4.5148 12.1232 6.41328 13.2555 8.73439 13.2622H8.75586C11.077 13.2622 12.9754 12.1232 13.8381 10.2314C13.9911 9.89194 14.1239 9.50688 13.9415 9.17012Z"
                          fill="#422B0D"
                        />
                        <path
                          d="M10.8935 12.7278C10.8438 12.6822 10.7928 12.6406 10.7418 12.5936C10.1944 12.112 9.48603 11.853 8.75616 11.8678C8.00482 11.8557 7.2736 12.1053 6.68729 12.5749C6.6363 12.6164 6.58263 12.6567 6.53299 12.709C6.48335 12.7613 6.45652 12.7989 6.42432 12.8432C7.16224 13.1316 7.94847 13.2792 8.7414 13.2752H8.76287C9.52226 13.2752 10.2749 13.1397 10.986 12.8727C10.9592 12.8217 10.9283 12.7734 10.8935 12.7278Z"
                          fill="#ED7770"
                        />
                        <path
                          d="M12.9834 8.81892C11.6068 9.22008 10.1792 9.41999 8.74499 9.41328C7.31073 9.41999 5.88318 9.22008 4.50662 8.81892C4.14571 8.68207 3.73783 8.82965 3.55 9.16776C3.52317 9.21874 3.5017 9.27241 3.48828 9.32742C3.53256 9.35023 3.58354 9.37303 3.64392 9.39718C5.2593 10.2062 7.04508 10.6168 8.85098 10.5953C10.5831 10.6154 12.2951 10.2384 13.8595 9.49513C13.9225 9.46829 13.9749 9.4428 14.0218 9.41865C14.0111 9.33144 13.9829 9.24557 13.9413 9.16776C13.7535 8.82965 13.3456 8.68073 12.9834 8.81892Z"
                          fill="white"
                        />
                        <path
                          d="M13.9468 9.17122C13.7563 8.83177 13.3471 8.6815 12.9822 8.81835C11.6056 9.21952 10.178 9.41943 8.74512 9.41272C7.31087 9.41943 5.88332 9.21952 4.50675 8.81835C4.14584 8.6815 3.73797 8.82909 3.55013 9.16719C3.36766 9.50395 3.49915 9.89438 3.65344 10.2311C3.72187 10.3828 3.79834 10.5317 3.88287 10.6752C3.88287 10.6752 3.60112 9.63141 3.8453 9.32819C3.92849 9.19939 4.06802 9.11889 4.22097 9.11084C4.28403 9.11084 4.34575 9.12157 4.40613 9.14036C5.80684 9.55225 7.25988 9.76021 8.72097 9.75753H8.76793C10.229 9.76021 11.6821 9.55225 13.0828 9.14036C13.1432 9.12157 13.2049 9.11084 13.2679 9.11084C13.4209 9.11889 13.5618 9.19939 13.6449 9.32819C13.8932 9.63141 13.6074 10.6793 13.6074 10.6793C13.6906 10.5357 13.7737 10.3881 13.8435 10.2352C13.9965 9.89841 14.1293 9.50932 13.9468 9.17122Z"
                          fill="#EB8F00"
                        />
                        <path
                          d="M13.7561 7.2207C13.7561 7.2207 16.037 7.28376 16.948 8.96489C17.329 9.67061 17.3505 10.8432 16.5616 11.2712C15.6076 11.7958 14.7127 11.0512 14.5987 10.2569C14.2914 8.10219 13.7561 7.2207 13.7561 7.2207Z"
                          fill="#29B6F6"
                        />
                        <path
                          d="M16.7844 10.4648C16.6945 10.6284 16.4973 10.6996 16.3242 10.6311C16.1699 10.5493 16.1283 10.3521 16.1042 10.179L16.0304 9.64231C15.9995 9.42898 15.7822 8.73801 16.0102 8.62531C16.1914 8.53676 16.5389 8.78095 16.6408 8.92182C16.8649 9.24114 16.9709 10.1508 16.7844 10.4648Z"
                          fill="#81D4FA"
                        />
                        <path
                          d="M16.0103 8.6319C16.1914 8.54335 16.5389 8.78754 16.6408 8.92841C16.8649 9.24103 16.9709 10.1507 16.7844 10.4646C16.6945 10.6283 16.4973 10.6994 16.3242 10.631C16.1699 10.5492 16.1283 10.3519 16.1042 10.1789L16.0304 9.64219"
                          fill="#81D4FA"
                        />
                        <path
                          d="M3.91108 7.2207C3.91108 7.2207 1.63022 7.28376 0.719217 8.96489C0.33818 9.6639 0.323421 10.8312 1.10562 11.2645C2.05956 11.7891 2.95446 11.0445 3.0685 10.2502C3.37575 8.10219 3.91108 7.2207 3.91108 7.2207Z"
                          fill="#29B6F6"
                        />
                        <path
                          d="M0.882949 10.4648C0.972841 10.6284 1.17007 10.6996 1.34315 10.6311C1.49744 10.5493 1.53903 10.3521 1.56318 10.179L1.63697 9.64231C1.66783 9.42898 1.88519 8.73801 1.6571 8.62531C1.47597 8.53676 1.12848 8.78095 1.02651 8.92182C0.802448 9.24114 0.696455 10.1508 0.882949 10.4648Z"
                          fill="#81D4FA"
                        />
                        <path
                          d="M6.70715 7.05685L6.68702 7.02734L6.65885 6.99111C6.64811 6.97635 6.63738 6.96428 6.62396 6.9522L6.54212 6.86902C6.50724 6.83413 6.46833 6.79925 6.4254 6.76302C6.33684 6.68655 6.23756 6.62483 6.13023 6.57653C6.02692 6.53091 5.91421 6.50676 5.80151 6.50676H5.82432H5.74785C5.73443 6.50542 5.72235 6.50542 5.70894 6.50676C5.68479 6.50945 5.66064 6.51347 5.63649 6.51884C5.58148 6.53226 5.52647 6.5497 5.47414 6.57116C5.36681 6.61812 5.26752 6.68118 5.17897 6.75766C5.10652 6.81803 5.0381 6.88378 4.97504 6.95489L4.89856 7.04075L4.85831 7.08637C4.69597 7.27152 4.41556 7.29031 4.2304 7.1293C4.09221 7.00855 4.04257 6.81401 4.10429 6.64093L4.11904 6.598C4.13112 6.56982 4.14185 6.52689 4.16734 6.47591C4.22906 6.331 4.30956 6.19281 4.40616 6.06803C4.64096 5.75811 4.97101 5.5327 5.34534 5.42537C5.42182 5.40524 5.49964 5.39048 5.57879 5.37975C5.61904 5.37975 5.67808 5.36768 5.69552 5.36768H5.79749C5.9491 5.37036 6.10071 5.39183 6.24829 5.42939C6.38917 5.4683 6.52334 5.52465 6.6508 5.5971C6.85876 5.7192 7.04123 5.8802 7.18747 6.07206C7.28676 6.2022 7.37128 6.34308 7.43702 6.49335C7.44776 6.51481 7.45715 6.53628 7.46252 6.55909C7.46922 6.57653 7.47325 6.59129 7.47727 6.60336L7.48935 6.63959C7.56582 6.87707 7.43568 7.13199 7.1982 7.20846C7.01976 7.26616 6.82253 7.20712 6.70581 7.06088L6.70715 7.05685Z"
                          fill="#422B0D"
                        />
                        <path
                          d="M12.7892 7.05685L12.765 7.02734L12.7368 6.99111C12.7261 6.97635 12.7154 6.96428 12.702 6.9522L12.6188 6.86902C12.5826 6.83145 12.5436 6.79657 12.5034 6.76302C12.4148 6.68655 12.3156 6.62483 12.2082 6.57653C12.1049 6.53091 11.9922 6.50676 11.8795 6.50676H11.9023H11.8245C11.8111 6.50542 11.799 6.50542 11.7856 6.50676C11.7614 6.50945 11.7373 6.51347 11.7131 6.51884C11.6581 6.53226 11.6031 6.5497 11.5508 6.57116C11.4421 6.62081 11.3402 6.68521 11.2489 6.76302C11.1765 6.82474 11.1094 6.89048 11.0463 6.96025L10.9699 7.04612L10.9296 7.09174C10.7673 7.27689 10.4868 7.29567 10.3017 7.13467C10.1635 7.01392 10.1139 6.81938 10.1756 6.6463L10.1903 6.60336C10.2024 6.57519 10.2131 6.53226 10.2373 6.48127C10.3017 6.33369 10.3835 6.19549 10.4828 6.06803C10.7176 5.75811 11.0477 5.5327 11.422 5.42537C11.4985 5.40524 11.5763 5.39048 11.6554 5.37975C11.6957 5.37975 11.7547 5.36768 11.7722 5.36768H11.8741C12.0258 5.37036 12.1774 5.39183 12.3249 5.42939C12.4645 5.46964 12.5987 5.52599 12.7248 5.59845C12.9327 5.7192 13.1152 5.88154 13.2614 6.0734C13.3178 6.14585 13.3674 6.22233 13.4131 6.30149C13.4493 6.36455 13.4815 6.42895 13.511 6.49469C13.5217 6.51616 13.5298 6.53762 13.5365 6.56043C13.5365 6.57787 13.5472 6.59263 13.5512 6.60471L13.5633 6.64093C13.6398 6.87841 13.5097 7.13333 13.2722 7.2098C13.0937 7.2675 12.8965 7.20846 12.7798 7.06222L12.7892 7.05685Z"
                          fill="#422B0D"
                        />
                        <defs>
                          <radialGradient
                            id="paint0_radial_3707_44577"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(8.7798 8) scale(7.64218)"
                          >
                            <stop offset="0.5" stopColor="#FDE030" />
                            <stop offset="0.92" stopColor="#F7C02B" />
                            <stop offset="1" stopColor="#F4A223" />
                          </radialGradient>
                        </defs>
                      </svg>
                    )}
                    {insight?.user_reaction?.emoji === 'sad' && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16" fill="none">
                        <path
                          d="M7.61957 14.793C4.05271 14.793 0.20459 12.5557 0.20459 7.64651C0.20459 2.73728 4.05271 0.5 7.61957 0.5C9.60116 0.5 11.4293 1.15201 12.7845 2.34096C14.2547 3.64498 15.0346 5.48594 15.0346 7.64651C15.0346 9.80708 14.2547 11.6353 12.7845 12.9393C11.4293 14.1282 9.58838 14.793 7.61957 14.793Z"
                          fill="url(#paint0_radial_3707_44594)"
                        />
                        <path
                          d="M13.7421 3.39819C14.4235 4.49766 14.7789 5.80678 14.7789 7.26293C14.7789 9.42351 13.999 11.2517 12.5288 12.5557C11.1737 13.7447 9.3327 14.4094 7.3639 14.4094C5.05502 14.4094 2.62853 13.4698 1.20435 11.4767C2.57995 13.7344 5.16625 14.793 7.61959 14.793C9.58839 14.793 11.4294 14.1282 12.7845 12.9392C14.2547 11.6352 15.0346 9.80704 15.0346 7.64647C15.0346 6.01773 14.5909 4.57053 13.7421 3.39819Z"
                          fill="#EB8F00"
                        />
                        <path
                          d="M7.61957 14.793C4.05271 14.793 0.20459 12.5557 0.20459 7.64651C0.20459 2.73728 4.05271 0.5 7.61957 0.5C9.60116 0.5 11.4293 1.15201 12.7845 2.34096C14.2547 3.64498 15.0346 5.48594 15.0346 7.64651C15.0346 9.80708 14.2547 11.6353 12.7845 12.9393C11.4293 14.1282 9.58838 14.793 7.61957 14.793Z"
                          fill="url(#paint1_radial_3707_44594)"
                        />
                        <path
                          d="M10.9616 9.71781C10.0257 9.41737 8.88919 9.25757 7.67083 9.25757C6.4333 9.25757 5.28909 9.43272 4.33792 9.73699C3.9774 10.334 3.75879 11.0257 3.75879 11.7633C3.75879 12.3898 4.38523 12.7605 4.93496 12.5048C5.62532 12.1852 6.63529 11.8656 7.67083 11.8656C8.70637 11.8656 9.72913 12.1852 10.4067 12.5048C10.9564 12.7605 11.5829 12.3898 11.5829 11.7633C11.5829 11.018 11.3438 10.3187 10.9616 9.71781Z"
                          fill="#422B0D"
                        />
                        <path
                          d="M10.9613 9.71764C10.1891 8.50312 8.83266 8.19629 7.67055 8.19629C6.40745 8.19629 5.07531 8.51207 4.33765 9.73682C5.28881 9.43255 6.43302 9.2574 7.67055 9.2574C8.88763 9.2574 10.0254 9.4172 10.9613 9.71764Z"
                          fill="white"
                        />
                        <path
                          d="M7.66309 8.00415C8.11054 8.01566 8.56183 8.05657 9.00034 8.17035C9.44013 8.27774 9.86713 8.46183 10.2417 8.72519C10.6125 8.99495 10.9244 9.34524 11.1379 9.74411C11.3552 10.1366 11.5138 10.5623 11.593 11.0034C11.6339 11.2233 11.6557 11.447 11.6557 11.6707C11.6595 11.7781 11.6493 11.9047 11.6173 12.0185C11.5841 12.1335 11.5253 12.2396 11.4498 12.3317C11.299 12.5171 11.0689 12.627 10.8349 12.6436C10.7186 12.65 10.601 12.6347 10.491 12.5989C10.3798 12.558 10.2865 12.5081 10.1867 12.4646C9.3941 12.1144 8.53626 11.8868 7.66309 11.8663C8.53754 11.8548 9.40944 12.0504 10.2251 12.3764C10.3274 12.4161 10.4296 12.4659 10.5242 12.4966C10.6227 12.5247 10.7262 12.5337 10.826 12.5247C11.0267 12.503 11.2184 12.4007 11.3386 12.2422C11.3987 12.1629 11.4447 12.0734 11.4677 11.9776C11.4908 11.8791 11.4959 11.7858 11.4895 11.6758C11.4805 11.4623 11.4524 11.2501 11.4064 11.043C11.3156 10.6275 11.1507 10.2286 10.9321 9.86557C10.7173 9.50121 10.4232 9.19694 10.0793 8.96426C9.73033 8.73798 9.33657 8.58329 8.92491 8.49891C8.51453 8.40942 8.08881 8.38513 7.66309 8.39024V8.00415Z"
                          fill="#EB8F00"
                        />
                        <path
                          d="M7.69501 8.38804C7.27057 8.38293 6.84357 8.40722 6.43319 8.49671C6.02153 8.58109 5.62777 8.73578 5.27875 8.96207C4.93485 9.19474 4.64081 9.49901 4.42603 9.86337C4.20613 10.2264 4.04249 10.624 3.95172 11.0408C3.9057 11.2492 3.87629 11.4601 3.86862 11.6736C3.86095 11.7836 3.86735 11.8769 3.89036 11.9754C3.91465 12.0712 3.96067 12.1607 4.01948 12.24C4.13965 12.3985 4.33142 12.5008 4.53214 12.5225C4.63313 12.5315 4.73541 12.5225 4.83385 12.4944C4.92846 12.4637 5.03073 12.4151 5.13301 12.3742C5.94738 12.0482 6.82056 11.8526 7.69501 11.8641C6.82056 11.8846 5.96272 12.1122 5.17136 12.4624C5.07164 12.5059 4.97831 12.5558 4.86709 12.5967C4.75714 12.6325 4.63953 12.6478 4.52319 12.6414C4.29051 12.6261 4.06039 12.5149 3.90826 12.3295C3.83283 12.2374 3.77402 12.1313 3.74078 12.0163C3.70882 11.9038 3.69859 11.7772 3.70243 11.6685C3.7037 11.4448 3.72544 11.2224 3.76507 11.0012C3.84561 10.5601 4.00286 10.1344 4.2202 9.74192C4.43498 9.34432 4.74692 8.99275 5.11639 8.723C5.49097 8.45964 5.91797 8.27426 6.35776 8.16815C6.79754 8.05565 7.24883 8.01346 7.69501 8.00195V8.38804Z"
                          fill="#EB8F00"
                        />
                        <path
                          d="M13.7421 3.39795C14.4235 4.49741 14.7789 5.80654 14.7789 7.26269C14.7789 9.42326 13.999 11.2514 12.5288 12.5555C11.1737 13.7444 9.3327 14.4092 7.3639 14.4092C5.05502 14.4092 2.62853 13.4695 1.20435 11.4764C2.57995 13.7342 5.16625 14.7927 7.61959 14.7927C9.58839 14.7927 11.4294 14.1279 12.7845 12.939C14.2547 11.635 15.0346 9.8068 15.0346 7.64622C15.0346 6.01748 14.5909 4.57028 13.7421 3.39795Z"
                          fill="#EB8F00"
                        />
                        <path
                          d="M2.3255 5.47484C2.25902 5.48634 2.18998 5.47612 2.13117 5.44799C2.01483 5.39174 1.92662 5.25495 1.9931 5.07596C2.23728 4.44441 2.74227 3.91513 3.37893 3.62109C3.61545 3.51115 3.86347 3.43827 4.11788 3.40376C4.30836 3.38202 4.41959 3.49836 4.44644 3.62237C4.47584 3.76044 4.4132 3.9292 4.23421 3.98801L4.21887 3.99312C3.52979 4.23091 2.91486 4.718 2.53388 5.32782C2.48147 5.41475 2.40476 5.46078 2.3255 5.47484Z"
                          fill="#422B0D"
                        />
                        <path
                          d="M12.7241 5.32784C12.3432 4.71802 11.7282 4.23093 11.0391 3.99314L11.0238 3.98803C10.8448 3.9305 10.7809 3.76047 10.8116 3.62239C10.8384 3.49711 10.9509 3.38205 11.1401 3.40378C11.3933 3.43702 11.6426 3.51117 11.8791 3.62112C12.5157 3.91516 13.0207 4.44443 13.2649 5.07599C13.3314 5.25497 13.2432 5.39176 13.1268 5.44801C13.0693 5.47614 13.0003 5.48637 12.9325 5.47486C12.8533 5.4608 12.7765 5.41477 12.7241 5.32784Z"
                          fill="#422B0D"
                        />
                        <path
                          d="M13.7739 13.7956C13.7739 13.4032 13.0643 13.0772 12.1733 13.0567L11.8984 6.33848C11.8715 6.31802 11.8447 6.29373 11.823 6.26688C11.823 6.26688 11.8153 6.25666 11.8 6.23876C11.7923 6.22981 11.7833 6.2183 11.7731 6.20424C11.7616 6.19018 11.7501 6.17484 11.7399 6.16717C11.7181 6.14543 11.6913 6.11858 11.6606 6.0879C11.6286 6.05594 11.5903 6.02142 11.5507 5.9869C11.4688 5.91787 11.3717 5.85395 11.2694 5.8092C11.1671 5.76701 11.0636 5.74144 10.9575 5.74272H10.9408L10.9038 5.744C10.8705 5.744 10.8769 5.74656 10.8667 5.74784C10.8577 5.74656 10.8219 5.75551 10.7977 5.75934C10.7465 5.77213 10.6928 5.78747 10.6417 5.8092C10.5394 5.85395 9.99096 5.87824 9.95516 5.89997L9.89635 13.7304C9.31722 13.9056 8.68311 14.1996 8.68311 14.5282C8.68311 15.0639 9.68413 15.4998 10.9191 15.4998C12.1541 15.4998 13.1551 15.0664 13.1551 14.5308C13.1551 14.4771 13.1423 14.4259 13.1231 14.3748C13.5195 14.2393 13.7739 14.0309 13.7739 13.7956Z"
                          fill="#29B6F6"
                        />
                        <path
                          d="M10.5317 13.2683C10.5432 13.6838 10.2927 14.0597 9.90656 14.2144C9.43737 14.4023 8.98992 14.667 9.65982 14.8255C9.65982 14.8255 10.1648 15.0735 10.4218 14.9444C10.5368 14.8868 10.4972 14.7922 10.4179 14.7066C10.331 14.6133 10.3745 14.4624 10.4985 14.4343L10.6084 14.41C10.8871 14.3486 11.0827 14.0968 11.0751 13.8117L10.8692 5.42124L10.4486 5.38672L10.5317 13.2683Z"
                          fill="#81D4FA"
                        />
                        <path
                          d="M5.48196 13.7303L5.42443 5.91513C5.36179 5.887 5.2544 6.25647 5.23905 6.23729C5.23138 6.22834 5.22244 6.21684 5.21221 6.20278C5.2007 6.18871 5.1892 6.17337 5.17897 6.1657C5.15723 6.14397 5.13039 6.11712 5.0997 6.08644C5.06774 6.05448 5.02939 6.01996 4.98976 5.98544C4.90794 5.9164 4.81078 5.85248 4.7085 5.80774C4.60622 5.76555 4.50267 5.73998 4.39656 5.74126H4.37994L4.34286 5.74253C4.30963 5.74253 4.31602 5.74509 4.30579 5.74637C4.29684 5.74509 4.26104 5.75404 4.23675 5.75788C4.18562 5.77066 4.13192 5.786 4.08078 5.80774C3.97851 5.85248 3.88135 5.9164 3.79953 5.98544C3.71898 6.05448 3.65123 6.12223 3.6052 6.17337C3.55918 6.22579 3.53233 6.25519 3.53233 6.25519L3.49398 6.29866C3.49014 6.30249 3.48631 6.30505 3.48247 6.30889L3.20633 13.0552C2.31397 13.0757 1.60571 13.3991 1.60571 13.7916C1.60571 14.0269 1.86012 14.2378 2.25516 14.372C2.23599 14.4232 2.2232 14.4756 2.2232 14.5293C2.2232 15.065 3.22423 15.4996 4.4592 15.4996C5.69418 15.4996 6.6952 15.0624 6.6952 14.5267C6.6952 14.1982 6.05982 13.9054 5.48196 13.7303Z"
                          fill="#29B6F6"
                        />
                        <path
                          d="M3.99528 5.88712L3.77155 12.9518C3.76516 13.1576 3.59257 13.3187 3.38802 13.3098C3.1221 13.2983 2.75774 13.3289 2.45859 13.5143C2.2975 13.614 2.23102 13.8685 2.37804 14.0104C2.53913 14.1651 2.96101 14.0308 3.17196 14.0091C3.26401 14.0001 3.38546 14.0091 3.40975 14.0986C3.42381 14.1497 3.39313 14.2047 3.35094 14.2354C3.30875 14.2673 3.25634 14.2801 3.2052 14.2955C3.12082 14.3197 3.03644 14.3491 2.96996 14.4067C2.90476 14.4642 2.86257 14.5563 2.88559 14.6406C2.91627 14.7493 3.03644 14.8017 3.14255 14.8388C3.32921 14.9066 3.5197 14.9743 3.71785 14.9833C3.72936 14.8426 3.51075 14.7455 3.55421 14.6112C3.56955 14.5639 3.6143 14.5332 3.65905 14.5115C3.77922 14.4553 3.91601 14.4438 4.04514 14.4131C4.25736 14.3632 4.64089 14.2558 4.54117 13.9643C4.50793 13.8685 4.44018 13.7905 4.38265 13.7061C4.16659 13.3865 4.1244 13.1052 4.16275 12.7217C4.19344 12.4225 4.27014 7.68845 4.32128 5.74138C4.33406 5.27474 4.18193 5.7043 4.02979 5.71581C4.01701 5.73243 4.00806 5.87178 3.99528 5.88712Z"
                          fill="#81D4FA"
                        />
                        <path
                          d="M5.70205 6.56369L5.67648 6.53813C5.6637 6.52534 5.62535 6.48699 5.61256 6.4742C5.58699 6.44863 5.56142 6.43585 5.52307 6.3975C5.48472 6.37193 5.44636 6.33357 5.39523 6.30801C5.34409 6.28244 5.29295 6.24408 5.24181 6.21851C5.19068 6.19295 5.12675 6.16738 5.06283 6.14181C4.99891 6.11624 4.93499 6.10345 4.87106 6.09067C4.80714 6.07789 4.74322 6.07789 4.66651 6.07789H4.64094H4.60259C4.56424 6.07789 4.56424 6.07789 4.55145 6.07789C4.52588 6.07789 4.48753 6.09067 4.46196 6.09067C4.39804 6.10345 4.33412 6.11624 4.27019 6.14181C4.20627 6.16738 4.14235 6.18016 4.09121 6.21851C4.04007 6.24408 3.98894 6.26965 3.9378 6.30801C3.84831 6.37193 3.7716 6.43585 3.72046 6.4742C3.66932 6.52534 3.64376 6.55091 3.64376 6.55091L3.61819 6.57648C3.46477 6.72989 3.20908 6.72989 3.0301 6.60205C2.90226 6.49977 2.8639 6.32079 2.92783 6.19295L2.94061 6.15459C2.9534 6.12902 2.96618 6.09067 2.99175 6.05232C3.04289 5.96283 3.13238 5.83498 3.27301 5.69435C3.33693 5.63043 3.42642 5.55372 3.51591 5.4898C3.6054 5.42588 3.72046 5.36196 3.83552 5.31082C3.95058 5.25968 4.09121 5.20854 4.21906 5.18297C4.28298 5.17019 4.35969 5.15741 4.43639 5.14462C4.47475 5.14462 4.52588 5.13184 4.53867 5.13184H4.64094C4.78157 5.13184 4.9222 5.14462 5.06283 5.17019C5.20346 5.19576 5.3313 5.2469 5.44636 5.29803C5.56142 5.34917 5.67648 5.41309 5.76598 5.47702C5.86825 5.54094 5.94496 5.60486 6.00888 5.68157C6.0728 5.74549 6.13672 5.80941 6.17508 5.87333C6.20065 5.8989 6.21343 5.93726 6.239 5.96283C6.25178 5.98839 6.27735 6.01396 6.29014 6.03953C6.32849 6.09067 6.32849 6.11624 6.34128 6.14181C6.35406 6.16738 6.35406 6.16738 6.35406 6.16738C6.44355 6.35914 6.34128 6.57648 6.12394 6.65319C6.00888 6.71711 5.81711 6.67875 5.70205 6.56369Z"
                          fill="#422B0D"
                        />
                        <path
                          d="M11.6993 6.51795C11.6993 6.51795 11.6903 6.509 11.6737 6.49238C11.6596 6.47832 11.6277 6.44252 11.6085 6.42846C11.5842 6.408 11.5548 6.38371 11.5203 6.35559C11.4832 6.32746 11.441 6.29678 11.395 6.26482C11.3477 6.23541 11.2966 6.20473 11.2416 6.17788C11.1879 6.14848 11.1265 6.12803 11.0652 6.10501C11.0038 6.08328 10.9386 6.07177 10.8734 6.05771C10.8082 6.04748 10.7404 6.04237 10.6727 6.04237H10.6906H10.6893H10.6868H10.6816H10.6714H10.651L10.6113 6.04365C10.5781 6.04365 10.573 6.0462 10.5602 6.04748C10.5397 6.04748 10.5027 6.05388 10.4707 6.05899C10.4055 6.07305 10.3403 6.08456 10.2789 6.10629C10.2188 6.1293 10.1575 6.14976 10.1038 6.17916C10.0488 6.20729 9.99767 6.23669 9.95037 6.2661C9.85832 6.32746 9.78289 6.38883 9.73303 6.43485C9.68189 6.48088 9.65249 6.509 9.65249 6.509L9.62053 6.54096C9.46584 6.69054 9.20503 6.69949 9.03628 6.5627C8.90332 6.45403 8.87008 6.28655 8.93656 6.1472C8.93656 6.1472 8.94295 6.13442 8.95574 6.10885C8.96852 6.08456 8.98386 6.0462 9.01199 6.00146C9.0644 5.91069 9.15134 5.78285 9.28941 5.64861C9.35845 5.58213 9.43899 5.51181 9.53487 5.44789C9.6282 5.38141 9.73687 5.32133 9.85704 5.26891C9.97721 5.21777 10.1076 5.17303 10.2457 5.1449C10.316 5.13212 10.3812 5.11933 10.4669 5.11166C10.5065 5.10783 10.5538 5.10399 10.5742 5.10271L10.6727 5.09888C10.8172 5.10143 10.9603 5.11677 11.0997 5.14362C11.2378 5.17175 11.3682 5.21649 11.4883 5.26763C11.6072 5.32005 11.7172 5.38013 11.8105 5.44661C11.9064 5.51054 11.9857 5.57957 12.0547 5.64733C12.1237 5.71381 12.1787 5.78029 12.2247 5.84038C12.2465 5.87106 12.2669 5.89918 12.2861 5.92603C12.304 5.95544 12.3219 5.98228 12.3359 6.00657C12.3705 6.06027 12.373 6.08072 12.3858 6.10501C12.3948 6.12547 12.3999 6.13697 12.3999 6.13697C12.4855 6.32746 12.382 6.54352 12.1685 6.62023C12.001 6.68031 11.8131 6.63429 11.6993 6.51795Z"
                          fill="#422B0D"
                        />
                        <defs>
                          <radialGradient
                            id="paint0_radial_3707_44594"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(7.61957 7.6465) scale(7.28199)"
                          >
                            <stop offset="0.5" stopColor="#FDE030" />
                            <stop offset="0.9188" stopColor="#F7C02B" />
                            <stop offset="1" stopColor="#F4A223" />
                          </radialGradient>
                          <radialGradient
                            id="paint1_radial_3707_44594"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(7.61957 7.6465) scale(7.28199)"
                          >
                            <stop offset="0.5" stopColor="#FDE030" />
                            <stop offset="0.9188" stopColor="#F7C02B" />
                            <stop offset="1" stopColor="#F4A223" />
                          </radialGradient>
                        </defs>
                      </svg>
                    )}
                  </button>
                </div>
              </Tooltip>
              <span className="mb-1">{insight?.reactions_count}</span>
            </div>
          )}

          <div className="flex items-center gap-1">
            <button onClick={() => setShowComments(!showComments)} className="hover:opacity-70">
              <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Comment button">
                  <path
                    id="Vector"
                    d="M7.75 18.75V17.75H6.75H4.79688C4.33689 17.75 3.89574 17.5673 3.57049 17.242C3.24523 16.9168 3.0625 16.4756 3.0625 16.0156V4.29687C3.0625 3.33978 3.83978 2.5625 4.79688 2.5625H21.2031C22.1602 2.5625 22.9375 3.33978 22.9375 4.29687V16.0156C22.9375 16.4756 22.7548 16.9168 22.4295 17.242C22.1043 17.5673 21.6631 17.75 21.2031 17.75H14.6563H14.242L13.9491 18.0429L9.92901 22.063C9.92895 22.0631 9.92889 22.0631 9.92883 22.0632C9.75033 22.2415 9.52297 22.363 9.27548 22.4122C9.02792 22.4614 8.77131 22.4361 8.5381 22.3396C8.3049 22.243 8.10556 22.0794 7.96528 21.8696C7.82507 21.6598 7.75016 21.4132 7.75 21.1609C7.75 21.1608 7.75 21.1607 7.75 21.1606V18.75Z"
                    stroke="#333333"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </button>
            <span>{insight?.comments_count}</span>
          </div>
        </div>
      </div>

      <div
        className={`w-full ${showComments ? 'max-h-[550px]' : 'max-h-0 overflow-hidden'} transition-all duration-300`}
      >
        <ListComments insight={insight} showComments={showComments} />
      </div>
    </section>
  );
}
