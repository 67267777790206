import * as Dialog from '@radix-ui/react-dialog';
import * as Popover from '@radix-ui/react-popover';
import { useEffect, useRef, useState } from 'react';

import { Collection, useInsights } from '@hooks/useInsights';

import ConfirmActionModal from '@components/ConfirmActionModal';
import RecordAudioModal from '@components/RecordAudioModal';
import ShareInsightPhotoOrVideoModal from '@components/ShareInsightPhotoOrVideoModal';
import TextEditorModal from '@components/TextEditorModal';
import DescriptionBox from './DescriptionBox';
import UploadedFilesPreview from './UploadedFilesPreview';

import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';
import { useTranslation } from 'react-i18next';
import { toast } from 'utils/toast';

interface CollectionCardProps {
  collection: Collection;
}

export default function CollectionCard({ collection }: Readonly<CollectionCardProps>) {
  const { t } = useTranslation();
  const { creatingInsight, editInsight, editCollection } = useInsights();
  const [coverPhoto, setCoverPhoto] = useState('');

  const dragAndDropInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (collection?.uploadedFiles?.length > 0 && !collection?.coverPhoto?.file) {
      editCollection({ ...collection, coverPhoto: { file: collection.uploadedFiles[0] } }, collection.uuid);
      if (collection.uploadedFiles[0].type.includes('video')) {
        const videoThumbnails = generateVideoThumbnails(collection.uploadedFiles[0], 1, 'png');
        videoThumbnails.then((data) => {
          setCoverPhoto(data[0]);
        });
      } else if (collection.uploadedFiles[0].type.includes('image')) {
        setCoverPhoto(URL.createObjectURL(collection.uploadedFiles[0]));
      }
    }

    if (collection?.coverPhoto?.file) {
      if (collection.coverPhoto.file.type.includes('video')) {
        const videoThumbnails = generateVideoThumbnails(collection.coverPhoto.file, 1, 'png');
        videoThumbnails.then((data) => {
          setCoverPhoto(data[0]);
        });
      } else if (collection.uploadedFiles[0].type.includes('image')) {
        setCoverPhoto(URL.createObjectURL(collection.coverPhoto.file));
      }
    } else {
      setCoverPhoto('');
    }
  }, [collection, editCollection]);

  function handleDeleteCollection(collectionId: string) {
    const newCollections = creatingInsight.collections.filter((collection) => collection.uuid !== collectionId);
    editInsight({ collections: newCollections });
  }

  function handleDragAndDropFile(event: any) {
    event.preventDefault();
    let fileList: FileList = {} as FileList;
    let files: File[] = [];

    if (event.target.files) {
      fileList = event.target.files;
    } else if (event.dataTransfer.files) {
      fileList = event.dataTransfer.files;
    }

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const fileNameParts = file?.name?.split('.');
      const extension =
        fileNameParts?.length > 1 ? fileNameParts?.[fileNameParts?.length - 1]?.toLowerCase?.() ?? '' : '';
      if (['svg', 'gif'].includes(extension)) {
        toast({
          label: t('error'),
          message: t('only_these_image_extensions_are_allowed'),
          type: 'error',
        });
        throw new Error('Invalid file type');
      }
    }

    if (!fileList) return;

    files = creatingInsight.collections.find((c) => c.uuid === collection.uuid)?.uploadedFiles || [];

    Array.from(fileList).forEach((file) => {
      if (file?.type.includes('image') || file?.type.includes('video') || file?.type.includes('audio')) {
        files.push(file);
      }
      editCollection({ uploadedFiles: files }, collection.uuid);
    });
  }

  return (
    <div key={collection.uuid} className="flex flex-col pt-5 pb-3 px-3 bg-white rounded-md relative">
      <input
        id="select-image"
        accept="image/*,video/*,audio/*"
        type="file"
        className="hidden"
        data-test="create-insight-select-file"
        multiple
        ref={dragAndDropInput}
        onChange={handleDragAndDropFile}
      />
      {creatingInsight.collections.length > 1 && (
        <input
          defaultValue={collection.title}
          onBlur={(e) => editCollection({ title: e.target.value }, collection.uuid)}
          type="text"
          maxLength={255}
          placeholder={t('add_a_collection_title')}
          className="py-3 px-2 w-full rounded-md border border-grey-600 text-xs text-grey-200 outline-grey-500 mt-5 mb-5"
        />
      )}
      {!collection.uploadedFiles || collection.uploadedFiles.length === 0 ? (
        <>
          <p className="text-lg font-medium text-grey-200 px-3">
            {t('here_you_can_add_a_set_of_photos_videos_audios_and_write_a_text')}
          </p>
          <div
            aria-hidden
            onDrop={handleDragAndDropFile}
            onDragOver={(e) => e.preventDefault()}
            onClick={() => dragAndDropInput.current?.click()}
            className="w-full h-[232px] border border-grey-600 flex flex-col gap-2 items-center justify-center cursor-pointer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
              <g opacity="0.6">
                <path
                  d="M19.1663 3.02539V8.53278C19.1663 9.27951 19.1663 9.65288 19.3117 9.9381C19.4395 10.189 19.6435 10.393 19.8944 10.5208C20.1796 10.6661 20.5529 10.6661 21.2997 10.6661H26.8071M12.4997 19.9993L16.4997 23.9993M16.4997 23.9993L20.4997 19.9993M16.4997 23.9993L16.4997 15.9993M19.1663 2.66602H12.233C9.9928 2.66602 8.87269 2.66602 8.01705 3.10199C7.2644 3.48548 6.65248 4.09741 6.26898 4.85005C5.83301 5.7057 5.83301 6.82581 5.83301 9.06602V22.9327C5.83301 25.1729 5.83301 26.293 6.26898 27.1486C6.65248 27.9013 7.2644 28.5132 8.01705 28.8967C8.87269 29.3327 9.9928 29.3327 12.233 29.3327H20.7663C23.0066 29.3327 24.1267 29.3327 24.9823 28.8967C25.735 28.5132 26.3469 27.9013 26.7304 27.1486C27.1663 26.293 27.1663 25.1729 27.1663 22.9327V10.666L19.1663 2.66602Z"
                  stroke="#282A74"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            <p className="text-sm text-grey-200 text-opacity-60">{t('your_files_will_appear_here')}</p>
          </div>
        </>
      ) : (
        <div
          onDrop={handleDragAndDropFile}
          onDragOver={(e) => e.preventDefault()}
          className="flex flex-col gap-8 items-center mt-8"
          aria-hidden
        >
          {coverPhoto ? (
            <span className="w-[150px] h-[150px] border-2 border-primary-200 relative">
              <img alt="Cover" src={coverPhoto} className="w-full h-full max-h-full max-w-full object-cover" />

              <button className="absolute top-0 right-0 flex items-center justify-center rounded-full py-1 px-3 text-primary-200 text-[8px] bg-white border border-primary-200 hover:brightness-110">
                {t('cover_photos')}
              </button>
            </span>
          ) : null}
          <div className="flex flex-wrap w-full justify-center gap-x-[2px] gap-y-3">
            {collection.uploadedFiles.map((file: File, index: number) => {
              return <UploadedFilesPreview key={index} index={index} file={file} collection={collection} />;
            })}
          </div>
        </div>
      )}
      {collection.description && <DescriptionBox collection={collection} />}
      <div className="flex items-center justify-between py-3 border-t border-grey-600 mt-8">
        <p className="w-[200px] text-lg font-medium ml-3">{t('add_to_collection')}</p>
        <div className="flex items-center justify-evenly flex-1">
          {/* RECORD AUDIO */}
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <button className="flex items-center justify-center bg-primary-200 bg-opacity-10 rounded-md px-[10px] py-[5px] hover:opacity-70">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path
                    d="M19.333 10V12C19.333 15.866 16.199 19 12.333 19M5.33301 10V12C5.33301 15.866 8.46701 19 12.333 19M12.333 19V22M8.33301 22H16.333M12.333 15C10.6762 15 9.33301 13.6569 9.33301 12V5C9.33301 3.34315 10.6762 2 12.333 2C13.9899 2 15.333 3.34315 15.333 5V12C15.333 13.6569 13.9899 15 12.333 15Z"
                    stroke="#EDB561"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </Dialog.Trigger>
            <RecordAudioModal collection={collection} />
          </Dialog.Root>
          {/* ADD DESCRIPTION */}
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <button
                defaultValue={collection.description}
                className="flex items-center justify-center bg-primary-200 bg-opacity-10 rounded-md px-[10px] py-[5px] hover:opacity-70"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path
                    d="M20.3955 19.7062H12.9453M3.16699 20.1719L8.33486 18.1842C8.6654 18.0571 8.83068 17.9935 8.9853 17.9105C9.12265 17.8368 9.25358 17.7517 9.37674 17.6561C9.51538 17.5485 9.6406 17.4233 9.89102 17.1729L20.3955 6.66844C21.4242 5.63979 21.4242 3.97202 20.3955 2.94336C19.3669 1.91471 17.6991 1.91471 16.6704 2.94336L6.16594 13.4478C5.91552 13.6983 5.79031 13.8235 5.68272 13.9621C5.58715 14.0853 5.50206 14.2162 5.42833 14.3535C5.34533 14.5082 5.28176 14.6734 5.15463 15.004L3.16699 20.1719ZM3.16699 20.1719L5.08366 15.1886C5.22081 14.832 5.28939 14.6537 5.40701 14.572C5.50981 14.5006 5.637 14.4737 5.75992 14.4971C5.90058 14.524 6.03566 14.6591 6.30582 14.9292L8.40964 17.0331C8.6798 17.3032 8.81488 17.4383 8.84174 17.579C8.86522 17.7019 8.83822 17.8291 8.76685 17.9319C8.68518 18.0495 8.50688 18.1181 8.15028 18.2552L3.16699 20.1719Z"
                    stroke="#902687"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </Dialog.Trigger>
            <TextEditorModal collection={collection} />
          </Dialog.Root>
          {/* ADD PHOTO AND VIDEO */}
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <button className="flex items-center justify-center bg-primary-200 bg-opacity-10 rounded-md px-[10px] py-[5px] hover:opacity-70">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M2 8.37722C2 8.0269 2 7.85174 2.01462 7.70421C2.1556 6.28127 3.28127 5.1556 4.70421 5.01462C4.85174 5 5.03636 5 5.40558 5C5.54785 5 5.61899 5 5.67939 4.99634C6.45061 4.94963 7.12595 4.46288 7.41414 3.746C7.43671 3.68986 7.45781 3.62657 7.5 3.5C7.54219 3.37343 7.56329 3.31014 7.58586 3.254C7.87405 2.53712 8.54939 2.05037 9.32061 2.00366C9.38101 2 9.44772 2 9.58114 2H14.4189C14.5523 2 14.619 2 14.6794 2.00366C15.4506 2.05037 16.126 2.53712 16.4141 3.254C16.4367 3.31014 16.4578 3.37343 16.5 3.5C16.5422 3.62657 16.5633 3.68986 16.5859 3.746C16.874 4.46288 17.5494 4.94963 18.3206 4.99634C18.381 5 18.4521 5 18.5944 5C18.9636 5 19.1483 5 19.2958 5.01462C20.7187 5.1556 21.8444 6.28127 21.9854 7.70421C22 7.85174 22 8.0269 22 8.37722V16.2C22 17.8802 22 18.7202 21.673 19.362C21.3854 19.9265 20.9265 20.3854 20.362 20.673C19.7202 21 18.8802 21 17.2 21H6.8C5.11984 21 4.27976 21 3.63803 20.673C3.07354 20.3854 2.6146 19.9265 2.32698 19.362C2 18.7202 2 17.8802 2 16.2V8.37722Z"
                    stroke="#E8228B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 16.5C14.2091 16.5 16 14.7091 16 12.5C16 10.2909 14.2091 8.5 12 8.5C9.79086 8.5 8 10.2909 8 12.5C8 14.7091 9.79086 16.5 12 16.5Z"
                    stroke="#E8228B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </Dialog.Trigger>
            <ShareInsightPhotoOrVideoModal collection={collection} />
          </Dialog.Root>
        </div>
      </div>

      {creatingInsight.collections.length > 1 && (
        <Popover.Root>
          <Popover.Trigger asChild>
            <button className="absolute top-1 right-2 hover:opacity-70">
              <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Frame">
                  <path
                    id="Vector"
                    d="M14.3752 24C14.3752 26.651 12.2299 28.8 9.58359 28.8C6.93726 28.8 4.79199 26.651 4.79199 24C4.79199 21.349 6.93726 19.2 9.58359 19.2C12.2299 19.2 14.3752 21.349 14.3752 24Z"
                    fill="#737373"
                  />
                  <path
                    id="Vector_2"
                    d="M28.7492 24C28.7492 26.651 26.604 28.8 23.9576 28.8C21.3113 28.8 19.166 26.651 19.166 24C19.166 21.349 21.3113 19.2 23.9576 19.2C26.604 19.2 28.7492 21.349 28.7492 24Z"
                    fill="#737373"
                  />
                  <path
                    id="Vector_3"
                    d="M38.3326 28.8C40.979 28.8 43.1242 26.651 43.1242 24C43.1242 21.349 40.979 19.2 38.3326 19.2C35.6862 19.2 33.541 21.349 33.541 24C33.541 26.651 35.6862 28.8 38.3326 28.8Z"
                    fill="#737373"
                  />
                </g>
              </svg>
            </button>
          </Popover.Trigger>
          <Popover.Content sideOffset={0} align="end">
            <Popover.Close hidden />
            <div className="z-50 bg-white p-4 rounded shadow-md">
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <button className="text-sm hover:opacity-70 flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 31 30" fill="none">
                      <path
                        d="M20.5 7.5V6.5C20.5 5.09987 20.5 4.3998 20.2275 3.86502C19.9878 3.39462 19.6054 3.01217 19.135 2.77248C18.6002 2.5 17.9001 2.5 16.5 2.5H14.5C13.0999 2.5 12.3998 2.5 11.865 2.77248C11.3946 3.01217 11.0122 3.39462 10.7725 3.86502C10.5 4.3998 10.5 5.09987 10.5 6.5V7.5M13 14.375V20.625M18 14.375V20.625M4.25 7.5H26.75M24.25 7.5V21.5C24.25 23.6002 24.25 24.6503 23.8413 25.4525C23.4817 26.1581 22.9081 26.7317 22.2025 27.0913C21.4003 27.5 20.3502 27.5 18.25 27.5H12.75C10.6498 27.5 9.5997 27.5 8.79754 27.0913C8.09193 26.7317 7.51825 26.1581 7.15873 25.4525C6.75 24.6503 6.75 23.6002 6.75 21.5V7.5"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p>{t('delete_collection')}</p>
                  </button>
                </Dialog.Trigger>
                <ConfirmActionModal
                  id={collection.uuid}
                  handleDelete={handleDeleteCollection}
                  title={t('delete_post')}
                  description={t('are_you_sure_you_want_to_delete_this_post')}
                />
              </Dialog.Root>
            </div>
          </Popover.Content>
        </Popover.Root>
      )}
    </div>
  );
}
