/* eslint-disable jsx-a11y/media-has-caption */
import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useRef, useState } from 'react';

import { type CoverPhoto } from '@hooks/useInsights';

interface ImagePreviewModalProps {
  collectionsMedia: CoverPhoto[];
  index: number;
}

export default function ImagePreviewModal({ collectionsMedia, index }: Readonly<ImagePreviewModalProps>) {
  const closeButton = useRef<HTMLButtonElement>(null);
  const [currentIndex, setCurrentIndex] = useState(index);
  const [media, setMedia] = useState(collectionsMedia[index]);
  const videoRef = useRef<any>(null);

  const handlNextImage = (e: any) => {
    if (e.key === 'ArrowRight' && currentIndex < collectionsMedia.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    }
    if (e.key === 'ArrowLeft' && currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  useEffect(() => {
    setMedia(collectionsMedia[currentIndex]);
  }, [collectionsMedia, currentIndex]);

  useEffect(() => {
    if (videoRef?.current && media?.type === 'video') {
      videoRef?.current?.requestFullscreen?.().then(() => videoRef?.current?.play());
    }
  }, [videoRef, media, currentIndex]);

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.5)',
          zIndex: '20',
        }}
      />
      <Dialog.Content
        forceMount
        onKeyDown={handlNextImage}
        className={
          'z-40 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed flex flex-col items-center gap-5 rounded-lg font-poppins desktop:w-[60vw] tablet:w-[80vw] h-[60vh]'
        }
      >
        <Dialog.Close ref={closeButton} className={'absolute top-[-50px] right-[-50px] z[1]'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
            <path
              d="M12.4504 9.62191C11.6693 8.84087 10.403 8.84087 9.62191 9.62191C8.84087 10.403 8.84087 11.6693 9.62191 12.4504L21.1714 23.9998L9.62203 35.5492C8.84099 36.3302 8.84099 37.5966 9.62203 38.3776C10.4031 39.1586 11.6694 39.1586 12.4505 38.3776L23.9998 26.8282L35.5492 38.3776C36.3302 39.1586 37.5965 39.1586 38.3776 38.3776C39.1586 37.5966 39.1586 36.3302 38.3776 35.5492L26.8281 23.9998L38.3777 12.4504C39.1587 11.6693 39.1587 10.403 38.3777 9.62191C37.5967 8.84087 36.3303 8.84087 35.5493 9.62191L23.9998 21.1714L12.4504 9.62191Z"
              fill="white"
            />
          </svg>
        </Dialog.Close>
        <div className="w-full h-full flex items-center justify-center">
          {media?.type === 'image' && (
            <img alt="Preview" className="w-full h-full object-contain" src={media.thumbnail1600} />
          )}
          {media?.type === 'video' && (
            <video
              controls
              autoPlay
              src={media.url}
              className="h-full max-h-full max-w-full object-contain"
              ref={videoRef}
            />
          )}
          {media?.type === 'audio' && (
            <span className="w-full h-full bg-primary-200 flex flex-col items-center justify-center relative">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                <path
                  d="M4.6875 32.8125V17.1875C4.6875 16.3246 3.98794 15.625 3.125 15.625C2.26206 15.625 1.5625 16.3246 1.5625 17.1875V32.8125C1.5625 33.6754 2.26206 34.375 3.125 34.375C3.98794 34.375 4.6875 33.6754 4.6875 32.8125Z"
                  fill="#EDB561"
                />
                <path
                  d="M39.8438 32.8125V17.1875C39.8438 16.3246 39.1442 15.625 38.2812 15.625C37.4183 15.625 36.7188 16.3246 36.7188 17.1875V32.8125C36.7188 33.6754 37.4183 34.375 38.2812 34.375C39.1442 34.375 39.8438 33.6754 39.8438 32.8125Z"
                  fill="#EDB561"
                />
                <path
                  d="M13.2812 39.0625V10.1562C13.2812 9.29331 12.5817 8.59375 11.7188 8.59375C10.8558 8.59375 10.1562 9.29331 10.1562 10.1563V39.0625C10.1562 39.9254 10.8558 40.625 11.7188 40.625C12.5817 40.625 13.2812 39.9254 13.2812 39.0625Z"
                  fill="#EDB561"
                />
                <path
                  d="M31.25 39.0625V10.9375C31.25 10.0746 30.5504 9.375 29.6875 9.375C28.8246 9.375 28.125 10.0746 28.125 10.9375V39.0625C28.125 39.9254 28.8246 40.625 29.6875 40.625C30.5504 40.625 31.25 39.9254 31.25 39.0625Z"
                  fill="#EDB561"
                />
                <path
                  d="M48.4375 39.0625V10.9375C48.4375 10.0746 47.7379 9.375 46.875 9.375C46.0121 9.375 45.3125 10.0746 45.3125 10.9375V39.0625C45.3125 39.9254 46.0121 40.625 46.875 40.625C47.7379 40.625 48.4375 39.9254 48.4375 39.0625Z"
                  fill="#EDB561"
                />
                <path
                  d="M21.875 46.875V3.125C21.875 2.26206 21.1754 1.5625 20.3125 1.5625C19.4496 1.5625 18.75 2.26206 18.75 3.125V46.875C18.75 47.7379 19.4496 48.4375 20.3125 48.4375C21.1754 48.4375 21.875 47.7379 21.875 46.875Z"
                  fill="#EDB561"
                />
              </svg>
              <audio
                controls
                controlsList="nodownload"
                className="w-full h-5 absolute bottom-1 left-0 customized-audio-tag"
                src={media.url}
              />
            </span>
          )}

          {currentIndex < collectionsMedia.length - 1 && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setCurrentIndex((prev) => prev + 1);
              }}
              className="absolute top-[50%] -translate-y-[50%] right-[-50px]"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="52" viewBox="0 0 30 52" fill="none">
                <path
                  d="M1.90735e-06 47.5048L4.49522 52L30 26.4952L4.49522 0.990437L1.90735e-06 5.48565L20.9777 26.4952L1.90735e-06 47.5048Z"
                  fill="white"
                />
              </svg>
            </button>
          )}

          {currentIndex > 0 && (
            <button
              onClick={() => setCurrentIndex((prev) => prev - 1)}
              className="absolute top-[50%] -translate-y-[50%] left-[-50px]"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="52" viewBox="0 0 30 52" fill="none">
                <path
                  d="M30 4.49522L25.5048 0L0 25.5048L25.5048 51.0096L30 46.5143L9.02232 25.5048L30 4.49522Z"
                  fill="white"
                />
              </svg>
            </button>
          )}
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
