import * as Dialog from '@radix-ui/react-dialog';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useCategory } from '@hooks/useCategory';
import { useInsights } from '@hooks/useInsights';

import CreateOrEditCategoryModal from '@components/CreateOrEditCategoryModal';
import NoCategories from '@components/EmptyStates/NoCategories';
import { useTranslation } from 'react-i18next';
import CategorieListComponent from './CategoriesListComponent';

import loadingGif from '@assets/icons/loading.gif';

export default function ShareInsightCategories() {
  const { t } = useTranslation();
  const [isInsightValid, setIsInsightValid] = useState(false);
  const [search, setSearch] = useState('');
  const [isSubbmitting, setIsSubmitting] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);

  const { creatingInsight } = useInsights();
  const { getAllCategories, categories } = useCategory();

  const searchInput = useRef<HTMLInputElement>(null);

  const getCategories = useCallback(async () => {
    setLoadingCategories(true);
    if (creatingInsight.groupUuid) {
      await getAllCategories(creatingInsight.groupUuid, '?take=10000&sortBy=created_at&sortDirection=desc');
    }
    setLoadingCategories(false);
  }, [creatingInsight.groupUuid, getAllCategories]);

  useEffect(() => {
    if (creatingInsight.title && creatingInsight.collections.length > 0 && creatingInsight.groupUuid)
      setIsInsightValid(true);
    else return;

    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = '';

      const confirmationMessage = t('are_you_sure_you_want_to_leave_all_unsaved_data_will_be_lost');
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [creatingInsight, t]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAllCategories(creatingInsight?.groupUuid, '?take=10000&sortBy=created_at&sortDirection=desc&search=' + search);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [creatingInsight?.groupUuid, getAllCategories, search]);

  return (
    <>
      {!isInsightValid ? (
        <></>
      ) : (
        <div className="flex justify-center w-full min-h-[calc(100vh-88px)] bg-backgray py-14">
          <div className="flex flex-col bg-white desktop:w-[50vw] tablet:w-[70vw] py-10 px-20 rounded-md">
            <h1 className="text-xl font-medium mb-5">{t('where_would_you_like_to_share_this_insight')}</h1>
            <div className="flex items-center border border-primary-200 bg-grey-600 rounded-md px-5 py-5 w-full mb-5">
              <svg
                className="cursor-pointer"
                onClick={() => searchInput.current?.focus()}
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M18 17.5L13 12.5M14.6667 8.33333C14.6667 11.555 12.055 14.1667 8.83333 14.1667C5.61167 14.1667 3 11.555 3 8.33333C3 5.11167 5.61167 2.5 8.83333 2.5C12.055 2.5 14.6667 5.11167 14.6667 8.33333Z"
                  stroke="#282A74"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                ref={searchInput}
                type="text"
                placeholder={t('search_for_name')}
                className="bg-transparent outline-none border-none w-full h-full ml-2 text-sm"
              />
            </div>
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <button
                  disabled={isSubbmitting}
                  data-test="create-insight-new-category"
                  className="border border-primary-200 flex items-center justify-center w-[150px] rounded-md py-1 px-2 gap-1 ml-auto hover:opacity-70"
                >
                  <p className="text-xs font-medium text-primary-200">{t('new_category')}</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M10.0001 4.16699V15.8337M4.16675 10.0003H15.8334"
                      stroke="#282A74"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </Dialog.Trigger>
              <CreateOrEditCategoryModal
                fullScreen
                groupId={creatingInsight?.groupUuid}
                isSubbmitting={isSubbmitting}
                setIsSubmitting={setIsSubmitting}
                isShareInsight
              />
            </Dialog.Root>
            <div className="flex flex-col mt-5 w-full p-0 max-h-[400px] overflow-auto" id="tag-list">
              {loadingCategories ? (
                <div className="flex items-center justify-center py-5">
                  <img src={loadingGif} alt="Loading" className="w-12" />
                </div>
              ) : (
                <>
                  {categories?.length === 0 && <NoCategories noButton />}
                  {categories &&
                    categories.map((category) => <CategorieListComponent key={category.uuid} category={category} />)}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
