import loading from '@assets/icons/loading.gif';

export default function Loading() {
  return (
    <>
      <div className="w-[calc(100vw-200px)] h-[calc(100vh-88px)] ml-[200px] mt-[88px] flex justify-center items-center fixed inset-0">
        <img src={loading} alt="Loading" className="w-12" />
      </div>
    </>
  );
}
