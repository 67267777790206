import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useCalendar } from '@hooks/useCalendar';
import type { Category } from '@hooks/useCategory';
import { useGroups } from '@hooks/useGroups';
import { useInsights } from '@hooks/useInsights';
import { useTags } from '@hooks/useTags';
import { useUser } from '@hooks/useUser';

import { Button } from '@components/Button';
import Calendar from '@components/Calendar';
import NoCategories from '@components/EmptyStates/NoCategories';
import Loading from '@components/Loading';
import MonthSchedule from '@components/MonthSchedule';
import NoTags from '@components/NoTags';
import PostFeed from '@components/PostFeed';

import loadingGif from '@assets/icons/loading.gif';
import userNoPhoto from '@assets/icons/user-no-photo.svg';
import inputImage from '@assets/input-image.png';

import EmptyFeed from '@components/EmptyStates/EmptyFeed';
import ImageViewer from '@components/ImageViewer';

import { useTranslation } from 'react-i18next';

import { getDefaultImages } from 'utils/auxFunctions';
import { handleMessageError } from 'utils/handleMessageError';
import { getCategoryImage } from 'utils/icons';

export default function GroupInformation() {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<'categories' | 'calendar' | 'tags'>('categories');
  const [loading, setLoading] = useState(true);
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [page, setPage] = useState<{ current: number; next: number[]; previous: number[] }>();

  const [, setSelectedDay] = useState<string | null>(null);

  const [, setMonthSelected] = useState('');

  const [, setYearSelected] = useState('');
  const [categoryList, setCategoryList] = useState<Category[]>([]);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const getFullDate = useMemo(() => new Date(), []);
  const [monthNow, setMonthNow] = useState((getFullDate.getMonth() + 1).toString().padStart(2, '0'));

  const [searchCategories, setSearchCategories] = useState('');
  const [isSearchingTags, setIsSearchingTags] = useState(false);
  const [searchTags, setSearchTags] = useState('');
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [loadingTagInsights, setLoadingTagInsights] = useState(false);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [loadingFeed, setLoadingFeed] = useState(false);

  const { getUserCalendar, userCalendar, getUserCalendarByDay, userCalendarByDay, setUserCalendarByDay } =
    useCalendar();
  const { getGroupByUuid, group } = useGroups();
  const { getAllTagsForGroup, tags, tagsPagination } = useTags();
  const { user } = useUser();
  const { getUserFeed, insights, pagination } = useInsights();

  const [searchParams] = useSearchParams();

  const tagUuid = searchParams.get('tag');

  const navigate = useNavigate();

  const { groupId } = useParams();

  const getGroup = useCallback(async () => {
    if (groupId) {
      await Promise.all([
        getGroupByUuid(groupId),
        getAllTagsForGroup(groupId, '?take=10&sortBy=created_at&sortDirection=desc'),
      ]).then((data) => {
        const group = data[0];
        if (group?.uuid) {
          setCategoryList(group?.categories_parent || []);
          setLoading(false);
        }
      });
    }
  }, [getAllTagsForGroup, getGroupByUuid, groupId]);

  const getCalendar = useCallback(
    async (year: string, month: string) => {
      setLoadingCalendar(true);
      try {
        await getUserCalendar(`year=${year}&month=${month}`);
      } catch (err) {
        const message = handleMessageError(err);
        console.error(message);
      } finally {
        setLoadingCalendar(false);
      }
    },
    [getUserCalendar],
  );
  const getCalendarByDay = useCallback(
    async (year: string, month: string, day: string) => {
      setLoadingCalendar(true);
      try {
        await getUserCalendarByDay(`year=${year}&month=${month}&day=${day}`);
      } catch (err) {
        const message = handleMessageError(err);
        console.error(message);
      } finally {
        setLoadingCalendar(false);
      }
    },
    [getUserCalendarByDay],
  );

  const handleDateSelect = useCallback(
    (day: string) => {
      setSelectedDay(day);
      getCalendarByDay(getFullDate.getFullYear().toString(), monthNow, day);
    },
    [getCalendarByDay, getFullDate, monthNow],
  );
  const handleDateChange = (newDate: Date | null) => {
    setSelectedDate(newDate);
  };

  const handleMonthYearChange = (month: string, year: string) => {
    setMonthSelected(month);
    setYearSelected(year);

    getCalendar(year, month);
    getCalendarByDay(year, month, '');

    setMonthNow(month);
  };

  const handleSelectPage = useCallback(
    async (selectedPage: number) => {
      if (!groupId) return;
      setIsLoadingTags(true);
      await getAllTagsForGroup(
        groupId,
        '?take=10&sortBy=created_at&sortDirection=desc&search=' + searchTags + '&page=' + selectedPage,
      );
      setIsLoadingTags(false);
    },
    [groupId, getAllTagsForGroup, searchTags],
  );

  const getFeedForUser = useCallback(
    async (first: boolean) => {
      setLoadingTagInsights(true);
      await getUserFeed(first, `?sortBy=updated_at&sortDirection=desc&page=1&tag=${selectedTag}&take=10`);
      setLoadingTagInsights(false);
    },
    [getUserFeed, selectedTag],
  );

  useEffect(() => {
    handleDateChange(selectedDate);
    if (selectedDate) {
      const yearParam = selectedDate.getFullYear().toString();
      const monthParam = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
      getCalendar(yearParam, monthParam);
      getCalendarByDay(yearParam, monthParam, '');
    }
  }, [getCalendar, getCalendarByDay, selectedDate]);

  useEffect(() => {
    getGroup();
    setUserCalendarByDay([]);
    setMonthSelected(monthNow);
    setYearSelected(getFullDate.getFullYear().toString());

    getCalendar(getFullDate.getFullYear().toString(), monthNow);
    getCalendarByDay(getFullDate.getFullYear().toString(), monthNow, '');
  }, [getCalendar, getCalendarByDay, getFullDate, getGroup, monthNow, setUserCalendarByDay]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchCategories) {
        setCategoryList(
          group?.categories_parent?.filter((category) =>
            category?.name?.toLowerCase?.()?.includes?.(searchCategories?.toLowerCase?.()),
          ) || [],
        );
      } else {
        setCategoryList(group?.categories_parent || []);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchCategories, categoryList, group?.categories_parent]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (!isSearchingTags || !groupId) return;
      await getAllTagsForGroup(groupId, '?take=10&sortBy=created_at&sortDirection=desc&search=' + searchTags);
      setIsSearchingTags(false);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTags, isSearchingTags, groupId, getAllTagsForGroup]);

  useEffect(() => {
    let current = 0;
    const next = [];
    const previous = [];
    if (tagsPagination) {
      for (let i = 1; i <= tagsPagination.last_page; i++) {
        if (tagsPagination.current_page === i) {
          current = i;
        }
        if (tagsPagination.current_page > i) {
          previous.push(i);
        }
        if (tagsPagination.current_page < i) {
          next.push(i);
        }
      }
    }
    setPage({ current: current, next: next, previous: previous });
  }, [tagsPagination]);

  useEffect(() => {
    if (!selectedTag) return;
    getFeedForUser(true);
  }, [getFeedForUser, selectedTag]);

  useEffect(() => {
    if (tagUuid) {
      setSelected('tags');
      setSelectedTag(tagUuid);
    }
  }, [tagUuid]);

  useEffect(() => {
    if (group && group?.role?.toLowerCase().includes('visitant')) {
      console.log(group?.role);
      navigate('/');
    }
  }, [group]);

  useEffect(() => {
    const onScroll = async () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 10 >= document.documentElement.offsetHeight &&
        !loadingFeed &&
        selected === 'tags' &&
        selectedTag &&
        pagination.current_page < pagination.last_page
      ) {
        setLoadingFeed(true);
        await getUserFeed(
          false,
          `?sortBy=updated_at&sortDirection=desc&page=${pagination.current_page + 1}&tag=${selectedTag}&take=10`,
        );
        setLoadingFeed(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [pagination, selected, loadingFeed, selectedTag, getUserFeed]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          {group && (
            <>
              <header className="relative flex flex-col items-center w-full">
                <div className="flex flex-col items-center gap-5 w-full h-[271px]">
                  <ImageViewer
                    className="w-full h-full object-cover"
                    url={group.thumbnail1600 || ''}
                    previewImage={inputImage}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = getDefaultImages('group-default-1.png');
                    }}
                  />
                </div>
                <button onClick={() => navigate('edit')} className="absolute top-6 right-10 hover:opacity-70">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <rect width="47.9159" height="48" rx="23.958" fill="#282A74" fillOpacity="1" />
                    <path
                      d="M14.3752 24.0002C14.3752 26.6512 12.2299 28.8002 9.58359 28.8002C6.93726 28.8002 4.79199 26.6512 4.79199 24.0002C4.79199 21.3492 6.93726 19.2002 9.58359 19.2002C12.2299 19.2002 14.3752 21.3492 14.3752 24.0002Z"
                      fill="#fff"
                    />
                    <path
                      d="M28.7492 24.0002C28.7492 26.6512 26.604 28.8002 23.9576 28.8002C21.3113 28.8002 19.166 26.6512 19.166 24.0002C19.166 21.3492 21.3113 19.2002 23.9576 19.2002C26.604 19.2002 28.7492 21.3492 28.7492 24.0002Z"
                      fill="#fff"
                    />
                    <path
                      d="M38.3326 28.8002C40.979 28.8002 43.1242 26.6512 43.1242 24.0002C43.1242 21.3492 40.979 19.2002 38.3326 19.2002C35.6862 19.2002 33.541 21.3492 33.541 24.0002C33.541 26.6512 35.6862 28.8002 38.3326 28.8002Z"
                      fill="#fff"
                    />
                  </svg>
                </button>
              </header>

              <div className="flex flex-col w-full mt-2">
                <div className="px-7 py-5">
                  <h2 className="text-black-100 font-bold text-2xl">{group.name}</h2>

                  <div className="flex items-center gap-5">
                    <p className="text-lg text-black-100">
                      {user?.language === 'en'
                        ? `${group.user_created?.first_name || ''}'s Group`
                        : `${t('group_of').replace(':name', group.user_created?.first_name || '')}`}{' '}
                    </p>
                    <p className="text-lg font-normal">
                      <b>
                        {group.users_count && group.users_count > 1
                          ? `${group.users_count}`
                          : `${group.users_count ? group.users_count : '1'}`}
                      </b>{' '}
                      {t('members')}
                    </p>
                    {group.last6Members && (
                      <span className="flex justify-center w-fit min-w-12">
                        {group.last6Members.map((member, index) => {
                          if (index < 3) {
                            return (
                              group.name !== 'Group Family Wisdom' && (
                                <img
                                  alt="member"
                                  key={index}
                                  src={member.image || userNoPhoto}
                                  className={`min-w-12 min-h-12 max-w-12 max-h-12 rounded-full ${
                                    group.last6Members && group.last6Members.length > 1 && index > 0 ? 'ml-[-20px]' : ''
                                  }`}
                                />
                              )
                            );
                          } else if (index === 3) {
                            return (
                              <div
                                key={index}
                                className={`relative min-w-12 min-h-12 max-w-12 max-h-12 rounded-full ${
                                  group.last6Members && group.last6Members.length > 1 && index > 0 ? 'ml-[-20px]' : ''
                                } ${group.name !== 'Group Family Wisdom' ? 'bg-black-100' : 'bg-primary-200'}`}
                              >
                                {group.name !== 'Group Family Wisdom' && (
                                  <img
                                    alt="more members"
                                    src={member.image || userNoPhoto}
                                    className={`min-w-12 min-h-12 max-w-12 max-h-12 rounded-full ${
                                      group?.users_count && group?.users_count > 4 && 'brightness-50'
                                    }`}
                                  />
                                )}
                                {group?.users_count && group?.users_count > 4 && (
                                  <span className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-xs">
                                    +{group?.users_count ? group?.users_count - 4 : ''}
                                  </span>
                                )}
                              </div>
                            );
                          }
                        })}
                      </span>
                    )}
                  </div>

                  <div className="flex items-center gap-2 w-full mt-2">
                    <button
                      onClick={() => navigate('edit')}
                      className="flex items-center gap-1 bg-primary-200 shadow-sm h-[30px] px-5 rounded-md hover:opacity-70"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path
                          d="M14.5 17.3333H18.6667V15.6667C18.6667 14.2859 17.5474 13.1667 16.1667 13.1667C15.3703 13.1667 14.6609 13.539 14.2032 14.1191M14.5 17.3333H6.16667M14.5 17.3333V15.6667C14.5 15.1198 14.3947 14.5975 14.2032 14.1191M14.2032 14.1191C13.5888 12.5842 12.0877 11.5 10.3333 11.5C8.57902 11.5 7.07792 12.5842 6.46354 14.1191M6.16667 17.3333H2V15.6667C2 14.2859 3.11929 13.1667 4.5 13.1667C5.29634 13.1667 6.00572 13.539 6.46354 14.1191M6.16667 17.3333V15.6667C6.16667 15.1198 6.27202 14.5975 6.46354 14.1191M12.8333 6.5C12.8333 7.88071 11.7141 9 10.3333 9C8.95258 9 7.83333 7.88071 7.83333 6.5C7.83333 5.11929 8.95258 4 10.3333 4C11.7141 4 12.8333 5.11929 12.8333 6.5ZM17.8333 9C17.8333 9.9205 17.0872 10.6667 16.1667 10.6667C15.2462 10.6667 14.5 9.9205 14.5 9C14.5 8.07952 15.2462 7.33333 16.1667 7.33333C17.0872 7.33333 17.8333 8.07952 17.8333 9ZM6.16667 9C6.16667 9.9205 5.42047 10.6667 4.5 10.6667C3.57952 10.6667 2.83333 9.9205 2.83333 9C2.83333 8.07952 3.57952 7.33333 4.5 7.33333C5.42047 7.33333 6.16667 8.07952 6.16667 9Z"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="px-2 text-xs text-center text-white">{t('manage')}</span>
                    </button>

                    {group?.role === 'Admin' && (
                      <button
                        onClick={() => navigate('invite')}
                        className="flex items-center gap-1 bg-primary-200 shadow-sm h-[30px] px-3 rounded-md hover:opacity-70"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            d="M14 17H4ZM14 17V15.25C14 14.6758 13.8736 14.1274 13.6438 13.625M4 17V15.25C4 14.6758 4.12642 14.1274 4.35625 13.625M4.35625 13.625C5.0935 12.0134 6.89482 10.875 9 10.875C11.1052 10.875 12.9065 12.0134 13.6438 13.625M12 5.625C12 7.07474 10.6569 8.25 9 8.25C7.3431 8.25 6 7.07474 6 5.625C6 4.17526 7.3431 3 9 3C10.6569 3 12 4.17526 12 5.625Z"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.5837 7.5C14.7678 7.5 14.917 7.64924 14.917 7.83333V8.83333H15.917C16.1011 8.83333 16.2503 8.98257 16.2503 9.16667C16.2503 9.35077 16.1011 9.5 15.917 9.5H14.917V10.5C14.917 10.6841 14.7678 10.8333 14.5837 10.8333C14.3996 10.8333 14.2503 10.6841 14.2503 10.5V9.5H13.2503C13.0662 9.5 12.917 9.35077 12.917 9.16667C12.917 8.98257 13.0662 8.83333 13.2503 8.83333H14.2503V7.83333C14.2503 7.64924 14.3996 7.5 14.5837 7.5Z"
                            fill="white"
                          />
                        </svg>
                        <span className="px-2 text-xs text-center text-white">{t('invite')}</span>
                      </button>
                    )}
                  </div>
                </div>

                {/* CATEGORIE/CALENDAR/TAG */}
                <div className="grid grid-cols-3 w-full mt-3">
                  <button
                    onClick={() => setSelected('categories')}
                    className={`flex items-center justify-center py-3 ${
                      selected === 'categories' ? 'border-b-2 border-grey-150' : ''
                    }`}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                      <g clipPath="url(#clip0_2338_47419)">
                        <path
                          d="M20.375 4H4.625C3.17525 4 2 4.89543 2 6V8C2 9.10457 3.17525 10 4.625 10H20.375C21.8247 10 23 9.10457 23 8V6C23 4.89543 21.8247 4 20.375 4Z"
                          stroke="#282A74"
                          strokeOpacity="0.6"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.375 15H4.625C3.17525 15 2 15.8954 2 17V19C2 20.1046 3.17525 21 4.625 21H20.375C21.8247 21 23 20.1046 23 19V17C23 15.8954 21.8247 15 20.375 15Z"
                          stroke="#282A74"
                          strokeOpacity="0.6"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2338_47419">
                          <rect width="25" height="25" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  <button
                    onClick={() => setSelected('calendar')}
                    className={`flex items-center justify-center py-3 ${
                      selected === 'calendar' ? 'border-b-2 border-grey-150' : ''
                    }`}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                      <path
                        d="M8.33333 7.29167V3.125M16.6667 7.29167V3.125M7.29167 11.4583H17.7083M5.20833 21.875H19.7917C20.9423 21.875 21.875 20.9423 21.875 19.7917V7.29167C21.875 6.14107 20.9423 5.20833 19.7917 5.20833H5.20833C4.05774 5.20833 3.125 6.14107 3.125 7.29167V19.7917C3.125 20.9423 4.05774 21.875 5.20833 21.875Z"
                        stroke="#282A74"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => setSelected('tags')}
                    className={`flex items-center justify-center py-3 ${
                      selected === 'tags' ? 'border-b-2 border-grey-150' : ''
                    }`}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                      <g opacity="0.6">
                        <path
                          d="M5.20801 9.37508H19.7913M5.20801 15.6251H19.7913M11.458 4.16675L7.29134 20.8334M17.708 4.16675L13.5413 20.8334"
                          stroke="#282A74"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
              </div>

              {/* CATEGORIES  AND EVENTS*/}
              {selected === 'categories' ? (
                <div className="flex flex-col bg-backgray py-5 px-5 gap-10 min-h-[500px]">
                  <div
                    className={`flex items-center relative w-[300px] py-1 ml-5 border border-primary-200 rounded-md`}
                  >
                    <input
                      onChange={(e) => setSearchCategories(e.target.value)}
                      value={searchCategories}
                      placeholder={t('search_for_name')}
                      className="bg-backgray outline-none w-full h-full py-1 rounded-md px-5 pr-8"
                      type="text"
                    />
                    <svg
                      className="absolute right-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M17.5 17.5L12.5 12.5M14.1667 8.33333C14.1667 11.555 11.555 14.1667 8.33333 14.1667C5.11167 14.1667 2.5 11.555 2.5 8.33333C2.5 5.11167 5.11167 2.5 8.33333 2.5C11.555 2.5 14.1667 5.11167 14.1667 8.33333Z"
                        stroke="#282A74"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-wrap bg-backgray gap-12 justify-center">
                    {categoryList?.length === 0 && <NoCategories />}
                    {categoryList &&
                      categoryList.length > 0 &&
                      categoryList.map((category) => {
                        return (
                          <button
                            onClick={() => {
                              navigate('/groups/' + group.uuid + '/category/' + category.uuid);
                            }}
                            key={category.uuid}
                            className="flex flex-col gap-5 py-3 bg-white items-center w-[160px] h-[152px] overflow-hidden p-3 rounded-md shadow-md hover:opacity-80"
                          >
                            <span
                              className="w-[60px] h-[60px] flex items-center justify-center rounded-full"
                              style={{ background: category.icon_color }}
                            >
                              <img
                                alt={category.name}
                                src={getCategoryImage(category.icon)}
                                className="w-10 h-10 svg-white"
                              />
                            </span>
                            <p className="text-grey-150 w-full max-h-[50px] text-center break-words overflow-hidden two-line-paragraph">
                              {category.name}
                            </p>
                          </button>
                        );
                      })}
                  </div>
                </div>
              ) : (
                <>
                  {selected === 'calendar' ? (
                    <div className="flex flex-wrap w-full px-20 py-12 bg-backgray justify-center gap-16">
                      <div className="flex flex-col items-center gap-9">
                        <Calendar
                          type="eventPicker"
                          onMonthYearChange={handleMonthYearChange}
                          onDateSelect={handleDateSelect}
                          userCalendar={userCalendar}
                        />
                        {group?.status !== 'fake' && (
                          <Button
                            size="medium"
                            onClick={() => navigate('/calendar/create-event?group=' + group?.uuid)}
                            text={t('create_an_event')}
                            variant="primary"
                          />
                        )}
                      </div>
                      <div className="w-[370px]">
                        {' '}
                        {loadingCalendar ? (
                          <div className="flex items-center justify-center py-10">
                            <img src={loadingGif} alt="Loading" className="w-12 mt-7" />
                          </div>
                        ) : (
                          <div className="max-h-[550px] overflow-y-auto overflow-x-hidden w-full flex justify-center items-center">
                            <MonthSchedule calendar={userCalendarByDay} />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col bg-backgray py-5 px-10 h-full min-h-[500px]">
                      {/* SEARCH TAGS */}
                      <div
                        className={`flex items-center relative w-[300px] py-1 mb-10 border border-primary-200 rounded-md`}
                      >
                        <input
                          onChange={(e) => {
                            setIsSearchingTags(true);
                            setSearchTags(e.target.value);
                          }}
                          value={searchTags}
                          placeholder={t('search_for_name')}
                          className="bg-backgray outline-none w-full h-full py-1 rounded-md px-5 pr-8"
                          type="text"
                        />
                        <svg
                          className="absolute right-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M17.5 17.5L12.5 12.5M14.1667 8.33333C14.1667 11.555 11.555 14.1667 8.33333 14.1667C5.11167 14.1667 2.5 11.555 2.5 8.33333C2.5 5.11167 5.11167 2.5 8.33333 2.5C11.555 2.5 14.1667 5.11167 14.1667 8.33333Z"
                            stroke="#282A74"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>

                      {/* LIST TAGS */}
                      {tags?.length === 0 && (
                        <div className="flex items-center justify-center w-full">
                          <NoTags />
                        </div>
                      )}
                      <div className="flex items-center gap-[10px] w-full">
                        {page?.previous && page?.previous?.length > 0 && (
                          <button
                            onClick={() => handleSelectPage(page?.current - 1)}
                            className="text-primary-200 text-xl hover:opacity-70 ml-[-20px]"
                          >
                            {'<'}
                          </button>
                        )}
                        <div className="flex flex-wrap items-center gap-[10px]">
                          {isLoadingTags && (
                            <div className="flex items-center justify-center">
                              <img src={loadingGif} className="w-12 h-12" alt="loading" />
                            </div>
                          )}
                          {!isLoadingTags &&
                            tags &&
                            tags.length > 0 &&
                            tags.map((tag) => (
                              <button
                                key={tag.uuid}
                                onClick={() => {
                                  if (selectedTag === tag.uuid) {
                                    setSelectedTag(null);
                                  } else {
                                    setSelectedTag(tag.uuid);
                                  }
                                }}
                                className={`rounded-full border border-primary-200 px-[10px] py-[5px] text-primary-200 text-sm relative hover:opacity-70 ${
                                  selectedTag === tag.uuid ? 'bg-primary-200 text-white' : ''
                                }`}
                              >
                                <p>{tag.name}</p>
                              </button>
                            ))}
                        </div>
                        {page?.next && page?.next?.length > 0 && (
                          <button
                            onClick={() => handleSelectPage(page?.current + 1)}
                            className="text-primary-200 text-xl hover:opacity-70 mr-[-20px]"
                          >
                            {'>'}
                          </button>
                        )}
                      </div>

                      {/* LIST INSIGHTS FOR TAG */}
                      <div className="flex flex-col w-full items-center gap-5 mt-10">
                        {loadingTagInsights && (
                          <div className="flex items-center justify-center mt-5">
                            <img src={loadingGif} className="w-12 h-12" alt="loading" />
                          </div>
                        )}

                        {!loadingTagInsights &&
                          selectedTag &&
                          insights &&
                          insights.length > 0 &&
                          insights.map((insight) => <PostFeed key={insight.uuid} insight={insight} />)}

                        {!loadingTagInsights && selectedTag && insights && insights?.length === 0 && <EmptyFeed />}

                        {loadingFeed && (
                          <div className="flex items-center justify-center">
                            <img src={loadingGif} className="w-12 h-12" alt="loading" />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
