import * as Dialog from '@radix-ui/react-dialog';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/Button';

interface FeedbackModalProps {
  setShowFeedbackModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function FeedbackModal({ setShowFeedbackModal }: FeedbackModalProps) {
  const { t } = useTranslation();
  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '10',
        }}
      />
      <Dialog.Content
        forceMount
        className={
          'z-40 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white px-20 py-10 flex flex-col items-center gap-5 rounded-lg font-poppins'
        }
      >
        <Dialog.Close className={'hidden'}></Dialog.Close>
        <h1 className="text-xl text-black-100 font-semibold">{t('thank_you_for_your_feedback')}</h1>
        <span className="text-sm text-black-100 mb-7">{t('we_will_contact_you_if_we_need_more_info')}.</span>
        <Button onClick={() => setShowFeedbackModal(false)} size="medium" variant="primary" text={t('confirm')} />
      </Dialog.Content>
    </Dialog.Portal>
  );
}
