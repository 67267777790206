import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { animated, config, useTransition } from 'react-spring';

import { useCategory } from '@hooks/useCategory';
import { useGroups } from '@hooks/useGroups';
import { useUser } from '@hooks/useUser';

import SettingsAndPrivacyModal from '@components/SettingsAndPrivacyModal';

import addParticipants from '@assets/icons/Header/add-participants.svg';
import calendar from '@assets/icons/Header/calendar.svg';
import categoryIcon from '@assets/icons/Header/category.svg';
import groupImage from '@assets/icons/Header/group.svg';
import insight from '@assets/icons/Header/insight.svg';
import language from '@assets/icons/Header/language.svg';
import leftArrow from '@assets/icons/Header/left-arrow.svg';
import notifications from '@assets/icons/Header/notifications.svg';
import reportProblem from '@assets/icons/Header/report-problem.svg';
import resetPassword from '@assets/icons/Header/reset-password.svg';
import shareLink from '@assets/icons/Header/share-link.svg';
import subscription from '@assets/icons/Header/subscription.svg';
import userIcon from '@assets/icons/Header/user.svg';

import visitors from '@assets/icons/Header/visitors.svg';
import faqPrimary from '@assets/icons/Profile/SettingsAndPrivacy/faq-primary.svg';
import privacyPrimary from '@assets/icons/Profile/SettingsAndPrivacy/privacy-primary.svg';
import termsPrimary from '@assets/icons/Profile/SettingsAndPrivacy/terms-primary.svg';
import logo from '@assets/logo.svg';
import defaultUserPhoto from '@assets/profiles/Ellipse 9.svg';

import ConfirmReturnHomeModal from '@components/ConfirmReturnHomeModal';
import ImageViewer from '@components/ImageViewer';
import { useTranslation } from 'react-i18next';
import { getCategoryImage } from 'utils/icons';

export default function Header() {
  const { t } = useTranslation();
  const [previousPath, setPreviousPath] = useState('');
  const [path, setPath] = useState('');
  const [openSettings, setOpenSettings] = useState(false);
  const [showReturnFeed, setShowReturnFeed] = useState<
    'groups' | 'insight' | 'calendar' | 'category' | 'insightSteps' | 'ads' | null
  >(null);
  const [showShareInsight, setShowShareInsight] = useState(false);
  const [showCreateAds, setShowCreateAds] = useState(false);

  const location = useLocation();
  const { groupId, categoryId, subCategoryId, topicId, insightUuid, eventUuid, adsUuid } = useParams();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const previousPage = searchParams.get('previous');

  const { group } = useGroups();
  const { category } = useCategory();
  const { user } = useUser();

  const transitions = useTransition(openSettings, {
    from: { opacity: 0, x: 1000 },
    enter: { opacity: 1, x: 0 },
    leave: { opacity: 0, x: -1000 },
    config: config.stiff,
  });

  const pathName: { [path: string]: string } = {
    '/groups': t('groups'),
    '/groups/create': t('start_a_new_group'),
    '/profile': t('profile'),
    '/profile/edit': t('edit_profile'),
    '/profile/report-problem': t('report_a_problem'),
    '/profile/notifications': t('notifications'),
    '/profile/notifications-settings': t('notifications_settings'),
    '/profile/subscription': t('subscription'),
    '/profile/calendar': t('calendar'),
    '/profile/calendar/share': t('calendar'),
    '/profile/calendar/settings': t('calendar'),
    '/profile/language': t('language'),

    '/category/create': t('create_a_category'),

    '/insight/create': t('share_an_insight'),
    '/insight/create/groups': t('share_an_insight'),
    '/insight/create/tags-and-description': t('share_an_insight'),
    '/insight/create/categories': t('share_an_insight'),
    '/insight/create/finish': t('share_an_insight'),

    '/feed': 'Family Wisdom',

    '/notifications': t('notifications'),

    '/privacy-policy': 'Privacy Policy',
    '/terms-of-service': 'Terms of Service',
    '/faq': 'FAQ',

    '/calendar': t('calendar'),
    '/calendar/create-event': t('create_a_new_event'),
    '/calendar/event-information': t('event_information'),
    '/events': t('events'),

    '/dashboard': 'Family Wisdom | Dashboard',
    '/invitation-code': t('invitation_code'),
    '/password/reset-internal': t('reset_password'),
    '/ads/create': t('ads'),
    '/ads': t('ads'),
  };

  // GROUPS
  pathName['/groups/' + groupId] = group.name || '';
  pathName['/groups/' + groupId + '/edit'] = group.name || '';
  pathName['/groups/' + groupId + '/addSubscription'] = t('group_subscription');
  pathName['/groups/' + groupId + '/invite'] = t('add_participants');
  pathName['/groups/' + groupId + '/invite/share'] = t('invite');

  // GROUPS - EDIT
  pathName['/groups/' + groupId + '/edit/participants'] = group.name || '';
  pathName['/groups/' + groupId + '/edit/event-labels'] = t('event_labels');
  pathName['/groups/' + groupId + '/edit/themes'] = t('themes');
  pathName['/groups/' + groupId + '/edit/subscription'] = t('group_subscription');
  pathName['/groups/' + groupId + '/edit/themes/' + categoryId] = category.name || '';
  pathName['/groups/' + groupId + '/edit/themes/' + categoryId + '/' + subCategoryId] = category.name || '';

  // CATEGORIES
  pathName['/groups/' + groupId + '/category/' + categoryId + '/edit'] = t('edit_category');
  pathName['/groups/' + groupId + '/category/' + categoryId] = category.name || '';
  pathName['/groups/' + groupId + '/category/' + categoryId + '/insight'] = category.name || '';
  pathName['/groups/' + groupId + '/category/create'] = t('create_a_category');

  //SUB CATEGORIES
  pathName['/groups/' + groupId + '/category/' + categoryId + '/' + subCategoryId] = category.name || '';
  pathName['/groups/' + groupId + '/category/' + categoryId + '/' + subCategoryId + '/insight'] = category.name || '';
  pathName['/groups/' + groupId + '/category/' + categoryId + '/' + subCategoryId + '/' + topicId] =
    category.name || '';
  pathName['/groups/' + groupId + '/category/' + categoryId + '/' + subCategoryId + '/' + topicId] =
    category.name || '';

  // INSIGHT
  pathName['/insight/edit/' + groupId + '/' + insightUuid] = t('edit_insight');
  pathName['/insight/edit/' + groupId + '/' + insightUuid + '/groups'] = t('edit_insight');
  pathName['/insight/edit/' + groupId + '/' + insightUuid + '/tags-and-description'] = t('edit_insight');
  pathName['/insight/edit/' + groupId + '/' + insightUuid + '/categories'] = t('edit_insight');
  pathName['/insight/edit/' + groupId + '/' + insightUuid + '/finish'] = t('edit_insight');
  pathName['/insight/' + groupId + '/' + insightUuid + '/visitors'] = t('insight_visitors');
  pathName['/groups/' + groupId + '/insight/' + insightUuid] = t('insights');

  // EVENT
  pathName['/calendar/create-event/' + eventUuid] = t('create_a_new_event');
  pathName['/calendar/edit-event/' + eventUuid] = t('edit_event');
  pathName['/calendar/edit-event/' + eventUuid + '/choose-event-label'] = t('edit_event');
  pathName['/events/' + eventUuid] = t('read_event');

  // ADS
  pathName['/ads/edit/' + adsUuid] = t('ads');
  pathName['/ads/statistics/' + adsUuid] = t('ads');

  const previousPaths: { [path: string]: string } = useMemo(() => {
    return {
      '/insight': '/profile',
      '/category': '/profile',
    };
  }, []);

  previousPaths['/groups/' + groupId + '/category'] = '/groups/' + groupId;
  previousPaths['/groups/' + groupId + '/category/' + categoryId + '/subcategory'] =
    '/groups/' + groupId + '/category/' + categoryId;

  previousPaths['/events'] = '/calendar';
  previousPaths['/calendar/edit-event'] = '/calendar';
  previousPaths['/insight/' + groupId + '/' + insightUuid] = '/feed';
  previousPaths['/insight/edit/' + groupId] = '/feed';
  previousPaths['/groups/' + groupId + '/insight'] = '/notifications';
  previousPaths['/insight/create'] = '/insight/create?isEditing=true';

  previousPaths['/ads/edit'] = '/ads';
  previousPaths['/ads/statistics'] = '/ads';

  const icon = (path: string) => {
    if (path.includes('groups')) {
      if (path.includes('category')) {
        if (categoryId && path.includes(categoryId) && !path.includes('edit')) {
          if (subCategoryId && path.includes(subCategoryId)) return '';
          return '@categoryIcon@';
        }
        return categoryIcon;
      }
      if (path.includes('invite')) return addParticipants;
      if (path.includes('event-labels')) return calendar;
      if (path.includes('addSubscription') || path.includes('subscription')) return subscription;
      if (path.includes('themes')) {
        if (path.endsWith('themes')) return categoryIcon;
        return '';
      }
      if (path.includes('insight')) return insight;

      return groupImage;
    } else if (path.includes('profile') || path.includes('feed')) {
      if (path.includes('edit')) return userIcon;
      if (path.includes('report-problem')) return reportProblem;
      if (path.includes('notifications')) return notifications;
      if (path.includes('privacy-policy')) return privacyPrimary;
      if (path.includes('terms-of-service')) return termsPrimary;
      if (path.includes('faq')) return faqPrimary;
      if (path.includes('subscription')) return subscription;
      if (path.includes('calendar')) return calendar;
      if (path.includes('events')) return calendar;
      if (path.includes('language')) return language;
      return logo;
    } else if (path.includes('insight')) {
      if (path.includes('visitors')) {
        return visitors;
      }
      return insight;
    } else if (path.includes('category')) return categoryIcon;
    else if (path.includes('calendar')) return calendar;
    else if (path.includes('events')) return calendar;
    else if (path.includes('notifications')) return notifications;
    else if (path.includes('privacy-policy')) return privacyPrimary;
    else if (path.includes('terms-of-service')) return termsPrimary;
    else if (path.includes('faq')) return faqPrimary;
    else if (path.includes('dashboard')) return logo;
    else if (path.includes('invitation-code')) return shareLink;
    else if (path.includes('reset-internal')) return resetPassword;
    else if (path.includes('ads')) return insight;

    return '';
  };

  useEffect(() => {
    let path = location.pathname;

    if (path.includes('feed')) {
      setShowShareInsight(true);
    } else if (path.includes('ads') && !path.includes('edit') && !path.includes('create')) {
      setShowCreateAds(true);
    } else {
      setShowShareInsight(false);
    }

    if (path.includes('create')) {
      if (path.includes('groups') && !path.includes('insight')) {
        if (path.includes('category')) setShowReturnFeed('category');
        else setShowReturnFeed('groups');
      } else if (path.includes('insight')) {
        if (
          !path.includes('groups') &&
          !path.includes('categories') &&
          !path.includes('tags-and-description') &&
          !path.includes('finish')
        )
          setShowReturnFeed('insight');
        else setShowReturnFeed('insightSteps');
      } else if (path.includes('calendar')) {
        if (eventUuid) setShowReturnFeed(null);
        else setShowReturnFeed('calendar');
      } else if (path.includes('ads')) {
        setShowReturnFeed('ads');
      } else setShowReturnFeed(null);
    } else {
      setShowReturnFeed(null);
    }

    if (path.endsWith('/')) path = path.slice(0, -1);
    setPath(path);
    const lastIndex = path.lastIndexOf('/');
    if (previousPage) {
      if (previousPage === 'insightGroups') {
        setPreviousPath('/insight/create/groups');
      } else if (previousPage === 'insightTagsAndDescription') {
        setPreviousPath('/insight/create/tags-and-description?previous=insightGroups');
      } else if (previousPage === 'insightCategories') {
        setPreviousPath('/insight/create/categories?previous=insightTagsAndDescription');
      } else if (previousPage === 'editInsightGroups') {
        setPreviousPath('/insight/edit/' + groupId + '/' + insightUuid + '/groups');
      } else if (previousPage === 'editInsightTagsAndDescription') {
        setPreviousPath(
          '/insight/edit/' + groupId + '/' + insightUuid + '/tags-and-description?previous=editInsightGroups',
        );
      } else if (previousPage === 'editInsightCategories') {
        setPreviousPath(
          '/insight/edit/' + groupId + '/' + insightUuid + '/categories?previous=editInsightTagsAndDescription',
        );
      } else {
        setPreviousPath('/' + previousPage);
      }
      return;
    }
    if (lastIndex !== -1) {
      let previousPath = path.slice(0, lastIndex);
      if (previousPaths[previousPath]) {
        previousPath = previousPaths[previousPath];
      }
      if (path.includes('invitation-code')) {
        setPreviousPath('/feed');
      } else if (path.includes('reset-password')) {
        setPreviousPath('/feed');
      } else {
        setPreviousPath(previousPath);
      }
    }
  }, [eventUuid, groupId, insightUuid, location, previousPage, previousPaths]);

  useEffect(() => {
    if (showReturnFeed) {
      const handleBeforeUnload = (e: any) => {
        e.preventDefault();
        e.returnValue = ''; // Define uma mensagem vazia para o navegador (não mostrando mensagem padrão)

        // Exibe a mensagem de notificação personalizada
        const confirmationMessage = t('are_you_sure_you_want_to_leave_all_unsaved_data_will_be_lost');
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      };

      // Adiciona o ouvinte de evento ao carregar o componente
      window.addEventListener('beforeunload', handleBeforeUnload);

      // Remove o ouvinte de evento ao descarregar o componente
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [showReturnFeed, t]);

  function handleConfirmHome() {
    navigate('/');
  }

  return (
    <header className="fixed top-0 left-0 flex bg-white items-center justify-between h-[88px] px-10 w-full z-10">
      <div className="flex items-center gap-2 max-w-[-webkit-fill-available] w-full">
        {previousPath && !showReturnFeed && (
          <Link className="hover:opacity-70" to={previousPath}>
            <img alt="Left" src={leftArrow} className="min-w-6" />
          </Link>
        )}
        {showReturnFeed === 'insightSteps' && (
          <Link className="hover:opacity-70" to={previousPath}>
            <img alt="Left arrow" src={leftArrow} className="min-w-6" />
          </Link>
        )}
        {icon(path) === '@categoryIcon@' ? (
          <span className="flex items-center justify-center rounded-full w-12 h-12 bg-[#E2E2E2]">
            <img alt="Category" className="w-[35px] h-[35px] min-w-[35px]" src={getCategoryImage(category?.icon)} />
          </span>
        ) : (
          <>{icon(path) && <img alt="Path" src={icon(path)} />}</>
        )}
        <h2
          className="text-primary-200 text-lg font-bold block truncate overflow-hidden break-all line-clamp-3 max-w-[70vw]"
          title={pathName[path]}
        >
          {pathName[path]}
        </h2>
      </div>
      <div className="flex items-center gap-5">
        {showReturnFeed && (
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <button className="hover:opacity-70">
                <svg width="48" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Frame">
                    <path
                      id="Vector"
                      d="M6.5 24L10.5 20M10.5 20L24.5 6L38.5 20M10.5 20L10.5 40C10.5 41.1046 11.3954 42 12.5 42H18.5M38.5 20L42.5 24M38.5 20V40C38.5 41.1046 37.6046 42 36.5 42H30.5M18.5 42C19.6046 42 20.5 41.1046 20.5 40V32C20.5 30.8954 21.3954 30 22.5 30H26.5C27.6046 30 28.5 30.8954 28.5 32L28.5 40C28.5 41.1046 29.3954 42 30.5 42M18.5 42H30.5"
                      stroke="#282A74"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </button>
            </Dialog.Trigger>
            <ConfirmReturnHomeModal showReturnFeed={showReturnFeed} handleConfirmHome={handleConfirmHome} />
          </Dialog.Root>
        )}
        {showShareInsight && (
          <Link to="/insight/create" className="hover:opacity-70" title={t('share_an_insight')}>
            <img alt={t('share_an_insight')} className="min-w-12 rounded-full" src={insight} />
          </Link>
        )}
        {showCreateAds && (
          <Link to="/ads/create" className="hover:opacity-70" title={t('create_new_ads')}>
            <img alt={t('create_new_ads')} className="min-w-12 rounded-full" src={insight} />
          </Link>
        )}

        <Link to={'/profile'} className="flex items-center justify-center hover:opacity-90">
          <ImageViewer
            url={user?.image || ''}
            previewImage={defaultUserPhoto}
            className="min-w-12 min-h-12 max-w-12 max-h-12 rounded-full object-cover"
          />
        </Link>
        <Dialog.Root open={openSettings} onOpenChange={setOpenSettings}>
          <Dialog.Trigger asChild>
            <button className="hover:opacity-70">
              <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                <path
                  d="M5.25 21H36.75M5.25 10.5H36.75M5.25 31.5H36.75"
                  stroke="#282A74"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </Dialog.Trigger>
          {transitions((styles, item) =>
            item ? (
              <Dialog.Content
                forceMount
                className={'z-[1000] w-screen h-screen p-4 top-0 left-0 fixed bg-white font-poppins'}
              >
                <animated.div style={styles}>
                  <SettingsAndPrivacyModal />
                </animated.div>
              </Dialog.Content>
            ) : null,
          )}
        </Dialog.Root>
      </div>
    </header>
  );
}
