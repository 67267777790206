import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useGroups } from '@hooks/useGroups';
import {
  InsightCollectionCreateDTO,
  InsightCoverPhotosCreateDTO,
  InsightCreateDTO,
  useInsights,
} from '@hooks/useInsights';

import ShareInsightPost from '@components/ShareInsightPost';

import Loading from '@components/Loading';
import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';
import { base64ToFile, retrieveImgS3 } from 'utils/auxFunctions';
import { uploadToS3 } from 'utils/awsS3';
import { toast } from 'utils/toast';

export default function EditInsightFinish() {
  const { editingInsight, updateInsight, clearCreatingInsight } = useInsights();
  const { checkStorage } = useGroups();
  const [isInsightValid, setIsInsightValid] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const { insightUuid, groupId } = useParams();

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    if (
      editingInsight.title &&
      editingInsight.collections.length > 0 &&
      editingInsight.groupUuid &&
      editingInsight.category
    )
      setIsInsightValid(true);
    else return;

    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = ''; // Define uma mensagem vazia para o navegador (não mostrando mensagem padrão)

      // Exibe a mensagem de notificação personalizada
      const confirmationMessage = 'Are you sure you want to leave? All unsaved data will be lost.';
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    // Adiciona o ouvinte de evento ao carregar o componente
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove o ouvinte de evento ao descarregar o componente
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [editingInsight]);

  async function handlePublishInsight() {
    if (!groupId || !insightUuid) return;
    setIsLoading(true);
    const insightToPublish: InsightCreateDTO = {
      category_id: '',
      cover_photos: [],
      description: '',
      title: '',
      tags: [],
      collections: [],
      storage: '',
    };
    const tags: string[] = [];
    let storageBytes = editingInsight.initialStorage || 0;

    insightToPublish.title = editingInsight.title;
    insightToPublish.description = editingInsight.description;
    insightToPublish.category_id =
      (editingInsight.category?.topicUuid
        ? editingInsight.category.topicUuid
        : editingInsight.category.subCategoryUuid
          ? editingInsight.category.subCategoryUuid
          : editingInsight.category.uuid) || '';

    editingInsight.tags.forEach((tag) => tags.push(tag.uuid));

    console.log(editingInsight, tags);

    insightToPublish.tags = tags;
    insightToPublish.group_id = editingInsight?.groupUuid;

    editingInsight.collections.map((c) =>
      c?.mediasToEdit?.map((f) => {
        if (f?.file) storageBytes += f.file.size;
      }),
    );

    if (storageBytes === 0) storageBytes += 1;

    insightToPublish.storage = storageBytes.toString();

    const hasSpace = await checkStorage(editingInsight.groupUuid, storageBytes || 1);
    if (!hasSpace) {
      toast({
        label: t('error'),
        message: t('insufficient_storage'),
        type: 'error',
      });
      return;
    }

    try {
      if (editingInsight?.cover_photo) {
        await Promise.all(
          editingInsight?.cover_photo?.map(async (coverPhoto) => {
            let imgURL = '';
            const cover_photo: InsightCoverPhotosCreateDTO = {} as InsightCoverPhotosCreateDTO;
            if (!coverPhoto.uuid && coverPhoto.file) {
              if (coverPhoto.type.includes('video')) {
                const videoThumbnails = await generateVideoThumbnails(coverPhoto.file, 1, 'png');
                const file = base64ToFile(videoThumbnails[0]);
                const { key, error } = await uploadToS3(file);
                if (error) {
                  toast({
                    label: t('error'),
                    message: t('failed_to_upload_image'),
                    type: 'error',
                  });
                } else {
                  if (key) imgURL = retrieveImgS3(key);
                  cover_photo.url = imgURL;
                  cover_photo.type = 'image';
                }
              } else if (coverPhoto.type.includes('audio')) {
                cover_photo.url = '';
                cover_photo.type = 'audio';
                cover_photo.duration = '100';
              } else {
                const { key, error } = await uploadToS3(coverPhoto.file);
                if (error) {
                  toast({
                    label: t('error'),
                    message: t('failed_to_upload_image'),
                    type: 'error',
                  });
                } else {
                  if (key) imgURL = retrieveImgS3(key);
                  cover_photo.url = imgURL;
                  cover_photo.type = 'image';
                }
              }
              insightToPublish.cover_photos.push(cover_photo);
            } else if (coverPhoto.uuid && coverPhoto.url) {
              insightToPublish.cover_photos.push({
                type: coverPhoto.type,
                url: coverPhoto.url,
                duration: coverPhoto.duration?.toString(),
              });
            }
          }),
        );
      }

      await Promise.all(
        editingInsight.collections.map(async (collection) => {
          let imgURL = '';
          const collectionToCreate: InsightCollectionCreateDTO = {
            description: '',
            files: [],
            title: '',
          };

          collectionToCreate.title = collection.title;
          collectionToCreate.description = collection.description;

          if (collection?.mediasToEdit && collection?.mediasToEdit.length > 0) {
            await Promise.all(
              collection?.mediasToEdit.map(async (media) => {
                if (!media.uuid && media.file) {
                  const { key, error } = await uploadToS3(media.file);
                  if (error) {
                    toast({
                      label: t('error'),
                      message: t('failed_to_upload_image'),
                      type: 'error',
                    });
                  } else {
                    if (key) imgURL = retrieveImgS3(key);
                    if (media.file.type.includes('video')) {
                      const video = document.createElement('video');
                      video.preload = 'metadata';

                      const loadedMetadataPromise = new Promise((resolve) => {
                        video.onloadedmetadata = () => {
                          const videoDuration = video.duration;
                          resolve(videoDuration);
                        };
                      });

                      video.src = URL.createObjectURL(media.file);
                      let duration: any = await loadedMetadataPromise;

                      if (duration === Infinity || Number.isNaN(duration)) {
                        duration = 0;
                      }

                      collectionToCreate.files!.push({
                        url: imgURL,
                        type: 'video',
                        duration: (Math.round(duration) * 1000)?.toString() || '',
                      });
                    } else if (media.file.type.includes('audio')) {
                      const audio = document.createElement('audio');
                      audio.preload = 'metadata';

                      const loadedMetadataPromise = new Promise((resolve) => {
                        audio.onloadedmetadata = () => {
                          const audioDuration = audio.duration;
                          resolve(audioDuration);
                        };
                      });

                      audio.src = URL.createObjectURL(media.file);
                      let duration: any = await loadedMetadataPromise;

                      if (duration === Infinity || Number.isNaN(duration)) {
                        duration = 0;
                      }
                      insightToPublish.cover_photos.push({
                        url: imgURL,
                        type: 'audio',
                        duration: (Math.round(duration) * 1000)?.toString() || '',
                      });

                      collectionToCreate.files!.push({
                        url: imgURL,
                        type: 'audio',
                        duration: (Math.round(duration) * 1000)?.toString() || '',
                      });
                    } else {
                      collectionToCreate.files!.push({
                        url: imgURL,
                        type: 'image',
                      });
                    }
                  }
                } else if (media.uuid && media.url) {
                  collectionToCreate.files!.push({
                    url: media.url,
                    type: media.type || '',
                    duration: media.duration || '0',
                  });
                }
              }),
            );
          }
          if (!collection?.mediasToEdit || collection?.mediasToEdit.length === 0) {
            delete collectionToCreate.files;
          }
          insightToPublish.collections.push(collectionToCreate);
        }),
      );
      const response = await updateInsight(groupId, insightUuid, insightToPublish);
      if (response) {
        toast({
          label: t('success'),
          message: t('insight_success'),
          type: 'success',
        });
        clearCreatingInsight();
        navigate('/feed');
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast({
        label: t('error'),
        message: t('insight_error'),
        type: 'error',
      });
      setIsLoading(false);
    }
  }

  return (
    <>
      {loading || !isInsightValid ? (
        <Loading />
      ) : (
        <div className="flex justify-center w-full bg-backgray min-h-[calc(100vh-88px)] py-12 relative">
          <div className="mb-20">
            <ShareInsightPost insight={editingInsight} isEditingInsight />
          </div>

          <footer className="flex items-center justify-center gap-24 fixed bottom-0 right-0 bg-white w-full py-5">
            <button
              onClick={() => navigate('/insight/edit/' + groupId + '/' + insightUuid)}
              className="py-2 px-10 border border-primary-200 rounded-md shadow-md h-10 hover:opacity-70"
            >
              {t('edit')}
            </button>
            <button
              data-test="edit-insight-finish"
              onClick={handlePublishInsight}
              className="py-2 px-10 bg-primary-200 text-white rounded-md shadow-md font-semibold h-10 hover:opacity-70"
            >
              {t('post')}
            </button>
          </footer>
        </div>
      )}
    </>
  );
}
