/* eslint-disable jsx-a11y/media-has-caption */
import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useRef, useState } from 'react';

import { Collection, MediaToEdit } from '@hooks/useInsights';

import CollectionFileEditModal from '@components/CollectionFileEditModal';

import defaultImagePreview from '@assets/defaultImageViewer.png';

interface UploadedFilesPreviewProps {
  index: number;
  file?: File;
  media?: MediaToEdit;
  collection: Collection;
  isEditMode?: boolean;
}

export default function UploadedFilesPreview({
  index,
  file,
  collection,
  isEditMode,
  media,
}: Readonly<UploadedFilesPreviewProps>) {
  const [type, setType] = useState('');
  const [previewImage, setPreviewImage] = useState(defaultImagePreview);
  const audioPlayer = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (file) {
      setType(file.type);
      const preview = URL.createObjectURL(file);
      if (preview) setPreviewImage(preview);
    }
    if (media) {
      if (media.file) {
        setType(media.file.type);
        const preview = URL.createObjectURL(media.file);
        if (preview) setPreviewImage(preview);
      } else {
        setType(media?.type || '');
        setPreviewImage(media?.url || '');
      }
    }
  }, [file, media]);

  return (
    <span className="w-[100px] h-[100px] relative">
      {type.includes('image') && (
        <img
          alt="Preview"
          src={previewImage}
          className="w-full h-full max-h-full max-w-full object-cover pointer-events-none"
        />
      )}
      {type.includes('video') && (
        <video controls src={previewImage} className="w-full h-full max-h-full max-w-full object-cover" />
      )}
      {type.includes('audio') && (
        <span className="w-full h-full bg-primary-200 flex flex-col items-center justify-center relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
            <path
              d="M4.6875 32.8125V17.1875C4.6875 16.3246 3.98794 15.625 3.125 15.625C2.26206 15.625 1.5625 16.3246 1.5625 17.1875V32.8125C1.5625 33.6754 2.26206 34.375 3.125 34.375C3.98794 34.375 4.6875 33.6754 4.6875 32.8125Z"
              fill="#EDB561"
            />
            <path
              d="M39.8438 32.8125V17.1875C39.8438 16.3246 39.1442 15.625 38.2812 15.625C37.4183 15.625 36.7188 16.3246 36.7188 17.1875V32.8125C36.7188 33.6754 37.4183 34.375 38.2812 34.375C39.1442 34.375 39.8438 33.6754 39.8438 32.8125Z"
              fill="#EDB561"
            />
            <path
              d="M13.2812 39.0625V10.1562C13.2812 9.29331 12.5817 8.59375 11.7188 8.59375C10.8558 8.59375 10.1562 9.29331 10.1562 10.1563V39.0625C10.1562 39.9254 10.8558 40.625 11.7188 40.625C12.5817 40.625 13.2812 39.9254 13.2812 39.0625Z"
              fill="#EDB561"
            />
            <path
              d="M31.25 39.0625V10.9375C31.25 10.0746 30.5504 9.375 29.6875 9.375C28.8246 9.375 28.125 10.0746 28.125 10.9375V39.0625C28.125 39.9254 28.8246 40.625 29.6875 40.625C30.5504 40.625 31.25 39.9254 31.25 39.0625Z"
              fill="#EDB561"
            />
            <path
              d="M48.4375 39.0625V10.9375C48.4375 10.0746 47.7379 9.375 46.875 9.375C46.0121 9.375 45.3125 10.0746 45.3125 10.9375V39.0625C45.3125 39.9254 46.0121 40.625 46.875 40.625C47.7379 40.625 48.4375 39.9254 48.4375 39.0625Z"
              fill="#EDB561"
            />
            <path
              d="M21.875 46.875V3.125C21.875 2.26206 21.1754 1.5625 20.3125 1.5625C19.4496 1.5625 18.75 2.26206 18.75 3.125V46.875C18.75 47.7379 19.4496 48.4375 20.3125 48.4375C21.1754 48.4375 21.875 47.7379 21.875 46.875Z"
              fill="#EDB561"
            />
          </svg>

          <audio
            controls
            controlsList="nodownload"
            className="w-full h-5 absolute bottom-1 left-0 customized-audio-tag"
            ref={audioPlayer}
            src={previewImage}
          />
        </span>
      )}

      {!isEditMode && (
        <Dialog.Root>
          <Dialog.Trigger asChild>
            <button className="absolute top-0 right-0 flex items-center justify-center rounded-full py-[2px] px-1 bg-white border border-primary-200 hover:brightness-110">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                <path
                  d="M8.16699 8.125C8.51217 8.125 8.79199 7.84518 8.79199 7.5C8.79199 7.15482 8.51217 6.875 8.16699 6.875C7.82181 6.875 7.54199 7.15482 7.54199 7.5C7.54199 7.84518 7.82181 8.125 8.16699 8.125Z"
                  fill="#282A74"
                />
                <path
                  d="M12.542 8.125C12.8872 8.125 13.167 7.84518 13.167 7.5C13.167 7.15482 12.8872 6.875 12.542 6.875C12.1968 6.875 11.917 7.15482 11.917 7.5C11.917 7.84518 12.1968 8.125 12.542 8.125Z"
                  fill="#282A74"
                />
                <path
                  d="M3.79199 8.125C4.13717 8.125 4.41699 7.84518 4.41699 7.5C4.41699 7.15482 4.13717 6.875 3.79199 6.875C3.44681 6.875 3.16699 7.15482 3.16699 7.5C3.16699 7.84518 3.44681 8.125 3.79199 8.125Z"
                  fill="#282A74"
                />
                <path
                  d="M8.16699 8.125C8.51217 8.125 8.79199 7.84518 8.79199 7.5C8.79199 7.15482 8.51217 6.875 8.16699 6.875C7.82181 6.875 7.54199 7.15482 7.54199 7.5C7.54199 7.84518 7.82181 8.125 8.16699 8.125Z"
                  stroke="#282A74"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.542 8.125C12.8872 8.125 13.167 7.84518 13.167 7.5C13.167 7.15482 12.8872 6.875 12.542 6.875C12.1968 6.875 11.917 7.15482 11.917 7.5C11.917 7.84518 12.1968 8.125 12.542 8.125Z"
                  stroke="#282A74"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.79199 8.125C4.13717 8.125 4.41699 7.84518 4.41699 7.5C4.41699 7.15482 4.13717 6.875 3.79199 6.875C3.44681 6.875 3.16699 7.15482 3.16699 7.5C3.16699 7.84518 3.44681 8.125 3.79199 8.125Z"
                  stroke="#282A74"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </Dialog.Trigger>
          <CollectionFileEditModal index={index} media={media} collection={collection} />
        </Dialog.Root>
      )}
    </span>
  );
}
