import { User, useUser } from '@hooks/useUser';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

// Defina um tipo para o contexto, que pode incluir o valor padrão.
interface NotificationContextType {
  unreadNotifications: number;
  incrementUnreadNotifications: () => void;
  markNotificationAsRead: () => void;
  markAllAsReadNotifications: () => void;
}

// Crie um contexto com um valor padrão inicial.
const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

// Exporte um provedor de contexto personalizado.
export function NotificationProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  const { user, getUserDetails } = useUser();
  const [userDetails, setUserDetails] = useState<User | null>(null);
  const [unreadNotifications, setUnreadNotifications] = useState<number>(0);
  userDetails;
  useEffect(() => {
    async function getUser() {
      if(!user?.uuid) return;
      const { userDetail } = await getUserDetails(String(user?.uuid));
      setUserDetails(userDetail);
      setUnreadNotifications(userDetail?.notifications || 0);
    }
    getUser();
  }, [getUserDetails, user?.uuid]);

  const incrementUnreadNotifications = useCallback(() => {
    setUnreadNotifications((prevCount) => prevCount + 1);
  }, []);

  const markNotificationAsRead = useCallback(() => {
    setUnreadNotifications((prevCount) => Math.max(0, prevCount - 1));
  }, []);

  const markAllAsReadNotifications = useCallback(() => {
    setUnreadNotifications(0);
  }, []);

  const value = useMemo(
    () => ({
      unreadNotifications,
      incrementUnreadNotifications,
      markNotificationAsRead,
      markAllAsReadNotifications,
    }),
    [unreadNotifications, incrementUnreadNotifications, markNotificationAsRead, markAllAsReadNotifications],
  );

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
}

export function useNotifications() {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification deve ser usado dentro de um NotificationProvider');
  }
  return context;
}
