import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCategory } from '@hooks/useCategory';
import { useInsights } from '@hooks/useInsights';

import EmptyFeed from '@components/EmptyStates/EmptyFeed';
import Loading from '@components/Loading';
import PostFeed from '@components/PostFeed';

import loadingGif from '@assets/icons/loading.gif';

export default function Topics() {
  const [loading, setLoading] = useState(true);

  const [scrollDir, setScrollDir] = useState<'up' | 'down'>('up');
  const [loadingFeed, setLoadingFeed] = useState(false);

  const { groupId, topicId, categoryId, subCategoryId } = useParams();
  const { getCategory } = useCategory();
  const { getPostsByGroup, insights, pagination } = useInsights();

  const getCategoryInformation = useCallback(async () => {
    if (!groupId) return;
    if (topicId) {
      await getCategory(groupId, topicId);
    } else if (categoryId) {
      await getCategory(groupId, categoryId);
    } else if (subCategoryId) {
      await getCategory(groupId, subCategoryId);
    }
  }, [categoryId, getCategory, groupId, subCategoryId, topicId]);

  const getCategoryInsights = useCallback(async () => {
    if (!groupId) return;
    if (topicId) {
      await getPostsByGroup(groupId, true, `?sortBy=updated_at&sortDirection=desc&categoryUuid=${topicId}&page=1`);
    } else if (categoryId) {
      await getPostsByGroup(groupId, true, `?sortBy=updated_at&sortDirection=desc&categoryUuid=${categoryId}&page=1`);
    } else if (subCategoryId) {
      await getPostsByGroup(
        groupId,
        true,
        `?sortBy=updated_at&sortDirection=desc&categoryUuid=${subCategoryId}&page=1`,
      );
    }
  }, [categoryId, getPostsByGroup, groupId, subCategoryId, topicId]);

  const getInformations = useCallback(async () => {
    await Promise.all([getCategoryInformation(), getCategoryInsights()]);
    setLoading(false);
  }, [getCategoryInformation, getCategoryInsights]);

  useEffect(() => {
    getInformations();
  }, [getInformations]);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.scrollY;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.scrollY;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? 'down' : 'up');
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = async () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }

      if (
        window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight &&
        !loadingFeed &&
        groupId &&
        categoryId &&
        pagination.current_page < pagination.last_page
      ) {
        setLoadingFeed(true);
        if (topicId) {
          await getPostsByGroup(
            groupId,
            false,
            `?sortBy=updated_at&sortDirection=desc&categoryUuid=${topicId}&page=${pagination.current_page + 1}`,
          );
        } else if (categoryId) {
          await getPostsByGroup(
            groupId,
            false,
            `?sortBy=updated_at&sortDirection=desc&categoryUuid=${categoryId}&page=${pagination.current_page + 1}`,
          );
        } else if (subCategoryId) {
          await getPostsByGroup(
            groupId,
            false,
            `?sortBy=updated_at&sortDirection=desc&categoryUuid=${subCategoryId}&page=${pagination.current_page + 1}`,
          );
        }
        setLoadingFeed(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollDir, pagination, loadingFeed, groupId, categoryId, topicId, subCategoryId, getPostsByGroup]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="relative">
          <main className="flex flex-col items-center w-full bg-backgray py-14 min-h-[90vh] gap-9">
            {insights?.length === 0 && (
              <div className="py-10">
                <EmptyFeed />
              </div>
            )}
            {insights?.length > 0 && insights.map((insight) => <PostFeed key={insight.uuid} insight={insight} />)}
            {loadingFeed && (
              <img src={loadingGif} alt="Loading" className="w-12 absolute bottom-0 left-[50%] -translate-x-[50%]" />
            )}
          </main>
        </div>
      )}
    </>
  );
}
