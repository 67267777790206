import loadingGif from '@assets/icons/loading.gif';
import { Button } from '@components/Button';
import Calendar from '@components/Calendar';

import MonthSchedule from '@components/MonthSchedule';
import { useCalendar } from '@hooks/useCalendar';
import { useGroups } from '@hooks/useGroups';
import { usePagination } from 'hooks/usePagination';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { handleMessageError } from 'utils/handleMessageError';

export default function CalendarPage() {
  const { t } = useTranslation();
  const [currentGroup, setCurrentGroup] = useState<string>('all');
  const [page, setPage] = useState<{ current: number; next: number[]; previous: number[] }>();
  const [loading, setLoading] = useState(true);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [showCreateEvent, setShowCreateEvent] = useState(false);

  const { getGroups, groups, pagination } = useGroups();
  const {
    getUserCalendar,
    userCalendar,
    getGroupCalendar,
    getGroupCalendarByDay,
    getUserCalendarByDay,
    userCalendarByDay,
    setUserCalendarByDay,
  } = useCalendar();
  const navigate = useNavigate();

  const [, setSelectedDay] = useState<string | null>(null);
  const [, setMonthSelected] = useState('');
  const [yearSelected, setYearSelected] = useState('');

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const getFullDate = new Date();
  const [monthNow, setMonthNow] = useState((getFullDate.getMonth() + 1).toString().padStart(2, '0'));
  const yearNow = getFullDate.getFullYear().toString();

  const activeTab =
    'rounded-2xl bg-primary-200 px-2 py-1 font-normal text-xs text-white flex justify-center items-center';
  const inactiveTab =
    'rounded-2xl bg-white border border-primary-200 px-2 py-1 font-normal text-xs text-primary-200 flex justify-center items-center';

  const getGroupsList = useCallback(async () => {
    await getGroups('?take=3&page=1');
    setLoading(false);
  }, [getGroups]);

  function handleDateSelect(day: string) {
    setSelectedDay(day);
    getCalendarByDay(currentGroup, yearSelected, monthNow, day);
  }

  const getCalendar = useCallback(
    async (groupUuid: string, year: string, month: string) => {
      setLoading(true);
      try {
        if (groupUuid === 'all') {
          await getUserCalendar(`year=${year}&month=${month}`);
        } else {
          await getGroupCalendar(groupUuid, `year=${year}&month=${month}`);
        }
      } catch (err) {
        const message = handleMessageError(err);
        console.error(message);
      } finally {
        setLoading(false);
      }
    },
    [getGroupCalendar, getUserCalendar],
  );

  const getCalendarByDay = useCallback(
    async (groupUuid: string, year: string, month: string, day: string) => {
      setLoading(true);
      try {
        if (groupUuid === 'all') {
          await getUserCalendarByDay(`year=${year}&month=${month}&day=${day}`);
        } else {
          await getGroupCalendarByDay(groupUuid, `year=${year}&month=${month}&day=${day}`);
        }
      } catch (err) {
        const message = handleMessageError(err);
        console.error(message);
      } finally {
        setLoading(false);
      }
    },
    [getGroupCalendarByDay, getUserCalendarByDay],
  );

  function setCurrentGroupAndClearEvents(groupUuid: string) {
    setCurrentGroup(groupUuid);
    setUserCalendarByDay([]);
    setLoading(true);
    getCalendar(groupUuid, yearNow, monthNow);
    getCalendarByDay(groupUuid, yearSelected, monthNow, '');
  }

  const handleDateChange = (newDate: Date | null) => {
    setSelectedDate(newDate);
  };

  useEffect(() => {
    handleDateChange(selectedDate);
    if (selectedDate) {
      const yearParam = selectedDate.getFullYear().toString();
      const monthParam = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
      getCalendar(currentGroup, yearParam, monthParam);
      getCalendarByDay(currentGroup, yearParam, monthParam, '');
    }
  }, [selectedDate, currentGroup, getCalendar, getCalendarByDay]);

  useEffect(() => {
    getGroupsList();
    setUserCalendarByDay([]);
    setMonthSelected(monthNow);
    setYearSelected(yearNow);

    getCalendar(currentGroup, yearNow, monthNow);
    getCalendarByDay(currentGroup, yearNow, monthNow, '');
  }, [currentGroup, getCalendar, getCalendarByDay, getGroupsList, setUserCalendarByDay, yearNow]);

  const handleMonthYearChange = (month: string, year: string) => {
    setMonthSelected(month);
    setYearSelected(year);

    if (currentGroup === 'all') {
      getCalendar(currentGroup, year, month);
      getCalendarByDay(currentGroup, year, month, '');
    }
    setMonthNow(month);
  };
  const { setPagination } = usePagination();
  useEffect(() => {
    setPagination(pagination, setPage);
  }, [pagination, setPagination]);

  useEffect(() => {
    const existGroupWithoutDefault = groups.find((group) => group.status !== 'fake');
    if (existGroupWithoutDefault) {
      setShowCreateEvent(true);
    }
  }, [groups]);

  async function handleSelectPage(selectedPage: number) {
    setLoadingGroups(true);
    await getGroups(`?take=3&page=${selectedPage}`);
    setLoadingGroups(false);
  }

  return (
    <div className="py-20 w-full min-h-[calc(100vh-88px)] bg-backgray">
      <div className="grid desktop:grid-cols-2 grid-cols-1 max-w-6xl mx-auto">
        <div className="flex flex-col items-center gap-10">
          <div className="max-w-lg">
            {groups.length === 0 ? (
              <button
                onClick={() => {
                  setCurrentGroupAndClearEvents('all');
                  setSelectedDate(null);
                }}
                className={currentGroup === 'all' ? activeTab : inactiveTab}
              >
                {t('all')}
              </button>
            ) : (
              <>
                {loadingGroups ? (
                  <div>
                    <img src={loadingGif} alt="Loading" className="w-12" />
                  </div>
                ) : (
                  <div className="flex items-center flex-col gap-2">
                    <div className="flex items-center gap-5">
                      {page?.previous && page?.previous?.length > 0 && (
                        <button
                          onClick={() => handleSelectPage(page?.current - 1)}
                          className="text-primary-200 text-xl hover:opacity-70 ml-[-20px]"
                        >
                          {'<'}
                        </button>
                      )}
                      <div className="flex gap-2 flex-wrap justify-center relative">
                        {groups.map((group) => (
                          <button
                            onClick={() => {
                              setCurrentGroupAndClearEvents(group.uuid);
                              setSelectedDate(null);
                            }}
                            className={currentGroup === group.uuid ? activeTab : inactiveTab}
                            key={group.uuid}
                          >
                            {group.name}
                          </button>
                        ))}
                      </div>
                      {page?.next && page?.next?.length > 0 && (
                        <button
                          onClick={() => handleSelectPage(page?.current + 1)}
                          className="text-primary-200 text-xl hover:opacity-70 mr-[-20px]"
                        >
                          {'>'}
                        </button>
                      )}
                    </div>
                    <button
                      onClick={() => {
                        setCurrentGroupAndClearEvents('all');
                        setSelectedDate(null);
                      }}
                      className={`${currentGroup === 'all' ? activeTab : inactiveTab}`}
                    >
                      {t('all')}
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
          <Calendar
            type="eventPicker"
            onMonthYearChange={handleMonthYearChange}
            userCalendar={userCalendar}
            onDateSelect={handleDateSelect}
          />

          {showCreateEvent && (
            <Button
              onClick={() => navigate('create-event')}
              size="medium"
              variant="primary"
              text={t('create_an_event')}
            />
          )}
        </div>
        <div className="flex flex-col items-center mt-8">
          {loading ? (
            <div>
              <img src={loadingGif} alt="Loading" className="w-12 mt-7" />
            </div>
          ) : (
            <div className="max-h-[550px] overflow-y-auto overflow-x-hidden flex justify-center items-center w-full">
              <MonthSchedule calendar={userCalendarByDay} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
