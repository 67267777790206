import { useCallback, useEffect, useRef, useState } from 'react';

import { useComments, type Comment } from '@hooks/useComments';
import { Insight, useInsights } from '@hooks/useInsights';
import { useUser } from '@hooks/useUser';

import ImageViewer from '@components/ImageViewer';
import CommentBox from './Comment';

import loadingGif from '@assets/icons/loading.gif';

import { PaperPlaneRight } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

interface ListCommentsProps {
  showComments: boolean;
  insight: Insight;
  isModal?: boolean;
}

export default function ListComments({ showComments, insight, isModal }: Readonly<ListCommentsProps>) {
  const { t } = useTranslation();
  const [comments, setComments] = useState<Comment[]>([]);
  const [isSubbmitting, setIsSubmitting] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);

  const { getCommentsForInsight, addComment, pagination } = useComments();
  const { updateSpecificInsight } = useInsights();
  const { user } = useUser();

  const textAreaComment = useRef<HTMLTextAreaElement>(null);
  const commentsDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onScroll = async () => {
      if (
        commentsDiv?.current &&
        commentsDiv?.current?.scrollTop + commentsDiv?.current?.clientHeight === commentsDiv?.current?.scrollHeight &&
        !loadingComments &&
        pagination.current_page < pagination.last_page
      ) {
        setLoadingComments(true);
        const comments = await getCommentsForInsight(
          insight?.group?.uuid || '',
          insight?.uuid || '',
          '?sortBy=created_at&sortDirection=desc&take=10&page=' + (pagination?.current_page + 1),
        );
        if (comments && comments?.length > 0) {
          setComments((prev) => [...prev, ...comments]);
        }
        setLoadingComments(false);
      }
    };

    commentsDiv?.current?.addEventListener('scroll', onScroll);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => commentsDiv?.current?.removeEventListener('scroll', onScroll);
  }, [pagination, commentsDiv, loadingComments, getCommentsForInsight, insight?.group?.uuid, insight?.uuid]);

  const getComments = useCallback(async () => {
    const comments = await getCommentsForInsight(
      insight?.group?.uuid || '',
      insight?.uuid || '',
      '?sortBy=created_at&sortDirection=desc&take=10&page=1',
    );
    setComments(comments);
  }, [getCommentsForInsight, insight?.group?.uuid, insight?.uuid]);

  async function handleAddNewComment() {
    if (!textAreaComment.current?.value || textAreaComment.current?.value === '') return;
    setIsSubmitting(true);
    const response = await addComment(insight?.group?.uuid || '', insight?.uuid || '', textAreaComment.current?.value);
    if (response?.uuid) {
      setComments((prev) => [
        {
          uuid: response.uuid,
          body: response.body,
          user: {
            uuid: user?.uuid || '',
            image: user?.image || '',
            first_name: user?.first_name || '',
            last_name: user?.last_name || '',
          },
        },
        ...prev,
      ]);
      textAreaComment.current.value = '';
      updateSpecificInsight(insight?.group?.uuid || '', insight?.uuid || '');
    }
    setIsSubmitting(false);
  }

  useEffect(() => {
    if (showComments) {
      getComments();
    }
  }, [getComments, showComments]);

  return (
    <div className="flex flex-col border border-grey-600 p-5 w-full relative">
      <div ref={commentsDiv} className="flex flex-col max-h-[400px] overflow-y-scroll mb-[90px] px-2 w-full">
        {comments &&
          comments?.map((comment) => (
            <CommentBox
              key={comment.uuid}
              comment={comment}
              isModal={isModal}
              insight={insight}
              setComments={setComments}
            />
          ))}
        {loadingComments && (
          <div className="flex items-center justify-center">
            <img src={loadingGif} alt="Loading" className="w-12 mb-2" />
          </div>
        )}
      </div>
      <div className="w-[90%] absolute bottom-5 left-5 flex items-center gap-3 rounded-md py-2 z-[1]">
        <ImageViewer url={user?.image || ''} previewImage={''} className="w-[40px] h-[40px] rounded-full" />
        <div className="w-full flex items-center gap-2">
          <textarea
            ref={textAreaComment}
            maxLength={255}
            className="w-full border border-grey-600 outline-none p-3 rounded-md text-sm h-[80px] bg-backgray resize-none"
            placeholder={t('leave_a_comment')}
          />
          <button
            disabled={isSubbmitting || insight.user?.role?.toLowerCase?.().includes?.('visitant')}
            onClick={handleAddNewComment}
            className="hover:opacity-70 disabled:cursor-not-allowed"
          >
            <PaperPlaneRight size={20} weight="fill" fill="#828282" />
          </button>
        </div>
      </div>
    </div>
  );
}
