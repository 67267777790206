import * as Dialog from '@radix-ui/react-dialog';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ButtonAdd } from '@components/ButtonAdd';
import EditUserModal from '@components/EditUserModal';

import { useGroups } from '@hooks/useGroups';
import { useUser } from '@hooks/useUser';

import arrowRight from '@assets/icons/Button/arrow-right.svg';
import userNoPhoto from '@assets/icons/user-no-photo.svg';
import Loading from '@components/Loading';

import { PaginationComponent } from '@components/Pagination';
import { useTranslation } from 'react-i18next';
import { getDefaultImages } from 'utils/auxFunctions';

export default function EditParticipants() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<{ current: number; next: number[]; previous: number[] }>();

  const { groupMembers, getGroupMembers, getGroupByUuid, group, pagination } = useGroups();
  const { user } = useUser();
  const { groupId } = useParams();

  const navigate = useNavigate();

  const getAllMembersFromGroup = useCallback(async () => {
    if (groupId) {
      await Promise.all([await getGroupByUuid(groupId), await getGroupMembers(groupId, '?take=10&page=1')]);
      setLoading(false);
    }
  }, [getGroupByUuid, getGroupMembers, groupId]);

  useEffect(() => {
    getAllMembersFromGroup();
  }, [getAllMembersFromGroup]);

  async function handleSelectPage(selectedPage: number) {
    setLoading(true);
    if (groupId) await getGroupMembers(groupId, '?take=10&page=' + selectedPage);
    setLoading(false);
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex flex-col items-center bg-backgray min-h-[calc(100vh-88px)] py-12">
          <div className="flex flex-col bg-white desktop:w-[45vw] tablet:w-[60vw] relative">
            {group?.role === 'Admin' && (
              <ButtonAdd
                bg="bg-white"
                text={t('add_participants')}
                textColor="text-primary-200"
                onClick={() => navigate(`/groups/${groupId}/invite`)}
              />
            )}
            {groupMembers &&
              groupMembers.map(
                (groupMember) =>
                  groupMember?.user && (
                    <div
                      key={groupMember.uuid}
                      className={`flex items-center justify-between w-full py-4 gap-3 px-[10px] border-t border-grey-600`}
                    >
                      {groupMember.user && (
                        <div className="flex items-center gap-8 ml-4">
                          <img
                            className="min-w-12 min-h-12 max-w-12 max-h-12 rounded-full"
                            src={groupMember.user.image || userNoPhoto}
                            alt="Group member"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = getDefaultImages('profile-default-1.png');
                            }}
                          />
                          <div className="flex flex-col items-start">
                            <span className="text-lg text-black-100">{groupMember.user.first_name}</span>
                            <span className="text-xs text-black-100">{groupMember.user.email}</span>
                          </div>
                        </div>
                      )}

                      <Dialog.Root key={groupMember.uuid}>
                        <Dialog.Trigger asChild>
                          <button
                            className={`flex items-center gap-1 ${group?.role === 'Admin' ? 'hover:opacity-70' : ''}`}
                          >
                            <span
                              className={`text-black-100 ${user?.uuid == groupMember?.user?.uuid ? 'mr-2' : ''} ${
                                group?.role !== 'Admin' ? 'mr-2' : ''
                              }`}
                            >
                              {groupMember.role?.name === 'Admin'
                                ? t('admin')
                                : groupMember.role?.name === 'Member'
                                  ? t('member')
                                  : t('visitor')}
                            </span>
                            <img className="w-[30px] h-[30px]" src={arrowRight} alt="Arrow right" />
                          </button>
                        </Dialog.Trigger>
                        {group?.role === 'Admin' && <EditUserModal userInGroupInfo={groupMember} />}
                      </Dialog.Root>
                    </div>
                  ),
              )}
          </div>
          {groupMembers?.length > 0 && (
            <PaginationComponent
              page={page}
              handleSelectPage={handleSelectPage}
              pagination={pagination}
              setPage={setPage}
            />
          )}
        </div>
      )}
    </>
  );
}
