import { Insight } from '@hooks/useInsights';

import defaultImageViewer from '@assets/defaultImageViewer.png';

interface CoverPhotoFeedProps {
  insight: Insight;
}

export default function CoverPhotoFeed({ insight }: CoverPhotoFeedProps) {
  return (
    <>
      {insight?.cover_photo?.length === 1 && (
        <div className="w-full h-[258px]">
          {insight?.cover_photo[0]?.type === 'audio' ? (
            <span className="w-full h-full bg-primary-200 flex flex-col items-center justify-center relative">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                <path
                  d="M4.6875 32.8125V17.1875C4.6875 16.3246 3.98794 15.625 3.125 15.625C2.26206 15.625 1.5625 16.3246 1.5625 17.1875V32.8125C1.5625 33.6754 2.26206 34.375 3.125 34.375C3.98794 34.375 4.6875 33.6754 4.6875 32.8125Z"
                  fill="#EDB561"
                />
                <path
                  d="M39.8438 32.8125V17.1875C39.8438 16.3246 39.1442 15.625 38.2812 15.625C37.4183 15.625 36.7188 16.3246 36.7188 17.1875V32.8125C36.7188 33.6754 37.4183 34.375 38.2812 34.375C39.1442 34.375 39.8438 33.6754 39.8438 32.8125Z"
                  fill="#EDB561"
                />
                <path
                  d="M13.2812 39.0625V10.1562C13.2812 9.29331 12.5817 8.59375 11.7188 8.59375C10.8558 8.59375 10.1562 9.29331 10.1562 10.1563V39.0625C10.1562 39.9254 10.8558 40.625 11.7188 40.625C12.5817 40.625 13.2812 39.9254 13.2812 39.0625Z"
                  fill="#EDB561"
                />
                <path
                  d="M31.25 39.0625V10.9375C31.25 10.0746 30.5504 9.375 29.6875 9.375C28.8246 9.375 28.125 10.0746 28.125 10.9375V39.0625C28.125 39.9254 28.8246 40.625 29.6875 40.625C30.5504 40.625 31.25 39.9254 31.25 39.0625Z"
                  fill="#EDB561"
                />
                <path
                  d="M48.4375 39.0625V10.9375C48.4375 10.0746 47.7379 9.375 46.875 9.375C46.0121 9.375 45.3125 10.0746 45.3125 10.9375V39.0625C45.3125 39.9254 46.0121 40.625 46.875 40.625C47.7379 40.625 48.4375 39.9254 48.4375 39.0625Z"
                  fill="#EDB561"
                />
                <path
                  d="M21.875 46.875V3.125C21.875 2.26206 21.1754 1.5625 20.3125 1.5625C19.4496 1.5625 18.75 2.26206 18.75 3.125V46.875C18.75 47.7379 19.4496 48.4375 20.3125 48.4375C21.1754 48.4375 21.875 47.7379 21.875 46.875Z"
                  fill="#EDB561"
                />
              </svg>
              <audio
                controls
                controlsList="nodownload"
                className="w-full h-5 absolute bottom-1 left-0 customized-audio-tag"
                src={insight?.cover_photo[0]?.url ?? ''}
              />
            </span>
          ) : (
            <div className="w-full h-[258px]">
              <img
                className="h-full max-h-[258px] w-full object-cover"
                src={insight?.cover_photo[0]?.thumbnail800 ?? defaultImageViewer}
                alt=""
              />
            </div>
          )}
        </div>
      )}
      {insight?.cover_photo?.length === 2 && (
        <div className="grid grid-cols-2 w-full h-[258px]">
          <div className="h-full max-h-[258px] col-span-1">
            {insight?.cover_photo[0]?.type === 'audio' ? (
              <span className="w-full h-full bg-primary-200 flex flex-col items-center justify-center relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                  <path
                    d="M4.6875 32.8125V17.1875C4.6875 16.3246 3.98794 15.625 3.125 15.625C2.26206 15.625 1.5625 16.3246 1.5625 17.1875V32.8125C1.5625 33.6754 2.26206 34.375 3.125 34.375C3.98794 34.375 4.6875 33.6754 4.6875 32.8125Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M39.8438 32.8125V17.1875C39.8438 16.3246 39.1442 15.625 38.2812 15.625C37.4183 15.625 36.7188 16.3246 36.7188 17.1875V32.8125C36.7188 33.6754 37.4183 34.375 38.2812 34.375C39.1442 34.375 39.8438 33.6754 39.8438 32.8125Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M13.2812 39.0625V10.1562C13.2812 9.29331 12.5817 8.59375 11.7188 8.59375C10.8558 8.59375 10.1562 9.29331 10.1562 10.1563V39.0625C10.1562 39.9254 10.8558 40.625 11.7188 40.625C12.5817 40.625 13.2812 39.9254 13.2812 39.0625Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M31.25 39.0625V10.9375C31.25 10.0746 30.5504 9.375 29.6875 9.375C28.8246 9.375 28.125 10.0746 28.125 10.9375V39.0625C28.125 39.9254 28.8246 40.625 29.6875 40.625C30.5504 40.625 31.25 39.9254 31.25 39.0625Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M48.4375 39.0625V10.9375C48.4375 10.0746 47.7379 9.375 46.875 9.375C46.0121 9.375 45.3125 10.0746 45.3125 10.9375V39.0625C45.3125 39.9254 46.0121 40.625 46.875 40.625C47.7379 40.625 48.4375 39.9254 48.4375 39.0625Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M21.875 46.875V3.125C21.875 2.26206 21.1754 1.5625 20.3125 1.5625C19.4496 1.5625 18.75 2.26206 18.75 3.125V46.875C18.75 47.7379 19.4496 48.4375 20.3125 48.4375C21.1754 48.4375 21.875 47.7379 21.875 46.875Z"
                    fill="#EDB561"
                  />
                </svg>
                <audio
                  controls
                  controlsList="nodownload"
                  className="w-full h-5 absolute bottom-1 left-0 customized-audio-tag"
                  src={insight?.cover_photo[0]?.url ?? ''}
                />
              </span>
            ) : (
              <img
                className="h-full max-h-[258px] w-full object-cover"
                src={insight?.cover_photo[0]?.thumbnail800 ?? defaultImageViewer}
                alt=""
              />
            )}
          </div>
          <div className="h-full max-h-[258px] col-span-1">
            {insight?.cover_photo[1]?.type === 'audio' ? (
              <span className="w-full h-full bg-primary-200 flex flex-col items-center justify-center relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                  <path
                    d="M4.6875 32.8125V17.1875C4.6875 16.3246 3.98794 15.625 3.125 15.625C2.26206 15.625 1.5625 16.3246 1.5625 17.1875V32.8125C1.5625 33.6754 2.26206 34.375 3.125 34.375C3.98794 34.375 4.6875 33.6754 4.6875 32.8125Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M39.8438 32.8125V17.1875C39.8438 16.3246 39.1442 15.625 38.2812 15.625C37.4183 15.625 36.7188 16.3246 36.7188 17.1875V32.8125C36.7188 33.6754 37.4183 34.375 38.2812 34.375C39.1442 34.375 39.8438 33.6754 39.8438 32.8125Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M13.2812 39.0625V10.1562C13.2812 9.29331 12.5817 8.59375 11.7188 8.59375C10.8558 8.59375 10.1562 9.29331 10.1562 10.1563V39.0625C10.1562 39.9254 10.8558 40.625 11.7188 40.625C12.5817 40.625 13.2812 39.9254 13.2812 39.0625Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M31.25 39.0625V10.9375C31.25 10.0746 30.5504 9.375 29.6875 9.375C28.8246 9.375 28.125 10.0746 28.125 10.9375V39.0625C28.125 39.9254 28.8246 40.625 29.6875 40.625C30.5504 40.625 31.25 39.9254 31.25 39.0625Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M48.4375 39.0625V10.9375C48.4375 10.0746 47.7379 9.375 46.875 9.375C46.0121 9.375 45.3125 10.0746 45.3125 10.9375V39.0625C45.3125 39.9254 46.0121 40.625 46.875 40.625C47.7379 40.625 48.4375 39.9254 48.4375 39.0625Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M21.875 46.875V3.125C21.875 2.26206 21.1754 1.5625 20.3125 1.5625C19.4496 1.5625 18.75 2.26206 18.75 3.125V46.875C18.75 47.7379 19.4496 48.4375 20.3125 48.4375C21.1754 48.4375 21.875 47.7379 21.875 46.875Z"
                    fill="#EDB561"
                  />
                </svg>
                <audio
                  controls
                  controlsList="nodownload"
                  className="w-full h-5 absolute bottom-1 left-0 customized-audio-tag"
                  src={insight?.cover_photo[1]?.url ?? ''}
                />
              </span>
            ) : (
              <img
                className="h-full max-h-[258px] w-full object-cover"
                src={insight?.cover_photo[1]?.thumbnail800 ?? defaultImageViewer}
                alt=""
              />
            )}
          </div>
        </div>
      )}
      {insight?.cover_photo?.length === 3 && (
        <div className="grid grid-rows-2 grid-cols-2 w-full h-[258px]">
          <div className="row-span-2 h-full max-h-[258px]">
            {insight?.cover_photo[0]?.type === 'audio' ? (
              <span className="w-full h-full bg-primary-200 flex flex-col items-center justify-center relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                  <path
                    d="M4.6875 32.8125V17.1875C4.6875 16.3246 3.98794 15.625 3.125 15.625C2.26206 15.625 1.5625 16.3246 1.5625 17.1875V32.8125C1.5625 33.6754 2.26206 34.375 3.125 34.375C3.98794 34.375 4.6875 33.6754 4.6875 32.8125Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M39.8438 32.8125V17.1875C39.8438 16.3246 39.1442 15.625 38.2812 15.625C37.4183 15.625 36.7188 16.3246 36.7188 17.1875V32.8125C36.7188 33.6754 37.4183 34.375 38.2812 34.375C39.1442 34.375 39.8438 33.6754 39.8438 32.8125Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M13.2812 39.0625V10.1562C13.2812 9.29331 12.5817 8.59375 11.7188 8.59375C10.8558 8.59375 10.1562 9.29331 10.1562 10.1563V39.0625C10.1562 39.9254 10.8558 40.625 11.7188 40.625C12.5817 40.625 13.2812 39.9254 13.2812 39.0625Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M31.25 39.0625V10.9375C31.25 10.0746 30.5504 9.375 29.6875 9.375C28.8246 9.375 28.125 10.0746 28.125 10.9375V39.0625C28.125 39.9254 28.8246 40.625 29.6875 40.625C30.5504 40.625 31.25 39.9254 31.25 39.0625Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M48.4375 39.0625V10.9375C48.4375 10.0746 47.7379 9.375 46.875 9.375C46.0121 9.375 45.3125 10.0746 45.3125 10.9375V39.0625C45.3125 39.9254 46.0121 40.625 46.875 40.625C47.7379 40.625 48.4375 39.9254 48.4375 39.0625Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M21.875 46.875V3.125C21.875 2.26206 21.1754 1.5625 20.3125 1.5625C19.4496 1.5625 18.75 2.26206 18.75 3.125V46.875C18.75 47.7379 19.4496 48.4375 20.3125 48.4375C21.1754 48.4375 21.875 47.7379 21.875 46.875Z"
                    fill="#EDB561"
                  />
                </svg>
                <audio
                  controls
                  controlsList="nodownload"
                  className="w-full h-5 absolute bottom-1 left-0 customized-audio-tag"
                  src={insight?.cover_photo[0]?.url ?? ''}
                />
              </span>
            ) : (
              <img
                className="h-full max-h-[258px] w-full object-cover"
                src={insight?.cover_photo[0]?.thumbnail800 ?? defaultImageViewer}
                alt=""
              />
            )}
          </div>
          <div className="row-span-1 h-full max-h-[258px]">
            {insight?.cover_photo[1]?.type === 'audio' ? (
              <span className="w-full h-full bg-primary-200 flex flex-col items-center justify-center relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                  <path
                    d="M4.6875 32.8125V17.1875C4.6875 16.3246 3.98794 15.625 3.125 15.625C2.26206 15.625 1.5625 16.3246 1.5625 17.1875V32.8125C1.5625 33.6754 2.26206 34.375 3.125 34.375C3.98794 34.375 4.6875 33.6754 4.6875 32.8125Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M39.8438 32.8125V17.1875C39.8438 16.3246 39.1442 15.625 38.2812 15.625C37.4183 15.625 36.7188 16.3246 36.7188 17.1875V32.8125C36.7188 33.6754 37.4183 34.375 38.2812 34.375C39.1442 34.375 39.8438 33.6754 39.8438 32.8125Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M13.2812 39.0625V10.1562C13.2812 9.29331 12.5817 8.59375 11.7188 8.59375C10.8558 8.59375 10.1562 9.29331 10.1562 10.1563V39.0625C10.1562 39.9254 10.8558 40.625 11.7188 40.625C12.5817 40.625 13.2812 39.9254 13.2812 39.0625Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M31.25 39.0625V10.9375C31.25 10.0746 30.5504 9.375 29.6875 9.375C28.8246 9.375 28.125 10.0746 28.125 10.9375V39.0625C28.125 39.9254 28.8246 40.625 29.6875 40.625C30.5504 40.625 31.25 39.9254 31.25 39.0625Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M48.4375 39.0625V10.9375C48.4375 10.0746 47.7379 9.375 46.875 9.375C46.0121 9.375 45.3125 10.0746 45.3125 10.9375V39.0625C45.3125 39.9254 46.0121 40.625 46.875 40.625C47.7379 40.625 48.4375 39.9254 48.4375 39.0625Z"
                    fill="#EDB561"
                  />
                  <path
                    d="M21.875 46.875V3.125C21.875 2.26206 21.1754 1.5625 20.3125 1.5625C19.4496 1.5625 18.75 2.26206 18.75 3.125V46.875C18.75 47.7379 19.4496 48.4375 20.3125 48.4375C21.1754 48.4375 21.875 47.7379 21.875 46.875Z"
                    fill="#EDB561"
                  />
                </svg>
                <audio
                  controls
                  controlsList="nodownload"
                  className="w-full h-5 absolute bottom-1 left-0 customized-audio-tag"
                  src={insight?.cover_photo[1]?.url ?? ''}
                />
              </span>
            ) : (
              <img
                className="h-full max-h-[258px] w-full object-cover"
                src={insight?.cover_photo[1]?.thumbnail800 ?? defaultImageViewer}
                alt=""
              />
            )}
          </div>
          {insight?.collection_media_count && insight?.collection_media_count > 3 ? (
            <div className="row-span-1 h-full max-h-[258px] relative">
              {insight?.cover_photo[2]?.type === 'audio' ? (
                <span className="w-full h-full bg-primary-200 flex flex-col items-center justify-center relative">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                    <path
                      d="M4.6875 32.8125V17.1875C4.6875 16.3246 3.98794 15.625 3.125 15.625C2.26206 15.625 1.5625 16.3246 1.5625 17.1875V32.8125C1.5625 33.6754 2.26206 34.375 3.125 34.375C3.98794 34.375 4.6875 33.6754 4.6875 32.8125Z"
                      fill="#EDB561"
                    />
                    <path
                      d="M39.8438 32.8125V17.1875C39.8438 16.3246 39.1442 15.625 38.2812 15.625C37.4183 15.625 36.7188 16.3246 36.7188 17.1875V32.8125C36.7188 33.6754 37.4183 34.375 38.2812 34.375C39.1442 34.375 39.8438 33.6754 39.8438 32.8125Z"
                      fill="#EDB561"
                    />
                    <path
                      d="M13.2812 39.0625V10.1562C13.2812 9.29331 12.5817 8.59375 11.7188 8.59375C10.8558 8.59375 10.1562 9.29331 10.1562 10.1563V39.0625C10.1562 39.9254 10.8558 40.625 11.7188 40.625C12.5817 40.625 13.2812 39.9254 13.2812 39.0625Z"
                      fill="#EDB561"
                    />
                    <path
                      d="M31.25 39.0625V10.9375C31.25 10.0746 30.5504 9.375 29.6875 9.375C28.8246 9.375 28.125 10.0746 28.125 10.9375V39.0625C28.125 39.9254 28.8246 40.625 29.6875 40.625C30.5504 40.625 31.25 39.9254 31.25 39.0625Z"
                      fill="#EDB561"
                    />
                    <path
                      d="M48.4375 39.0625V10.9375C48.4375 10.0746 47.7379 9.375 46.875 9.375C46.0121 9.375 45.3125 10.0746 45.3125 10.9375V39.0625C45.3125 39.9254 46.0121 40.625 46.875 40.625C47.7379 40.625 48.4375 39.9254 48.4375 39.0625Z"
                      fill="#EDB561"
                    />
                    <path
                      d="M21.875 46.875V3.125C21.875 2.26206 21.1754 1.5625 20.3125 1.5625C19.4496 1.5625 18.75 2.26206 18.75 3.125V46.875C18.75 47.7379 19.4496 48.4375 20.3125 48.4375C21.1754 48.4375 21.875 47.7379 21.875 46.875Z"
                      fill="#EDB561"
                    />
                  </svg>
                  <audio
                    controls
                    controlsList="nodownload"
                    className="w-full h-5 absolute bottom-1 left-0 customized-audio-tag"
                    src={insight?.cover_photo[2]?.url ?? ''}
                  />
                </span>
              ) : (
                <img
                  className="h-full max-h-[258px] w-full object-cover"
                  src={insight?.cover_photo[2]?.thumbnail800 ?? defaultImageViewer}
                  alt=""
                />
              )}

              <span className="absolute inset-0 flex items-center justify-center text-white text-xl font-bold">
                +{insight?.collection_media_count - 3}
              </span>
            </div>
          ) : (
            <div className="row-span-1 h-full max-h-[258px]">
              {insight?.cover_photo[2]?.type === 'audio' ? (
                <span className="w-full h-full bg-primary-200 flex flex-col items-center justify-center relative">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                    <path
                      d="M4.6875 32.8125V17.1875C4.6875 16.3246 3.98794 15.625 3.125 15.625C2.26206 15.625 1.5625 16.3246 1.5625 17.1875V32.8125C1.5625 33.6754 2.26206 34.375 3.125 34.375C3.98794 34.375 4.6875 33.6754 4.6875 32.8125Z"
                      fill="#EDB561"
                    />
                    <path
                      d="M39.8438 32.8125V17.1875C39.8438 16.3246 39.1442 15.625 38.2812 15.625C37.4183 15.625 36.7188 16.3246 36.7188 17.1875V32.8125C36.7188 33.6754 37.4183 34.375 38.2812 34.375C39.1442 34.375 39.8438 33.6754 39.8438 32.8125Z"
                      fill="#EDB561"
                    />
                    <path
                      d="M13.2812 39.0625V10.1562C13.2812 9.29331 12.5817 8.59375 11.7188 8.59375C10.8558 8.59375 10.1562 9.29331 10.1562 10.1563V39.0625C10.1562 39.9254 10.8558 40.625 11.7188 40.625C12.5817 40.625 13.2812 39.9254 13.2812 39.0625Z"
                      fill="#EDB561"
                    />
                    <path
                      d="M31.25 39.0625V10.9375C31.25 10.0746 30.5504 9.375 29.6875 9.375C28.8246 9.375 28.125 10.0746 28.125 10.9375V39.0625C28.125 39.9254 28.8246 40.625 29.6875 40.625C30.5504 40.625 31.25 39.9254 31.25 39.0625Z"
                      fill="#EDB561"
                    />
                    <path
                      d="M48.4375 39.0625V10.9375C48.4375 10.0746 47.7379 9.375 46.875 9.375C46.0121 9.375 45.3125 10.0746 45.3125 10.9375V39.0625C45.3125 39.9254 46.0121 40.625 46.875 40.625C47.7379 40.625 48.4375 39.9254 48.4375 39.0625Z"
                      fill="#EDB561"
                    />
                    <path
                      d="M21.875 46.875V3.125C21.875 2.26206 21.1754 1.5625 20.3125 1.5625C19.4496 1.5625 18.75 2.26206 18.75 3.125V46.875C18.75 47.7379 19.4496 48.4375 20.3125 48.4375C21.1754 48.4375 21.875 47.7379 21.875 46.875Z"
                      fill="#EDB561"
                    />
                  </svg>
                  <audio
                    controls
                    controlsList="nodownload"
                    className="w-full h-5 absolute bottom-1 left-0 customized-audio-tag"
                    src={insight?.cover_photo[2]?.url ?? ''}
                  />
                </span>
              ) : (
                <img
                  className="h-full max-h-[258px] w-full object-cover"
                  src={insight?.cover_photo[2]?.thumbnail800 ?? defaultImageViewer}
                  alt=""
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
