/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/named */
import { useGoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';
import { AppleUser, FacebookUser, GoogleUser, useUser } from '@hooks/useUser';

import { Button } from '@components/Button';
import { Input } from '@components/Input';

// @ts-expect-error
import { IResolveParams, LoginSocialApple, LoginSocialFacebook } from 'reactjs-social-login';

import { AppleIcon } from '@assets/icons/AppleIcon';
import { FacebookIcon } from '@assets/icons/FacebookIcon';
import { GoogleIcon } from '@assets/icons/GoogleIcon';
import logo from '@assets/logo.svg';

import { PasswordInfo } from '@components/Auth/PasswordInfo';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { api } from 'services/axios';
import { isEmail, isPhoneNumber, showErrorMessage } from 'utils/auxFunctions';
import { toast } from 'utils/toast';

const registerFormSchema = z.object({
  emailOrPhone: z.string(),
  password: z.string(),
  confirmPassword: z.string(),
});

export const countryCodes = [
  { code: '+351', name: '🇵🇹' },
  { code: '+55', name: '🇧🇷' },
  { code: '+1', name: '🇺🇸' },
];

type registerFormInputs = z.infer<typeof registerFormSchema>;

export default function RegisterForm() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<registerFormInputs>({
    resolver: zodResolver(registerFormSchema),
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createUser } = useUser();
  const [validationsError, setValidationsError] = useState<
    'emailOrPhone' | 'passwordNotMatch' | 'password' | 'confirmPassword' | null
  >(null);
  const [userLanguage, setUserLanguage] = useState<string>('en');

  useEffect(() => {
    const browserLanguage = navigator.language;

    if (browserLanguage.includes('pt')) {
      setUserLanguage('pt');
    } else {
      setUserLanguage('en');
    }
  }, []);

  async function handleRegister(data: registerFormInputs) {
    let emailOrPhone = data.emailOrPhone;
    if (!isEmail(emailOrPhone) && !isPhoneNumber(emailOrPhone) && !isPhoneNumber('+' + emailOrPhone)) {
      toast({
        label: 'Error',
        message: userLanguage === 'pt' ? 'Email inválido' : 'Invalid Email',
        type: 'error',
      });
      setValidationsError('emailOrPhone');
      return;
    }

    if (data.password.length < 8) {
      toast({
        label: 'Error',
        message: t('password_info_a'),
        type: 'error',
      });
      setValidationsError('password');
      return;
    }

    if (data.confirmPassword.length < 8) {
      toast({
        label: 'Error',
        message: t('password_info_a'),
        type: 'error',
      });
      setValidationsError('confirmPassword');
      return;
    }

    if (data.password !== data.confirmPassword) {
      toast({
        label: 'Error',
        message: t('password_dont_match'),
        type: 'error',
      });
      setValidationsError('passwordNotMatch');
      return;
    }
    if (isPhoneNumber(emailOrPhone) && emailOrPhone.startsWith('+')) {
      emailOrPhone = emailOrPhone.replaceAll('+', '');
    }
    const response = await createUser(emailOrPhone, data.password, data.confirmPassword);
    if (!response) {
      setValidationsError(null);
      return;
    }
    if (response.status === true && response.message.includes('Two-factor authentication is not activated')) {
      navigate(`/two-factor?emailOrPhone=${emailOrPhone}`);
    } else if (response.status === true) navigate(`/login`);
  }

  const handleRegisterWithGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const response = await fetch(
        `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${codeResponse.access_token}`,
      );
      const user: GoogleUser = await response.json();
      try {
        const { data } = await api.post('/auth/register', {
          email_or_phone: user.email,
          first_name: user.given_name,
          last_name: user.family_name,
          provider: 'google',
          provider_id: user.id,
          image: user.picture,
          language: user.locale,
        });
        if (data.status === true && data.message.includes('Two-factor authentication is not activated')) {
          api.post('/auth/resend-two-factor-code', {
            email_or_phone: user.email,
          });
          navigate(`/two-factor?emailOrPhone=${user.email}`);
        } else if (data.status === true) navigate(`/login`);

        toast({
          label: 'Success',
          message: t('created_successfully'),
          type: 'success',
        });
      } catch (error: any) {
        showErrorMessage(error);
      }
    },
    onError: () =>
      toast({
        label: 'Error',
        message: userLanguage === 'pt' ? 'Autenticação falhou' : 'Authentication failed',
        type: 'error',
      }),
  });

  async function handleRegisterWithFacebook(obj: { accessToken: string }) {
    const accessToken = obj.accessToken;
    const data = await fetch(
      `https://graph.facebook.com/me?fields=id,first_name,last_name,email,picture,location&access_token=${accessToken}`,
    );
    const user: FacebookUser = await data.json();
    if (!user.email) {
      toast({
        label: 'Error',
        message:
          userLanguage === 'pt'
            ? 'Falha ao obter o email do utilizador, certifique-se que o campo é publico'
            : "Failed to get the user's email, make sure the field is set to public",
        type: 'error',
      });
      return;
    }
    try {
      const { data } = await api.post('/auth/register', {
        email_or_phone: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        provider: 'facebook',
        provider_id: user.id,
        image: user.picture.data.url,
        language: user.location || '',
      });
      if (data.status === true && data.message.includes('Two-factor authentication is not activated')) {
        navigate(`/two-factor?emailOrPhone=${user.email}`);
      } else if (data.status === true) navigate(`/login`);
      toast({
        label: 'Success',
        message: userLanguage === 'pt' ? 'Conta criada com sucesso' : 'Account created successfully',
        type: 'success',
      });
    } catch (error: any) {
      showErrorMessage(error);
    }
  }

  async function handleRegisterWithApple(obj: AppleUser) {
    const userJWT: { email: string; sub: string } = jwtDecode(obj.authorization.id_token);
    try {
      if (obj?.user) {
        const { user } = obj;
        const { data } = await api.post('/auth/register', {
          email_or_phone: user.email,
          first_name: user.name.firstName,
          last_name: user.name.lastName,
          provider: 'apple',
          provider_id: userJWT.sub,
        });
        if (data.status === true && data.message.includes('Two-factor authentication is not activated')) {
          navigate(`/two-factor?emailOrPhone=${user.email}`);
        } else if (data.status === true) navigate(`/login`);
        toast({
          label: 'Success',
          message: userLanguage === 'pt' ? 'Conta criada com sucesso' : 'Account created successfully',
          type: 'success',
        });
      } else {
        const { data } = await api.post('/auth/register', {
          email_or_phone: userJWT.email,
          first_name: '',
          last_name: '',
          provider: 'apple',
          provider_id: userJWT.sub,
        });
        if (data.status === true && data.message.includes('Two-factor authentication is not activated')) {
          navigate(`/two-factor?emailOrPhone=${userJWT.email}`);
        } else if (data.status === true) navigate(`/login`);

        toast({
          label: 'Success',
          message: userLanguage === 'pt' ? 'Conta criada com sucesso' : 'Account created successfully',
          type: 'success',
        });
      }
    } catch (error: any) {
      showErrorMessage(error);
    }
  }

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <main className="h-[80vh]">
        <form
          onSubmit={handleSubmit(handleRegister)}
          action=""
          className="flex flex-col items-center w-full max-w-[400px] bg-white p-8 shadow-md border border-gray-100"
        >
          <img className="mb-3 shadow-md rounded-lg" width={48} height={48} src={logo} alt="Logo" />
          <h1 className="text-primary-200 text-xl font-medium text-center">
            {userLanguage === 'pt'
              ? 'Crie a sua conta para partilhar as memórias e saberes da família e amigos.'
              : 'Create your account to share memories and knowledge from family and friends.'}
          </h1>
          <section className="w-full mt-10  flex flex-col gap-5">
            <div className="flex items-center gap-1">
              <Input
                {...register('emailOrPhone')}
                type="text"
                identificator="emailOrPhone"
                data-test="register-email"
                label={userLanguage === 'pt' ? 'Email' : 'Email'}
                required
                redBorder={validationsError === 'emailOrPhone'}
              />
            </div>
            <Input
              {...register('password')}
              type="password"
              identificator="password"
              data-test="register-password"
              label={userLanguage === 'pt' ? 'Senha' : 'Password'}
              required
              redBorder={validationsError === 'password' || validationsError === 'passwordNotMatch'}
            />
            <Input
              {...register('confirmPassword')}
              type="password"
              identificator="confirmPassword"
              data-test="register-confirm-password"
              label={userLanguage === 'pt' ? 'Confirme a Senha' : 'Confirm password'}
              required
              redBorder={validationsError === 'confirmPassword' || validationsError === 'passwordNotMatch'}
            />
            <PasswordInfo userLanguage={userLanguage} />
          </section>

          <section className="mt-10 flex flex-col items-center justify-center gap-5 max-w-[256px]">
            <Button
              disabled={isSubmitting}
              text={userLanguage === 'pt' ? 'Registrar' : 'Register'}
              type="submit"
              data-test="register-submit"
              size="large"
              variant="primary"
            />
            <span className="text-[10px] text-center">
              {userLanguage === 'pt'
                ? ' Ao clicar em Registrar, você concorda com os'
                : 'By clicking Register, you agree to the'}{' '}
              <Link className="font-bold text-secondary-300" to={t('terms_of_use_link')} target="_blank">
                {userLanguage === 'pt' ? 'Termos de Uso' : 'Terms of use'}
              </Link>{' '}
              {userLanguage === 'pt' ? 'e leu nossa' : ' and read our'}{' '}
              <Link className="font-bold text-secondary-300" to={t('privacy_policy_link')} target="_blank">
                {userLanguage === 'pt' ? 'Política de Privacidade' : 'Privacy Policy'}
              </Link>
              .
            </span>
          </section>

          <div className="flex w-full items-center mt-10">
            <hr className="w-full h-[1px] border" />
            <span className="px-5 text-primary-200 w-full text-xs text-center">
              {userLanguage === 'pt' ? 'Ou' : 'Or'}
            </span>
            <hr className="w-full h-[1px] border" />
          </div>

          <section className="flex mt-6 justify-between w-full">
            <LoginSocialFacebook
              isOnlyGetToken
              appId={import.meta.env.VITE_FACEBOOK_OAUTH_CLIENT_ID}
              onResolve={({ data }: IResolveParams) => {
                handleRegisterWithFacebook(data);
              }}
            >
              <button
                disabled={isSubmitting}
                onClick={(e) => e.preventDefault()}
                className="py-4 px-8 bg-white rounded shadow-md hover:scale-110 transition-transform"
              >
                <FacebookIcon height={30} width={25} />
              </button>
            </LoginSocialFacebook>
            <button
              disabled={isSubmitting}
              onClick={(e) => {
                e.preventDefault();
                handleRegisterWithGoogle();
              }}
              className="py-4 px-8 bg-white rounded shadow-md hover:scale-110 transition-transform"
            >
              <GoogleIcon height={25} width={25} />
            </button>
            <LoginSocialApple
              client_id={import.meta.env.VITE_APPLE_OAUTH_CLIENT_ID}
              scope={'name email'}
              redirect_uri={window.location.origin + '/feed'}
              onResolve={({ data }: IResolveParams) => {
                handleRegisterWithApple(data);
              }}
            >
              <button
                disabled={isSubmitting}
                onClick={(e) => e.preventDefault()}
                id="apple-sign-in"
                className="py-4 px-8 bg-white rounded shadow-md hover:scale-110 transition-transform"
              >
                <AppleIcon height={30} />
              </button>
            </LoginSocialApple>
          </section>
          <span className="text-xs text-start w-full mt-5">
            {userLanguage === 'pt' ? 'Tem uma conta?' : 'Have an account?'}{' '}
            <Link className="text-secondary-300 underline" to={'/login'}>
              Login
            </Link>
          </span>
        </form>
      </main>
    </div>
  );
}
