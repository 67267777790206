import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAds } from '@hooks/useAds';

import Loading from '@components/Loading';
import AdsPrimaryInformation from './PrimaryInformation';
import AdsSecondaryInformation from './SecondaryInformation';

export default function AdsStatisticsPage() {
  const { t } = useTranslation();
  const { ads, setAds, getAdsInfo } = useAds();

  const { adsUuid } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const getAdsInformation = useCallback(async () => {
    setIsLoading(true);
    if (!adsUuid) return;
    getAdsInfo(adsUuid).then((data) => {
      setAds({
        title: data.title,
        description: data.description,
        link: data.external_url,
        file: undefined,
        awsMediaUrl: data.aws_url,
        url: data.aws_url,
        thumbnail: data.aws_url,
        activeState: Boolean(data.active),
        maxClickImpression: data.max_clicks.toString(),
        maxViewImpression: data.max_views.toString(),
        startDate: data.start_date,
        expirationDate: data.end_date,
        fileType: data.aws_type,
        views: data.views,
        clicks: data.clicks,
      });
    });
    setIsLoading(false);
  }, [adsUuid, setAds, getAdsInfo]);

  useEffect(() => {
    getAdsInformation();
  }, [adsUuid]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <section className="flex justify-center w-full min-h-[calc(100vh-88px)] bg-backgray py-12 relative">
          <div className="flex flex-col gap-8 w-[435px] mb-5">
            <h2 className="text-xl font-medium">{t('ads_title')}</h2>
            <input
              value={ads.title}
              maxLength={100}
              placeholder={t('add_a_ads_title')}
              type="text"
              className="w-full bg-white py-4 px-5 border border-grey-300 rounded-md text-sm placeholder:text-grey-300 outline-grey-200"
              readOnly
            />
            <h2 className="text-xl font-medium">{t('ads_statistics')}</h2>
            <AdsPrimaryInformation />

            <AdsSecondaryInformation />
          </div>
        </section>
      )}
    </>
  );
}
