import * as Dialog from '@radix-ui/react-dialog';

import { Collection, useInsights } from '@hooks/useInsights';
import TextEditorModal from '@components/TextEditorModal';

interface DescriptionBoxProps {
  collection: Collection;
}

export default function DescriptionBox({ collection }: DescriptionBoxProps) {
  const { editCollection } = useInsights();
  function handleDeleteDescription() {
    editCollection({ description: '' }, collection.uuid);
  }

  return (
    <div className="flex flex-col w-full p-1 border border-grey-600 rounded-md mt-8">
      <div className="flex items-center justify-end">
        <Dialog.Root>
          <Dialog.Trigger asChild>
            <button className="hover:opacity-70">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 15" fill="none">
                <g clipPath="url(#clip0_2346_81977)">
                  <g filter="url(#filter0_d_2346_81977)">
                    <path
                      d="M10.0201 3.27015L12.2298 5.47985M10.9576 2.33265C11.5678 1.72245 12.5571 1.72245 13.1673 2.33265C13.7775 2.94284 13.7775 3.93216 13.1673 4.54235L4.5625 13.1472H2.375V10.9153L10.9576 2.33265Z"
                      stroke="#282A74"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      shapeRendering="crispEdges"
                    />
                  </g>
                </g>
                <defs>
                  <filter
                    id="filter0_d_2346_81977"
                    x="-2.125"
                    y="1.375"
                    width="20.25"
                    height="20.2725"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2346_81977" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2346_81977" result="shape" />
                  </filter>
                  <clipPath id="clip0_2346_81977">
                    <rect width="15" height="15" fill="white" transform="translate(0.5)" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </Dialog.Trigger>
          <TextEditorModal isEditingInsight collection={collection} />
        </Dialog.Root>
        <button onClick={handleDeleteDescription} className="hover:opacity-70">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 15" fill="none">
            <g clipPath="url(#clip0_2346_81978)">
              <g filter="url(#filter0_d_2346_81978)">
                <path
                  d="M12.375 4.375L11.8329 11.9641C11.7862 12.6182 11.2419 13.125 10.5861 13.125H5.4139C4.7581 13.125 4.2138 12.6182 4.16708 11.9641L3.625 4.375M6.75 6.875V10.625M9.25 6.875V10.625M9.875 4.375V2.5C9.875 2.15482 9.59518 1.875 9.25 1.875H6.75C6.40482 1.875 6.125 2.15482 6.125 2.5V4.375M3 4.375H13"
                  stroke="#CD0C0C"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  shapeRendering="crispEdges"
                />
              </g>
            </g>
            <defs>
              <filter
                id="filter0_d_2346_81978"
                x="-1.5"
                y="1.375"
                width="19"
                height="20.25"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2346_81978" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2346_81978" result="shape" />
              </filter>
              <clipPath id="clip0_2346_81978">
                <rect width="15" height="15" fill="white" transform="translate(0.5)" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
      <div className="px-5 py-5">
        <span
          className="text-black-100 text-sm break-words"
          dangerouslySetInnerHTML={{ __html: collection.description }}
        />
      </div>
    </div>
  );
}
