import { api } from '../services/axios';
import { create } from 'zustand';
import { showErrorMessage } from '../utils/auxFunctions';

interface Pagination {
  current_page: number;
  last_page: number;
  total: number;
  last_page_url: string | null;
}

export interface Comment {
  uuid: string;
  body: string;
  user: {
    uuid: string;
    first_name: string;
    last_name: string;
    image: string;
  };
  elapsed_time?: string;
}

interface ResponseDTO {
  uuid: string;
  body: string;
}

interface CommentsProps {
  pagination: Pagination;
  getCommentsForInsight: (groupUuid: string, insightUuid: string, params?: string) => Promise<Comment[]>;
  addComment: (groupUuid: string, insightUuid: string, body: string) => Promise<ResponseDTO>;
  updateComment: (groupUuid: string, insightUuid: string, commentUuid: string, body: string) => Promise<ResponseDTO>;
  deleteComment: (groupUuid: string, insightUuid: string, commentUuid: string) => Promise<ResponseDTO>;
}

export const useComments = create<CommentsProps>((set) => ({
  pagination: {
    current_page: 1,
    last_page: 1,
    total: 1,
    last_page_url: null,
  },
  getCommentsForInsight: async (groupUuid: string, insightUuid: string, params?: string) => {
    try {
      const { data } = await api.get(`/groups/${groupUuid}/posts/${insightUuid}/comments` + (params ? params : ''));
      set({
        pagination: {
          current_page: data.content.data.current_page,
          last_page: data.content.data.last_page,
          total: data.content.data.total,
          last_page_url: data.content.data.last_page_url,
        },
      });
      return data.content.data.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  addComment: async (groupUuid: string, insightUuid: string, body: string) => {
    try {
      const { data } = await api.post(`/groups/${groupUuid}/posts/${insightUuid}/comments`, { body });
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  updateComment: async (groupUuid: string, insightUuid: string, commentUuid: string, body: string) => {
    try {
      const { data } = await api.put(`/groups/${groupUuid}/posts/${insightUuid}/comments/${commentUuid}`, { body });
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  deleteComment: async (groupUuid: string, insightUuid: string, commentUuid: string) => {
    try {
      const { data } = await api.delete(`/groups/${groupUuid}/posts/${insightUuid}/comments/${commentUuid}`);
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },
}));
