import * as Dialog from '@radix-ui/react-dialog';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Collection, CoverPhoto, MediaToEdit, useInsights } from '@hooks/useInsights';

import ButtonAction from '@components/ButtonAction';
import ImageViewer from '@components/ImageViewer';

import moveDown from '@assets/icons/Button/move-down.svg';
import moveUp from '@assets/icons/Button/move-up.svg';
import star from '@assets/icons/Button/star.svg';
import trash from '@assets/icons/Button/trash.svg';

interface CollectionFileEditModalProps {
  index: number;
  file?: File;
  media?: MediaToEdit;
  collection: Collection;
  isEditingInsight?: boolean;
}

export default function CollectionFileEditModal({
  index,
  file,
  collection,
  media,
}: Readonly<CollectionFileEditModalProps>) {
  const { t } = useTranslation();
  const closeButton = useRef<HTMLButtonElement>(null);

  const closeButtonModal = useRef<HTMLButtonElement>(null);

  const { editCollection, editingInsight, editInsight } = useInsights();

  function handleConvertToCoverPhoto() {
    editCollection({ ...collection, coverPhoto: { file: file } }, collection.uuid);
    closeButton.current?.click();
  }

  function handleMoveFileUp() {
    if (file) {
      const newCollectionFiles = collection.uploadedFiles;
      if (newCollectionFiles) {
        const fileToMove = newCollectionFiles.splice(index, 1)[0];
        newCollectionFiles.splice(index - 1, 0, fileToMove);
        editCollection({ ...collection, uploadedFiles: newCollectionFiles }, collection.uuid);
      }
    }
    if (media) {
      const newCollectionFiles = collection.mediasToEdit;
      if (newCollectionFiles) {
        const fileToMove = newCollectionFiles.splice(index, 1)[0];
        newCollectionFiles.splice(index - 1, 0, fileToMove);
        editCollection({ ...collection, mediasToEdit: newCollectionFiles }, collection.uuid);
      }
    }
    closeButton.current?.click();
  }

  function handleMoveFileDown() {
    if (file) {
      const newCollectionFiles = collection.uploadedFiles;
      if (newCollectionFiles) {
        const fileToMove = newCollectionFiles.splice(index, 1)[0];
        newCollectionFiles.splice(index + 1, 0, fileToMove);
        editCollection({ ...collection, uploadedFiles: newCollectionFiles }, collection.uuid);
      }
    }
    if (media) {
      const newCollectionFiles = collection.mediasToEdit;
      if (newCollectionFiles) {
        const fileToMove = newCollectionFiles.splice(index, 1)[0];
        newCollectionFiles.splice(index + 1, 0, fileToMove);
        editCollection({ ...collection, mediasToEdit: newCollectionFiles }, collection.uuid);
      }
    }
    closeButton.current?.click();
  }

  function handleDeleteFile() {
    if (file) {
      const newCollectionFiles = collection.uploadedFiles;
      const newCoverPhoto = collection.coverPhoto;
      if (
        collection.coverPhoto.file &&
        collection.coverPhoto.file.name === file.name &&
        collection.coverPhoto.file.size === file.size &&
        collection.coverPhoto.file.lastModified === file.lastModified
      ) {
        newCoverPhoto.file = null;
        newCoverPhoto.url = '';
      }
      if (newCollectionFiles) {
        newCollectionFiles.splice(index, 1);
        editCollection(
          { ...collection, uploadedFiles: newCollectionFiles, coverPhoto: newCoverPhoto },
          collection.uuid,
        );
        closeButton.current?.click();
      }
    }
    if (media) {
      const newCollectionFiles = collection.mediasToEdit;
      if (newCollectionFiles) {
        newCollectionFiles.splice(index, 1);
        editCollection({ ...collection, mediasToEdit: newCollectionFiles }, collection.uuid);
        closeButton.current?.click();
      }
    }
  }

  function handleAddMediaToCoverPhoto(index: number) {
    const coverPhotos = editingInsight.cover_photo || [];
    if (media?.uuid && media?.type && media?.url) {
      const newCoverPhoto: CoverPhoto = {
        uuid: media.uuid,
        duration: 0,
        url: media.url,
        order: index,
        type: media.type,
      };
      coverPhotos.splice(index, 1, newCoverPhoto);
    } else if (media?.file) {
      const newCoverPhoto: CoverPhoto = {
        uuid: '',
        duration: 0,
        url: URL.createObjectURL(media.file),
        file: media.file,
        order: index,
        type: media.file.type.includes('video') ? 'video' : media.file.type.includes('image') ? 'image' : 'audio',
      };
      coverPhotos.splice(index, 1, newCoverPhoto);
    }
    editInsight({ cover_photo: coverPhotos }, true);
    closeButtonModal.current?.click();
  }

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '10',
        }}
      />
      <Dialog.Content
        forceMount
        className={'z-40 w-[calc(100vw-200px)] rounded-lg p-4 right-0 bottom-0 fixed bg-white font-poppins'}
      >
        <Dialog.Close ref={closeButton} className={'absolute top-4 right-8'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
            <path
              d="M3.35209 0.489335C2.69963 -0.163112 1.6418 -0.163112 0.989335 0.489335C0.336888 1.1418 0.336888 2.19963 0.989335 2.85209L10.6372 12.4999L0.989435 22.1478C0.336989 22.8002 0.336989 23.8581 0.989435 24.5105C1.6419 25.1629 2.69973 25.1629 3.35217 24.5105L12.9999 14.8627L22.6478 24.5105C23.3002 25.1629 24.3581 25.1629 25.0105 24.5105C25.6629 23.8581 25.6629 22.8002 25.0105 22.1478L15.3627 12.4999L25.0107 2.85209C25.6631 2.19963 25.6631 1.1418 25.0107 0.489335C24.3583 -0.163112 23.3004 -0.163112 22.648 0.489335L12.9999 10.1372L3.35209 0.489335Z"
              fill="#737373"
            />
          </svg>
        </Dialog.Close>
        <div className="w-full flex flex-col gap-1 p-10">
          {index > 0 && <ButtonAction onClick={handleMoveFileUp} src={moveUp} text={t('move_position_up')} />}
          {file && index < collection?.uploadedFiles?.length - 1 && (
            <ButtonAction onClick={handleMoveFileDown} src={moveDown} text={t('move_position_down')} />
          )}
          {media && collection?.mediasToEdit && index < collection?.mediasToEdit?.length - 1 && (
            <ButtonAction onClick={handleMoveFileDown} src={moveDown} text={t('move_position_down')} />
          )}
          {!media && file && !file.type.includes('audio') && (
            <ButtonAction onClick={handleConvertToCoverPhoto} src={star} text={t('make_cover_photo')} />
          )}
          {media && (
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <ButtonAction src={star} text={t('make_cover_photo')} />
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay
                  style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                    inset: 0,
                    background: 'rgba(0, 0, 0, 0.2)',
                    zIndex: '50',
                  }}
                />
                <Dialog.Content
                  forceMount
                  className={'z-[60] w-[calc(100vw-200px)] rounded-lg p-4 right-0 bottom-0 fixed bg-white font-poppins'}
                >
                  <Dialog.Close ref={closeButtonModal} className={'absolute top-4 right-8'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                      <path
                        d="M3.35209 0.489335C2.69963 -0.163112 1.6418 -0.163112 0.989335 0.489335C0.336888 1.1418 0.336888 2.19963 0.989335 2.85209L10.6372 12.4999L0.989435 22.1478C0.336989 22.8002 0.336989 23.8581 0.989435 24.5105C1.6419 25.1629 2.69973 25.1629 3.35217 24.5105L12.9999 14.8627L22.6478 24.5105C23.3002 25.1629 24.3581 25.1629 25.0105 24.5105C25.6629 23.8581 25.6629 22.8002 25.0105 22.1478L15.3627 12.4999L25.0107 2.85209C25.6631 2.19963 25.6631 1.1418 25.0107 0.489335C24.3583 -0.163112 23.3004 -0.163112 22.648 0.489335L12.9999 10.1372L3.35209 0.489335Z"
                        fill="#737373"
                      />
                    </svg>
                  </Dialog.Close>
                  <div className="flex flex-col items-center gap-5">
                    <h1 className="text-xl text-black-100 font-semibold">Cover Photos</h1>
                    <div className="flex items-center justify-center gap-5">
                      <button
                        onClick={() => handleAddMediaToCoverPhoto(0)}
                        className="w-[150px] h-[150px] relative brightness-75 hover:opacity-90"
                      >
                        {editingInsight?.cover_photo &&
                          editingInsight?.cover_photo[0]?.url &&
                          !editingInsight?.cover_photo[0]?.file?.type.includes('audio') &&
                          editingInsight?.cover_photo[0]?.uuid && (
                            <ImageViewer
                              className="w-full h-full object-cover"
                              url={(editingInsight?.cover_photo && editingInsight?.cover_photo[0]?.url) || ''}
                            />
                          )}

                        {editingInsight?.cover_photo &&
                          editingInsight?.cover_photo[0]?.url &&
                          !editingInsight?.cover_photo[0]?.file?.type.includes('audio') &&
                          !editingInsight?.cover_photo[0]?.uuid && (
                            <img
                              alt="Preview"
                              className="w-full h-full object-cover"
                              src={(editingInsight?.cover_photo && editingInsight?.cover_photo[0]?.url) || ''}
                            />
                          )}
                        <span className="flex items-center justify-center absolute top-0 left-0 w-full h-full bg-black-100 bg-opacity-40">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 24 25"
                            fill="none"
                          >
                            <path
                              d="M2.5 22.4691L8.04927 20.3347C8.40421 20.1982 8.58168 20.1299 8.74772 20.0408C8.8952 19.9617 9.0358 19.8703 9.16804 19.7677C9.31692 19.6521 9.45137 19.5177 9.72028 19.2488L21 7.96905C22.1046 6.86449 22.1046 5.07362 21 3.96905C19.8955 2.86448 18.1046 2.86448 17 3.96905L5.72028 15.2488C5.45138 15.5177 5.31692 15.6521 5.20139 15.801C5.09877 15.9333 5.0074 16.0739 4.92823 16.2213C4.83911 16.3874 4.77085 16.5648 4.63433 16.9198L2.5 22.4691ZM2.5 22.4691L4.55812 17.118C4.7054 16.7351 4.77903 16.5436 4.90534 16.4559C5.01572 16.3793 5.1523 16.3503 5.2843 16.3755C5.43533 16.4044 5.58038 16.5494 5.87048 16.8395L8.12957 19.0986C8.41967 19.3887 8.56472 19.5337 8.59356 19.6848C8.61877 19.8168 8.58979 19.9534 8.51314 20.0637C8.42545 20.19 8.23399 20.2637 7.85107 20.411L2.5 22.4691Z"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </button>
                      <button
                        onClick={() => handleAddMediaToCoverPhoto(1)}
                        className="w-[150px] h-[150px] relative brightness-75 hover:opacity-90"
                      >
                        {editingInsight?.cover_photo &&
                          editingInsight?.cover_photo[1]?.url &&
                          !editingInsight?.cover_photo[1]?.file?.type.includes('audio') &&
                          editingInsight?.cover_photo[1]?.uuid && (
                            <ImageViewer
                              className="w-full h-full object-cover"
                              url={(editingInsight?.cover_photo && editingInsight?.cover_photo[1]?.url) || ''}
                            />
                          )}
                        {editingInsight?.cover_photo &&
                          editingInsight?.cover_photo[1]?.url &&
                          !editingInsight?.cover_photo[1]?.file?.type.includes('audio') &&
                          !editingInsight?.cover_photo[1]?.uuid && (
                            <img
                              alt="Preview"
                              className="w-full h-full object-cover"
                              src={(editingInsight?.cover_photo && editingInsight?.cover_photo[1]?.url) || ''}
                            />
                          )}
                        <span className="flex items-center justify-center absolute top-0 left-0 w-full h-full bg-black-100 bg-opacity-40">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 24 25"
                            fill="none"
                          >
                            <path
                              d="M2.5 22.4691L8.04927 20.3347C8.40421 20.1982 8.58168 20.1299 8.74772 20.0408C8.8952 19.9617 9.0358 19.8703 9.16804 19.7677C9.31692 19.6521 9.45137 19.5177 9.72028 19.2488L21 7.96905C22.1046 6.86449 22.1046 5.07362 21 3.96905C19.8955 2.86448 18.1046 2.86448 17 3.96905L5.72028 15.2488C5.45138 15.5177 5.31692 15.6521 5.20139 15.801C5.09877 15.9333 5.0074 16.0739 4.92823 16.2213C4.83911 16.3874 4.77085 16.5648 4.63433 16.9198L2.5 22.4691ZM2.5 22.4691L4.55812 17.118C4.7054 16.7351 4.77903 16.5436 4.90534 16.4559C5.01572 16.3793 5.1523 16.3503 5.2843 16.3755C5.43533 16.4044 5.58038 16.5494 5.87048 16.8395L8.12957 19.0986C8.41967 19.3887 8.56472 19.5337 8.59356 19.6848C8.61877 19.8168 8.58979 19.9534 8.51314 20.0637C8.42545 20.19 8.23399 20.2637 7.85107 20.411L2.5 22.4691Z"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </button>
                      <button
                        onClick={() => handleAddMediaToCoverPhoto(2)}
                        className="w-[150px] h-[150px] relative brightness-75 hover:opacity-90"
                      >
                        {editingInsight?.cover_photo &&
                          editingInsight?.cover_photo[2]?.url &&
                          !editingInsight?.cover_photo[2]?.file?.type.includes('audio') &&
                          editingInsight?.cover_photo[2]?.uuid && (
                            <ImageViewer
                              className="w-full h-full object-cover"
                              url={(editingInsight?.cover_photo && editingInsight?.cover_photo[2]?.url) || ''}
                            />
                          )}
                        {editingInsight?.cover_photo &&
                          editingInsight?.cover_photo[2]?.url &&
                          !editingInsight?.cover_photo[2]?.file?.type.includes('audio') &&
                          !editingInsight?.cover_photo[2]?.uuid && (
                            <img
                              alt="cover"
                              className="w-full h-full object-cover"
                              src={(editingInsight?.cover_photo && editingInsight?.cover_photo[2]?.url) || ''}
                            />
                          )}
                        <span className="flex items-center justify-center absolute top-0 left-0 w-full h-full bg-black-100 bg-opacity-40">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 24 25"
                            fill="none"
                          >
                            <path
                              d="M2.5 22.4691L8.04927 20.3347C8.40421 20.1982 8.58168 20.1299 8.74772 20.0408C8.8952 19.9617 9.0358 19.8703 9.16804 19.7677C9.31692 19.6521 9.45137 19.5177 9.72028 19.2488L21 7.96905C22.1046 6.86449 22.1046 5.07362 21 3.96905C19.8955 2.86448 18.1046 2.86448 17 3.96905L5.72028 15.2488C5.45138 15.5177 5.31692 15.6521 5.20139 15.801C5.09877 15.9333 5.0074 16.0739 4.92823 16.2213C4.83911 16.3874 4.77085 16.5648 4.63433 16.9198L2.5 22.4691ZM2.5 22.4691L4.55812 17.118C4.7054 16.7351 4.77903 16.5436 4.90534 16.4559C5.01572 16.3793 5.1523 16.3503 5.2843 16.3755C5.43533 16.4044 5.58038 16.5494 5.87048 16.8395L8.12957 19.0986C8.41967 19.3887 8.56472 19.5337 8.59356 19.6848C8.61877 19.8168 8.58979 19.9534 8.51314 20.0637C8.42545 20.19 8.23399 20.2637 7.85107 20.411L2.5 22.4691Z"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          )}
          <ButtonAction onClick={handleDeleteFile} src={trash} text={t('delete_file')} />
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
