import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Category, useCategory } from '@hooks/useCategory';

import ImageViewer from '@components/ImageViewer';

import photoPreviewLarge from '@assets/icons/Category/photoPreviewLarge.png';

import { Star } from '@phosphor-icons/react';

interface CategoryCardProps {
  groupId: string | undefined;
  subCategory: Category;
}

export default function CategoryCard({ subCategory, groupId }: CategoryCardProps) {
  const [isFavorite, setIsFavorite] = useState(subCategory?.category_favorite?.length === 0 ? false : true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { addCategoryToFavorite } = useCategory();

  async function handleAddCategoryToFavorite(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    if (!groupId) return;
    setIsSubmitting(true);
    await addCategoryToFavorite(groupId, subCategory.uuid, !isFavorite);
    setIsSubmitting(false);
    setIsFavorite(!isFavorite);
  }

  return (
    <Link to={subCategory.uuid} className="flex flex-col w-[486px]">
      <div className="w-full h-[166px]">
        <ImageViewer
          className="h-full w-full object-cover"
          url={subCategory?.image || photoPreviewLarge}
          previewImage={photoPreviewLarge}
        />
      </div>

      <div className="w-full bg-white flex items-center justify-between p-4 pt-2 h-[70px]">
        <p className="text-2xl text-grey-150">{subCategory.name}</p>
        {subCategory?.category_favorite && (
          <button onClick={handleAddCategoryToFavorite} disabled={isSubmitting}>
            {isFavorite ? (
              <Star size={48} weight="fill" color="#EDB561" fill="#EDB561" />
            ) : (
              <Star size={48} weight="bold" color="#EDB561" fill="#EDB561" />
            )}
          </button>
        )}
      </div>
    </Link>
  );
}
