import { DeleteObjectCommand, PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { v4 as uuid } from 'uuid';

const { VITE_AWS_ACCESS_KEY_ID, VITE_AWS_SECRET_ACCESS_KEY, VITE_AWS_BUCKET_NAME, VITE_AWS_BUCKET_REGION } = import.meta
  .env;

const s3 = new S3Client({
  region: VITE_AWS_BUCKET_REGION,
  credentials: {
    accessKeyId: VITE_AWS_ACCESS_KEY_ID,
    secretAccessKey: VITE_AWS_SECRET_ACCESS_KEY,
  },
});
const BUCKET = VITE_AWS_BUCKET_NAME;

export const uploadToS3 = async (file: File, fileKey?: string) => {
  const fileExt = file.type.split('/')[1];
  const key = `private/${fileKey ? fileKey : uuid()}.${fileExt}`;
  const command = new PutObjectCommand({
    Bucket: BUCKET,
    Key: key,
    Body: file,
    ContentType: file.type,
  });

  try {
    await s3.send(command);
    return { key };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const uploadToS3ToPublicFolder = async (file: File, fileKey?: string) => {
  const fileExt = file.type.split('/')[1];
  const key = `public/${fileKey ? fileKey : uuid()}.${fileExt}`;
  const command = new PutObjectCommand({
    Bucket: BUCKET,
    Key: key,
    Body: file,
    ContentType: file.type,
  });

  try {
    await s3.send(command);
    return { key };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const deleteFromS3 = async (key: string) => {
  const command = new DeleteObjectCommand({
    Bucket: BUCKET,
    Key: 'public/' + key,
  });
  try {
    //await s3.send(command);
    console.log(command);
  } catch (error) {
    console.log(error);
  }
};
