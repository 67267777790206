import * as Dialog from '@radix-ui/react-dialog';
import { useRef } from 'react';

import { Insight, useInsights } from '@hooks/useInsights';

import { useNavigate } from 'react-router-dom';

import ButtonAction from '@components/ButtonAction';

import trash from '@assets/icons/Button/trash.svg';
import visitor from '@assets/icons/Button/visitor.svg';
import pencil from '@assets/icons/ProfileModal/pencil.svg';
import { useTranslation } from 'react-i18next';
import { useUser } from '@hooks/useUser';

interface DeleteInsightModalProps {
  insight: Insight;
  closeModal?: React.RefObject<HTMLButtonElement>;
}

export default function DeleteInsightModal({ insight, closeModal }: DeleteInsightModalProps) {
  const { t } = useTranslation();
  const { deleteInsight } = useInsights();
  const closeButton = useRef<HTMLButtonElement>(null);
  const closeButtonConfirmModal = useRef<HTMLButtonElement>(null);
  const { user } = useUser();


  const navigate = useNavigate();

  async function handleDeleteInsight() {
    await deleteInsight(insight?.group?.uuid || '', insight?.uuid || '');
    closeButtonConfirmModal.current?.click();
    closeButton.current?.click();
    if (closeModal) {
      closeModal.current?.click();
    }
  }

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.40)',
          zIndex: '40',
        }}
      />
      <Dialog.Content
        forceMount
        className={'z-40 w-[calc(100vw-200px)] rounded-lg p-4 right-0 bottom-0 fixed bg-white font-poppins'}
      >
        <Dialog.Close ref={closeButton} className={'absolute top-4 right-8'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
            <path
              d="M3.35209 0.489335C2.69963 -0.163112 1.6418 -0.163112 0.989335 0.489335C0.336888 1.1418 0.336888 2.19963 0.989335 2.85209L10.6372 12.4999L0.989435 22.1478C0.336989 22.8002 0.336989 23.8581 0.989435 24.5105C1.6419 25.1629 2.69973 25.1629 3.35217 24.5105L12.9999 14.8627L22.6478 24.5105C23.3002 25.1629 24.3581 25.1629 25.0105 24.5105C25.6629 23.8581 25.6629 22.8002 25.0105 22.1478L15.3627 12.4999L25.0107 2.85209C25.6631 2.19963 25.6631 1.1418 25.0107 0.489335C24.3583 -0.163112 23.3004 -0.163112 22.648 0.489335L12.9999 10.1372L3.35209 0.489335Z"
              fill="#737373"
            />
          </svg>
        </Dialog.Close>
        <div className="w-full flex flex-col gap-1 p-10">
          {user?.uuid === insight?.user?.uuid && (
            <ButtonAction
              onClick={() =>
                navigate('/insight/edit/' + insight?.group?.uuid + '/' + insight?.uuid, {
                  state: { fetchInsight: true },
                })
              }
              src={pencil}
              text={t('edit_insight')}
            />
          )}

          <ButtonAction
            onClick={() => navigate(`/insight/${insight?.group?.uuid}/${insight?.uuid}/visitors`)}
            src={visitor}
            text={t('visitor_permissions')}
          />
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <ButtonAction src={trash} text={t('delete_insight')} />
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay
                style={{
                  position: 'fixed',
                  width: '100vw',
                  height: '100vh',
                  inset: 0,
                  background: 'rgba(0, 0, 0, 0.70)',
                  zIndex: '40',
                }}
              />
              <Dialog.Content
                forceMount
                className={
                  'z-40 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white px-12 py-8 rounded-lg font-poppins'
                }
              >
                <div className="flex flex-col gap-5">
                  <Dialog.Close ref={closeButtonConfirmModal} className={'hidden'}></Dialog.Close>
                  <h1 className="text-lg text-black-100 font-semibold">{t('delete_insight')}</h1>
                  <span className="text-sm text-grey-200 mb-2">
                    {t('are_you_sure_you_want_to_delete_this_insight')}
                  </span>
                  <div className="flex items-center justify-end gap-4">
                    <button
                      onClick={() => closeButtonConfirmModal.current?.click()}
                      className="flex items-center justify-center py-2 px-3 rounded-md bg-primary-200 bg-opacity-20 hover:opacity-70"
                    >
                      {t('cancel')}
                    </button>
                    <button
                      onClick={handleDeleteInsight}
                      className="flex items-center justify-center py-2 px-3 rounded-md bg-red-500 hover:opacity-70 text-white"
                    >
                      {t('delete')}
                    </button>
                  </div>
                </div>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
