import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { api } from '../../../../services/axios';

import { Button } from '@components/Button';

import logo from '@assets/logo.svg';
import { User, useUser } from '@hooks/useUser';
import { isEmail, isPhoneNumber } from 'utils/auxFunctions';
import { toast } from 'utils/toast';

export default function TwoFactor() {
  const [values, setValues] = useState<string[]>(['', '', '', '']);
  const [isEmailOrPhone, setIsEmailOrPhone] = useState<'email' | 'phone' | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const [userLanguage, setUserLanguage] = useState<string>('en');
  const { setUser, setToken } = useUser();

  const emailOrPhone = searchParams.get('emailOrPhone');
  const navigate = useNavigate();

  const codeInput0 = useRef<HTMLInputElement>(null);
  const codeInput1 = useRef<HTMLInputElement>(null);
  const codeInput2 = useRef<HTMLInputElement>(null);
  const codeInput3 = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const browserLanguage = navigator.language;

    if (browserLanguage.includes('pt')) {
      setUserLanguage('pt');
    } else {
      setUserLanguage('en');
    }
  }, []);

  useEffect(() => {
    if (emailOrPhone) {
      if (isEmail(emailOrPhone)) setIsEmailOrPhone('email');
      if (isPhoneNumber('+' + emailOrPhone)) setIsEmailOrPhone('phone');
    }
  }, [emailOrPhone]);

  function handleInputChange(value: string, position: number) {
    let number = value;

    if (value.length === 4) {
      const intValue = parseInt(value, 10);
      if (intValue.toString().length >= 4) {
        const newArray = [...values];
        newArray[0] = intValue.toString()[0];
        newArray[1] = intValue.toString()[1];
        newArray[2] = intValue.toString()[2];
        newArray[3] = intValue.toString()[3];
        setValues(newArray);
      }
      return;
    }

    if (value.length > 1 && values[position] === value[0]) {
      number = value[1];
    } else if (values.length > 1 && values[position] === value[1]) {
      number = value[0];
    }
    if (/^\d*$/.test(number)) {
      const intValue = parseInt(number, 10);
      if (intValue >= 0 && intValue <= 9) {
        const newArray = [...values];
        newArray[position] = intValue.toString();
        setValues(newArray);
      }
    }
  }

  async function handleResetPassword() {
    if (isSubmitting) return;
    if (!emailOrPhone) return;
    try {
      setIsSubmitting(true);
      await api.post('/auth/resend-two-factor-code', {
        email_or_phone: emailOrPhone,
      });
      toast({
        label: 'Success',
        message: `Code resended successfully`,
        type: 'success',
      });
      setIsSubmitting(false);
    } catch (error: any) {
      toast({
        label: 'Error',
        message: error?.response?.data?.message || 'Failed to resend code',
        type: 'error',
      });
      setIsSubmitting(false);
    }
  }

  async function handleCofirmTwoFactorCode() {
    if (!emailOrPhone || values.length !== 4) {
      toast({
        label: 'Error',
        message: 'Invalid code or email',
        type: 'error',
      });
      return;
    }
    if (!values[0] || !values[1] || !values[2] || !values[3]) {
      toast({
        label: 'Error',
        message: 'Invalid code',
        type: 'error',
      });
      return;
    }
    if (!isEmail(emailOrPhone) && !isPhoneNumber('+' + emailOrPhone)) {
      toast({
        label: 'Error',
        message: 'Invalid email',
        type: 'error',
      });
      return;
    }

    try {
      setIsSubmitting(true);
      const code = values[0] + values[1] + values[2] + values[3];
      const { data } = await api.post('/auth/confirm-two-factor-code', {
        code,
        email_or_phone: emailOrPhone,
      });
      const token = data.auth.token;
      const user: User = data.user;
      setUser(user);
      setToken(token);
      toast({
        label: 'Success',
        message: `Acount verified successfully`,
        type: 'success',
      });
      setIsSubmitting(false);
      navigate('/register/finish');
    } catch (error: any) {
      const errorMessage = error.response?.data.message;
      toast({
        label: 'Error',
        message: errorMessage || 'Unknown error',
        type: 'error',
      });
      setIsSubmitting(false);
      console.error(error);
    }
  }
  const handleSetValues = useCallback((value: number) => {
    setValues((prev) => {
      const newArray = [...prev];
      newArray[value] = '';
      return newArray;
    });
  }, []);

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <main className="h-[80vh]">
        <div className="flex flex-col justify-center items-center w-full max-w-[420px] max-h-screen px-[50px] pt-[70px] pb-[50px] bg-white shadow-md border border-gray-100 gap-24">
          <img className="shadow-md rounded-lg" width={48} height={48} src={logo} alt="Logo" />
          <div className="text-start flex flex-col w-full">
            <h1 className="text-primary-200 text-xl font-semibold mb-5">
              {isEmailOrPhone === 'email' && userLanguage === 'en' && 'Check your e-mail inbox'}
              {isEmailOrPhone === 'email' && userLanguage === 'pt' && 'Verifique seu e-mail'}
              {isEmailOrPhone === 'phone' && userLanguage === 'en' && 'Check your SMS messages'}
              {isEmailOrPhone === 'phone' && userLanguage === 'pt' && 'Verifique suas mensagens'}
            </h1>
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-1">
                <span className="text-secondary-400 text-xs">
                  {userLanguage === 'en'
                    ? 'Thank you for subscribing to the Family Wisdom app.'
                    : 'Agradecemos a sua inscrição na Family Wisdom.'}
                </span>
                <span className="text-secondary-400 text-xs">
                  {userLanguage === 'en'
                    ? 'We are happy to have you on board.'
                    : 'Estamos felizes por tê-lo(a) a bordo.'}
                </span>
              </div>
              <span className="text-secondary-400 text-xs">
                {isEmailOrPhone === 'email' &&
                  userLanguage === 'en' &&
                  'We need you to verify your email address. A code has been sent to:'}
                {isEmailOrPhone === 'email' &&
                  userLanguage === 'pt' &&
                  'Precisamos que verifique o endereço de email. Foi enviado um código para: '}

                {isEmailOrPhone !== 'email' &&
                  userLanguage === 'en' &&
                  'We need you to verify your SMS. A code has been sent to:'}
                {isEmailOrPhone !== 'email' &&
                  userLanguage === 'pt' &&
                  'Precisamos que verifique o seu SMS. Foi enviado um código para:'}
              </span>

              <span className="text-xs text-secondary-300 text-center">{emailOrPhone}</span>

              <p className="text-secondary-400 text-xs">
                {userLanguage === 'en' ? 'which will expire in 30 minutes.' : 'que expirará em 30 minutos.'}
              </p>
              <p className="text-secondary-400 text-xs mb-12">
                {userLanguage === 'en' ? 'Greetings from the Family Wisdom team' : 'Saudações da equipa Family Wisdom'}
              </p>
            </div>
            <div className="flex gap-2 justify-center items-center">
              <input
                type="number"
                value={values[0]}
                data-test="two-factor-code-input-0"
                required
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    handleSetValues(0);
                  } else if (e.key === 'Delete') handleSetValues(0);
                }}
                onChange={(e) => {
                  handleInputChange(e.target.value, 0);
                  codeInput1.current?.focus();
                }}
                ref={codeInput0}
                className="text-center text-primary-200 py-[10px] border-2 rounded-[3px] border-primary-200 max-w-[50px] text-3xl font-semibold placeholder:text-primary-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              />
              <input
                type="number"
                value={values[1]}
                data-test="two-factor-code-input-1"
                required
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    handleSetValues(1);
                  } else if (e.key === 'Delete') handleSetValues(1);
                }}
                onChange={(e) => {
                  handleInputChange(e.target.value, 1);
                  codeInput2.current?.focus();
                }}
                ref={codeInput1}
                className="text-center text-primary-200 py-[10px] border-2 rounded-[3px] border-primary-200 max-w-[50px] text-3xl font-semibold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              />
              <input
                type="number"
                value={values[2]}
                data-test="two-factor-code-input-2"
                required
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    handleSetValues(2);
                  } else if (e.key === 'Delete') handleSetValues(2);
                }}
                onChange={(e) => {
                  handleInputChange(e.target.value, 2);
                  codeInput3.current?.focus();
                }}
                ref={codeInput2}
                className="text-center text-primary-200 py-[10px] border-2 rounded-[3px] border-primary-200 max-w-[50px] text-3xl font-semibold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              />
              <input
                type="number"
                value={values[3]}
                data-test="two-factor-code-input-3"
                required
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    handleSetValues(3);
                  } else if (e.key === 'Delete') handleSetValues(3);
                }}
                onChange={(e) => {
                  handleInputChange(e.target.value, 3);
                  codeInput3.current?.blur();
                }}
                ref={codeInput3}
                className="text-center text-primary-200 py-[10px] border-2 rounded-[3px] border-primary-200 max-w-[50px] text-3xl font-semibold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              />
            </div>
          </div>

          <div className="flex flex-col justify-center items-center">
            <Button
              disabled={isSubmitting}
              onClick={handleCofirmTwoFactorCode}
              data-test="two-factor-code-submit"
              text="Continue"
              size="large"
              variant="primary"
            />
            <span className="text-grey-100 text-xs mt-5">
              {isEmailOrPhone === 'email' && (
                <div>
                  {' '}
                  {userLanguage === 'pt'
                    ? 'Não recebeu o e-mail? Tente verificar a caixa de spam ou'
                    : "Didn't receive the email? Try checking your spam folders or"}{' '}
                  <button className="underline cursor-pointer" onClick={handleResetPassword}>
                    {userLanguage === 'pt' ? 'Reenviar' : 'Resend'}
                  </button>
                  .
                </div>
              )}
              {isEmailOrPhone === 'phone' && (
                <div>
                  {' '}
                  {userLanguage === 'pt' ? 'Não recebeu o SMS?' : "Didn't receive the SMS?"}{' '}
                  <button className="underline cursor-pointer" onClick={handleResetPassword}>
                    {userLanguage === 'pt' ? 'Reenviar' : 'Resend'}{' '}
                  </button>
                  .
                </div>
              )}
            </span>
          </div>
        </div>
      </main>
    </div>
  );
}
