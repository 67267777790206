import * as Dialog from '@radix-ui/react-dialog';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useEventLabels } from '@hooks/useEventLabels';

import { ButtonAdd } from '@components/ButtonAdd';
import CreateOrEditEventLabelModal from '@components/CreateOrEditEventLabelModal';
import Loading from '@components/Loading';
import { PaginationComponent } from '@components/Pagination';
import { useGroups } from '@hooks/useGroups';
import { useTranslation } from 'react-i18next';
import { PageProps } from '..';

export default function EditEventLabels() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<PageProps>();

  const { eventLabels, getEventLabelsForGroup, pagination } = useEventLabels();
  const { getGroupByUuid, group } = useGroups();

  const { groupId } = useParams();

  const getAllEventLabelsForGroup = useCallback(async () => {
    if (!groupId) return;
    getGroupByUuid(groupId);
    await getEventLabelsForGroup(groupId, '?take=10&page=1');
    setLoading(false);
  }, [getEventLabelsForGroup, getGroupByUuid, groupId]);

  useEffect(() => {
    getAllEventLabelsForGroup();
  }, [getAllEventLabelsForGroup]);

  async function handleSelectPage(selectedPage: number) {
    setLoading(true);
    if (groupId) await getEventLabelsForGroup(groupId, '?take=10&page=' + selectedPage);
    setLoading(false);
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex flex-col items-center w-full min-h-[calc(100vh-88px)] bg-backgray py-14">
          <div className="flex flex-col w-[800px] bg-white relative">
            {group?.role === 'Admin' && (
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <ButtonAdd bg="bg-white" text={t('add_event_label')} textColor="text-primary-200" />
                </Dialog.Trigger>
                <CreateOrEditEventLabelModal groupId={groupId} />
              </Dialog.Root>
            )}
            {eventLabels.map((eventLabel) => (
              <div
                key={eventLabel.uuid}
                className="flex items-center justify-between px-5 py-4 border-t border-grey-600 relative"
              >
                <div className="flex items-center gap-8">
                  <span
                    className="w-12 h-12 flex items-center justify-center rounded-full"
                    style={{ background: eventLabel.color }}
                  ></span>
                  <p className="text-black-100 text-lg">{eventLabel.name}</p>
                </div>
                {group?.role === 'Admin' && (
                  <Dialog.Root>
                    <Dialog.Trigger asChild>
                      <button className="text-sm text-black-100 hover:opacity-70">{t('edit')}</button>
                    </Dialog.Trigger>
                    <CreateOrEditEventLabelModal groupId={groupId} eventLabel={eventLabel} />
                  </Dialog.Root>
                )}
              </div>
            ))}
          </div>
          {eventLabels?.length > 0 && (
            <PaginationComponent
              page={page}
              handleSelectPage={handleSelectPage}
              pagination={pagination}
              setPage={setPage}
            />
          )}
        </div>
      )}
    </>
  );
}
