import { create } from 'zustand';
import { api } from '../services/axios';
import { showErrorMessage } from '../utils/auxFunctions';

export interface Dashboard {
  groups: {
    free: number;
    premium: number;
    total: number;
  };
  storage: {
    free: number;
    premium: number;
    total: number;
  };
  members: {
    free: number;
    premium: number;
  };
  categories: {
    free: number;
    premium: number;
    total: number;
  };
  insights: {
    free: number;
    premium: number;
    total: number;
  };
  'insights-storage': {
    free: number;
    premium: number;
    total: number;
  };
  events: {
    free: number;
    premium: number;
    total: number;
  };
  'events-types': {
    free: number;
    premium: number;
    total: number;
  };
  'most-used-viat': {
    image: number;
    video: number;
    audio: number;
    text: number;
  };
  'groups-subscriptions': {
    free: { [date: string]: number };
    premium: { [date: string]: number };
  };
}

interface DashboardProps {
  dashboard: Dashboard;
  getDasboard: (start_date: string, end_date: string) => Promise<Dashboard>;
  downloadDashboard: (start_date: string, end_date: string) => Promise<void>;
}

export const useDashboard = create<DashboardProps>((set) => ({
  dashboard: {
    groups: {
      free: 0,
      premium: 0,
      total: 0,
    },
    storage: {
      free: 0,
      premium: 0,
      total: 0,
    },
    members: {
      free: 0,
      premium: 0,
    },
    categories: {
      free: 0,
      premium: 0,
      total: 0,
    },
    insights: {
      free: 0,
      premium: 0,
      total: 0,
    },
    'insights-storage': {
      free: 0,
      premium: 0,
      total: 0,
    },
    events: {
      free: 0,
      premium: 0,
      total: 0,
    },
    'events-types': {
      free: 0,
      premium: 0,
      total: 0,
    },
    'most-used-viat': {
      image: 0,
      video: 0,
      audio: 0,
      text: 0,
    },
    'groups-subscriptions': {
      free: {},
      premium: {},
    },
  },
  getDasboard: async (start_date: string, end_date: string) => {
    try {
      const { data } = await api.get('/dashboard?start_date=' + start_date + '&end_date=' + end_date);
      set({ dashboard: data.content.data });
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  downloadDashboard: async (start_date: string, end_date: string) => {
    try {
      const { data } = await api.get('/dashboard/export?start_date=' + start_date + '&end_date=' + end_date, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'dashboard.csv');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      showErrorMessage(error);
    }
  },
}));
