import * as Dialog from '@radix-ui/react-dialog';
import { useRef, useState } from 'react';

import { Button } from '@components/Button';
import { EventLabel, useEventLabels } from '@hooks/useEventLabels';
import { useTranslation } from 'react-i18next';

interface EditEventLabelModalProps {
  groupId: string | undefined;
  eventLabel?: EventLabel;
}

const eventLabelsColor = ['#EB8F00', '#FF6333', '#E8228B', '#2D5B09', '#282A74', '#727E97'];

export default function CreateOrEditEventLabelModal({ eventLabel, groupId }: EditEventLabelModalProps) {
  const { t } = useTranslation();
  const [selectedColor, setSelectedColor] = useState(eventLabel?.color || '#EB8F00');
  const closeButton = useRef<HTMLButtonElement>(null);
  const { createEventLabel, updateEventLabel, getEventLabelsForGroup } = useEventLabels();

  const eventLabelNameInput = useRef<HTMLInputElement>(null);

  async function handleCreateOrEditEventLabel() {
    if (!groupId) return;
    if (eventLabel) {
      await updateEventLabel(groupId, eventLabel.uuid, eventLabelNameInput.current?.value || '', selectedColor);
    } else {
      await createEventLabel(groupId, eventLabelNameInput.current?.value || '', selectedColor);
    }
    await getEventLabelsForGroup(groupId);
    closeButton.current?.click();
  }

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '10',
        }}
      />
      <Dialog.Content
        forceMount
        className={'z-40 w-[calc(100vw-200px)] rounded-lg p-4 right-0 bottom-0 fixed bg-white font-poppins'}
      >
        <Dialog.Close ref={closeButton} className={'absolute top-4 right-8'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
            <path
              d="M3.35209 0.489335C2.69963 -0.163112 1.6418 -0.163112 0.989335 0.489335C0.336888 1.1418 0.336888 2.19963 0.989335 2.85209L10.6372 12.4999L0.989435 22.1478C0.336989 22.8002 0.336989 23.8581 0.989435 24.5105C1.6419 25.1629 2.69973 25.1629 3.35217 24.5105L12.9999 14.8627L22.6478 24.5105C23.3002 25.1629 24.3581 25.1629 25.0105 24.5105C25.6629 23.8581 25.6629 22.8002 25.0105 22.1478L15.3627 12.4999L25.0107 2.85209C25.6631 2.19963 25.6631 1.1418 25.0107 0.489335C24.3583 -0.163112 23.3004 -0.163112 22.648 0.489335L12.9999 10.1372L3.35209 0.489335Z"
              fill="#737373"
            />
          </svg>
        </Dialog.Close>
        <div className="flex flex-col items-center w-full py-10">
          <div className="flex flex-col gap-3 bg-white  py-5 mb-8">
            <h2 className="text-xl text-black-100 font-bold mb-5 w-full text-center">
              {eventLabel ? t('edit_event_label') : t('create_event_label')}
            </h2>
            <div className="flex items-center justify-center my-2">
              <span className={`rounded-full w-12 h-12`} style={{ background: selectedColor }} />
            </div>

            <div className="flex flex-col gap-4 w-[800px]">
              <span className="text-lg text-secondary-400">{t('name')}</span>
              <input
                defaultValue={eventLabel?.name || ''}
                ref={eventLabelNameInput}
                maxLength={50}
                className="bg-transparent border-b border-grey-600 focus:outline-none text-lg text-black-100"
                type="text"
                placeholder={t('choose_a_name')}
              />
              <div className="flex items-center gap-5 mt-5">
                <span>{t('choose_a_color')}</span>
                <div className="flex items-center gap-3">
                  {eventLabelsColor.map((color) => (
                    <button
                      key={color}
                      onClick={() => setSelectedColor(color)}
                      className={`rounded-full w-10 h-10`}
                      style={{
                        backgroundColor: color,
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <Button onClick={handleCreateOrEditEventLabel} text={t('save')} size="medium" variant="primary" boxShadow />
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
