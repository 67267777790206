import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';

import { Button } from '@components/Button';
import { Input } from '@components/Input';

import logo from '@assets/logo.svg';

import { isEmail, isPhoneNumber } from 'utils/auxFunctions';
import { toast } from 'utils/toast';
import { api } from '../../../../services/axios';

const forgotPasswordSchema = z.object({
  emailOrPhone: z.string(),
});

type forgotPasswordInputs = z.infer<typeof forgotPasswordSchema>;

export default function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<forgotPasswordInputs>({
    resolver: zodResolver(forgotPasswordSchema),
  });

  const [userLanguage, setUserLanguage] = useState<string>('en');

  const navigate = useNavigate();

  useEffect(() => {
    const browserLanguage = navigator.language;

    if (browserLanguage.includes('pt')) {
      setUserLanguage('pt');
    } else {
      setUserLanguage('en');
    }
  }, []);

  async function handleResetPassword(data: forgotPasswordInputs) {
    let emailOrPhone = data.emailOrPhone;
    if (!emailOrPhone) return;
    if (!isEmail(emailOrPhone) && !isPhoneNumber(emailOrPhone) && !isPhoneNumber('+' + emailOrPhone)) {
      toast({
        label: 'Error',
        message: userLanguage === 'pt' ? 'Email inválido' : 'Invalid Email',
        type: 'error',
      });
      return;
    }

    if (isPhoneNumber(emailOrPhone) && emailOrPhone.startsWith('+')) {
      emailOrPhone = emailOrPhone.replaceAll('+', '');
    }

    try {
      await api.post('/auth/forget-password', {
        email_or_phone: emailOrPhone,
      });
      toast({
        label: 'Success',
        message: userLanguage === 'pt' ? 'Código enviado com sucesso' : `Code sent successfully`,
        type: 'success',
      });
      navigate('/login');
    } catch (error: any) {
      toast({
        label: 'Error',
        message: error.response.data.message,
        type: 'error',
      });
    }
  }

  return (
    <div className="w-screen flex items-center justify-center">
      <main className="h-[60vh]">
        <form
          onSubmit={handleSubmit(handleResetPassword)}
          className="flex flex-col justify-center items-center w-full max-w-[400px] px-8 py-[70px] bg-white shadow-md border border-gray-100 gap-20 relative"
        >
          <img className="shadow-md rounded-lg" width={48} height={48} src={logo} alt="Logo" />
          <div className="text-start flex flex-col w-full">
            <h1 className="text-primary-200 text-xl font-semibold mb-2">
              {userLanguage === 'pt' ? 'Vamos encontrar sua conta' : "Let's find your account"}
            </h1>
            <span className="text-secondary-400 text-xs mb-6">
              {userLanguage === 'pt'
                ? 'Nós enviaremos um código para redefinir sua senha'
                : "We'll send you a code to reset your password."}
            </span>
            <div className="flex flex-col items-center gap-1">
              <Input
                {...register('emailOrPhone')}
                identificator="email"
                data-test="forgot-password-email"
                label={userLanguage === 'pt' ? 'Email' : 'Email'}
                required
              />
            </div>
          </div>
          <Button
            disabled={isSubmitting}
            text={userLanguage === 'pt' ? 'Reiniciar' : 'Reset'}
            type="submit"
            data-test="forgot-password-submit"
            size="large"
            variant="primary"
          />
          <Link to={'/login'} className="absolute top-4 left-4 hover:opacity-70">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 40" fill="none">
              <path d="M23.525 3.525L20 0L0 20L20 40L23.525 36.475L7.075 20L23.525 3.525Z" fill="#282A74" />
            </svg>
          </Link>
        </form>
      </main>
    </div>
  );
}
