import { createBrowserRouter, createRoutesFromElements, Outlet, Route } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';
import {
  AddParticipants,
  AdsStatisticsPage,
  CalendarOptions,
  CalendarPage,
  CalendarSettings,
  Category,
  ChooseEventLabel,
  CreateAds,
  CreateCategoryGroup,
  CreateEventPage,
  CreateGroup,
  DashboardPage,
  EditAdsPage,
  EditCategories,
  EditEventLabels,
  EditEventPage,
  EditGroup,
  EditInsight,
  EditInsightCategories,
  EditInsightFinish,
  EditInsightGroups,
  EditInsightTagsAndDescription,
  EditParticipants,
  EditProfile,
  EditSubject,
  EditTopic,
  EnterInvitationCode,
  ExternalRemoveAccount,
  ExternalRemoveAccountTwoFactor,
  Feed,
  ForgotPassword,
  GroupInformation,
  Insight,
  InsightVisitors,
  InvitePage,
  LanguagePage,
  ListAdsPage,
  ListGroups,
  LoginForm,
  Notifications,
  NotificationsSettings,
  PageNotFound,
  PasswordReset,
  ProfileConfigurationForm,
  ReadEvent,
  RedirectTo,
  RegisterForm,
  RemoveAccountInternal,
  RemoveAccountInternalTwoFactor,
  ReportProblem,
  ResetPasswordPage,
  ShareCalendarUrl,
  ShareInsight,
  ShareInsightCategories,
  ShareInsightFinish,
  ShareInsightGroups,
  ShareInsightTagsAndDescription,
  ShareLink,
  SubCategory,
  Subscription,
  Topics,
  TwoFactor,
  TwoFactorRegister,
  UserProfile,
} from './routes';

import DataDeletion from '@pages/DataDeletion';
import { ConfirmDeletion } from '@pages/DataDeletion/ConfirmDeletion';
import DefaultLayout from 'layouts/DefaultLayout';
import LoginLayout from 'layouts/LoginLayout';
import WithoutSideBarLayout from 'layouts/WithoutSideBarLayout';
import PublicRoutes from './PublicRoutes';
import SuperAdminRoutes from './SuperAdminRoutes';

export const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Outlet />}>
      <Route index element={<RedirectTo />} />
      {/* Public routes */}
      <Route element={<LoginLayout />}>
        <Route element={<PublicRoutes />}>
          <Route path="login" element={<LoginForm />} />
          <Route path="two-factor" element={<TwoFactorRegister />} />
          {/* Password */}
          <Route path="/password" element={<Outlet />}>
            <Route path="forgot" element={<ForgotPassword />} />
            <Route path="reset" element={<TwoFactor />} />
            <Route path="new-password" element={<PasswordReset />} />
          </Route>

          {/* Register */}
          <Route path="/register" element={<Outlet />}>
            <Route index element={<RegisterForm />} />
            <Route path="finish" element={<ProfileConfigurationForm />} />
          </Route>
        </Route>

        {/* Invite */}
        <Route path="/invite/:code" element={<InvitePage />} />

        {/*external remove account*/}
        <Route path="/account" element={<Outlet />}>
          <Route path="remove" element={<ExternalRemoveAccount />} />
          <Route path="two-factor" element={<ExternalRemoveAccountTwoFactor />} />
        </Route>
      </Route>

      {/* Protected routes */}
      <Route element={<ProtectedRoutes />}>
        {/* Groups */}
        <Route path="groups">
          <Route path="" element={<DefaultLayout />}>
            <Route index element={<ListGroups />} />
          </Route>
          <Route path="create" element={<WithoutSideBarLayout />}>
            <Route index element={<CreateGroup />} />
          </Route>

          <Route path=":groupId" element={<DefaultLayout />}>
            <Route index element={<GroupInformation />} />
            <Route path="invite">
              <Route index element={<AddParticipants />} />
              <Route path="share" element={<ShareLink />} />
            </Route>
            {/* <Route path="addSubscription" element={<GroupSubscription />} /> */}

            {/* EDIT */}
            <Route path="edit">
              <Route index element={<EditGroup />} />
              <Route path="participants" element={<EditParticipants />} />
              <Route path="event-labels" element={<EditEventLabels />} />
              <Route path="themes">
                <Route index element={<EditCategories />} />
                <Route path=":categoryId">
                  <Route index element={<EditSubject />} />
                  <Route path=":subCategoryId">
                    <Route index element={<EditTopic />} />
                  </Route>
                </Route>
              </Route>
              {/* <Route path="subscription" element={<GroupSubscription />} /> */}
            </Route>
          </Route>

          {/* Categories for groups */}
          <Route path=":groupId">
            <Route path="category">
              <Route path="create" element={<WithoutSideBarLayout />}>
                <Route index element={<CreateCategoryGroup />} />
              </Route>
              <Route path=":categoryId" element={<DefaultLayout />}>
                <Route index element={<Category />} />
                <Route path="insight" element={<Topics />} />
                <Route path=":subCategoryId">
                  <Route index element={<SubCategory />} />
                  <Route path="insight" element={<Topics />} />
                  <Route path=":topicId">
                    <Route index element={<Topics />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>

          {/* Insights */}
          <Route path=":groupId" element={<DefaultLayout />}>
            <Route path="insight">
              <Route path=":insightUuid">
                <Route index element={<Insight />} />
              </Route>
            </Route>
          </Route>
        </Route>

        {/* Users */}
        <Route path="profile" element={<DefaultLayout />}>
          <Route index element={<UserProfile />} />
          <Route path="edit" element={<EditProfile />} />
          <Route path="report-problem" element={<ReportProblem />} />
          <Route path="notifications-settings" element={<NotificationsSettings />} />
          <Route path="subscription" element={<Subscription />} />
          <Route path="calendar">
            <Route index element={<CalendarOptions />} />
            <Route path="share" element={<ShareCalendarUrl />} />
            <Route path="settings" element={<CalendarSettings />} />
          </Route>
          <Route path="language" element={<LanguagePage />} />

          <Route path="account-remove" element={<RemoveAccountInternal />} />
          <Route path="two-factor" element={<RemoveAccountInternalTwoFactor />} />
        </Route>

        {/* remove account */}

        {/* Insight */}
        <Route path="insight">
          <Route path="create" element={<WithoutSideBarLayout />}>
            <Route index element={<ShareInsight />} />
            <Route path="groups" element={<ShareInsightGroups />} />
            <Route path="tags-and-description" element={<ShareInsightTagsAndDescription />} />
            <Route path="categories" element={<ShareInsightCategories />} />
            <Route path="finish" element={<ShareInsightFinish />} />
          </Route>
          <Route path="edit" element={<DefaultLayout />}>
            <Route path=":groupId">
              <Route path=":insightUuid">
                <Route index element={<EditInsight />} />
                <Route path="groups" element={<EditInsightGroups />} />
                <Route path="tags-and-description" element={<EditInsightTagsAndDescription />} />
                <Route path="categories" element={<EditInsightCategories />} />
                <Route path="finish" element={<EditInsightFinish />} />
              </Route>
            </Route>
          </Route>
          <Route path=":groupId/:insightUuid/visitors" element={<DefaultLayout />}>
            <Route index element={<InsightVisitors />} />
          </Route>
        </Route>

        {/* Feed */}
        <Route path="feed" element={<DefaultLayout />}>
          <Route index element={<Feed />} />
        </Route>

        {/* Notifications */}
        <Route path="notifications" element={<DefaultLayout />}>
          <Route index element={<Notifications />} />
        </Route>

        {/* Calendar */}
        <Route path="calendar">
          <Route path="" element={<DefaultLayout />}>
            <Route index element={<CalendarPage />} />
          </Route>
          <Route path="create-event">
            <Route path="" element={<WithoutSideBarLayout />}>
              <Route index element={<CreateEventPage />} />
            </Route>
            <Route path=":eventUuid" element={<DefaultLayout />}>
              <Route index element={<ChooseEventLabel />} />
            </Route>
          </Route>
          <Route path="edit-event/:eventUuid" element={<DefaultLayout />}>
            <Route index element={<EditEventPage />} />
            <Route path="choose-event-label" element={<ChooseEventLabel />} />
          </Route>
        </Route>

        {/* Events */}
        <Route path="events" element={<DefaultLayout />}>
          <Route path=":eventUuid">
            <Route index element={<ReadEvent />} />
            <Route path="edit" />
          </Route>
        </Route>

        {/* Privacy Policy */}
        {/* <Route path="privacy-policy" element={<DefaultLayout />}>
          <Route index element={<PrivacyPolicy />} />
        </Route> */}

        {/* Terms of Service */}
        {/* <Route path="terms-of-service" element={<DefaultLayout />}>
          <Route index element={<TermsOfService />} />
        </Route> */}

        {/* Faq */}
        {/* <Route path="faq" element={<DefaultLayout />}>
          <Route index element={<Faq />} />
        </Route> */}

        {/* Enter invitation code */}
        <Route path="invitation-code" element={<DefaultLayout />}>
          <Route index element={<EnterInvitationCode />} />
        </Route>

        {/* Dashboard */}
        <Route path="dashboard" element={<DefaultLayout />}>
          <Route index element={<DashboardPage />} />
        </Route>

        {/* Ads */}
        <Route path="ads" element={<SuperAdminRoutes />}>
          <Route path="" element={<DefaultLayout />}>
            <Route index element={<ListAdsPage />} />
          </Route>
          <Route path="create" element={<WithoutSideBarLayout />}>
            <Route index element={<CreateAds />} />
          </Route>
          <Route path="edit" element={<DefaultLayout />}>
            <Route path=":adsUuid">
              <Route index element={<EditAdsPage />} />
            </Route>
          </Route>
          <Route path="statistics" element={<DefaultLayout />}>
            <Route path=":adsUuid">
              <Route index element={<AdsStatisticsPage />} />
            </Route>
          </Route>
        </Route>

        {/* Reset Password */}
        <Route path="password/reset-internal" element={<DefaultLayout />}>
          <Route index element={<ResetPasswordPage />} />
        </Route>
      </Route>
      <Route path="account-delete">
        <Route index path="" element={<DataDeletion />} />
        <Route path="confirmation" element={<ConfirmDeletion />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Route>,
  ),
);
