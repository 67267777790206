import EmptyEvents from '@components/EmptyStates/EmptyEvents';
import { CalendarUser } from '@hooks/useCalendar';
import { format } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface MonthScheduleProps {
  calendar: CalendarUser | object;
}

export default function MonthSchedule({ calendar }: Readonly<MonthScheduleProps>) {
  const { i18n } = useTranslation();
  const isEmpty = Object.keys(calendar).every((date: string) => {
    //@ts-expect-error parse string as index
    const eventsForDate = calendar[date]?.event;
    return !eventsForDate || eventsForDate.length === 0;
  });

  if (isEmpty) {
    return (
      <div className="flex flex-col max-w-[370px] w-full bg-white p-7 gap-6">
        <EmptyEvents />
      </div>
    );
  }

  return (
    <div className="flex flex-col max-w-[370px] w-full bg-white p-7 gap-6">
      {!calendar ||
        Object?.keys(calendar)?.map((date: string) => {
          //@ts-expect-error parse string as index
          const eventsForDate = calendar[date]?.event;
          if (eventsForDate?.length > 0) {
            return (
              <div key={date} className="flex flex-col">
                {eventsForDate.map((event: any) => (
                  <section key={event?.uuid || event.title} className="flex justify-center gap-3">
                    <div className="flex flex-col items-center w-[35px]">
                      <span className="text-sm font-medium text-grey-150 capitalize">
                        {i18n.language === 'en'
                          ? format(new Date(event.start_time), 'E')
                          : format(new Date(event.start_time), 'E', { locale: ptLocale })}
                      </span>
                      <span className="text-sm font-medium text-grey-150">
                        {format(new Date(event.start_time), 'dd')}
                      </span>
                    </div>
                    <div className="flex flex-col gap-3 p-3 bg-[#DDD] border-grey-150 max-w-[308px] min-w-[308px] overflow-hidden">
                      {event.uuid == undefined ? (
                        <div
                          className={`flex flex-col justify-center rounded-md text-white py-[5px] px-[10px] overflow-hidden cursor-default`}
                          style={{
                            backgroundColor: `${event.event_label == undefined ? '#282A74' : event.event_label.color}`,
                          }}
                        >
                          <h2 className="text-sm font-medium">{event.title}</h2>
                          <p className="text-xs">
                            {format(new Date(event.start_time), 'HH:mm')} - {format(new Date(event.end_time), 'HH:mm')}
                          </p>
                        </div>
                      ) : (
                        <Link
                          key={date}
                          to={`/events/${event.uuid}`}
                          className={`flex flex-col justify-center rounded-md text-white py-[5px] px-[10px] overflow-hidden`}
                          style={{
                            backgroundColor: `${event.event_label == undefined ? '#282A74' : event.event_label.color}`,
                          }}
                        >
                          <h2 className="text-sm font-medium">{event.title}</h2>
                          <p className="text-xs">
                            {format(new Date(event.start_time), 'HH:mm')} - {format(new Date(event.end_time), 'HH:mm')}
                          </p>
                        </Link>
                      )}
                    </div>
                  </section>
                ))}
              </div>
            );
          }
          return null;
        })}
    </div>
  );
}
