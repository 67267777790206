import { useCallback, useEffect, useState } from 'react';
import { BiSolidInfoCircle } from 'react-icons/bi';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { GrNotification } from 'react-icons/gr';
import { IoIosCloseCircle, IoMdAlert } from 'react-icons/io';
import { MdClose } from 'react-icons/md';
import './style.css';

export interface ToastProps {
  id: string | number;
  type: 'info' | 'success' | 'error' | 'alert' | 'notification';
  label: string;
  message: string;
  duration?: number;
  urlImage?: string;
  urlNotification?: string;
  onRemoveMessage?(id: number | string): void;
}

export function ToastMessage({ id, message, type, label, duration, urlImage, onRemoveMessage }: Readonly<ToastProps>) {
  const [visible, setVisible] = useState(true);

  const handleRemoveToast = useCallback(() => {
    setVisible(false);
    setTimeout(() => {
      onRemoveMessage!(id);
    }, 300); // Tempo de espera para remover completamente o toast após a animação
  }, [id, onRemoveMessage]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleRemoveToast();
    }, duration || 7000); // 7s

    return () => {
      clearTimeout(timeoutId);
    };
  }, [id, duration, handleRemoveToast]);

  function handleAnimationEnd() {
    if (!visible) {
      onRemoveMessage!(id);
    }
  }

  return (
    <>
      {type === 'notification' ? (
        <a href="/notifications">
          <article
            className={`toast relative w-fit border mx-auto flex items-center py-4 bg-white shadow-lg mb-4 z-50 ${
              visible ? 'show' : 'hide'
            }`}
            onAnimationEnd={handleAnimationEnd}
            onClick={handleRemoveToast}
            aria-hidden
          >
            <div
              className={`h-full w-[4px] absolute top-0 left-0 ${type === 'notification' ? 'bg-transparent' : ''}`}
            ></div>
            <div className="px-6">
              {urlImage == undefined ? (
                <GrNotification size={24} color="#29df67" />
              ) : (
                <img src={`${urlImage}`} alt="Notification" className="w-12" />
              )}
            </div>
            <div className="flex flex-col">
              <h4 className="font-bold text-gray-800">{label}</h4>
              <span className="text-sm text-gray-500">{message}</span>
            </div>
            <div className="h-full flex ml-6 px-6">
              <button onClick={handleRemoveToast} className="text-gray-400 hover:opacity-70">
                <MdClose size={22} />
              </button>
            </div>
          </article>
        </a>
      ) : (
        <article
          className={`toast relative w-fit border mx-auto flex items-center py-4 bg-white shadow-lg mb-4 z-50 ${
            visible ? 'show' : 'hide'
          }`}
          onAnimationEnd={handleAnimationEnd}
          onClick={handleRemoveToast}
          aria-hidden
        >
          <div
            className={`h-full w-[4px] absolute top-0 left-0 ${type === 'success' ? 'bg-[#29df67]' : ''} ${
              type === 'info' ? 'bg-[#212fe1]' : ''
            } ${type === 'alert' ? 'bg-[#c5c90e]' : ''} ${type === 'error' ? 'bg-[#ec2a2a]' : ''}`}
          ></div>
          <div className="px-6">
            {type === 'success' && <BsFillCheckCircleFill size={22} color="#29df67" />}
            {type === 'info' && <BiSolidInfoCircle size={24} color="#212fe1" />}
            {type === 'alert' && <IoMdAlert size={24} color="#c5c90e" />}
            {type === 'error' && <IoIosCloseCircle size={24} color="#ec2a2a" />}
          </div>
          <div className="flex flex-col">
            <h4 className="font-bold text-gray-800">{label}</h4>
            <span className="text-sm text-gray-500">{message}</span>
          </div>
          <div className="h-full flex ml-6 px-6">
            <button onClick={handleRemoveToast} className="text-gray-400 hover:opacity-70">
              <MdClose size={22} />
            </button>
          </div>
        </article>
      )}
    </>
  );
}
