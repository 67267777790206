import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import { useGroups } from '@hooks/useGroups';
import { zodResolver } from '@hookform/resolvers/zod';

import { Input } from '@components/Input';
import { Button } from '@components/Button';

import { toast } from 'utils/toast';

const invitationCodeSchema = z.object({
  code: z.string(),
});

type invitationCodeInputs = z.infer<typeof invitationCodeSchema>;

export default function EnterInvitationCode() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<invitationCodeInputs>({
    resolver: zodResolver(invitationCodeSchema),
  });
  const [codeInvalid, setCodeInvalid] = useState(false);

  const { acceptShareLinkInvite } = useGroups();

  const { t } = useTranslation();

  async function handleEnterCode(data: invitationCodeInputs) {
    const { code } = data;
    if (!code) {
      toast({
        label: t('error'),
        message: t('invalid_code'),
        type: 'error',
      });
      setCodeInvalid(true);
      return;
    }
    const response = await acceptShareLinkInvite(code);
    if (response?.headerCode === 202) {
      toast({
        label: t('error'),
        message: t('already_member'),
        type: 'error',
      });
      setCodeInvalid(false);
    } else if (response?.headerCode === 200) {
      toast({
        label: t('success'),
        message: t('request_sent'),
        type: 'success',
      });
      setCodeInvalid(false);
    } else {
      setCodeInvalid(true);
    }
  }

  return (
    <div className="flex justify-center py-14">
      <form
        onSubmit={handleSubmit(handleEnterCode)}
        className="flex flex-col gap-5 items-center w-[400px] bg-white p-8 shadow-md border border-gray-100"
      >
        <h1 className="text-primary-200 text-lg font-bold text-center">{t('enter_share_link')}</h1>
        <Input
          {...register('code')}
          type="text"
          identificator="code"
          label={t('invitation_code')}
          redBorder={codeInvalid}
        />
        <div className="mt-5">
          <Button text={t('enter')} disabled={isSubmitting} size="medium" variant="primary" type="submit" />
        </div>
      </form>
    </div>
  );
}
