import * as Dialog from '@radix-ui/react-dialog';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { MediaToEdit, useInsights } from '@hooks/useInsights';

import CollectionEditCard from '@components/CollectionEditCard';
import CollectionEditModeCard from '@components/CollectionEditModeCard';
import Loading from '@components/Loading';

import CoverPhotosModal from '@components/CoverPhotosModal';
import { useTranslation } from 'react-i18next';
import { toast } from 'utils/toast';
import { v4 as uuid } from 'uuid';

const MAX_COLLECTIONS = 10;

export default function EditInsight() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { insightUuid, groupId } = useParams();
  const { editingInsight, editInsight, getInsightDetails, editCollection } = useInsights();
  const [editMode, setEditMode] = useState(false);
  const [coverPhotosNumber, setCoverPhotosNumber] = useState(0);

  const location = useLocation();

  const navigate = useNavigate();

  const getInsight = useCallback(async () => {
    if (!location?.state?.fetchInsight) return;
    setIsLoading(true);
    if (insightUuid && groupId) {
      const insight = await getInsightDetails(groupId, insightUuid);
      if (insight) {
        editInsight(
          {
            title: insight.title,
            description: insight.description,
            groupUuid: insight?.group?.uuid || groupId,
            groupName: insight?.group?.name || '',
            tags: insight.tags,
            category: {
              uuid: insight?.category?.uuid || '',
              name: insight?.category?.name,
              subCategoryUuid: '',
              topicUuid: '',
            },
            collections: insight.collections,
            cover_photo: insight.cover_photo,
            initialStorage: insight?.storage || 0,
          },
          true,
        );
        insight.collections.forEach((collection) => {
          const newMedias: MediaToEdit[] = [];
          if (collection?.collection_media) {
            collection.collection_media.forEach((media) => {
              const existMedia = newMedias.find((m) => m.uuid === media.uuid);
              if (!existMedia) {
                newMedias.push({
                  uuid: media.uuid,
                  url: media.url,
                  duration: media.duration?.toString() || '',
                  type: media.type,
                });
              }
            });
          }
          editCollection(
            {
              mediasToEdit: newMedias,
            },
            collection.uuid,
            true,
          );
        });
        setIsLoading(false);
      }
    }
  }, [editCollection, editInsight, getInsightDetails, groupId, insightUuid, location?.state?.fetchInsight]);

  useEffect(() => {
    if (editingInsight.cover_photo) {
      let numberOfCoverPhotos = 0;
      editingInsight.cover_photo.forEach((cover) => {
        if (cover.type !== '') {
          numberOfCoverPhotos++;
        }
      });
      setCoverPhotosNumber(numberOfCoverPhotos);
    } else {
      setCoverPhotosNumber(0);
    }
  }, [editingInsight]);

  useEffect(() => {
    getInsight();

    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = ''; // Define uma mensagem vazia para o navegador (não mostrando mensagem padrão)

      // Exibe a mensagem de notificação personalizada
      const confirmationMessage = t('are_you_sure_you_want_to_leave_all_unsaved_data_will_be_lost');
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    // Adiciona o ouvinte de evento ao carregar o componente
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove o ouvinte de evento ao descarregar o componente
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [getInsight, t]);

  function handleAddNewCollection() {
    if (editingInsight.collections.length === MAX_COLLECTIONS) {
      toast({
        label: t('error'),
        message: t('you_can_only_have_10_collections'),
        type: 'error',
      });
      return;
    }
    for (let i = 0; i < editingInsight.collections.length; i++) {
      if (
        editingInsight.collections[i].description === '' &&
        editingInsight?.collections[i]?.mediasToEdit?.length === 0
      ) {
        toast({
          label: t('error'),
          message: t('one_or_more_collections_have_no_description_or_files'),
          type: 'error',
        });
        return;
      }
    }
    editInsight(
      {
        collections: [
          ...editingInsight.collections,
          { uuid: uuid(), title: '', description: '', files: [], coverPhoto: {}, uploadedFiles: [], mediasToEdit: [] },
        ],
      },
      true,
    );
  }

  function handleNextStep() {
    if (editingInsight.title === '') {
      toast({
        label: t('error'),
        message: t('the_insight_title_cannot_be_empty'),
        type: 'error',
      });
      return;
    }
    let collectionsOnlyWithDescription = 0;
    let collectionsWithoutTitle = 0;
    for (let i = 0; i < editingInsight.collections.length; i++) {
      const collection = editingInsight.collections[i];
      if (collection.description === '' && collection?.mediasToEdit?.length === 0) {
        toast({
          label: t('error'),
          message: t('one_or_more_collections_have_no_description_or_files'),
          type: 'error',
        });
        return;
      }
      const uploadedFilesWithoutAudio = collection?.mediasToEdit?.filter(
        (uploaded) => !uploaded?.file?.type?.includes('audio') && !uploaded?.type?.includes('audio'),
      );

      if (
        (collection?.mediasToEdit?.length === 0 && collection.description) ||
        uploadedFilesWithoutAudio?.length === 0
      ) {
        collectionsOnlyWithDescription++;
      }
      if (collection.title === '') {
        collectionsWithoutTitle++;
      }
    }
    if (coverPhotosNumber < 1 && collectionsOnlyWithDescription < editingInsight.collections.length) {
      toast({
        label: t('error'),
        message: t('please_select_cover_photos'),
        type: 'error',
      });
      return;
    }
    if (coverPhotosNumber > 3) {
      toast({
        label: t('error'),
        message: t('a_maximum_of_3_collections_can_have_a_cover_photo'),
        type: 'error',
      });
      return;
    }

    if (collectionsWithoutTitle > 0 && editingInsight.collections.length > 1) {
      toast({
        label: t('error'),
        message: t('collections_must_have_a_title'),
        type: 'error',
      });
      return;
    }
    navigate('groups');
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <section className="flex justify-center w-full min-h-[calc(100vh-88px)] bg-backgray py-12 mb-5 relative">
          {editMode ? (
            <div className="flex flex-col gap-12 mb-10">
              {editingInsight.collections.map((collection) => (
                <CollectionEditModeCard
                  key={collection.uuid}
                  collection={collection}
                  setEditMode={setEditMode}
                  isEditingInsight
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col gap-8 w-[435px] mb-10">
              <h2 className="text-xl font-medium">{t('choose_a_title_for_your_insight')}</h2>
              <input
                maxLength={255}
                value={editingInsight?.title}
                onChange={(e) => editInsight({ title: e.target.value }, true)}
                placeholder={t('add_a_insight_title')}
                type="text"
                className="w-full bg-white py-4 px-5 border border-grey-300 rounded-md text-sm placeholder:text-grey-300 outline-grey-200"
              />
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-medium">{t('edit_a_post')}</h2>
                <Dialog.Root>
                  <Dialog.Trigger asChild>
                    <button className="rounded-full px-5 py-1 bg-primary-200 text-white hover:opacity-70 shadow-md">
                      {t('all_cover_photos')} {coverPhotosNumber} / 3
                    </button>
                  </Dialog.Trigger>
                  <CoverPhotosModal />
                </Dialog.Root>
              </div>
              {editingInsight.collections.map((collection) => (
                <CollectionEditCard key={collection.uuid} collection={collection} />
              ))}
            </div>
          )}
          <footer className="fixed bottom-0 right-0 bg-white w-[calc(100vw-200px)] flex items-center justify-center py-5 gap-5">
            <button
              onClick={handleAddNewCollection}
              className="bg-primary-200 rounded-md shadow-md flex items-center justify-center py-[10px] px-[10px] hover:opacity-70 disabled:bg-grey-400"
            >
              <span className="text-xs text-white mr-[5px]">{t('new_collection')}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M10.0003 4.16602V15.8327M4.16699 9.99935H15.8337"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {editingInsight.collections.length > 1 && (
              <button
                onClick={() => setEditMode(!editMode)}
                className="bg-primary-200 rounded-md shadow-md flex items-center justify-center p-2 hover:opacity-70 disabled:bg-grey-400 relative"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M8 8V5.2C8 4.0799 8 3.51984 8.21799 3.09202C8.40973 2.71569 8.71569 2.40973 9.09202 2.21799C9.51984 2 10.0799 2 11.2 2H18.8C19.9201 2 20.4802 2 20.908 2.21799C21.2843 2.40973 21.5903 2.71569 21.782 3.09202C22 3.51984 22 4.0799 22 5.2V12.8C22 13.9201 22 14.4802 21.782 14.908C21.5903 15.2843 21.2843 15.5903 20.908 15.782C20.4802 16 19.9201 16 18.8 16H16M5.2 22H12.8C13.9201 22 14.4802 22 14.908 21.782C15.2843 21.5903 15.5903 21.2843 15.782 20.908C16 20.4802 16 19.9201 16 18.8V11.2C16 10.0799 16 9.51984 15.782 9.09202C15.5903 8.71569 15.2843 8.40973 14.908 8.21799C14.4802 8 13.9201 8 12.8 8H5.2C4.0799 8 3.51984 8 3.09202 8.21799C2.71569 8.40973 2.40973 8.71569 2.21799 9.09202C2 9.51984 2 10.0799 2 11.2V18.8C2 19.9201 2 20.4802 2.21799 20.908C2.40973 21.2843 2.71569 21.5903 3.09202 21.782C3.51984 22 4.07989 22 5.2 22Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="absolute w-[20px] h-[20px] top-[-10px] right-[-1px] rounded-full bg-primary-100 text-white text-[10px] font-semibold flex items-center justify-center">
                  {editingInsight.collections.length}
                </span>
              </button>
            )}
            <button
              onClick={handleNextStep}
              data-test="edit-insight-next"
              className="bg-primary-200 rounded-md shadow-md flex items-center justify-center py-[8px] px-[20px] text-white font-semibold hover:opacity-70 disabled:bg-grey-400"
            >
              {t('next')}
            </button>
          </footer>
        </section>
      )}
    </>
  );
}
