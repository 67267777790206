import { useCallback, useEffect, useState } from 'react';

import { useSubscription } from '@hooks/useSubscription';

import EmptySubscriptions from '@components/EmptyStates/EmptySubscriptions';

import Loading from '@components/Loading';
import { PaginationComponent } from '@components/Pagination';
import { t } from 'i18next';

export default function Subscription() {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState<{ current: number; next: number[]; previous: number[] }>();

  const { getSubscriptionsForUser, subscriptions, pagination } = useSubscription();

  const getSubscriptions = useCallback(async () => {
    setIsLoading(true);
    await getSubscriptionsForUser('?take=10&page=1');
    setIsLoading(false);
  }, [getSubscriptionsForUser]);

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  async function handleSelectPage(selectedPage: number) {
    setIsLoading(true);
    await getSubscriptionsForUser(`?take=10&page=${selectedPage}`);
    setIsLoading(false);
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col gap-5 items-center w-full py-12 bg-backgray min-h-[calc(100vh-88px)] relative">
          {subscriptions?.length === 0 && (
            <div className="py-10">
              <EmptySubscriptions />
            </div>
          )}
          {subscriptions &&
            subscriptions?.length > 0 &&
            subscriptions.map((subscription) => {
              return (
                <div
                  key={subscription.uuid}
                  className="rounded-md w-[500px] px-6 py-8 relative bg-white shadow-md flex flex-col"
                >
                  <div className="flex items-center justify-between">
                    <p className="text-lg font-semibold">{subscription?.group?.name || ''}</p>
                    <p className={`text-sm ${subscription.status === 'active' ? 'text-green-600' : 'text-red-600'}`}>
                      {t(subscription.status)}
                    </p>
                  </div>
                  <span className="text-sm font-light">
                    {t('type')}: {subscription.subscription_plan.name}
                  </span>

                  <h3 className="font-medium mt-5">{t('size')}</h3>
                  <span className="text-sm font-light">
                    {t('used')}: {subscription?.group?.storage_used} / {subscription?.group?.storage_limit}
                  </span>
                </div>
              );
            })}

          {subscriptions?.length > 0 && (
            <PaginationComponent
              page={page}
              handleSelectPage={handleSelectPage}
              setPage={setPage}
              pagination={pagination}
            />
          )}
        </div>
      )}
    </>
  );
}
