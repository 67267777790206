import { create } from 'zustand';
import { api } from '../services/axios';
import { showErrorMessage } from '../utils/auxFunctions';

export interface MoonPhase {
  start_time: string;
  end_time: string;
  description: string;
  title: string;
  svg: string;
}
interface Event {
  uuid: string;
  title: string;
  location: string;
  start_time: string;
  end_time: string;
  event_label: {
    uuid: string;
    name: string;
    color: string;
  };
}
interface TideInfo {
  tideLevel: string;
  tideTime: string;
  tideType: string;
}

export interface MoonPhasesRecurrencie {
  phaseName?: string;
  isPhaseLimit?: number;
  lighting?: number;
  svg?: string;
  svgMini?: string;
  timeEvent?: string;
  dis?: number;
  dayWeek?: number;
  npWidget?: string;
  day?: number;
  month?: number;
  year?: number;
}

interface CalendarDay {
  event: Event[];
  moonPhase: MoonPhase[];
  tides: TideInfo[];
}
export interface CalendarUser {
  [date: string]: CalendarDay;
}
export interface CalendarConfig {
  show_holidays: boolean;
  show_moon_phases: boolean;
  show_tides: boolean;
}
type CalendarType = 'lunar' | 'tide' | 'holidays' | null;
interface CalendarProps {
  type: CalendarType;
  changeCalendarType: (type: 'lunar' | 'tide' | 'holidays' | null) => void;

  userCalendar: CalendarUser | object;
  userCalendarByDay: CalendarUser | object;
  moonPhasesRecurrencies: MoonPhasesRecurrencie[];
  setUserCalendar: (value: CalendarUser | object) => void;
  setUserCalendarByDay: (value: CalendarUser | object) => void;
  getUserCalendar: (params?: string) => Promise<void>;
  getUserCalendarByDay: (params?: string) => Promise<void>;
  getGroupCalendar: (groupUuid: string, params?: string) => Promise<void>;
  getGroupCalendarByDay: (groupUuid: string, params?: string) => Promise<void>;
  getCalendarConfig: () => Promise<{ settings: CalendarConfig }>;
  updateCalendarConfig: (settings: CalendarConfig) => Promise<void>;
  searchLunarRecurrencies: (dataToPost: {
    year: string;
    month: string;
    moon: string;
  }) => Promise<MoonPhasesRecurrencie[] | null>;
  setMoonPhasesRecurrencies: (value: MoonPhasesRecurrencie[]) => void;
  clearMoonPhasesRecurrencies: () => void;
}

export const useCalendar = create<CalendarProps>((set) => ({
  type: null,

  changeCalendarType: (type: 'lunar' | 'tide' | 'holidays' | null) => {
    set({ type });
  },

  userCalendar: {},
  userCalendarByDay: {},
  moonPhasesRecurrencies: [],
  setUserCalendar: (value: CalendarUser | object) => set(() => ({ userCalendar: value })),
  setUserCalendarByDay: (value: CalendarUser | object) => set(() => ({ userCalendarByDay: value })),
  setMoonPhasesRecurrencies: (value: MoonPhasesRecurrencie[]) => set(() => ({ moonPhasesRecurrencies: value })),
  getUserCalendar: async (params?: string) => {
    try {
      const { data } = await api.get(`/calendar/user${params ? '?' + params : ''}`);
      set({ userCalendar: data.content.data });
    } catch (err) {
      console.log(err);
    }
  },
  getUserCalendarByDay: async (params?: string) => {
    try {
      const { data } = await api.get(`/calendar/user${params ? '?' + params : ''}`);
      set({ userCalendarByDay: data.content.data });
    } catch (err) {
      console.log(err);
    }
  },
  getGroupCalendar: async (groupUuid: string, params?: string) => {
    try {
      const { data } = await api.get(`/calendar/group/${groupUuid}${params ? '?' + params : ''}`);
      set({ userCalendar: data.content.data });
    } catch (err) {
      console.log(err);
    }
  },
  getGroupCalendarByDay: async (groupUuid: string, params?: string) => {
    try {
      const { data } = await api.get(`/calendar/group/${groupUuid}${params ? '?' + params : ''}`);
      set({ userCalendarByDay: data.content.data });
    } catch (err) {
      console.log(err);
    }
  },
  getCalendarConfig: async () => {
    try {
      const { data } = await api.get('/user-calendar-settings');
      return {
        settings: data.content.data,
      };
    } catch (err) {
      console.log(err);
      return { settings: {} };
    }
  },
  updateCalendarConfig: async (settings: CalendarConfig) => {
    try {
      await api.put('/user-calendar-settings', settings);
    } catch (err) {
      console.log(err);
    }
  },

  searchLunarRecurrencies: async (dataToPost: { year: string; month: string; moon: string }) => {
    try {
      const { data } = await api.get('/calendar/moon-phases', {
        params: dataToPost,
      });
      set({ moonPhasesRecurrencies: data.content.data });
      return data.content.data;
    } catch (err) {
      showErrorMessage(err);
      return null;
    }
  },

  clearMoonPhasesRecurrencies: () => {
    set({ moonPhasesRecurrencies: [] });
  },
}));
