import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Category } from '@hooks/useCategory';
import { useInsights } from '@hooks/useInsights';

interface TopicsListComponentProps {
  category: Category;
}

export default function TopicsListComponent({ category }: Readonly<TopicsListComponentProps>) {
  const { editingInsight, editInsight } = useInsights();
  const [isSelected, setIsSelected] = useState<boolean>(editingInsight?.category.topicUuid === category.uuid || false);

  const { insightUuid, groupId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    setIsSelected(editingInsight.category.topicUuid === category.uuid);
  }, [category.uuid, editingInsight.category.topicUuid]);

  function handleSelectTopic(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.stopPropagation();
    editInsight(
      {
        category: {
          uuid: editingInsight.category.uuid,
          name: editingInsight.category.name,
          subCategoryUuid: editingInsight.category.subCategoryUuid,
          subCategoryName: editingInsight.category.subCategoryName,
          topicUuid: category.uuid,
          topicName: category.name,
        },
      },
      true,
    );
    navigate('/insight/edit/' + groupId + '/' + insightUuid + '/finish?previous=editInsightCategories');
  }

  return (
    <button
      className={`flex items-center w-full gap-3 py-3 pl-24 pr-3 transition-all border-b border-grey-600 cursor-pointer ${
        isSelected ? 'bg-primary-200 bg-opacity-10' : ''
      }`}
      onClick={() => {
        if (!isSelected) {
          editInsight(
            {
              category: {
                uuid: editingInsight.category.uuid,
                name: editingInsight.category.name,
                subCategoryUuid: editingInsight.category.subCategoryUuid,
                subCategoryName: editingInsight.category.subCategoryName,
                topicUuid: category.uuid,
                topicName: category.name,
              },
            },
            true,
          );
        } else {
          editInsight(
            {
              category: {
                uuid: editingInsight.category.uuid,
                name: editingInsight.category.name,
                subCategoryUuid: editingInsight.category.subCategoryUuid,
                subCategoryName: editingInsight.category.subCategoryName,
                topicUuid: '',
                topicName: '',
              },
            },
            true,
          );
        }
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
        <g clipPath="url(#clip0_3335_17023)">
          <path
            d="M7.5 14.25C10.9518 14.25 13.75 11.4518 13.75 8C13.75 4.54822 10.9518 1.75 7.5 1.75C4.04822 1.75 1.25 4.54822 1.25 8C1.25 11.4518 4.04822 14.25 7.5 14.25Z"
            stroke="#282A74"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3335_17023">
            <rect width="15" height="15" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </svg>
      <p>{category?.name}</p>
      {isSelected && (
        <button onClick={handleSelectTopic} className="hover:opacity-70 z-[1] ml-auto">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 5V19M5 12H19" stroke="#EDB561" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      )}
    </button>
  );
}
