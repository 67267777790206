import { useState } from 'react';

import { useUser } from '@hooks/useUser';

import { useTranslation } from 'react-i18next';
import { toast } from 'utils/toast';

export default function LanguagePage() {
  const { i18n, t } = useTranslation();
  const { user, updateUser } = useUser();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(user?.language || 'en');

  async function handleUpdateLanguage(currentLanguage: string) {
    i18n.changeLanguage(currentLanguage);
    const response = await updateUser({
      language: currentLanguage,
    });
    if (response.uuid) {
      toast({
        label: t('success'),
        message: t('language_updated_successfully'),
        type: 'success',
      });
      return;
    }
    i18n.changeLanguage(user?.language);
    toast({
      label: t('error'),
      message: t('failed_to_update_language'),
      type: 'error',
    });
  }
  return (
    <div className="flex flex-col w-full items-center">
      <div className="w-[50vw] flex flex-col py-10 mb-10">
        <button
          className="px-5 py-8 w-full border-b border-grey-600 hover:opacity-70 flex items-center justify-between"
          onClick={() => {
            setSelectedLanguage('en');
            handleUpdateLanguage('en');
          }}
        >
          <span className="text-lg text-black-100">English</span>
          {selectedLanguage === 'en' && (
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
              <path
                d="M5.2085 14.0415L9.37516 18.2082L19.7918 7.7915"
                stroke="#EDB561"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </button>
        <button
          className="px-5 py-8 w-full border-b border-grey-600 hover:opacity-70 flex items-center justify-between"
          onClick={() => {
            setSelectedLanguage('pt');
            handleUpdateLanguage('pt');
          }}
        >
          <span className="text-lg text-black-100">Portuguese (Portugal)</span>
          {selectedLanguage === 'pt' && (
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
              <path
                d="M5.2085 14.0415L9.37516 18.2082L19.7918 7.7915"
                stroke="#EDB561"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </button>
      </div>
      {/* <Button onClick={handleUpdateLanguage} size="medium" variant="primary" text={t('done')} /> */}
    </div>
  );
}
