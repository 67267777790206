export function Trash() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" stroke="black" viewBox="0 0 27 29">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M24 7l-1.301 18.214A3 3 0 0119.707 28H7.293a3 3 0 01-2.992-2.786L3 7m7.5 6v9m6-9v9M18 7V2.5A1.5 1.5 0 0016.5 1h-6A1.5 1.5 0 009 2.5V7M1.5 7h24"
      ></path>
    </svg>
  );
}
