import { useCallback, useEffect, useState } from 'react';

import { useGroups } from '@hooks/useGroups';
import { useUser } from '@hooks/useUser';

import { Button } from '@components/Button';

import appleStore from '@assets/appleStore.png';
import googlePlay from '@assets/googleplay.png';
import userNoPhoto from '@assets/icons/user-no-photo.svg';
import logo from '@assets/logo.svg';

import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'utils/toast';

function InvitePage() {
  const { t } = useTranslation();

  const [isValid, setIsValid] = useState<'valid' | 'invalid' | 'already-member'>('valid');

  const { code } = useParams();
  const { user } = useUser();
  const { validateShareLink, group, joinGuest, denyGuest } = useGroups();
  const navigate = useNavigate();

  function handleRedirect(type: 'android' | 'ios') {
    if (isMobile) {
      if (type === 'android') {
        window.open(`https://play.google.com/store/apps/details?id=com.noiteminente.familywisdom`);
      } else {
        window.open(`https://apps.apple.com/us/app/family-wisdom/id6475693830?platform=iphone`);
      }
      if (!code) return;

      const scheme = import.meta.env.VITE_APP_SCHEME ?? 'com.noiteminente.familywisdom://';
      window.location.replace(`${scheme}invite/${code}`);
    }
  }

  async function handleAcceptInvite() {
    if (!code) return;

    const response = await joinGuest(group?.uuid, user?.uuid!);
    if (response?.status === 202) {
      toast({
        label: t('error'),
        message: t('already_member'),
        type: 'error',
      });
      navigate('/');
    } else if (response?.status === 200) {
      toast({
        label: t('success'),
        message: t('request_sent'),
        type: 'success',
      });
      navigate('/');
    }
  }

  async function handleRejectInvite() {
    if (!code) return;

    const response = await denyGuest(group?.uuid, user?.uuid!);

    if (response?.status === 202) {
      toast({
        label: t('error'),
        message: t('already_member'),
        type: 'error',
      });
      navigate('/');
    } else if (response?.status === 200) {
      toast({
        label: t('success'),
        message: t('request_sent'),
        type: 'success',
      });
      navigate('/');
    }
  }

  const handleValidateLink = useCallback(async () => {
    if (!code) return;
    const result = await validateShareLink(code);
    setIsValid(result);

    if (result === 'valid') {
      if (isMobile) {
        const scheme = import.meta.env.VITE_APP_SCHEME ?? 'com.noiteminente.familywisdom://';
        window.location.replace(`${scheme}invite/${code}`);
      } else {
        if (!user) {
          localStorage.setItem('inviteLink', code);
          navigate('/login');
          return;
        }
        localStorage.setItem('inviteLink', '');
      }
    }
  }, [code, navigate, user, validateShareLink]);

  useEffect(() => {
    handleValidateLink();
  }, [code, handleValidateLink]);

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="flex flex-col gap-5 items-center w-[400px] bg-white p-8 shadow-md border border-gray-100">
        {isValid === 'invalid' && (
          <>
            <img className="shadow-md rounded-full" width={48} height={48} src={logo} alt="Logo" />
            <h1 className="text-primary-200 text-3xl font-semibold text-center">{group?.name ?? 'Family Wisdom'}</h1>
            <span className="text-primary-200">{t('invalid_link')}</span>
          </>
        )}
        {isValid === 'already-member' && (
          <>
            <img className="shadow-md rounded-full" width={48} height={48} src={logo} alt="Logo" />
            <h1 className="text-primary-200 text-3xl font-semibold text-center">{group?.name ?? 'Family Wisdom'}</h1>
            <span className="text-primary-200">{t('already_member')}</span>
          </>
        )}
        {isValid === 'valid' && (
          <>
            <img className="shadow-md rounded-full" width={48} height={48} src={logo} alt="Logo" />
            <h1 className="text-primary-200 text-3xl font-semibold text-center">{group?.name ?? 'Family Wisdom'}</h1>
            {isMobile && (
              <span className="text-primary-200">
                {t('invite_code')}: <b>{code}</b>
              </span>
            )}
            <span className="text-center text-primary-200 text-sm">
              {t('you_have_been_invited')} {group?.user_created?.first_name}.
            </span>
            <span className="flex my-5">
              {group?.last6Members &&
                group.last6Members.map((member, index) => {
                  if (index < 3) {
                    return (
                      <img
                        alt="Member"
                        key={index}
                        src={member.image || userNoPhoto}
                        className={`min-w-12 min-h-12 max-w-12 max-h-12 rounded-full ${
                          group.last6Members && group.last6Members.length > 1 && index > 0 ? 'ml-[-20px]' : ''
                        }`}
                      />
                    );
                  } else if (index === 3) {
                    return (
                      <div
                        key={index}
                        className={`relative ${
                          group.last6Members && group.last6Members.length > 1 && index > 0 ? 'ml-[-20px]' : ''
                        }`}
                      >
                        <img
                          alt="Member"
                          src={member.image || userNoPhoto}
                          className={`min-w-12 min-h-12 max-w-12 max-h-12 rounded-full ${
                            group?.users_count && group?.users_count > 4 && 'brightness-50'
                          }`}
                        />
                        {group?.users_count && group?.users_count > 4 && (
                          <span className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-xs">
                            +{group?.users_count ? group?.users_count - 4 : ''}
                          </span>
                        )}
                      </div>
                    );
                  }
                })}
            </span>
            {isMobile ? (
              <div className="flex flex-col gap-5">
                <button onClick={() => handleRedirect('android')}>
                  <img src={googlePlay} className="w-[150px] h-[40px]" alt="Google" />
                </button>
                <button onClick={() => handleRedirect('ios')}>
                  <img src={appleStore} className="w-[150px] h-[40px]" alt="Apple" />
                </button>
              </div>
            ) : (
              <div className="flex items-center gap-10">
                <button onClick={handleRejectInvite} className="hover:opacity-70">
                  {t('reject')}
                </button>
                <Button text={t('accept')} onClick={handleAcceptInvite} size="medium" variant="primary" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default InvitePage;
