import { create } from 'zustand';

import { showErrorMessage } from '../utils/auxFunctions';
import { api } from '../services/axios';

export interface Guest {
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
  provider: string;
  birth_date: string;
  image: string;
  country_id: string;
  phone: string;
  language: string;
  timezone: string;
}

interface GuestProps {
  guests: Guest[];
  getGuestsByPostUuid: (groupUuid: string, postUuid: string) => Promise<Guest[]>;
  deleteGuest: (groupUuid: string, postUuid: string, userUuid: string) => Promise<any>;
  inviteGuest: (groupUuid: string, postUuid: string, email_or_phone: string) => Promise<any>;
}

export const useGuest = create<GuestProps>((set) => ({
  guests: [],
  getGuestsByPostUuid: async (groupUuid: string, postUuid: string) => {
    try {
      const { data } = await api.get(`/groups/${groupUuid}/posts/${postUuid}/guests`);
      set({ guests: data.content.data });
      return data.content.data;
    } catch (err) {
      showErrorMessage(err);
    }
  },

  deleteGuest: async (groupUuid: string, postUuid: string, userUuid: string) => {
    try {
      const { data } = await api.delete(`/groups/${groupUuid}/posts/${postUuid}/guests/${userUuid}`);
      return data.content.data;
    } catch (err) {
      showErrorMessage(err);
    }
  },

  inviteGuest: async (groupUuid: string, postUuid: string, email_or_phone: string) => {
    try {
      const { data } = await api.post(`/groups/${groupUuid}/posts/${postUuid}/guests`, { email_or_phone });
      return data.content.data;
    } catch (err) {
      showErrorMessage(err);
    }
  },
}));
