import { useUser } from '@hooks/useUser';
import { Navigate } from 'react-router-dom';

export default function RedirectTo() {
  const { getUser, verifyUser } = useUser();
  const isTokenVerify = verifyUser();
  const existUser = getUser();

  if (!existUser || !isTokenVerify) return <Navigate to="/login" />;

  return <Navigate to="/feed" />;
}
