import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AdsCreateDTO, useAds } from '@hooks/useAds';
import { useUser } from '@hooks/useUser';

import Loading from '@components/Loading';
import AdsPrimaryInformation from './PrimaryInformation';
import AdsSecondaryInformation from './SecondaryInformation';

import { FileArrowUp } from '@phosphor-icons/react';
import { retrieveImgS3 } from 'utils/auxFunctions';
import { uploadToS3 } from 'utils/awsS3';
import { toast } from 'utils/toast';

export default function CreateAds() {
  const { t } = useTranslation();
  const { user } = useUser();
  const { ads, setAds, clearAds, createAds } = useAds();

  const [isLoading, setIsLoading] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState<File | undefined>();

  const navigate = useNavigate();

  useEffect(() => {
    clearAds();
    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = '';

      const confirmationMessage = t('are_you_sure_you_want_to_leave_all_unsaved_data_will_be_lost');
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [t]);

  const handleCreateAds = useCallback(async () => {
    setIsLoading(true);
    if (ads.title === '') {
      setIsLoading(false);
      toast({
        label: t('error'),
        message: t('missing_title'),
        type: 'error',
      });
      return;
    }

    if (!ads?.name) {
      setIsLoading(false);
      toast({
        label: t('error'),
        message: t('missing_name'),
        type: 'error',
      });
      return;
    }

    if (!profilePhoto) {
      setIsLoading(false);
      toast({
        label: t('error'),
        message: t('missing_profile_photo'),
        type: 'error',
      });
      return;
    }

    if (!ads.link) {
      setIsLoading(false);
      toast({
        label: t('error'),
        message: t('missing_link'),
        type: 'error',
      });
      return;
    }

    if (!ads.description) {
      setIsLoading(false);
      toast({
        label: t('error'),
        message: t('missing_description'),
        type: 'error',
      });
      return;
    }

    if (!ads.file) {
      setIsLoading(false);
      toast({
        label: t('error'),
        message: t('missing_file'),
        type: 'error',
      });
      return;
    }

    if (!user?.first_name || !user?.last_name) {
      setIsLoading(false);
      toast({
        label: t('error'),
        message: t('failed_to_obtain_user_information'),
        type: 'error',
      });
      return;
    }

    if (ads.startDate === '' || ads.expirationDate === '') {
      setIsLoading(false);
      toast({
        label: t('error'),
        message: t('missing_dates'),
        type: 'error',
      });
      return;
    }

    // if (ads.priority === '' || Number(ads.priority) < 1 || isNaN(Number(ads.priority))) {
    //   setIsLoading(false);
    //   toast({
    //     label: t('error'),
    //     message: t('priority_must_be_higher_than_0'),
    //     type: 'error',
    //   });
    //   return;
    // }
    if (!profilePhoto) {
      return;
    }
    const { key: keyProfilePhoto, error: errProfilePhoto } = await uploadToS3(profilePhoto);
    if (errProfilePhoto) {
      toast({
        label: t('error'),
        message: t('failed_to_upload'),
        type: 'error',
      });
      return;
    }

    const profilePhotoS3 = retrieveImgS3(String(keyProfilePhoto));
    const adsCreateDTO: AdsCreateDTO = {
      title: ads.title,
      description: ads.description,
      active: ads.activeState,
      external_url: ads.link ?? '',
      aws_type: 'image',
      aws_url: '',
      // priority: Number(ads.priority),
      max_clicks: Number(ads.maxClickImpression),
      max_views: Number(ads.maxViewImpression),
      start_date: ads.startDate ?? '',
      end_date: ads.expirationDate ?? '',
      name: String(ads?.name),
      profile_photo: profilePhotoS3,
    };

    if (!ads.file.type.includes('video') && !ads.file.type.includes('image')) {
      setIsLoading(false);
      toast({
        label: t('error'),
        message: t('failed_to_upload'),
        type: 'error',
      });
      return;
    }

    // COVER PHOTO
    // if (ads.file?.type.includes('video')) {
    //   const file = base64ToFile(ads?.thumbnail ?? '');
    //   if (!file) {
    //     toast({
    //       label: t('error'),
    //       message: t('failed_to_upload'),
    //       type: 'error',
    //     });
    //     return;
    //   }
    //   const { key, error } = await uploadToS3(file);
    //   if (error) {
    //     toast({
    //       label: t('error'),
    //       message: t('failed_to_upload'),
    //       type: 'error',
    //     });
    //     return;
    //   }
    //   if (key) {
    //     adsCreateDTO.url_aws_cover = retrieveImgS3(key);
    //   }
    // }

    // UPLOAD FILE
    const { key, error } = await uploadToS3(ads.file);
    if (error) {
      toast({
        label: t('error'),
        message: t('failed_to_upload'),
        type: 'error',
      });
      return;
    }
    if (key) {
      adsCreateDTO.aws_url = retrieveImgS3(key);
      adsCreateDTO.aws_type = ads.file.type.includes('image') ? 'image' : 'video';
    }

    const reponse = await createAds(adsCreateDTO);
    if (reponse?.uuid) {
      toast({
        label: t('success'),
        message: t('ads_created'),
        type: 'success',
      });
      setIsLoading(false);
      navigate('/');
    }

    setIsLoading(false);
  }, [ads]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <section className="flex justify-center w-full min-h-[calc(100vh-88px)] bg-backgray py-12 mb-10 relative">
          <div className="flex flex-col gap-8 w-[435px] mb-5">
            <h2 className="text-xl font-medium">{t('choose_a_title_for_your_ads')}</h2>
            <input
              value={ads.title}
              maxLength={100}
              onChange={(e) => setAds({ title: e.target.value })}
              placeholder={t('add_a_ads_title')}
              type="text"
              className="w-full bg-white py-4 px-5 border border-grey-300 rounded-md text-sm placeholder:text-grey-300 outline-grey-200"
            />
            <h2 className="text-xl font-medium">{t('choose_a_comapny_name_for_your_ads')}</h2>
            <input
              value={ads.name}
              maxLength={100}
              onChange={(e) => setAds({ name: e.target.value })}
              placeholder={t('add_company_name')}
              type="text"
              className="w-full bg-white py-4 px-5 border border-grey-300 rounded-md text-sm placeholder:text-grey-300 outline-grey-200"
            />
            <h2 className="text-xl font-medium">{t('advertiser_photo')}</h2>
            <div className="w-full relative bg-white py-4 px-5 border border-grey-300 rounded-md text-sm placeholder:text-grey-300 outline-grey-200">
              <input
                maxLength={100}
                onChange={(e) => setProfilePhoto(e.target.files?.[0])}
                placeholder={t('advertiser_photo')}
                type="file"
                accept="image/png, image/jpg, image/jpeg, image/heic"
                className="absolute opacity-0 top-0 left-0 w-full h-full "
              />
              <div className="flex items-center gap-2">
                {!profilePhoto ? (
                  <FileArrowUp size={32} color="#828282" />
                ) : (
                  <img src={URL.createObjectURL(profilePhoto)} alt="Profile" className="w-8 h-8" />
                )}
                <span className="text-grey-300">{profilePhoto ? profilePhoto.name : t('advertiser_photo')}</span>
              </div>
            </div>
            <h2 className="text-xl font-medium">{t('create_a_ads')}</h2>
            <AdsPrimaryInformation />

            <AdsSecondaryInformation />
          </div>
          <footer className="fixed bottom-0 right-0 bg-white w-full flex items-center justify-center py-5 gap-5">
            <button
              onClick={handleCreateAds}
              className="bg-primary-200 rounded-md shadow-md flex items-center justify-center py-[8px] px-[20px] text-white font-semibold hover:opacity-70 disabled:bg-grey-400"
            >
              {t('publish')}
            </button>
          </footer>
        </section>
      )}
    </>
  );
}
