import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useRef, useState } from 'react';

import { Collection, useInsights } from '@hooks/useInsights';

import { Button } from '@components/Button';
import ButtonAction from '@components/ButtonAction';

import cloudUpload from '@assets/icons/Profile/cloud-upload.svg';
import microfone from '@assets/icons/Profile/microfone.svg';

import { useTranslation } from 'react-i18next';
import { toast } from 'utils/toast';
import { v4 as uuid } from 'uuid';
interface RecordAudioModalProps {
  collection: Collection;
  isEdit?: boolean;
}

export default function RecordAudioModal({ collection, isEdit }: RecordAudioModalProps) {
  const [showRecordAudio, setShowRecordAudio] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const [recording, setRecording] = useState<boolean>(false);
  const [timer, setTimer] = useState<string>('00:00');

  const { editCollection, creatingInsight, editingInsight } = useInsights();

  const { t } = useTranslation();

  const closeButton = useRef<HTMLButtonElement>(null);
  const inputAudio = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let intervalId: any;

    if (recording) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          const prevTimerArray = prevTimer.split(':');
          if (prevTimerArray.length === 2) {
            const minutes = parseInt(prevTimerArray[0]);
            const seconds = parseInt(prevTimerArray[1]);
            if (seconds === 59) {
              return `${String(minutes + 1).padStart(2, '0')}:00`;
            }
            return `${String(minutes).padStart(2, '0')}:${String(seconds + 1).padStart(2, '0')}`;
          } else {
            return '00:00';
          }
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [recording]);

  const startRecording = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const recorder = new MediaRecorder(stream);

        recorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            setAudioChunks(() => [event.data]);
          }
        };

        recorder.start();
        setMediaRecorder(recorder);
        setTimer('00:00');
        setRecording(true);
      } catch (error) {
        toast({
          label: t('error'),
          message: t('failed_to_start_recording'),
          type: 'error',
        });
      }
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && recording) {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  function handleSaveRecordAudio() {
    if (audioChunks.length > 0) {
      const audioFile = new File(audioChunks, `${uuid()}.wav`, { type: 'audio/wav' });
      let files: any = [];
      if (!isEdit) {
        files = creatingInsight.collections.find((c) => c.uuid === collection.uuid)?.uploadedFiles || [];
        editCollection({ uploadedFiles: [...files, audioFile] }, collection.uuid);
      } else {
        files = editingInsight.collections.find((c) => c.uuid === collection.uuid)?.mediasToEdit || [];
        editCollection({ mediasToEdit: [...files, { file: audioFile }] }, collection.uuid, true);
      }
    }
    setTimer('00:00');
    setAudioChunks([]);
    setRecording(false);
    setShowRecordAudio(false);
    closeButton.current?.click();
  }

  function handleAudioChange(event: React.ChangeEvent<HTMLInputElement>) {
    const uploadedFiles = event.currentTarget.files as FileList;
    let files: any = [];
    if (uploadedFiles) {
      if (!isEdit) {
        files = creatingInsight.collections.find((c) => c.uuid === collection.uuid)?.uploadedFiles;
        editCollection({ uploadedFiles: [...files, ...uploadedFiles] }, collection.uuid);
      } else {
        files = editingInsight.collections.find((c) => c.uuid === collection.uuid)?.mediasToEdit || [];
        Array.from(uploadedFiles).forEach((file) => {
          files.push({ file: file });
        });
        editCollection({ mediasToEdit: files }, collection.uuid, true);
      }
    }
    setTimer('00:00');
    setAudioChunks([]);
    setRecording(false);
    setShowRecordAudio(false);
    closeButton.current?.click();
  }

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '10',
        }}
      />
      <Dialog.Content
        forceMount
        className={`z-40 ${
          isEdit ? 'w-[calc(100vw-200px)]' : 'w-[100vw]'
        } rounded-lg p-4 right-0 bottom-0 fixed bg-white font-poppins`}
      >
        <Dialog.Close onClick={() => setShowRecordAudio(false)} ref={closeButton} className={'absolute top-4 right-8'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
            <path
              d="M3.35209 0.489335C2.69963 -0.163112 1.6418 -0.163112 0.989335 0.489335C0.336888 1.1418 0.336888 2.19963 0.989335 2.85209L10.6372 12.4999L0.989435 22.1478C0.336989 22.8002 0.336989 23.8581 0.989435 24.5105C1.6419 25.1629 2.69973 25.1629 3.35217 24.5105L12.9999 14.8627L22.6478 24.5105C23.3002 25.1629 24.3581 25.1629 25.0105 24.5105C25.6629 23.8581 25.6629 22.8002 25.0105 22.1478L15.3627 12.4999L25.0107 2.85209C25.6631 2.19963 25.6631 1.1418 25.0107 0.489335C24.3583 -0.163112 23.3004 -0.163112 22.648 0.489335L12.9999 10.1372L3.35209 0.489335Z"
              fill="#737373"
            />
          </svg>
        </Dialog.Close>
        {showRecordAudio ? (
          <div className="flex flex-col gap-5 items-center w-full">
            <h3 className="text-xl text-grey-150">Tap to record</h3>
            <button
              onClick={() => {
                if (recording) {
                  stopRecording();
                } else {
                  startRecording();
                }
              }}
              className={`flex items-center justify-center rounded-full w-[120px] h-[120px] bg-primary-200 ${
                recording ? '' : 'bg-opacity-20'
              }`}
            >
              <span className="flex items-center justify-center rounded-full w-[100px] h-[100px] bg-white">
                <svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" viewBox="0 0 61 60" fill="none">
                  <path
                    d="M49.8359 26.6016C49.8359 26.3438 49.625 26.1328 49.3672 26.1328H45.8516C45.5938 26.1328 45.3828 26.3438 45.3828 26.6016C45.3828 34.8223 38.7207 41.4844 30.5 41.4844C22.2793 41.4844 15.6172 34.8223 15.6172 26.6016C15.6172 26.3438 15.4062 26.1328 15.1484 26.1328H11.6328C11.375 26.1328 11.1641 26.3438 11.1641 26.6016C11.1641 36.4863 18.582 44.6426 28.1562 45.7969V51.7969H19.6426C18.8398 51.7969 18.1953 52.6348 18.1953 53.6719V55.7812C18.1953 56.0391 18.3594 56.25 18.5586 56.25H42.4414C42.6406 56.25 42.8047 56.0391 42.8047 55.7812V53.6719C42.8047 52.6348 42.1602 51.7969 41.3574 51.7969H32.6094V45.8262C42.2949 44.7715 49.8359 36.5684 49.8359 26.6016ZM30.5 36.5625C36.002 36.5625 40.4609 32.1562 40.4609 26.7188V13.5938C40.4609 8.15625 36.002 3.75 30.5 3.75C24.998 3.75 20.5391 8.15625 20.5391 13.5938V26.7188C20.5391 32.1562 24.998 36.5625 30.5 36.5625ZM24.9922 13.5938C24.9922 10.6289 27.4473 8.20312 30.5 8.20312C33.5527 8.20312 36.0078 10.6289 36.0078 13.5938V26.7188C36.0078 29.6836 33.5527 32.1094 30.5 32.1094C27.4473 32.1094 24.9922 29.6836 24.9922 26.7188V13.5938Z"
                    fill="#282A74"
                  />
                </svg>
              </span>
            </button>
            <h3 className="text-xl text-grey-150">{timer}</h3>
            <Button disabled={recording} onClick={handleSaveRecordAudio} size="medium" variant="primary" text="Add" />
          </div>
        ) : (
          <div className="w-full flex flex-col gap-1 p-10">
            <input hidden multiple type="file" accept=".mp3,audio/*" ref={inputAudio} onChange={handleAudioChange} />
            <ButtonAction onClick={() => setShowRecordAudio(true)} src={microfone} text="Record audio" />
            <ButtonAction
              onClick={() => inputAudio.current?.click()}
              src={cloudUpload}
              text="Upload from your device"
            />
          </div>
        )}
      </Dialog.Content>
    </Dialog.Portal>
  );
}
