import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from '@components/Button';
import classNames from 'classnames';

import logo from '@assets/logo.svg';

import loading from '@assets/icons/loading.gif';
import { useUser } from '@hooks/useUser';
import { t } from 'i18next';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { isEmail, isPhoneNumber } from 'utils/auxFunctions';
import { toast } from 'utils/toast';
import { api } from '../../../../services/axios';

type Props = {
  removingAccount?: boolean;
  isInternal?: boolean;
};

export default function TwoFactor({ isInternal = false, removingAccount = false }: Readonly<Props>) {
  const [values, setValues] = useState<string[]>(['', '', '', '']);
  const [isEmailOrPhone, setIsEmailOrPhone] = useState<'email' | 'phone' | null>(null);
  const [searchParams] = useSearchParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userLanguage, setUserLanguage] = useState<string>('en');
  const [isLoading, setIsLoading] = useState(false);

  const token = searchParams.get('token');
  const emailOrPhone = searchParams.get('email_or_phone');
  const navigate = useNavigate();
  const code = searchParams.get('code');
  const { user, updateUser } = useUser();

  const codeInput0 = useRef<HTMLInputElement>(null);
  const codeInput1 = useRef<HTMLInputElement>(null);
  const codeInput2 = useRef<HTMLInputElement>(null);
  const codeInput3 = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (code) {
      if (isMobile) {
        const scheme = import.meta.env.VITE_APP_SCHEME ?? 'com.noiteminente.familywisdom://';
        window.location.replace(`${scheme}forgot-password-2/${code}/${emailOrPhone}/${token}`);
      } else {
        const newCode = code.split('');
        setValues(newCode);
      }
    }

    const browserLanguage = navigator.language;

    if (browserLanguage.includes('pt')) {
      setUserLanguage('pt');
    } else {
      setUserLanguage('en');
    }
  }, [code, emailOrPhone, token]);

  useEffect(() => {
    if (emailOrPhone) {
      if (isEmail(emailOrPhone)) setIsEmailOrPhone('email');
      if (isPhoneNumber('+' + emailOrPhone)) setIsEmailOrPhone('phone');
    }
  }, [emailOrPhone]);

  function handleInputChange(value: string, position: number) {
    let number = value;
    if (value.length === 4) {
      const intValue = parseInt(value, 10);
      if (intValue.toString().length >= 4) {
        const newArray = [...values];
        newArray[0] = intValue.toString()[0];
        newArray[1] = intValue.toString()[1];
        newArray[2] = intValue.toString()[2];
        newArray[3] = intValue.toString()[3];
        setValues(newArray);
      }
      return;
    }
    if (value.length > 1 && values[position] === value[0]) {
      number = value[1];
    } else if (values.length > 1 && values[position] === value[1]) {
      number = value[0];
    }
    if (/^\d*$/.test(number)) {
      const intValue = parseInt(number, 10);
      if (intValue >= 0 && intValue <= 9) {
        const newArray = [...values];
        newArray[position] = intValue.toString();
        setValues(newArray);
      }
    }
  }

  const handleCofirmTwoFactorCode = useCallback(
    async function handleCofirmTwoFactorCode() {
      if (!emailOrPhone || values.length !== 4) {
        toast({
          label: 'Error',
          message: userLanguage === 'pt' ? 'Código inválido' : 'Invalid code or email',
          type: 'error',
        });
        return;
      }

      if (!values[0] || !values[1] || !values[2] || !values[3]) {
        toast({
          label: 'Error',
          message: userLanguage === 'pt' ? 'Código inválido' : 'Invalid code',
          type: 'error',
        });
        return;
      }

      if (!isEmail(emailOrPhone) && !isPhoneNumber('+' + emailOrPhone)) {
        toast({
          label: 'Error',
          message: userLanguage === 'pt' ? 'Email inválido' : 'Invalid email',
          type: 'error',
        });
        return;
      }

      try {
        setIsSubmitting(true);
        const code = values[0] + values[1] + values[2] + values[3];

        await api.post('/auth/confirm-reset-password-code', {
          code,
          email_or_phone: emailOrPhone,
        });

        navigate(`/password/new-password?token=${token}&email_or_phone=${emailOrPhone}`);
      } catch (error: any) {
        toast({
          label: 'Error',
          message: error?.response?.data?.message || 'Invalid code',
          type: 'error',
        });
      } finally {
        setIsSubmitting(false);
        setIsLoading(false);
      }
    },
    [emailOrPhone, navigate, token, userLanguage, values],
  );
  const handleSetValues = useCallback((value: number) => {
    setValues((prev) => {
      const newArray = [...prev];
      newArray[value] = '';
      return newArray;
    });
  }, []);

  const confirmAccountDeletion = useCallback(async () => {
    if (removingAccount) {
      setIsLoading(true);

      try {
        const code = values.join('');

        console.log(code, values);

        if (!code || code.length < 4) {
          toast({
            label: userLanguage === 'en' ? 'Error' : 'Erro',
            message: userLanguage === 'en' ? 'Insert the code' : 'Digite o código',
            type: 'error',
          });
          return;
        }
        let res = null;
        if (isInternal) {
          res = await api.post('/deletion/confirm', {
            code,
            email_or_phone: emailOrPhone,
          });
          await updateUser(user as any);
        } else {
          res = await api.post('/auth/request-account-deletion', {
            code,
            email_or_phone: emailOrPhone,
          });
        }

        if (res) {
          const message = userLanguage === 'en' ? 'Insert the code' : 'Digite o código';
          toast({
            label: userLanguage === 'en' ? 'Success' : 'Sucesso',
            message: isInternal ? t('remove_account_success') : message,
            type: 'success',
            duration: 5000,
          });

          isInternal ? navigate('/feed') : navigate('/login');
          setTimeout(() => {
            console.info('account deleted');
          }, 1000);
        }
      } catch (error) {
        toast({
          type: 'error',
          message: t('invalid_code'),
          label: userLanguage === 'en' ? 'Error' : 'Erro',
        });
        console.trace(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      handleCofirmTwoFactorCode();
    }
  }, [
    emailOrPhone,
    handleCofirmTwoFactorCode,
    isInternal,
    navigate,
    removingAccount,
    updateUser,
    user,
    userLanguage,
    values,
  ]);

  useEffect(() => {
    if (isAndroid || isIOS) {
      const scheme = import.meta.env.VITE_APP_SCHEME ?? 'com.noiteminente.familywisdom://';
      window.location.replace(
        `${scheme}forgot-password-2/?code=` + code + '&email=' + emailOrPhone + '&token=' + token,
      );
    }
  }, []);

  return (
    <div
      className={classNames(`flex items-center justify-center`, {
        'mt-14': isInternal,
        'w-screen h-screen': !isInternal,
      })}
    >
      <main className="h-[80vh]">
        <div className="flex flex-col justify-center items-center w-full max-w-[400px] max-h-screen px-[50px] pt-[70px] pb-[50px] bg-white shadow-md border border-gray-100 gap-24">
          <img className="shadow-md rounded-lg" width={48} height={48} src={logo} alt="Logo" />
          <div className="text-start flex flex-col w-full">
            <h1 className="text-primary-200 text-xl font-semibold mb-5">
              {isEmailOrPhone === 'email' && userLanguage === 'en' && 'Check your e-mail inbox'}
              {isEmailOrPhone === 'email' && userLanguage === 'pt' && 'Verifique seu e-mail'}
              {isEmailOrPhone === 'phone' && userLanguage === 'en' && 'Check your SMS messages'}
              {isEmailOrPhone === 'phone' && userLanguage === 'pt' && 'Verifique suas mensagens'}
            </h1>
            <div className="flex flex-col gap-5">
              <span className="text-secondary-400 text-xs">
                {isEmailOrPhone === 'email' &&
                  userLanguage === 'en' &&
                  'We need you to verify your email address. A code has been sent to:'}
                {isEmailOrPhone === 'email' &&
                  userLanguage === 'pt' &&
                  'Precisamos que verifique o endereço de email. Foi enviado um código para: '}

                {isEmailOrPhone !== 'email' &&
                  userLanguage === 'en' &&
                  'We need you to verify your SMS. A code has been sent to:'}
                {isEmailOrPhone !== 'email' &&
                  userLanguage === 'pt' &&
                  'Precisamos que verifique o seu SMS. Foi enviado um código para:'}
              </span>

              <span className="text-xs text-secondary-300 text-center">{emailOrPhone}</span>

              <p className="text-secondary-400 text-xs mb-12">
                {userLanguage === 'en' ? 'which will expire in 30 minutes.' : 'que expirará em 30 minutos.'}
              </p>
            </div>
            <div className="flex gap-2 justify-center items-center">
              <input
                type="number"
                value={values[0]}
                required
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setValues((prev) => {
                      const newArray = [...prev];
                      newArray[0] = '';
                      return newArray;
                    });
                  } else if (e.key === 'Delete') {
                    handleSetValues(0);
                  }
                }}
                onChange={(e) => {
                  handleInputChange(e.target.value, 0);
                  codeInput1.current?.focus();
                }}
                ref={codeInput0}
                className="text-center text-primary-200 py-[10px] border-2 rounded-[3px] border-primary-200 max-w-[50px] text-3xl font-semibold placeholder:text-primary-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              />
              <input
                type="number"
                value={values[1]}
                required
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setValues((prev) => {
                      const newArray = [...prev];
                      newArray[1] = '';
                      return newArray;
                    });
                  } else if (e.key === 'Delete') {
                    handleSetValues(1);
                  }
                }}
                onChange={(e) => {
                  handleInputChange(e.target.value, 1);
                  codeInput2.current?.focus();
                }}
                ref={codeInput1}
                className="text-center text-primary-200 py-[10px] border-2 rounded-[3px] border-primary-200 max-w-[50px] text-3xl font-semibold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              />
              <input
                type="number"
                value={values[2]}
                required
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setValues((prev) => {
                      const newArray = [...prev];
                      newArray[2] = '';
                      return newArray;
                    });
                  } else if (e.key === 'Delete') {
                    handleSetValues(2);
                  }
                }}
                onChange={(e) => {
                  handleInputChange(e.target.value, 2);
                  codeInput3.current?.focus();
                }}
                ref={codeInput2}
                className="text-center text-primary-200 py-[10px] border-2 rounded-[3px] border-primary-200 max-w-[50px] text-3xl font-semibold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              />
              <input
                type="number"
                value={values[3]}
                required
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    setValues((prev) => {
                      const newArray = [...prev];
                      newArray[3] = '';
                      return newArray;
                    });
                  } else if (e.key === 'Delete') {
                    handleSetValues(3);
                  }
                }}
                onChange={(e) => {
                  handleInputChange(e.target.value, 3);
                  codeInput3.current?.blur();
                }}
                ref={codeInput3}
                className="text-center text-primary-200 py-[10px] border-2 rounded-[3px] border-primary-200 max-w-[50px] text-3xl font-semibold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              />
            </div>
          </div>

          <div className="flex flex-col justify-center items-center">
            {isLoading ? (
              <img src={loading} alt="loader" className="w-10 h-10" />
            ) : (
              <Button
                disabled={isSubmitting}
                onClick={confirmAccountDeletion}
                data-test="forgot-password-code-submit"
                text={userLanguage === 'pt' ? 'Continuar' : 'Continue'}
                size="large"
                variant="primary"
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
