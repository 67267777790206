import { create } from 'zustand';
import { api } from '../services/axios';
import { showErrorMessage } from '../utils/auxFunctions';
import { toast } from '../utils/toast';

export interface Tag {
  name: string;
  uuid: string;
}

interface Pagination {
  current_page: number;
  last_page: number;
  total: number;
  last_page_url: string | null;
}

interface TagsProps {
  tags: Tag[];
  tagsPagination: Pagination;
  setTags: (tags: Tag[]) => void;
  getAllTagsForGroup: (groupUuid: string, params?: string) => Promise<void>;
  createNewTagForGroup: (groupUuid: string, name: string) => Promise<Tag>;
  getTagDetails: (groupUuid: string, tagUuid: string) => Promise<Tag>;
}

export const useTags = create<TagsProps>((set) => ({
  tags: [],
  tagsPagination: {
    current_page: 1,
    last_page: 1,
    total: 1,
    last_page_url: null,
  },

  setTags: (tags: Tag[]) => {
    set({ tags });
  },

  getAllTagsForGroup: async (groupUuid: string, params?: string) => {
    try {
      const { data } = await api.get(`/groups/${groupUuid}/tags` + (params ? params : ''));
      if (!data?.content?.data) return;
      set({
        tagsPagination: {
          current_page: data.content.data.current_page,
          last_page: data.content.data.last_page,
          total: data.content.data.total,
          last_page_url: data.content.data.last_page_url,
        },
      });
      set({ tags: data.content.data.data });
    } catch (error) {
      showErrorMessage(error);
    }
  },

  createNewTagForGroup: async (groupUuid: string, name: string) => {
    try {
      const { data } = await api.post(`/groups/${groupUuid}/tags`, { name });
      if (data?.content?.status) {
        toast({
          label: 'Success',
          message: data.content.message || 'Tag created successfully',
          type: 'success',
        });
        return data.content.data;
      } else {
        toast({
          label: 'Error',
          message: data.content.message || 'Failed to create tag',
          type: 'error',
        });
      }
    } catch (error) {
      showErrorMessage(error);
    }
  },

  getTagDetails: async (groupUuid: string, tagUuid: string) => {
    try {
      const { data } = await api.get(`/groups/${groupUuid}/tags/${tagUuid}`);
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },
}));
