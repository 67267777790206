import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AdsCreateDTO, useAds } from '@hooks/useAds';
import { useUser } from '@hooks/useUser';

import Loading from '@components/Loading';
import AdsPrimaryInformation from './PrimaryInformation';
import AdsSecondaryInformation from './SecondaryInformation';

import { retrieveImgS3 } from 'utils/auxFunctions';
import { uploadToS3 } from 'utils/awsS3';
import { toast } from 'utils/toast';

export default function EditAdsPage() {
  const { t } = useTranslation();
  const { user } = useUser();
  const { ads, setAds, updateAds, getAdsInfo } = useAds();

  const { adsUuid } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState<File | undefined>(undefined);

  const navigate = useNavigate();

  const getAdsInformation = useCallback(async () => {
    setIsLoading(true);
    if (!adsUuid) return;
    getAdsInfo(adsUuid).then((data) => {
      setAds({
        title: data.title,
        description: data.description,
        link: data.external_url,
        file: undefined,
        name: data.name,
        awsMediaUrl: data.aws_url,
        url: data.aws_url,
        thumbnail: data.aws_url,
        activeState: Boolean(data.active),
        maxClickImpression: data.max_clicks.toString(),
        maxViewImpression: data.max_views.toString(),
        startDate: data.start_date,
        expirationDate: data.end_date,
        fileType: data.aws_type,
        profile_photo: data.profile_photo,
      });
    });
    setIsLoading(false);
  }, [adsUuid, setAds, getAdsInfo]);

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = '';

      const confirmationMessage = t('are_you_sure_you_want_to_leave_all_unsaved_data_will_be_lost');
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [adsUuid]);

  useEffect(() => {
    getAdsInformation();
  }, []);

  const handleCreateAds = useCallback(async () => {
    if (!adsUuid) return;

    if (ads.title === '') {
      setIsLoading(false);
      toast({
        label: t('error'),
        message: t('missing_title'),
        type: 'error',
      });
      return;
    }

    if (!ads?.name) {
      setIsLoading(false);
      toast({
        label: t('error'),
        message: t('missing_name'),
        type: 'error',
      });
      return;
    }

    if (!ads.description) {
      setIsLoading(false);
      toast({
        label: t('error'),
        message: t('missing_description'),
        type: 'error',
      });
      return;
    }

    if (!ads.file && !ads.url) {
      setIsLoading(false);
      toast({
        label: t('error'),
        message: t('missing_file'),
        type: 'error',
      });
      return;
    }

    if (!ads.link) {
      setIsLoading(false);
      toast({
        label: t('error'),
        message: t('missing_link'),
        type: 'error',
      });
      return;
    }

    if (!user?.first_name || !user?.last_name) {
      setIsLoading(false);
      toast({
        label: t('error'),
        message: t('failed_to_obtain_user_information'),
        type: 'error',
      });
      return;
    }

    if (ads.startDate === '' || ads.expirationDate === '') {
      setIsLoading(false);
      toast({
        label: t('error'),
        message: t('missing_dates'),
        type: 'error',
      });
      return;
    }

    // if (ads.priority === '' || Number(ads.priority) < 1 || isNaN(Number(ads.priority))) {
    //   setIsLoading(false);
    //   toast({
    //     label: t('error'),
    //     message: t('priority_must_be_higher_than_0'),
    //     type: 'error',
    //   });
    //   return;
    // }

    setIsLoading(true);

    let profilePhotoS3 = null;
    if (profilePhoto !== undefined) {
      const { key: keyProfilePhoto, error: errProfilePhoto } = await uploadToS3(profilePhoto);
      if (errProfilePhoto) {
        toast({
          label: t('error'),
          message: t('failed_to_upload'),
          type: 'error',
        });
        return;
      }
      profilePhotoS3 = await retrieveImgS3(String(keyProfilePhoto));
    }

    const trimmedUrl = String(ads?.profile_photo).split('?')[0];
    const adsUrl = String(ads?.url).split('?')[0];
    const adsCreateDTO: AdsCreateDTO = {
      title: ads.title,
      description: ads.description,
      active: ads.activeState,
      external_url: ads.link ?? '',
      aws_type: (ads?.fileType as any) ?? '',
      aws_url: adsUrl.includes('https://') ? adsUrl : '',
      // priority: Number(ads.priority),
      max_clicks: Number(ads.maxClickImpression),
      max_views: Number(ads.maxViewImpression),
      start_date: ads.startDate ?? '',
      end_date: ads.expirationDate ?? '',
      name: String(ads.name),
      profile_photo: profilePhotoS3 ?? trimmedUrl,
    };
    setIsLoading(false);
    if (ads.file) {
      if (!ads.file.type.includes('video') && !ads.file.type.includes('image')) {
        setIsLoading(false);
        toast({
          label: t('error'),
          message: t('failed_to_upload'),
          type: 'error',
        });
        return;
      }

      // COVER PHOTO
      // if (ads.file?.type.includes('video')) {
      //   const file = base64ToFile(ads?.thumbnail ?? '');
      //   if (!file) {
      //     toast({
      //       label: t('error'),
      //       message: t('failed_to_upload'),
      //       type: 'error',
      //     });
      //     return;
      //   }
      //   const { key, error } = await uploadToS3(file);
      //   if (error) {
      //     toast({
      //       label: t('error'),
      //       message: t('failed_to_upload'),
      //       type: 'error',
      //     });
      //     return;
      //   }
      //   if (key) {
      //     adsCreateDTO.url_aws_cover = retrieveImgS3(key);
      //   }
      // }

      // UPLOAD FILE
      const { key, error } = await uploadToS3(ads.file);
      if (error) {
        toast({
          label: t('error'),
          message: t('failed_to_upload'),
          type: 'error',
        });
        return;
      }
      if (key) {
        adsCreateDTO.aws_url = retrieveImgS3(key);
        adsCreateDTO.aws_type = ads.file.type.includes('image') ? 'image' : 'video';
      }
    }
    const reponse = await updateAds(adsUuid, adsCreateDTO);
    if (reponse) {
      toast({
        label: t('success'),
        message: t('ads_updated'),
        type: 'success',
      });
      setIsLoading(false);
      navigate('/');
    } else {
      await getAdsInformation();
    }
    setIsLoading(false);
  }, [ads, profilePhoto]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <section className="flex justify-center w-full min-h-[calc(100vh-88px)] bg-backgray py-12 mb-10 relative">
          <div className="flex flex-col gap-8 w-[435px] mb-5">
            <h2 className="text-xl font-medium">{t('choose_a_title_for_your_ads')}</h2>
            <input
              value={ads.title}
              maxLength={100}
              onChange={(e) => setAds({ title: e.target.value })}
              placeholder={t('add_a_ads_title')}
              type="text"
              className="w-full bg-white py-4 px-5 border border-grey-300 rounded-md text-sm placeholder:text-grey-300 outline-grey-200"
            />
            <h2 className="text-xl font-medium">{t('choose_a_comapny_name_for_your_ads')}</h2>
            <input
              value={ads.name}
              maxLength={100}
              onChange={(e) => setAds({ name: e.target.value })}
              placeholder={t('add_company_name')}
              type="text"
              className="w-full bg-white py-4 px-5 border border-grey-300 rounded-md text-sm placeholder:text-grey-300 outline-grey-200"
            />
            <h2 className="text-xl font-medium">{t('advertiser_photo')}</h2>
            <div className="w-full relative bg-white py-4 px-5 border border-grey-300 rounded-md text-sm placeholder:text-grey-300 outline-grey-200">
              <input
                onChange={(e) => setProfilePhoto(e.target.files?.[0])}
                placeholder={t('advertiser_photo')}
                type="file"
                accept="image/png, image/jpg, image/jpeg, image/heic"
                className="absolute opacity-0 top-0 left-0 w-full h-full "
              />
              <div className="flex items-center gap-2">
                <img
                  src={!profilePhoto ? ads.profile_photo : URL.createObjectURL(profilePhoto)}
                  alt="Profile"
                  className="w-8 h-8 object-cover"
                />
                <span className="text-grey-300">{profilePhoto ? profilePhoto.name : ads.name}</span>
              </div>
            </div>
            <h2 className="text-xl font-medium">{t('create_a_ads')}</h2>
            <AdsPrimaryInformation />

            <AdsSecondaryInformation />
          </div>
          <footer className="fixed bottom-0 right-0 bg-white w-full flex items-center justify-center py-5 gap-5">
            <button
              onClick={handleCreateAds}
              className="bg-primary-200 rounded-md shadow-md flex items-center justify-center py-[8px] px-[20px] text-white font-semibold hover:opacity-70 disabled:bg-grey-400"
            >
              {t('update_ads')}
            </button>
          </footer>
        </section>
      )}
    </>
  );
}
