import { Button } from '@components/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function EmptyAds() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center gap-5">
      <svg xmlns="http://www.w3.org/2000/svg" width="219" height="160" viewBox="0 0 219 160" fill="none">
        <path d="M133.785 47.208H123.618V51.504H133.785V47.208Z" fill="#EBEBEB" />
        <path d="M128.113 52.626H117.946V56.922H128.113V52.626Z" fill="#EBEBEB" />
        <path d="M139.456 52.626H129.289V56.922H139.456V52.626Z" fill="#EBEBEB" />
        <path d="M175.957 87.9092H165.79V92.2052H175.957V87.9092Z" fill="#EBEBEB" />
        <path d="M175.957 6.49609H165.79V10.7921H175.957V6.49609Z" fill="#EBEBEB" />
        <path d="M181.629 11.9131H171.462V16.2091H181.629V11.9131Z" fill="#EBEBEB" />
        <path d="M165.315 32.333H97.1545V35.322H165.315V32.333Z" fill="#EBEBEB" />
        <path d="M103.965 35.323H100.975V45.209H103.965V35.323Z" fill="#EBEBEB" />
        <path d="M103.965 35.323H100.975V38.379H103.965V35.323Z" fill="#DBDBDB" />
        <path d="M161.496 35.323H158.506V45.209H161.496V35.323Z" fill="#EBEBEB" />
        <path d="M161.496 35.323H158.506V38.379H161.496V35.323Z" fill="#DBDBDB" />
        <path d="M114.674 32.3342V13.2842H109.877V32.3342H114.674Z" fill="#C7C7C7" />
        <path d="M114.674 14.3581V12.2651H109.877V14.3581H114.674Z" fill="#DBDBDB" />
        <path d="M114.674 17.818V17.041H109.877V17.818H114.674Z" fill="#DBDBDB" />
        <path d="M115.272 29.8349V27.7419H109.877V29.8349H115.272Z" fill="#DBDBDB" />
        <path d="M118.027 32.3341V10.1121H114.674V32.3341H118.027Z" fill="#DBDBDB" />
        <path d="M117.342 20.7482V12.2012H115.33V20.7482H117.342Z" fill="#EBEBEB" />
        <path d="M141.88 29.9535L126.075 14.332L123.718 16.7167L139.523 32.3382L141.88 29.9535Z" fill="#C7C7C7" />
        <path d="M137.705 26.7566L131.628 20.748L130.214 22.1786L136.291 28.1871L137.705 26.7566Z" fill="#DBDBDB" />
        <path d="M123.719 32.337V15.458H118.025V32.337H123.719Z" fill="#A6A6A6" />
        <path d="M123.719 30.5481V28.9751H118.025V30.5481H123.719Z" fill="#C7C7C7" />
        <path d="M123.719 27.942V27.332H118.025V27.942H123.719Z" fill="#C7C7C7" />
        <path d="M109.877 32.3381V16.4751H105.944V32.3381H109.877Z" fill="#A6A6A6" />
        <path d="M109.877 30.656V29.178H105.944V30.656H109.877Z" fill="#C7C7C7" />
        <path d="M109.877 21.2231V19.7451H105.944V21.2231H109.877Z" fill="#C7C7C7" />
        <path d="M109.877 22.8091V22.2361H105.944V22.8091H109.877Z" fill="#C7C7C7" />
        <path
          d="M158.149 24.942L157.72 26.074L157.459 26.764L157.03 27.9L155.355 32.336H146.298L144.619 27.9L144.19 26.764L143.929 26.074L143.5 24.942L146.842 20.076L145.11 16.865H156.548L154.808 20.076L158.149 24.942Z"
          fill="#DBDBDB"
        />
        <path d="M158.149 24.9419L157.72 26.0739H143.929L143.5 24.9419H158.149Z" fill="#A6A6A6" />
        <path d="M157.454 26.7629L157.025 27.8989H144.619L144.19 26.7629H157.454Z" fill="#A6A6A6" />
        <path d="M78.5305 0.664062H27.4585V78.9741H78.5305V0.664062Z" fill="#EBEBEB" />
        <path d="M51.8075 3.80103H30.2415V38.633H51.8075V3.80103Z" fill="white" />
        <path d="M75.7465 3.80103H54.1805V38.633H75.7465V3.80103Z" fill="white" />
        <path d="M51.8075 41.0061H30.2415V75.8381H51.8075V41.0061Z" fill="white" />
        <path d="M75.7465 41.0061H54.1805V75.8381H75.7465V41.0061Z" fill="white" />
        <path d="M39.5545 0.664062H27.4585V112.7H39.5545V0.664062Z" fill="#DBDBDB" />
        <path
          d="M37.2615 5.99097L37.3545 18.105L37.3885 30.218L37.4525 54.445L37.3885 78.672L37.3545 90.786L37.2605 102.9L37.1665 90.786L37.1315 78.673L37.0675 54.446L37.1315 30.218L37.1655 18.104L37.2615 5.99097Z"
          fill="#EBEBEB"
        />
        <path
          d="M31.7065 8.80005L31.7995 19.6161L31.8535 30.4321L31.8985 52.065L31.8545 73.6981L31.8005 84.511L31.7065 95.3271L31.6125 84.511L31.5585 73.6951L31.5145 52.062L31.5595 30.429L31.6135 19.6131L31.7065 8.80005Z"
          fill="#EBEBEB"
        />
        <path
          d="M34.4875 11.353L34.5805 24.018L34.6145 36.683L34.6785 62.013L34.6145 87.344L34.5785 100.013L34.4875 112.675L34.3875 100.013L34.3535 87.348L34.2925 62.013L34.3565 36.683L34.3875 24.018L34.4875 11.353Z"
          fill="#EBEBEB"
        />
        <path
          d="M29.6415 6.62915L29.7345 19.8852L29.7685 33.1412L29.8325 59.6532L29.7685 86.1651L29.7345 99.4212L29.6405 112.677L29.5465 99.4212L29.5125 86.1651L29.4485 59.6532L29.5125 33.1412L29.5465 19.8852L29.6415 6.62915Z"
          fill="#EBEBEB"
        />
        <path d="M66.4335 112.7H78.5295L78.5295 0.663948H66.4335L66.4335 112.7Z" fill="#DBDBDB" />
        <path
          d="M68.7275 5.99097L68.8205 18.105L68.8545 30.218L68.9185 54.445L68.8545 78.672L68.8205 90.786L68.7265 102.9L68.6325 90.786L68.5985 78.672L68.5345 54.445L68.5985 30.218L68.6325 18.104L68.7275 5.99097Z"
          fill="#EBEBEB"
        />
        <path
          d="M74.2814 8.80005L74.3745 19.6161L74.4285 30.4321L74.4734 52.065L74.4294 73.6981L74.3754 84.5141L74.2814 95.33L74.1874 84.5141L74.1324 73.6991L74.0884 52.066L74.1324 30.4331L74.1864 19.6171L74.2814 8.80005Z"
          fill="#EBEBEB"
        />
        <path
          d="M71.5045 11.353L71.5975 24.018L71.6315 36.683L71.6955 62.013L71.6315 87.344L71.5985 100.013L71.5045 112.678L71.4105 100.013L71.3765 87.348L71.3125 62.017L71.3765 36.687L71.4105 24.022L71.5045 11.353Z"
          fill="#EBEBEB"
        />
        <path
          d="M76.3475 6.62915L76.4405 19.8852L76.4745 33.1412L76.5385 59.6532L76.4745 86.1651L76.4405 99.4212L76.3465 112.677L76.2525 99.4212L76.2185 86.1651L76.1545 59.6532L76.2185 33.1412L76.2525 19.8852L76.3475 6.62915Z"
          fill="#EBEBEB"
        />
        <path d="M1.13049 112.786H217.869" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M107.396 153.019C158.598 153.019 200.104 147.852 200.104 141.477C200.104 135.103 158.598 129.935 107.396 129.935C56.1953 129.935 14.6885 135.103 14.6885 141.477C14.6885 147.852 56.1953 153.019 107.396 153.019Z"
          fill="#EBEBEB"
        />
        <path d="M154.815 120.125H63.8145L92.3875 53.436H183.387L154.815 120.125Z" fill="#263238" />
        <path d="M93.0605 54.8579H182.777L154.814 120.125H65.4375L93.0605 54.8579Z" fill="#EBEBEB" />
        <path
          d="M93.0605 54.8579C93.4247 61.1459 92.7038 67.4496 90.9295 73.4929C88.0015 83.0699 73.4945 102.247 70.6725 115.182L65.4375 120.124H154.814L182.777 54.8569L93.0605 54.8579Z"
          fill="#DBDBDB"
        />
        <path
          d="M93.0605 54.8579C92.9775 55.8249 92.0345 68.6329 88.2835 76.9069C84.1015 86.1319 74.0115 100.892 70.2735 114.421"
          stroke="#DBDBDB"
          strokeMiterlimit="10"
        />
        <path
          d="M92.1465 58.731C91.155 65.4183 89.1701 71.9201 86.2575 78.021C81.4575 88.008 73.1905 100.579 69.3425 116.443"
          stroke="#DBDBDB"
          strokeMiterlimit="10"
        />
        <path
          d="M121.451 123.825H208.841L179.261 54.8579H93.0605C93.0605 54.8579 92.7215 65.2989 98.4405 80.4069C103.828 94.6399 113.793 103.196 121.451 123.825Z"
          fill="white"
        />
        <path
          d="M120.938 123.825C113.63 105.65 102.979 96.1029 97.5015 82.9669C93.2515 72.7749 93.0605 54.8579 93.0605 54.8579"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M93.9165 56.8489C93.9165 56.8489 95.9865 73.5779 103.743 87.4909C111.499 101.404 115.314 106.61 119.342 116.128"
          stroke="#EBEBEB"
          strokeMiterlimit="10"
        />
        <path d="M123.251 125.247H214.251L183.451 53.4351H92.4505L123.251 125.247Z" fill="#EDB561" />
        <path d="M181.986 125.247H202.007L171.206 53.4351H151.185L181.986 125.247Z" fill="#EDB561" />
        <path d="M132.714 125.247H139.964L109.164 53.4351H101.914L132.714 125.247Z" fill="#EDB561" />
        <path d="M93.0605 54.858H184.061L185.027 52.582H91.4415L93.0605 54.858Z" fill="#EDB561" />
        <path d="M52.9125 148.894L18.1055 139.729L1.91248 148.409L35.9595 159.755L52.9125 148.894Z" fill="#8DA8DF" />
        <path
          d="M50.7534 148.737L35.2314 158.715L3.32245 148.107L18.9784 139.792C18.9784 139.792 28.5275 141.049 28.9725 141.192C29.4174 141.335 33.6284 143.079 33.6284 143.079C33.6284 143.079 39.5364 144.107 39.9375 144.241C40.3385 144.375 50.7534 148.737 50.7534 148.737Z"
          fill="white"
        />
        <path
          d="M33.6285 143.074C32.4058 143.954 31.1762 144.822 29.9395 145.68L26.2165 148.237L22.4645 150.75C21.2098 151.583 19.9475 152.404 18.6775 153.212C19.8995 152.333 21.1292 151.464 22.3665 150.606L26.0895 148.05L29.8425 145.538C31.0965 144.705 32.3585 143.884 33.6285 143.074Z"
          fill="#DBDBDB"
        />
        <path
          d="M47.3355 148.645C46.2021 148.201 45.0728 147.746 43.9475 147.281L40.5775 145.881L39.7365 145.528C39.5965 145.472 39.4535 145.411 39.3195 145.365C39.1855 145.319 39.0255 145.294 38.8785 145.258L37.1035 144.841C35.9215 144.559 34.7415 144.263 33.5635 143.953C34.7601 144.18 35.9548 144.42 37.1475 144.672L38.9345 145.059C39.0835 145.094 39.2345 145.119 39.3815 145.159C39.5285 145.199 39.6735 145.268 39.8175 145.324L40.6635 145.668C41.7835 146.148 42.9025 146.625 44.0145 147.122C45.1265 147.619 46.2345 148.124 47.3355 148.645Z"
          fill="#DBDBDB"
        />
        <path
          d="M46.2545 149.283C45.1211 148.839 43.9918 148.384 42.8665 147.919L39.4965 146.519L38.6555 146.166C38.5155 146.11 38.3725 146.049 38.2395 146.003C38.1065 145.957 37.9445 145.932 37.7985 145.896L36.0235 145.479C34.8415 145.197 33.6615 144.901 32.4835 144.591C33.6801 144.818 34.8748 145.058 36.0675 145.31L37.8545 145.697C38.0035 145.732 38.1545 145.757 38.3015 145.797C38.4485 145.837 38.5935 145.906 38.7375 145.962L39.5835 146.307C40.7035 146.786 41.8225 147.264 42.9345 147.76C44.0465 148.256 45.1535 148.762 46.2545 149.283Z"
          fill="#DBDBDB"
        />
        <path
          d="M45.1725 149.921C44.0645 149.487 42.9605 149.042 41.8605 148.588L38.5665 147.216L37.7455 146.871C37.48 146.748 37.2047 146.648 36.9225 146.571L35.1895 146.156C34.0322 145.88 32.8772 145.594 31.7245 145.296C32.8952 145.51 34.0618 145.74 35.2245 145.984C35.8085 146.102 36.3875 146.235 36.9695 146.37C37.2626 146.447 37.5489 146.547 37.8255 146.67L38.6515 147.009C39.7445 147.479 40.8395 147.945 41.9255 148.431C43.0115 148.917 44.0945 149.411 45.1725 149.921Z"
          fill="#DBDBDB"
        />
        <path
          d="M44.0915 150.557C42.9995 150.129 41.9115 149.691 40.8275 149.244L37.5815 147.891C37.0425 147.674 36.4985 147.416 35.9645 147.238L34.2585 146.827C33.1185 146.553 31.9805 146.27 30.8445 145.976C31.9991 146.186 33.1501 146.411 34.2975 146.652C34.8725 146.771 35.4425 146.899 36.0175 147.036C36.5975 147.224 37.1175 147.461 37.6675 147.684C38.7445 148.148 39.8245 148.606 40.8935 149.084C41.9625 149.562 43.0305 150.056 44.0915 150.557Z"
          fill="#DBDBDB"
        />
        <path
          d="M43.0095 151.196C41.9215 150.769 40.8371 150.333 39.7565 149.887L36.5215 148.539C35.9845 148.321 35.4415 148.069 34.9115 147.885C34.3535 147.739 33.7785 147.615 33.2115 147.474C32.0781 147.202 30.9448 146.919 29.8115 146.625C30.9621 146.833 32.1095 147.057 33.2535 147.298C33.8265 147.418 34.3935 147.544 34.9685 147.682C35.5435 147.877 36.0685 148.108 36.6105 148.332C37.6835 148.794 38.7595 149.251 39.8255 149.732C40.8915 150.213 41.9525 150.696 43.0095 151.196Z"
          fill="#DBDBDB"
        />
        <path
          d="M41.9285 151.832C40.8438 151.406 39.7631 150.973 38.6865 150.532L35.4615 149.188C34.9265 148.97 34.3845 148.722 33.8615 148.532C33.3065 148.386 32.7325 148.261 32.1685 148.122C31.0351 147.85 29.9048 147.567 28.7775 147.275C29.9241 147.482 31.0678 147.705 32.2085 147.945C32.7795 148.065 33.3445 148.189 33.9185 148.329C34.4885 148.529 35.0115 148.756 35.5525 148.981C36.6225 149.443 37.6955 149.897 38.7575 150.375C39.8195 150.853 40.8755 151.333 41.9285 151.832Z"
          fill="#DBDBDB"
        />
        <path
          d="M40.8465 152.47C39.7755 152.049 38.7075 151.623 37.6465 151.183L34.4645 149.857C33.9345 149.642 33.4035 149.402 32.8785 149.202C32.3465 149.041 31.7695 148.927 31.2165 148.788C30.0995 148.517 28.9835 148.241 27.8715 147.949C29.0048 148.149 30.1335 148.369 31.2575 148.608C31.8225 148.733 32.3765 148.839 32.9465 149.002C33.4965 149.208 34.0205 149.426 34.5515 149.652C35.6075 150.108 36.6665 150.552 37.7135 151.029C38.7605 151.506 39.8065 151.978 40.8465 152.47Z"
          fill="#DBDBDB"
        />
        <path
          d="M39.7655 153.107C38.6945 152.686 37.6265 152.26 36.5655 151.82L33.3825 150.497C32.8525 150.282 32.3215 150.042 31.7965 149.842C31.2635 149.681 30.6875 149.567 30.1345 149.428C29.0175 149.157 27.9015 148.881 26.7885 148.589C27.9218 148.789 29.0505 149.009 30.1745 149.248C30.7395 149.373 31.2935 149.479 31.8635 149.641C32.4145 149.847 32.9375 150.065 33.4695 150.291C34.5255 150.747 35.5835 151.191 36.6315 151.668C37.6795 152.145 38.7255 152.615 39.7655 153.107Z"
          fill="#DBDBDB"
        />
        <path
          d="M38.8665 153.839C37.7815 153.409 36.6985 152.974 35.6215 152.525L32.3945 151.173L30.7835 150.5C30.2545 150.324 29.6625 150.216 29.1025 150.073C27.9685 149.798 26.8355 149.518 25.7025 149.222C26.8518 149.428 27.9975 149.651 29.1395 149.891C29.7125 150.023 30.2745 150.119 30.8545 150.302C31.4035 150.516 31.9415 150.736 32.4775 150.968C33.5475 151.433 34.6215 151.889 35.6845 152.368C36.7475 152.847 37.8125 153.339 38.8665 153.839Z"
          fill="#DBDBDB"
        />
        <path
          d="M37.7835 154.5C36.7015 154.068 35.6224 153.632 34.5494 153.181L31.3324 151.824L29.7265 151.148C29.2025 150.966 28.6094 150.862 28.0514 150.718C26.9204 150.443 25.7905 150.164 24.6635 149.868C25.8101 150.072 26.9531 150.295 28.0924 150.536C28.6634 150.67 29.2244 150.76 29.8034 150.95C30.3474 151.166 30.8855 151.385 31.4195 151.619C32.4865 152.086 33.5574 152.543 34.6194 153.027C35.6814 153.511 36.7325 153.998 37.7835 154.5Z"
          fill="#DBDBDB"
        />
        <path
          d="M36.6965 155.213C35.6495 154.784 34.6024 154.352 33.5634 153.905C32.5245 153.458 31.4875 153.005 30.4465 152.56L28.8905 151.888C28.6384 151.771 28.3741 151.683 28.1025 151.625L27.2785 151.425C26.1785 151.156 25.0785 150.884 23.9865 150.592C25.1018 150.788 26.2135 151.003 27.3215 151.237L28.1504 151.423C28.2884 151.457 28.4265 151.484 28.5685 151.529C28.7105 151.574 28.8404 151.629 28.9684 151.686C29.4938 151.902 30.0145 152.125 30.5305 152.355C31.5635 152.818 32.6015 153.27 33.6305 153.755C34.6595 154.24 35.6785 154.717 36.6965 155.213Z"
          fill="#DBDBDB"
        />
        <path
          d="M35.6185 155.803C34.5525 155.415 33.4885 155.024 32.4305 154.617C31.3725 154.21 30.3165 153.796 29.2585 153.392C28.7305 153.192 28.1985 152.972 27.6775 152.792L26.0465 152.312C24.9625 151.981 23.8795 151.642 22.8035 151.283C23.9035 151.549 25.0035 151.835 26.1035 152.129L27.7415 152.586C28.2955 152.768 28.8135 152.978 29.3415 153.186C30.3935 153.609 31.4495 154.021 32.4935 154.464C33.5375 154.907 34.5795 155.349 35.6185 155.803Z"
          fill="#DBDBDB"
        />
        <path
          d="M19.9865 150.638C18.9725 150.285 17.9715 149.901 16.9765 149.506L16.2275 149.22C15.9818 149.123 15.7295 149.042 15.4725 148.98L13.9215 148.555L10.8215 147.686C9.78846 147.397 8.76246 147.086 7.73346 146.786C8.77446 147.044 9.81846 147.286 10.8555 147.559L13.9685 148.359L15.5225 148.771C15.7869 148.834 16.0474 148.912 16.3025 149.006C16.5555 149.099 16.8025 149.206 17.0515 149.306C18.0481 149.714 19.0265 150.158 19.9865 150.638Z"
          fill="#DBDBDB"
        />
        <path
          d="M21.0365 149.816C20.0365 149.524 19.0475 149.21 18.0695 148.872C17.5775 148.712 17.0865 148.532 16.5985 148.39L15.0985 147.967L12.1075 147.103C11.1075 146.818 10.1195 146.503 9.12549 146.203C10.1305 146.459 11.1405 146.703 12.1425 146.97L15.1505 147.77C16.1609 148.018 17.1576 148.318 18.1365 148.67C19.1165 149.02 20.0815 149.405 21.0365 149.816Z"
          fill="#DBDBDB"
        />
        <path
          d="M22.0485 149.085C21.0585 148.796 20.0745 148.485 19.1005 148.155L17.6395 147.669L16.1445 147.291L13.1525 146.522C12.1525 146.267 11.1625 145.986 10.1675 145.722C11.1735 145.946 12.1825 146.156 13.1855 146.393L16.1955 147.099L17.6955 147.465C18.1955 147.612 18.6755 147.794 19.1635 147.965C20.1365 148.303 21.0985 148.684 22.0485 149.085Z"
          fill="#DBDBDB"
        />
        <path
          d="M22.9145 148.544C21.9345 148.2 20.9835 147.787 20.0255 147.405C19.794 147.302 19.5537 147.219 19.3075 147.159L18.5505 146.988L17.0345 146.652C15.0132 146.2 12.9948 145.734 10.9795 145.252C13.0162 145.634 15.0495 146.036 17.0795 146.458L18.5995 146.782L19.3595 146.947C19.6182 147.004 19.8705 147.087 20.1125 147.194C21.0698 147.594 22.0051 148.045 22.9145 148.544Z"
          fill="#DBDBDB"
        />
        <path
          d="M23.8635 147.885C22.8835 147.54 21.9325 147.127 20.9745 146.746C20.743 146.643 20.5027 146.561 20.2565 146.501L19.4995 146.329L17.9835 145.997C15.9621 145.545 13.9438 145.079 11.9285 144.597C13.9651 144.979 15.9985 145.381 18.0285 145.803L19.5485 146.127L20.3085 146.293C20.5671 146.35 20.8193 146.433 21.0615 146.54C22.0186 146.939 22.9539 147.388 23.8635 147.885Z"
          fill="#DBDBDB"
        />
        <path
          d="M24.8115 147.225C23.8315 146.881 22.8805 146.467 21.9225 146.086C21.6909 145.983 21.4507 145.901 21.2045 145.84L20.4475 145.669L18.9315 145.334C16.9102 144.882 14.8915 144.415 12.8755 143.934C14.9122 144.316 16.9455 144.718 18.9755 145.14L20.4955 145.464L21.2555 145.629C21.5142 145.686 21.7664 145.769 22.0085 145.876C22.9662 146.276 23.9019 146.726 24.8115 147.225Z"
          fill="#DBDBDB"
        />
        <path
          d="M25.7605 146.565C24.7805 146.22 23.8295 145.807 22.8715 145.426C22.6399 145.324 22.3996 145.242 22.1535 145.181L21.3965 145.01L19.8795 144.674C17.8588 144.222 15.8405 143.756 13.8245 143.274C15.8611 143.656 17.8945 144.058 19.9245 144.48L21.4445 144.803L22.2045 144.969C22.4631 145.026 22.7153 145.109 22.9575 145.216C23.9151 145.616 24.8508 146.066 25.7605 146.565Z"
          fill="#DBDBDB"
        />
        <path
          d="M26.9845 146.035C26.0045 145.691 25.0545 145.277 24.0955 144.896C23.8642 144.794 23.6243 144.712 23.3785 144.651L22.6215 144.48L21.1055 144.144C19.0848 143.692 17.0665 143.226 15.0505 142.744C17.0871 143.126 19.1205 143.528 21.1505 143.95L22.6695 144.273L23.4295 144.439C23.6881 144.496 23.9404 144.579 24.1825 144.686C25.1398 145.086 26.0752 145.536 26.9845 146.035Z"
          fill="#DBDBDB"
        />
        <path
          d="M28.1754 145.511C27.1954 145.167 26.2444 144.754 25.2864 144.372C25.0549 144.269 24.8146 144.187 24.5684 144.126L23.8114 143.955L22.2954 143.62C20.2741 143.168 18.2554 142.701 16.2394 142.22C18.2761 142.602 20.3094 143.004 22.3394 143.426L23.8594 143.75L24.6194 143.915C24.8781 143.972 25.1304 144.055 25.3724 144.162C26.3301 144.562 27.2657 145.012 28.1754 145.511Z"
          fill="#DBDBDB"
        />
        <path
          d="M29.1245 144.851C28.1465 144.507 27.1975 144.094 26.2395 143.714C26.0092 143.611 25.7698 143.53 25.5245 143.471L24.7675 143.312L23.2485 143.003C21.2231 142.588 19.1998 142.16 17.1785 141.719C19.2178 142.063 21.2535 142.427 23.2855 142.811L24.8085 143.111L25.5705 143.264C25.8295 143.319 26.0819 143.402 26.3235 143.51C27.2803 143.907 28.2153 144.355 29.1245 144.851Z"
          fill="#DBDBDB"
        />
        <path
          d="M30.0744 144.191C29.0974 143.847 28.1494 143.436 27.1944 143.056C26.9651 142.953 26.7263 142.872 26.4814 142.814L25.7234 142.668L24.2004 142.389C22.1704 142.015 20.1418 141.63 18.1144 141.234C20.1584 141.533 22.1984 141.854 24.2344 142.197L25.7604 142.463L26.5234 142.603C26.783 142.657 27.0359 142.739 27.2774 142.848C28.2329 143.246 29.1665 143.695 30.0744 144.191Z"
          fill="#DBDBDB"
        />
        <path
          d="M31.0224 143.532C30.0474 143.189 29.1014 142.779 28.1474 142.399C27.9199 142.296 27.6823 142.215 27.4384 142.16L26.6784 142.036L25.1504 141.807C23.1134 141.501 21.0764 141.191 19.0414 140.862C21.0901 141.089 23.1358 141.34 25.1784 141.614L26.7094 141.83L27.4764 141.949C27.7368 142 27.9902 142.082 28.2314 142.192C29.1848 142.589 30.1164 143.037 31.0224 143.532Z"
          fill="#DBDBDB"
        />
        <path
          d="M34.5165 156.721C33.4285 156.36 32.3425 155.994 31.2615 155.612L28.0245 154.458C27.4855 154.271 26.9455 154.058 26.4125 153.881L24.7735 153.362C23.6825 153.007 22.5955 152.643 21.5145 152.262C22.6235 152.555 23.7265 152.862 24.8255 153.184L26.4715 153.677C27.0255 153.861 27.5605 154.049 28.0995 154.249C29.1735 154.649 30.2515 155.038 31.3195 155.454C32.3875 155.87 33.4555 156.289 34.5165 156.721Z"
          fill="#DBDBDB"
        />
        <path
          d="M19.2055 151.442C18.1055 151.103 17.0055 150.759 15.9165 150.4L12.6435 149.313L11.0145 148.769L9.35947 148.284C8.25947 147.952 7.15947 147.61 6.06647 147.25C7.18547 147.52 8.29847 147.808 9.40747 148.105L11.0695 148.565C11.6295 148.737 12.1695 148.914 12.7145 149.103C13.8005 149.481 14.8895 149.848 15.9695 150.242C17.0495 150.636 18.1315 151.031 19.2055 151.442Z"
          fill="#DBDBDB"
        />
        <path
          d="M187.972 88.921C187.038 88.249 157.23 79 155.872 79.366C155.724 79.408 155.41 80.748 154.064 84.075C153.999 84.233 139.816 101.427 139.816 101.427L159.227 111.672L170.305 96.0531C170.305 96.0531 184.162 93.425 185.613 92.866C187.064 92.307 188.906 89.592 187.972 88.921Z"
          fill="#FFC3BD"
        />
        <path
          d="M187.972 88.921C187.038 88.249 157.23 79 155.872 79.366C155.724 79.408 155.41 80.748 154.064 84.075C153.999 84.233 152.058 86.4851 151.106 87.7121C152.341 88.7731 164.251 97.1811 167.523 99.9751L170.306 96.0521C170.306 96.0521 184.163 93.424 185.614 92.865C187.065 92.306 188.906 89.592 187.972 88.921Z"
          fill="#282A74"
        />
        <path
          d="M161.55 82.2632C161.399 82.6016 161.206 82.9202 160.977 83.2122C160.526 83.8015 159.941 84.2755 159.27 84.5952C158.59 84.9123 157.839 85.05 157.09 84.9952C156.349 84.9217 155.631 84.6927 154.984 84.3232C154.948 84.3042 154.984 84.2482 155.018 84.2682C155.663 84.5802 156.371 84.7361 157.087 84.7232C157.803 84.7104 158.506 84.5291 159.138 84.1942C159.741 83.8793 160.263 83.4313 160.666 82.8842C160.862 82.6168 161.03 82.3299 161.166 82.0281C161.234 81.8777 161.293 81.7235 161.342 81.5662C161.393 81.4062 161.406 81.2722 161.56 81.1822C161.597 81.1629 161.64 81.1586 161.68 81.1702C161.72 81.1817 161.754 81.2082 161.774 81.2442C161.939 81.4992 161.66 82.0152 161.55 82.2632Z"
          fill="white"
        />
        <path
          d="M181.697 93.7621C181.042 93.5162 180.479 93.0732 180.086 92.4941C179.649 91.908 179.287 91.2698 179.007 90.5941C178.727 89.9313 178.53 89.2358 178.422 88.5241C178.371 88.1775 178.339 87.8283 178.327 87.4781C178.295 87.1217 178.316 86.7623 178.389 86.4121C178.393 86.399 178.401 86.3875 178.412 86.3793C178.423 86.3711 178.436 86.3667 178.45 86.3667C178.464 86.3667 178.477 86.3711 178.488 86.3793C178.499 86.3875 178.507 86.399 178.51 86.4121C178.556 86.724 178.585 87.0381 178.596 87.3531C178.634 87.702 178.689 88.0488 178.761 88.3921C178.901 89.0607 179.102 89.7151 179.361 90.3471C179.834 91.6252 180.69 92.7261 181.812 93.4991C181.841 93.5183 181.861 93.5467 181.871 93.5794C181.88 93.6121 181.878 93.6472 181.864 93.6784C181.851 93.7096 181.826 93.7351 181.796 93.7504C181.765 93.7657 181.731 93.7698 181.697 93.7621Z"
          fill="white"
        />
        <path
          d="M167.534 96.3641C163.91 93.7481 160.303 91.1071 156.653 88.5281C155.619 87.7971 154.591 87.054 153.545 86.34C153.51 86.316 153.55 86.268 153.585 86.29C157.422 88.596 161.131 91.1077 164.696 93.814C165.701 94.59 166.678 95.403 167.65 96.22C167.67 96.2354 167.682 96.2578 167.684 96.2821C167.687 96.3065 167.68 96.331 167.664 96.35C167.649 96.3691 167.627 96.3813 167.602 96.384C167.578 96.3866 167.554 96.3794 167.534 96.3641Z"
          fill="white"
        />
        <path
          d="M153.883 86.9251C153.871 86.9525 153.852 86.977 153.829 86.997C153.803 87.017 153.774 87.0351 153.747 87.0541L153.591 87.154C153.48 87.228 153.368 87.298 153.252 87.364C153.027 87.492 152.797 87.6091 152.564 87.7221C152.531 87.7381 152.501 87.686 152.533 87.667C152.751 87.532 152.965 87.3901 153.176 87.2441C153.276 87.1731 153.382 87.1031 153.486 87.0331L153.65 86.92C153.698 86.8785 153.756 86.8491 153.817 86.834C153.829 86.8322 153.841 86.8336 153.851 86.838C153.862 86.8425 153.872 86.8498 153.878 86.8593C153.885 86.8687 153.889 86.8798 153.89 86.8914C153.891 86.903 153.889 86.9147 153.883 86.9251Z"
          fill="white"
        />
        <path
          d="M155.575 88.1721C155.563 88.1995 155.544 88.224 155.521 88.2441C155.495 88.2641 155.467 88.2821 155.439 88.3011L155.283 88.4011C155.172 88.4751 155.06 88.5451 154.944 88.6111C154.719 88.7391 154.489 88.8561 154.256 88.9691C154.224 88.9851 154.193 88.9331 154.225 88.9141C154.443 88.7791 154.657 88.6361 154.868 88.4911C154.968 88.4201 155.075 88.3501 155.178 88.2801L155.341 88.1671C155.389 88.1255 155.447 88.0961 155.508 88.0811C155.52 88.079 155.532 88.0802 155.543 88.0846C155.554 88.0889 155.563 88.0963 155.57 88.1057C155.577 88.1152 155.581 88.1264 155.582 88.1382C155.583 88.1499 155.581 88.1616 155.575 88.1721Z"
          fill="white"
        />
        <path
          d="M157.265 89.419C157.253 89.4464 157.234 89.4709 157.211 89.491C157.185 89.511 157.157 89.529 157.129 89.548L156.973 89.648C156.862 89.722 156.75 89.792 156.634 89.858C156.409 89.986 156.179 90.103 155.946 90.216C155.913 90.232 155.883 90.18 155.915 90.161C156.133 90.026 156.347 89.883 156.557 89.737L156.867 89.526L157.031 89.413C157.079 89.3715 157.137 89.342 157.198 89.327C157.21 89.3248 157.222 89.326 157.233 89.3304C157.244 89.3348 157.254 89.3422 157.261 89.3519C157.268 89.3615 157.272 89.3729 157.273 89.3848C157.274 89.3967 157.271 89.4086 157.265 89.419Z"
          fill="white"
        />
        <path
          d="M158.955 90.6652C158.943 90.6926 158.924 90.7171 158.901 90.7372C158.875 90.7572 158.847 90.7752 158.819 90.7942L158.663 90.8942C158.552 90.9682 158.441 91.0382 158.325 91.1042C158.099 91.2322 157.869 91.3492 157.637 91.4622C157.604 91.4782 157.574 91.4262 157.606 91.4062C157.824 91.2722 158.038 91.1292 158.249 90.9832C158.349 90.9122 158.456 90.8432 158.559 90.7722L158.722 90.6592C158.771 90.6179 158.828 90.5884 158.889 90.5732C158.901 90.5712 158.913 90.5725 158.924 90.577C158.935 90.5815 158.944 90.589 158.951 90.5985C158.958 90.6081 158.962 90.6195 158.963 90.6312C158.964 90.643 158.961 90.6548 158.955 90.6652Z"
          fill="white"
        />
        <path
          d="M160.647 91.9121C160.634 91.9395 160.616 91.964 160.593 91.9842C160.567 92.0042 160.539 92.0221 160.511 92.0411L160.355 92.1411C160.244 92.2151 160.132 92.2851 160.016 92.3511C159.791 92.4791 159.561 92.5961 159.327 92.7091C159.295 92.7251 159.265 92.6731 159.296 92.6531C159.515 92.5191 159.728 92.3762 159.939 92.2302C160.039 92.1592 160.146 92.0902 160.249 92.0192L160.412 91.9062C160.461 91.865 160.518 91.8356 160.579 91.8202C160.591 91.8177 160.603 91.8187 160.615 91.823C160.626 91.8273 160.636 91.8347 160.643 91.8444C160.65 91.8541 160.654 91.8656 160.655 91.8776C160.656 91.8896 160.653 91.9016 160.647 91.9121Z"
          fill="white"
        />
        <path
          d="M162.337 93.1593C162.324 93.1866 162.306 93.2111 162.283 93.2312C162.257 93.2512 162.228 93.2693 162.201 93.2883L162.045 93.3883C161.934 93.4613 161.822 93.5323 161.706 93.5983C161.481 93.7263 161.251 93.8433 161.018 93.9563C160.985 93.9713 160.955 93.9203 160.987 93.9003C161.205 93.7663 161.419 93.6233 161.629 93.4773C161.729 93.4063 161.836 93.3373 161.939 93.2663L162.103 93.1532C162.152 93.112 162.209 93.0826 162.27 93.0672C162.282 93.065 162.294 93.0662 162.305 93.0706C162.316 93.075 162.326 93.0825 162.333 93.0921C162.34 93.1017 162.344 93.1131 162.345 93.125C162.346 93.1369 162.343 93.1488 162.337 93.1593Z"
          fill="white"
        />
        <path
          d="M164.028 94.4043C164.015 94.4316 163.997 94.4561 163.974 94.4762C163.948 94.4962 163.92 94.5143 163.892 94.5323L163.736 94.6323C163.625 94.7063 163.513 94.7763 163.397 94.8423C163.172 94.9703 162.942 95.0873 162.708 95.2003C162.676 95.2153 162.645 95.1643 162.677 95.1443C162.895 95.0103 163.109 94.8673 163.32 94.7213C163.42 94.6503 163.527 94.5813 163.63 94.5103L163.793 94.3973C163.842 94.356 163.9 94.3265 163.961 94.3113C163.973 94.3089 163.986 94.31 163.997 94.3144C164.008 94.3189 164.018 94.3264 164.025 94.3362C164.032 94.346 164.036 94.3576 164.037 94.3697C164.037 94.3818 164.034 94.3938 164.028 94.4043Z"
          fill="white"
        />
        <path
          d="M165.719 95.6512C165.707 95.679 165.688 95.7039 165.665 95.7242C165.639 95.7442 165.611 95.7622 165.583 95.7802L165.427 95.8851C165.316 95.9581 165.204 96.0292 165.088 96.0942C164.863 96.2222 164.633 96.3392 164.4 96.4522C164.367 96.4672 164.337 96.4162 164.369 96.3962C164.587 96.2622 164.801 96.1192 165.011 95.9732C165.111 95.9022 165.218 95.8332 165.321 95.7622L165.484 95.6491C165.532 95.6076 165.59 95.5782 165.651 95.5631C165.663 95.5612 165.674 95.5624 165.685 95.5666C165.696 95.5707 165.705 95.5777 165.712 95.5867C165.719 95.5958 165.723 95.6066 165.725 95.6179C165.726 95.6292 165.724 95.6407 165.719 95.6512Z"
          fill="white"
        />
        <path
          d="M167.409 96.9032C167.397 96.9305 167.379 96.955 167.356 96.9752C167.33 96.9952 167.301 97.0133 167.274 97.0313L167.118 97.1312C167.007 97.2052 166.895 97.2753 166.779 97.3413C166.554 97.4693 166.324 97.5863 166.091 97.6993C166.058 97.7153 166.028 97.6632 166.06 97.6442C166.278 97.5102 166.492 97.3672 166.703 97.2212C166.803 97.1502 166.91 97.0812 167.013 97.0102L167.177 96.8973C167.226 96.8557 167.283 96.8263 167.344 96.8113C167.356 96.8095 167.368 96.811 167.379 96.8156C167.389 96.8201 167.399 96.8276 167.405 96.8372C167.412 96.8467 167.416 96.858 167.417 96.8696C167.418 96.8813 167.415 96.8929 167.409 96.9032Z"
          fill="white"
        />
        <path
          d="M164.943 104.051L147.821 90.5771C147.821 90.5771 130.242 113.043 129.943 113.195C122.117 113.362 114.317 114.153 106.616 115.56C104.626 115.96 94.0225 110.78 82.1645 120.76L83.9485 139.336C102.597 142.004 128.613 137.989 139.7 134.36C150.169 130.931 164.943 104.051 164.943 104.051Z"
          fill="#A0BDFF"
        />
        <path
          d="M162.546 105.809C158.695 102.626 154.808 99.4921 150.761 96.5601C149.609 95.7251 148.422 94.9391 147.289 94.0791C147.251 94.0501 147.211 94.1121 147.248 94.1411C148.236 94.9111 149.171 95.7411 150.141 96.5351C151.111 97.3291 152.08 98.1131 153.06 98.8891C155.015 100.436 157.034 101.898 159.043 103.374C160.189 104.217 161.343 105.045 162.504 105.874C162.545 105.9 162.582 105.839 162.546 105.809Z"
          fill="#455A64"
        />
        <path
          d="M149.404 98.0691C146.322 102.169 143.225 106.255 140.159 110.369C138.625 112.425 137.088 114.478 135.548 116.529C134.979 117.479 134.102 118.206 133.064 118.588C132.502 118.726 131.931 118.82 131.355 118.871L129.449 119.106C124.388 119.73 119.328 120.363 114.265 120.975C113.003 121.152 111.731 121.246 110.457 121.256C109.184 121.201 107.918 121.046 106.671 120.791C104.404 120.404 102.1 120.281 99.8045 120.427C95.2545 120.777 90.8118 121.985 86.7105 123.986C86.1895 124.233 85.6725 124.486 85.1595 124.751C85.1165 124.773 85.1595 124.835 85.1985 124.815C89.427 122.746 93.9883 121.44 98.6715 120.96C100.977 120.733 103.3 120.779 105.595 121.097C106.822 121.275 108.034 121.548 109.269 121.665C110.535 121.762 111.808 121.732 113.069 121.576C115.612 121.307 118.149 120.957 120.688 120.644L128.312 119.704C129.576 119.548 130.847 119.417 132.107 119.231C133.136 119.146 134.107 118.716 134.863 118.011C135.628 117.147 136.33 116.229 136.963 115.264C137.737 114.206 138.514 113.151 139.296 112.099C142.338 107.979 145.355 103.841 148.382 99.7101L149.519 98.1591C149.564 98.0881 149.453 98.0051 149.404 98.0691Z"
          fill="#455A64"
        />
        <path
          d="M139.559 56.4801C138.603 57.1191 119.496 81.7971 119.359 83.1951C119.346 83.3491 120.492 84.1121 123.136 86.5391C123.378 87.3583 123.505 88.2071 123.513 89.0611C123.966 94.4831 124.339 106.736 124.339 106.736L144.354 101.813L140.933 80.9511L140.045 75.5251C140.045 75.5251 142.44 61.6251 142.425 60.0711C142.41 58.5171 140.514 55.8411 139.559 56.4801Z"
          fill="#FFC3BD"
        />
        <path
          d="M139.559 56.4802C138.603 57.1192 119.496 81.7972 119.359 83.1952C119.346 83.3492 120.492 84.1122 123.136 86.5392C123.378 87.3584 123.505 88.2071 123.513 89.0612C126.788 87.4812 136.848 82.8332 140.933 80.9492L140.045 75.5232C140.045 75.5232 142.44 61.6232 142.425 60.0692C142.41 58.5152 140.514 55.8412 139.559 56.4802Z"
          fill="#282A74"
        />
        <path
          d="M124.066 78.8952C124.331 79.1561 124.562 79.4482 124.755 79.7652C125.15 80.3947 125.39 81.1089 125.455 81.8492C125.512 82.5978 125.377 83.3486 125.062 84.0302C124.734 84.6988 124.268 85.2907 123.695 85.7672C123.664 85.7942 123.624 85.7432 123.656 85.7162C124.175 85.222 124.569 84.6128 124.808 83.9378C125.046 83.2628 125.123 82.5412 125.03 81.8312C124.947 81.1567 124.71 80.5103 124.338 79.9412C124.156 79.6646 123.946 79.4076 123.711 79.1742C123.594 79.0579 123.47 78.9487 123.339 78.8472C123.207 78.7472 123.086 78.6852 123.056 78.5082C123.051 78.4669 123.062 78.4253 123.087 78.3919C123.112 78.3585 123.148 78.3361 123.189 78.3292C123.486 78.2632 123.873 78.7052 124.066 78.8952Z"
          fill="white"
        />
        <path
          d="M141.894 64.0542C141.435 64.5818 140.822 64.9539 140.142 65.1192C139.439 65.3231 138.713 65.4387 137.981 65.4632C137.262 65.494 136.542 65.4345 135.837 65.2862C135.495 65.2127 135.157 65.1199 134.824 65.0082C134.479 64.914 134.15 64.7683 133.847 64.5762C133.836 64.568 133.829 64.5563 133.825 64.5431C133.821 64.5299 133.822 64.5159 133.827 64.5031C133.832 64.4903 133.841 64.4795 133.852 64.4723C133.864 64.4651 133.878 64.4619 133.891 64.4632C134.2 64.5301 134.504 64.6132 134.803 64.7122C135.143 64.7993 135.487 64.8691 135.834 64.9212C136.51 65.0247 137.193 65.0659 137.875 65.0442C139.239 65.0504 140.57 64.6344 141.687 63.8532C141.817 63.7582 141.997 63.9222 141.894 64.0542Z"
          fill="white"
        />
        <path
          d="M139.61 78.8792C135.586 80.8242 131.548 82.7412 127.543 84.7272C126.408 85.2892 125.266 85.8402 124.143 86.4212C124.106 86.4412 124.083 86.3822 124.118 86.3622C127.971 84.0826 131.943 82.013 136.018 80.1622C137.178 79.6462 138.358 79.1732 139.539 78.7072C139.551 78.7026 139.563 78.7002 139.575 78.7002C139.587 78.7002 139.599 78.7026 139.611 78.7073C139.622 78.712 139.632 78.7189 139.641 78.7275C139.649 78.7362 139.656 78.7464 139.661 78.7577C139.666 78.769 139.668 78.7811 139.668 78.7933C139.668 78.8056 139.666 78.8176 139.661 78.8289C139.656 78.8402 139.649 78.8505 139.641 78.8591C139.632 78.8677 139.622 78.8745 139.61 78.8792Z"
          fill="white"
        />
        <path
          d="M124.812 86.3981C124.831 86.4226 124.844 86.4505 124.85 86.4801C124.855 86.5121 124.857 86.5461 124.861 86.5801C124.867 86.6431 124.874 86.7051 124.879 86.7671C124.891 86.9001 124.9 87.0321 124.903 87.1671C124.903 87.4261 124.903 87.6841 124.89 87.9431C124.89 87.9791 124.828 87.9811 124.826 87.9431C124.811 87.6871 124.787 87.4311 124.759 87.1761C124.745 87.0521 124.734 86.9271 124.721 86.8031C124.715 86.7365 124.707 86.6698 124.699 86.6031C124.685 86.5413 124.685 86.4769 124.699 86.4151C124.703 86.4036 124.709 86.3933 124.718 86.3854C124.728 86.3775 124.739 86.3724 124.751 86.3706C124.762 86.3688 124.775 86.3704 124.786 86.3753C124.796 86.3802 124.806 86.3881 124.812 86.3981Z"
          fill="white"
        />
        <path
          d="M126.712 85.5042C126.731 85.5283 126.744 85.5559 126.75 85.5852C126.755 85.6182 126.757 85.6512 126.761 85.6852C126.767 85.7472 126.773 85.8092 126.779 85.8722C126.791 86.0052 126.8 86.1362 126.803 86.2722C126.808 86.5312 126.803 86.7892 126.79 87.0472C126.79 87.0832 126.728 87.0852 126.726 87.0472C126.711 86.7912 126.687 86.5352 126.659 86.2802C126.645 86.1562 126.634 86.0312 126.621 85.9072C126.615 85.8412 126.607 85.7752 126.6 85.7072C126.585 85.6454 126.585 85.581 126.6 85.5192C126.604 85.5081 126.611 85.4982 126.62 85.4907C126.629 85.4833 126.64 85.4784 126.652 85.4769C126.663 85.4753 126.675 85.4771 126.686 85.4819C126.697 85.4867 126.706 85.4945 126.712 85.5042Z"
          fill="white"
        />
        <path
          d="M128.613 84.6093C128.632 84.6337 128.645 84.6616 128.651 84.6913C128.657 84.7233 128.66 84.7573 128.663 84.7913C128.669 84.8543 128.674 84.9163 128.68 84.9783C128.692 85.1113 128.7 85.2433 128.703 85.3783C128.708 85.6373 128.703 85.8953 128.691 86.1543C128.691 86.1903 128.63 86.1923 128.628 86.1543C128.613 85.8983 128.588 85.6433 128.56 85.3873C128.546 85.2633 128.535 85.1383 128.522 85.0143C128.515 84.9483 128.508 84.8823 128.501 84.8143C128.487 84.7525 128.487 84.688 128.501 84.6263C128.505 84.615 128.512 84.6049 128.521 84.5972C128.53 84.5895 128.541 84.5845 128.552 84.5827C128.564 84.581 128.576 84.5825 128.587 84.5872C128.597 84.5919 128.607 84.5995 128.613 84.6093Z"
          fill="white"
        />
        <path
          d="M130.513 83.7143C130.532 83.7384 130.545 83.766 130.551 83.7953C130.557 83.8283 130.559 83.8613 130.562 83.8953C130.569 83.9573 130.575 84.0193 130.58 84.0823C130.592 84.2153 130.601 84.3473 130.604 84.4823C130.609 84.7413 130.604 84.9993 130.592 85.2583C130.592 85.2943 130.53 85.2963 130.528 85.2583C130.513 85.0023 130.489 84.7473 130.461 84.4913C130.447 84.3673 130.436 84.2423 130.423 84.1183C130.416 84.0523 130.409 83.9863 130.402 83.9183C130.388 83.8565 130.388 83.7921 130.402 83.7303C130.406 83.7194 130.413 83.7096 130.422 83.7022C130.431 83.6948 130.442 83.69 130.453 83.6884C130.465 83.6867 130.476 83.6883 130.487 83.6928C130.498 83.6974 130.507 83.7048 130.513 83.7143Z"
          fill="white"
        />
        <path
          d="M132.414 82.8193C132.433 82.8438 132.445 82.8717 132.452 82.9013C132.457 82.9333 132.46 82.9673 132.464 83.0013C132.47 83.0633 132.476 83.1253 132.481 83.1883C132.493 83.3203 132.502 83.4523 132.505 83.5883C132.51 83.8473 132.505 84.1053 132.492 84.3643C132.492 84.4003 132.43 84.4023 132.428 84.3643C132.413 84.1083 132.389 83.8523 132.361 83.5973C132.347 83.4733 132.336 83.3493 132.323 83.2243C132.317 83.1576 132.309 83.0909 132.301 83.0243C132.287 82.9625 132.287 82.8981 132.301 82.8363C132.305 82.8248 132.311 82.8144 132.321 82.8065C132.33 82.7987 132.341 82.7935 132.353 82.7917C132.364 82.7899 132.377 82.7916 132.388 82.7964C132.399 82.8013 132.408 82.8093 132.414 82.8193Z"
          fill="white"
        />
        <path
          d="M134.315 81.9243C134.333 81.9484 134.346 81.976 134.353 82.0053C134.358 82.0383 134.36 82.0713 134.364 82.1053C134.37 82.1673 134.377 82.2303 134.382 82.2923C134.394 82.4253 134.403 82.5573 134.406 82.6923C134.411 82.9513 134.406 83.2093 134.393 83.4683C134.393 83.5043 134.331 83.5063 134.329 83.4683C134.314 83.2123 134.29 82.9563 134.262 82.7013C134.248 82.5773 134.237 82.4523 134.224 82.3283C134.218 82.2623 134.21 82.1963 134.203 82.1283C134.189 82.0665 134.189 82.0021 134.203 81.9403C134.207 81.9291 134.214 81.9191 134.223 81.9115C134.232 81.9039 134.243 81.899 134.254 81.8974C134.266 81.8957 134.278 81.8973 134.289 81.9021C134.3 81.9068 134.309 81.9145 134.315 81.9243Z"
          fill="white"
        />
        <path
          d="M136.216 81.0293C136.235 81.0536 136.248 81.0816 136.254 81.1113C136.26 81.1443 136.262 81.1772 136.265 81.2112C136.271 81.2732 136.277 81.3352 136.283 81.3982C136.295 81.5312 136.304 81.6622 136.307 81.7982C136.312 82.0572 136.307 82.3152 136.294 82.5742C136.294 82.6102 136.232 82.6122 136.23 82.5742C136.215 82.3182 136.191 82.0622 136.163 81.8072C136.149 81.6832 136.138 81.5583 136.125 81.4333C136.119 81.3673 136.111 81.3012 136.104 81.2332C136.09 81.1715 136.09 81.107 136.104 81.0452C136.108 81.034 136.115 81.0241 136.124 81.0165C136.133 81.0089 136.144 81.004 136.155 81.0023C136.167 81.0007 136.179 81.0023 136.19 81.0071C136.201 81.0118 136.21 81.0195 136.216 81.0293Z"
          fill="white"
        />
        <path
          d="M138.111 80.1352C138.13 80.1596 138.143 80.1875 138.149 80.2172C138.155 80.2492 138.157 80.2832 138.16 80.3172C138.167 80.3802 138.173 80.4421 138.178 80.5041C138.19 80.6371 138.199 80.7691 138.202 80.9041C138.207 81.1631 138.202 81.4211 138.19 81.6801C138.19 81.7161 138.128 81.7181 138.126 81.6801C138.111 81.4241 138.087 81.1691 138.059 80.9131C138.045 80.7891 138.034 80.6642 138.021 80.5402C138.014 80.4742 138.007 80.4081 138 80.3401C137.986 80.2784 137.986 80.2139 138 80.1522C138.004 80.1411 138.011 80.1313 138.02 80.1238C138.028 80.1163 138.039 80.1114 138.051 80.1096C138.062 80.1079 138.074 80.1093 138.085 80.1138C138.095 80.1183 138.105 80.1257 138.111 80.1352Z"
          fill="white"
        />
        <path
          d="M140.016 79.2403C140.035 79.2644 140.048 79.292 140.054 79.3213C140.059 79.3543 140.062 79.3873 140.065 79.4213C140.071 79.4833 140.078 79.5453 140.083 79.6083C140.095 79.7413 140.104 79.8733 140.107 80.0083C140.112 80.2673 140.107 80.5253 140.094 80.7833C140.094 80.8193 140.032 80.8213 140.03 80.7833C140.015 80.5273 139.991 80.2713 139.963 80.0163C139.949 79.8923 139.938 79.7673 139.925 79.6433C139.918 79.5773 139.911 79.5113 139.903 79.4433C139.889 79.3815 139.889 79.3172 139.903 79.2553C139.907 79.244 139.914 79.2338 139.923 79.2261C139.932 79.2185 139.943 79.2135 139.955 79.212C139.967 79.2104 139.979 79.2122 139.99 79.2173C140.001 79.2223 140.01 79.2303 140.016 79.2403Z"
          fill="white"
        />
        <path
          d="M141.956 86.7971L121.763 91.0861C121.763 91.0861 124.185 119.366 124.097 119.686C117.94 120.463 111.732 120.764 105.528 120.586C105.528 120.586 91.6435 114.551 82.1645 124.965L80.8165 143.236C107.227 146.436 142.587 144.954 147.275 135.96C152.368 126.192 141.956 86.7971 141.956 86.7971Z"
          fill="#A0BDFF"
        />
        <path
          d="M124.049 119.56C121.809 119.56 118.049 119.983 115.812 120.07C113.59 120.156 111.369 120.287 109.148 120.402C107.87 120.468 106.801 120.389 105.527 120.511C105.518 120.511 105.508 120.515 105.501 120.522C105.494 120.529 105.49 120.538 105.49 120.548C105.49 120.558 105.494 120.567 105.501 120.574C105.508 120.581 105.518 120.585 105.527 120.585C109.922 120.821 114.327 120.735 118.708 120.325C119.987 120.21 122.833 119.907 124.102 119.717C124.164 119.71 124.269 119.56 124.049 119.56Z"
          fill="#455A64"
        />
        <path
          d="M104.268 118.657C103.44 117.739 102.508 116.92 101.491 116.215C101.456 116.19 101.416 116.247 101.447 116.274C102.333 117.108 103.165 117.996 103.939 118.933C104.778 119.837 105.549 120.803 106.245 121.821C106.249 121.825 106.253 121.827 106.258 121.828C106.263 121.828 106.268 121.827 106.272 121.825C106.276 121.822 106.279 121.818 106.281 121.814C106.282 121.809 106.282 121.804 106.28 121.799C105.722 120.684 105.048 119.631 104.268 118.657Z"
          fill="#455A64"
        />
        <path
          d="M148.681 122.355C148.657 121.355 148.569 120.355 148.492 119.363C148.34 117.388 148.144 115.418 147.881 113.455C147.35 109.501 146.664 105.574 145.781 101.683C145.534 100.596 145.281 99.51 145.002 98.429C144.751 97.322 144.437 96.2303 144.061 95.159C144.029 95.076 143.878 95.084 143.894 95.186C144.037 96.143 144.294 97.093 144.505 98.037C144.716 98.981 144.935 99.954 145.138 100.914C145.553 102.873 145.933 104.84 146.277 106.814C146.965 110.735 147.522 114.673 147.987 118.626C148.118 119.736 148.228 120.849 148.348 121.96C148.408 122.516 148.632 126.96 148.643 127.535C148.643 127.584 148.722 127.581 148.717 127.535C148.671 127.04 148.716 123.842 148.681 122.355Z"
          fill="#455A64"
        />
        <path
          d="M124.048 116.49C123.992 115.456 123.844 114.43 123.605 113.423C123.603 113.418 123.598 113.415 123.593 113.412C123.588 113.41 123.583 113.409 123.577 113.41C123.572 113.411 123.567 113.413 123.563 113.417C123.559 113.421 123.556 113.426 123.554 113.431C123.49 114.494 123.526 115.561 123.662 116.617C123.76 117.648 123.909 118.673 124.11 119.688C124.115 119.694 124.12 119.699 124.127 119.702C124.134 119.704 124.141 119.705 124.148 119.704C124.155 119.703 124.162 119.7 124.167 119.695C124.173 119.69 124.177 119.684 124.178 119.677C124.192 118.613 124.148 117.549 124.048 116.49Z"
          fill="#455A64"
        />
        <path
          d="M142.661 125.513C142.585 121.955 142.341 118.402 141.982 114.862C141.274 107.878 140.12 100.943 138.882 94.0352C138.731 93.1872 138.579 92.3398 138.426 91.4932C138.417 91.4452 138.347 91.4682 138.355 91.5142C139.509 98.4242 140.693 105.335 141.477 112.3C141.866 115.758 142.164 119.228 142.312 122.705C142.384 124.392 142.426 126.082 142.412 127.771C142.436 129.188 142.306 130.604 142.022 131.992C141.748 133.369 141.105 134.645 140.163 135.686C139.153 136.675 137.924 137.412 136.575 137.837C135.115 138.316 133.622 138.691 132.107 138.957C130.441 139.287 128.761 139.557 127.069 139.766C120.299 140.497 113.485 140.738 106.68 140.485C99.7105 140.345 92.7415 140.085 85.7705 140.009C84.8965 139.999 84.0235 139.988 83.1505 139.967C83.1025 139.967 83.1045 140.04 83.1505 140.041C86.7455 140.152 90.3385 140.31 93.9335 140.42C97.4948 140.529 101.056 140.635 104.618 140.736C111.501 141.046 118.396 140.897 125.258 140.291C128.58 139.974 131.873 139.405 135.109 138.591C136.473 138.272 137.779 137.742 138.979 137.02C140.123 136.296 141.038 135.263 141.619 134.041C142.87 131.424 142.721 128.334 142.661 125.513Z"
          fill="#455A64"
        />
        <path
          d="M141.986 89.9581C137.049 90.7231 132.124 91.5471 127.245 92.6251C125.856 92.9321 124.48 93.2971 123.084 93.5731C123.037 93.5831 123.057 93.654 123.104 93.645C124.336 93.418 125.579 93.271 126.815 93.072C128.051 92.873 129.282 92.6721 130.515 92.4531C132.97 92.0191 135.404 91.48 137.842 90.959C139.234 90.659 140.621 90.346 142.008 90.029C142.056 90.02 142.035 89.9491 141.986 89.9581Z"
          fill="#455A64"
        />
        <path
          d="M38.7645 115.737C39.4972 112.546 40.7161 109.486 42.3785 106.665C43.3335 105.223 46.5135 101.371 47.6785 102.019C48.8435 102.667 48.0115 103.875 48.0115 103.875C48.0115 103.875 49.5985 102.449 50.7115 103.207C51.8245 103.965 50.4705 105.807 50.4705 105.807C50.4705 105.807 52.2065 104.461 53.2615 105.298C54.3165 106.135 52.6465 107.753 52.6465 107.753C52.6465 107.753 54.1775 106.453 54.7555 107.503C55.4555 108.771 52.3555 110.858 51.3825 112.503C51.1615 112.877 47.4325 120.408 47.4325 120.408L38.7645 115.737Z"
          fill="#FFC3BD"
        />
        <path
          d="M52.6385 107.79C50.7298 108.959 49.2873 110.755 48.5585 112.871C48.5375 112.919 48.4585 112.899 48.4785 112.846C49.3165 110.325 50.0365 108.856 52.5695 107.675C52.6935 107.618 52.7465 107.716 52.6385 107.79Z"
          fill="#263238"
        />
        <path
          d="M50.5385 105.713C48.667 107.153 47.1763 109.029 46.1965 111.177C46.1775 111.213 46.1155 111.185 46.1315 111.147C47.2235 108.577 47.9755 107.127 50.4315 105.615C50.5405 105.547 50.6395 105.63 50.5385 105.713Z"
          fill="#263238"
        />
        <path
          d="M47.8355 104.034C46.0314 105.551 44.6745 107.531 43.9105 109.76C43.9065 109.773 43.8974 109.785 43.8852 109.791C43.873 109.798 43.8587 109.799 43.8455 109.795C43.8322 109.791 43.8211 109.782 43.8145 109.77C43.808 109.758 43.8065 109.743 43.8105 109.73C44.3237 107.368 45.7453 105.302 47.7685 103.978C47.9125 103.878 47.9625 103.914 47.8355 104.034Z"
          fill="#263238"
        />
        <path
          d="M47.2145 122.353C48.5207 121.336 49.7501 120.224 50.8925 119.025C51.8715 117.825 53.8775 112.025 52.6485 111.518C51.0615 110.867 49.2425 113.99 48.5485 115.636C48.5485 115.636 44.8785 114.126 43.0885 116.085C41.2985 118.044 47.2145 122.353 47.2145 122.353Z"
          fill="#FFC3BD"
        />
        <path
          d="M52.1515 111.482C50.2755 111.932 49.5085 114.151 48.7195 115.698C48.7089 115.721 48.6928 115.742 48.6725 115.758C48.6523 115.773 48.6285 115.784 48.6033 115.789C48.578 115.793 48.552 115.792 48.5275 115.784C48.503 115.777 48.4807 115.763 48.4625 115.745C46.8825 115.215 44.4985 114.927 43.0855 115.976C43.0775 115.983 43.0671 115.987 43.0564 115.986C43.0457 115.986 43.0357 115.981 43.0285 115.973C43.0213 115.965 43.0176 115.955 43.0182 115.944C43.0187 115.933 43.0235 115.923 43.0315 115.916C44.3935 114.585 46.7585 114.677 48.4535 115.368C49.2385 113.768 50.1055 111.577 52.1475 111.468C52.1488 111.469 52.15 111.469 52.151 111.47C52.152 111.471 52.1527 111.473 52.1531 111.474C52.1534 111.475 52.1535 111.477 52.1532 111.478C52.153 111.48 52.1524 111.481 52.1515 111.482Z"
          fill="#263238"
        />
        <path
          d="M60.1355 122.58C55.7898 129.112 49.9141 134.483 43.0195 138.226C38.5975 140.65 33.9615 137.826 33.1985 136.035C31.5665 132.207 37.2565 117.349 39.3105 114.354C39.9195 113.465 40.8355 115.247 43.6945 117.74C45.0485 118.921 50.2405 118.617 49.8735 120.081C49.6115 121.124 47.9135 124.087 48.1515 124.068C48.7385 124.019 53.0345 120.896 57.5825 117.968C63.8415 113.94 61.5965 120.436 60.1355 122.58Z"
          fill="#FFC3BD"
        />
        <path
          d="M48.1595 123.948C47.8875 124.311 43.9595 132.33 43.0255 133.982C43.0015 134.024 43.0655 134.059 43.0905 134.018C44.0905 132.405 45.1255 130.811 46.0075 129.13C46.4435 128.3 46.8925 127.468 47.2505 126.602C47.4215 126.188 47.5795 125.77 47.7415 125.352C47.9468 124.913 48.1099 124.456 48.2285 123.987C48.2355 123.949 48.1855 123.912 48.1595 123.948Z"
          fill="#263238"
        />
        <path
          d="M48.5275 123.308L58.5325 127.137C60.1301 124.846 61.6071 122.472 62.9575 120.027C64.7495 116.471 63.8485 114.972 59.3255 116.808C56.2985 118.035 48.5275 123.308 48.5275 123.308Z"
          fill="#346AE1"
        />
        <path
          d="M49.9054 122.747C51.4004 123.204 52.8844 123.681 54.3504 124.224C55.0838 124.495 55.8104 124.777 56.5304 125.07C57.2524 125.363 57.9454 125.731 58.6694 126.013C58.7094 126.029 58.6814 126.092 58.6424 126.074C57.9344 125.754 57.1754 125.528 56.4514 125.248C55.7274 124.968 54.9954 124.689 54.2654 124.416C52.8074 123.87 51.3494 123.316 49.8794 122.808C49.8374 122.797 49.8654 122.735 49.9054 122.747Z"
          fill="#263238"
        />
        <path
          d="M68.2265 123.555H66.3355C66.2215 122.441 65.934 121.352 65.4835 120.327C65.7082 121.388 65.7926 122.473 65.7345 123.555H64.0735C63.9144 121.712 63.387 119.92 62.5225 118.285C62.9971 120.001 63.2273 121.775 63.2065 123.555H48.5725C47.9235 120.468 48.4265 117.108 48.1725 117.841C47.5993 119.693 47.2903 121.617 47.2545 123.555H45.6255C45.5799 122.776 45.6242 121.994 45.7575 121.224C45.9715 120.199 45.1305 122.224 45.0235 123.555H43.9865C43.8913 120.971 44.3694 118.398 45.3865 116.021C47.5235 111.849 46.2725 110.305 47.1495 105.737C48.1495 100.537 54.9775 94.2552 61.8025 100.825C62.9698 101.839 63.9084 103.09 64.5561 104.494C65.2038 105.898 65.5458 107.424 65.5595 108.97C65.5915 112.686 64.7465 114.649 66.0215 116.558C67.4465 118.614 68.2153 121.054 68.2265 123.555Z"
          fill="#263238"
        />
        <path
          d="M43.3185 122.934C43.144 121.351 43.2926 119.75 43.7555 118.226C43.9762 117.46 44.2588 116.713 44.6005 115.993C44.7645 115.633 44.9675 115.278 45.1425 114.935C45.3204 114.596 45.4738 114.245 45.6015 113.884C46.0595 112.396 46.3543 110.862 46.4805 109.309C46.5805 108.527 46.6805 107.743 46.8435 106.967C46.9979 106.19 47.2387 105.433 47.5615 104.709C47.1393 106.221 46.8953 107.778 46.8345 109.346C46.7755 110.131 46.6995 110.918 46.5885 111.705C46.4754 112.499 46.2886 113.28 46.0305 114.038C45.8921 114.419 45.7267 114.789 45.5355 115.145C45.3475 115.5 45.1615 115.83 44.9875 116.181C44.6395 116.876 44.3408 117.595 44.0935 118.332C43.8438 119.071 43.6516 119.827 43.5185 120.595C43.4455 120.979 43.3985 121.367 43.3615 121.758C43.3245 122.149 43.3135 122.54 43.3185 122.934Z"
          fill="#263238"
        />
        <path
          d="M65.5635 108.971C65.5955 109.603 65.5505 110.234 65.5635 110.861C65.5765 111.488 65.5775 112.113 65.6325 112.731C65.6852 113.347 65.7892 113.957 65.9435 114.555C65.9775 114.705 66.0305 114.849 66.0715 114.996L66.1045 115.106L66.1405 115.197C66.1685 115.26 66.2015 115.325 66.2405 115.391C66.3875 115.655 66.5565 115.921 66.7215 116.191C67.0465 116.732 67.3655 117.285 67.6425 117.861C67.9217 118.437 68.1557 119.033 68.3425 119.645C68.527 120.257 68.6539 120.884 68.7215 121.519C68.7831 122.151 68.7804 122.788 68.7135 123.419C68.6688 122.169 68.4295 120.934 68.0045 119.757C67.5672 118.592 67.0084 117.477 66.3375 116.429C66.1765 116.159 66.0105 115.893 65.8545 115.604C65.8155 115.53 65.7785 115.455 65.7435 115.374C65.7265 115.333 65.7095 115.286 65.6945 115.245L65.6605 115.128C65.6185 114.972 65.5605 114.819 65.5345 114.661C65.3898 114.036 65.3067 113.398 65.2865 112.756C65.2657 112.122 65.2868 111.487 65.3495 110.856C65.4035 110.22 65.4935 109.597 65.5635 108.971Z"
          fill="#263238"
        />
        <path
          d="M80.5245 143.915C83.6906 140.737 85.7359 136.614 86.3511 132.171C86.9663 127.727 86.118 123.204 83.9345 119.285C83.9345 119.285 79.1795 120.041 77.9345 119.797C74.1495 119.06 71.3125 116.964 66.9545 115.34C62.5965 113.716 59.5855 114.991 57.8545 117.951C57.4225 118.69 55.2975 129.482 56.8545 133.31C58.0755 136.321 63.0215 137.194 63.0215 137.194C63.0215 137.194 72.2015 144.834 80.5245 143.915Z"
          fill="#282A74"
        />
        <path
          d="M63.4585 117.454C63.4265 117.946 61.6755 121.397 59.4935 121.654C58.7825 121.737 58.1575 117.877 58.1575 117.877L58.0455 117.602L56.4915 113.76L60.6795 109.974L61.2905 109.463C61.2905 109.463 61.6465 110.542 62.0765 111.891C62.0965 111.953 62.1165 112.016 62.1375 112.083C62.1585 112.15 62.1895 112.23 62.2105 112.301C62.2775 112.501 62.3445 112.707 62.4105 112.912C62.4595 113.069 62.5105 113.221 62.5525 113.386C62.5945 113.551 62.6455 113.713 62.6955 113.878C63.0314 115.051 63.2863 116.246 63.4585 117.454Z"
          fill="#FFC3BD"
        />
        <path
          d="M62.0765 111.893C61.8595 113.081 61.3865 114.207 60.6904 115.193C59.9943 116.179 59.0917 117.002 58.0455 117.604L56.4915 113.762L60.6795 109.976L61.2905 109.465C61.2905 109.465 61.6465 110.544 62.0765 111.893Z"
          fill="#263238"
        />
        <path
          d="M49.5965 109.492C51.2225 116.582 55.6535 116.409 57.1815 116.057C58.5685 115.737 63.2535 114.472 62.3685 107.251C61.4835 100.03 57.6685 98.5462 54.4615 99.1092C51.2545 99.6722 47.9705 102.401 49.5965 109.492Z"
          fill="#FFC3BD"
        />
        <path
          d="M50.6575 108.112C50.8083 108.039 50.9552 107.959 51.0975 107.87C51.2636 107.806 51.4041 107.689 51.4975 107.537C51.5171 107.484 51.5187 107.426 51.5019 107.371C51.4851 107.317 51.4508 107.27 51.4045 107.237C51.3025 107.185 51.1886 107.162 51.0745 107.169C50.9604 107.176 50.8503 107.213 50.7555 107.277C50.5435 107.377 50.3763 107.553 50.2865 107.769C50.269 107.82 50.2667 107.874 50.2798 107.926C50.2928 107.978 50.3207 108.025 50.3601 108.062C50.3994 108.098 50.4484 108.122 50.5013 108.131C50.5541 108.14 50.6083 108.134 50.6575 108.112Z"
          fill="#263238"
        />
        <path
          d="M55.5215 106.795C55.3557 106.818 55.1888 106.833 55.0215 106.839C54.8473 106.879 54.6649 106.86 54.5025 106.786C54.4564 106.753 54.4225 106.706 54.4059 106.652C54.3892 106.597 54.3909 106.539 54.4105 106.486C54.4662 106.386 54.5477 106.303 54.6463 106.246C54.7449 106.188 54.8572 106.158 54.9714 106.158C55.2034 106.123 55.4401 106.175 55.6354 106.305C55.6776 106.337 55.7096 106.381 55.7276 106.431C55.7455 106.481 55.7487 106.535 55.7366 106.587C55.7246 106.639 55.6979 106.686 55.6597 106.723C55.6216 106.76 55.5735 106.785 55.5215 106.795Z"
          fill="#263238"
        />
        <path
          d="M55.1255 108.832C55.1255 108.813 55.1695 108.844 55.1745 108.864C55.2925 109.345 55.5085 109.877 55.9845 109.915C55.9995 109.915 56.0045 109.94 55.9845 109.943C55.4355 110.015 55.1595 109.307 55.1255 108.832Z"
          fill="#263238"
        />
        <path
          d="M55.4125 108.217C56.1635 107.944 56.6525 109.467 55.9575 109.717C55.2625 109.967 54.7825 108.444 55.4125 108.217Z"
          fill="#263238"
        />
        <path
          d="M55.7374 108.196C55.8984 108.24 56.0764 108.349 56.2424 108.314C56.4334 108.273 56.5224 108.068 56.5944 107.876C56.6024 107.856 56.6194 107.865 56.6264 107.885C56.6951 108.017 56.711 108.17 56.6709 108.313C56.6308 108.456 56.5376 108.578 56.4104 108.655C56.1384 108.786 55.8504 108.594 55.6824 108.324C55.6504 108.279 55.6724 108.183 55.7374 108.196Z"
          fill="#263238"
        />
        <path
          d="M52.1035 109.567C52.0935 109.55 52.0745 109.601 52.0805 109.621C52.2505 110.087 52.3675 110.648 51.9935 110.945C51.9815 110.954 51.9935 110.977 52.0055 110.97C52.5015 110.725 52.3395 109.981 52.1035 109.567Z"
          fill="#263238"
        />
        <path
          d="M51.5205 109.215C50.7445 109.407 51.1865 110.944 51.9045 110.766C52.6225 110.588 52.1665 109.054 51.5205 109.215Z"
          fill="#263238"
        />
        <path
          d="M51.2295 109.408C51.1295 109.524 51.0535 109.708 50.9115 109.758C50.7465 109.817 50.5745 109.687 50.4215 109.558C50.4055 109.545 50.3975 109.558 50.4025 109.582C50.4785 109.882 50.6635 110.156 50.9625 110.132C51.2345 110.109 51.3505 109.803 51.3365 109.49C51.3335 109.432 51.2685 109.361 51.2295 109.408Z"
          fill="#263238"
        />
        <path
          d="M56.0565 112.525C55.9865 112.675 55.9305 112.866 55.7675 112.943C55.5933 113.004 55.4083 113.027 55.2245 113.012C55.2207 113.012 55.217 113.013 55.2138 113.015C55.2107 113.017 55.2083 113.02 55.207 113.023C55.2058 113.027 55.2057 113.031 55.2069 113.034C55.2081 113.038 55.2104 113.041 55.2135 113.043C55.3138 113.11 55.4302 113.148 55.5504 113.154C55.6707 113.16 55.7903 113.133 55.8965 113.076C55.9819 113.016 56.0476 112.932 56.0853 112.835C56.1231 112.738 56.1311 112.632 56.1085 112.53C56.1065 112.5 56.0675 112.503 56.0565 112.525Z"
          fill="#263238"
        />
        <path
          d="M53.5505 111.424C53.5505 111.424 53.6705 112.161 53.7735 112.495C53.7835 112.526 53.8735 112.516 53.9845 112.488C53.9905 112.488 53.9945 112.488 53.9975 112.488C54.3004 112.452 54.5875 112.333 54.8271 112.144C55.0667 111.955 55.2495 111.703 55.3555 111.417C55.3685 111.377 55.3155 111.356 55.2915 111.386C54.9561 111.808 54.4934 112.11 53.9725 112.247C53.9205 112.162 53.8085 110.978 53.7545 110.99C53.5043 111.092 53.2689 111.226 53.0545 111.39C52.7545 109.862 52.7105 108.299 52.3945 106.782C52.3917 106.771 52.3853 106.762 52.3764 106.755C52.3674 106.749 52.3565 106.746 52.3454 106.746C52.3343 106.747 52.3238 106.751 52.3156 106.759C52.3074 106.766 52.3021 106.776 52.3005 106.787C52.3009 108.464 52.4684 110.137 52.8005 111.78C52.8255 111.984 53.4345 111.522 53.5505 111.424Z"
          fill="#263238"
        />
        <path
          d="M62.4035 107.716C60.5975 108.222 58.3285 102.899 58.0565 102.242C58.4193 103.147 58.6838 104.088 58.8455 105.049C58.6526 105.106 58.4515 105.131 58.2505 105.122C58.1629 104.852 58.055 104.589 57.9275 104.335C57.8735 104.214 57.9665 104.622 58.0545 105.135C57.9445 105.135 57.8245 105.141 57.6955 105.148C57.5311 104.564 57.2897 104.005 56.9775 103.484C57.0035 104.05 57.0676 104.613 57.1695 105.17C55.504 105.21 53.8479 105.434 52.2315 105.837C51.7805 105.962 51.3985 106.09 51.0635 106.215C50.8485 105.549 50.6985 104.815 50.6985 104.982C50.6937 105.428 50.7329 105.873 50.8155 106.311C50.6345 106.38 50.4675 106.447 50.3155 106.511C49.9695 105.583 49.9375 103.936 49.8605 104.211C49.6395 105.063 49.5818 105.949 49.6905 106.822C49.0905 107.151 48.9495 107.334 48.8795 107.173C48.4807 105.255 48.7774 103.258 49.7165 101.539C51.2785 98.7262 55.5625 96.7782 59.1045 99.2152C60.5839 99.6564 61.8406 100.643 62.6205 101.975C63.6385 104.042 63.4465 107.422 62.4035 107.716Z"
          fill="#263238"
        />
        <path
          d="M61.3665 108.053C61.3665 108.053 62.1805 105.405 63.3345 105.627C64.4885 105.849 64.3625 109.349 63.3495 110.148C63.2267 110.26 63.0825 110.345 62.9258 110.4C62.769 110.454 62.6028 110.476 62.4373 110.465C62.2717 110.453 62.1102 110.409 61.9624 110.333C61.8147 110.257 61.6838 110.153 61.5775 110.025L61.3665 108.053Z"
          fill="#FFC3BD"
        />
        <path
          d="M63.2735 106.629C63.2915 106.621 63.3035 106.646 63.2895 106.658C62.9786 106.962 62.735 107.328 62.5743 107.732C62.4136 108.136 62.3394 108.569 62.3565 109.003C62.3773 108.907 62.4187 108.817 62.4777 108.738C62.5368 108.66 62.6122 108.595 62.6987 108.549C62.7852 108.502 62.8808 108.475 62.9788 108.469C63.0768 108.463 63.175 108.479 63.2665 108.514C63.2925 108.522 63.2825 108.562 63.2585 108.564C63.1022 108.565 62.9499 108.614 62.8225 108.705C62.6952 108.796 62.5991 108.924 62.5475 109.071C62.4628 109.319 62.4048 109.574 62.3745 109.834C62.3595 109.918 62.2175 109.922 62.2175 109.834V109.813C61.8615 108.788 62.2015 107.088 63.2735 106.629Z"
          fill="#263238"
        />
        <path
          d="M69.6805 118.731C75.6225 126.108 83.7384 138.642 80.3454 141.566C74.7674 146.373 58.2095 149.466 54.4065 150.019C52.7645 150.257 49.2065 139.579 50.2715 138.16C51.3365 136.741 68.8895 135.331 68.9865 134.249C69.0415 133.636 67.4865 128.423 65.6095 121.936C63.4465 114.456 67.9805 116.62 69.6805 118.731Z"
          fill="#FFC3BD"
        />
        <path
          d="M53.1295 137.203C53.1295 137.203 48.0845 137.287 44.7885 137.498C42.6373 137.595 40.5153 138.033 38.5025 138.798C36.5405 139.851 29.0025 145.51 30.3395 147.365C31.5455 149.045 37.4005 143.365 37.4005 143.365C37.4005 143.365 30.9855 148.577 32.8105 150.333C34.6355 152.089 40.3955 144.788 40.3955 144.788C40.3955 144.788 33.9245 150.288 36.0955 151.804C37.7335 152.948 43.3235 146.63 43.3235 146.63C43.3235 146.63 37.7945 151.399 39.4285 152.647C41.2345 154.027 46.7605 148.518 46.7605 148.518C51.4485 150.993 54.6925 150.253 56.9375 149.453C57.4785 149.26 53.1295 137.203 53.1295 137.203Z"
          fill="#FFC3BD"
        />
        <path
          d="M39.5035 141.421C38.7275 142.006 37.9385 142.593 37.2175 143.245C36.4965 143.897 35.7975 144.575 35.1405 145.29C34.2633 146.209 33.4946 147.226 32.8495 148.32C34.2118 146.554 35.7365 144.919 37.4035 143.436C38.1225 142.786 38.8095 142.099 39.5295 141.447C39.5455 141.426 39.5225 141.407 39.5035 141.421Z"
          fill="#263238"
        />
        <path
          d="M41.9044 143.982C40.9464 144.772 39.9664 145.521 39.0424 146.353C38.1184 147.185 37.3245 148.048 36.4645 148.937C36.3985 149.006 36.4565 149.019 36.5265 148.952C37.4215 148.097 38.3135 147.377 39.2175 146.533C40.1215 145.689 40.9935 144.81 41.9245 144.003C41.9395 143.99 41.9184 143.969 41.9044 143.982Z"
          fill="#263238"
        />
        <path
          d="M44.3605 145.809C42.8616 146.985 41.4877 148.312 40.2605 149.769C40.1975 149.84 40.2755 149.848 40.3435 149.779C41.6805 148.437 42.7205 147.379 44.3985 145.861C44.4265 145.838 44.3925 145.785 44.3605 145.809Z"
          fill="#263238"
        />
        <path
          d="M56.8825 136.56C56.1745 136.572 54.6895 136.9 53.0345 137.009C49.3095 137.253 44.0345 137.363 42.1575 137.509C37.0875 137.89 34.0885 141.709 32.6955 143.049C31.8625 143.863 31.1115 144.757 30.4535 145.717C30.1115 146.238 29.7905 146.977 30.2765 147.534C30.8675 148.212 33.0055 147.425 35.1945 145.346C35.2735 145.271 35.2215 145.227 35.1405 145.296C32.0615 147.918 30.8025 147.655 30.4645 147.227C30.2455 146.95 30.4385 146.458 30.6195 146.08C30.9126 145.534 31.2746 145.028 31.6965 144.573C33.0978 142.992 34.6565 141.557 36.3485 140.291C38.1654 138.865 40.3502 137.983 42.6485 137.75C44.2805 137.576 49.3065 137.5 53.0605 137.223C54.7045 137.101 56.1805 136.739 56.8935 136.653C56.9425 136.641 56.9365 136.559 56.8825 136.56Z"
          fill="#263238"
        />
        <path
          d="M77.2675 128.089L66.5565 128.295C65.8315 125.597 65.2477 122.864 64.8075 120.105C64.3285 116.152 65.6845 115.047 69.3165 118.305C71.7465 120.492 77.2675 128.089 77.2675 128.089Z"
          fill="#282A74"
        />
        <path
          d="M67.8635 128.273C67.7485 128.254 66.6475 128.239 66.5635 128.261C66.4155 127.645 65.6875 124.773 65.6015 124.476C65.6006 124.472 65.5988 124.468 65.5963 124.464C65.5939 124.461 65.5907 124.458 65.587 124.455C65.5833 124.453 65.5793 124.451 65.575 124.45C65.5707 124.45 65.5663 124.45 65.562 124.451C65.5577 124.452 65.5537 124.453 65.5501 124.456C65.5465 124.458 65.5434 124.461 65.5411 124.465C65.5387 124.469 65.5371 124.473 65.5363 124.477C65.5355 124.482 65.5356 124.486 65.5365 124.49C65.5685 124.664 65.6875 125.327 65.8265 126.069L65.8095 126.04C65.7355 125.91 65.6515 125.784 65.5695 125.658C65.4875 125.532 65.4185 125.385 65.3455 125.249C65.3235 125.207 65.2575 125.249 65.2815 125.288C65.3565 125.417 65.4395 125.545 65.5035 125.681C65.5675 125.817 65.6205 125.938 65.6865 126.063C65.7525 126.188 65.8195 126.31 65.8865 126.434L65.8965 126.452C66.0555 127.289 66.2255 128.139 66.3135 128.452C66.8307 128.439 67.3467 128.395 67.8585 128.318C67.8855 128.307 67.8815 128.276 67.8635 128.273Z"
          fill="#263238"
        />
        <path
          d="M76.1625 127.094C74.6015 127.016 73.0435 126.961 71.4805 126.975C70.7005 126.982 69.9212 127 69.1425 127.031C68.3645 127.062 67.5875 127.173 66.8105 127.192C66.7675 127.192 66.7725 127.262 66.8105 127.259C67.5845 127.199 68.3745 127.244 69.1515 127.226C69.9285 127.208 70.7105 127.194 71.4905 127.185C73.0475 127.166 74.6055 127.144 76.1625 127.161C76.2115 127.161 76.2035 127.096 76.1625 127.094Z"
          fill="#263238"
        />
        <path
          d="M73.2685 122.738C73.2654 122.736 73.2616 122.734 73.2577 122.734C73.2537 122.734 73.2498 122.735 73.2467 122.738C73.2436 122.74 73.2415 122.744 73.2407 122.748C73.24 122.752 73.2406 122.756 73.2425 122.759C73.4755 123.224 76.2625 127.032 77.1515 128.019C76.8734 128.033 76.5965 128.064 76.3225 128.113C76.6863 128.146 77.0521 128.149 77.4165 128.122C77.4365 128.114 73.6705 123.083 73.2685 122.738Z"
          fill="#263238"
        />
        <path
          d="M61.2765 85.843C60.2577 87.8442 58.6988 89.5202 56.7765 90.681C54.7271 89.7632 52.9761 88.289 51.7225 86.426C50.3995 84.118 50.9895 81.501 52.8135 80.826C55.4295 79.857 56.3975 84.458 56.3975 84.458C56.3975 84.458 56.7975 79.774 59.5155 80.418C61.4065 80.865 62.3095 83.392 61.2765 85.843Z"
          fill="#EDB561"
        />
        <path
          d="M48.2175 77.8232C48.2924 79.6569 47.8521 81.4749 46.9465 83.0711C45.1204 83.2375 43.2843 82.8886 41.6465 82.0642C39.7805 80.9502 39.1525 78.8492 40.1825 77.6382C41.6585 75.9012 44.1895 78.7901 44.1895 78.7901C44.1895 78.7901 42.5995 75.2902 44.7895 74.6642C46.3175 74.2252 47.9725 75.6632 48.2175 77.8232Z"
          fill="#EDB561"
        />
        <path
          d="M64.9425 53.5731C65.0175 55.4071 64.5772 57.2255 63.6715 58.8221C61.8455 58.9882 60.0094 58.6394 58.3715 57.8151C56.5045 56.7011 55.8775 54.6001 56.9065 53.3891C58.3825 51.6511 60.9135 54.5411 60.9135 54.5411C60.9135 54.5411 59.3245 51.0411 61.5135 50.4141C63.0415 49.9761 64.6965 51.4141 64.9425 53.5731Z"
          fill="#EDB561"
        />
        <path
          d="M57.9575 66.9969C57.169 68.0711 56.095 68.9026 54.8575 69.3969C53.7459 68.6649 52.862 67.6355 52.3065 66.4259C51.7525 64.9499 52.3465 63.4749 53.4785 63.2539C55.1015 62.9369 55.2285 65.7199 55.2285 65.7199C55.2285 65.7199 55.9095 63.0199 57.4375 63.6539C58.5085 64.0969 58.7955 65.6609 57.9575 66.9969Z"
          fill="#EDB561"
        />
        <path
          d="M50.5775 45.82C50.4244 46.6062 50.0541 47.3337 49.5085 47.9201C48.7162 47.8063 47.9717 47.4726 47.3595 46.9571C47.2042 46.8432 47.075 46.6974 46.9807 46.5296C46.8863 46.3617 46.8289 46.1756 46.8123 45.9838C46.7958 45.7919 46.8204 45.5987 46.8846 45.4172C46.9488 45.2356 47.051 45.0699 47.1845 44.9311C47.9845 44.3431 48.7685 45.825 48.7685 45.825C48.7685 45.825 48.4465 44.181 49.4415 44.135C50.1345 44.102 50.6905 44.879 50.5775 45.82Z"
          fill="#EDB561"
        />
      </svg>

      <div className="flex flex-col items-center">
        <h3 className="font-medium text-black-200">{t('no_ads')}</h3>
        <span className="text-sm text-black-200 w-[251px] text-center">{t('no_ads_yet')}</span>
      </div>
      <Button
        onClick={() => navigate('/ads/create')}
        size="medium"
        text={t('create_new_ads')}
        variant="primary"
        boxShadow
      />
    </div>
  );
}
