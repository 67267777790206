import { SelectHTMLAttributes, useState } from 'react';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: any;
  bg?: string;
  textColor?: string;
}

export default function Select({ options, bg, textColor, className, ...props }: SelectProps) {
  const [iconDropdown, setIconDropdown] = useState(false);

  return (
    <div
      className={`${
        bg ? bg : 'bg-backgray'
      } desktop:w-[588px] tablet:[60vw] relative cursor-pointer border border-grey-150 border-opacity-50 rounded-md p-1`}
    >
      <select
        onMouseDown={() => setIconDropdown(!iconDropdown)}
        onClick={() => setIconDropdown(!iconDropdown)}
        className={`bg-transparent block custom_select w-full outline-none py-6 px-4 cursor-pointer text-xl ${
          textColor ? textColor : 'text-black-100 text-opacity-50 '
        }  font-semibold ${className}`}
        {...props}
      >
        {options}
      </select>
      <svg
        className={`absolute right-4 top-8 ${iconDropdown ? 'rotate-90' : 'rotate-0'}`}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <g clipPath="url(#clip0_1768_775)">
          <path d="M5.59 16.59L7 18L15 10L7 2L5.59 3.41L12.17 10L5.59 16.59Z" fill="black" />
        </g>
        <defs>
          <clipPath id="clip0_1768_775">
            <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 -1 20 20)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
