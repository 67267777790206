import { create } from 'zustand';
import { api } from '../services/axios';
import { showErrorMessage } from '../utils/auxFunctions';
import { Insight } from './useInsights';

interface Pagination {
  current_page: number;
  last_page: number;
  total: number;
  last_page_url: string | null;
}

export interface Category {
  id?: number;
  uuid: string;
  name: string;
  parent_id?: number | null;
  icon: string;
  icon_color: string;
  group_id?: number;
  posts: Insight[];
  children: Category[];
  category_favorite: { uuid: string; favorite: number }[];
  image?: string;
}
interface CategoryProps {
  pagination: Pagination;
  categories: Category[];
  category: Category;
  createCategory: (
    groupUuid: string,
    name: string,
    icon?: string,
    icon_color?: string,
    parent_id?: string,
  ) => Promise<Category>;
  getCategory: (groupUuid: string, categoryUuid: string) => Promise<Category>;
  updateCategory: (
    groupUuid: string,
    categoryUuid: string,
    name: string,
    icon?: string,
    icon_color?: string,
    parent_id?: string,
  ) => Promise<Category>;
  deleteCategory: (groupUuid: string, categoryUuid: string) => Promise<Category>;
  addCategoryToFavorite: (groupUuid: string, categoryUuid: string, favorite: boolean) => Promise<void>;
  getAllCategories: (groupUuid: string, params?: string) => Promise<void>;
}

export const useCategory = create<CategoryProps>((set) => ({
  pagination: {
    current_page: 1,
    last_page: 1,
    total: 1,
    last_page_url: null,
  },
  categories: [],
  category: {} as Category,

  getCategory: async (groupUuid: string, categoryUuid: string) => {
    try {
      const { data } = await api.get(`/groups/${groupUuid}/categories/${categoryUuid}`);
      set({ category: data.content.data.category });
      return data.content.data.category;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  getAllCategories: async (groupUuid: string, params?: string) => {
    try {
      const { data } = await api.get(`/groups/${groupUuid}/categories` + (params ? params : ''));
      set({ categories: data.content.data.data });
      set({
        pagination: {
          current_page: data.content.data.current_page,
          last_page: data.content.data.last_page,
          total: data.content.data.total,
          last_page_url: data.content.data.last_page_url,
        },
      });
    } catch (error) {
      showErrorMessage(error);
    }
  },

  updateCategory: async (
    groupUuid: string,
    categoryUuid: string,
    name: string,
    icon?: string,
    icon_color?: string,
    parent_id?: string,
  ) => {
    try {
      const { data } = await api.put(`/groups/${groupUuid}/categories/${categoryUuid}`, {
        name,
        icon,
        icon_color,
        parent_id: parent_id ? parent_id : null,
      });
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  createCategory: async (groupUuid: string, name: string, icon?: string, icon_color?: string, parent_id?: string) => {
    try {
      const { data } = await api.post(`/groups/${groupUuid}/categories`, {
        name,
        icon,
        icon_color,
        parent_id: parent_id ? parent_id : null,
      });
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },

  deleteCategory: async (groupUuid: string, categoryUuid: string) => {
    try {
      const { data } = await api.delete(`/groups/${groupUuid}/categories/${categoryUuid}`);
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  addCategoryToFavorite: async (groupUuid: string, categoryUuid: string, favorite: boolean) => {
    try {
      await api.put(`/groups/${groupUuid}/categories/${categoryUuid}/favorite`, { favorite });
    } catch (error) {
      showErrorMessage(error);
    }
  },
}));
