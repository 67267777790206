import * as Dialog from '@radix-ui/react-dialog';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCategory } from '@hooks/useCategory';
import { useGroups } from '@hooks/useGroups';

import { ButtonAdd } from '@components/ButtonAdd';
import CreateOrEditSubjectModal from '@components/CreateOrEditSubjectModal';
import Loading from '@components/Loading';

import { useTranslation } from 'react-i18next';
import { toast } from 'utils/toast';

export default function EditSubject() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [editThemes, setEditThemes] = useState(false);
  const { groupId, categoryId } = useParams();
  const [isSubbmitting, setIsSubmitting] = useState(false);
  const { deleteCategory, getCategory, category } = useCategory();
  const { getGroupByUuid, group } = useGroups();

  const navigate = useNavigate();

  const getCategoryInformation = useCallback(async () => {
    if (!groupId || !categoryId) {
      return;
    }
    getGroupByUuid(groupId);
    await getCategory(groupId, categoryId);
    setLoading(false);
  }, [categoryId, getCategory, getGroupByUuid, groupId]);

  useEffect(() => {
    getCategoryInformation();
  }, [getCategoryInformation]);

  async function handleDeleteCategory(subCategoryId: string) {
    if (!groupId) {
      toast({
        label: t('error'),
        message: t('group_not_found'),
        type: 'error',
      });
      return;
    }
    if (!categoryId) {
      toast({
        label: t('error'),
        message: t('category_not_found'),
        type: 'error',
      });
      return;
    }
    setIsSubmitting(true);
    const response = await deleteCategory(groupId, subCategoryId);
    if (response.uuid) {
      toast({
        label: t('success'),
        message: `${t('category')} ${response.name}, ${t('deleted_successfully')}`,
        type: 'success',
      });
      getCategory(groupId, categoryId);
    }
    setIsSubmitting(false);
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex flex-col items-center w-full min-h-[calc(100vh-88px)] bg-backgray py-14">
          <div className="flex flex-col desktop:w-[45vw] tablet:w-[60vw] bg-white">
            {group?.role === 'Admin' && (
              <div className="flex w-full relative">
                <Dialog.Root>
                  <Dialog.Trigger asChild>
                    <ButtonAdd bg="bg-white" text={t('add_new_subject')} textColor="text-primary-200" />
                  </Dialog.Trigger>
                  <CreateOrEditSubjectModal
                    isSubmitting={isSubbmitting}
                    setIsSubmitting={setIsSubmitting}
                    groupId={groupId || ''}
                    categoryId={categoryId || ''}
                  />
                </Dialog.Root>
                <button
                  onClick={() => setEditThemes(!editThemes)}
                  className="absolute top-[50%] translate-y-[-50%] right-2 text-secondary-300 text-lg hover:opacity-70 px-4 py-2"
                >
                  {editThemes ? t('done') : t('edit')}
                </button>
              </div>
            )}
            {category.children &&
              category.children.map((child) => {
                return (
                  <div
                    key={child.uuid}
                    className="flex items-center justify-between px-5 py-4 border-t border-grey-600 relative"
                  >
                    <div className="flex items-center gap-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="44" height="40" viewBox="0 0 44 40" fill="none">
                        <path
                          d="M24 10L21.7689 5.53783C21.1268 4.25361 20.8057 3.61146 20.3267 3.14233C19.9032 2.72746 19.3926 2.41195 18.8321 2.21862C18.1983 2 17.4804 2 16.0446 2H8.4C6.15979 2 5.03969 2 4.18404 2.43597C3.43139 2.81947 2.81947 3.43139 2.43597 4.18404C2 5.03969 2 6.15979 2 8.4V10M2 10H32.4C35.7603 10 37.4405 10 38.7239 10.654C39.8529 11.2292 40.7708 12.1471 41.346 13.2761C42 14.5595 42 16.2397 42 19.6V28.4C42 31.7603 42 33.4405 41.346 34.7239C40.7708 35.8529 39.8529 36.7708 38.7239 37.346C37.4405 38 35.7603 38 32.4 38H11.6C8.23969 38 6.55953 38 5.27606 37.346C4.14708 36.7708 3.2292 35.8529 2.65396 34.7239C2 33.4405 2 31.7603 2 28.4V10Z"
                          stroke="#282A74"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className="text-black-100 text-lg">{child.name}</p>
                    </div>
                    {child?.posts?.length === 0 && (
                      <div className="flex items-center gap-3">
                        <Dialog.Root>
                          <Dialog.Trigger asChild>
                            <button
                              className={`hover:opacity-50 ${
                                editThemes ? 'opacity-100 scale-100' : 'opacity-0 scale-0'
                              } transition-button duration-300`}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                              >
                                <path
                                  d="M3.125 26.8749L10.0616 24.207C10.5053 24.0363 10.7271 23.951 10.9346 23.8396C11.119 23.7406 11.2947 23.6264 11.4601 23.4982C11.6462 23.3537 11.8142 23.1857 12.1503 22.8495L26.25 8.74989C27.6307 7.36918 27.6307 5.1306 26.25 3.74989C24.8693 2.36918 22.6307 2.36918 21.25 3.74989L7.15035 17.8495C6.81422 18.1857 6.64615 18.3537 6.50174 18.5398C6.37347 18.7051 6.25925 18.8809 6.16029 19.0652C6.04888 19.2728 5.96356 19.4946 5.79292 19.9383L3.125 26.8749ZM3.125 26.8749L5.69765 20.1861C5.88175 19.7074 5.97379 19.4681 6.13168 19.3585C6.26965 19.2627 6.44038 19.2265 6.60537 19.258C6.79417 19.294 6.97548 19.4753 7.3381 19.838L10.162 22.6618C10.5246 23.0244 10.7059 23.2058 10.742 23.3946C10.7735 23.5595 10.7372 23.7303 10.6414 23.8683C10.5318 24.0261 10.2925 24.1182 9.81384 24.3023L3.125 26.8749Z"
                                  stroke="#282A74"
                                  strokeWidth="3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </Dialog.Trigger>
                          <CreateOrEditSubjectModal
                            isSubmitting={isSubbmitting}
                            setIsSubmitting={setIsSubmitting}
                            groupId={groupId || ''}
                            categoryId={categoryId || ''}
                            subCategory={child}
                          />
                        </Dialog.Root>

                        <button
                          disabled={isSubbmitting}
                          className={`hover:opacity-50 ${
                            editThemes ? 'opacity-100 scale-100' : 'opacity-0 scale-0'
                          } transition-button duration-300`}
                          onClick={() => handleDeleteCategory(child.uuid)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                          >
                            <path
                              d="M20 7.5V6.5C20 5.09987 20 4.3998 19.7275 3.86502C19.4878 3.39462 19.1054 3.01217 18.635 2.77248C18.1002 2.5 17.4001 2.5 16 2.5H14C12.5999 2.5 11.8998 2.5 11.365 2.77248C10.8946 3.01217 10.5122 3.39462 10.2725 3.86502C10 4.3998 10 5.09987 10 6.5V7.5M3.75 7.5H26.25M23.75 7.5V21.5C23.75 23.6002 23.75 24.6503 23.3413 25.4525C22.9817 26.1581 22.4081 26.7317 21.7025 27.0913C20.9003 27.5 19.8502 27.5 17.75 27.5H12.25C10.1498 27.5 9.0997 27.5 8.29754 27.0913C7.59193 26.7317 7.01825 26.1581 6.65873 25.4525C6.25 24.6503 6.25 23.6002 6.25 21.5V7.5"
                              stroke="#CD0C0C"
                              strokeWidth="3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    )}

                    <button
                      className={`absolute top-[50%] translate-y-[-50%] right-5 hover:opacity-50 ${
                        editThemes ? 'opacity-0 scale-0' : 'opacity-100 scale-100'
                      } transition-button duration-300`}
                      onClick={() => navigate(`${child.uuid}`)}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <g clipPath="url(#clip0_2494_13684)">
                          <path
                            d="M7.49999 24.885L9.61499 27L21.615 15L9.61499 3L7.49999 5.115L17.37 15L7.49999 24.885Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2494_13684">
                            <rect width="30" height="30" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}
