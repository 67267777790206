import * as Dialog from '@radix-ui/react-dialog';

import appleStore from '@assets/appleStore.png';
import googlePlay from '@assets/googleplay.png';
import { useTranslation } from 'react-i18next';

interface ScreenNotAllowedProps {
  blockScreen?: boolean;
}

export default function ScreenNotAllowed({ blockScreen }: Readonly<ScreenNotAllowedProps>) {
  const { t } = useTranslation();

  console.log(blockScreen);

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '100',
        }}
      />
      <Dialog.Content
        forceMount
        className={
          'z-[1000] top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white p-10 flex flex-col items-center gap-5 rounded-lg font-poppins w-[70vw]'
        }
      >
        <h1 className="text-xl text-center font-semibold w-full">{t('maximum_screen_size_reached')}</h1>

        <>
          <span className="text-sm font-light text-center">{t('download_app_on_the_link_below')}</span>
          <div className="flex flex-wrap justify-center items-center gap-4 mt-2">
            <button
              onClick={() =>
                window.location.replace('https://play.google.com/store/apps/details?id=com.noiteminente.familywisdom')
              }
            >
              <img alt="googlePlay" src={googlePlay} className="w-[150px] h-[40px]" />
            </button>
            <button
              onClick={() =>
                window.location.replace('https://apps.apple.com/us/app/family-wisdom/id6475693830?platform=iphone')
              }
            >
              <img alt="appleStore" src={appleStore} className="w-[150px] h-[40px]" />
            </button>
          </div>
        </>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
