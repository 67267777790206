import { v4 as uuid } from 'uuid';
import { create } from 'zustand';
import { api } from '../services/axios';
import { showErrorMessage } from '../utils/auxFunctions';
import { Tag } from './useTags';

export interface InsightCreateDTO {
  title: string;
  description?: string;
  category_id: string;
  tags: string[];
  cover_photos: InsightCoverPhotosCreateDTO[];
  collections: InsightCollectionCreateDTO[];
  event?: InsightEventCreateDTO[];
  storage: string;
  group_id?: string;
}

export interface InsightCoverPhotosCreateDTO {
  url: string;
  type: string;
  duration?: string;
}

export interface InsightCollectionCreateDTO {
  title: string;
  description: string;
  files?: { url: string; type: string; duration?: string }[];
}

export interface InsightEventCreateDTO {
  title: string;
  description: string;
  start_time: string;
  end_time: string;
  location: string;
  recurring: string;
  category: string;
  notifications: string[];
}

export interface CollectionFile {
  url: string;
  type: string;
  duration?: string;
}

export interface MediaToEdit {
  url?: string;
  type?: string;
  duration?: string;
  uuid?: string;
  file?: File;
}

export interface Collection {
  uuid: string;
  title: string;
  description: string;
  order?: number;
  files: CollectionFile[];
  uploadedFiles: File[];
  coverPhoto: { url?: string; file?: File | null };
  collection_media?: CoverPhoto[];
  mediasToEdit?: MediaToEdit[];
}

interface User {
  uuid: string;
  first_name: string;
  last_name: string;
  image: string;
  role: string;
}

interface Category {
  id: number;
  uuid: string;
  name: string;
  parent_id: number | null;
}

interface Group {
  name: string;
  uuid: string;
}

export interface CoverPhoto {
  uuid: string;
  url: string;
  thumbnail800?: string;
  thumbnail1600?: string;
  order: number;
  type: string;
  duration: number | null;
  file?: File;
}

export interface Insight {
  uuid?: string;
  title: string;
  status?: string;
  views?: number;
  comments_count?: number;
  cover_photo_count?: number;
  collection_media_count?: number;
  reactions_count?: number;
  reactions_funny_count?: number;
  reactions_happy_count?: number;
  reactions_like_count?: number;
  reactions_sad_count?: number;
  elapsed_time?: string;
  favorite?: number;
  user?: User;
  category?: Category;
  description: string;
  category_id?: string;
  tags?: Tag[];
  group?: Group;
  cover_photo?: CoverPhoto[];
  cover_photo_image?: CoverPhoto[];
  collections: Collection[];
  user_reaction?: { uuid?: string; emoji?: string } | null;
  isNew?: boolean;
  storage?: number;

  type?: string;

  // ADS
  name?: string;
  profile_photo?: string;
  external_url?: string;
  aws_url?: string;
  aws_type?: string;
  max_clicks?: number;
  clicks?: number;
  max_views?: number;
  start_date?: string;
  end_date?: string;
  total_likes?: number;
  active?: number;
  reactions?: [
    { id: number; uuid: string; ad_id: number; user_id: number; emoji: string; created_at: string; updated_at: string },
  ];
}

export interface CreatingInsight {
  title: string;
  description: string;
  groupName?: string;
  groupUuid: string;
  tags: Tag[];
  collections: Collection[];
  category: {
    name?: string;
    uuid: string;
    subCategoryName?: string;
    subCategoryUuid: string;
    topicName?: string;
    topicUuid: string;
  };
  cover_photo?: CoverPhoto[];
  initialStorage?: number;
}

interface Pagination {
  current_page: number;
  last_page: number;
  total: number;
  last_page_url: string | null;
}

export interface ShareInsightProps {
  insight: Insight;
  insights: Insight[];
  creatingInsight: CreatingInsight;
  editingInsight: CreatingInsight;
  pagination: Pagination;
  editInsight: (props: Partial<CreatingInsight>, isEditing?: boolean) => void;
  editCollection: (props: Partial<Collection>, collectionId: string, isEditing?: boolean) => void;
  getInsightDetails: (groupUuid: string, insightUuid: string) => Promise<Insight | undefined>;
  getUserFeed: (first: boolean, params?: string) => Promise<any>;
  addInsightToFavorite: (groupUuid: string, insightUuid: string, favorite: boolean) => Promise<any>;
  getUserPosts: (first: boolean, params?: string) => Promise<any>;
  updateSpecificInsight: (groupUuid: string, insightUuid: string) => Promise<void>;
  getFavoriteInsights: (first: boolean, params?: string) => Promise<any>;
  getPostsByGroup: (groupUuid: string, first: boolean, params?: string) => Promise<any>;
  deleteInsight: (groupUuid: string, insightUuid: string) => Promise<void>;
  createNewInsight: (groupUuid: string, newInsight: InsightCreateDTO) => Promise<any>;
  clearCreatingInsight: () => void;
  clearEditingInsight: () => void;
  updateInsight: (groupUuid: string, insightUuid: string, newInsight: InsightCreateDTO) => Promise<any>;
  editCurrentInsight: (props: Partial<Insight>) => void;
  approvalInsight: (groupUuid: string, insightUuid: string, status: 'published' | 'refused') => Promise<any>;
  addReactionToPost: (postUuid: string, emoji: string, isChanging?: boolean, isModal?: boolean) => Promise<any>;
  deleteReactionFromPost: (postUuid: string, isModal?: boolean) => Promise<any>;
}

export const useInsights = create<ShareInsightProps>((set, get) => ({
  insight: {
    uuid: '',
    title: '',
    description: '',
    category_id: '',
    status: '',
    views: 0,
    comments_count: 0,
    cover_photo_count: 0,
    collection_media_count: 0,
    reactions_count: 0,
    reactions_funny_count: 0,
    reactions_happy_count: 0,
    reactions_like_count: 0,
    reactions_sad_count: 0,
    elapsed_time: '',
    favorite: 0,
    user: {
      uuid: '',
      first_name: '',
      last_name: '',
      image: '',
      role: '',
    },
    category: {
      id: 0,
      uuid: '',
      name: '',
      parent_id: 0,
    },
    tags: [],
    group: {
      name: '',
      uuid: '',
    },
    cover_photo: [],
    collections: [{ uuid: uuid(), title: '', description: '', files: [], coverPhoto: {}, uploadedFiles: [] }],
    user_reaction: {},
  },
  insights: [],
  feedInsightsOrAds: [],
  creatingInsight: {
    title: '',
    description: '',
    groupUuid: '',
    groupName: '',
    tags: [],
    category: { uuid: '', name: '', subCategoryUuid: '', topicUuid: '', topicName: '', subCategoryName: '' },
    collections: [{ uuid: uuid(), title: '', description: '', files: [], coverPhoto: {}, uploadedFiles: [] }],
  },

  editingInsight: {
    title: '',
    description: '',
    groupUuid: '',
    groupName: '',
    tags: [],
    category: { uuid: '', name: '', subCategoryUuid: '', topicUuid: '', topicName: '', subCategoryName: '' },
    collections: [{ uuid: uuid(), title: '', description: '', files: [], coverPhoto: {}, uploadedFiles: [] }],
  },

  pagination: {
    current_page: 1,
    last_page: 1,
    total: 1,
    last_page_url: null,
  },

  editInsight: (props: Partial<CreatingInsight>, isEditing?: boolean) => {
    if (isEditing) {
      for (const [key, value] of Object.entries(props)) {
        set((state) => ({ editingInsight: { ...state.editingInsight, [key]: value } }));
      }
    } else {
      for (const [key, value] of Object.entries(props)) {
        set((state) => ({ creatingInsight: { ...state.creatingInsight, [key]: value } }));
      }
    }
  },

  editCurrentInsight: (props: Partial<Insight>) => {
    for (const [key, value] of Object.entries(props)) {
      set((state) => ({ insight: { ...state.insight, [key]: value } }));
    }
  },

  editCollection: (props: Partial<Collection>, collectionId: string, isEditing?: boolean) => {
    if (isEditing) {
      for (const [key, value] of Object.entries(props)) {
        set((state) => ({
          editingInsight: {
            ...state.editingInsight,
            collections: state.editingInsight.collections.map((collection) =>
              collection.uuid === collectionId ? { ...collection, [key]: value } : collection,
            ),
          },
        }));
      }
    } else {
      for (const [key, value] of Object.entries(props)) {
        set((state) => ({
          creatingInsight: {
            ...state.creatingInsight,
            collections: state.creatingInsight.collections.map((collection) =>
              collection.uuid === collectionId ? { ...collection, [key]: value } : collection,
            ),
          },
        }));
      }
    }
  },

  getInsightDetails: async (groupUuid: string, insightUuid: string) => {
    try {
      const { data } = await api.get(`/groups/${groupUuid}/posts/${insightUuid}`);
      const insight: Insight = data.content.data;
      set({ insight });
      return insight;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  getUserFeed: async (first: boolean, params?: string) => {
    try {
      const { data } = await api.get(`/posts/feed` + (params ? params : ''));
      if (first) {
        set({ insights: data.content.data.data });
      } else {
        if (data?.content?.data?.data && data.content.data.data.length > 0) {
          set({ insights: [...get().insights, ...data.content.data.data] });
        }
      }
      set({
        pagination: {
          current_page: data.content.data.current_page,
          last_page: data.content.data.last_page,
          total: data.content.data.total,
          last_page_url: data.content.data.last_page_url,
        },
      });
      return data.content.data.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  addInsightToFavorite: async (groupUuid: string, insightUuid: string, favorite: boolean) => {
    try {
      const { data } = await api.put(`/groups/${groupUuid}/posts/${insightUuid}/favorite`, { favorite });
      return data?.content;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  getUserPosts: async (first: boolean, params?: string) => {
    try {
      const { data } = await api.get(`/posts/user` + (params ? params : ''));
      if (first) {
        set({ insights: data.content.data.data });
      } else {
        if (data?.content?.data?.data && data.content.data.data.length > 0) {
          set({ insights: [...get().insights, ...data.content.data.data] });
        }
      }
      set({
        pagination: {
          current_page: data.content.data.current_page,
          last_page: data.content.data.last_page,
          total: data.content.data.total,
          last_page_url: data.content.data.last_page_url,
        },
      });
      return data.content.data.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  updateSpecificInsight: async (groupUuid: string, insightUuid: string) => {
    try {
      const { data } = await api.get(`/groups/${groupUuid}/posts/${insightUuid}`);
      const newInsight: Insight = data.content.data;
      const newInsights = get().insights;
      set({ insight: newInsight });
      set({ insights: newInsights.map((insight) => (insight.uuid === insightUuid ? newInsight : insight)) });
    } catch (error) {
      showErrorMessage(error);
    }
  },

  getFavoriteInsights: async (first: boolean, params?: string) => {
    try {
      const { data } = await api.get(`/posts/favorites` + (params ? params : ''));
      if (first) {
        set({ insights: data.content.data.data });
      } else {
        if (data?.content?.data?.data && data.content.data.data.length > 0) {
          set({ insights: [...get().insights, ...data.content.data.data] });
        }
      }
      set({
        pagination: {
          current_page: data.content.data.current_page,
          last_page: data.content.data.last_page,
          total: data.content.data.total,
          last_page_url: data.content.data.last_page_url,
        },
      });
      return data.content.data.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  getPostsByGroup: async (groupUuid: string, first: boolean, params?: string) => {
    try {
      const { data } = await api.get(`/groups/${groupUuid}/posts` + (params ? params : ''));
      console.log(data);
      if (first) {
        set({ insights: data.content.data.data });
      } else {
        if (data?.content?.data?.data && data.content.data.data.length > 0) {
          set({ insights: [...get().insights, ...data.content.data.data] });
        }
      }
      set({
        pagination: {
          current_page: data.content.data.current_page,
          last_page: data.content.data.last_page,
          total: data.content.data.total,
          last_page_url: data.content.data.last_page_url,
        },
      });
      return data.content.data.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  deleteInsight: async (groupUuid: string, insightUuid: string) => {
    try {
      const { data } = await api.delete(`/groups/${groupUuid}/posts/${insightUuid}`);
      if (data.content.status === true) {
        const newInsights = get().insights.filter((insight) => insight.uuid !== insightUuid);
        set({ insights: newInsights });
      }
    } catch (error) {
      showErrorMessage(error);
    }
  },

  createNewInsight: async (groupUuid: string, newInsight: InsightCreateDTO) => {
    try {
      const { data } = await api.post(`/groups/${groupUuid}/posts`, newInsight);
      return data.content;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  clearCreatingInsight: () => {
    set({
      creatingInsight: {
        title: '',
        description: '',
        groupUuid: '',
        groupName: '',
        tags: [],
        category: { uuid: '', name: '', subCategoryUuid: '', topicUuid: '', topicName: '', subCategoryName: '' },
        collections: [{ uuid: uuid(), title: '', description: '', files: [], coverPhoto: {}, uploadedFiles: [] }],
      },
    });
  },

  clearEditingInsight: () => {
    set({
      editingInsight: {
        title: '',
        description: '',
        groupUuid: '',
        groupName: '',
        tags: [],
        category: { uuid: '', name: '', subCategoryUuid: '', topicUuid: '', topicName: '', subCategoryName: '' },
        collections: [{ uuid: uuid(), title: '', description: '', files: [], coverPhoto: {}, uploadedFiles: [] }],
      },
    });
  },

  updateInsight: async (groupUuid: string, insightUuid: string, newInsight: InsightCreateDTO) => {
    try {
      const { data } = await api.put(`/groups/${groupUuid}/posts/${insightUuid}`, newInsight);
      return data.content;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  approvalInsight: async (groupUuid: string, insightUuid: string, status: 'published' | 'refused') => {
    try {
      const { data } = await api.post(`/groups/${groupUuid}/posts/${insightUuid}/approval`, { status });
      return data.content.data;
    } catch (error) {
      showErrorMessage(error);
    }
  },

  addReactionToPost: async (postUuid: string, emoji: string, isChanging?: boolean, isModal?: boolean) => {
    const newInsights = get().insights;
    let newInsight = newInsights.find((insight) => insight.uuid === postUuid);
    if (isModal) {
      newInsight = get().insight;
    }
    if (!newInsight) return;
    newInsight.user_reaction = {
      uuid: '',
      emoji,
    };
    if (!isChanging) {
      if (emoji === 'like') newInsight.reactions_like_count = (newInsight?.reactions_like_count || 0) + 1;
      else if (emoji === 'funny') newInsight.reactions_funny_count = (newInsight?.reactions_funny_count || 0) + 1;
      else if (emoji === 'sad') newInsight.reactions_sad_count = (newInsight?.reactions_sad_count || 0) + 1;
      else if (emoji === 'happy') newInsight.reactions_happy_count = (newInsight?.reactions_happy_count || 0) + 1;
      newInsight.reactions_count = (newInsight?.reactions_count || 0) + 1;
    }
    set({ insight: newInsight });
    set({ insights: newInsights.map((insight) => (insight.uuid === postUuid ? newInsight! : insight)) });
    try {
      const { data } = await api.post(`/posts/${postUuid}/reactions`, { emoji });
      newInsight.user_reaction = data.content.data;
      set({ insight: newInsight });
      set({ insights: newInsights.map((insight) => (insight.uuid === postUuid ? newInsight! : insight)) });
      return data.content.data;
    } catch (error) {
      await get().updateSpecificInsight(newInsight?.group?.uuid || '', postUuid);
      return;
    }
  },

  deleteReactionFromPost: async (postUuid: string, isModal?: boolean) => {
    const newInsights = get().insights;
    let newInsight = newInsights.find((insight) => insight.uuid === postUuid);
    if (isModal) {
      newInsight = get().insight;
    }
    if (!newInsight) return;
    if (newInsight?.user_reaction?.emoji === 'like')
      newInsight.reactions_like_count = newInsight?.reactions_like_count ? newInsight?.reactions_like_count - 1 : 0;
    else if (newInsight?.user_reaction?.emoji === 'funny')
      newInsight.reactions_funny_count = newInsight?.reactions_funny_count ? newInsight?.reactions_funny_count - 1 : 0;
    else if (newInsight?.user_reaction?.emoji === 'sad')
      newInsight.reactions_sad_count = newInsight?.reactions_sad_count ? newInsight?.reactions_sad_count - 1 : 0;
    else if (newInsight?.user_reaction?.emoji === 'happy')
      newInsight.reactions_happy_count = newInsight?.reactions_happy_count ? newInsight?.reactions_happy_count - 1 : 0;
    newInsight.reactions_count = newInsight?.reactions_count ? newInsight?.reactions_count - 1 : 0;
    newInsight.user_reaction = null;
    set({ insight: newInsight });
    set({ insights: newInsights.map((insight) => (insight.uuid === postUuid ? newInsight! : insight)) });
    try {
      const { data } = await api.post(`/posts/${postUuid}/reactions`, { emoji: 'remove' });
      return data.content.data;
    } catch (error) {
      await get().updateSpecificInsight(newInsight?.group?.uuid || '', postUuid);
      return;
    }
  },
}));
