export function handleMessageError(error: any) {
    const errors = error.response.data.errors;
    if (errors) {
      const message = Object.keys(errors)[0];
      console.log(message);
      return errors[message];
    }
  
    const message = error.response.data.message;
    console.log(message);
    return message;
  }