import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGroups } from '@hooks/useGroups';
import {
  InsightCollectionCreateDTO,
  InsightCoverPhotosCreateDTO,
  InsightCreateDTO,
  useInsights,
} from '@hooks/useInsights';

import Loading from '@components/Loading';
import ShareInsightPost from '@components/ShareInsightPost';

import { base64ToFile, retrieveImgS3 } from 'utils/auxFunctions';
import { uploadToS3 } from 'utils/awsS3';
import { toast } from 'utils/toast';

import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';

export default function ShareInsightFinish() {
  const { creatingInsight, createNewInsight, clearCreatingInsight } = useInsights();
  const { checkStorage } = useGroups();
  const [isInsightValid, setIsInsightValid] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    if (
      creatingInsight.title &&
      creatingInsight.collections.length > 0 &&
      creatingInsight.groupUuid &&
      creatingInsight.category
    )
      setIsInsightValid(true);
    else return;

    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = ''; // Define uma mensagem vazia para o navegador (não mostrando mensagem padrão)

      // Exibe a mensagem de notificação personalizada
      const confirmationMessage = 'Are you sure you want to leave? All unsaved data will be lost.';
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    // Adiciona o ouvinte de evento ao carregar o componente
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove o ouvinte de evento ao descarregar o componente
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [creatingInsight]);

  async function handlePublishInsight() {
    setIsLoading(true);
    const insightToPublish: InsightCreateDTO = {
      category_id: '',
      cover_photos: [],
      description: '',
      title: '',
      tags: [],
      collections: [],
      storage: '',
    };
    const tags: string[] = [];
    let storageBytes = 0;

    insightToPublish.title = creatingInsight.title;
    insightToPublish.description = creatingInsight.description;
    insightToPublish.category_id =
      (creatingInsight.category?.topicUuid
        ? creatingInsight.category.topicUuid
        : creatingInsight.category.subCategoryUuid
          ? creatingInsight.category.subCategoryUuid
          : creatingInsight.category.uuid) || '';

    creatingInsight.tags.forEach((tag) => tags.push(tag.uuid));

    insightToPublish.tags = tags;

    creatingInsight.collections.map((c) => c.uploadedFiles.map((f) => (storageBytes += f.size)));

    if (storageBytes === 0) storageBytes += 1;

    insightToPublish.storage = storageBytes.toString();

    const hasSpace = await checkStorage(creatingInsight.groupUuid, storageBytes);
    if (!hasSpace) {
      toast({
        label: t('error'),
        message: t('insufficient_storage'),
        type: 'error',
      });
      return;
    }

    try {
      await Promise.all(
        creatingInsight.collections.map(async (collection) => {
          let imgURL = '';
          const cover_photo: InsightCoverPhotosCreateDTO = {} as InsightCoverPhotosCreateDTO;
          const collectionToCreate: InsightCollectionCreateDTO = {
            description: '',
            files: [],
            title: '',
          };

          collectionToCreate.title = collection.title;
          collectionToCreate.description = collection.description;

          if (collection?.coverPhoto.file) {
            if (collection.coverPhoto.file?.type.includes('video')) {
              const videoThumbnails = await generateVideoThumbnails(collection.coverPhoto.file, 1, 'png');
              const file = base64ToFile(videoThumbnails[0]);
              const { key, error } = await uploadToS3(file);
              if (error) {
                toast({
                  label: t('error'),
                  message: t('failed_to_upload_image'),
                  type: 'error',
                });
              } else {
                if (key) imgURL = retrieveImgS3(key);
                cover_photo.url = imgURL;
                cover_photo.type = 'image';
              }
            } else {
              const { key, error } = await uploadToS3(collection.coverPhoto.file);
              if (error) {
                toast({
                  label: t('error'),
                  message: t('failed_to_upload_image'),
                  type: 'error',
                });
              } else {
                if (key) imgURL = retrieveImgS3(key);
                cover_photo.url = imgURL;
                cover_photo.type = 'image';
              }
            }

            if (!collection.coverPhoto.file?.type.includes('audio')) {
              insightToPublish.cover_photos.push(cover_photo);
            }
          }

          if (collection?.uploadedFiles && collection?.uploadedFiles.length > 0) {
            await Promise.all(
              collection?.uploadedFiles.map(async (file) => {
                const { key, error } = await uploadToS3(file);
                if (error) {
                  toast({
                    label: t('error'),
                    message: t('failed_to_upload_image'),
                    type: 'error',
                  });
                } else {
                  if (key) imgURL = retrieveImgS3(key);
                  if (file.type.includes('video')) {
                    const video = document.createElement('video');
                    video.preload = 'metadata';

                    const loadedMetadataPromise = new Promise((resolve) => {
                      video.onloadedmetadata = () => {
                        const videoDuration = video.duration;
                        resolve(videoDuration);
                      };
                    });

                    video.src = URL.createObjectURL(file);
                    let duration: any = await loadedMetadataPromise;

                    if (duration === Infinity || Number.isNaN(duration)) {
                      duration = 0;
                    }

                    collectionToCreate.files!.push({
                      url: imgURL,
                      type: 'video',
                      duration: (Math.round(duration) * 1000)?.toString() || '',
                    });
                  } else if (file.type.includes('audio')) {
                    const audio = document.createElement('audio');
                    audio.preload = 'metadata';

                    const loadedMetadataPromise = new Promise((resolve) => {
                      audio.onloadedmetadata = () => {
                        const audioDuration = audio.duration;
                        resolve(audioDuration);
                      };
                    });

                    audio.src = URL.createObjectURL(file);
                    let duration: any = await loadedMetadataPromise;

                    if (duration === Infinity || Number.isNaN(duration)) {
                      duration = 0;
                    }

                    insightToPublish.cover_photos.push({
                      url: imgURL,
                      type: 'audio',
                      duration: (Math.round(duration) * 1000)?.toString() || '',
                    });

                    collectionToCreate.files!.push({
                      url: imgURL,
                      type: 'audio',
                      duration: (Math.round(duration) * 1000)?.toString() || '',
                    });
                  } else {
                    collectionToCreate.files!.push({
                      url: imgURL,
                      type: 'image',
                    });
                  }
                }
              }),
            );
          }
          if (!collection?.uploadedFiles || collection?.uploadedFiles.length === 0) {
            delete collectionToCreate.files;
          }
          insightToPublish.collections.push(collectionToCreate);
        }),
      );
      const response = await createNewInsight(creatingInsight.groupUuid, insightToPublish);
      if (response) {
        toast({
          label: t('success'),
          message: t('insight_success'),
          type: 'success',
        });
        clearCreatingInsight();
        navigate('/feed');
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast({
        label: t('error'),
        message: t('insight_error'),
        type: 'error',
      });
      setIsLoading(false);
    }
  }

  return (
    <>
      {loading || !isInsightValid ? (
        <Loading />
      ) : (
        <div className="flex justify-center w-full bg-backgray min-h-[calc(100vh-88px)] py-12 relative">
          <div className="mb-20">
            <ShareInsightPost insight={creatingInsight} />
          </div>

          <footer className="flex items-center justify-center gap-24 fixed bottom-0 right-0 bg-white w-full py-5">
            <button
              onClick={() => navigate('/insight/create?isEditing=true')}
              className="py-2 px-10 border border-primary-200 rounded-md shadow-md h-10 hover:opacity-70"
            >
              {t('edit')}
            </button>
            <button
              onClick={handlePublishInsight}
              data-test="create-insight-post"
              className="py-2 px-10 bg-primary-200 text-white rounded-md shadow-md font-semibold h-10 hover:opacity-70"
            >
              {t('post')}
            </button>
          </footer>
        </div>
      )}
    </>
  );
}
