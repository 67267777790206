import { useUser } from '@hooks/useUser';
import { Navigate, Outlet } from 'react-router-dom';

export default function PublicRoutes() {
  const { verifyUser } = useUser();
  const isUserVerify = verifyUser();

  if (isUserVerify === 'feed') return <Navigate to="/feed" />;
  else if (isUserVerify === 'inviteLink') return <Navigate to={`/invite/${localStorage.getItem('inviteLink')}`} />;

  return <Outlet />;
}
