import { useTranslation } from 'react-i18next';

type Props = {
  userLanguage?: string;
};

export function PasswordInfo({ userLanguage }: Readonly<Props>) {
  const { t } = useTranslation();
  return (
    <div className="text-primary-200 text-xs font-semibold flex flex-col gap-1">
      {userLanguage ? (
        <>
          <span>
            {userLanguage === 'pt'
              ? 'A senha deve ter pelo menos 8 dígitos'
              : 'The password must have at least 8 digits'}
          </span>
          <span>
            {userLanguage === 'pt'
              ? 'A senha deve conter pelo menos uma letra maiúscula e um número'
              : 'The password must have at least 1 uppercase letter and 1 number'}
          </span>
          <span>
            {userLanguage === 'pt'
              ? 'A senha deve conter pelo menos um dos seguintes caracteres ?!@$%^&*-'
              : 'The password must have one of theses special characters ?!@$%^&*-'}
          </span>
        </>
      ) : (
        <>
          <span>{t('password_info_a')}</span>
          <span>{t('password_info_b')}</span>
          <span>{t('password_info_c')}</span>
        </>
      )}
    </div>
  );
}
