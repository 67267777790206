import * as Dialog from '@radix-ui/react-dialog';
import { useCallback, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from '@components/Button';

interface TermsOfUseModalProps {
  handleAcceptTermsOfUse: () => Promise<void>;
}

export default function TermsOfUseModal({ handleAcceptTermsOfUse }: TermsOfUseModalProps) {
  const { t } = useTranslation();

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [error, setError] = useState('');

  const url = t('terms_of_use_link');
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handleConfirmTerms = useCallback(() => {
    if (!acceptTerms) {
      setError(t('you_need_to_accept_the_terms'));
      return;
    }
    handleAcceptTermsOfUse();
  }, [acceptTerms]);

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '100',
        }}
      />
      <Dialog.Content
        forceMount
        className={
          'z-[1000] top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white p-10 flex flex-col items-center gap-5 rounded-lg font-poppins w-[70vw]'
        }
      >
        <iframe ref={iframeRef} src={url} style={{ width: '100%', height: '70vh' }}></iframe>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            data-test="terms-checkbox"
            checked={acceptTerms}
            onChange={() => setAcceptTerms(!acceptTerms)}
          />
          <span className="text-sm font-bold text-primary-200">{t('accept_terms')}</span>
        </div>
        {error && <span className="text-xs text-red-500 mt-[-15px]">*{error}</span>}
        <Button
          size="medium"
          data-test="terms-submit"
          variant="primary"
          text={t('confirm')}
          onClick={handleConfirmTerms}
        />
      </Dialog.Content>
    </Dialog.Portal>
  );
}
