import { Button } from '@components/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function NoNotifications() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center gap-5">
      <svg xmlns="http://www.w3.org/2000/svg" width="150" height="151" viewBox="0 0 150 151" fill="none">
        <path
          d="M75 150.664C116.421 150.664 150 117.085 150 75.6641C150 34.2427 116.421 0.664062 75 0.664062C33.5786 0.664062 0 34.2427 0 75.6641C0 117.085 33.5786 150.664 75 150.664Z"
          fill="#282A74"
          fillOpacity="0.1"
        />
        <path
          d="M120 150.664H30V53.6641C34.242 53.6593 38.3089 51.9721 41.3084 48.9725C44.308 45.973 45.9952 41.9061 46 37.6641H104C103.996 39.7654 104.408 41.8468 105.213 43.7878C106.018 45.7288 107.2 47.4909 108.691 48.972C110.172 50.4631 111.934 51.6457 113.875 52.451C115.817 53.2564 117.898 53.6687 120 53.6641V150.664Z"
          fill="white"
        />
        <path
          d="M75 102.664C88.2548 102.664 99 91.9189 99 78.6641C99 65.4092 88.2548 54.6641 75 54.6641C61.7452 54.6641 51 65.4092 51 78.6641C51 91.9189 61.7452 102.664 75 102.664Z"
          fill="#282A74"
        />
        <path
          d="M83.4853 89.9779L75 81.4927L66.5147 89.9779L63.6863 87.1495L72.1716 78.6642L63.6863 70.1789L66.5147 67.3505L75 75.8358L83.4853 67.3505L86.3137 70.1789L77.8284 78.6642L86.3137 87.1495L83.4853 89.9779Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M88 108.664H62C60.3431 108.664 59 110.007 59 111.664C59 113.321 60.3431 114.664 62 114.664H88C89.6569 114.664 91 113.321 91 111.664C91 110.007 89.6569 108.664 88 108.664Z"
          fill="#902687"
        />
        <path
          opacity="0.2"
          d="M97 120.664H53C51.3431 120.664 50 122.007 50 123.664C50 125.321 51.3431 126.664 53 126.664H97C98.6569 126.664 100 125.321 100 123.664C100 122.007 98.6569 120.664 97 120.664Z"
          fill="#902687"
        />
      </svg>
      <div className="flex flex-col items-center">
        <h3 className="font-medium text-black-200">{t('no_notifications')}</h3>
        <span className="text-sm text-black-200">{t('no_notifications_yet')}</span>
      </div>
      <Button
        onClick={() => navigate('/profile/notifications-settings')}
        size="medium"
        text={t('manage_notifications')}
        variant="primary"
        boxShadow
      />
    </div>
  );
}
