import { CalendarUser } from '@hooks/useCalendar';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { calendarBuild } from '..';
import DayCard from '../DayCard';

interface MonthCardProps {
  month: string;
  currentYear: number;
  selectedDate?: string;
  setDate?: React.Dispatch<React.SetStateAction<string>>;
  type: 'datePicker' | 'eventPicker';
  userCalendar?: CalendarUser | object;
  onDateSelect: (day: string) => void;
}

export default function MonthCard({
  month,
  currentYear,
  selectedDate,
  setDate,
  type,
  userCalendar,
  onDateSelect,
}: Readonly<MonthCardProps>) {
  const { t } = useTranslation();
  const [value, setValue] = useState<moment.Moment>(moment().locale('en').month(month).year(currentYear));
  const [calendar, setCalendar] = useState<moment.Moment[][]>([]);
  const weekDays = [t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat'), t('sun')];

  function handleDateSelect(day: string) {
    onDateSelect(day);
  }

  useEffect(() => {
    setValue(value.month(month).year(currentYear));
    setCalendar(calendarBuild(value));
  }, [value, month, currentYear]);

  return (
    <div className="h-full">
      <div className="grid grid-cols-7 mb-2">
        {weekDays.map((value, index) => {
          if (value === 'Sat' || value === 'Sun' || value === 'Sáb' || value === 'Dom') {
            return (
              <div className="text-xs text-primary-100 mb-2 text-center w-[50px]" key={index}>
                {value}
              </div>
            );
          }
          return (
            <div className="text-xs text-primary-200 mb-2 text-center w-[50px]" key={index}>
              {value}
            </div>
          );
        })}
      </div>
      {calendar.map((week, index) => (
        <div className="grid grid-cols-7" key={week.toString()}>
          {week.map((day) => {
            return (
              <DayCard
                key={day.unix()}
                calendarIndex={index}
                day={day}
                type={type}
                setDate={setDate}
                selectedDate={selectedDate}
                userCalendar={userCalendar}
                onDateSelect={handleDateSelect}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
}
