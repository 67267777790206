import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { routes } from './routes/app.routes';

import ScreenNotAllowed from '@components/ScreenNotAllowed';
import ToastContainer from '@components/Toast/ToastContainer';

import { NotificationProvider } from '../src/contexts/NotificationContext';
import { onMessageListener } from '../src/utils/firebase';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then(() => {})
    .catch((error) => {
      console.error('Erro ao registrar Service Worker:', error);
    });
}

function App() {
  const [notification, setNotification] = useState({ title: '', body: '', urlImage: '' });
  notification;
  const [blockScreen, setBlockScreen] = useState<boolean>(false);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState<{ width: number | undefined; height: number | undefined }>({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
  }

  const size = useWindowSize();

  useEffect(() => {
    if (size.width && size.width < 768) {
      if (window.location.pathname.startsWith('/invite/')) {
        setBlockScreen(false);
      } else if (window.location.pathname.startsWith('/password/')) {
        setBlockScreen(false);
      } else {
        setBlockScreen(true);
      }
    } else {
      setBlockScreen(false);
    }
  }, [size]);

  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        setNotification({
          title: payload?.notification?.title,
          body: payload?.notification?.body,
          urlImage: payload?.data?.url_image,
        });
      })
      .catch((err) => console.log('failed: ', err));
  }, []);

  return (
    <NotificationProvider>
      <ToastContainer />
      <Dialog.Root open={blockScreen}>
        <ScreenNotAllowed blockScreen={blockScreen} />
      </Dialog.Root>
      <RouterProvider router={routes} />
    </NotificationProvider>
  );
}

export default App;
