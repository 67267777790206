import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as z from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';

import { Button } from '@components/Button';
import { Input } from '@components/Input';

import logo from '@assets/logo.svg';

import { toast } from 'utils/toast';
import { api } from '../../../../services/axios';

import { PasswordInfo } from '@components/Auth/PasswordInfo';

const passwordResetSchema = z.object({
  password: z.string(),
  confirmPassword: z.string(),
});

type passwordResetInputs = z.infer<typeof passwordResetSchema>;

export default function PasswordReset() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<passwordResetInputs>({
    resolver: zodResolver(passwordResetSchema),
  });
  const [userLanguage, setUserLanguage] = useState<string>('en');

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const emailOrPhone = searchParams.get('email_or_phone');
  const navigate = useNavigate();

  useEffect(() => {
    const browserLanguage = navigator.language;

    if (browserLanguage.includes('pt')) {
      setUserLanguage('pt');
    } else {
      setUserLanguage('en');
    }
  }, []);

  async function handleChangePassword(data: passwordResetInputs) {
    const { password, confirmPassword } = data;
    if (password !== confirmPassword) {
      toast({
        label: 'Error',
        message: userLanguage === 'pt' ? 'Senha não corresponde' : "Password don't match",
        type: 'error',
      });
      return;
    }
    try {
      await api.post('/auth/reset-password', {
        password: password,
        password_confirmation: confirmPassword,
        email_or_phone: emailOrPhone,
        token: token,
      });
      toast({
        label: 'Success',
        message: userLanguage === 'pt' ? 'Conta atualizada com sucesso' : 'Account updated successfully',
        type: 'success',
      });
      navigate('/login');
    } catch (error: any) {
      Object.values(error?.response?.data?.errors).forEach((fieldMessages) => {
        (fieldMessages as string[])?.map((message: string) => {
          console.log(message == 'The password format is invalid.');
          if (message == 'The password format is invalid.') {
            toast({
              label: userLanguage === 'pt' ? 'Erro' : 'Error',
              message: userLanguage === 'pt' ? 'A formato da senha é inválido.' : 'The password format is invalid.',
              type: 'error',
            });
            return;
          }

          toast({
            label: 'Error',
            message: message,
            type: 'error',
          });
        });
      });
      console.error(error);
    }
  }

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <main className="h-[80vh]">
        <form
          onSubmit={handleSubmit(handleChangePassword)}
          className="flex flex-col justify-center items-center w-full max-w-[400px] max-h-screen px-[50px] pt-[70px] pb-[50px] bg-white shadow-md border border-gray-100 gap-24"
        >
          <img className="shadow-md rounded-lg" width={48} height={48} src={logo} alt="Logo" />
          <div className="text-start flex flex-col w-full">
            <h1 className="text-primary-200 text-xl font-semibold mb-5">
              {userLanguage === 'pt' ? 'Digite uma nova senha' : 'Create a new password'}
            </h1>
            <div className="flex flex-col w-full gap-5">
              <Input
                {...register('password')}
                type="password"
                identificator="password"
                data-test="reset-password-new-password"
                label={userLanguage === 'pt' ? 'Nova senha' : 'New password'}
                required
              />
              <Input
                {...register('confirmPassword')}
                type="password"
                identificator="confirmPassword"
                data-test="reset-password-confirm-password"
                label={userLanguage === 'pt' ? 'Confirmar senha' : 'Confirm password'}
                required
              />
              <PasswordInfo userLanguage={userLanguage} />
            </div>
          </div>
          <span className="flex flex-col w-full items-center justify-center">
            <Button
              disabled={isSubmitting}
              type="submit"
              data-test="reset-password-submit"
              text={userLanguage === 'pt' ? 'Alterar senha' : 'Change password'}
              size="large"
              variant="primary"
            />
          </span>
        </form>
      </main>
    </div>
  );
}
