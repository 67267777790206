import AdsDescriptionBox from '@components/AdsDescriptionBox';

import { useAds } from '@hooks/useAds';
import { useTranslation } from 'react-i18next';

export default function AdsPrimaryInformation() {
  const { t } = useTranslation();
  const { ads } = useAds();

  return (
    <div className="flex flex-col pt-5 pb-3 px-3 bg-white rounded-md relative">
      {!ads.url ? (
        <>
          <p className="text-lg font-medium text-grey-200 px-3">
            {t('select_a_photo_video_or_add_a_description_for_your_commercial')}
          </p>
          <div
            aria-hidden
            className="w-full h-[232px] border border-grey-600 flex flex-col gap-2 items-center justify-center cursor-pointer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
              <g opacity="0.6">
                <path
                  d="M19.1663 3.02539V8.53278C19.1663 9.27951 19.1663 9.65288 19.3117 9.9381C19.4395 10.189 19.6435 10.393 19.8944 10.5208C20.1796 10.6661 20.5529 10.6661 21.2997 10.6661H26.8071M12.4997 19.9993L16.4997 23.9993M16.4997 23.9993L20.4997 19.9993M16.4997 23.9993L16.4997 15.9993M19.1663 2.66602H12.233C9.9928 2.66602 8.87269 2.66602 8.01705 3.10199C7.2644 3.48548 6.65248 4.09741 6.26898 4.85005C5.83301 5.7057 5.83301 6.82581 5.83301 9.06602V22.9327C5.83301 25.1729 5.83301 26.293 6.26898 27.1486C6.65248 27.9013 7.2644 28.5132 8.01705 28.8967C8.87269 29.3327 9.9928 29.3327 12.233 29.3327H20.7663C23.0066 29.3327 24.1267 29.3327 24.9823 28.8967C25.735 28.5132 26.3469 27.9013 26.7304 27.1486C27.1663 26.293 27.1663 25.1729 27.1663 22.9327V10.666L19.1663 2.66602Z"
                  stroke="#282A74"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            <p className="text-sm text-grey-200 text-opacity-60">{t('your_files_will_appear_here')}</p>
          </div>
        </>
      ) : (
        <div className="flex flex-col gap-8 items-center mt-8" aria-hidden>
          <span className="w-[150px] h-[150px] relative">
            {ads?.fileType == 'image' && (
              <img
                alt="Preview"
                src={ads.url}
                className="w-full h-full max-h-full max-w-full object-cover pointer-events-none"
              />
            )}
            {ads?.fileType == 'video' && (
              <video controls src={ads.url} className="w-full h-full max-h-full max-w-full object-cover" />
            )}
          </span>
        </div>
      )}

      {ads.description && <AdsDescriptionBox readonly />}
    </div>
  );
}
