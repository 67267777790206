import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Notification, useNotification } from '@hooks/useNotification';

import NoNotifications from '@components/EmptyStates/NoNotifications';

import groupDefaultPhoto from '@assets/group-default.png';
import checkPrimary from '@assets/icons/Button/check-primary.svg';
import loadingGif from '@assets/icons/loading.gif';
import defaultUserPhoto from '@assets/profiles/Ellipse 9.svg';

import { useNotifications } from 'contexts/NotificationContext';

import ImageViewer from '@components/ImageViewer';
import { getDefaultImages } from 'utils/auxFunctions';
import { handleMessageError } from '../../utils/handleMessageError';

export default function Notifications() {
  const { t } = useTranslation();
  const { getAllNotifications, markAsRead, markAllAsRead, pagination } = useNotification();
  const { markNotificationAsRead, markAllAsReadNotifications } = useNotifications();

  const [currentTab, setCurrentTab] = useState<'new' | 'cleared'>('new');
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingNotifications, setLoadingNotifications] = useState(false);

  const activeTab = 'text-sm font-medium text-primary-200 border-b-2 border-primary-200 py-3';
  const inactiveTab = 'text-sm font-medium text-primary-200 opacity-60 py-3';

  const handleGetAllNotifications = useCallback(
    async (read?: number) => {
      setIsLoading(true);
      setNotifications([]);
      try {
        const { notifications } = await getAllNotifications(
          `take=20&sortBy=created_at&sortDirection=desc&read=${read || 0}`,
        );
        setNotifications(notifications);
      } catch (err) {
        const message = handleMessageError(err);
        console.log(message);
      } finally {
        setIsLoading(false);
      }
    },
    [getAllNotifications],
  );

  useEffect(() => {
    handleGetAllNotifications(currentTab === 'new' ? 0 : 1);
  }, [currentTab, handleGetAllNotifications]);

  useEffect(() => {
    const onScroll = async () => {
      if (
        window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight &&
        !loadingNotifications &&
        pagination.current_page < pagination.last_page
      ) {
        setLoadingNotifications(true);
        try {
          const read = currentTab === 'new' ? 0 : 1;
          const { notifications } = await getAllNotifications(
            `take=20&sortBy=created_at&sortDirection=desc&read=${read || 0}&page=${pagination.current_page + 1}`,
          );
          setNotifications((prev) => [...prev, ...notifications]);
          setLoadingNotifications(false);
        } catch (err) {
          const message = handleMessageError(err);
          console.log(message);
        }
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [currentTab, getAllNotifications, loadingNotifications, pagination]);

  async function handleMarkAsRead(notification: Notification) {
    try {
      await markAsRead(notification.uuid);
      setNotifications((state) => state.filter((item) => item.uuid !== notification.uuid));
      markNotificationAsRead();
    } catch (err) {
      const message = handleMessageError(err);
      console.error(message);
    }
  }

  async function handleMarkAllAsRead() {
    try {
      await markAllAsRead();
      setNotifications([]);
      markAllAsReadNotifications();
    } catch (err) {
      const message = handleMessageError(err);
      console.error(message);
    }
  }

  return (
    <>
      <header className="relative flex flex-col items-center w-full">
        <div className="grid grid-cols-2 w-full mt-14">
          <button onClick={() => setCurrentTab('new')} className={currentTab === 'new' ? activeTab : inactiveTab}>
            {t('new')}
          </button>
          <button
            onClick={() => setCurrentTab('cleared')}
            className={currentTab === 'cleared' ? activeTab : inactiveTab}
          >
            {t('cleared')}
          </button>
        </div>
      </header>
      <main className="flex flex-col items-center w-full max-w-xl mx-auto bg-white py-10 px-2 min-h-[70vh] gap-5 relative">
        {isLoading ? (
          <div>
            <img src={loadingGif} alt="Loading" className="w-12" />
          </div>
        ) : notifications.length === 0 ? (
          <div className="py-10">
            <NoNotifications />
          </div>
        ) : (
          <>
            {currentTab === 'new' && (
              <div className="w-full flex justify-end items-center">
                <button
                  onClick={handleMarkAllAsRead}
                  className="text-sm text-primary-200 hover:opacity-60 font-light cursor-pointer"
                >
                  {t('read_all')}
                </button>
              </div>
            )}

            {notifications.map((notification) => (
              <div key={notification.uuid} className="flex justify-between gap-7 items-center w-full">
                {/* <Link to={`/groups/${notification.group?.uuid}/insight/${notification.post?.uuid}`}> */}
                {notification.type === 'reaction' ||
                notification.type === 'comment' ||
                notification.type === 'post-published-admin' ||
                notification.type === 'post-pending' ? (
                  <Link to={`/groups/${notification.group?.uuid}/insight/${notification.post?.uuid}`}>
                    <div className="flex items-center gap-4">
                      <ImageViewer
                        className="min-w-12 min-h-12 max-w-12 max-h-12 rounded-full"
                        url={notification.image}
                        previewImage={defaultUserPhoto}
                        alt="User Profile"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = getDefaultImages('group-default-1.png');
                        }}
                      />
                      <p className="text-sm text-gray-600 font-light">{notification.description}</p>
                    </div>
                  </Link>
                ) : notification.type === 'event' ? (
                  <Link to={`/events/${notification.event?.uuid}`}>
                    <div className="flex items-center gap-4">
                      <ImageViewer
                        className="min-w-12 min-h-12 max-w-12 max-h-12 rounded-full"
                        url={notification.image}
                        previewImage={defaultUserPhoto}
                        alt="User Profile"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = getDefaultImages('group-default-1.png');
                        }}
                      />
                      <p className="text-sm text-gray-600 font-light">{notification.description}</p>
                    </div>
                  </Link>
                ) : notification.type === 'group-join' ? (
                  <Link to={`/groups/${notification.group?.uuid}`}>
                    <div className="flex items-center gap-4">
                      <ImageViewer
                        className="min-w-12 min-h-12 max-w-12 max-h-12 rounded-full"
                        url={notification.group?.image || ''}
                        previewImage={groupDefaultPhoto}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = getDefaultImages('group-default-1.png');
                        }}
                      />
                      <p className="text-sm text-gray-600 font-light">{notification.description}</p>
                    </div>
                  </Link>
                ) : notification.type === 'group-exit' ? (
                  <div className="flex items-center gap-4">
                    <ImageViewer
                      className="min-w-12 min-h-12 max-w-12 max-h-12 rounded-full"
                      url={notification.group?.image || ''}
                      previewImage={groupDefaultPhoto}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = getDefaultImages('group-default-1.png');
                      }}
                    />
                    <p className="text-sm text-gray-600 font-light">{notification.description}</p>
                  </div>
                ) : (
                  <div className="flex items-center gap-4">
                    <img
                      alt="notification"
                      className="min-w-12 min-h-12 max-w-12 max-h-12 rounded-full"
                      src={notification.image}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = getDefaultImages('group-default-1.png');
                      }}
                    />
                    <p className="text-sm text-gray-600 font-light">{notification.description}</p>
                  </div>
                )}
                {currentTab === 'new' && (
                  <button onClick={() => handleMarkAsRead(notification)}>
                    <img
                      src={checkPrimary}
                      alt="Cleared Notification"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = getDefaultImages('group-default-1.png');
                      }}
                    />
                  </button>
                )}
              </div>
            ))}
          </>
        )}
        {loadingNotifications && (
          <img src={loadingGif} alt="Loading" className="w-12 absolute bottom-[-20px] left-[50%] -translate-x-[50%]" />
        )}
      </main>
    </>
  );
}
