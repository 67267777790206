import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useInsights } from '@hooks/useInsights';

import CollectionCard from '@components/CollectionCard';
import CollectionEditModeCard from '@components/CollectionEditModeCard';

import { useTranslation } from 'react-i18next';
import { toast } from 'utils/toast';
import { v4 as uuid } from 'uuid';

const MAX_COLLECTIONS = 10;

export default function ShareInsight() {
  const { t } = useTranslation();
  const { creatingInsight, editInsight, clearCreatingInsight } = useInsights();
  const [editMode, setEditMode] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isEditing = searchParams.get('isEditing');

  useEffect(() => {
    if (!isEditing) clearCreatingInsight();
    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = ''; // Define uma mensagem vazia para o navegador (não mostrando mensagem padrão)

      // Exibe a mensagem de notificação personalizada
      const confirmationMessage = t('are_you_sure_you_want_to_leave_all_unsaved_data_will_be_lost');
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    // Adiciona o ouvinte de evento ao carregar o componente
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove o ouvinte de evento ao descarregar o componente
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [clearCreatingInsight, isEditing, t]);

  function handleAddNewCollection() {
    if (creatingInsight.collections.length === MAX_COLLECTIONS) {
      toast({
        label: t('error'),
        message: t('you_can_only_have_10_collections'),
        type: 'error',
      });
      return;
    }
    for (let i = 0; i < creatingInsight.collections.length; i++) {
      if (
        creatingInsight.collections[i].description === '' &&
        creatingInsight.collections[i].uploadedFiles.length === 0
      ) {
        toast({
          label: t('error'),
          message: t('one_or_more_collections_have_no_description_or_files'),
          type: 'error',
        });
        return;
      }
    }
    editInsight({
      collections: [
        ...creatingInsight.collections,
        { uuid: uuid(), title: '', description: '', files: [], coverPhoto: {}, uploadedFiles: [] },
      ],
    });
  }

  function handleNextStep() {
    if (creatingInsight.title === '') {
      toast({
        label: t('error'),
        message: t('the_insight_title_cannot_be_empty'),
        type: 'error',
      });
      return;
    }
    let collectionsWithCoverPhoto = 0;
    let collectionsOnlyWithDescription = 0;
    let collectionsWithoutTitle = 0;
    for (let i = 0; i < creatingInsight.collections.length; i++) {
      const collection = creatingInsight.collections[i];
      if (collection.description === '' && collection.uploadedFiles.length === 0) {
        toast({
          label: t('error'),
          message: t('one_or_more_collections_have_no_description_or_files'),
          type: 'error',
        });
        return;
      }
      const uploadedFilesWithoutAudio = collection.uploadedFiles.filter((uploaded) => !uploaded.type.includes('audio'));
      if (collection.coverPhoto.file) {
        collectionsWithCoverPhoto++;
      }
      if ((collection.uploadedFiles.length === 0 && collection.description) || uploadedFilesWithoutAudio.length === 0) {
        collectionsOnlyWithDescription++;
      }
      if (collection.title === '') {
        collectionsWithoutTitle++;
      }
    }

    if (collectionsWithCoverPhoto < 1 && collectionsOnlyWithDescription < creatingInsight.collections.length) {
      toast({
        label: t('error'),
        message: t('at_least_one_of_the_collections_must_have_a_cover_photo'),
        type: 'error',
      });
      return;
    }
    if (collectionsWithCoverPhoto > 3) {
      toast({
        label: t('error'),
        message: t('a_maximum_of_3_collections_can_have_a_cover_photo'),
        type: 'error',
      });
      return;
    }

    if (collectionsWithoutTitle > 0 && creatingInsight.collections.length > 1) {
      toast({
        label: t('error'),
        message: t('collections_must_have_a_title'),
        type: 'error',
      });
      return;
    }
    navigate('groups');
  }

  return (
    <section className="flex justify-center w-full min-h-[calc(100vh-88px)] bg-backgray py-12 mb-5 relative">
      {editMode ? (
        <div className="flex flex-col gap-12">
          {creatingInsight.collections.map((collection) => (
            <CollectionEditModeCard key={collection.uuid} collection={collection} setEditMode={setEditMode} />
          ))}
        </div>
      ) : (
        <div className="flex flex-col gap-8 w-[435px] mb-5">
          <h2 className="text-xl font-medium">{t('choose_a_title_for_your_insight')}</h2>
          <input
            value={creatingInsight?.title}
            maxLength={255}
            data-test="create-insight-title"
            onChange={(e) => editInsight({ title: e.target.value })}
            placeholder={t('add_a_insight_title')}
            type="text"
            className="w-full bg-white py-4 px-5 border border-grey-300 rounded-md text-sm placeholder:text-grey-300 outline-grey-200"
          />
          <h2 className="text-xl font-medium">{t('create_a_post')}</h2>
          {creatingInsight.collections.map((collection) => (
            <CollectionCard key={collection.uuid} collection={collection} />
          ))}
        </div>
      )}
      <footer className="fixed bottom-0 right-0 bg-white w-full flex items-center justify-center py-5 gap-5">
        <button
          onClick={handleAddNewCollection}
          className="bg-primary-200 rounded-md shadow-md flex items-center justify-center py-[10px] px-[10px] hover:opacity-70 disabled:bg-grey-400"
        >
          <span className="text-xs text-white mr-[5px]">{t('new_collection')}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M10.0003 4.16602V15.8327M4.16699 9.99935H15.8337"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        {creatingInsight.collections.length > 1 && (
          <button
            onClick={() => setEditMode(!editMode)}
            className="bg-primary-200 rounded-md shadow-md flex items-center justify-center p-2 hover:opacity-70 disabled:bg-grey-400 relative"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M8 8V5.2C8 4.0799 8 3.51984 8.21799 3.09202C8.40973 2.71569 8.71569 2.40973 9.09202 2.21799C9.51984 2 10.0799 2 11.2 2H18.8C19.9201 2 20.4802 2 20.908 2.21799C21.2843 2.40973 21.5903 2.71569 21.782 3.09202C22 3.51984 22 4.0799 22 5.2V12.8C22 13.9201 22 14.4802 21.782 14.908C21.5903 15.2843 21.2843 15.5903 20.908 15.782C20.4802 16 19.9201 16 18.8 16H16M5.2 22H12.8C13.9201 22 14.4802 22 14.908 21.782C15.2843 21.5903 15.5903 21.2843 15.782 20.908C16 20.4802 16 19.9201 16 18.8V11.2C16 10.0799 16 9.51984 15.782 9.09202C15.5903 8.71569 15.2843 8.40973 14.908 8.21799C14.4802 8 13.9201 8 12.8 8H5.2C4.0799 8 3.51984 8 3.09202 8.21799C2.71569 8.40973 2.40973 8.71569 2.21799 9.09202C2 9.51984 2 10.0799 2 11.2V18.8C2 19.9201 2 20.4802 2.21799 20.908C2.40973 21.2843 2.71569 21.5903 3.09202 21.782C3.51984 22 4.07989 22 5.2 22Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="absolute w-[20px] h-[20px] top-[-10px] right-[-1px] rounded-full bg-primary-100 text-white text-[10px] font-semibold flex items-center justify-center">
              {creatingInsight.collections.length}
            </span>
          </button>
        )}
        <button
          onClick={handleNextStep}
          data-test="create-insight-next"
          className="bg-primary-200 rounded-md shadow-md flex items-center justify-center py-[8px] px-[20px] text-white font-semibold hover:opacity-70 disabled:bg-grey-400"
        >
          {t('next')}
        </button>
      </footer>
    </section>
  );
}
