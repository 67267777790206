import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Group, useGroups } from '@hooks/useGroups';

import userNoPhoto from '@assets/icons/user-no-photo.svg';
import inputImage from '@assets/input-image.png';

import ImageViewer from '@components/ImageViewer';
import { Star } from '@phosphor-icons/react';
import { getDefaultImages } from 'utils/auxFunctions';
import { toast } from 'utils/toast';

interface GroupProps {
  group: Group;
}
export default function GroupCard({ group }: Readonly<GroupProps>) {
  const { t } = useTranslation();
  const [isSubmmiting, setIsSubmmiting] = useState(false);

  const { updateFavorite } = useGroups();

  async function handleEditGroupFavorite(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    setIsSubmmiting(true);
    await updateFavorite(group.uuid, group.favorite !== 1);
    toast({
      label: t('success'),
      message: group.favorite === 1 ? t('group_added_favorite') : t('group_removed_favorite'),
      type: 'success',
    });
    setIsSubmmiting(false);
  }

  return (
    <Link to={`/groups/${group.uuid}`} className="w-[481px] flex flex-col">
      <span className="relative">
        <ImageViewer
          url={group.thumbnail800 || ''}
          previewImage={inputImage}
          className="w-full h-[225px] object-cover"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = getDefaultImages('group-default-1.png');
          }}
        />
        <button
          disabled={isSubmmiting}
          onClick={handleEditGroupFavorite}
          className="absolute top-2 right-2 p-2 bg-primary-200 rounded-full hover:opacity-90"
        >
          {group.favorite === 1 ? (
            <Star size={25} weight="fill" color="#fff" fill="#fff" />
          ) : (
            <Star size={25} weight="bold" color="#fff" fill="#fff" />
          )}
        </button>
      </span>
      <div className="flex items-center bg-white py-4 px-5 justify-between min-h-[104px]">
        <h3 className="text-3xl max-w-[60%] break-words font-light text-black-100">{group.name}</h3>
        <span className="flex">
          {group.last4Members &&
            group.last4Members.map((member, index) => {
              if (index < 3) {
                return (
                  group.name !== 'Group Family Wisdom' && (
                    <img
                      alt="Group members"
                      key={index}
                      src={member.image || userNoPhoto}
                      className={`min-w-12 min-h-12 max-w-12 max-h-12 rounded-full ${
                        group.last4Members && group.last4Members.length > 1 && index > 0 ? 'ml-[-20px]' : ''
                      }`}
                    />
                  )
                );
              } else if (index === 3) {
                return (
                  <div
                    key={index}
                    className={`relative min-w-12 min-h-12 max-w-12 max-h-12 rounded-full ${
                      group.last4Members && group.last4Members.length > 1 && index > 0 ? 'ml-[-20px]' : ''
                    } ${group.name !== 'Group Family Wisdom' ? 'bg-black-100' : 'bg-primary-200'}`}
                  >
                    {group.name !== 'Group Family Wisdom' && (
                      <img
                        alt="Group members"
                        src={member.image || userNoPhoto}
                        className={`min-w-12 min-h-12 max-w-12 max-h-12 rounded-full ${
                          group?.users_count && group?.users_count > 4 && 'brightness-50'
                        }`}
                      />
                    )}
                    {group?.users_count && group?.users_count > 4 && (
                      <span className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-xs">
                        +{group?.users_count ? group?.users_count - 4 : ''}
                      </span>
                    )}
                  </div>
                );
              }
            })}
        </span>
      </div>
    </Link>
  );
}
