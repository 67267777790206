import * as Dialog from '@radix-ui/react-dialog';
import { useMemo, useRef } from 'react';

import ButtonAction from '@components/ButtonAction';

import notifcation from '@assets/icons/Profile/SettingsAndPrivacy/notification.svg';

import { Button } from '@components/Button';
import { CheckFat } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

interface NotificationPickModalProps {
  auxNotifications: { label: string; value: string; order: number }[];
  setAuxNotifications: React.Dispatch<React.SetStateAction<{ label: string; value: string; order: number }[]>>;
  notifications: { label: string; value: string; order: number }[];
  setNotifications: React.Dispatch<React.SetStateAction<{ label: string; value: string; order: number }[]>>;
  isCreatingEvent?: boolean;
}

export default function NotificationPickModal({
  auxNotifications,
  setAuxNotifications,
  setNotifications,
  isCreatingEvent,
}: NotificationPickModalProps) {
  const { t } = useTranslation();
  const closeButton = useRef<HTMLButtonElement>(null);

  function handleSaveNotifications() {
    const orderedNotifications = auxNotifications.sort((a, b) => a.order - b.order);

    setNotifications(orderedNotifications);
    closeButton.current?.click();
  }

  const isThirtyMinutesSelected = useMemo(
    () => auxNotifications.some((el) => el.value === 'thirty_minutes_before'),
    [auxNotifications],
  );

  const isOneHourSelected = useMemo(
    () => auxNotifications.some((el) => el.value === 'one_hour_before'),
    [auxNotifications],
  );

  const isOneDaySelected = useMemo(
    () => auxNotifications.some((el) => el.value === 'one_day_before'),
    [auxNotifications],
  );

  const isNoneDaySelected = useMemo(() => auxNotifications.some((el) => el.value === 'none'), [auxNotifications]);

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '10',
        }}
      />
      <Dialog.Content
        forceMount
        className={`z-40 ${
          isCreatingEvent ? 'w-[100vw]' : 'w-[calc(100vw-200px)]'
        }  rounded-lg p-4 right-0 bottom-0 fixed bg-white font-poppins`}
      >
        <Dialog.Close ref={closeButton} className={'absolute top-4 right-8'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
            <path
              d="M3.35209 0.489335C2.69963 -0.163112 1.6418 -0.163112 0.989335 0.489335C0.336888 1.1418 0.336888 2.19963 0.989335 2.85209L10.6372 12.4999L0.989435 22.1478C0.336989 22.8002 0.336989 23.8581 0.989435 24.5105C1.6419 25.1629 2.69973 25.1629 3.35217 24.5105L12.9999 14.8627L22.6478 24.5105C23.3002 25.1629 24.3581 25.1629 25.0105 24.5105C25.6629 23.8581 25.6629 22.8002 25.0105 22.1478L15.3627 12.4999L25.0107 2.85209C25.6631 2.19963 25.6631 1.1418 25.0107 0.489335C24.3583 -0.163112 23.3004 -0.163112 22.648 0.489335L12.9999 10.1372L3.35209 0.489335Z"
              fill="#737373"
            />
          </svg>
        </Dialog.Close>
        <div className="w-full flex flex-col items-center gap-5 p-10">
          <div className="flex items-center w-full relative">
            <ButtonAction
              onClick={() => {
                setAuxNotifications((prev) => {
                  const notification = prev.find((el) => el.value === 'thirty_minutes_before');
                  if (notification) {
                    return prev.filter((el) => el.value !== 'thirty_minutes_before');
                  }
                  return [
                    ...prev.filter((el) => el.value !== 'none'),
                    {
                      label: t('thirty_minutes_before'),
                      value: 'thirty_minutes_before',
                      order: 0,
                    },
                  ];
                });
              }}
              src={notifcation}
              text={t('thirty_minutes_before')}
            />
            {isThirtyMinutesSelected && (
              <span className="absolute right-10">
                <CheckFat color="#282A74" size={20} weight="fill" />
              </span>
            )}
          </div>

          <div className="flex items-center w-full relative">
            <ButtonAction
              onClick={() => {
                setAuxNotifications((prev) => {
                  const notification = prev.find((el) => el.value === 'one_hour_before');
                  if (notification) {
                    return prev.filter((el) => el.value !== 'one_hour_before');
                  }
                  return [
                    ...prev.filter((el) => el.value !== 'none'),
                    {
                      label: t('one_hour_before'),
                      value: 'one_hour_before',
                      order: 1,
                    },
                  ];
                });
              }}
              src={notifcation}
              text={t('one_hour_before')}
            />
            {isOneHourSelected && (
              <span className="absolute right-10">
                <CheckFat color="#282A74" size={20} weight="fill" />
              </span>
            )}
          </div>

          <div className="flex items-center w-full relative">
            <ButtonAction
              onClick={() => {
                setAuxNotifications((prev) => {
                  const notification = prev.find((el) => el.value === 'one_day_before');
                  if (notification) {
                    return prev.filter((el) => el.value !== 'one_day_before');
                  }
                  return [
                    ...prev.filter((el) => el.value !== 'none'),
                    {
                      label: t('one_day_before'),
                      value: 'one_day_before',
                      order: 2,
                    },
                  ];
                });
              }}
              src={notifcation}
              text={t('one_day_before')}
            />
            {isOneDaySelected && (
              <span className="absolute right-10">
                <CheckFat color="#282A74" size={20} weight="fill" />
              </span>
            )}
          </div>

          <div className="flex items-center w-full relative">
            <ButtonAction
              onClick={() => {
                setAuxNotifications([
                  {
                    label: t('none'),
                    value: 'none',
                    order: 0,
                  },
                ]);
              }}
              src={notifcation}
              text={t('none')}
            />
            {isNoneDaySelected && (
              <span className="absolute right-10">
                <CheckFat color="#282A74" size={20} weight="fill" />
              </span>
            )}
          </div>
        </div>
        <span className="flex items-center justify-center mb-5">
          <Button onClick={handleSaveNotifications} size="medium" text={t('save')} variant="primary" boxShadow />
        </span>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
