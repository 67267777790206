import { create } from 'zustand';
import { api } from '../services/axios';

export interface Notification {
  id: number;
  uuid: string;
  description: string;
  type: string;
  action_admin: boolean;
  read: boolean;
  image: string;
  event?: {
    uuid?: string;
  };
  user: {
    uuid: string;
    first_name: string;
    last_name: string;
    image: string;
  };
  post?: {
    uuid: string;
    title: string;
    user: {
      uuid: string;
      first_name: string;
      last_name: string;
      email: string;
      birth_date: string;
      image: string;
      country_id: string;
      phone?: string;
      language: string;
    };
  };
  group?: {
    uuid: string;
    name: string;
    image: string;
  };
}

export interface NotificationSettings {
  reactions?: boolean;
  comments?: boolean;
  new_posts_groups?: boolean;
  your_events?: boolean;
  group_events?: boolean;
  adm_actions?: boolean;
  email?: boolean;
}

interface Pagination {
  current_page: number;
  last_page: number;
  total: number;
  last_page_url: string | null;
}

interface NotificationContextProps {
  pagination: Pagination;
  getAllNotifications: (params?: string) => Promise<{ notifications: Notification[] }>;
  markAsRead: (uuid: string) => Promise<void>;
  markAllAsRead: () => Promise<void>;
  getAllNotificationsSettings: () => Promise<{ settings: NotificationSettings }>;
  updateNotificationsSettings: (settings: NotificationSettings) => Promise<void>;
}

export const useNotification = create<NotificationContextProps>((set) => ({
  pagination: {
    current_page: 1,
    last_page: 1,
    total: 1,
    last_page_url: null,
  },
  getAllNotifications: async (params?: string) => {
    try {
      const { data } = await api.get(`/notifications?${params}`);
      set({
        pagination: {
          current_page: data.content.data.current_page,
          last_page: data.content.data.last_page,
          total: data.content.data.total,
          last_page_url: data.content.data.last_page_url,
        },
      });
      return {
        notifications: data.content.data.data,
      };
    } catch (err) {
      console.log(err);
      return { notifications: [] };
    }
  },
  markAsRead: async (uuid: string) => {
    try {
      await api.post(`/notifications/${uuid}/mark-as-read`);
    } catch (err) {
      console.log(err);
    }
  },
  markAllAsRead: async () => {
    try {
      await api.post(`/notifications/mark-all-as-read`);
    } catch (err) {
      console.log(err);
    }
  },
  getAllNotificationsSettings: async () => {
    try {
      const { data } = await api.get('/user-notification-settings');
      return {
        settings: data.content.data,
      };
    } catch (err) {
      console.log(err);
      return { settings: {} };
    }
  },
  updateNotificationsSettings: async (settings: NotificationSettings) => {
    try {
      await api.put('/user-notification-settings', settings);
    } catch (err) {
      console.log(err);
    }
  },
}));
