/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/named */
import { Switch, SwitchProps, styled } from '@mui/material';

interface NotificationSwitchProps extends SwitchProps {
  colorSwitchOn?: string;
  colorSwitchOff?: string;
}

export const NotificationSwitch = styled(({ colorSwitchOn, colorSwitchOff, ...props }: NotificationSwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ colorSwitchOn, colorSwitchOff, theme }) => ({
  width: 38,
  height: 17,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 1,
    margin: 0,
    transitionDuration: '200ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: colorSwitchOn ?? '#EDB561',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: colorSwitchOn ?? '#EDB561',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 15,
    height: 15,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: colorSwitchOff ?? '#b6b3b3',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
