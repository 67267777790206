/* eslint-disable jsx-a11y/media-has-caption */
import { CreatingInsight } from '@hooks/useInsights';
import { useUser } from '@hooks/useUser';

import ImageViewer from '@components/ImageViewer';

interface ShareInsightPostsProps {
  insight: CreatingInsight;
  isEditingInsight?: boolean;
}

export default function ShareInsightPost({ insight, isEditingInsight }: Readonly<ShareInsightPostsProps>) {
  const { user } = useUser();

  return (
    <section className="flex flex-col w-[534px] bg-white p-5 gap-5">
      <header className="flex items-center justify-between mb-5">
        <div className="flex items-center gap-5">
          <ImageViewer className="h-12 w-12 rounded-full" url={user?.image || ''} alt="User Profile" previewImage="" />
          <span className="flex flex-col">
            <h3 className="text-lg font-bold text-black-100">
              {user?.first_name} {user?.last_name}
            </h3>
            <p className="text-xs text-black-100">{'5 minutes ago'}</p>
          </span>
        </div>
        <div className="flex items-center gap-1"></div>
      </header>

      <div className="flex flex-col gap-5 px-5 mb-5">
        <h2 className="text-xl text-black-100 font-medium break-all">{insight?.title || ''}</h2>
        <span className="text-lg text-black-100 break-all">{insight?.description || ''}</span>
      </div>

      <div className="flex flex-col gap-10 px-5">
        {insight.collections &&
          insight.collections.map((collection, index) => (
            <div key={collection?.uuid ?? index} className="flex flex-col">
              <p className="text-xl ml-3 mb-5 break-all">{collection.title}</p>
              {!isEditingInsight && collection?.uploadedFiles && (
                <div className="flex items-center justify-center flex-wrap mb-5">
                  {collection?.uploadedFiles.map((file) => {
                    const preview = URL.createObjectURL(file);
                    return (
                      <span key={file.lastModified} className="w-[100px] h-[100px] relative">
                        {file.type.includes('image') && (
                          <img
                            alt="Preview"
                            src={preview}
                            className="w-full h-full max-h-full max-w-full object-cover"
                          />
                        )}
                        {file.type.includes('video') && (
                          <video controls src={preview} className="w-full h-full max-h-full max-w-full object-cover" />
                        )}
                        {file.type.includes('audio') && (
                          <span className="w-full h-full bg-primary-200 flex flex-col items-center justify-center relative">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="50"
                              height="50"
                              viewBox="0 0 50 50"
                              fill="none"
                            >
                              <path
                                d="M4.6875 32.8125V17.1875C4.6875 16.3246 3.98794 15.625 3.125 15.625C2.26206 15.625 1.5625 16.3246 1.5625 17.1875V32.8125C1.5625 33.6754 2.26206 34.375 3.125 34.375C3.98794 34.375 4.6875 33.6754 4.6875 32.8125Z"
                                fill="#EDB561"
                              />
                              <path
                                d="M39.8438 32.8125V17.1875C39.8438 16.3246 39.1442 15.625 38.2812 15.625C37.4183 15.625 36.7188 16.3246 36.7188 17.1875V32.8125C36.7188 33.6754 37.4183 34.375 38.2812 34.375C39.1442 34.375 39.8438 33.6754 39.8438 32.8125Z"
                                fill="#EDB561"
                              />
                              <path
                                d="M13.2812 39.0625V10.1562C13.2812 9.29331 12.5817 8.59375 11.7188 8.59375C10.8558 8.59375 10.1562 9.29331 10.1562 10.1563V39.0625C10.1562 39.9254 10.8558 40.625 11.7188 40.625C12.5817 40.625 13.2812 39.9254 13.2812 39.0625Z"
                                fill="#EDB561"
                              />
                              <path
                                d="M31.25 39.0625V10.9375C31.25 10.0746 30.5504 9.375 29.6875 9.375C28.8246 9.375 28.125 10.0746 28.125 10.9375V39.0625C28.125 39.9254 28.8246 40.625 29.6875 40.625C30.5504 40.625 31.25 39.9254 31.25 39.0625Z"
                                fill="#EDB561"
                              />
                              <path
                                d="M48.4375 39.0625V10.9375C48.4375 10.0746 47.7379 9.375 46.875 9.375C46.0121 9.375 45.3125 10.0746 45.3125 10.9375V39.0625C45.3125 39.9254 46.0121 40.625 46.875 40.625C47.7379 40.625 48.4375 39.9254 48.4375 39.0625Z"
                                fill="#EDB561"
                              />
                              <path
                                d="M21.875 46.875V3.125C21.875 2.26206 21.1754 1.5625 20.3125 1.5625C19.4496 1.5625 18.75 2.26206 18.75 3.125V46.875C18.75 47.7379 19.4496 48.4375 20.3125 48.4375C21.1754 48.4375 21.875 47.7379 21.875 46.875Z"
                                fill="#EDB561"
                              />
                            </svg>
                            <audio
                              controls
                              controlsList="nodownload"
                              className="w-full h-5 absolute bottom-1 left-0 customized-audio-tag"
                              src={preview}
                            />
                          </span>
                        )}
                      </span>
                    );
                  })}
                </div>
              )}

              {isEditingInsight && collection?.mediasToEdit && (
                <div className="flex items-center justify-center flex-wrap mb-5">
                  {collection?.mediasToEdit.map((media) => {
                    const type = media?.type || media?.file?.type || '';
                    let preview = '';
                    if (media?.url) {
                      preview = media?.url;
                    } else if (!media.uuid && media.file) {
                      preview = URL.createObjectURL(media.file);
                    }
                    return (
                      <span key={media.uuid ?? media?.file?.name} className="w-[100px] h-[100px] relative">
                        {type?.includes('image') && (
                          <img
                            alt="preview"
                            src={preview}
                            className="w-full h-full max-h-full max-w-full object-cover"
                          />
                        )}
                        {type?.includes('video') && (
                          <video controls src={preview} className="w-full h-full max-h-full max-w-full object-cover" />
                        )}
                        {type?.includes('audio') && (
                          <span className="w-full h-full bg-primary-200 flex flex-col items-center justify-center relative">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="50"
                              height="50"
                              viewBox="0 0 50 50"
                              fill="none"
                            >
                              <path
                                d="M4.6875 32.8125V17.1875C4.6875 16.3246 3.98794 15.625 3.125 15.625C2.26206 15.625 1.5625 16.3246 1.5625 17.1875V32.8125C1.5625 33.6754 2.26206 34.375 3.125 34.375C3.98794 34.375 4.6875 33.6754 4.6875 32.8125Z"
                                fill="#EDB561"
                              />
                              <path
                                d="M39.8438 32.8125V17.1875C39.8438 16.3246 39.1442 15.625 38.2812 15.625C37.4183 15.625 36.7188 16.3246 36.7188 17.1875V32.8125C36.7188 33.6754 37.4183 34.375 38.2812 34.375C39.1442 34.375 39.8438 33.6754 39.8438 32.8125Z"
                                fill="#EDB561"
                              />
                              <path
                                d="M13.2812 39.0625V10.1562C13.2812 9.29331 12.5817 8.59375 11.7188 8.59375C10.8558 8.59375 10.1562 9.29331 10.1562 10.1563V39.0625C10.1562 39.9254 10.8558 40.625 11.7188 40.625C12.5817 40.625 13.2812 39.9254 13.2812 39.0625Z"
                                fill="#EDB561"
                              />
                              <path
                                d="M31.25 39.0625V10.9375C31.25 10.0746 30.5504 9.375 29.6875 9.375C28.8246 9.375 28.125 10.0746 28.125 10.9375V39.0625C28.125 39.9254 28.8246 40.625 29.6875 40.625C30.5504 40.625 31.25 39.9254 31.25 39.0625Z"
                                fill="#EDB561"
                              />
                              <path
                                d="M48.4375 39.0625V10.9375C48.4375 10.0746 47.7379 9.375 46.875 9.375C46.0121 9.375 45.3125 10.0746 45.3125 10.9375V39.0625C45.3125 39.9254 46.0121 40.625 46.875 40.625C47.7379 40.625 48.4375 39.9254 48.4375 39.0625Z"
                                fill="#EDB561"
                              />
                              <path
                                d="M21.875 46.875V3.125C21.875 2.26206 21.1754 1.5625 20.3125 1.5625C19.4496 1.5625 18.75 2.26206 18.75 3.125V46.875C18.75 47.7379 19.4496 48.4375 20.3125 48.4375C21.1754 48.4375 21.875 47.7379 21.875 46.875Z"
                                fill="#EDB561"
                              />
                            </svg>
                            <audio
                              controls
                              controlsList="nodownload"
                              className="w-full h-5 absolute bottom-1 left-0 customized-audio-tag"
                              src={preview}
                            />
                          </span>
                        )}
                      </span>
                    );
                  })}
                </div>
              )}

              {collection.description && (
                <span
                  className="border border-grey-600 text-lg font-light p-5 rounded-md break-words"
                  dangerouslySetInnerHTML={{ __html: collection.description }}
                />
              )}
            </div>
          ))}
        <div className="flex items-center gap-2 mt-2 justify-between">
          <div className="flex flex-col">
            <p className="text-lg font-bold underline text-black-100 break-all">{insight?.groupName}</p>
            <div className="flex items-center gap-1">
              <p className="text-xs text-black-100 break-all pr-5">{insight?.category?.name}</p>
              {insight?.category?.subCategoryName && (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 10 16" fill="none">
                    <path
                      d="M0.500156 14.59L1.91016 16L9.91016 8L1.91016 0L0.500156 1.41L7.08016 8L0.500156 14.59Z"
                      fill="#333333"
                    />
                  </svg>
                  <p className="text-xs text-black-100 break-all pr-5">{insight?.category?.subCategoryName}</p>
                </>
              )}

              {insight?.category?.topicName && (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 10 16" fill="none">
                    <path
                      d="M0.500156 14.59L1.91016 16L9.91016 8L1.91016 0L0.500156 1.41L7.08016 8L0.500156 14.59Z"
                      fill="#333333"
                    />
                  </svg>
                  <p className="text-xs text-black-100 break-all pr-5">{insight?.category?.topicName}</p>
                </>
              )}
            </div>
          </div>
        </div>

        {insight?.tags && insight?.tags.length > 0 && (
          <div className="flex flex-wrap items-center gap-3">
            {insight?.tags.map((tag) => (
              <span
                key={tag.uuid}
                className="flex items-center justify-center rounded-full border border-primary-200 px-[10px] py-[5px] text-primary-200 text-sm"
              >
                {tag.name}
              </span>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}
