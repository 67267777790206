import { SVGProps } from 'react';

interface AppleProps extends SVGProps<SVGSVGElement> {}

function AppleIcon({ ...props }: AppleProps) {
  return (
    <svg {...props} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.4568 8.01904C18.0943 8.01904 17.0959 9.14639 15.4506 9.14639C13.7637 9.14639 12.477 8.02725 10.4297 8.02725C8.42579 8.02725 6.28888 9.25068 4.93185 11.3349C3.02638 14.2739 3.34981 19.8093 6.43595 24.5249C7.53985 26.213 9.01407 28.1062 10.9477 28.1267H10.9828C12.6633 28.1267 13.1625 27.0263 15.4752 27.0134H15.5104C17.7885 27.0134 18.2455 28.1202 19.919 28.1202H19.9541C21.8877 28.0997 23.441 26.0021 24.5449 24.3204C25.3395 23.111 25.6348 22.504 26.2442 21.1358C21.7799 19.4413 21.0627 13.1126 25.4777 10.6862C24.1301 8.99873 22.2363 8.02139 20.451 8.02139L20.4568 8.01904Z"
        fill="black"
      />
      <path
        d="M19.9365 1.875C18.5303 1.97051 16.8897 2.86582 15.9287 4.03477C15.0569 5.09414 14.3397 6.66563 14.6209 8.18965H14.7334C16.2311 8.18965 17.7639 7.28789 18.6592 6.13242C19.5217 5.03262 20.1756 3.47402 19.9365 1.875Z"
        fill="black"
      />
    </svg>
  );
}

export { AppleIcon };
