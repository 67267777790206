import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useInsights } from '@hooks/useInsights';
import { Tag } from '@hooks/useTags';

import { Button } from '@components/Button';
import CreateInsightAddTagsModal from '@components/CreateInsightAddTagsModal';
import { useTranslation } from 'react-i18next';

export default function ShareInsightTagsAndDescription() {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const { creatingInsight, editInsight } = useInsights();
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = ''; // Define uma mensagem vazia para o navegador (não mostrando mensagem padrão)

      // Exibe a mensagem de notificação personalizada
      const confirmationMessage = t('are_you_sure_you_want_to_leave_all_unsaved_data_will_be_lost');
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    // Adiciona o ouvinte de evento ao carregar o componente
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove o ouvinte de evento ao descarregar o componente
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [t]);

  useEffect(() => {
    if (creatingInsight.groupUuid) {
      setIsVisible(true);
    }
  }, [creatingInsight]);

  function handleChangeInsightDescription(event: React.ChangeEvent<HTMLTextAreaElement>) {
    editInsight({ description: event.target.value });
  }

  function handleDeleteTag(tag: Tag) {
    editInsight({ tags: creatingInsight.tags.filter((t) => t.uuid !== tag.uuid) });
  }

  async function handleSaveTagsAndDescription() {
    navigate('/insight/create/categories?previous=insightTagsAndDescription');
  }

  return (
    <>
      {!isVisible ? (
        <></>
      ) : (
        <div className="flex flex-col items-center w-full min-h-[calc(100vh-88px)] bg-backgray py-5">
          <div className="flex flex-col bg-white desktop:w-[40vw] tablet:w-[60vw] py-12 px-12 mt-10 rounded-md">
            <h3 className="text-xl font-medium mb-10">{t('tell_us_about_what_you_are_sharing')}</h3>
            <textarea
              onBlur={handleChangeInsightDescription}
              maxLength={500}
              data-test="create-insight-description"
              placeholder={t('insight_description')}
              defaultValue={creatingInsight.description ?? ''}
              className="resize-none bg-backgray border border-grey-100 rounded-md p-2 h-[200px] outline-none"
            />
          </div>

          <div className="bg-white desktop:w-[40vw] tablet:w-[60vw] mt-5 py-10 px-10 rounded-md mb-16">
            <h3 className="text-xl mb-10">{t('you_can_create_tags_to_summarize_the_content_of_your_insight')}</h3>
            <div className="flex flex-wrap items-center gap-3 gap-y-5">
              {creatingInsight?.tags &&
                creatingInsight.tags.map((tag) => (
                  <span
                    key={tag.uuid}
                    className="rounded-full border border-primary-200 px-[10px] py-[5px] text-primary-200 text-sm relative"
                  >
                    <p>{tag.name}</p>
                    <button
                      onClick={() => handleDeleteTag(tag)}
                      className="flex items-center justify-center absolute top-[-12px] right-[-4px] w-[20px] h-[20px] font-semibold bg-primary-200 text-white rounded-full hover:opacity-70"
                    >
                      X
                    </button>
                  </span>
                ))}
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <button className="rounded-full border border-primary-200 px-[10px] py-[5px] text-primary-200 text-sm hover:opacity-70">
                    +
                  </button>
                </Dialog.Trigger>
                <CreateInsightAddTagsModal />
              </Dialog.Root>
            </div>
          </div>

          {/* <div className="flex items-center justify-between bg-white py-10 px-10 rounded-md mb-12 desktop:w-[40vw] tablet:w-[60vw]">
            <p>{t('connect_to_an_event')}</p>
            <NotificationSwitch />
          </div> */}

          <div className="flex justify-center w-full">
            <Button
              data-test="create-insight-save"
              onClick={handleSaveTagsAndDescription}
              variant="primary"
              size="medium"
              text={t('save')}
              boxShadow
            />
          </div>
        </div>
      )}
    </>
  );
}
