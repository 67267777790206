import * as Dialog from '@radix-ui/react-dialog';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Event, useEvent } from '@hooks/useEvent';
import { useEventLabels } from '@hooks/useEventLabels';

import { Button } from '@components/Button';
import Loading from '@components/Loading';

import CreateOrEditEventLabelModal from '@components/CreateOrEditEventLabelModal';
import { useTranslation } from 'react-i18next';
import { toast } from 'utils/toast';

export default function ChooseEventLabel() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<Event | null>(null);
  const { getEventByUuid, updateEvent, deleteEvent } = useEvent();
  const { getEventLabelsForGroup, eventLabels } = useEventLabels();

  const { eventUuid } = useParams();

  const navigate = useNavigate();
  const closeButton = useRef<HTMLButtonElement>(null);

  const getEventInformations = useCallback(async () => {
    if (!eventUuid) return;
    const { event } = await getEventByUuid(eventUuid);
    setCurrentEvent(event);
    if (!event?.group?.uuid) return;
    await getEventLabelsForGroup(event?.group?.uuid);
    setIsLoading(false);
  }, [eventUuid, getEventByUuid, getEventLabelsForGroup]);

  useEffect(() => {
    getEventInformations();
  }, [getEventInformations]);

  async function handleSelectEventLabel(eventLabelId: string) {
    if (!eventUuid) return;
    setIsSubmitting(true);
    const response = await updateEvent(eventUuid, {
      notifications: currentEvent?.event_notifications,
      event_label_id: eventLabelId,
    });
    if (response?.uuid) {
      toast({
        label: t('success'),
        message: t('event_updated'),
        type: 'success',
      });
      navigate('/events/' + response.uuid + '?finish=true');
      setIsSubmitting(false);
    }
  }

  async function handleDeleteEvent() {
    if (!eventUuid) return;
    setIsSubmitting(true);
    const response = await deleteEvent(eventUuid);
    if (response?.uuid) {
      toast({
        label: t('success'),
        message: t('event_deleted'),
        type: 'success',
      });
      navigate('/calendar');
      setIsSubmitting(false);
    }
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <main className="flex flex-col items-center py-12 w-full min-h-[calc(100vh-88px)] bg-backgray">
          <div className="flex flex-col items-center bg-white p-8 rounded-md shadow-md min-w-[30vw]">
            <h3 className="text-xl font-medium mb-5">{t('choose_label_title')}</h3>
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <button className="border border-primary-200 flex items-center justify-center w-[230px] rounded-md py-1 px-2 gap-1 ml-auto hover:opacity-70">
                  <p className="text-xs font-medium text-primary-200">{t('create_a_new_event_label')}</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M10.0001 4.16699V15.8337M4.16675 10.0003H15.8334"
                      stroke="#282A74"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </Dialog.Trigger>
              <CreateOrEditEventLabelModal groupId={currentEvent?.group?.uuid || ''} />
            </Dialog.Root>
            <div className="flex flex-col w-full mt-5">
              {eventLabels &&
                eventLabels.map((eventLabel) => {
                  return (
                    <button
                      key={eventLabel.uuid}
                      disabled={isSubmitting}
                      onClick={() => handleSelectEventLabel(eventLabel.uuid)}
                      className="flex items-center px-5 gap-10 border-b border-grey-600 w-full py-2 hover:opacity-70"
                    >
                      <span
                        className="rounded-full min-w-12 min-h-12 max-w-12 max-h-12"
                        style={{ backgroundColor: eventLabel.color }}
                      />
                      <p className="text-lg break-all">{eventLabel.name}</p>
                    </button>
                  );
                })}
            </div>
          </div>

          <div className="flex items-center gap-12 mt-12">
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <Button disabled={isSubmitting} size="medium" variant="primary" boxShadow text={t('delete')} />
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay
                  style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                    inset: 0,
                    background: 'rgba(0, 0, 0, 0.75)',
                    zIndex: '10',
                  }}
                />
                <Dialog.Content
                  forceMount
                  className={
                    'z-40 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white px-12 py-8 rounded-lg font-poppins'
                  }
                >
                  <div className="flex flex-col gap-5">
                    <Dialog.Close ref={closeButton} className={'hidden'}></Dialog.Close>
                    <h1 className="text-lg text-black-100 font-semibold">{t('delete_event_title')}</h1>
                    <span className="text-sm text-grey-200 mb-2">{t('delete_event_description')}</span>
                    <div className="flex items-center justify-end gap-4">
                      <button
                        onClick={() => closeButton.current?.click()}
                        className="flex items-center justify-center py-2 px-3 rounded-md bg-primary-200 bg-opacity-20 hover:opacity-70"
                      >
                        {t('cancel')}
                      </button>
                      <button
                        onClick={handleDeleteEvent}
                        className="flex items-center justify-center py-2 px-3 rounded-md bg-green-300 hover:opacity-70"
                      >
                        {t('confirm')}
                      </button>
                    </div>
                  </div>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
            <Button
              onClick={() => navigate('/calendar/edit-event/' + (eventUuid || ''))}
              disabled={isSubmitting}
              size="medium"
              variant="primary"
              boxShadow
              text={t('edit')}
            />
          </div>
        </main>
      )}
    </>
  );
}
