import * as Dialog from '@radix-ui/react-dialog';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/Button';
import FeedbackModal from '@components/FeedbackModal';

import Loading from '@components/Loading';
import { api } from 'services/axios';
import { retrieveImgS3 } from 'utils/auxFunctions';
import { uploadToS3ToPublicFolder } from 'utils/awsS3';
import { toast } from 'utils/toast';

export default function ReportProblem() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [uploadedPhotos, setUploadedPhotos] = useState<File[]>([]);
  const [uploadedVideos, setUploadedVideos] = useState<File[]>([]);
  const [description, setDescription] = useState<string>('');
  const dialogTrigger = useRef<HTMLButtonElement>(null);

  const uploadPhotosInput = useRef<HTMLInputElement>(null);
  const uploadVideoInput = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  async function handleUploadPhotos(event: React.ChangeEvent<HTMLInputElement>) {
    const uploadedFiles = event.currentTarget.files;
    if (!uploadedFiles) return;
    const onlyPhotos = Array.from(uploadedFiles).filter((file) => file.type.includes('image'));
    if (onlyPhotos.length > 0 && onlyPhotos.length < 7) {
      setUploadedPhotos(onlyPhotos);
    } else {
      toast({
        label: t('error'),
        message: t('you_can_only_upload_6_photos'),
        type: 'error',
      });
      return;
    }
  }

  async function handleUploadVideo(event: React.ChangeEvent<HTMLInputElement>) {
    const uploadedFiles = event.currentTarget.files;
    if (!uploadedFiles) return;
    const onlyVideos = Array.from(uploadedFiles).filter((file) => file.type.includes('video'));
    if (onlyVideos.length > 0 && onlyVideos.length < 2) {
      setUploadedVideos(onlyVideos);
    } else {
      toast({
        label: t('error'),
        message: t('you_can_only_upload_1_video'),
        type: 'error',
      });
      return;
    }
  }

  async function handleSendFeedback() {
    if (!description) {
      toast({
        label: t('error'),
        message: t('description_required'),
        type: 'error',
      });
      return;
    }
    setIsSubmitting(true);
    const filesToSend: { url: string; type: string }[] = [];
    if (uploadedPhotos.length > 0 && uploadedPhotos.length < 7) {
      await Promise.all(
        uploadedPhotos.map(async (file) => {
          let imgURL = '';
          const { key, error } = await uploadToS3ToPublicFolder(file);
          if (error) {
            toast({
              label: t('error'),
              message: t('failed_to_upload_image'),
              type: 'error',
            });
          } else {
            if (key) imgURL = retrieveImgS3(key);
            filesToSend.push({ url: imgURL, type: 'image' });
          }
        }),
      );
    }
    if (uploadedVideos.length > 0 && uploadedVideos.length < 2) {
      await Promise.all(
        uploadedVideos.map(async (file) => {
          let imgURL = '';
          const { key, error } = await uploadToS3ToPublicFolder(file);
          if (error) {
            toast({
              label: t('error'),
              message: t('failed_to_upload_video'),
              type: 'error',
            });
          } else {
            if (key) imgURL = retrieveImgS3(key);
            filesToSend.push({ url: imgURL, type: 'video' });
          }
        }),
      );
    }
    try {
      const { data } = await api.post('/reports', {
        description: description,
        files: filesToSend,
      });
      if (data?.content?.data.uuid) {
        setDescription('');
        setUploadedPhotos([]);
        setUploadedVideos([]);
        setIsSubmitting(false);
        setShowFeedbackModal(true);
      }
    } catch (e) {
      console.log(e);
      toast({
        label: t('error'),
        message: t('failed_to_send_feedback'),
        type: 'error',
      });
    }
    setIsSubmitting(false);
  }

  return (
    <>
      {isSubmitting ? (
        <Loading />
      ) : (
        <div className="flex items-center justify-center py-10">
          <div className="flex flex-col gap-5 p-12 shadow-lg max-w-[410px]">
            <h1 className="text-xl text-black-100 font-semibold">{t('tell_us_your_feedback')}</h1>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={t('write_a_report')}
              maxLength={255}
              className="text-sm text-black-100 font-semibold px-4 py-2 h-[145px] outline-none border border-black-100 border-opacity-70 rounded-md resize-none"
            />
            <p className="text-xs">{t('add_a_screenshot_or_a_screen_recording_that_shows_us_what_is_happening')}</p>
            <div className="flex items-center justify-center gap-5">
              <button
                onClick={() => uploadPhotosInput.current?.click()}
                className="flex flex-col items-center justify-center w-[110px] h-[110px] bg-grey-600 rounded-md gap-2 hover:opacity-70"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                  <path
                    d="M7.35303 26.6665L14.996 19.0235C16.2978 17.7218 18.4083 17.7218 19.71 19.0235L27.353 26.6665M24.0197 23.3332L26.6627 20.6902C27.9644 19.3884 30.075 19.3884 31.3767 20.6902L34.0197 23.3332M24.0197 13.3332H24.0364M10.6864 33.3332H30.6864C32.5273 33.3332 34.0197 31.8408 34.0197 29.9998V9.99984C34.0197 8.15889 32.5273 6.6665 30.6864 6.6665H10.6864C8.84541 6.6665 7.35303 8.15889 7.35303 9.99984V29.9998C7.35303 31.8408 8.84541 33.3332 10.6864 33.3332Z"
                    stroke="#282A74"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-xl text-grey-200">{uploadedPhotos.length} / 6</p>
              </button>
              <button
                onClick={() => uploadVideoInput.current?.click()}
                className="flex flex-col items-center justify-center w-[110px] h-[110px] bg-grey-600 rounded-md gap-2 hover:opacity-70"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                  <path
                    d="M25.0592 16.6667L32.6472 12.8727C33.7554 12.3186 35.0592 13.1244 35.0592 14.3634V25.6366C35.0592 26.8756 33.7553 27.6814 32.6472 27.1273L25.0592 23.3333M8.39254 30H21.7259C23.5668 30 25.0592 28.5076 25.0592 26.6667V13.3333C25.0592 11.4924 23.5668 10 21.7259 10H8.39254C6.55159 10 5.0592 11.4924 5.0592 13.3333V26.6667C5.0592 28.5076 6.55159 30 8.39254 30Z"
                    stroke="#282A74"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-xl text-grey-200">{uploadedVideos.length} / 1</p>
              </button>

              <input
                id="choose-photo-library"
                accept="image/*"
                type="file"
                className="hidden"
                multiple
                ref={uploadPhotosInput}
                onChange={handleUploadPhotos}
              />

              <input
                id="choose-video-library"
                accept="video/*"
                type="file"
                className="hidden"
                multiple
                ref={uploadVideoInput}
                onChange={handleUploadVideo}
              />
            </div>
            <span className="flex items-center justify-center w-full mt-5">
              <Button
                disabled={isSubmitting}
                onClick={handleSendFeedback}
                size="medium"
                variant="primary"
                text={t('submit')}
              />
            </span>
          </div>

          <Dialog.Root open={showFeedbackModal}>
            <Dialog.Trigger ref={dialogTrigger} className="hidden"></Dialog.Trigger>

            <FeedbackModal setShowFeedbackModal={setShowFeedbackModal} />
          </Dialog.Root>
        </div>
      )}
    </>
  );
}
