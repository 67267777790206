import Header from '@components/Header';
import SideBar from '@components/SideBar';

import { Outlet } from 'react-router-dom';

export default function DefaultLayout() {
  return (
    <div className="font-poppins w-full relative">
      <Header />
      <div className="flex w-full mt-[88px] h-full">
        <SideBar />
        <main className="ml-[200px] w-full">
          <Outlet />
        </main>
      </div>
    </div>
  );
}
