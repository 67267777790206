import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Dialog from '@radix-ui/react-dialog';

import { useUser } from '@hooks/useUser';
import { useAds } from '@hooks/useAds';

import { NotificationSwitch } from '@components/Switch';

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/pt';
import DatePickModal from '@components/DatePickModal';
import { WarningCircle } from '@phosphor-icons/react';

export default function AdsSecondaryInformation() {
  const { t } = useTranslation();
  const { user } = useUser();
  const { ads, setAds } = useAds();

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');

  const [selectedStartDate, setSelectedStartDate] = useState<string>(dayjs().format('DD/MM/YYYY'));
  const [selectedStartDateFormatted, setSelectedStartDateFormatted] = useState<string>('');
  const [selectedStartTime, setSelectedStartTime] = useState<string>(dayjs().format('HH:mm'));

  const [selectedEndDate, setSelectedEndDate] = useState<string>(dayjs().format('DD/MM/YYYY'));
  const [selectedEndDateFormatted, setSelectedEndDateFormatted] = useState<string>('');
  const [selectedEndTime, setSelectedEndTime] = useState<string>(dayjs().add(1, 'hour').format('HH:mm'));

  const [endDateError, setEndDateError] = useState(false);

  useEffect(() => {
    if (!selectedStartDate) return;
    const startDateArray = selectedStartDate.split('/');
    const endDateArray = selectedEndDate.split('/');
    if (user?.language === 'pt') {
      setSelectedStartDateFormatted(startDateArray[0] + '/' + startDateArray[1] + '/' + startDateArray[2]);
      setSelectedEndDateFormatted(endDateArray[0] + '/' + endDateArray[1] + '/' + endDateArray[2]);
    } else {
      setSelectedStartDateFormatted(startDateArray[1] + '/' + startDateArray[0] + '/' + startDateArray[2]);
      setSelectedEndDateFormatted(endDateArray[1] + '/' + endDateArray[0] + '/' + endDateArray[2]);
    }
    setAds({
      startDate: startDateArray[2] + '-' + startDateArray[1] + '-' + startDateArray[0] + ' ' + selectedStartTime,
      expirationDate: endDateArray[2] + '-' + endDateArray[1] + '-' + endDateArray[0] + ' ' + selectedEndTime,
    });
    const startTimeArray = selectedStartTime.split(':');
    const endTimeArray = selectedEndTime.split(':');
    const startDateMilliseconds = new Date(
      Number(startDateArray[2]),
      Number(startDateArray[1]),
      Number(startDateArray[0]),
      Number(startTimeArray[0]),
      Number(startTimeArray[1]),
    ).getTime();
    const endDateMilliseconds = new Date(
      Number(endDateArray[2]),
      Number(endDateArray[1]),
      Number(endDateArray[0]),
      Number(endTimeArray[0]),
      Number(endTimeArray[1]),
    ).getTime();
    if (endDateMilliseconds <= startDateMilliseconds) {
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }
  }, [setAds, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, user?.language]);

  useEffect(() => {
    if (!selectedStartDate || !selectedStartTime) return;
    const startDateArray = selectedStartDate.split('/');
    const startTimeArray = selectedStartTime.split(':');
    const date = new Date(
      Number(startDateArray[2]),
      Number(startDateArray[1]) - 1,
      Number(startDateArray[0]),
      Number(startTimeArray[0]),
      Number(startTimeArray[1]),
    );
    setSelectedEndDate(dayjs(date).add(1, 'month').format('DD/MM/YYYY'));
    setSelectedEndTime(dayjs(date).format('HH:mm'));
  }, [selectedStartTime, selectedStartDate]);

  return (
    <div className="flex flex-col gap-10 p-5 bg-white rounded-md relative">
      <div className="flex items-center gap-3 w-full">
        <label htmlFor="title" className="text text text-grey-200 w-[200px]">
          {t('max_click_impression')}
        </label>
        <input
          value={ads.maxClickImpression}
          onChange={(event) => {
            const maxClickImpression = Number(event.target.value);
            if (isNaN(maxClickImpression)) {
              return;
            }
            if (maxClickImpression <= 0) {
              setAds({ maxClickImpression: '' });
            } else {
              setAds({ maxClickImpression: maxClickImpression.toString() });
            }
          }}
          className="w-[180px] px-2 outline-none border-b border-grey-500 bg-transparent text-black-100 placeholder:text-black-100 placeholder:text-opacity-40"
          type="text"
        />
      </div>
      <div className="flex items-center gap-3 w-full">
        <label htmlFor="title" className="text text text-grey-200 w-[200px]">
          {t('max_view_impression')}
        </label>
        <input
          value={ads.maxViewImpression}
          onChange={(event) => {
            const maxViewImpression = Number(event.target.value);
            if (isNaN(maxViewImpression)) {
              return;
            }
            if (maxViewImpression <= 0) {
              setAds({ maxViewImpression: '' });
            } else {
              setAds({ maxViewImpression: maxViewImpression.toString() });
            }
          }}
          className="w-[180px] px-2 outline-none border-b border-grey-500 bg-transparent text-black-100 placeholder:text-black-100 placeholder:text-opacity-40"
          type="text"
        />
      </div>
      {/* <div className="flex items-center gap-3 w-full">
        <label htmlFor="title" className="text text text-grey-200 w-[200px]">
          {t('priority')}
        </label>
        <input
          value={ads.priority}
          onChange={(event) => {
            const priority = Number(event.target.value);
            if (isNaN(priority)) {
              return;
            }
            if (priority <= 0) {
              setAds({ priority: '' });
            } else {
              setAds({ priority: priority.toString() });
            }
          }}
          className="w-[180px] px-2 outline-none border-b border-grey-500 bg-transparent text-black-100 placeholder:text-black-100 placeholder:text-opacity-40"
          type="text"
        />
      </div> */}
      <div className="flex items-center gap-3 w-full">
        <label htmlFor="title" className="text text text-grey-200 w-[200px]">
          {t('external_link')}
        </label>
        <input
          value={ads.link}
          onChange={(event) => setAds({ link: event.target.value })}
          className="w-[180px] px-2 outline-none border-b border-grey-500 bg-transparent text-black-100 placeholder:text-black-100 placeholder:text-opacity-40"
          type="text"
        />
      </div>
      <div className="flex items-center gap-3 w-full">
        <label htmlFor="title" className="text text text-grey-200 w-[200px]">
          {t('ads_state')}
        </label>
        <NotificationSwitch
          colorSwitchOn="#282A74"
          value={Boolean(ads.activeState)}
          checked={Boolean(ads.activeState)}
          onChange={() => {
            setAds({ activeState: !ads.activeState });
          }}
        />
      </div>
      <div className="flex items-center gap-3 w-full">
        <label htmlFor="title" className="text text text-grey-200 w-[200px]">
          {t('start_date')}
        </label>

        <Dialog.Root
          onOpenChange={() => {
            setSelectedDate('');
            setSelectedTime('');
          }}
        >
          <Dialog.Trigger asChild>
            <div
              className={`flex items-center justify-between w-[180px] px-2 outline-none border-b border-grey-500 bg-transparent text-black-100 placeholder:text-black-100 placeholder:text-opacity-40 cursor-pointer ${
                selectedStartDate ? '' : 'text-opacity-40'
              }`}
              id="start-date"
            >
              <span>{selectedStartDate ? selectedStartDateFormatted : dayjs().format('DD/MM/YYYY')}</span>
              <span>{selectedStartTime ?? '00:00'}</span>
            </div>
          </Dialog.Trigger>
          <DatePickModal
            isCreatingEvent
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            setSelectedDate={setSelectedDate}
            setSelectedTime={setSelectedTime}
            setDate={setSelectedStartDate}
            setTime={setSelectedStartTime}
            withTime
          />
        </Dialog.Root>
      </div>

      <div className="flex gap-3 w-full">
        <label className="text-grey-200 w-[200px]">{t('expiration_date')}</label>
        <Dialog.Root
          onOpenChange={() => {
            setSelectedDate('');
            setSelectedTime('');
          }}
        >
          <Dialog.Trigger asChild>
            <div
              className={`flex items-center justify-between w-[180px] px-2 outline-none border-b border-grey-500 bg-transparent text-black-100 placeholder:text-black-100 placeholder:text-opacity-40 cursor-pointer relative ${
                selectedEndDate ? '' : 'text-opacity-40'
              }`}
              id="end-date"
            >
              <span className={`${endDateError ? 'text-[#ec2a2a]' : ''}`}>
                {selectedEndDate ? selectedEndDateFormatted : dayjs().format('DD/MM/YYYY')}
              </span>
              <span className={`${endDateError ? 'text-[#ec2a2a]' : ''}`}>{selectedEndTime ?? '00:00'}</span>
              {selectedEndDate && endDateError && (
                <span
                  className="absolute right-[-20px] bottom-1"
                  title={t('start_date_must_be_less_than_expiration_date')}
                >
                  <WarningCircle color="#ec2a2a" size={20} weight="fill" />
                </span>
              )}
            </div>
          </Dialog.Trigger>
          <DatePickModal
            isCreatingEvent
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            setSelectedDate={setSelectedDate}
            setSelectedTime={setSelectedTime}
            setDate={setSelectedEndDate}
            setTime={setSelectedEndTime}
            withTime
          />
        </Dialog.Root>
      </div>
    </div>
  );
}
