import * as Dialog from '@radix-ui/react-dialog';

import { useUser } from '@hooks/useUser';
import { type Comment } from '@hooks/useComments';
import { type Insight } from '@hooks/useInsights';

import DeleteOrEditCommentModal from '@components/DeleteOrEditCommentModal';
import ImageViewer from '@components/ImageViewer';

interface CommentProps {
  comment: Comment;
  isModal?: boolean;
  insight: Insight;
  setComments: React.Dispatch<React.SetStateAction<Comment[]>>;
}

export default function CommentBox({ comment, isModal, insight, setComments }: CommentProps) {
  const { user } = useUser();
  return (
    <div key={comment.uuid} className="flex items-start gap-3 mb-5 relative w-full">
      <ImageViewer url={comment.user?.image || ''} previewImage={''} className="w-[40px] h-[40px] rounded-full" />
      <div className="flex flex-col w-full bg-backgray rounded-md py-2 px-5">
        <span className="text-black-100 font-bold text-sm">
          {comment.user?.first_name} {comment.user?.last_name}
        </span>
        <span className={`text-black-100 text-sm break-words w-full ${isModal ? 'max-w-[380px]' : 'max-w-[250px]'}`}>
          {comment.body}
        </span>
      </div>
      {comment.user?.uuid === user?.uuid && (
        <Dialog.Root>
          <Dialog.Trigger asChild>
            <button className="absolute top-1 right-2 hover:opacity-70">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" fill="none">
                <path
                  d="M9 14.5C9 16.1569 7.65686 17.5 6 17.5C4.34314 17.5 3 16.1569 3 14.5C3 12.8431 4.34314 11.5 6 11.5C7.65686 11.5 9 12.8431 9 14.5Z"
                  fill="#333333"
                />
                <path
                  d="M18 14.5C18 16.1569 16.6569 17.5 15 17.5C13.3431 17.5 12 16.1569 12 14.5C12 12.8431 13.3431 11.5 15 11.5C16.6569 11.5 18 12.8431 18 14.5Z"
                  fill="#333333"
                />
                <path
                  d="M24 17.5C25.6569 17.5 27 16.1569 27 14.5C27 12.8431 25.6569 11.5 24 11.5C22.3431 11.5 21 12.8431 21 14.5C21 16.1569 22.3431 17.5 24 17.5Z"
                  fill="#333333"
                />
              </svg>
            </button>
          </Dialog.Trigger>
          <DeleteOrEditCommentModal setComments={setComments} insight={insight} comment={comment} />
        </Dialog.Root>
      )}
      <span className="absolute top-1 right-10 text-[10px] text-grey-150">{comment?.elapsed_time || ''}</span>
    </div>
  );
}
