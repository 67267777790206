import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Dialog from '@radix-ui/react-dialog';

import Calendar from '@components/Calendar';
import { Button } from '@components/Button';

interface DatePickModalProps {
  setDate: React.Dispatch<React.SetStateAction<string>>;
  selectedDate: string;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  selectedTime?: string;
  setSelectedTime?: React.Dispatch<React.SetStateAction<string>>;
  setTime?: React.Dispatch<React.SetStateAction<string>>;
  withTime: boolean;
  isCreatingEvent?: boolean;
}

export default function DatePickModal({
  setDate,
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
  setTime,
  withTime,
  isCreatingEvent,
}: DatePickModalProps) {
  const closeButton = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  function handleSaveDate() {
    const dateFormated = selectedDate.replaceAll('-', '/');
    setDate(dateFormated);
    if (setTime && selectedTime !== undefined) {
      if (selectedTime === '') setTime('00:00');
      else setTime(selectedTime);
    }

    closeButton.current?.click();
  }

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          inset: 0,
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '10',
        }}
      />
      <Dialog.Content
        forceMount
        className={`z-40 ${
          isCreatingEvent ? 'w-[100vw]' : 'w-[calc(100vw-200px)]'
        }  rounded-lg p-4 right-0 bottom-0 fixed bg-white font-poppins`}
      >
        <Dialog.Close ref={closeButton} className={'absolute top-4 right-8'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
            <path
              d="M3.35209 0.489335C2.69963 -0.163112 1.6418 -0.163112 0.989335 0.489335C0.336888 1.1418 0.336888 2.19963 0.989335 2.85209L10.6372 12.4999L0.989435 22.1478C0.336989 22.8002 0.336989 23.8581 0.989435 24.5105C1.6419 25.1629 2.69973 25.1629 3.35217 24.5105L12.9999 14.8627L22.6478 24.5105C23.3002 25.1629 24.3581 25.1629 25.0105 24.5105C25.6629 23.8581 25.6629 22.8002 25.0105 22.1478L15.3627 12.4999L25.0107 2.85209C25.6631 2.19963 25.6631 1.1418 25.0107 0.489335C24.3583 -0.163112 23.3004 -0.163112 22.648 0.489335L12.9999 10.1372L3.35209 0.489335Z"
              fill="#737373"
            />
          </svg>
        </Dialog.Close>
        <div className="w-full flex flex-col items-center gap-5 p-10">
          {withTime ? (
            <>
              {selectedDate ? (
                <div className="w-full flex flex-col items-center justify-center gap-5">
                  <input
                    type="time"
                    className="w-[300px] border-b border-grey-600 px-10 py-2 outline-none font-semibold text-grey-150 mb-5"
                    value={selectedTime}
                    onChange={(e) => {
                      if (setSelectedTime) setSelectedTime(e.target.value);
                    }}
                  />
                  <Button onClick={handleSaveDate} text={t('done')} variant="primary" size="medium" />
                </div>
              ) : (
                <div className="w-full flex items-center justify-center gap-5">
                  <Calendar selectedDate={selectedDate} setDate={setSelectedDate} type="datePicker" />
                </div>
              )}
            </>
          ) : (
            <div className="w-full flex items-center justify-center gap-5">
              <Calendar selectedDate={selectedDate} setDate={setSelectedDate} type="datePicker" />
            </div>
          )}
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
