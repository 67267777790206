/* eslint-disable react/display-name */
import EyeOff from '@assets/icons/EyeOff.svg';
import EyeOn from '@assets/icons/EyeOn.svg';

import React, { InputHTMLAttributes, useCallback, useState } from 'react';
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  identificator: string;
  label: string;
  type?: string;
  required?: boolean;
  redBorder?: boolean;
}

export const Input = React.forwardRef<HTMLInputElement, Omit<InputProps, 'ref'>>(
  ({ required, type, identificator, label, redBorder, ...props }, ref) => {
    const [inputType, setInputType] = useState(type ?? 'text');

    const handleTogglePasswordVisibility = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event?.stopPropagation();
        event?.preventDefault();
        setInputType(inputType === 'password' ? 'text' : 'password');
      },
      [inputType],
    );

    return (
      <div className="relative flex-1 flex items-center">
        {type == 'password' && (
          <button
            className="absolute right-2 p-1 rounded-full hover:bg-primary-200/10"
            data-test="show-password-trigger"
            onClick={handleTogglePasswordVisibility}
          >
            {inputType == 'password' ? (
              <img className="w-6 h-6" src={EyeOff} alt="See password" />
            ) : (
              <img className="w-6 h-6" src={EyeOn} alt="See password" />
            )}
          </button>
        )}
        <input
          {...props}
          id={identificator}
          ref={ref}
          type={inputType || 'text'}
          className={`block border-2 p-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-grey-300 appearance-none focus:outline-none focus:ring-0 focus:border-grey-300 peer ${
            redBorder ? 'border-red-500' : ''
          }`}
          placeholder=" "
          required={required ?? false}
        />
        <label
          htmlFor={identificator}
          className="absolute text-sm text-grey-300 font-semibold  duration-300 transform -translate-y-4 scale-75 top-2 z-[1] origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-grey-300  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2"
        >
          {label}
        </label>
      </div>
    );
  },
);
