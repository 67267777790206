import { create } from 'zustand';
import { api } from '../services/axios';
import { showErrorMessage } from '../utils/auxFunctions';

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/pt';

export interface AdsCreateDTO {
  name: string;
  profile_photo: string;
  title: string;
  description: string;
  external_url: string;
  aws_url: string;
  // url_aws_cover?: string;
  aws_type: 'image' | 'video';
  max_clicks: number;
  max_views: number;
  start_date: string;
  end_date: string;
  active: boolean;
  // priority: number;
}

export interface Ads {
  title: string;
  description: string;
  name?: string;
  link?: string;
  awsMediaUrl: string;
  // awsMediaCoverUrl: string;
  maxClickImpression: string;
  maxViewImpression: string;
  profile_photo?: string;
  // priority: string;
  activeState: boolean;
  startDate?: string;
  expirationDate?: string;
  file?: File;
  thumbnail?: string;
  url?: string;
  fileType?: string;
  views?: number;
  clicks?: number;
}

export interface AdsView {
  uuid: string;
  name: string;
  profile_photo: string;
  title: string;
  description: string;
  external_url?: string;
  aws_url: string;
  aws_type: 'image' | 'video';
  max_clicks: number;
  clicks: number;
  max_views: number;
  views: number;
  start_date: string;
  end_date: string;
  total_likes: number;
  active: number;
  user_reaction?: { uuid?: string; emoji?: string } | null;
}

interface Pagination {
  current_page: number;
  last_page: number;
  total: number;
  last_page_url: string | null;
}

interface AdsProps {
  ads: Ads;
  pagination: Pagination;
  adsList: AdsView[];
  getAdsInfo: (adsUuid: string) => Promise<AdsView>;
  listAds: (first: boolean, params?: string) => Promise<AdsView[]>;
  updateAds: (adsUuid: string, objData: AdsCreateDTO) => Promise<any>;
  deleteAds: (adsUuid: string) => Promise<any>;
  createAds: (adsToCreate: AdsCreateDTO) => Promise<any>;
  registerReaction: (adsUuid: string, emoji: string) => Promise<any>;
  registerClick: (adsUuid: string) => void;
  registerView: (adsUuid: string) => void;
  setAds: (props: Partial<Ads>) => void;
  clearAds: () => void;
}

export const useAds = create<AdsProps>((set, get) => ({
  ads: {
    title: '',
    description: '',
    awsMediaUrl: '',
    // awsMediaCoverUrl: '',
    maxClickImpression: '0',
    maxViewImpression: '0',
    // priority: '1',
    activeState: false,
    startDate: '',
    expirationDate: '',
    file: undefined,
    thumbnail: '',
    url: '',
    link: '',
  },

  pagination: {
    current_page: 1,
    last_page: 1,
    total: 1,
    last_page_url: null,
  },

  adsList: [],

  getAdsInfo: async (adsUuid: string) => {
    try {
      const { data } = await api.get(`post-marke/${adsUuid}`);
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
      throw error;
    }
  },

  listAds: async (first: boolean, params?: string) => {
    try {
      const { data } = await api.get(`post-marke` + (params ? params : ''));
      if (first) {
        set({ adsList: data.content.data.data });
      } else {
        if (data?.content?.data?.data && data.content.data.data.length > 0) {
          set({ adsList: [...get().adsList, ...data.content.data.data] });
        }
      }
      set({
        pagination: {
          current_page: data.content.data.current_page,
          last_page: data.content.data.last_page,
          total: data.content.data.total,
          last_page_url: data.content.data.last_page_url,
        },
      });
      return data.content.data.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },

  updateAds: async (adsUuid: string, objData: AdsCreateDTO) => {
    try {
      const { data } = await api.put(`post-marke/${adsUuid}`, objData);
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },

  createAds: async (adsToCreate: AdsCreateDTO) => {
    try {
      const { data } = await api.post(`post-marke`, adsToCreate);
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },

  deleteAds: async (adsUuid: string) => {
    try {
      const { data } = await api.delete(`post-marke/${adsUuid}`);
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },

  registerClick: (adsUuid: string) => {
    try {
      api.post(`post-marke/${adsUuid}/register-click`);
    } catch (error: any) {
      showErrorMessage(error);
    }
  },

  registerView: (adsUuid: string) => {
    try {
      api.post(`post-marke/${adsUuid}/register-view`);
    } catch (error: any) {
      showErrorMessage(error);
    }
  },

  registerReaction: async (adsUuid: string, emoji: string) => {
    try {
      const { data } = await api.post(`post-marke/${adsUuid}/register-reaction`, { emoji });
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },

  setAds: (props: Partial<Ads>) => {
    for (const [key, value] of Object.entries(props)) {
      set((state) => ({ ads: { ...state.ads, [key]: value } }));
    }
  },

  clearAds: () => {
    const startDate = dayjs().format('DD/MM/YYYY');
    const startTime = dayjs().format('HH:mm');
    const endDate = dayjs().add(1, 'month').format('DD/MM/YYYY');
    const endTime = dayjs().format('HH:mm');

    const startDateArray = startDate.split('/');
    const endDateArray = endDate.split('/');

    set({
      ads: {
        title: '',
        description: '',
        awsMediaUrl: '',
        // awsMediaCoverUrl: '',
        maxClickImpression: '0',
        maxViewImpression: '0',
        // priority: '1',
        activeState: false,
        startDate: `${startDateArray[2]}-${startDateArray[1]}-${startDateArray[0]} ${startTime}`,
        expirationDate: `${endDateArray[2]}-${endDateArray[1]}-${endDateArray[0]} ${endTime}`,
        file: undefined,
        thumbnail: '',
        url: '',
        link: '',
        name: '',
        profile_photo: '',
      },
    });
  },
}));
