import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/Button';

import loadingImg from '@assets/icons/loading.gif';
import Loading from '@components/Loading';
import { Group, useGroups } from '@hooks/useGroups';
import { TextField } from '@mui/material';
import { api } from '@services/axios';
import { useParams } from 'react-router-dom';
import { toast } from 'utils/toast';

export default function ShareLink() {
  const { t } = useTranslation();

  const [url, setUrl] = useState('');
  const [expiresIn, setExpiresIn] = useState<string | null>('never');
  const { getGroupByUuid } = useGroups();
  const { groupId } = useParams();
  const [group, setGroup] = useState<Group>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getShareUrl = async () => {
      if (groupId) {
        const response = await getGroupByUuid(groupId);
        setGroup(response);
        setExpiresIn(response.share_link ?? 'never');
        setUrl(`${import.meta.env.VITE_APP_DOMAIN}/${response.code}`);
      }
    };

    getShareUrl();
  }, [getGroupByUuid, groupId]);

  async function handleChangeExpireIn(expires: string) {
    console.log(group?.code);
    setLoading(true);
    try {
      await api.put(`/groups/${groupId}`, {
        name: group?.name,
        share_link: expires,
        time_expire_link: expires,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }

    setExpiresIn(expires);
  }
  if (!group) return <Loading />;
  return (
    <section className="flex w-full justify-center py-12 bg-backgray min-h-[calc(100vh-88px)]">
      <div className="bg-white p-12 flex flex-col gap-[60px] rounded-md shadow-lg h-full">
        <h1 className="text-xl font-semibold text-primary-200">{t('share_invite_link_with_others')}</h1>
        <div className="flex flex-col gap-5">
          <h2 className="text-lg text-primary-200 font-semibold">{t('link_expires_in')}:</h2>
          <div className="grid grid-cols-2 grid-rows-3 gap-x-5 gap-y-5">
            <div className="flex items-center gap-[10px]">
              <input checked={expiresIn === 'never'} onChange={() => handleChangeExpireIn('never')} type="checkbox" />
              <span className="text-lg">{t('never')}</span>
            </div>
            <div className="flex items-center gap-[10px]">
              <input checked={expiresIn === '1d'} onChange={() => handleChangeExpireIn('1d')} type="checkbox" />
              <span className="text-lg">{t('one_day')}</span>
            </div>
            <div className="flex items-center gap-[10px]">
              <input checked={expiresIn === '30m'} onChange={() => handleChangeExpireIn('30m')} type="checkbox" />
              <span className="text-lg">{t('thirty_minutes')}</span>
            </div>
            <div className="flex items-center gap-[10px]">
              <input checked={expiresIn === '10d'} onChange={() => handleChangeExpireIn('10d')} type="checkbox" />
              <span className="text-lg">{t('ten_days')}</span>
            </div>
            <div className="flex items-center gap-[10px]">
              <input checked={expiresIn === '1h'} onChange={() => handleChangeExpireIn('1h')} type="checkbox" />
              <span className="text-lg">{t('one_hour')}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-5">
          <TextField
            className="w-full"
            id="outlined-basic"
            label="URL"
            variant="outlined"
            InputProps={{
              style: {
                fontSize: '14px',
                fontFamily: 'Poppins',
              },
            }}
            SelectProps={{
              style: {
                color: '#282A74',
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: '16px',
                fontFamily: 'Poppins',
                fontWeight: 'bold',
                color: '#282A74',
              },
            }}
            value={url}
          />
          <span>
            {loading ? (
              <div className="w-10 h-10">
                <img src={loadingImg} alt="loading" />
              </div>
            ) : (
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(url);
                  toast({
                    label: t('success'),
                    message: t('copied_clipboard'),
                    type: 'success',
                  });
                }}
                size="medium"
                variant="primary"
                text={t('copy')}
                disabled={loading}
              />
            )}
          </span>
        </div>
      </div>
    </section>
  );
}
